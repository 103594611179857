import "./activityModal.scss";
import { CollapsibleCard } from "./collapsibleCard";

export const ActivityModal = ({ onClose }) => {
  return (
    <div className="activity-modal">
      <div className="top-section">
        <h4 className="title">Science - Class 6</h4>
        <i
          class="bx bx-x"
          onClick={() => {
            onClose?.();
          }}
        ></i>
      </div>
      <CollapsibleCard />
      <div className="comment">
        <div className="comment-header">
          <p className="content">Comments</p>
          <p className="medium-content">
            Your comments and responses to clarification requests
          </p>
        </div>
        <div className="comment-card">
          <img src={require("../../../../images/t1.jpg").default} alt="img" />
          <div className="comment-block">
            <div className="comment-top">
              <p className="content">Eiko Daiton</p>
              <p className="medium-content">
                Head of Department of <span>‘Science’</span>
              </p>
            </div>
            <div className="comment-content-block">
              <p className="medium-content">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                dictum amet at nunc interdum sit.
              </p>
              <p className="time">1h ago</p>
            </div>
          </div>
        </div>
        <div className="comment-box">
          <textarea
            className="text-field"
            placeholder="Write your remarks here"
            rows={4}
          />
          <button className="main-btn">Post</button>
        </div>
      </div>
    </div>
  );
};
