import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import Modal from "react-awesome-modal";

const LiveVideoPlayer = ({ device, height, grid }) => {
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [visible, setVisible] = useState();
  useEffect(() => {
    if (device) {
      if (videoRef.current) {
        playerRef.current = videojs(videoRef.current, {
          controls: true,
          autoplay: true,
          preload: "auto",
          fluid: true,
          sources: [
            {
              src: device?.live_url,
              type: "application/x-mpegURL", // HLS stream type
            },
          ],
        });
      }
    }

    // Cleanup when the component is unmounted
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
      }
    };
  }, [device]);

  const closemodal = () => {
    setVisible(false);
  };
  if (device) {
    return (
      <div className="live-videoplayer-section" style={{ height }}>
        <div className={`top-header-section ${grid === "32" && "ft-sz"}`}>
          <p>{device?.camera_name}</p>
          <p
            onClick={() => {
              setVisible(true);
            }}
          >
            <i class="bx bx-history"></i>
          </p>
        </div>
        <video
          ref={videoRef}
          className="video-js vjs-default-skin"
          playsInline
          width="100%"
          height={height}
          autoPlay={true}
          muted
        ></video>

        <Modal
          visible={visible}
          width="50%"
          effect="fadeInUp"
          className="popuo-modal"
          onClickAway={() => closemodal()}
        >
          <div className="modal-details-section">
            <div className="modal-header">
              <div className="title-content">
                <div className="icon-block">
                  <i className="bx bxs-cog"></i>
                </div>
                <p className="modal-title">{device?.camera_name} - History</p>
              </div>

              <div className="close-icon" onClick={closemodal}>
                <i className="bx bx-x"></i>
              </div>
            </div>

            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <p>Date from</p>
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>Date To</p>
                    <input type="date" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>Time from</p>
                    <input type="time" className="form-control" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <p>Time To</p>
                    <input type="time" className="form-control" />
                  </div>
                </div>
                <div className="col-md-12">
                  <button
                    className="btn btn-primary"
                    style={{ marginTop: "20px" }}
                  >
                    Show
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* 
        <video
          controls
          autoPlay
          muted
          width="600"
          height="400"
          src="https://example.com/live/stream.m3u8"
          type="application/x-mpegURL"
        /> */}
      </div>
    );
  } else {
    return <div className="not-available" style={{ height: height }}></div>;
  }
};

export default LiveVideoPlayer;
