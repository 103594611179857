//@ts-check
import { useEffect, useState } from "react"
import { getChatList } from "../../components/Api/GetApi";

export const useGroupChat = () => {
    const [groupChat, setGroupChat] = useState(null);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        getChatList().then(res => {
            if (res.status === 200)
                setGroupChat(res.data)

        }).catch((e) => {
            console.log(e);
        }).finally(() => {

            setLoading(false)
        })
    }, [])
    return { groupChat, loading }
}