import React from "react";
import { NoDataContent } from "../NoDataContent/NoDataContent";

export const TeacherRemark = ({ data }) => {
  return (
    <div className="teacher-remark-section">
      <div className="header-flex-seccleation"></div>

      {data?.length > 0 ? (
        <div className="table-data-section">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th width="20%">Student Name </th>
                <th width="20%">Grade </th> 
                <th width="20%">Subject </th>
                <th width="40%">Remark </th>
              </tr>
            </thead>

            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.student_name}</td>
                    <td>{item?.grade}</td>
                    <td>{item?.subject}</td>
                    <td>{item?.description}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <NoDataContent
          title=""
          image={require("../../images/emptyfile.png").default}
          description="No Remark found  at the moment"
        />
      )}
    </div>
  );
};
