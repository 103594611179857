import { create } from "zustand";

const initialState = {
  popupType: "",
  selectedLessonTab: "",
  viewedDetails: "",
  active: 0,
  popUpMarks: "",
};

export const useTeacherDashboardStore = create((set) => ({
  ...initialState,
  setPopupType: (val) => set(() => ({ popupType: val })),
  setSelectedLessonTab: (val) => set(() => ({ selectedLessonTab: val })),
  setViewedDetails: (val) => set(() => ({ viewedDetails: val })),
  setActive: (val) => set(() => ({ active: val })),
  setPopUpMarks: (val) => set(() => ({ popUpMarks: val })),
}));
