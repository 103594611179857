import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  TeachingPlanError,
  useTeachingPlanFormikWrapper,
} from "../../../pages/teachingPlan/formikWrapper/formikWrapper";
import { InputField } from "../../ui/formikFields/inputField";
import { TextField } from "../../ui/formikFields/textField";

export const FlowSheet = ({ keyName, fieldArrayIndex, resourcesForPanel }) => {
  const { setFieldValue, values } = useTeachingPlanFormikWrapper();

  const showResourcesField = (keyName) => {
    switch (keyName) {
      case "response":
        return false;
      case "rules":
        return false;
      case "skills":
        return false;
      case "homework":
        return false;
      case "classwork":
        return false;
      case "preview":
        return false;
      default:
        return true;
    }
  };
  const showKeywordField = (keyName) => {
    switch (keyName) {
      case "response":
        return false;
      case "rules":
        return false;
      case "preview":
        return false;
      case "skills":
        return false;
      case "homework":
        return false;
      case "classwork":
        return false;
      default:
        return true;
    }
  };
  const showTimeField = (keyName) => {
    switch (keyName) {
      case "skills":
        return false;
      case "homework":
        return false;
      case "classwork":
        return false;
      default:
        return true;
    }
  };
  const showTeachingLessonField = (keyName) => {
    switch (keyName) {
      case "homework":
        return false;
      case "classwork":
        return false;
      default:
        return true;
    }
  };
  const showHomeworkField = (keyName) => {
    switch (keyName) {
      case "homework":
        return true;
      default:
        return false;
    }
  };
  const showClassworkField = (keyName) => {
    switch (keyName) {
      case "classwork":
        return true;
      default:
        return false;
    }
  };

  return (
    <div className="flow-sheet">
      {resourcesForPanel.length > 0 ? (
        <div className="input-field-block">
          <p className="content">Available Resources</p>
          <Select
            className="creatable-select"
            isMulti
            options={resourcesForPanel?.map((resource) => ({
              value: resource?.desc,
              label: resource?.id,
            }))}
            onChange={(selectedOptions) => {
              let currentTeachingMethodsString =
                values.lesson_plans?.[fieldArrayIndex]?.[keyName]
                  ?.teaching_methods || "";

              let currentTeachingMethods = currentTeachingMethodsString
                ? currentTeachingMethodsString.split(",")
                : [];

              const selectedLabels = selectedOptions.map((item) => item.label);

              const updatedMethodsArr = Array.from(
                new Set([
                  ...currentTeachingMethods.filter((item) =>
                    selectedLabels.includes(item.replace("# ", ""))
                  ),
                  ...selectedLabels.map((label) => `#${label}`),
                ])
              );

              const updatedMethodsString = updatedMethodsArr.join(" ");

              setFieldValue(
                `lesson_plans.${fieldArrayIndex}.${keyName}.teaching_methods`,
                updatedMethodsString
              );
            }}
          />
        </div>
      ) : (
        <>
          {showTeachingLessonField(keyName) && (
            <div className="input-field-block">
              <p className="content">
                Teaching methods/Activities/Experiments/Practical work
              </p>
              <TextField
                name={`lesson_plans.${fieldArrayIndex}.${keyName}.teaching_methods`}
                className="text-field"
                placeholder="Enter Teaching methods/Activities/Experiments/Practical work"
              />
              <TeachingPlanError
                name={`lesson_plans.${fieldArrayIndex}.${keyName}.teaching_methods`}
              />
            </div>
          )}
        </>
      )}
      {showTimeField(keyName) && (
        <div className="input-field-block">
          <p className="content">Time</p>
          <div className="time-block">
            <InputField
              name={`lesson_plans.${fieldArrayIndex}.${keyName}.time`}
              className="input-field"
              type="number"
              placeHolder="Enter total time "
            />
            <p className="content">minutes</p>
            <TeachingPlanError
              name={`lesson_plans.${fieldArrayIndex}.${keyName}.time`}
            />
          </div>
        </div>
      )}
      {showResourcesField(keyName) && (
        <div className="input-field-block">
          <p className="content">Resources</p>
          <TextField
            name={`lesson_plans.${fieldArrayIndex}.${keyName}.resources`}
            className="text-field"
            placeholder="Enter resources here"
          />
          <TeachingPlanError
            name={`lesson_plans.${fieldArrayIndex}.${keyName}.resources`}
          />
        </div>
      )}
      {showKeywordField(keyName) && (
        <div className="input-field-block">
          <p className="content">Keywords</p>
          <CreatableSelect
            className="creatable-select"
            isMulti
            // menuIsOpen={false}
            // value={`lesson_plans.${fieldArrayIndex}.${keyName}.keywords`}
            defaultValue={values?.lesson_plans?.[fieldArrayIndex]?.[
              keyName
            ]?.keywords?.map((keyword) => ({ label: keyword, value: keyword }))}
            onChange={(e) => {
              const temp = e.map((item) => item?.value);
              setFieldValue(
                `lesson_plans.${fieldArrayIndex}.${keyName}.keywords`,
                temp
              );
            }}
          />
          <TeachingPlanError
            name={`lesson_plans.${fieldArrayIndex}.${keyName}.keywords`}
          />
        </div>
      )}
      {showHomeworkField(keyName) && (
        <div className="input-field-block">
          <p className="content">Homework</p>
          <TextField
            name={`lesson_plans.${fieldArrayIndex}.planned_homework`}
            className="text-field"
            placeholder="Enter Homework"
          />
          <TeachingPlanError
            name={`lesson_plans.${fieldArrayIndex}.planned_homework`}
          />
        </div>
      )}
      {showClassworkField(keyName) && (
        <div className="input-field-block">
          <p className="content">Classwork</p>
          <TextField
            name={`lesson_plans.${fieldArrayIndex}.planned_classwork`}
            className="text-field"
            placeholder="Enter Classwork"
          />
          <TeachingPlanError
            name={`lesson_plans.${fieldArrayIndex}.planned_classwork`}
          />
        </div>
      )}
    </div>
  );
};
