import { useState } from "react";
import { useStudentStore } from "../../store/store";
import Table from "../Reusable Table/Table";
import "./marksTable.scss";

const MarksTable = ({ student }) => {
  const headers = ["Subjects", "Marks"];
  const examData = student?.exam_detail[0];

  //for option, extracting the terminals
  const Terminals = examData && Object?.keys(examData);

  const [selectedTerm, setSelectedTerm] = useState(Terminals?.[0]);

  const handleTermChange = (event) => {
    setSelectedTerm(event.target.value);
  };

  //it generates the array of subject and marks according to the option selected
  const getMarksByTerm = (term) => {
    const exams = examData?.[term];
    const marks = exams
      ?.map((exam) => {
        const subject = Object?.keys(exam)[0];
        const marks = Object?.values(exam)[0];
        const validMarks = parseFloat(marks);

        if (isNaN(validMarks)) {
          return null;
        }

        return [subject, parseFloat(marks)];
      })
      ?.filter((mark) => mark !== null);

    return marks;
  };

  const marksArr = getMarksByTerm(selectedTerm);
  if (
    Terminals?.length === 0 ||
    (Terminals?.length === 1 && marksArr?.length === 0)
  ) {
    return <p></p>;
  }

  return (
    <>
      {marksArr && (
        <div className="marksTable-container">
          <div className="flex">
            <h4>Subject Wise Marks</h4>
            <select value={selectedTerm} onChange={handleTermChange}>
              {Terminals?.map((term) => (
                <option key={term} value={term}>
                  {term}
                </option>
              ))}
            </select>
          </div>
          <Table headers={headers} rows={marksArr} />
        </div>
      )}
    </>
  );
};

export default MarksTable;
