import React, { useEffect, useState } from 'react'
import "./Numpad.scss"

const Numpad = ({setVal,shouldClear}) => {
  const [value, setValue] = useState('');

  const handleKeyPress = (num) => {
    
    setValue((prev)=> prev+num);
    setVal(value+num); // Pass the updated value to the parent component
  };

  useEffect(()=>{
    
      setValue("")
      setVal(null)
    

  },[shouldClear])

  return (
    <div className="numpad">
    <div className="row">
      <button onClick={() => handleKeyPress('1')}>1</button>
      <button onClick={() => handleKeyPress('2')}>2</button>
      <button onClick={() => handleKeyPress('3')}>3</button>

    </div>
    <div className="row">
    <button onClick={() => handleKeyPress('4')}>4</button>
      <button onClick={() => handleKeyPress('5')}>5</button>
      <button onClick={() => handleKeyPress('6')}>6</button>
      
    
    </div>
    <div className="row">
    <button onClick={() => handleKeyPress('7')}>7</button>
    <button onClick={() => handleKeyPress('8')}>8</button>
      <button onClick={() => handleKeyPress('9')}>9</button>
   
      


    </div>

    <div className=" row">
 
    
    <button onClick={() => handleKeyPress('0')}>0</button>
    </div>

 
  </div>
  )
}

export default Numpad