import React, { useState } from "react";
import "./sizebtn.scss";

const SizeBtn = ({
 
  index:rowIndex,
  getHandleBtn,
  type,
  customType

}) => {

 
  return (
    <div style={{maxWidth: "fit-content",marginInline:"auto"}}>
    <div className="sizeBtn_container">
      {type.map((btn,index)=>(

<div key={index}
onClick={() => {
getHandleBtn(btn.product_type,rowIndex)
 
}}
className={`${ customType === btn?.product_type  &&
 "selected_btn"
} inner_btn`}
>
  {btn?.product_type}

</div>
      )
      
      

      )}

     
    </div>
    </div>
  );
};

export default SizeBtn;
