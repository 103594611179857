import React, { useContext, useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import { isBrowser } from "react-device-detect";
import { AddPreferenceModal } from "../AddPreferenceModal/AddPreferenceModal";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import StudentAssignmentCard from "../StudentAssignmentCard/StudentAssignmentCard";
import { AssessmentContext } from "../TeacherAssessment/TeacherAssessment";
import PreLoader from "../preLoader/preLoader";
import { useAssignmentDetails, usePreferenceStore } from "../store/useStore";
import "./AssignmentDetailForm.scss";
import LineTab from "../TabList/LineTab/LineTab";
import { CheckedStatusActivity } from "../CheckedStatusACtivity/CheckedStatusActivity";

export const AssignmentDetailForm = ({
  ishomework,
  dataList,
  viewData,
  viewonly,
}) => {
  const [visible, setVisible] = useState(false);
  const [assignmentToken, setAssignmentToken] = useState();
  const [studentList, setStudentList] = useState();
  const { preferenceData, data } = usePreferenceStore();
  // const { subjectToken, iframe } = useContext(AssessmentContext);
  const {
    fetchAssignmentDetails,
    checkedData,
    UnCheckedData,
    CWcheckedData,
    CWUnCheckedData,
    loader,
  } = useAssignmentDetails();
  const {
    gradeToken: contextGradeToken,
    subjectToken: contextSubjectToken,
    iframe,
  } = useContext(AssessmentContext) || {};
  const [isBtnLoading, setBtnLoading] = useState();
  const gradeToken = viewonly ? viewData?.[0]?.grade_token : contextGradeToken;
  const subjectToken = viewonly ? "" : contextSubjectToken;

  const closeModal = () => {
    setVisible(false);
  };

  useEffect(() => {
    preferenceData();
  }, []);

  useEffect(() => {
    if (viewonly) {
      setAssignmentToken(viewData?.[0].assignment_id);
    } else {
      if (dataList?.[0]?.id) {
        setAssignmentToken(dataList?.[0]?.id);
      } else {
        setAssignmentToken(undefined);
      }
    }
  }, [dataList, viewData]);

  useEffect(() => {
    if (viewonly === true) {
      if (gradeToken && viewData?.[0].assignment_id) {
        fetchAssignmentDetails(gradeToken, assignmentToken, ishomework);
      } else {
        setStudentList(undefined);
      }
    } else {
      if (gradeToken && assignmentToken) {
        let isAllSame = false;
        if (typeof gradeToken === "string") {
          const data = gradeToken
            ?.split(",")
            .map((item) => parseInt(item.trim(), 10));
          isAllSame = data.every((value, index, array) => value === array[0]);
        } else {
          isAllSame = false;
        }

        if (isAllSame) {
          (async () => {
            try {
              fetchAssignmentDetails({
                gradeToken,
                assignmentToken,
                ishomework,
              });
            } catch {
              setStudentList(undefined);
            }
          })();
        } else {
          setStudentList(undefined);
        }
      } else {
        setStudentList(undefined);
      }
    }
  }, [gradeToken, assignmentToken, viewData, ishomework]);

  const handleDownload = (value) => {
    const link = document.createElement("a");
    link.href = value;
    link.download = "image.jpg";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const assignmentDetails = studentList?.map((item) => {
    return {
      studentToken: item?.studenttoken,
      assignmentId: item?.assignmentid,
      imageToken: item?.imageToken,
      remark: item?.remark,
      checked: item?.checked,
      checkedImageToken: item?.checkedImageToken,
      is_homework: ishomework,
      status_token: item?.status_token,
      marks: item?.marks,
      rollNo: item?.rollNo,
      studentName: item?.studentName,
      gradeToken: gradeToken,
      subjectToken: subjectToken,
    };
  });

  const tabData = [
    {
      title: "UnChecked",
      content: (
        <CheckedStatusActivity
          isBtnLoading={isBtnLoading}
          handleDownload={handleDownload}
          setBtnLoading={setBtnLoading}
          studentList={ishomework ? UnCheckedData : CWUnCheckedData}
          checked="checked"
          ishomework={ishomework}
          viewonly={viewonly}
          assignmentToken={assignmentToken}
        />
      ),
    },

    {
      title: "Checked",
      content: (
        <CheckedStatusActivity
          isBtnLoading={isBtnLoading}
          handleDownload={handleDownload}
          setBtnLoading={setBtnLoading}
          studentList={ishomework ? checkedData : CWcheckedData}
          ishomework={ishomework}
          checked="unchecked"
          viewonly={viewonly}
          assignmentToken={assignmentToken}
        />
      ),
    },
  ];

  return (
    <div className="assignment-detail-form">
      <Modal
        visible={visible}
        width={!isBrowser || iframe ? "95%" : "40%"}
        effect="fadeInUp"
        className="popuo-modal"
        onClickAway={() => closeModal()}
      >
        <div className="modal-details-section" style={{ minHeight: "480px" }}>
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                <i className="bx bxs-cog"></i>
              </div>
              <p className="modal-title">Preferences </p>
            </div>

            <div className="close-icon" onClick={() => closeModal()}>
              <i className="bx bx-x"></i>
            </div>
          </div>

          {!viewonly && (
            <div className="modal-body">
              <AddPreferenceModal
                closeModal={closeModal}
                assignmentToken={assignmentToken}
              />
            </div>
          )}
        </div>
      </Modal>

      {viewonly ? (
        ""
      ) : (
        <>
          {" "}
          <div className="assignment-header">
            <div className="search-block ">
              <label> Assignment Name</label>
              {dataList && dataList.length > 0 ? (
                <p>{dataList?.[0]?.value}</p>
              ) : (
                <p>No assignment found</p>
              )}
            </div>

            <div
              className="add-preference-block"
              onClick={() => {
                setVisible(true);
              }}
            >
              <button className="info-btn">
                <i class="bx bxs-cog"></i>
                Preferences
              </button>
            </div>
          </div>
        </>
      )}

      {loader ? (
        <div>
          <PreLoader />
        </div>
      ) : (
        <>
          {viewonly ? (
            <>
              {viewData && assignmentDetails ? (
                <StudentAssignmentCard
                  isBtnLoading={isBtnLoading}
                  handleDownload={handleDownload}
                  setBtnLoading={setBtnLoading}
                  studentList={studentList}
                  ishomework={ishomework}
                  viewonly={viewonly}
                />
              ) : (
                <NoDataContent
                  title=""
                  image={require("../../images/emptyfile.png").default}
                  description="No data found  at the moment"
                />
              )}
            </>
          ) : (
            <div className="line-tab-under-checked">
              {dataList && dataList.length > 0 ? (
                <LineTab tabData={tabData} defaultIndex={false} />
              ) : (
                <NoDataContent
                  title=""
                  image={require("../../images/emptyfile.png").default}
                  description="No data found  at the moment"
                />
              )}

              {/* {dataList && dataList.length > 0 && assignmentDetails ? (
                <StudentAssignmentCard
                  isBtnLoading={isBtnLoading}
                  handleDownload={handleDownload}
                  setBtnLoading={setBtnLoading}
                  studentList={studentList}
                  ishomework={ishomework}
                />
              ) : (
                <NoDataContent
                  title=""
                  image={require("../../images/emptyfile.png").default}
                  description="No data found  at the moment"
                />
              )} */}
            </div>
          )}
        </>
      )}
    </div>
  );
};
