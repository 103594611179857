import React,{useRef} from 'react'
import Modal from 'react-modal';
import CanteenBill from '../CanteenBill/CanteenBill';
import { useBillData } from '../../store/Canteen';
import {useReactToPrint} from "react-to-print"
import { IoClose } from "react-icons/io5";

const CanteenBillPopUp = () => {
    const {isBillShowing,clearIsBillShowing}= useBillData()

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      Width:"20%",
    },
  };
  let subtitle;

const billRef = useRef();

const handlePrint = useReactToPrint({
  content:()=> billRef.current,
})


  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#000000';
  }

  function closeModal() {
    clearIsBillShowing()
  }
    
  return (
    <div className='CanteenBillPopUp_container'>
       <Modal
        isOpen={isBillShowing}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
       
      >
             <div style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
        <button style={{color:"white" , borderRadius:"4px", background:"#756CEF"}}  onClick={handlePrint}>Print</button>
             <div style={{}} onClick={closeModal}><IoClose size={24} /></div>
             </div>

        <div ref={billRef}>
        <CanteenBill/>
        </div>
       
      </Modal>  


    </div>
  )
}

export default CanteenBillPopUp