import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getEvaluationUnitApi,
  getGradelist,
  getStudentEvaluationData,
  getStudentsFromGrade,
  getTeacherSubjectApi,
  getTeachingEvaluationList,
} from "../../components/Api/GetApi";
import NividLoader from "../../components/NividLoader/NividLoader";
import { NoDataContent } from "../../components/NoDataContent/NoDataContent";
import { useTeachingPlanMainStore } from "../teachingPlanMain/store/teachingPlanMainStore";

export const GraphFilter = () => {
  const [loadingType, setLoadingType] = useState("grade");

  const {
    grade,
    setGrade,
    subjectList,
    setSubjectList,
    gradeToken,
    setGradeToken,
    setViewPage,
    studentsList,
    setStudentsList,
    setSelectedEvaluationToken,
    subjectwiseUnitList,
    setSubjectwiseUnitList,
    setIsLessonEvaluation,
    selectedSubjectUnit,
    unitEvaluationList,
    setUnitEvaluationList,
    setSelectedSubjectUnit,
    setUnitwiseEvaluationData,
    unitwiseEvaluationData,
  } = useTeachingPlanMainStore();

  useEffect(() => {
    getGradelist()
      .then((res) => {
        setLoadingType("");
        setGrade(res?.data);
      })
      .catch((err) => {
        toast.error(err);
        setLoadingType("");
      });
  }, []);

  useEffect(() => {
    if (gradeToken) {
      setLoadingType("subject");
      getTeacherSubjectApi(gradeToken)
        .then((res) => {
          setLoadingType("");
          setSubjectList(res?.data?.subject);
        })
        .catch((err) => {
          toast.error(err);
          setLoadingType("");
        });
    }
  }, [gradeToken]);

  useEffect(() => {
    if (gradeToken && subjectList?.length > 0) {
      subjectList.forEach((item) => {
        if (item?.id) {
          getUnitList(gradeToken, item.id);
        }
      });
      setLoadingType("student");
      const data = {
        gradeToken: gradeToken,
        subjectToken: subjectList?.[0]?.id,
      };
      getStudentsFromGrade(data)
        .then((res) => {
          setStudentsList(res?.data);
          setLoadingType("");
        })
        .catch((err) => {
          toast.error(err);
          setLoadingType("");
        });
    }
  }, [gradeToken, subjectList]);

  const getUnitList = (grade_token, subject_token) => {
    if (
      subject_token !== 0 &&
      subject_token !== null &&
      grade_token !== "" &&
      grade_token !== null
    ) {
      setLoadingType("unit");
      getEvaluationUnitApi(grade_token, subject_token)
        .then((res) => {
          const planData = res?.data?.data?.plan;
          const data = {
            id: subject_token,
            planData: planData ? planData : [],
          };
          const temp = subjectwiseUnitList?.find(
            (item) => item.id === subject_token
          );
          if (!temp) {
            subjectwiseUnitList.push(data);
          }

          setIsLessonEvaluation(res?.data?.data?.is_lesson_evaluation);
          setLoadingType("");
        })
        .catch((err) => {
          toast.error(err);
          setLoadingType("");
        });
    }
  };

  const handleUnitChange = (subjectId, unitId) => {
    const data = {
      subjectId: subjectId,
      unitId: unitId,
    };
  
    const existingIndex = selectedSubjectUnit.findIndex(
      (item) => item.subjectId === subjectId
    );
  
    if (existingIndex > -1) {
      selectedSubjectUnit[existingIndex] = data;
    } else {
      selectedSubjectUnit.push(data);
    }
  
    getUnitEvaluationList(gradeToken, subjectId, unitId);
  };

  const getUnitEvaluationList = (grade_token, subject_token, unit_id) => {
    if (
      subject_token !== 0 &&
      subject_token !== null &&
      unit_id &&
      grade_token
    ) {
      setLoadingType("evaluation");
      getTeachingEvaluationList(grade_token, subject_token, unit_id)
        .then((res) => {
          const evaluationList = res?.data?.data;
          const tempdata = {
            id: unit_id,
            data: evaluationList ? evaluationList : [],
          };
          const temp = unitEvaluationList?.find((item) => item.id === unit_id);
          if (!temp) {
            unitEvaluationList.push(tempdata);
          }
          setLoadingType("");
        })
        .catch((err) => {
          toast.error(err);
          setLoadingType("");
        });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const studentsDataArray = [];
      for (const element of unitEvaluationList) {
        const tempData = [];
        if (element.data && element.data.length > 0) {
          for (const item of element.data) {
            try {
              const res = await getStudentEvaluationData(
                item?.evaluation_meta_token
              );
              const data = {
                data: res?.data?.data,
                evaluation_meta_token: item?.evaluation_meta_token,
              };

              tempData.push(data);
            } catch (error) {
              console.error("Error fetching student evaluation data:", error);
            }
          }

          const data = {
            unit_id: element.id,
            data: tempData,
          };

          studentsDataArray.push(data);
        }
      }

      setUnitwiseEvaluationData(studentsDataArray);
    };

    fetchData();
  }, [unitEvaluationList.length]);

  return (
    <div className="section-gap">
      <div className="container ">
        {loadingType === "grade" ? (
          <NividLoader />
        ) : (
          <div className="row">
            <div className="col-md-12 p-0">
              <div className="table-section">
                {grade.length > 0 ? (
                  <table className="table table-bordered">
                    <thead className="thead-light">
                      <tr>
                        <th colSpan="100%">
                          <div className="input-field-block">
                            <p className="content">Grade</p>
                            <select
                              name="gradeToken"
                              onChange={(e) => {
                                setGradeToken(e.target.value);
                                setStudentsList([]);
                                setSelectedEvaluationToken([]);
                                setViewPage("Evaluation List");
                                setSubjectwiseUnitList([]);
                                setSubjectList([]);
                                setSelectedSubjectUnit([]);
                                setUnitEvaluationList([]);
                                setStudentsList([]);
                                setUnitwiseEvaluationData([]);
                              }}
                              value={gradeToken}
                              className="input-field"
                            >
                              <option value="">Select Grade</option>
                              {grade?.map((item, index) => {
                                return (
                                  <option value={item?.id} key={index}>
                                    {item?.class_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </th>
                      </tr>
                      {loadingType === "subject" ? (
                        <NividLoader />
                      ) : (
                        <>
                          <tr>
                            {studentsList?.length > 0 && (
                              <th rowSpan="3">Student Name</th>
                            )}
                            {subjectList?.map((item, index) => {
                              const selectedUnit = selectedSubjectUnit.find(
                                (itm) => itm.subjectId === item?.id
                              )?.unitId;
                              const evaluation = unitEvaluationList.find(
                                (itm) => itm.id === selectedUnit
                              );

                              return (
                                <th
                                  key={index}
                                  colSpan={evaluation?.data?.length || 1}
                                >
                                  {item?.subjectName}
                                </th>
                              );
                            })}
                          </tr>
                          <tr>
                            {subjectList?.map((item, index) => {
                              const unit_list = subjectwiseUnitList?.find(
                                (itm) => itm.id === item?.id
                              );
                              const selectedUnit = selectedSubjectUnit.find(
                                (itm) => itm.subjectId === item?.id
                              )?.unitId;

                              const evaluation = unitEvaluationList.find(
                                (itm) => itm.id === selectedUnit
                              );

                              return (
                                <td
                                  key={index}
                                  colSpan={evaluation?.data?.length || 1}
                                >
                                  {unit_list?.planData?.length > 0 ? (
                                    <div className="input-field-block">
                                      <select
                                        name="selectedUnit"
                                        disabled={gradeToken === ""}
                                        className="input-field"
                                        onChange={(e) =>
                                          handleUnitChange(
                                            item.id,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="">Select Unit</option>
                                        {unit_list?.planData?.map(
                                          (unit, unitIndex) => (
                                            <option
                                              value={unit?.id}
                                              key={unitIndex}
                                            >
                                              {unit?.unit_name}
                                            </option>
                                          )
                                        )}
                                      </select>
                                    </div>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                          <tr>
                            {subjectList?.map((item, index) => {
                              const selectedUnit = selectedSubjectUnit.find(
                                (itm) => itm.subjectId === item?.id
                              )?.unitId;
                              const evaluation = unitEvaluationList.find(
                                (itm) => itm.id === selectedUnit
                              );

                              return evaluation?.data?.length > 0 ? (
                                evaluation.data.map((evalItem, evalIndex) => (
                                  <th
                                    key={`${index}-${evalIndex}`}
                                    title={evalItem?.title}
                                  >
                                    {evalItem?.title.charAt(0)}
                                  </th>
                                ))
                              ) : (
                                <th key={index}>-</th>
                              );
                            })}
                          </tr>
                        </>
                      )}
                    </thead>
                    <tbody>
                      {studentsList?.length > 0 || subjectList?.length > 0 ? (
                        <>
                          {studentsList
                            ?.sort((a, b) => a.rollNo - b.rollNo)
                            ?.map((student, studentIndex) => {
                              return (
                                <tr key={studentIndex}>
                                  <td>{student?.studentName}</td>
                                  {subjectList?.map((item, subjectIndex) => {
                                    const selectedUnit =
                                      selectedSubjectUnit.find(
                                        (itm) => itm.subjectId === item?.id
                                      )?.unitId;

                                    const formattedUnit =
                                      unitwiseEvaluationData.find(
                                        (unit) => unit.unit_id === selectedUnit
                                      );

                                    return formattedUnit?.data?.length > 0 ? (
                                      formattedUnit.data.map(
                                        (evaluation, evalIndex) => {
                                          const studentEvaluation =
                                            evaluation.data.find(
                                              (evalStudent) =>
                                                evalStudent.student_token ===
                                                student.id
                                            );
                                          return (
                                            <td
                                              key={`${subjectIndex}-${evalIndex}-${student.student_token}`}
                                            >
                                              {studentEvaluation
                                                ? studentEvaluation.value
                                                : "-"}
                                            </td>
                                          );
                                        }
                                      )
                                    ) : (
                                      <td key={subjectIndex} colSpan={1}>
                                        -
                                      </td>
                                    );
                                  })}
                                </tr>
                              );
                            })}
                        </>
                      ) : (
                        <NoDataContent
                          image={require("../../images/emptyfile.png").default}
                          title="No Subjects"
                          description={"No subjects has been added yet"}
                        />
                      )}
                    </tbody>
                  </table>
                ) : (
                  <NoDataContent
                    image={require("../../images/emptyfile.png").default}
                    title="No Grade"
                    description={"No grades has been added yet"}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
