import React from "react";
import "./mealPopUp.scss";
import { Preloader, Oval } from "react-preloader-icon";

class MealPopUp extends React.Component {
  render() {
    return (
      <section className="meal-popup">
        <div className="container">
          <div className="meal-popup-section">
            {this.props.checkedfoods.length === 0 ? (
              <p className="content">
                You haven't selected any items. Please select some item.
              </p>
            ) : (
              <>
                {this.props.successsMessage !== false ? (
                  <>
                    <div className="meal-popup-icon">
                      <i className="bx bxs-check-circle"></i>
                    </div>
                    <h2 className="title">Meal Plan Received</h2>
                  </>
                ) : (
                  <div className="">
                    <h4 className="block-title">
                      You have chosen the following meals for today
                    </h4>
                    <div className="meal-popup-content-block">
                      {this.props.checkedfoods.map((food, index) => {
                        return (
                          <div className="content-block" key={index}>
                            <i className="bx bx-check-circle front-content"></i>
                            <p className="content">{food.name}</p>
                          </div>
                        );
                      })}
                    </div>
                    <div className="btn-block">
                      <button
                        className="main-btn"
                        onClick={() => this.props.postApi()}
                      >
                        Submit
                      </button>
                      <button
                        className="secondary-btn"
                        onClick={() => this.props.closeModal()}
                      >
                        Cancel
                      </button>
                    </div>
                    {this.props.loading !== false ? (
                      <div className="preloader-section">
                        <Preloader
                          use={Oval}
                          size={30}
                          strokeWidth={6}
                          strokeColor="#29ABE2"
                          duration={2000}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default MealPopUp;
