import React, { useEffect, useState } from "react";
import {
  useAllSubjects,
  useExamSubject,
  useExamSubjectList,
  useTeacherDetailsStore,
} from "../store/useStore";
import { postExamSubject } from "../Api/PostApi";
import { toast } from "react-toastify";
import Select from "react-select";
import CheckboxSelect from "../../utils/services/CheckboxSelect/CheckboxSelect";

export const ExamSubjectPopup = ({ closeModal }) => {
  const [subjectValue, setSubjectValue] = useState([]);
  const [examSubjectValue, setExamSubjectValue] = useState();
  const { data: details } = useTeacherDetailsStore();
  const [subjectData, setSubjectData] = useState();
  const [ExamSubjectData, setExamSubjectData] = useState();
  const { fetchExamSubject, data: value } = useExamSubject();
  const { fetchAllSubject, data: item } = useAllSubjects();
  const { fetchExamSubjectList } = useExamSubjectList();
  const [loader, setLoader] = useState(false);

  const options = [
    { value: "One" },
    { value: "Two" },
    { value: "Three" },
    { value: "Four" },
    { value: "Five" },
    { value: "Six" },
  ];
  useEffect(() => {
    fetchExamSubject(details.teacher_id);
    fetchAllSubject(details.teacher_id);
  }, [details]);
  useEffect(() => {
    const gradeOption = item?.map((itm) => {
      return {
        value: itm?.id,
        label: `${itm?.subjectName} - ${itm?.class_name}`,
      };
    });
    setSubjectData(gradeOption);
  }, [item]);

  useEffect(() => {
    const examOption = value?.map((itm) => {
      return {
        value: itm?.id,
        label: `${itm?.subjectName}-${itm?.class_name}`,
      };
    });
    setExamSubjectData(examOption);
  }, [value]);
  const AddRole = async () => {
    const subjectList = subjectValue?.map((item) => {
      return item.value;
    });

    const ExamSubjectList = examSubjectValue?.map((item) => {
      return item.value;
    });

    const values = {
      teacher_token: details?.teacher_id,
      subject_list: subjectList || [],
      exam_subject_list: ExamSubjectList || [],
    };

    if (values.subject_list.length <= 0 && values.exam_subject_list <= 0) {
      toast("please select a subject");
    } else {
      setLoader(true);

      try {
        const res = await postExamSubject(values);
        if (res.status === 200) {
          toast(res?.message);
          closeModal();
          fetchExamSubjectList(details?.teacher_id);
        } else {
          toast("something went wrong");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <div className="exam-subject">
      <div className="form-group">
        <label>Select Exam subject</label>

        <div>
          <CheckboxSelect
            optionvalue={ExamSubjectData}
            setState={setExamSubjectValue}
            state={examSubjectValue}
          />
        </div>
      </div>

      <div className="form-group">
        <label>Select subject</label>

        <CheckboxSelect
          optionvalue={subjectData}
          setState={setSubjectValue}
          state={subjectValue}
        />
      </div>

      <div className="footer-btn-section end-block mt-20">
        <button className=" btn cancel-btn mr-10" onClick={closeModal}>
          Cancel
        </button>
        <button
          className={`btn btn-content custom-btn ${loader && "disable-cursor"}`}
          disabled={loader}
          onClick={() => {
            AddRole();
          }}
        >
          {loader ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};
