import axios from "axios";

import { domain, headers } from "../../url";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

export const DeleteTeacherRole = (value) => {
  return axios.delete(`${domain}/teacher_api/delete_tgss/${value}`, {
    headers,
  });
};

export const DeleteTeacherRoutine = (values) => {
  return axiosInstance.delete(`${domain}/teacher_api/delete_teacher_routine`, {
    data: values,
  });
};

export const DeleteAllExamSubject = (id) => {
  return axios.delete(
    `${domain}/api/v2/teacher_api/delete_selected_subjects_v2?teacher_token=${id}`,
    { headers }
  );
};

export const DeleteTeacherSubject = (teacher_id, id) => {
  console.log("id", id);
  return axiosInstance.delete(
    `${domain}/api/v2/teacher_api/delete_subject_v2?teacher_token=${teacher_id}&subject_id=${id}`
  );
};

export const DeleteExamSubject = (teacher_id, id) => {
  return axiosInstance.delete(
    `${domain}/api/v2/teacher_api/delete_exam_subject_v2?teacher_token=${teacher_id}&subject_id=${id}`
  );
};

export const DeleteParent = (id) => {
  return axiosInstance.delete(`${domain}/student_api/delete_parent?id=${id}`);
};

export const DeleteHodSubject = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/delete_hod_subjects`,
    data
  );
};

export const DeleteLessonPlan = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/delete_lesson_plans?lesson_plan_id=${data}`
  );
};

export const DeleteAttendance = (date) => {
  return axiosInstance.delete(
    `${domain}/api/v2/teacher_api/delete_attendance?date=${date}`
  );
};

export const DeleteProxySubject = (data) => {
  return axiosInstance.delete(`${domain}/delete_proxy_class`, {
    data,
  });
};

export const DeleteRouteList = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/delete_user_from_user_group`,
    data
  );
};
