import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useTeacherDetailsStore } from "../store/useStore";
import { postTeacherRoutine } from "../Api/PostApi";
import { toast } from "react-toastify";
import * as Yup from "yup";

export const TeacherRoutineForm = ({ closeModal }) => {
  const { data } = useTeacherDetailsStore();
  const [gradeValue, setGradeValue] = useState();
  const [routineValue, setRoutineValue] = useState();

  useEffect(() => {
    const gradeOption = data?.subject_teacher_options?.map((item) => {
      return {
        value: item?.id,
        label: `${item?.subjectName} - ${item.gradeName}`,
      };
    });
    setGradeValue(gradeOption);
  }, [data]);

  useEffect(() => {
    const routineOption = data?.routine_options?.map((item) => {
      return {
        value: item?.id,
        label: item.routine_name,
      };
    });
    setRoutineValue(routineOption);
  }, [data]);

  return (
    <div className="teacher-routine-form">
      <Formik
        initialValues={{
          period: "",
          routine: "",
          selectedSubjects: [],
        }}
        onSubmit={(values) => {
          const testData = { ...values, teacherId: data?.teacher_id };
          postTeacherRoutine(testData).then((res) => {
            if (res.data.status === 200) {
              toast(res?.data?.message);
              closeModal();
            } else {
              toast("something went wrong");
            }
          });
        }}
        validationSchema={Yup.object().shape({
          period: Yup.string().required("Please add period"),
          routine: Yup.string().required("Please select routine"),
          selectedSubjects: Yup.array().min(1, "Please select subject/Grade"),
        })}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="row">
              <div className="col-md-6">
                <label>Select subject/Grade</label>
                <Select
                  options={gradeValue}
                  onChange={(e) => {
                    setFieldValue("selectedSubjects", [e]);
                  }}
                />

                <ErrorMessage
                  name="selectedSubjects"
                  component="div"
                  className="error"
                />
              </div>

              <div className="col-md-6">
                <label>Select Routine</label>
                <Select
                  options={routineValue}
                  onChange={(e) => {
                    setFieldValue("routine", e.value);
                  }}
                />

                <ErrorMessage
                  name="routine"
                  component="div"
                  className="error"
                />
              </div>

              <div className="col-md-12 mt-20">
                <label>Enter Period</label>
                <Field
                  name="period"
                  type="number"
                  className="form-control"
                  onKeyPress={(e) => {
                    if (e.key === "-" || e.key === "e") {
                      e.preventDefault();
                    }
                  }}
                />

                <ErrorMessage name="period" component="div" className="error" />
              </div>
            </div>

            <div className="footer-btn-section end-block mt-20">
              <button className=" btn btn-light mr-10" onClick={closeModal}>
                Cancel
              </button>
              <button type="submit" className="btn btn-primary ">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
