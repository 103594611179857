import * as Yup from "yup";

const validationSchema1 = Yup.object().shape({
  unit_name: Yup.string().required("Please enter unit name"),
  unit_objective: Yup.string().required("Please enter unit objective"),
  gradeToken: Yup.string().required("Please Select grade"),
  subjectToken: Yup.string().required("Please select subjectToken"),
  total_teaching_hour: Yup.number().required("Please enter teaching hours"),
  start_day_token: Yup.string()
    .required("Please select start date")
    .test(
      "is-less-than-end_day_token",
      "Start Date must be earlier than End Date",
      function (value) {
        const endDayToken = this.parent.end_day_token;
        return parseInt(value) <= parseInt(endDayToken);
      }
    ),
  end_day_token: Yup.string()
    .required("Please select end date")
    .test(
      "is-greater-than-start_day_token",
      "End Date must be greater than Start Date",
      function (value) {
        const startDayToken = this.parent.start_day_token;
        return parseInt(value) >= parseInt(startDayToken);
      }
    ),
});

const validationSchema2 = Yup.object().shape({
  lesson_plans: Yup.array().of(
    Yup.object().shape({
      topic: Yup.string().required("Please enter topic"),
      // total_time: Yup.string().required("Please enter total time"),
      from_page: Yup.string()
        .required("Please enter from page")
        .test(
          "is-less-than-to-page",
          "From page must be less than to page",
          function (value) {
            const toPage = this.parent.to_page;
            return parseInt(value) <= parseInt(toPage);
          }
        )
        .test("not-zero", "From page cannot be 0", function (value) {
          return parseInt(value) !== 0;
        }),
      to_page: Yup.string()
        .required("Please enter to page")
        .test(
          "is-greater-than-from-page",
          "To page must be greater than from page",
          function (value) {
            const fromPage = this.parent.from_page;
            return parseInt(value) >= parseInt(fromPage);
          }
        )
        .test("not-zero", "To page cannot be 0", function (value) {
          return parseInt(value) !== 0;
        }),
      // objective: Yup.string().required("Please enter objective"),
      // response: Yup.object().shape({
      //   teaching_methods: Yup.string().required(
      //     "Please enter teaching methods"
      //   ),
      //   time: Yup.string().required("Please enter time"),
      // }),
      // rules: Yup.object().shape({
      //   teaching_methods: Yup.string().required(
      //     "Please enter teaching methods"
      //   ),
      //   time: Yup.string().required("Please enter time"),
      // }),
      // preview: Yup.object().shape({
      //   teaching_methods: Yup.string().required(
      //     "Please enter teaching methods"
      //   ),
      //   time: Yup.string().required("Please enter time"),
      //   resources: Yup.string().required("Please enter resources"),
      // }),
      // pedagogy: Yup.object().shape({
      //   teaching_methods: Yup.string().required(
      //     "Please enter teaching methods"
      //   ),
      //   time: Yup.string().required("Please enter time"),
      //   resources: Yup.string().required("Please enter resources"),
      //   keywords: Yup.array().of(Yup.string().required("Please enter keyword")),
      // }),
      // skills: Yup.object().shape({
      //   teaching_methods: Yup.string().required(
      //     "Please enter teaching methods"
      //   ),
      // }),
      // planned_homework:  Yup.string().required("Please enter homework"),
      // planned_classwork:  Yup.string().required("Please enter classwork")
    })
  ),
});

export const requiredValidation = (viewPage, isAddLessonPlan) => {
  switch (viewPage) {
    case "newPlan":
      if (!isAddLessonPlan) {
        return validationSchema1;
      } else {
        return;
      }
    case "lessonTab":
      return validationSchema2;
    default:
      return validationSchema1;
  }
};
