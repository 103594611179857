import { create } from "zustand";

const initialState = {
  schoolName: "",
  contact: "",
  email: "",
  state: "4",
  district: "",
  municipality: "",
  wardNo: "",
  uploadReceipt: {},
  delegate_photo: {},
  guardian_name: "",
};

export const useSchoolRegistrationStore = create((set) => ({
  ...initialState,
  setSchoolName: (val) => set(() => ({ schoolName: val })),
  setContact: (val) => set(() => ({ contact: val })),
  setEmail: (val) => set(() => ({ email: val })),
  setState: (val) => set(() => ({ state: val })),
  setDistrict: (val) => set(() => ({ district: val })),
  setMunicipality: (val) => set(() => ({ municipality: val })),
  setWardNo: (val) => set(() => ({ wardNo: val })),
  setUploadReceipt: (val) => set(() => ({ uploadReceipt: val })),
  setDelegateName: (val) => set(() => ({ guardian_name: val })),
  setDelegatePhoto: (val) => set(() => ({ delegate_photo: val })),
  resetForm: () => set(() => ({ ...initialState })),
}));
