import { React, useEffect, useState } from "react";

export const StudentList = ({ submitData, status, handleSave, getApiData }) => {
  const [studentPerformance, setStudentPerformance] = useState({});

  const [isChecked, setIsChecked] = useState("");
  const [value, setValue] = useState("");
  const [studentDescription, setStudentDescription] = useState("");

  const [stateList, setStateList] = useState([]);
  const [headerState, setHeaderState] = useState("");

  const handleSelect = (item) => {
    if (value === item.value) {
      setIsChecked(!isChecked);
    }
  };

  useEffect(() => {
    const studentPerformanceMap = {};
    const studentDescription = {};

    submitData?.student?.forEach((student) => {
      studentPerformanceMap[student.id] = "";
      studentDescription[student.id] = " No Remark";
    });

    setStudentPerformance(studentPerformanceMap);
    setStudentDescription(studentDescription);
  }, [submitData?.student]);

  useEffect(() => {
    const studentPerformanceMap = {};
    getApiData?.remark.forEach((item) => {
      studentPerformanceMap[item.id] = submitData?.remark?.find(
        (remarkItem) => remarkItem.id == item.value
      )?.value;
      studentDescription[item.id] = item.description;
    });
    setStudentPerformance(studentPerformanceMap);
  }, [getApiData]);

  return (
    <div className="student-list">
      <div className="d-flex">
        <h5>Student List</h5>
      </div>
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th scope="col">Student Name</th>
            {submitData?.remark.map((item) => {
              return (
                <th
                  scope="row"
                  // onChange={() => {
                  //   stateList((current) => [...current, item?.value])
                  // }}
                >
                  <input
                    className="header-checkbox"
                    type="checkbox"
                    name="value"
                    value={item.value}
                    checked={headerState === item.value}
                    onChange={(e) => {
                      setHeaderState(e.target.value);
                      const newObj = { ...studentPerformance };
                      Object.keys(newObj).forEach((key) => {
                        newObj[key] = e.target.value;
                      });
                      setStudentPerformance(newObj);
                    }}
                  />
                  {item?.value}
                </th>
              );
            })}
            <th>Description</th>
          </tr>
        </thead>

        <tbody>
          {submitData?.student?.map((item) => {
            return (
              <tr>
                <td id={item?.id}>{item?.studentName}</td>
                {submitData?.remark?.map((itm, idx) => {
                  const itemID = item.id;
                  // console.log(studentPerformance?.[itemID] === itm.value)

                  return (
                    <td scope="row" key={idx}>
                      <input
                        id={itm.id}
                        type="checkbox"
                        name={itm.value}
                        checked={studentPerformance?.[itemID] === itm.value}
                        onChange={(e) => {
                          // console.log('studentPerformance', studentPerformance)
                          const newStudentPerformance = {
                            ...studentPerformance,
                          };
                          newStudentPerformance[itemID] = e.target.name;
                          setStudentPerformance(newStudentPerformance);
                          //
                        }}
                      />
                    </td>
                  );
                })}
                <td>
                  <input
                    type="text"
                    className="full-width"
                    value={
                      studentDescription[item.id] === ""
                        ? "No Remarks"
                        : studentDescription[item.id]
                    }
                    onChange={(e) => {
                      const newStudentDescription = {
                        ...studentDescription,
                      };
                      newStudentDescription[item.id] = e.target.value;
                      setStudentDescription(newStudentDescription);
                    }}
                    id={item?.id}
                  ></input>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button
        type="submit"
        className="btn btn-info"
        onClick={() => handleSave(studentPerformance, studentDescription)}
      >
        Save
      </button>
    </div>
  );
};
