import React from 'react'
import "./cashDetailRect.scss"

const CashDetailRect = ({title, sub,backgroundColor,border}) => {
  return (
    <div style={{backgroundColor:backgroundColor,
    border:border}} className='cash_container'>
        <div className='title'>{title}</div>
        <div className='sub_title'>{sub}</div>

    </div>
  )
}

export default CashDetailRect