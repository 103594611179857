import { downloadExcel } from "../../utils/services/downloadService";
import { useTeachingPlanMainStore } from "../teachingPlanMain/store/teachingPlanMainStore";
import { GraphFilter } from "./graphFilter";
import { SiMicrosoftexcel } from "react-icons/si";

export const TeachingPlanEvaluationGraph = () => {
  const {
    gradeToken,
    grade,
    subjectList,
    studentsList,
    subjectwiseUnitList,
    selectedSubjectUnit,
    unitEvaluationList,
    unitwiseEvaluationData,
  } = useTeachingPlanMainStore();
  return (
    <div className="teaching-plan-evaluation-graph">
      <div className="title-block header">
        <div className="container">
          <div className="d-flex justify-content-between">
            <h1 className="title">Teaching Plan Evaluation</h1>
            <button
              className="primary-btn"
              onClick={() => {
                downloadExcel(
                  gradeToken,
                  grade,
                  subjectList,
                  subjectwiseUnitList,
                  studentsList,
                  selectedSubjectUnit,
                  unitEvaluationList,
                  unitwiseEvaluationData
                );
              }}
            >
              <SiMicrosoftexcel className="mr-2"/>
              Download Excel
            </button>
          </div>
        </div>
      </div>
      <GraphFilter />
    </div>
  );
};
