import axios from "axios";
import { useEffect, useState } from "react";

import { domain, jwtToken } from "../../url";

import { LandscapeLayout } from "../MarksheetLayout/Layout/LandscapeLayout/LandscapeLayout";
import { NEBGradeSheet } from "../MarksheetLayout/Layout/NebLedger/NebLedger";
import { Layout1 } from "../MarksheetLayout/Layout/Layout1/Layout1";
import { Layout2 } from "../MarksheetLayout/Layout/Layout2/Layout2";
import { Layout3 } from "../MarksheetLayout/Layout/Layout3/Layout3";
import { Layout4 } from "../MarksheetLayout/Layout/Layout4/Layout4";
import { Layout5 } from "../MarksheetLayout/Layout/Layout5/Layout5";
import { Layout6 } from "../MarksheetLayout/Layout/Layout6/Layout6";
import Layout2080 from "../MarksheetLayout/Layout/Layout2080/Layout2080";

export const LayoutPdf = (props) => {
  const [layoutdata, setLayoutData] = useState();
  const [info, setInfo] = useState();
  const [message, setMessage] = useState();

  const name = props.layoutShow?.class_name;
  const mark = props.layoutShow?.mark_layout;

  const classwise = false;

  useEffect(() => {
    axios
      .get(
        `${domain}/mobile_exam_datas/${props?.exam}/${props?.studentToken}?token=${jwtToken}`
      )
      .then((res) => {
        if (res.status === 200) {
          setInfo(res.data);

          axios
            .get(
              `${domain}/mobile_score/${props?.grade}/${
                props?.exam
              }/${classwise}/${props.studentToken ?? ""}?token=${jwtToken}`
            )
            .then((resp) => {
              setLayoutData(resp.data);
              props?.data(false);
            });
        } else {
          setMessage("Result not published yet !!");
        }
      });
  }, [props.exam, props.grade, props.studentToken]);
  return (
    <div>
      {layoutdata ? (
        <div className="">
          {name === "XI" ||
          name === "Eleven" ||
          name === "XII" ||
          name === "Twelve" ? (
            <NEBGradeSheet
              resultData={layoutdata}
              exam={props.exam}
              info={info}
            />
          ) : mark === "A" ? (
            <Layout1 resultData={layoutdata} exam={props.exam} info={info} />
          ) : mark === "B" ? (
            <Layout2 resultData={layoutdata} exam={props.exam} info={info} />
          ) : mark === "C" ? (
            <Layout3 resultData={layoutdata} exam={props.exam} info={info} />
          ) : mark === "D" ? (
            <Layout4 resultData={layoutdata} exam={props.exam} info={info} />
          ) : mark === "E" ? (
            <Layout5 resultData={layoutdata} exam={props.exam} info={info} />
          ) : mark === "F" ? (
            <Layout6 resultData={layoutdata} exam={props.exam} info={info} />
          ) : (
            <Layout2080 resultData={layoutdata} exam={props.exam} info={info} />
          )}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};
