import react, { useEffect, useState } from "react";
import Select from "react-select";
import { getSubjectData } from "../Api/GetApi";
import { NepDatePicker } from "neps-date-picker";
import "./AddProxySubjectPopup.scss";
import { useProxySubject, useTeacherDetailsStore } from "../store/useStore";
import { postProxyClassRoutine } from "../Api/PostApi";
import { toast } from "react-toastify";

export const AddProxySubjectPopup = ({ closeModal }) => {
  const [loader, setLoader] = useState(false);
  const [subjectData, setSubjectData] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [date, setDate] = useState();
  const { data } = useTeacherDetailsStore();
  const { fetchProxySubjectList } = useProxySubject();

  useEffect(() => {
    getSubjectData().then((res) => {
      const data = res.data;
      const option = data?.map((item) => {
        return {
          label: item?.subjects,
          value: item?.grade_subject_token,
        };
      });
      setSubjectData(option);
    });
  }, []);

  const handleSubmit = async () => {
    if (!date) {
      toast("Please select a date");
    } else if (!selectedSubject) {
      toast("Please select a grade/Subject");
    } else {
      setLoader(true);
      const subjectList = selectedSubject?.map((item) => {
        return item.value;
      });
      const values = {
        teacher_token: data?.teacher_id,
        grade_subject_token: subjectList,
        day_token: date,
      };

      try {
        const res = await postProxyClassRoutine(values);
        if (res.data.status === 1) {
          toast("successfully added");
          fetchProxySubjectList();
          closeModal();
        } else {
          toast(res.data.message);
        }
      } catch {
        toast("something went wrong");
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <div className="exam-subject">
      <div className="form-group">
        <label>Select grade and subject</label>
        <Select
          isMulti
          options={subjectData}
          onChange={(value) => {
            setSelectedSubject(value);
          }}
        />
      </div>

      <div className="form-group proxy-date-section">
        <label>Select date</label>
        <NepDatePicker onDateSelect={(val) => setDate(val)} />
      </div>

      <div className="footer-btn-section end-block mt-20">
        <button className=" btn cancel-btn mr-10" onClick={closeModal}>
          Cancel
        </button>
        <button
          className={`btn btn-content custom-btn ${loader && "disable-cursor"}`}
          disabled={loader}
          onClick={() => {
            handleSubmit();
          }}
        >
          {loader ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};
