import { Link } from "react-router-dom";
import "./StudentProfileUpdater.scss";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import { domain, jwtToken } from "../../url";
import { UpdateStudentProfilePost } from "../Api/PostApi";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

export const StudentProfileUpdater = () => {
  const [classList, setClassList] = useState([]);
  const [field, setField] = useState([]);
  const [gradeToken, setGradeToken] = useState();
  const [tableData, setTableData] = useState();
  const [tableField, setTableField] = useState();
  const [inputValues, setInputValues] = useState([]);
  const [fieldShow, setFieldShow] = useState();
  const [tableValues, setTableValues] = useState();

  useEffect(() => {
    axios.get(`${domain}/api/class_list_for_mobile`).then((res) => {
      setClassList(res.data);
    });
  }, []);

  function handleSelectChange(event) {
    const selectedOptionId = event.target.selectedOptions[0].id;
    setGradeToken(selectedOptionId);
  }

  useEffect(() => {
    // if(gradeToken && fieldShow){
    // axios.get(`${domain}/api/mobile/student_profile_updater_values?field_name=${fieldShow?.column_name}&grade_token=${gradeToken}&token=${jwtToken}`).then((res)=>{
    axios
      .get(
        `${domain}/api/mobile/student_profile_updater_values?field_name="dob"&grade_token=${gradeToken}&token=${jwtToken}`
      )
      .then((res) => {
        setTableValues(res.data);
      });
    // }
  }, [gradeToken, fieldShow]);

  function handleSelectField(event) {
    setTableField(event.target.value);

    const selectedField = field.find(
      (item) => item.column_name === event.target.value
    );
    setFieldShow(selectedField);
  }

  useEffect(() => {
    if (gradeToken) {
      axiosInstance
        .get(
          `${domain}/teacherApi/student_list_from_grade?grade_token=${gradeToken}&token=${jwtToken}`
        )
        .then((res) => {
          setTableData(res.data);
        });
    }
  }, [gradeToken]);

  function handleInputChange(e, index) {
    const { value } = e.target;
    setInputValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value;
      return newValues;
    });
  }

  function handleUpdateClick() {
    const studentTokens = tableData.map((item) => item.id);
    const column_value = tableValues.map(
      (item, index) => inputValues[index] || item?.[tableField]?.toString()
    );
    const formData = {
      student_token: studentTokens,
      column_value: column_value,
    };

    axios
      .post(`${domain}/api/mobile/student_profile_updater`, formData)
      .then((res) => {
        if (res.status === 200) {
          alert("updated");
        } else {
          alert("error");
        }
      });
  }

  useEffect(() => {
    axios.get(`${domain}/api/student_detail_columns`).then((res) => {
      setField(res.data);
    });
  }, []);

  const renderSwitch = (index) => {
    const dataVal = tableValues[index]?.[tableField];

    switch (fieldShow?.data_type) {
      case "integer":
      case "bigint":
      case "double precision":
        return (
          <input
            className="input-field"
            type="number"
            value={inputValues[index] || dataVal}
            onChange={(event) => handleInputChange(event, index)}
          />
        );
      case "character varying":
      case "text":
        return (
          <input
            className="input-field"
            type="text"
            value={inputValues[index] || dataVal}
            onChange={(event) => handleInputChange(event, index)}
          />
        );
      case "boolean":
        return (
          <>
            <input type="radio" id="yes" name="fav_language" value="yes" />
            <label for="yes">yes</label>
            <br />
            <input type="radio" id="no" name="fav_language" value="no" />
            <label for="no">no</label>
            <br></br>
          </>
        );
      case "date":
        return (
          <input
            type="date"
            id="date"
            name="date"
            data-date-format="DD MMMM YYYY"
            value={inputValues[index] || dataVal}
            onChange={(event) => handleInputChange(event, index)}
          />
        );

      default:
        //     return     <input className="input-field test"
        //     value={inputValues[index] || ''}
        //     onChange={(event) => handleInputChange(event, index)}
        //  />
        return (
          <input
            type="date"
            id="date"
            name="date"
            data-date-format="DD MMMM YYYY"
            value={inputValues[index] || dataVal}
            onChange={(event) => handleInputChange(event, index)}
          />
        );
    }
  };
  return (
    <div className="student-profile-section">
      <div className="title-block header">
        <Link to="/">
          <i className="bx bxs-chevron-left"></i>
        </Link>
        <h2 className="title">Student profile updater</h2>
      </div>

      <div className="container">
        <div className="student-profile-content">
          <div className="input-content-block">
            <p className="content">Select Field</p>
            <div className="form-group">
              <select
                className="input-field "
                name="grade"
                onChange={handleSelectField}
                value="dob"
                disabled
              >
                {field?.map((item, index) => {
                  return <option key={index}>{item?.column_name}</option>;
                })}
              </select>
              {/* <span className="error">asas</span> */}
            </div>
          </div>

          <div className="input-content-block">
            <p className="content">Select Class Name</p>
            <div className="form-group">
              <select
                className="input-field "
                name="grade"
                onChange={handleSelectChange}
              >
                {classList?.map((item, index) => {
                  return (
                    <option key={index} id={item.id}>
                      {item?.class_name}
                    </option>
                  );
                })}
              </select>
              {/* <span className="error">asas</span> */}
            </div>
          </div>

          {tableData && (
            <table className="table table-bordered">
              <thead>
                <tr>
                  <td>Student Name</td>
                  <td>{tableField ? tableField : "Field Name"}</td>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((item, index) => {
                  return (
                    <tr>
                      <td>{item?.studentName}</td>
                      <td>{renderSwitch(index)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}

          <button className="main-btn" onClick={handleUpdateClick}>
            Update
          </button>
        </div>
      </div>
    </div>
  );
};
