import React from "react";
import "./elearning.scss";
import SubjectList from "../../components/eLearning/SubjectList/SubjectList";
import { Banner } from "../../components/eLearning/Banner/Banner";

export const Elearning = () => {
  return (
    <div className="elearning">
      <Banner />
      <SubjectList />
    </div>
  );
};
