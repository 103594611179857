import React from "react";
import noData from "../../images/no-data.svg";
import { domain } from "../../url";

export const BarChartCard = (props) => {
  let { attendanceData } = props;

  return (
    <div className="right-box">
      {/* <a href="/absent_list"> */}
      {attendanceData === "" ? (
        <div className="no-data">
          <div className="no-img">
            <img src={noData} alt="no-data" />
          </div>
          <div className="no-data-content">
            No Assignment available at the moment
          </div>
        </div>
      ) : (
        <>
          <div
            className="cpoint"
            onClick={() => {
              window.open(`${domain}/grade_wise_attendance`);
            }}
          >
            <div className="data-box present " key="index">
              <h4 className="extra-small-content data-title">Total Present</h4>
              <h3 className="data-content">{attendanceData?.today_present}</h3>
            </div>
          </div>
          {/* </a> */}

          <div
            className="cpoint"
            onClick={() => {
              window.open(`${domain}/late_list`);
            }}
          >
            <div className="data-box late" key="index">
              <h4 className="extra-small-content data-title">Total Late</h4>
              <h3 className=" data-content">{attendanceData?.today_late}</h3>
            </div>
          </div>

          <div
            className="cpoint"
            onClick={() => {
              window.open(`${domain}/absent_list`);
            }}
          >
            <div className="data-box absent" key="index">
              <h4 className="extra-small-content data-title">Total Absent</h4>
              <h3 className="data-content">{attendanceData?.today_absent}</h3>
            </div>
          </div>

          <div
            className="cpoint"
            onClick={() => {
              window.open(`${domain}/early_exit_list`);
            }}
          >
            <div className="data-box exit" key="index">
              <h4 className="extra-small-content data-title">
                Total Early Exit
              </h4>
              <h3 className="data-content">{attendanceData?.today_exit}</h3>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
