import React from 'react'
import right from "../../images/arrow-right.svg"

const ViewAll = () => {
    const style = {
        color: "#0A78B1",
        fontSize: "12px",
        fontWeight: "500",
        textDecoration:"underline",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "4px",
        cursor: "pointer"
      
        
    }
  return (
    <div style={style}>ViewAll <span><img src={right} alt="right-arrow" /></span></div>
  )
}

export default ViewAll