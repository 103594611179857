import React from "react";
import { AttendanceSettingsTable } from "../AttendanceSettingTable/AttendanceSettingsTable";

export const StudentAttendance = () => {
  return (
    <div style={{ margin: "10px 30px" }}>
      <AttendanceSettingsTable singlePage={false} isStudent={true} />
    </div>
  );
};
