import React, { useEffect, useState } from "react";
import "./inventorytable.scss";
import ViewAll from "../AccountDashboardViewBtn/ViewAll";
import SizeBtn from "../sizeBtn/SizeBtn";
import { useInventoryList } from "../../store/accountDashboard";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import NividLoader from "../NividLoader/NividLoader";

const InventoryTable = ({ search }) => {
  const { inventory, loading, fetchInventoryList } = useInventoryList();
  const [modifiedApi, setModifiedApi] = useState([]);

  useEffect(() => {
    fetchInventoryList();
  }, []);

  const tableHead = [
    "S.N.",
    "PRODUCT NAME",
    "TYPE",
    "STOCK ON HAND",
    "RATE",
    // "ACTION",
  ];

  useEffect(() => {
    const api = inventory?.map((data) => ({
      ...data,
      type: data?.type_data[0]?.product_type,
    }));
    setModifiedApi(api);
  }, [inventory]);

  useEffect(() => {
    const filteredInventory = inventory?.filter((item) =>
      item.product_name.toLowerCase().includes(search.toLowerCase())
    );
    setModifiedApi(filteredInventory);
  }, [search]);

  const showData = modifiedApi?.slice(0, 5);

  const getHandleBtn = (value, index) => {
    const temp = [...modifiedApi];
    temp[index].type = value;
    setModifiedApi(temp);
  };

  if (loading) {
    return (
      <div>
        <NividLoader height={"325px"} />
      </div>
    );
  }

  return (
    <div className="inventory_table_container">
      <div
        style={{
          height: "310px",
          overflowY: "auto",
          border: "1px solid #EEEEEE",
        }}
      >
        <table>
          <thead>
            <tr>
              {tableHead.map((h) => {
                return <th key={h}>{h}</th>;
              })}
            </tr>
          </thead>
          {modifiedApi.length !== 0 ? (
            <tbody>
              {showData?.map((d, index) => {
                const filterData = d?.type_data.find(
                  (item) => item.product_type === d.type
                );

                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{d.product_name}</td>
                    <td>
                      {d.stock === "0" ? (
                        "-"
                      ) : (
                        <div>
                          {" "}
                          <SizeBtn
                            index={index}
                            getHandleBtn={getHandleBtn}
                            type={d.type_data}
                            customType={d.type}
                          />
                        </div>
                      )}{" "}
                    </td>
                    <td>
                      {filterData?.stock_on_hand <= "0" ||
                      filterData?.stock_on_hand === undefined ? (
                        <div className="outofstock">OUT OF STOCK</div>
                      ) : (
                        filterData?.stock_on_hand
                      )}
                    </td>
                    <td>
                      {filterData?.selling_price != null
                        ? `Rs. ${filterData?.selling_price}`
                        : "-"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                width: "100%",
                height: "250px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <tr>
                <td colSpan={tableHead.length} style={{ textAlign: "center" }}>
                  <NoDataContent
                    image={require("../../images/emptyfile.png").default}
                    title="No Data"
                    description="Couldn't find inventory data at the moment"
                  />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <div style={{ marginTop: "8px" }}>
        <ViewAll />
      </div>
    </div>
  );
};

export default InventoryTable;
