import { useState, useEffect } from "react";

export const useSelectableTable = () => {
  const [isItemSelected, setIsItemSelected] = useState("");
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    // Filtering the table with the searched input
    const filter = tableData?.filter((item) =>
      item.studentName?.toLowerCase().includes(searchedInput.toLowerCase())
    );
    setFilteredData(filter);
  }, [searchedInput, tableData]);

  // This handles the selection of student from the table
  // isItemSelected for the selected student css and selectedItemList for the data to send in POST API
  const handleSelectItem = (index, itemInfo) => {
    if (isItemSelected === "selected" && selectedItemList.includes(itemInfo)) {
      const indexOf = selectedItemList.indexOf(itemInfo);
      const newSelectedItemList = selectedItemList.slice();
      newSelectedItemList.splice(indexOf, 1);
      setSelectedItemList(newSelectedItemList);
      return;
    }
    setIsItemSelected("selected");
    setSelectedItemList([...selectedItemList, itemInfo]);
  };

  const handleSearch = (e) => {
    setSearchedInput(e.target.value);
  };

  // If all students are selected individually, allselected is set to true
  useEffect(() => {
    if (selectedItemList.length === tableData.length) {
      setAreAllSelected(true);
    } else {
      setAreAllSelected(false);
    }
  }, [selectedItemList, tableData]);

  // To select all the student
  const handleSelectAll = () => {
    setAreAllSelected((prevValue) => !prevValue);
    setSelectedItemList(tableData.map((item, index) => item.studentToken));
    setIsItemSelected("selected");
    if (areAllSelected) {
      setSelectedItemList([]);
      setIsItemSelected("");
    }
  };

  return {
    isItemSelected,
    selectedItemList,
    handleSelectItem,
    searchedInput,
    handleSearch,
    setTableData,
    tableData,
    filteredData,
    areAllSelected,
    handleSelectAll,
    setIsItemSelected,
    setSelectedItemList,
  };
};
