import React, { useEffect, useState } from "react";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import {
  DeleteExamSubject,
  DeleteHodSubject,
  DeleteTeacherRole,
  DeleteTeacherSubject,
} from "../Api/DeleteApi";
import Modal from "react-modal";
import {
  useExamSubjectList,
  useHodSubjectList,
  useTeacherDetailsStore,
} from "../store/useStore";
import { toast } from "react-toastify";
import { ExamSubjectPopup } from "../ExamSubjectPopup/ExamSubjectPopup";
import { HodSubjectPopup } from "../HodSubjectPopup/HodSubjectPopup";
import { getTeacherData } from "../Api/GetApi";
import { AcademicsTable } from "./AcademicsTable";
import { academicStore } from "../../store/teacherProfileStore";

export const TeacherRole = () => {
  const [visible, setVisible] = useState();
  const { data: details } = useTeacherDetailsStore();
  const { fetchExamSubjectList, data } = useExamSubjectList();
  const { fetchHodSubjectList, hodData } = useHodSubjectList();

  const [hodVisible, setHodVisible] = useState();
  const [isHod, setIsHod] = useState();

  const {
    setModalVisible,
    setkeyValue,
    keyValue,
    handleId,
    academicId,
    setAcademicId,
    modalVisible,
  } = academicStore();

  useEffect(() => {
    if (details?.teacher_id) {
      fetchExamSubjectList(details?.teacher_id);
    }
    fetchHodSubjectList();
  }, [details]);

  useEffect(() => {
    getTeacherData().then((res) => {
      setIsHod(res.data?.is_hod);
    });
  }, []);
  const handleDelete = () => {
    if (keyValue === "subject") {
      DeleteTeacherSubject(details?.teacher_id, academicId?.subject).then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            fetchExamSubjectList(details?.teacher_id);
            closeModalVisible();
            const changedData = academicId;
            changedData[keyValue] = [];
            setAcademicId(changedData);
          } else {
            toast("something went wrong");
          }
        }
      );
    } else if (keyValue === "examSubject") {
      DeleteExamSubject(details?.teacher_id, academicId?.examSubject).then(
        (res) => {
          if (res.status === 200) {
            toast(res.data.message);
            fetchExamSubjectList(details?.teacher_id);
            closeModalVisible();
            const changedData = academicId;
            changedData[keyValue] = [];
            setAcademicId(changedData);
          } else {
            toast("something went wrong");
          }
        }
      );
    } else {
      let payload = null;
      if (keyValue === "hodSubject") {
        payload = {
          subject: academicId.hodSubject,
        };
      } else {
        payload = {
          exam_subject: academicId.hodExamSubject,
        };
      }

      DeleteHodSubject(payload).then((res) => {
        if (res.status === 200) {
          toast(res.data.message);
          fetchHodSubjectList();
          closeModalVisible();
        } else {
          toast("something went wrong");
        }
      });
    }
  };

  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: "50%",
    height: "fit-content",
  };

  const closeModal = () => {
    setVisible(!visible);
  };

  const closeHodModal = () => {
    setHodVisible(false);
  };

  const closeModalVisible = () => {
    setModalVisible(false);
  };
  const isStateString = typeof data?.subject_list === "string";

  useEffect(() => {
    console.log("academicId", academicId);
  }, [academicId]);
  return (
    <div className="teacher-role-section">
      <Modal
        isOpen={visible}
        // onAfterOpen={}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Add Academics </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <ExamSubjectPopup closeModal={closeModal} />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={hodVisible}
        // onAfterOpen={}
        onRequestClose={closeHodModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Assign HOD Subject </p>
            </div>

            <div className="close-icon" onClick={closeHodModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <HodSubjectPopup closeModal={closeHodModal} />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={modalVisible}
        // onAfterOpen={}
        onRequestClose={closeModalVisible}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Teacher Role </p>
            </div>

            <div className="close-icon" onClick={closeModalVisible}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <p className="text-center">
              Are you sure you want to delete this subject ?
            </p>

            <div className="d-flex-center">
              <button
                className="btn btn-content custom-btn"
                onClick={() => {
                  handleDelete();
                }}
              >
                Yes
              </button>
              <button
                className=" btn btn-light mr-10"
                onClick={() => {
                  closeModalVisible();
                }}
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="header-flex-section" style={{ justifyContent: "end" }}>
        <button
          className="btn btn-content custom-btn"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setVisible(true);
          }}
        >
          <i className="bx bx-plus"></i> Add Academics
        </button>
      </div>

      <div className="d-flex" style={{ gap: "30px", alignItems: "initial" }}>
        <AcademicsTable data={data?.exam_subject_list} keyName="examSubject" />
        <AcademicsTable data={data?.subject_list} keyName="subject" />
      </div>

      {isHod && (
        <div
          className="header-flex-section"
          style={{
            justifyContent: "end",
            borderBottom: "1px solid #00000040",
            paddingBottom: "15px",
          }}
        >
          <button
            className="btn btn-content custom-btn"
            style={{ marginLeft: "auto" }}
            onClick={() => {
              setHodVisible(true);
            }}
          >
            <i className="bx bx-plus"></i> Assign HOD subject
          </button>
        </div>
      )}
      {isHod && (
        <div className="d-flex" style={{ gap: "30px", alignItems: "initial" }}>
          <AcademicsTable
            data={hodData?.exam_subject_list}
            keyName="hodExamSubject"
          />
          <AcademicsTable data={hodData?.subject_list} keyName="hodSubject" />
        </div>
      )}
    </div>
  );
};
