import React from "react";
import TotalCard from "../../components/TotalCard/TotalCard";
import GenderChart from "../../components/GenderChart/GenderChart";
import { AssignmentPage } from "../../components/AssignmentPage/AssignmentPage";
import { EventBarPage } from "../../components/EventBarPage/EventBarPage";
import BarChart from "../../components/BarChart/BarChart";
import { LeaveNote } from "../../components/LeaveNote/LeaveNote";
import Notices from "../../components/Notices/Notices";
import { Message } from "../../components/Message/Message";
import { Revenu } from "../../components/Revenu/Revenu";
import "../Dashboard/main.scss";
import { LeaveData } from "../../components/LeaveData/LeaveData";

export const Dashboard = ({ dashboardData: props }) => {
  return (
    <div
      className="dashboard-section-block"
      style={{ padding: "20px", background: "#f8f8f8" }}>
      <div className="">
        <div className="row">
          <div className="col-md-4 col-sm-12 p-10">
            <TotalCard data={props} />
            {/* <GenderChart data={props} /> */}
            <LeaveData />
          </div>
          <div className="col-md-4 col-sm-12 p-10">
            <AssignmentPage props={props} />
          </div>
          <div className="col-md-4 col-sm-12 p-10">
            <EventBarPage props={props} />
          </div>

          <div className="col-md-12 col-sm-12 p-10">
            <BarChart attendanceData={props?.attendanceData} />
          </div>

          <div className="col-md-6  col-sm-12 p-10">
            <LeaveNote />
          </div>
          <div className="col-md-6 col-sm-12 p-10">
            <Notices props={props} />
          </div>

          <div className="col-md-12  col-sm-12 p-10">
            <Message />
          </div>

          {/* {props?.admin === true ? (
            <div className="col-md-12 col-sm-12 p-10">
              <Revenu props={props?.admin} />
            </div>
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};
