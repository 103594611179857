import { useEffect, useState } from "react";
import img from "../../images/noImage.jpeg";
import { getGradeApi, getStudentInfo, getTeacherData } from "../Api/GetApi";
import "./studentImageAdd.scss";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { postStudentImage } from "../Api/PostApi";
import { toast } from "react-toastify";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import NividLoader from "../NividLoader/NividLoader";
import Select from "react-select";
import axios from "axios";
import { domain } from "../../url";

const StudentImageAdd = ({ showHeader }) => {
  const [studentList, setStudentList] = useState(null);
  const [showImage, setShowImage] = useState([]);
  const [isSubmitting, setSetIsSubmitting] = useState(false);
  const [loader, setLoader] = useState(true);
  const [gradeValue, setGradeValue] = useState();
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [admin, setAdmin] = useState();
  const [imageErrorList, setImageErrorList] = useState([]);

  useEffect(() => {
    getTeacherData().then((res) => {
      if (res.data === "You are not a teacher") {
        setAdmin(true);
      } else {
        const isAdmin = res?.data?.admin;
        setAdmin(isAdmin);
      }
    });
  }, []);

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const res = await getStudentInfo(selectedGrade, admin);
        if (res.status === 204) {
          return;
        }
        setStudentList(
          res?.data?.sort((a, b) => a.studentName.localeCompare(b.studentName))
        );
      } catch (error) {
        setStudentList([]);
        console.log(error);
      } finally {
        setLoader(false);
      }
    };

    if (selectedGrade) {
      fetchApi();
    }
  }, [selectedGrade]);

  useEffect(() => {
    if (admin) {
      getGradeApi().then((res) => {
        const gradeData = res.data;
        if (gradeData) {
          const gradeOption = gradeData?.grade?.map((item) => {
            return {
              value: item?.id,
              label: item?.classAlpha,
            };
          });
          setGradeValue(gradeOption);
        }
      });
    }
  }, [admin]);

  const handleDrop = (acceptedFiles, item) => {
    const values = {
      student_id: item.id,
      image: acceptedFiles[0],
    };
    setShowImage((prevShowImage) => [...prevShowImage, values]);
    console.log("showImage", showImage);
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    [...showImage].forEach((student, index) => {
      formData.append(`students[${index}][image]`, student.image);
      formData.append(`students[${index}][student_id]`, student.student_id);
    });
    try {
      setSetIsSubmitting(true);
      // const response = await postStudentImage(formData);
      // return axiosInstance.post(`${domain}/uploadStudentImage`, data);

      const response = await axios.post(
        `${domain}/uploadStudentImage`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.status === 1) {
        toast.success("Image uploaded successfully");
        setShowImage([]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      setStudentList([]);
      console.log(error);
      // toast.error("Something went wrong");
    }

    getStudentInfo().then((res) => {
      setStudentList(
        res?.data?.sort((a, b) => a.studentName.localeCompare(b.studentName))
      );
    });
    setSetIsSubmitting(false);
  };

  return (
    <div className="student-image-add-container">
      {showHeader && (
        <div className="title-block header">
          <h1 className="title">Upload Student Image</h1>
        </div>
      )}

      <div className="container-wrap">
        {admin && (
          <div className="header-section">
            <label className="d-block">Select Grade</label>
            <Select
              options={gradeValue}
              onChange={(e) => {
                setSelectedGrade(e.value);
              }}
            />
          </div>
        )}
        {loader ? (
          <div style={{ margin: "30px 0" }}>
            <NividLoader />
          </div>
        ) : (
          <div className="image-upload-container">
            {studentList?.length > 0 ? (
              <>
                {studentList?.map((item, index) => {
                  const filterItem = showImage?.filter(
                    (itm) => itm.student_id === item?.id
                  );

                  const firstIndex = filterItem && filterItem.length - 1;

                  return (
                    <div
                      key={index}
                      className={`img-field ${
                        studentList?.length - 1 === index && "last-child-margin"
                      }`}
                    >
                      <img
                        src={
                          imageErrorList?.includes(item.id)
                            ? img
                            : item.thumpImage
                        }
                        alt="student-img"
                        onError={() => {
                          setImageErrorList([...imageErrorList, item.id]);
                        }}
                      />
                      <div className="student-details">
                        <p className="block-title">{item.studentName}</p>
                        <div>
                          <span>{item.className}</span>
                          <span>Roll no: {item.rollNo}</span>
                        </div>
                      </div>

                      {filterItem?.[0] && (
                        <div style={{ position: "relative", margin: "auto 0" }}>
                          <img
                            className="selectedImg"
                            src={URL.createObjectURL(
                              filterItem?.[firstIndex]?.image
                            )}
                            alt="selected-img"
                          />
                          <div className="overlay"></div>
                        </div>
                      )}
                      <div style={{ margin: "auto 0" }}>
                        <Dropzone
                          accept=".jpg,.jpeg,.png"
                          multiple={false}
                          onDrop={(acceptedFiles) =>
                            handleDrop(acceptedFiles, item)
                          }
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps({ className: "dropzone" })}>
                              <input {...getInputProps()} />
                              <div className="drop-zone-area">
                                <button className="upload-btn">
                                  <CloudUploadIcon
                                    style={{ marginRight: "6px" }}
                                  />
                                  Upload
                                </button>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  );
                })}

                <div className="footer-block">
                  <button
                    className="upload-btn submit-btn"
                    style={{ width: "100%", marginTop: "5px" }}
                    onClick={() => handleSubmit()}
                  >
                    {isSubmitting ? "Uploading..." : "Submit"}
                  </button>
                </div>
              </>
            ) : (
              <NoDataContent
                title="No Students available!"
                description="No Data Found.."
                image={require("../../images/emptyfile.png").default}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StudentImageAdd;
