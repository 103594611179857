import React from "react";
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import "./AssesmentCard.scss"
import {useTeacherProfile} from "../../../../store/teacherProfileStore"

const AssesmentCard = ({cardData, setIsOpen}) => {
    const {setModalOpen,setSelectedModalData,setAssessmentModalData} = useTeacherProfile()
function modalOpen(){
  setAssessmentModalData(cardData)
setModalOpen(true)

}




  return (
    <div className="assesment_card_container">
      
      <div className="card_detail_section">
        <div className="icon_wrapper">
          <IoDocumentTextOutline color="#0E89CA" size={20} />
        </div>
        <div className="detail_section">
        <div className="detail_top_section">
        <div className="sub_class_wrapper">
            {`${cardData?.subjectName} - ${cardData?.className}`}
            </div>   
            <div className="classwork_homework_wrapper">
                {cardData?.description}
            </div>
        </div>
        <p className="date_section">{cardData?.assignmentDate}</p>
        

      </div>
    
      </div>
   

      <div onClick={()=>modalOpen()}  className="arrow_section">
      <FaArrowRightLong color="#1A1A1A" />
      </div>
    </div>
  );
};

export default AssesmentCard;
