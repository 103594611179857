import AreaChart from "../AreaChart/AreaChart";
import "./marks.scss";
import MarksTable from "../MarksTable/MarksTable";
import { useStudentStore } from "../../store/store";

const Marks = () => {
  const { student } = useStudentStore();
  return (
    <div className="marks-container">
      <AreaChart student={student} />
      <MarksTable student={student} />
    </div>
  );
};

export default Marks;
