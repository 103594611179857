import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import { outcome } from "../Api/PostApi";
import "./outcomeScheduling.scss";
import { getTeacherOutcome } from "../Api/GetApi";
function SchedulingOutcome({ visitorId, close }) {
  const validationSchema = Yup.object().shape({
    outcome: Yup.string().required("Outcome is required"),
  });
  const [outcomeDatas, setOutcomeDatas] = useState();
  const visitor_id = visitorId?.[0]?.visitor_id;
  useEffect(() => {
    fetchData();
  }, [visitor_id]);

  const fetchData = () => {
    getTeacherOutcome().then((res) => {
      const filteredData = res?.data?.data.filter(
        (item) => item.visitor_id === visitor_id
      );
      setOutcomeDatas(filteredData);
    });
  };
  const [initialValues, setInitialValues] = useState({
    visitor_id: visitor_id,
    outcome: "",
  });

  return (
    <div className="vistitors-outcome">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const outcomeData = {
            visitor_id: visitor_id,
            outcome: values.outcome,
          };
          console.log(outcomeData);
          outcome(outcomeData)
            .then((res) => {
              toast.success("Submitted successfully!");
              close();
            })
            .catch((error) => {
              toast.error("Submission failed");
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}>
        {({ isSubmitting, submitForm }) => (
          <Form>
            {!outcomeDatas?.[0]?.teacher_outcome ? (
              <>
                <h6 className="outcome-title">Outcome</h6>
                <Field className="textarea" name="outcome" as="textarea" />
                <ErrorMessage
                  name="outcome"
                  component="div"
                  className="error"
                />
                <button
                  type="submit"
                  className="primary-btns"
                  disabled={isSubmitting}
                  onClick={() => {
                    fetchData();
                    submitForm();
                    close();
                  }}>
                  Submit
                </button>
              </>
            ) : (
              <>
                <p className="visitor_outcome">
                  <span style={{ fontWeight: "600" }}>Outcome:</span>
                  {outcomeDatas?.[0]?.teacher_outcome}
                </p>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SchedulingOutcome;
