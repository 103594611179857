import React, { useState, useEffect } from "react";
import "./result.scss";
import { domain, jwtToken } from "../../url";
import "react-tabs/style/react-tabs.css";
import { LayoutPdf } from "./LayoutPdf";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useHistory } from "react-router-dom";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

const Result = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [initialValue, setInitialValue] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [studentDetails, setStudentDetails] = useState([]);
  const [pdfFile, setPdfFile] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [dataLoad, setDataLoad] = useState(true);

  useEffect(() => {
    fetchResultApi();
  }, []);

  const uniqueStudentData = {};

  if (data) {
    data?.forEach((item) => {
      item.student_data?.forEach((student) => {
        const name = student.studentName;
        if (!uniqueStudentData.hasOwnProperty(name)) {
          uniqueStudentData[name] = student.photo;
        }
      });
    });
  }

  useEffect(() => {
    if (initialValue) {
      const filteredStudentData = data
        ?.flatMap((exam) => exam.student_data)
        .filter((student) => student.studentName === initialValue);
      setFilteredData(filteredStudentData);
      setStudentDetails(filteredStudentData[0]);
    }
  }, [initialValue]);

  function getDataLoad() {
    setDataLoad(false);
  }

  const fetchResultApi = async () => {
    try {
      const api = `${domain}/mobile_result?token=${jwtToken}`;
      var res = await axiosInstance.get(api);
      setData(res.data);
      setInitialValue(res.data[0].student_data[0].studentName);
    } catch (err) {
      console.log("error", err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setSelectedItem(filteredData[0]);
    setDataLoad(true);
  }, []);

  var history = useHistory();

  const handleButtonClick = (componentId) => {
    setButtonDisabled(true);
    const input = document.querySelector("#" + componentId);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);

      // Convert the PDF document to a data URL
      // const pdfDataUri = pdf.output('datauristring');

      // Update state with the PDF data URL
      setPdfFile(imgData);
      setButtonDisabled(false);

      history.push({
        pathname: "/exam-layout",
        state: { imgPdf: imgData },
      });
    });
  };

  function handleClick() {
    // setButtonDisabled(true);
    setTimeout(() => {
      setButtonDisabled(false);
    }, 6000);
  }

  const changeStudent = (value) => {
    setInitialValue(value);
  };
  return (
    <div className="container">
      <>
        {/* <div className="title-block header center">
        <h2 className="title ">Result</h2>
      </div> */}

        {isLoading ? (
          <div className="pre-loader">
            <h4 className="content center">Loading...</h4>
          </div>
        ) : (
          <div>
            {/* {pdfFile ? (
           <div className= "pdf-show-page">
           
        <img width="fit-content !important" className="pdf-view" src={pdfFile} type="application/pdf"  height="1140px" />
        </div>

      ):<> */}

            {Array.isArray(data) ? (
              <div className="result-page">
                <div className="sticky-header">
                  <div className="student-header">
                    <div className="student-list-section">
                      {Object.keys(uniqueStudentData).map((name) => {
                        return (
                          <div key={name}>
                            <div
                              className={` img-details ${
                                initialValue === name ? "selected" : ""
                              }`}
                              onClick={() => {
                                changeStudent(name);
                              }}
                            >
                              {uniqueStudentData[name] ===
                              "https://storage.googleapis.com/thump_image/" ? (
                                <div>
                                  {" "}
                                  <p className="img-indicator ">
                                    {name ? name.charAt(0) : "U"}
                                  </p>
                                  <p className="student-name">
                                    {" "}
                                    {name.split(" ").map((word) => (
                                      <React.Fragment key={word}>
                                        {word}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </p>
                                </div>
                              ) : (
                                <div>
                                  <div>
                                    {" "}
                                    <img
                                      src={uniqueStudentData[name]}
                                      alt={name}
                                    />{" "}
                                  </div>

                                  <p className="student-name">
                                    {" "}
                                    {name.split(" ").map((word) => (
                                      <React.Fragment key={word}>
                                        {word}
                                        <br />
                                      </React.Fragment>
                                    ))}{" "}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  <div className="student-image ">
                    {studentDetails?.photo ===
                    "https://storage.googleapis.com/thump_image/" ? (
                      <div className="img-indicator">
                        {studentDetails?.studentName
                          ? studentDetails?.studentName.charAt(0)
                          : "U"}
                      </div>
                    ) : (
                      <img src={studentDetails?.photo} alt="img" />
                    )}

                    <div className="student-details">
                      <div className="text-init">
                        <h4 className="student-name mt-3">
                          Name:{" "}
                          <span>
                            {" "}
                            {studentDetails?.studentName
                              ? studentDetails?.studentName
                              : "Username"}
                          </span>
                        </h4>
                        <h5 className="class-name mt-1">
                          Class: <span>{studentDetails?.class_name}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                {filteredData?.map((item, index) => {
                  return (
                    <div key={index}>
                      <div className="result-contain-section">
                        <div className="width-calc">
                          <h1 className="exam-name ">
                            {item.exam_name.replace(/\(.*?\)/, "")}
                          </h1>
                          <p className="message ">
                            <span>{item?.message}</span>
                          </p>
                        </div>

                        <div>
                          <div className="single-result-card">
                            <div style={{ height: "0px", overflow: "hidden" }}>
                              <div id={`myComponentId${index}`}>
                                <LayoutPdf
                                  Layout={item?.url}
                                  grade={item?.gradeToken}
                                  exam={item?.exam_name}
                                  studentToken={item?.studentToken}
                                  layoutShow={item}
                                  data={getDataLoad}
                                />
                              </div>
                            </div>

                            <div className="message-section">
                              {item.url && item.url.length >= 1 ? (
                                <button
                                  className="main-btn"
                                  disabled={buttonDisabled}
                                  onClick={() => {
                                    setSelectedItem(item);
                                    handleButtonClick(`myComponentId${index}`);
                                  }}
                                >
                                  {dataLoad ||
                                  (selectedItem === item && buttonDisabled)
                                    ? "loading...."
                                    : "    View Result"}
                                </button>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          {/* <ResultCard item={item} /> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-center mt-10">{data}</p>
            )}
            {/* </>} */}
          </div>
        )}
      </>
    </div>
  );
};

export default Result;
