import ReactPlayer from "react-player";
import { NoDataContent } from "../../../components/NoDataContent/NoDataContent";
import { useState } from "react";

export const VedioList = ({ videos }) => {
  const [videoErrorIndexList, setVideoErrorIndexList] = useState([]);

  return (
    <div className="video-list">
      <>
        {videos ? (
          <>
            {videos?.map((item, index) => {
              return (
                <div className="video-block" key={index}>
                  <ReactPlayer
                    key={index}
                    url={item}
                    height={"100%"}
                    width={"100"}
                    onError={() => {
                      if (!videoErrorIndexList.includes(index)) {
                        setVideoErrorIndexList([...videoErrorIndexList, index]);
                      }
                    }}
                  />
                  {videoErrorIndexList?.includes(index) && (
                    <div className="video-error">
                      <h4 className="block-title">Fail to render</h4>
                      <p className="content"> {item}</p>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        ) : (
          <NoDataContent
            title="No Videos"
            image={require("../../../images/emptyfile.png").default}
            description={"There is no videos at the moment"}
          />
        )}
      </>
    </div>
  );
};
