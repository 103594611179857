import { create } from "zustand";

export const useAppointmentStore = create((set) => ({
  appointmentList: [],
  appointmentListTeacher: [],
  qrData: null,
  setAppointmentList: (appointmentList) => set({ appointmentList }),
  setAppointmentListTeacher: (appointmentListTeacher) =>
    set({ appointmentListTeacher }),

  setQrData: (val) => {
    set(() => ({ qrData: val }));
  },
}));
