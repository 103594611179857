import React, { useEffect, useState } from "react";
import "./TeacherProfile.scss";
import { TabPanelPage } from "../TabPanelPage/TabPanelPage";
import { BasicInformation } from "../BasicInformation/BasicInformation";
import { AttendanceSettingsTable } from "../AttendanceSettingTable/AttendanceSettingsTable";
import { TeacherRole } from "../TeacherRole/TeacherRole";
import { TeacherRoutine } from "../TeacherRoutine/TeacherRoutine";
import { TeacherRemark } from "../TeacherRemark/TeacherRemark";
import Modal from "react-modal";
import { useTeacherDetailsStore } from "../store/useStore";
import PreLoader from "../preLoader/preLoader";
import { domain, imgDomain } from "../../url";
import { postAdmin } from "../Api/PostApi";
import { toast } from "react-toastify";
import VisitorManagement from "../VisitorManagemnet/VisitorManagement";
import GridDate from "../Date/Date";
import { useTeacherProfile } from "../../store/teacherProfileStore";
import { ProxyData } from "../ProxyData/ProxyData";
import { RouteList } from "../RouteList/RouteList";

export default function TeacherProfile({ token }) {
  const [visible, setVisible] = useState();
  const { teacherDetails, data: details, loading } = useTeacherDetailsStore();
  const { isUserAdmin } = useTeacherProfile();

  const customStyles = {
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "50%",
    height: "fit-content",
  };

  useEffect(() => {
    if (token) {
      teacherDetails(token);
    }
    return () => {
      localStorage.removeItem("token");
    };
  }, [token]);

  const handleAdmin = () => {
    const values = {
      teacher_token: details.teacher_id,
    };
    postAdmin(values).then((res) => {
      if (res.status === 200) {
        toast(res.data.message);
        teacherDetails();
      } else {
        toast("something went wrong");
      }
    });
  };

  const remarkShow = true;
  const attendanceShow = true;
  const VisitorShow = true;
  const schedulingShow = true;
  const proxy = true;
  const tabData = [
    {
      label: "Basic Information",
      content: <BasicInformation data={details?.basic_information} />,
    },
    {
      label: "Academics",
      content: <TeacherRole data={details?.teacher_role} />,
    },
    {
      label: "Teacher's Routine",
      content: <TeacherRoutine data={details?.teacher_routine} />,
    },
    remarkShow && {
      label: "Remarks",
      content: <TeacherRemark data={details?.remarks} />,
    },
    attendanceShow && {
      label: "Attendance Log",
      content: <AttendanceSettingsTable singlePage={false} />,
    },
    {
      label: "Visitor Scheduling",
      content: <VisitorManagement />,
    },
    schedulingShow && {
      label: "Calendar",
      content: <GridDate />,
    },
    proxy && {
      label: "Proxy",
      content: <ProxyData />,
    },
    {
      label: "Route",
      content: <RouteList />,
    },
  ];

  const closeModal = () => {
    setVisible(!visible);
  };

  return (
    <div className="teacher-profile-section">
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                <i className="bx bxs-cog"></i>
              </div>
              <p className="modal-title">QR Code </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <div style={{ display: "flex", gap: "20px" }}>
              <img
                style={{ height: "150px", width: "150px" }}
                src={details?.src_for_qr}
                alt="qrcode"
              />

              <div className="">
                <h4 className="label-content mb-10 ">
                  PIN:
                  <span className="stepper-color"> {details?.pin}</span>
                </h4>
                <h4 className="label-content mb-10 ">
                  Teacher Name:
                  <span className="stepper-color">
                    {details?.basic_information?.teacher_name}
                  </span>
                </h4>
                <h4 className="label-content mb-10 ">
                  Designation:
                  <span className="stepper-color">
                    {details?.basic_information?.designation}
                  </span>
                </h4>
                <h4 className="label-content mb-10 ">
                  Teaching Level:
                  <span className="stepper-color">
                    {details?.basic_information?.teacher_level}
                  </span>
                </h4>
                <h4 className="label-content mb-10 ">
                  Contact Number:
                  <span className="stepper-color">
                    {details?.basic_information?.phone_number}
                  </span>
                </h4>
                <h4 className="label-content mb-10 ">
                  Class Teacher of:
                  <span className="stepper-color">
                    {details?.basic_information?.class_teacher}
                  </span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* {showHeader && (
        <div className="title-block header">
          <Link to="/">
            <i className="bx bxs-chevron-left"></i>
          </Link>
          <h2 className="title">Teacher Profile </h2>
        </div>
      )} */}
      {loading ? (
        <>
          <PreLoader />
        </>
      ) : (
        <div className="container">
          <div className="fixed-header-block ">
            <div className="top-btn-section">
              {details?.basic_information?.is_admin && isUserAdmin ? (
                <div
                  className="create-tag remove-tag"
                  onClick={() => {
                    handleAdmin();
                  }}
                  title="Remove Admin"
                >
                  <span>
                    <i class="bx bxs-user-x"></i> Remove Admin
                  </span>
                </div>
              ) : (
                <>
                  {isUserAdmin && (
                    <div
                      className="create-tag"
                      onClick={() => {
                        handleAdmin();
                      }}
                      title="Create Admin"
                    >
                      <>
                        <span>
                          <i class="bx bxs-user-check"></i> Create Admin
                        </span>
                      </>
                    </div>
                  )}
                </>
              )}
              <button
                className="btn btn-warning custom-btn"
                onClick={() => {
                  setVisible(true);
                }}
              >
                <i class="bx bx-qr-scan"></i> Login
              </button>

              <button
                className="btn btn-content custom-btn"
                onClick={() => {
                  try {
                    window.open(`${domain}/staff_tables/${details?.id}/edit`);
                  } catch (err) {
                    console.log("error", err);
                  }
                }}
              >
                <i class="bx bx-edit"></i> Edit
              </button>
            </div>

            <div className="row">
              <div className="col-md-8">
                <div className="teacher-info-block">
                  <div className="teacher-image">
                    {details?.basic_information?.image ? (
                      <img
                        src={`${imgDomain}${details?.basic_information?.image}`}
                        alt="teacher-img"
                      />
                    ) : (
                      <img
                        src={
                          require("../../images/carbon_user-avatar-filled.png")
                            .default
                        }
                        alt="teacher-img"
                      />
                    )}
                  </div>
                  <div className="teacher-details">
                    <h4 className="title-head text-capitalize">
                      {details?.basic_information?.teacher_name
                        ? details.basic_information.teacher_name
                        : "-"}
                    </h4>
                    <p className="middle-content text-capitalize">
                      {details?.basic_information?.designation
                        ? details?.basic_information?.designation
                        : "-"}
                    </p>
                    <div className="details-wrap">
                      <i class="bx bxs-phone"></i>
                      <p className="medium-title">
                        {details?.basic_information?.phone_number
                          ? details.basic_information?.phone_number
                          : "-"}
                      </p>
                    </div>

                    <div className="details-wrap">
                      <i class="bx bxs-map"></i>
                      <p className="medium-title">
                        {details?.basic_information?.address
                          ? details.basic_information?.address
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="student-status">
                  <div className="status-header">
                    <h4 className="middle-content status-title text-capitalize">
                      Today's Status
                    </h4>
                    {/* <div className="tag">Today</div> */}
                  </div>

                  <div
                    style={{ marginTop: "10px" }}
                    className="attendance-area"
                  >
                    <p className="description">Attendance</p>

                    {details?.basic_information?.present_today === "true" ? (
                      <div className="present-block block">IN</div>
                    ) : (
                      <div className="absent-block block">NOT In Yet</div>
                    )}
                    {/* <div class="attendance-block">
                    <span>Present</span>
                    <div className="switch-btn-block">
                      <div
                        className={`attendance-btn ${
                          details?.basic_information?.present_today ===
                            "true" && "active"
                        }`}
                      ></div>
                      <div
                        className={`attendance-btn ${
                          details?.basic_information?.present_today ===
                            "false" && "active"
                        }`}
                      ></div>
                    </div>
                    <span>Absent</span>
                  </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tab-section">
            <TabPanelPage tabData={tabData} />
          </div>
        </div>
      )}
    </div>
  );
}
