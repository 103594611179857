import React from "react";
import { AttendanceSettingsTable } from "../AttendanceSettingTable/AttendanceSettingsTable";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const AttendanceSinglePage = () => {
  return (
    <div className="attendance-single-page">
      <div className="container mt-20">
        <AttendanceSettingsTable singlePage={true} />
      </div>
    </div>
  );
};
