import React, { useEffect, useState, Suspense } from "react";
import "./dashboard.scss";
import HeaderCard from "../../components/headerCard/Card1";
import CashDetailRect from "../../components/cashDetailRect/CashDetailRect";
import PaymentCard from "../../components/pamentcard/PayementCard";
import ExpensesCard from "../../components/expensesCard/ExpensesCard";
import InventoryCard from "../../components/inventoryCard/InventoryCard";
import SalesDoughnutChart from "../../components/salesDoughnutChart/SalesDoughnutChart";
import SalesBarChart from "../../components/salesBarChart/SalesBarChart";
import ExpensesDoughnutChart from "../../components/expensesdoughnutchart/ExpensesDoughnutChart";
import CashLineChart from "../../components/CashLineChart/CashLineChart";
import "./index.scss";
// import 'bootstrap/dist/css/bootstrap.min.css';

import { useCashFlow, useTotalReceivable } from "../../store/accountDashboard";
import NepaliDate from "nepali-date";
import HeaderCardRight from "../../components/HeaderCardRight/HeaderCardRight";
import PreLoader from "../../components/preLoader/preLoader";

// const AccountDashboard = () => {
//   const [year, setYear] = useState("2080");
//   const {
//     receivable,
//     loading: recLoading,
//     fetchTotalReceivable,
//   } = useTotalReceivable();
//   const { cashFlow, loading: cashLoading, fetchCashFlow } = useCashFlow();

const AccountDashboard = () => {
  const nepaliDate = new NepaliDate();
  const nepYear = nepaliDate.getYear();

  const [year, setYear] = useState(nepYear);
  const [receivable, fetchTotalReceivable] = useTotalReceivable((state) => [
    state.receivable,
    state.fetchTotalReceivable,
  ]);
  const [cashFlow, fetchCashFlow] = useCashFlow((state) => [
    state.cashFlow,
    state.fetchCashFlow,
  ]);

  const date = cashFlow?.current_year;

  const nepaliCurrentDate = nepaliDate.format("MM-DD");

  useEffect(() => {
    fetchTotalReceivable();
  }, []);

  useEffect(() => {
    fetchCashFlow(year);
  }, [year]);
  console.log("cashFlow", cashFlow);

  const calculateAmount = (amount) => {
    if (amount) {
      console.log("amount", amount);
      let sub;
      if (amount?.startsWith("(") && amount?.endsWith(")")) {
        let amt;
        amt = ` ${Intl.NumberFormat("en-IN").format(
          parseFloat(amount.replace(/[()]/g, ""))
        )}`;
        sub = `Rs.(${amt})`;
        return sub;
      } else {
        sub = `Rs. ${Intl.NumberFormat("en-IN").format(parseFloat(amount))}`;
        return sub;
      }
    } else {
      return 0;
    }
  };
  return (
    <Suspense fallback={<PreLoader />}>
      <div className="account_dashboard_section">
        <div className="total_receivable_payment">
          <div className="left_content">
            <HeaderCard
              btnHead="New Income"
              main="Total Receivable"
              title="View Overdue Fee Payment"
              showAction={true}
              currentData={
                Intl.NumberFormat("en-IN").format(receivable?.current_due) ||
                "0.00"
              }
              overdueData={
                Intl.NumberFormat("en-IN").format(receivable?.overdue) || "0.00"
              }
              tableData={receivable.classwise_due || []}
              tableHeader={["S.N.", "Class", "Overdue Count", "Amount Due"]}
            />
          </div>
          <div className="right_content">
            <HeaderCardRight />
          </div>
        </div>

        <div className="cashflow_linechart">
          <div className="heading">
            <div className="title_main col-10">Cash Flow</div>
            <div>
              <select
                className="form-control"
                aria-label="Default select example"
                onChange={(e) => {
                  setYear(e.target.value);
                }}
              >
                <option value={date}>{date}</option>
                <option value={date - 1}>{date - 1}</option>
                <option value={date - 2}>{date - 2}</option>
              </select>
            </div>
          </div>
          <div className="content">
            <div className="content_left">
              <div className="cashflow_content_wrapper">
                <CashDetailRect
                  title={`Cash As on ${year}`}
                  sub={calculateAmount(cashFlow?.all_cash)}
                  backgroundColor="#E8F7F4"
                  border="1px solid #8ED8CA"
                />
              </div>

              <div className="cashflow_content_wrapper">
                <CashDetailRect
                  title="Cash in Hand"
                  sub={calculateAmount(cashFlow?.cash_at_hand)}
                  backgroundColor="#FDF7E9"
                  border="1px solid #F4C65A"
                />

                <div className="detail-list-block">
                  {/* <i class="bx bx-left-arrow"></i> */}

                  <table>
                    <thead>
                      <tr>
                        <th>User</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    {cashFlow?.cash_with_user?.length > 0 ? (
                      <tbody>
                        {cashFlow?.cash_with_user?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.username}</td>
                              <td>{calculateAmount(item?.cash_at_hand)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={2} className="text-center">
                            No data found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
              <div className="cashflow_content_wrapper">
                <CashDetailRect
                  title="Cash At Bank"
                  sub={calculateAmount(cashFlow?.cash_at_bank)}
                  backgroundColor="#EDF7FB"
                  border="1px solid #008AC5"
                />

                <div className="detail-list-block">
                  {/* <i class="bx bx-left-arrow"></i> */}

                  <table>
                    <thead>
                      <tr>
                        <th>Bank Name</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    {cashFlow?.bank_cash_with_bank?.length > 0 ? (
                      <tbody>
                        {cashFlow?.bank_cash_with_bank?.map((item, index) => {
                          return (
                            <tr>
                              <td>{item?.ledgerName}</td>
                              <td>{calculateAmount(item?.cash_at_bank)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td colSpan={2} className="text-center">
                            No data found
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            <div className="content_right">
              <CashLineChart />
            </div>
          </div>
        </div>

        <div className="payment_expenses">
          <div className="payment">
            <PaymentCard />
          </div>
          <div className="expenses">
            <ExpensesCard />
          </div>
        </div>
        <div className="inventory_sales">
          <div className="left_content">
            <InventoryCard />
          </div>

          <div className="right_content">
            <SalesDoughnutChart />
          </div>
        </div>

        <div className="barchartsales_expenses">
          <div className="left_content">
            <SalesBarChart />
          </div>
          <div className="right_content">
            <ExpensesDoughnutChart />
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default AccountDashboard;
