import React, { Component } from "react";
// import FeatherIcon from "feather-icons-react";
import Goal from "./goal/Goal";
import "./addDetailsPage.scss";
import { Link } from "react-router-dom";
import { domain } from "../../url.js";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import PreLoader from "../preLoader/preLoader";
import PopUp from "../popUp/popUp";
import axiosInstance from "../AxiosInceptor/AxiosInstance.js";
class AddDetailsPage extends Component {
  state = {
    teacherPlan: [],
    planTypes: [],
    allplans: {},
    openSnackBar: false,
    snackMessge: "",
    snackAutoHideDuration: 1000,
    status: false,
    visible: false,
    modalWidth: "50%",
    isLoading: true,
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  getModalWidth = () => {
    var windowWidth = window.innerWidth;
    var modalWidth = "50%";
    if (windowWidth <= 320) {
      modalWidth = "90%";
    } else if (windowWidth <= 480) {
      modalWidth = "90%";
    }
    this.setState({
      modalWidth,
    });
  };

  dateCheck(selectedDate, dueDate, startDate) {
    if (
      new Date(selectedDate) >= new Date(startDate) &&
      new Date(selectedDate) <= new Date(dueDate)
    ) {
      return true;
    } else {
      return false;
    }
  }
  createPlan = async () => {
    let dataToPost = Object.keys(this.state.allplans).map(
      (plan) => this.state.allplans[plan]
    );
    var startDate = this.props.location.startDate;
    var dueDate = this.props.location.dueDate;
    var isFormDatesValid = true;
    dataToPost.forEach((item) => {
      if (!this.dateCheck(item.due_date, dueDate, startDate)) {
        isFormDatesValid = false;
      }
    });
    if (isFormDatesValid) {
      var postdata = {
        plans: {
          plan_name: this.props.location.title,
          grade_token: this.props.location.grade,
          subject_token: this.props.location.subject,
          start_date: this.props.location.startDate,
          end_date: this.props.location.dueDate,
        },
        details: dataToPost,
      };
      var { data } = await axiosInstance.post(
        `${domain}/teacher_api/plans`,
        postdata
      );

      if (data.status === 11) {
        this.props.history.push({
          pathname: "/teaching_plan",
          status: true,
          showSuccessSnackbar: true,
        });
      } else if (data.status === 500) {
        this.setState({
          openSnackBar: true,
          snackMessge: "Failed to submit",
        });
      }
    } else {
      this.setState({
        openSnackBar: true,
        snackMessge: "Please select dates between start and due date",
      });
    }
  };

  addPlanTab = (planType, index) => {
    let temallplans = this.state.allplans;
    temallplans[planType.name + index] = {
      plan_type_token: planType?.value,
      description: "",
      due_date: "",
    };
    this.setState({
      allplans: temallplans,
    });
  };

  removePlanTab = (planType, index) => {
    let temallplans = this.state.allplans;
    delete temallplans[planType.name + index];
    this.setState({
      allplans: temallplans,
    });
  };

  onDetailsChange = (value, key, inputType, planTypeToken) => {
    let temallplans = this.state.allplans;
    if (temallplans[key] === undefined) {
      temallplans[key] = {};
    }
    temallplans[key]["plan_type_token"] = planTypeToken;
    temallplans[key][inputType] = value;
    this.setState({
      allplans: temallplans,
    });
  };

  componentDidMount() {
    this.getModalWidth();
    this.fetchTeachingPlanApi();
  }

  fetchTeachingPlanApi = async () => {
    this.setState({
      isLoading: true,
    });
    var res = await axiosInstance.get(`${domain}/teacher_api/plan_types`);
    this.setState({
      planTypes: res.data,
      isLoading: false,
    });
  };

  render() {
    // const { details } = this.props.location;
    return (
      <>
        {this.state.isLoading ? (
          <PreLoader />
        ) : (
          <div className="plan-form ">
            <div className="add-details-page ">
              <div className="title-block header">
                <Link to="/new_plan">
                  <i className="bx bxs-chevron-left"></i>
                </Link>
                <h2 className="title">Add Details</h2>
              </div>
              <div className="section-gap">
                <div className="container">
                  <div className="goal block-gap">
                    {this.state.planTypes.map((item, index) => {
                      return (
                        <Goal
                          key={index}
                          goalIndex={index}
                          type={item}
                          onDetailsChange={this.onDetailsChange}
                          allplans={this.state.allplans}
                          addPlanTab={this.addPlanTab}
                          endDate={this.state.endDate}
                          removePlanTab={this.removePlanTab}
                        />
                      );
                    })}

                    <hr />
                  </div>
                  <Snackbar
                    open={this.state.openSnackBar}
                    autoHideDuration={3000}
                    onClose={() => this.setState({ openSnackBar: false })}
                  >
                    <Alert severity="error">{this.state.snackMessge}</Alert>
                  </Snackbar>

                  {/* <button className="main-btn" onClick={this.createPlan}>
                Create Plan
              </button> */}

                  <button className="main-btn" onClick={() => this.openModal()}>
                    Create Plan
                  </button>

                  <PopUp
                    visible={this.state.visible}
                    closeModal={() => this.closeModal()}
                    title="Create Plan"
                    post={this.createPlan}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default AddDetailsPage;
