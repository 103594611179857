import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { getRouteList } from "../Api/GetApi";
import Select from "react-select";
import { postRouteselect } from "../Api/PostApi";
import { toast } from "react-toastify";
import { DeleteRouteList } from "../Api/DeleteApi";
import { useviewRoute } from "../store/useStore";

export const RouteList = () => {
  const [visible, setVisible] = useState();
  const [modalvisible, setModalVisible] = useState();
  const [selectedId, setSelectedId] = useState();
  const [selectedList, setSelectedList] = useState();

  const [routes, setRoutes] = useState();
  const [loader, setLoader] = useState(false);
  const { fetchViewsRoute, viewData } = useviewRoute();
  useEffect(() => {
    fetchViewsRoute();
    getRouteList().then((res) => {
      const data = res.data?.map((item) => {
        return {
          value: item.id,
          label: item.view_name,
        };
      });

      setRoutes(data);
    });
  }, []);

  const customStyles = {
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "50%",
    height: "fit-content",
  };

  const closeModal = () => {
    setVisible(false);
  };

  const handleSubmit = async () => {
    setLoader(true);
    const selectedArr = selectedList?.map((item) => item?.value);

    const data = {
      view_token: selectedArr,
    };
    try {
      const res = await postRouteselect(data);
      if (res.status === 200) {
        toast("Added Successfully");
        closeModal();
        fetchViewsRoute();
      } else {
        toast("something went wrong");
      }
    } catch {
      toast("something went wrong");
    } finally {
      setLoader(false);
    }
  };

  const handleDelete = async () => {
    const data = {
      view_token: selectedId,
    };
    try {
      const response = await DeleteRouteList(data);
      if (response.status === 200) {
        toast("Deleted successfully");
        fetchViewsRoute();
        setModalVisible(false);
      } else {
        toast("something went wrong");
      }
    } catch {
      toast("something went wrong");
    }
  };

  return (
    <div className="route-list-section">
      <Modal
        isOpen={modalvisible}
        // onAfterOpen={}
        onRequestClose={() => {
          setModalVisible(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title"> Route list </p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setModalVisible(false);
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <p className="text-center">
              Are you sure you want to delete this route list ?
            </p>

            <div className="d-flex-center">
              <button
                className="btn btn-content custom-btn"
                onClick={() => {
                  handleDelete();
                }}
              >
                Yes
              </button>
              <button
                className=" btn btn-light mr-10"
                onClick={() => {
                  setModalVisible(false);
                }}
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div>
              <p className="modal-title">Assign Routes </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <div className="form-group">
              <label>Select Routes</label>
              <Select
                isMulti
                options={routes}
                onChange={(option) => {
                  setSelectedList(option);
                }}
              />
            </div>

            <div className="footer-btn-section end-block mt-20">
              <button className=" btn cancel-btn mr-10" onClick={closeModal}>
                Cancel
              </button>
              <button
                className={`btn btn-content custom-btn ${
                  loader && "disable-cursor"
                }`}
                disabled={loader}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loader ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="header-flex-section" style={{ justifyContent: "end" }}>
        <button
          className="btn btn-content custom-btn"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setVisible(true);
          }}
        >
          <i className="bx bx-plus"></i> Assign Routes
        </button>
      </div>

      <div className="d-flex" style={{ gap: "30px", alignItems: "initial" }}>
        <div className="table-data-section" style={{ width: "50%" }}>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th className="text-center">S.N </th>
                <th>Route Name</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>

            {viewData?.length > 0 ? (
              <tbody>
                {viewData?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{item?.view_name}</td>
                      <td className="text-center">
                        <button
                          className="action-btn action-delete-btn"
                          onClick={() => {
                            setModalVisible(true);
                            setSelectedId(item?.id);
                          }}
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <td
                  className="text-center"
                  colSpan={3}
                  style={{ padding: "15px" }}
                >
                  No data added yet
                </td>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
