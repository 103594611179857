import React, { useEffect, useState } from "react";
import "./AccountDateFilter.scss";
import { toast } from "react-toastify";
import { NepDatePicker } from "neps-date-picker";
import { RxReset } from "react-icons/rx";


const AccountDateFilter = ({ show, setShow, getFromDate, getToDate }) => {
  const [prevDate, setPrevDate] = useState();
  const [nextDate, setNextDate] = useState();
  const [resetDate,setResetDate] = useState(false)

  function handleSubmit() {
    // if (prevDate && nextDate) {
      getFromDate(prevDate);
      getToDate(nextDate);
      setShow(false);
    // } 
    // else {
    //   toast("Please select both dates.");
    // }
  }
  const reset=()=>{
    setResetDate(!resetDate);
    getFromDate("")
    getToDate("")
    setShow(false)
  }

  function cancel() {
    setShow(false);
  }

  return (
    <div className={`date_filter_container ${!show && "d-none"}`}>
      <div className="top-section">
      <p className="date-title">Select date</p>
      <div onClick={()=> reset()} className="reset-btn"> <RxReset size={12} /> reset</div>


      </div>
      <div className="select-content">
        <div className="date-container-wrap">
          <label>From:</label>

          <NepDatePicker
            reset={resetDate}
            onDateSelect={(val) => {
              return setPrevDate(val);
            }}
          />
        </div>

        <div className="date-container-wrap">
          <label>To:</label>

          <NepDatePicker   
          reset={resetDate}
            onDateSelect={(val) => {
              return setNextDate(val);
            }}
          />
        </div>
      </div>
   
      <div className="btn-footer">
        <button onClick={() => cancel()} className=" btn-default">
          Cancel
        </button>
        <button onClick={() => handleSubmit()} className=" btn-content">
          Submit
        </button>
      </div>
      
    </div>
  );
};

export default AccountDateFilter;
