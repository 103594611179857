import React, { useEffect, useState } from "react";
import Table1 from "../paymenttable/PaymentTable";

import "./paymentcard.scss";
import RecordBtn from "../recordTransactionBtn/RecordBtn";
import { useRecentPayment } from "../../store/accountDashboard";
import { domain } from "../../url";
import Select from "react-select";

import { LuSettings2 } from "react-icons/lu";
import AccountDateFilter from "../AccountDateFilter/AccountDateFilter";

const PaymentCard = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [gradeToken, setGradeToken] = useState({
    value: "",
    label: "Select Grade",
  });

  const { loading, payment, fetchPayment } = useRecentPayment();

  const [gradeOptions, setGradeOptions] = useState([]);
  const apiGradeList = payment?.grade_list;

  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    (async () => {
      const selectGradeList = await apiGradeList?.map((item, index) => {
        return { value: item.id, label: item.class_name };
      });

      await selectGradeList.unshift({
        value: "",
        label: "All Grade",
      });

      setGradeOptions(selectGradeList);
    })();
  }, [apiGradeList]);

  useEffect(() => {
    fetchPayment(fromDate, toDate, gradeToken?.value);
  }, [fromDate, toDate, gradeToken?.value]);

  return (
    <div className="card2_container">
      <div className="heading">
        <div className="title_main">Recent Receipts</div>
        <div
          onClick={() => {
            window.open(`${domain}/feePayment`);
          }}
        >
          <RecordBtn name="Fee Transaction" />
        </div>
      </div>
      <div className="sub_heading">
        <div>
          <Select
            value={gradeToken}
            options={gradeOptions || []}
            onChange={(e) => {
              setGradeToken(e);
            }}
          />
        </div>
        <div className="sub_heading_date">
          <div title="Date Filter" className="date_filter_wrapper">
            <LuSettings2
              size={24}
              color="#0E89CA"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            />

            <AccountDateFilter
              show={showFilter}
              setShow={setShowFilter}
              getFromDate={(value) => {
                setFromDate(value);
              }}
              getToDate={(value) => {
                setToDate(value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Table1 from={fromDate} to={toDate} gradeToken={gradeToken?.value} />
      </div>
    </div>
  );
};

export default PaymentCard;
