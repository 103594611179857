import React from "react";
import { Link, withRouter } from "react-router-dom";
import "../timeline.scss";
import "./singlePlanCard.scss";
import NepaliDate from "nepali-date";

import { domain } from "../../../../../url";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { NoDataContent } from "../../../../NoDataContent/NoDataContent";
import axiosInstance from "../../../../AxiosInceptor/AxiosInstance";

class SinglePlanCard extends React.Component {
  state = {
    isToggleOn: false,
    comment: "",
    planDetailId: "",
    groupCode: 0,
    snackMessge: "",
    openSnackBar: false,
    snackType: "",
    data: [],
  };

  componentDidMount() {
    this.getPlanComment();
  }

  showAction = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  getDueDate = (props) => {
    const dueDate = new NepaliDate(props);
    const nepaliDueDate = dueDate.format("mmmm d, yyyy ");
    return nepaliDueDate;
  };

  getStartDate = (props) => {
    const startDate = new NepaliDate(props);
    const nepaliStartDate = startDate.format("mmmm d, yyyy");
    return nepaliStartDate;
  };

  onComment = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  getPlanComment = async () => {
    var res = await axiosInstance.get(
      `${domain}/teacher_api/remarks/${this.props?.location?.planTypeID}`
    );
    this.setState({
      data: res.data,
    });
  };

  postComment = async () => {
    var postData = {
      comment: this.state.comment,
      plan_detail_id: this.props.location.planTypeID,
      group_code: this.state.groupCode,
    };

    var { data } = await axiosInstance.post(
      `${domain}/teacher_api/plan_comments`,
      postData
    );
    if (data.status === 11) {
      this.setState({
        openSnackBar: true,
        snackMessge: "Successfully submited",
        snackType: "success",
        comment: "",
      });
      this.getPlanComment();
    } else {
      this.setState({
        openSnackBar: true,
        snackMessge: "Failed",
        snackType: "error",
      });
    }
  };

  render() {
    const details = this.props.location;
    return (
      <div className="single-plan-card">
        {details?.state ? (
          <div className="timeline ">
            <div className="title-block header">
              <Link
                to={{
                  pathname: "/timeline",
                  state: this.props?.location?.state,
                }}
              >
                <i class="bx bxs-chevron-left"></i>
              </Link>
              <h1 className="title">{this.props?.location.state?.planName}</h1>
              {/* <div className="end-block plan-icon">
              <div className="edit-icon">
                <i class="bx bx-edit-alt"></i>
              </div>
              <div className="delete-icon">
                <i class="bx bx-trash"></i>
              </div>
            </div> */}
            </div>
            <div className="section-gap">
              <div className="container">
                <div className="timeline-section">
                  <div className="timeline-card">
                    <div className="timeline-top">
                      <div className="card-title-block">
                        <p
                          className={`card-title ${
                            details.planTypeID === "1"
                              ? "blue"
                              : details.planTypeID === "2"
                              ? "pink"
                              : details.planTypeID === "3"
                              ? "dark-green"
                              : "red"
                          }`}
                        >
                          {details.planType}
                        </p>
                        {details.overDue === "true" ? (
                          <p className="card-title red">Overdue</p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        onClick={this.showAction}
                        className="action-top-icon"
                      >
                        <i class="bx bx-trash icon-bg"></i>
                      </div>
                      {/* {this.state.isToggleOn ? (
                      <div className="action-block">
                        <p className="action-content">Edit</p>
                        <p className="action-content">Delete</p>
                      </div>
                    ) : (
                      ""
                    )} */}
                    </div>
                    <div className="date-content-block">
                      <i class="bx bx-calendar"></i>
                      <p className="date-content">
                        {this.getStartDate(details?.startDate)} -{" "}
                        {this.getDueDate(details?.dueDate)}
                      </p>
                    </div>
                    <p className="block-content">{details.description}</p>
                    <div className="timeline-comment">
                      <p className="block-content">Comment (1)</p>
                    </div>
                    <div className="comment-section">
                      <div className="comment-type">
                        <div className="comment-image">
                          {details.imageName == null ? (
                            <div clasName="image-icon">
                              <AccountCircleIcon />
                            </div>
                          ) : (
                            <img src={details.imageName} alt="detailimage" />
                          )}
                        </div>
                        <textarea
                          className="input-field"
                          placeholder="Add a comment"
                          name="comment"
                          value={this.state.comment}
                          onChange={this.onComment}
                        />
                      </div>
                      <div className="end-block ">
                        <button
                          className="comment-btn"
                          onClick={this.postComment}
                        >
                          Post
                        </button>
                      </div>

                      {this.state.data.map((item, index) => {
                        return (
                          <div className="comment-block">
                            <div className="comment-image">
                              <img src={item.imageName} alt="comentimage" />
                            </div>
                            <div className="comment-area">
                              <div className="comment-content-block">
                                <h2 className="front-content">
                                  {item.teacherName}
                                </h2>
                                <p className="block-content">{item.comment}</p>
                              </div>
                              <div className="date-content-block">
                                <p className="date-content">
                                  {this.getStartDate(item.miti)}
                                </p>
                                {/* <div className="comment-reply-number">
                                <div className="dot"></div>
                                <p className="date-content "> Reply (1)</p>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {/* <div className="comment-block">
                      <div className="comment-image">
                        <img src="images/t1.jpg" />
                      </div>
                      <div className="comment-area">
                        <div className="comment-content-block">
                          <h2 className="front-content">Head of Director</h2>
                          <p className="block-content">
                            You won’t be able to complete it in time. Please
                            extend your deadline. Also, this will eventually
                            progress the subject.
                          </p>
                        </div>
                        <div className="date-content-block">
                          <p className="date-content">Feb 20, 2021</p>
                          <div className="comment-reply-number">
                            <div className="dot"></div>
                            <p className="date-content "> Reply (1)</p>
                          </div>
                        </div>
                        <div className="comment-reply">
                          <div className="comment-block">
                            <div className="comment-image">
                              <img src="images/t1.jpg" />
                            </div>
                            <div className="comment-area">
                              <div className="comment-content-block">
                                <h2 className="front-content">
                                  Head of Director
                                </h2>
                                <p className="block-content">
                                  You won’t be able to complete it in time.
                                  Please extend your deadline. Also, this will
                                  eventually progress the subject.
                                </p>
                              </div>
                              <div className="date-content-block">
                                <p className="date-content">Feb 20, 2021</p>
                                <div className="comment-reply-number">
                                  <div className="dot"></div>
                                  <p className="date-content "> Reply (1)</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              onClose={() => this.setState({ openSnackBar: false })}
            >
              <Alert severity={this.state.snackType}>
                {this.state.snackMessge}
              </Alert>
            </Snackbar>
          </div>
        ) : (
          <NoDataContent
            title="No single plan Recorded Yet"
            description={
              "Looks like there are no single plan recorded yet. Add single plan  for each students to appear here"
            }
          />
        )}
      </div>
    );
  }
}

export default withRouter(SinglePlanCard);
