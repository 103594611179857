import React, { useState } from "react";

import "./paymenttable.scss";
import ViewAll from "../AccountDashboardViewBtn/ViewAll";

import { useRecentPayment } from "../../store/accountDashboard";
import { toast } from "react-toastify";

import { useHistory } from "react-router-dom";

import { NoDataContent } from "../NoDataContent/NoDataContent";
import { domain } from "../../url";
import NividLoader from "../NividLoader/NividLoader";

const PaymentTable = ({ from, to, gradeToken }) => {
  const headers = [
    "ROLL NO.",
    "STUDENT NAME",
    "TRANSACTION NO.",
    "DATE",
    "DISCOUNT",
    "AMOUNT PAID",
  ];

  const { payment, loading } = useRecentPayment();
  const navigate = useHistory();

  const paymentObj =
    payment?.recent_fee_payment && Object.values(payment?.recent_fee_payment);

  if (loading) {
    return (
      <div>
        <NividLoader height={"380px"} />
      </div>
    );
  }

  const showData = paymentObj?.slice(0, 5);

  return (
    <div className="payment_table">
      <div
        style={{
          height: "350px",
          overflowY: "auto",
          border: "1px solid #EEEEEE",
        }}
      >
        <table>
          <thead>
            <tr>
              {headers.map((head) => {
                return <th key={head}>{head}</th>;
              })}
            </tr>
          </thead>
          {showData?.length >= 1 ? (
            <tbody>
              {showData?.map((d, index) => {
                return (
                  <tr key={index}>
                    <td>{d.rollNo}</td>
                    <td>
                      <div className="student_name_wrapper">
                        <div className="img_container">
                          {d.imageName === null ? (
                            <img src={d?.imageName} alt="student_img" />
                          ) : (
                            <div className="no_img_filler">
                              {d?.studentName?.charAt(0)}{" "}
                            </div>
                          )}
                        </div>
                        <div className="name_container">
                          {d.studentName}
                          <span className="class">Class: {d?.class_name}</span>
                        </div>
                      </div>
                    </td>
                    <td
                      onDoubleClick={() => {
                        const values = {
                          name: "transaction",
                          document: d?.documentNo,
                        };
                        try {
                          window.parent.postMessage(values, `${domain}`);
                        } catch (err) {
                          console.log("error", err);
                        }
                      }}
                    >
                      {d?.documentNo}
                    </td>
                    <td>{d?.miti}</td>
                    <td>
                      Rs. {Intl.NumberFormat("en-IN").format(d?.discount)}
                    </td>
                    <td>
                      Rs. {Intl.NumberFormat("en-IN").format(d?.amount_paid)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody
              style={{
                width: "100%",
                height: "200px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <tr>
                <td
                  colSpan={headers.length}
                  style={{ borderBottom: "none", textAlign: "center" }}
                >
                  <NoDataContent
                    image={require("../../images/emptyfile.png").default}
                    title="No Data"
                    description="Couldn't find payment data at the moment"
                  />
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </div>

      {showData?.length >= 1 && (
        <div
          onClick={() => {
            if (to !== "" && from !== "") {
              window.open(
                `${domain}/daily_transcation_detail_report?utf8=✓&fromDayToken=${from}&toDayToken=${to}&type=rcp&commit=Show`
              );
            } else {
              toast("Please select both date.");
            }
          }}
          style={{ marginTop: "8px" }}
        >
          <ViewAll />
        </div>
      )}
    </div>
  );
};

export default PaymentTable;
