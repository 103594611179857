// @ts-nocheck
import { useEffect, useState } from "react";
import Axios from "axios";
import axiosInstance from "../../components/AxiosInceptor/AxiosInstance";
import consumer from "./cable";
import { domain } from "../../url";

export const useTeacherGroupChat = (groupId, uuid) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axiosInstance.get(
          `${domain}/api/v2/message_api/message_history?chatId=${groupId}`
        );
        setMessages(response.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    if (groupId) fetchMessages();
  }, [groupId]);

  const sendMessage = async (e, toToken, fromToken) => {
    e.preventDefault();

    var formData = new FormData();
    formData.append("toToken", toToken);
    formData.append("groupChat", "true");
    formData.append("content", newMessage);
    formData.append("chatId", groupId);
    formData.append("fromParentApp", "false");

    try {
      await axiosInstance.post(
        `${domain}/api/v2/message_api/post_message`,
        formData
      );
      setNewMessage("");
    } catch (error) {
      setError(error);
    }
  };
  useEffect(() => {
    if (uuid) {
      connectGroupChatWebSocket(uuid);
    }
    return () => {
      disconnectGroupChatWebSocket();
    };
  }, [uuid]);

  const connectGroupChatWebSocket = (uuid) => {
    consumer.subscriptions.create(
      {
        channel: "ChatChannel",
        uuid: uuid,
      },
      {
        connected() {
          console.log("Cable Connected.....");
        },
        received(data) {
          console.log("Received Data", data);
          if (data.type === "ping") {
            // Ignore ping messages
            return;
          }
          setMessages((prevMessages) => [...prevMessages, data]);
        },
        disconnected() {
          console.log("Cable Disconnected");
        },
      }
    );
  };
  const disconnectGroupChatWebSocket = () => {
    consumer.disconnect();
  };

  return {
    messages,
    newMessage,
    setNewMessage,
    loading,
    error,
    sendMessage,
    connectGroupChatWebSocket,
  };
};
