import React from "react";
import { AddPreferenceModal } from "../AddPreferenceModal/AddPreferenceModal";

export const PreferenceBlock = (data) => {
  return (
    <div className="preference-block">
      <div className="modal-details-section" style={{ minHeight: "480px" }}>
        <div className="modal-header">
          <div className="title-content">
            <div className="icon-block">
              <i className="bx bxs-cog"></i>
            </div>
            <p className="modal-title">Preferences </p>
          </div>
        </div>

        <div className="modal-body">
          <AddPreferenceModal
            assignmentToken={data?.assignmentToken}
            gradeId={data?.gradeToken}
            dashboard={true}
            showHeader={data?.showHeader}
          />
        </div>
      </div>
    </div>
  );
};
