import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom';
import Modal from 'react-modal';        
import AccordionPopup from '../AccountDashboardPopup/AcordionPopup';
import arrowRight from "../../images/arrow-right.svg";
import cross from "../../images/cross.svg";
import "./popupmodal.scss";         
import { useGradewiseReceivable, useTotalReceivable } from '../../store/accountDashboard';
import { isBrowser } from "react-device-detect";

const PopupModal = ({gradeToken,gradeName}) => {
  const [gradeId, setGradeId] = useState("");
  const {fetchGradeReceiable} = useGradewiseReceivable()


  useEffect(()=>{
if(gradeId){
  fetchGradeReceiable(gradeId)


}


  },[gradeId])
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          width:isBrowser ? '50%': "95%",
          height:"70%",
          boxShadow: "0px 4px 8px 0px #0000000F",
          border:"1px solid transparent"
        },
      };
      let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
   
  function openModal(id) {
    setIsOpen(true);
    setGradeId(id)
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
   <div>
    <div  className='blue flex' onClick={()=>openModal(gradeToken)}>View <img src={arrowRight} alt='arrow_right'></img></div>
    <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className='popupmodal_container'>

          <div className="heading">
            <div className="title_main">
            {`Overdue Fee of Class ${gradeName} `}
            </div>
            <div style={{cursor:"pointer"}} onClick={closeModal}><img src={cross} alt="closebtn" /></div>
          </div>
          <div>
          <AccordionPopup/>

          </div>

      
       
       



        </div>
      
    

        </Modal>
   </div>
  )
}

export default PopupModal