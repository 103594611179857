import React, { useEffect, useState } from "react";
import "./ChildProfile.scss";
import { getChildData } from "../Api/GetApi";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { toast } from "react-toastify";
import StudentProfilePage from "../../pages/StudentProfilePage/StudentProfilePage";
import { useStudentId } from "../../store/store";
import NividLoader from "../NividLoader/NividLoader";

const ChildProfile = () => {
  const [childData, setChildData] = useState([]);
  const [activeStudentToken, setActiveStudentToken] = useState("");

  const handleTabChange = (index) => {
    // Update activeStudentToken when tab changes
    setActiveStudentToken(childData[index].studentToken);
  };
  const { setManualAttendance } = useStudentId();
  useEffect(() => {
    setManualAttendance(false);
    const fetchChildData = async () => {
      try {
        const res = await getChildData();
        if (res.status === 200) {
          const resData = res?.data;
          setChildData(resData);
          setActiveStudentToken(resData[0]?.studentToken || "");
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchChildData();
  }, []);

  return (
    <div className="childProfile_container">
      {/* <div className="title-block header">
        <h1 className="title">Children Profile</h1>
      </div> */}
      <div className="childProfile_wrapper">
        {childData?.length > 0 ? (
          <Tabs onSelect={(index) => handleTabChange(index)}>
            {childData?.map((item, index) => (
              <Tab
                key={index}
                title={item?.studentName}
                label={item?.studentName}
                isActive={item?.studentToken === activeStudentToken}
              >
                <StudentProfilePage childId={item?.studentToken} />
              </Tab>
            ))}
          </Tabs>
        ) : (
          <NoDataContent
            marginTop="100px"
            image={require("../../images/emptyfile.png").default}
            title="No children Found"
            description={"Confirm that you have atleast one child"}
          />
        )}
      </div>
    </div>
  );
};

export default ChildProfile;
