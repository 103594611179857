import React, { useEffect, useState } from "react";
import "./salesbarchart.scss";
import Chart from "react-apexcharts";
import { useIncomeExpensesChart } from "../../store/accountDashboard";

import { NoDataContent } from "../NoDataContent/NoDataContent";
import NividLoader from "../NividLoader/NividLoader";
import NepaliDate from "nepali-date-converter";

const SalesBarChart = () => {
  const nepaliDate = new NepaliDate();
  const nepYear = nepaliDate.getYear();

  const { incomeExpensesChart, loading, fetchIncomeExpensesChart } =
    useIncomeExpensesChart();
  const currentYear = incomeExpensesChart?.current_year;

  const [year, setYear] = useState(nepYear);
  const income = incomeExpensesChart?.income_expenses?.map((a) => a.income);

  const expenses = incomeExpensesChart?.income_expenses?.map((a) => a.expenses);

  useEffect(() => {
    fetchIncomeExpensesChart(year);
  }, [year]);
  const series = [
    {
      name: "Income",
      data: income,
      color: "#53CB86",
    },
    {
      name: "Expenses",
      data: expenses,
      color: "#F5626B",
    },
  ];
  const options = {
    chart: {
      type: "bar",
      // height: 350
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "8",
        endingShape: "rounded",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Baisakh",
        "Jestha",
        "Asar",
        "Shrawan",
        "Badhra",
        "Asoj",
        "Kartik",
        "Mangsir",
        "Poush",
        "Magh",
        "Fagun",
        "Chaitra",
      ],
    },
    // yaxis: {
    //   title: {
    //     text: "Rs. (thousands)",
    //   },
    // },
    fill: {
      opacity: 1,
      colors: ["#5cb85c", "#d9534f"],
    },
    tooltip: {
      // y: {
      //   formatter: function (val) {
      //     return val + " K";
      //   },
      // },
    },
    legend: {
      show: true,
      position: "bottom",
    },
  };

  return (
    <div className="barchart_container">
      <div className="heading">
        <div className="title_main">Total Sales</div>
        <div>
          <select
            className="form-control"
            aria-label="Default select example"
            onChange={(e) => setYear(e.target.value)}
          >
            <option value={currentYear} selected>
              {currentYear}
            </option>
            <option value={currentYear - 1}>{currentYear - 1}</option>
            <option value={currentYear - 2}>{currentYear - 2}</option>
          </select>
        </div>
      </div>

      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              height: "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <NividLoader height={"325px"} />
          </div>
        ) : incomeExpensesChart?.income_expenses?.length >= 1 ? (
          <Chart series={series} type="bar" options={options} height={300} />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <NoDataContent
              image={require("../../images/emptyfile.png").default}
              title="No Data"
              description="Couldn't find sales data at the moment"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesBarChart;
