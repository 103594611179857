import React from "react";
import CanteenOrderMenu from "../CanteenOrderMenu/CanteenOrderMenu";
import CanteenOrderCart from "../CanteenOrderCart/CanteenOrderCart";
import "./CanteenOrderMain.scss";

const CanteenOrderMain = () => {
  return (
    <div className="Canteen_main_container">
      <div className="menu_container">
        <CanteenOrderMenu />
      </div>

      <div className="cart_container">
        <CanteenOrderCart />
      </div>
    </div>
  );
};

export default CanteenOrderMain;
