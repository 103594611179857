import { create } from "zustand";
import { getFoodMenuList } from "../../Api/GetApi";

export const useCanteenParentOrder = create((set) => ({
  foodMenu: [],
  activeStudentToken: null,
  setActiveStudentToken: (val) => set({ activeStudentToken: val }),
  selectedFoodCategory: null,
  setSelectedFoodCategory: (val) => set({ selectedFoodCategory: val }),
  studentFoodList: [],
  setStudentFoodList: (val) => set({ studentFoodList: val }),
  selectedFood: [],
  setSelectedFood: (val) => set({ selectedFood: val }),
  loading: false,
  todayMenuList: [],
  setTodayMenuList: (val) => set({ todayMenuList: val }),

  fetchFoodMenu: async () => {
    set({ loading: true });
    try {
      const res = await getFoodMenuList();
      if (res.status === 200) {
        const resData = await res?.data?.data;
        set({ foodMenu: resData, loading: false });
      } else {
        set({ loading: false });
      }
    } catch (err) {
      set({ loading: false });
    }
  },
}));
