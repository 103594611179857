import React from "react";
import "./card1.scss";
import btn from "../../images/btn_plus.svg"

import Acordion from "../AccountDashboardAccordion/Acordion";
import { domain } from "../../url";
const HeaderCard = ({main,showAction,title,btnHead,currentData,overdueData,tableHeader,tableData}) => {


  return (
    <div>
      <div className="card1_container">
        <div className="card_header">
          <h2>{main}</h2>
          <div>
            <button onClick={()=>{
              if(showAction===true){
                window.open(`${domain}/income`)
              }
            }} className="btn_1">
              <img width="16px" height="16px" src={btn} alt="btn_plus" />

              <p>{btnHead}</p>
              


            </button>
          </div>
        </div>
        <div className="card_body">
          <div className="card_body_top">
            <div className="card_body_content_top_left">
              <p>Current</p>
              <h3>Rs. {currentData || 0}</h3>
            </div>
           
            <div className="card_body_content_top_right ">
              <p>Overdue</p>
              <h3>Rs. {overdueData || 0}</h3>
            </div>
          </div>
          <div className="pipe_hor"></div>

          <div>
            <div className="card_body_bottom">
              <Acordion tableHeader={tableHeader} tableData = {tableData} showAction={showAction} title={title} />
            </div>
  
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderCard;
