import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { editDeviceAttendance } from "../Api/GetApi";
import { useAttendanceData, useTeacherDetailsStore } from "../store/useStore";
import { toast } from "react-toastify";
import * as Yup from "yup";

export const EditTimeLog = ({ closeModal, data, singlePage, month }) => {
  const { data: details, teacherDetails } = useTeacherDetailsStore();
  const { attendanceList } = useAttendanceData();
  const [btnLoad, setBtnLoad] = useState(false);

  useEffect(() => {
    if (singlePage) {
      teacherDetails();
    }
  }, []);
  return (
    <div className="edit-time-log">
      <Formik
        initialValues={{
          entrytime: data?.r_entry ? data?.r_entry : data?.entry,
          exittime: data?.r_exit ? data?.r_exit : data?.exit,
          remark: "",
        }}
        validationSchema={Yup.object().shape({
          entrytime: Yup.string().required("Please add Entry Time"),
          exittime: Yup.string().required("Please add Exit Time"),
          remark: Yup.string().required("Please enter your regularize remark "),
        })}
        onSubmit={async (values) => {
          const value = {
            id: parseInt(details.teacher_id),
            remark: values.remark,
            entry_time: values.entrytime,
            exit_time: values.exittime,
            date: data.date,
          };
          setBtnLoad(true);

          try {
            const res = await editDeviceAttendance(value);
            if (res.data.status === 200) {
              toast(res.data.message);
              closeModal();
              attendanceList(month);
            } else {
              toast(res.data.message);
            }
          } catch {
            toast("something went wrong");
          } finally {
            setBtnLoad(false);
          }
        }}
      >
        {({ setFieldValue }) => (
          <Form>
            <div class="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label>Entry Time</label>
                  <Field
                    name="entrytime"
                    className="form-control"
                    type="time"
                  />
                  <ErrorMessage
                    name="entrytime"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Exit Time</label>
                  <Field name="exittime" className="form-control" type="time" />
                  <ErrorMessage
                    name="exitTime"
                    component="div"
                    className="error"
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>Remark</label>
                  <Field
                    name="remark"
                    type="text"
                    className="form-control"
                    placeholder="Enter your Remark here"
                  />
                  <ErrorMessage
                    name="remark"
                    component="div"
                    className="error"
                  />
                </div>
              </div>
            </div>

            <div className="footer-btn-section end-block">
              <button
                type="button"
                className=" btn cancel-btn mr-10"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="btn btn-content custom-btn"
                type="submit"
                disabled={btnLoad}
              >
                {btnLoad ? "submitting..." : "Regularize"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
