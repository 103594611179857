export const SubCard = () => {
  return (
    <div className="sub-card">
      <div className="sub-card-top-section">
        <div className="sub-card-info-block">
          <img src={require("../../../../images/t1.jpg").default} alt="img" />
          <div className="sub-card-info">
            <div className="top-info">
              <p className="content">Kriti Tamang</p>
              <div className="tag-block">
                <div className="tag submit-tag">Submitted</div>
                <div className="tag complete-tag">
                  <i class="bx bxs-check-circle"></i>Completed
                </div>
              </div>
            </div>

            <div className="bottom-info">
              <p className="medium-content">
                Class: <span>Five ‘A’</span>
              </p>
              <div className="dot" />
              <p className="medium-content">
                Roll No.: <span> 3</span>
              </p>
            </div>
          </div>
        </div>
        <div className="rating">
          <img
            src={require("../../../../images/rating.png").default}
            alt="rating"
          />
          <p className="content">4.3</p>
        </div>
      </div>
      <p className="small-content">ReMarks</p>
      <p className="content">
        Amet, consectetur adipi scing elit, sed do eiusmod tempor incididunt ut
        labore
      </p>
    </div>
  );
};
