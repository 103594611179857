import React, { useState } from "react";
import Chart from "react-apexcharts";

export const DonutChart = () => {
  const [chartState] = useState({
    series: [44, 55, 41, 17],
    options: {
      chart: {
        type: "donut",
      },
      labels: ["Submitted 16", "Late 8", "Pending 11", "Tasks & Deadlines 8"],
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: "bottom",
      },
      plotOptions: {
        pie: {
          donut: {
            size: "80%",
            labels: {
              show: true,
            },
            name: {
              show: true,
              fontSize: "24px",
              color: "#000",
            },
            value: {
              show: true,
              fontSize: "24px",
              color: "#000",
            },
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 225,
            },
          },
        },
      ],
    },
  });

  return (
    <div className="donut-chart">
      <div id="donut-chart">
        <Chart
          options={chartState?.options}
          series={chartState?.series}
          type="donut"
          height={250}
        />
      </div>
    </div>
  );
};
