import ChartBar from "../ChartBar/ChartBar";
import InformationField from "../InformationField/InformationField";
import ParentField from "../ParentField/ParentField";
import "./basicInformationstudent.scss";

const BasicInformationStudent = ({childId}) => {
  return (
    <div className="basic-info-container">
      <div className="topDiv">
        {/* <InformationField />
        <ChartBar /> */}
      </div>
      <ParentField childId={childId} />
    </div>
  );
};

export default BasicInformationStudent;
