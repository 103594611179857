import "nepali-datepicker-reactjs/dist/index.css";
import { NepDatePicker } from "neps-date-picker";
import React, { useEffect, useState } from "react";
import Earmark from "../../images/bi_file-earmark-text.svg";
import "./Teacher.scss";
import { FaAngleRight, FaArrowRightLong } from "react-icons/fa6";
import { isBrowser } from "react-device-detect";
import { MdClear } from "react-icons/md";
import Modal from "react-modal";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import { useHodTeacherActivity } from "../../store/hodDashboardStore";
import { getClassAlpha, getGradeApi } from "../Api/GetApi";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import Homework from "../homework/Homework";
import NividLoader from "../NividLoader/NividLoader";

function Teacher() {
  const { popupType, setPopupType } = useTeacherDashboardStore();
  const [showAll, setShowAll] = useState(false);
  const [clearDate, setClearDate] = useState(false);

  const [selectedData, setSelectedData] = useState();
  const [data, setData] = useState([]);
  const [transformData, setTransformData] = useState([]);
  const [gradeData, setGradeData] = useState();
  const [select, setSelect] = useState("");
  const [date, setDate] = useState("");
  const [isVisible, setIsVisible] = useState(false); //to hide the view all button when nodata is found
  const { HodTeacherActivity, loading, fetchHodTeacherActivity } =
    useHodTeacherActivity();

  const customStyles = {
    overFlow: "scroll",
    width: isBrowser ? "80%" : "85%",
    height: isBrowser ? "80%" : "90%",
    display: "grid",
    marginLeft: "auto",
    marginRight: "auto",
  };

  useEffect(() => {
    if (select && date) {
      fetchHodTeacherActivity(select, date);
    }
  }, [select, date]);

  useEffect(() => {
    setData(HodTeacherActivity);
  }, [HodTeacherActivity]);

  useEffect(() => {
    // getGradeApi().then((response) => {
    //   setGradeData(response?.data?.grade);
    // });

    getClassAlpha().then((res) => {
      setGradeData(res?.data?.data);
    });
  }, []);

  function modifiedKeyValues(data) {
    //this function is used to given data into creating seperate object for classwork and homework
    const modifiedData = [];
    for (const obj of data) {
      const objWithHomework = { ...obj };
      const objWithClasswork = { ...obj };
      objWithClasswork.home_work = undefined;
      objWithHomework.class_work = undefined;
      modifiedData.push(objWithClasswork, objWithHomework);
    }

    return modifiedData;
  }

  useEffect(() => {
    if (data?.length > 0) {
      const modified = modifiedKeyValues(data);
      setTransformData(modified);
      setIsVisible(true);
    } else {
      setTransformData([]);
      setIsVisible(false);
    }
  }, [data]);

  const modifiedTransformData = showAll
    ? transformData
    : transformData?.slice(0, 4) || [];
  return (
    <>
      <div className="teacher_container">
        <Modal
          isOpen={popupType === "teacherActivity"}
          // onAfterOpen={}
          onRequestClose={() => {
            setPopupType("");
          }}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: customStyles,
          }}
          contentLabel="Example Modal"
        >
          <div className="modal-details-section">
            <div className="modal-header">
              <div className="title-content">
                {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}
                <p>
                  {selectedData?.subjectName} - Class{" "}
                  {selectedData?.grade_class_name}{" "}
                </p>
              </div>

              <div
                className="close-icon"
                onClick={() => {
                  setPopupType("");
                }}
              >
                <i class="bx bx-x"></i>
              </div>
            </div>
            <div>
              <Homework
                onClose={() => setPopupType("")}
                select={select}
                date={date}
                activityData={selectedData}
              />
            </div>
          </div>
        </Modal>
        <div className="big-box ">
          <div className="start-frame">
            <div className="start-frame-of d-flex">
              <p className="first-heading">Recent Teacher’s Activity</p>
              {/* <button className="start-button bg-white d-flex">
                <FaPlus />
                <p className="add-button">Add Teacher Remark</p>
              </button> */}
            </div>
            <div className="sec-frame d-flex mt-2">
              <div style={{ display: "flex", gap: "20px" }}>
                <select
                  style={{ width: "150px" }}
                  className="input-field"
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setSelect("");
                    } else {
                      const selectedGrade = gradeData?.find(
                        (grade) => grade.id === e.target.value
                      );
                      setSelect(selectedGrade?.id);
                    }
                  }}
                >
                  <option value="">Select Class</option>
                  {gradeData?.map((grade, index) => (
                    <option key={index}>{grade.name}</option>
                  ))}
                </select>
              </div>
              <div className="last-sel-layout d-flex justify-content-end">
                <div
                  title="Clear Date"
                  onClick={() => setClearDate(!clearDate)}
                  className="cross_btn"
                >
                  <MdClear size={12} />
                </div>
                <NepDatePicker
                  reset={clearDate}
                  onDateSelect={(val) => setDate(val)}
                />
              </div>
            </div>
          </div>
          <div className="last-frame-of-f">
            {loading ? (
              <div>
                <NividLoader height={"400px"} />{" "}
              </div>
            ) : modifiedTransformData?.length === 0 ? (
              <NoDataContent
                marginTop="100px"
                title="No data available"
                description="Data will be appeared when updated"
                image={require("../../images/emptyfile.png").default}
              />
            ) : (
              <>
                {modifiedTransformData?.map((s, index) => {
                  return (
                    <div key={index} className="start-layout mt-2">
                      <div className="sub-frame d-flex">
                        <div className="sub-start-frame d-flex">
                          <div className="fig-frame">
                            <img src={Earmark} className="imag"></img>
                          </div>
                          <div className="subj-frame subject-frame">
                            <span>{s.subjectName}</span>
                            <span className="ms-1">-</span>
                            <span className="ms-1">{s.grade_class_name}</span>
                            <p className="date-lay">{s.miti}</p>
                          </div>
                        </div>
                        <div className="arrow-frame">
                          <p
                            className="arrow"
                            type="button"
                            onClick={() => {
                              setSelectedData(s);
                              setPopupType("teacherActivity");
                            }}
                          >
                            <FaArrowRightLong />
                          </p>
                        </div>
                      </div>
                      <div className="long-line mt-2"></div>
                      <div className="last-frame-frame-of d-flex  mt-2">
                        <div className="first-f d-flex">
                          <div className="group-g">
                            <div className="image-group">
                              <p className="small-g">
                                {s?.teacherName?.charAt(0)}
                              </p>
                            </div>
                          </div>
                          <div className="name-group-frame">
                            <div className="group-text">
                              <p>
                                <span>{s.teacherName} •</span>
                                <span className="text-color">
                                  {" "}
                                  Class Teacher of
                                </span>
                                <span style={{ marginLeft: "4px" }}>
                                  {s.class_teacher_class_name}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="home-frame">
                          {s?.home_work === undefined ? (
                            <p className="home-text text-uppercase">
                              CLASSWORK
                            </p>
                          ) : (
                            <p className="home-text text-uppercase">HOMEWORK</p>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          {!showAll ? (
            <p
              type="button"
              className={`tt mt-3 ${isVisible ? "visible" : "hidden"}`}
              onClick={() => {
                setShowAll(!showAll);
              }}
            >
              {!showAll ? "View All" : "View Less"}
              <FaAngleRight className="ms-1" />
            </p>
          ) : (
            <p
              type="button"
              className="tt mt-3"
              onClick={() => {
                setShowAll(false);
              }}
            >
              Show Less
              <FaAngleRight className="ms-1" />
            </p>
          )}
        </div>
      </div>
    </>
  );
}
export default Teacher;
