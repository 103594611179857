import React from "react";
import "./PopUp.scss";

const PopUp = ({ onClose, title, description }) => {
  return (
    <div className="subject-popup">
      <div className="popup-section">
        <div className="popup-header">
          <div className="title">{title}</div>
          <button className="close-button" onClick={onClose}>
            <i class="bx bx-x"></i>
          </button>
        </div>
        <div className="description">{description}</div>
      </div>
    </div>
  );
};

export default PopUp;
