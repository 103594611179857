import { Field } from "formik";

export const TextField = ({ name, touched, ...rest }) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <textarea {...field} {...rest} className={`text-field`} />
      )}
    </Field>
  );
};
