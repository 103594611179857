import React, { useState } from "react";
import "./TeacherSchedule.scss";
import Select from "react-select";
import { useTeacherProfile } from "../../../store/teacherProfileStore";
import useFetchData from "../../../utils/useFetchData";
import { getTeacherPlanProfileSchedules } from "../../Api/GetApi";
import NividLoader from "../../NividLoader/NividLoader";
import { NoDataContent } from "../../NoDataContent/NoDataContent";
import Modal from "react-modal";
import { SinglePlanDetails } from "../../../pages/teachingPlanMain/singlePlanDetails";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const dateDropdown = [
  { label: "This Week", value: "this_week" },
  { label: "Last Week", value: "last_week" },
  { label: "Next Week", value: "next_year" },
];

const TeacherSchedule = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [itemGrade, setItemGrade] = useState();
  const [itemSubjectToken, setItemSubjectToken] = useState();
  const [itemMonth, setItemMonth] = useState();
  const [itemUnit, setItemUnit] = useState();

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  }

  function closeModal() {
    setIsOpen(false);
  }
  const [selectedWeek, setSelectedWeek] = useState(dateDropdown?.[0].value);

  const { selectedGrade, selectedSubject } = useTeacherProfile();

  const { data, loading, error } = useFetchData(
    getTeacherPlanProfileSchedules,
    selectedGrade,
    selectedSubject,
    selectedWeek
  );

  const tableHead = [
    { day: "Su", date: 5 },
    { day: "Mo", date: 6 },
    { day: "Tu", date: 7 },
    { day: "We", date: 8 },
    { day: "Th", date: 9 },
    { day: "Fr", date: 10 },
    { day: "Sa", date: 11 },
  ];

  function chapterClick() {}
  return (
    <div className="teacher_schedule_container component_wrapper ">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}
              <p className="modal-title">Lesson Plan Detail</p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                closeModal();
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>
          <SinglePlanDetails
            popup={true}
            selectedGrade={itemGrade}
            selectedSubject={itemSubjectToken}
            selectedMonth={itemMonth}
            selectedId={itemUnit}
            showHeader={false}
            closeModal={closeModal}
            removeEdit={false}
          />
        </div>
      </Modal>
      <div className="top_section">
        <h2 className="heading">Schedule</h2>
        <div className="dropdown_wrapper">
          <Select
            placeholder="Select Date"
            className="react-select"
            options={dateDropdown}
            defaultValue={dateDropdown?.[0]}
            onChange={(week) => setSelectedWeek(week.value)}
          />
        </div>
      </div>
      <div className="body_section">
        {loading ? (
          <NividLoader height="300px" />
        ) : (
          <table>
            <thead>
              <tr>
                <th></th>
                {tableHead?.map((item, index) => (
                  <th key={index}>
                    <div className="table_header_wrapper">
                      <div className="week_day">{item?.day}</div>
                      {/* <div className="date">{item?.date}</div> */}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.length === 0 ? (
                <NoDataContent
                  image={require("../../../images/emptyfile.png").default}
                  title="No Data Found"
                  description="make sure that date filter is selected"
                />
              ) : (
                data?.schedules?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="class_name">
                        <div className="class_detail">{item.class_name}</div>
                      </td>

                      {Object.keys(item)
                        .filter((a) => a !== "class_name")
                        .map((val, index) => {
                          return item[val] === null ? (
                            <td key={index}></td>
                          ) : (
                            <td
                              onClick={() => {
                                setItemGrade(item[val]?.split("_")[2]);
                                setItemSubjectToken(
                                  Number(item[val]?.split("_")[3])
                                );
                                setItemMonth(Number(item[val]?.split("_")[4]));
                                setItemUnit(
                                  Number(item[val]?.split("_")[5] || 0)
                                );
                                setIsOpen(true);
                              }}
                              style={{ cursor: "pointer" }}
                              key={index}
                            >
                              <div className="table_data_wrapper">
                                <div className="img_wrapper">
                                  {`U${item[val]?.split("_")[0]}`}
                                </div>
                                <LightTooltip title={item[val]?.split("_")[1]}>
                                  <p className="schedule_desc">
                                    {item[val]?.split("_")[1]}
                                  </p>
                                </LightTooltip>
                              </div>
                            </td>
                          );
                        })}
                      <td></td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default TeacherSchedule;
