import { useSchoolRegistrationStore } from "../../pages/schoolRegistration/store/schoolRegistrationStore";
import { handleEnterKey } from "../../utils/service";
import DropZone from "../dropZone/dropZone";

export const PersonalInfo = () => {
  const schoolRegistrationState = useSchoolRegistrationStore((state) => state);
  const {
    setSchoolName,
    setContact,
    setEmail,
    schoolName,
    contact,
    email,
    setDelegateName,
    setDelegatePhoto,
    guardian_name,
    delegate_photo,
  } = schoolRegistrationState;
  const validateInput = (value) => {
    var regex = /^[0-9]+$/;
    var isValid = regex.test(value);
    console.log(isValid);
    return isValid;
  };
  return (
    <div className="personal-info">
      <div className="row">
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">School Name<i className="required-level">*</i></p>
            <input
              className="input-field"
              value={schoolName}
              onKeyDown={(e) => handleEnterKey(e, "contact")}
              onChange={(e) => {
                setSchoolName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Contact Number<i className="required-level">*</i></p>
            <input
              className="input-field"
              id="contact"
              value={contact}
              onKeyDown={(e) => handleEnterKey(e, "email")}
              onChange={(e) => {
                if (validateInput(e.target.value)) {
                  setContact(e.target.value);
                }
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Email Address<i className="required-level">*</i></p>
            <input
              className="input-field"
              id={"email"}
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Delegate Name<i className="required-level">*</i></p>
            <input
              className="input-field"
              id={"delegate_name"}
              value={guardian_name}
              onChange={(e) => {
                setDelegateName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Delegate Photo<i className="required-level">*</i></p>
            <DropZone
              onDrop={(file) => {
                if (!file.length) {
                  return setDelegatePhoto({});
                }
                setDelegatePhoto(file[0]);
              }}
              file={delegate_photo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
