

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LayoutPdf } from '../LayoutPdf';

export const PreviewContainer =()=>{
    const [show,setShow]= useState(false);
    const [pdfFile,setPdfFile]= useState(false);
    const location = useLocation()
    const item = location.state.item
  const handleButtonClick = () => {
      const input = document.querySelector("#myComponentId")
      html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'PNG', 0, 0);
        // pdf.save('myComponent.pdf');
        const pdfUrl = pdf.output('bloburl');
        // window.open(pdfUrl)
        setPdfFile(pdfUrl)
        setShow(true)
      });

  }
  useEffect(()=>{
    if(item){
    handleButtonClick()
}
  },[])
    return(
        <div>

    <div id="myComponentId">

    <LayoutPdf Layout={item.url} grade={item?.gradeToken} exam={item.exam_name} studentToken={item.studentToken} layoutShow={item}/>
        </div>     
        </div>
 
    )
}