import { useEffect, useState } from "react";
import parse from "react-html-parser";
import Axios from "axios";

import "./AssignmentPage.scss";
// import { Tabs, Tab } from "react-bootstrap-tabs";
import { AttendanceProgress } from "../AttendanceProgress/AttendanceProgress";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { toast } from "react-toastify";
import { domain } from "../../url";
import NividLoader from "../NividLoader/NividLoader";
import { useDeviceData } from "../../store/hodDashboardStore";

export const AssignmentPage = (props) => {
  const [assignment, setAssignment] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { attendanceData } = useDeviceData();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await Axios.get(`${domain}/dashboard_api/v1/assignments`);

        if (res.status === 200) {
          setAssignment(res.data);
        }
      } catch (e) {
        console.log(e, "something went wrong");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="progress-bar-section">
      <div
        className={`custom-card ${
          attendanceData ? "device-card" : "no-device-card"
        }`}
      >
        <div className=" mg-btm-10">
          <p className="medium-title-header">Today’s Assignments</p>
        </div>
        {isLoading ? (
          <NividLoader height={"400px"} />
        ) : (
          <Tabs>
            <Tab label="Today's Assignment" title="Homework">
              <AttendanceProgress assignment={assignment} />
            </Tab>

            <Tab label="Today's Classwork" title="classwork">
              <AttendanceProgress
                assignment={props?.props?.classwork}
                classwork={true}
              />
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};
