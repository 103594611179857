import React, { useEffect, useState } from "react";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import "./table.scss";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import Modal from "react-awesome-modal";
import { DeleteParent } from "../Api/DeleteApi";
import { toast } from "react-toastify";
import { getParentList } from "../Api/GetApi";
import { changeReload, useStudentId, useStudentStore } from "../../store/store";
import { postRegisterParent } from "../Api/PostApi";

const Table = ({
  headers,
  rows,
  showDelete,
  remarksType,
  remarks,
  showEntries,
  childId,
  setModal,
  makeGuardian,
  studentParent,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [searchedRows, setSearchedRows] = useState(rows);
  const [visible, setVisible] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const closeModal = () => {
    setVisible(false);
  };

  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedRows = searchedRows?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(searchedRows?.length / rowsPerPage);

  const student = useStudentId();

  const { fetchStudentData } = useStudentStore();
  const { setReload } = changeReload();
  const findGuardian = studentParent?.filter(
    (item) => item.isGuardian === true
  );
  console.log("find guardian", findGuardian);
  const [isGuardian, setIsGuardian] = useState(findGuardian?.[0]?.id || null);

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (currentPage > totalPages) {
      setCurrentPage(1);
    }
    if (currentPage < 1) {
      setCurrentPage(totalPages);
    }
  }, [currentPage, totalPages]);

  const handleRowsPerPageChange = (event) => {
    const value = parseInt(event.target.value);
    setRowsPerPage(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const filteredData = rows?.filter((row) =>
      row.some((cell) =>
        cell?.toString().toLowerCase().includes(searchText.toLowerCase())
      )
    );
    setSearchedRows(filteredData);
  }, [searchText, rows]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleDelete = async () => {
    try {
      const res = await DeleteParent(selectedId);
      toast(res.data.message);
      getParentList();
      setReload(false);
      fetchStudentData(student.studentId);
      closeModal();
    } catch {
      toast("something went wrong");
    }
  };

  const handleGuardianChange = async (index) => {
    const payLoad = {
      check_boolean: "true",
      student_id: student.studentId,
      parent_detail: {
        0: {
          father_detail: {
            father_name: "",
            father_number: "",
          },
          mother_detail: {
            mother_name: "",
            mother_number: "",
          },
          guardian_detail: {
            guardian_name: studentParent?.[index]?.p_name,
            guardian_number: studentParent?.[index]?.number,
          },
        },
      },
    };

    try {
      const res = await postRegisterParent(payLoad);
      if (res.data.status === 1) {
        toast.success(res.data.message);
        closeModal();
        setReload(false);

        fetchStudentData(student.studentId);
      } else {
        toast.success(res.data.message);
      }
    } catch {
      toast("some error occured");
    }
  };

  return (
    <div className="tableContainer">
      {showEntries && rows?.length > 0 && (
        <div className="entriesContainer">
          <div className="entries">
            <select value={rowsPerPage} onChange={handleRowsPerPageChange}>
              {[5, 10, 15, 20].map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
            entries per page
          </div>
          <input type="text" placeholder="Search..." onChange={handleSearch} />
        </div>
      )}

      <div style={{ overflow: "auto" }}>
        {searchedRows?.length > 0 ? (
          <table>
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                {makeGuardian && <th>Is Guardian</th>}

                {showDelete && !childId && <th>Action</th>}

                {remarksType && <th>Remark Type</th>}
              </tr>
            </thead>
            <tbody>
              {paginatedRows.map((row, index) => (
                <tr key={index}>
                  {row.slice(0, 3)?.map((cell, index) => {
                    return <td key={index}>{cell}</td>;
                  })}

                  {makeGuardian && (
                    <td>
                      <input
                        type="checkbox"
                        name="guardian"
                        checked={studentParent[index]?.id === isGuardian}
                        onChange={() => {
                          if (studentParent[index]?.id) {
                            if (studentParent[index]?.id !== isGuardian) {
                              setIsGuardian(studentParent[index]?.id);
                              handleGuardianChange(index);
                            }
                          } else {
                            console.error("couldn't find parent id");
                          }
                        }}
                      />
                    </td>
                  )}
                  {showDelete && !childId && (
                    <td className="actionCell">
                      <button
                        className="deleteBtn"
                        onClick={() => {
                          setVisible(true);
                          setSelectedId(row?.[3]);
                        }}
                      >
                        <img
                          src={require("../../images/delete.png").default}
                          alt="Delete"
                          className="icon"
                        />
                      </button>
                    </td>
                  )}

                  {remarksType && (
                    <td className="remarks">
                      <span>{remarks[index]}</span>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <NoDataContent
            image={require("../../images/noRemarks.png").default}
            description="No Data"
          />
        )}
      </div>

      {searchedRows?.length > rowsPerPage && (
        <div className="entriesBottom">
          <div className="entriesText">
            Showing {startIndex + 1} to{" "}
            {endIndex > searchedRows.length ? searchedRows.length : endIndex} of{" "}
            {searchedRows.length} entries
          </div>
          <div className="pagination">
            <ReactPaginate
              pageCount={totalPages}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              onPageChange={handlePageClick}
              containerClassName="paginationContainer"
              activeClassName="active"
              previousLabel={<FaCaretLeft />}
              nextLabel={<FaCaretRight />}
            />
          </div>
        </div>
      )}

      <Modal
        visible={visible}
        width="50%"
        effect="fadeInUp"
        onClickAway={() => closeModal()}
      >
        <div className="delete-modal">
          <div className="modal-header">
            <h4>Do you want to remove this parent? </h4>
          </div>
          <div className="modal-footer">
            <button className="btn default-btn " onClick={() => closeModal()}>
              No
            </button>
            <button
              className=" btn btn-content custom-btn"
              onClick={() => {
                handleDelete();
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Table;
