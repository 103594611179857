import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { NepDatePicker } from "neps-date-picker";
import { Modal } from "@mui/material";

export const EditModal = ({
  visible,
  setVisible,
  onEdit,
  startDateProps,
  endDateProps,
}) => {
  const [clearStartDate, setClearStartDate] = useState(false);
  const [clearEndDate, setClearEndDate] = useState(false);
  const [endDate, setEndDate] = useState();
  const [startDate, setStartDate] = useState();
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    setStartDate(startDateProps);
  }, [startDateProps]);

  useEffect(() => {
    setEndDate(endDateProps);
  }, [endDateProps]);

  return (
    <div className="edit-confirmation-modal">
      <Modal
        open={visible}
        onClose={() => {
          setVisible?.("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="edit-modal">
          <div className="title-section">
            <h4 className="title">Edit Date of Unit Plan</h4>
            <i
              className="bx bx-x"
              onClick={() => {
                setVisible?.("");
              }}
            ></i>
          </div>
          <div className="new-plan-date-block">
            <div className="input-field-block">
              <p className="content">Start Date</p>
              <div className="d-flex">
                <div
                  title="Clear Date"
                  onClick={() => setClearStartDate(!clearStartDate)}
                  className="cross_btn"
                >
                  <MdClear size={12} />
                </div>
                <NepDatePicker
                  reset={clearStartDate}
                  dateToken={startDateProps}
                  onDateSelect={(val) => setStartDate(val)}
                />
              </div>
            </div>
            <div className="input-field-block">
              <p className="content">End date</p>
              <div className="d-flex">
                <div
                  title="Clear Date"
                  onClick={() => setClearEndDate(!clearEndDate)}
                  className="cross_btn"
                >
                  <MdClear size={12} />
                </div>
                <NepDatePicker
                  reset={clearEndDate}
                  dateToken={endDateProps}
                  onDateSelect={(val) => setEndDate(val)}
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="btn-block">
            <button
              className="secondary-btn"
              onClick={() => {
                setVisible?.("");
              }}
            >
              Cancel
            </button>
            <button
              className="main-btn"
              onClick={() => {
                onEdit?.(startDate, endDate);
              }}
            >
              Update
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
