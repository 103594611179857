import { useEffect } from "react";
import {
  FormikWrapper,
  useTeachingPlanFormikWrapper,
} from "../../pages/teachingPlan/formikWrapper/formikWrapper";
import { LessonTab } from "../teachingPlanComponents/lessonTab";
import { useTeachingPlanMainStore } from "../../pages/teachingPlanMain/store/teachingPlanMainStore";
import { useTeachingPlanStore } from "../../pages/teachingPlan/store/teachingPlanStore";

export const LessonPlanWrapper = ({ data }) => {
  const { setRedirect } = useTeachingPlanMainStore();
  const { setIsAddLessonPlan } = useTeachingPlanStore();
  const { setSelectedUnitName, setGradeToken, setSubjectToken } =
    useTeachingPlanMainStore();

  useEffect(() => {
    setSelectedUnitName(data?.unit_name);
    setGradeToken(data?.gradeToken);
    setSubjectToken(data?.subject_id);
    setRedirect(true);
    setIsAddLessonPlan(true);
  }, []);

  return <LessonTab />;
};

export const SingleLessonPlan = ({ dashboardData, showHeader }) => {
  return (
    <div className="single-lesson-plan">
      <FormikWrapper>
        <LessonPlanWrapper data={dashboardData} />
      </FormikWrapper>
    </div>
  );
};
