import React from "react";
import { default as ReactSelect, components } from "react-select";
import "./CheckboxSelect.scss";

const Option = (props) => {
  return (
    <div
      style={{
        backgroundColor: props.isSelected ? "red !important" : "transparent",
        color: props.isSelected ? "white" : "inherit",
      }}
    >
      <components.Option
        {...props}
        className={`${props.isSelected && "selected-option"}`}
      >
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function CheckboxSelect({ optionvalue, setState, state }) {
  const handleChange = (selected) => {
    setState(selected);
  };

  return (
    <div className="checkbox-select-area ">
      <ReactSelect
        options={optionvalue}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={handleChange}
        value={state}
      />
    </div>
  );
}

export default CheckboxSelect;
