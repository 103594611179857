import { FieldArray } from "formik";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTeachingPlanFormikWrapper } from "../../../pages/teachingPlan/formikWrapper/formikWrapper";
import { useTeachingPlanStore } from "../../../pages/teachingPlan/store/teachingPlanStore";
import { useTeachingPlanMainStore } from "../../../pages/teachingPlanMain/store/teachingPlanMainStore";
import { LessonForm } from "../lessonForm";
import "./lessonTab.scss";

export const LessonTab = () => {
  const { values, handleSubmit, isSubmitting } = useTeachingPlanFormikWrapper();
  const { showLessonHeader, setIsUpdate } = useTeachingPlanStore();
  // *
  const {
    viewPage,
    isAddLessonPlan,
    setIsAddLessonPlan,
    setEditMode,
    setViewPage,
    editMode,
  } = useTeachingPlanStore();
  const { selectedUnit, gradeToken, subjectToken, month } =
    useTeachingPlanMainStore();
  const history = useHistory();
  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");
  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const handleBackButton = () => {
    if (!editMode) {
      const valueExists = values.lesson_plans.some((item) => {
        if (
          item.topic !== "" ||
          item.total_time !== "" ||
          item.from_page !== "" ||
          item.to_page !== "" ||
          item.objective !== ""
        )
          return true;
      });

      if (valueExists) {
        localStorage.setItem("unitPlanData", JSON.stringify(values));
      }
    }
  };

  useEffect(() => {
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSaveAndProceed = (index) => {
    if (index === 0) {
      setIsUpdate(false);
      handleSubmit();
    } else {
      setIsUpdate(true);
      handleSubmit();
    }
  };

  // const handleDelete = (remove, index) => {
  //   remove(index);
  //   toast.info("Lesson deleted successfully !");
  //   setVisible(false);
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  //   if (index === 0) {
  //     setActiveTab(0);
  //   } else {
  //     setActiveTab(index - 1);
  //   }
  // };

  return (
    <div className="lesson-tab">
      {showLessonHeader && (
        <div className="title-block header">
          <i
            className="bx bxs-chevron-left cursor-pointer"
            onClick={() => {
              handleBackButton();
              setIsAddLessonPlan(false);
              setIsUpdate(false);
              setEditMode(false);
              if (viewPage === "lessonTab" && !isAddLessonPlan && !editMode) {
                return setViewPage("newPlan");
              } else if (isAddLessonPlan || editMode) {
                history.push(
                  `/single_unit_plan?selectedUnit=${selectedUnit}&gradeToken=${gradeToken}&subjectToken=${subjectToken}&month=${month}&showHeader=${true}&token=${token}`
                );
              } else {
                history.push(`/teaching-plan2?token=${token}`);
              }
            }}
          ></i>
          <h2 className="title">Lesson Plan</h2>
        </div>
      )}
      <div className="lesson-tab-details">
        <FieldArray
          name="lesson_plans"
          render={({ remove, push }) => (
            <>
              {values?.lesson_plans?.map((_, index) => {
                return (
                  <>
                    <LessonForm fieldArrayIndex={index} key={index} />
                    <div className="btn-block lesson-btn-block">
                      {/* <button
                          className="secondary-btn"
                          disabled={values?.lesson_plans?.length === 1}
                          onClick={() => {
                            // remove(index);
                            setVisible(true);
                          }}
                        >
                          Delete Lesson
                        </button> */}
                      <button
                        className="main-btn"
                        onClick={() => {
                          handleSaveAndProceed(index);
                        }}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            <i class="bx bx-loader-circle bx-spin"></i>{" "}
                            Submitting ...
                          </>
                        ) : (
                          <>
                            {values?.lesson_plans?.length === index + 1
                              ? "Submit"
                              : "Save and Proceed"}
                          </>
                        )}
                      </button>
                    </div>
                    {/* {visible && (
                        <DeleteConfirmationModal
                          visible={visible}
                          setVisible={setVisible}
                          onDelete={() => {
                            handleDelete(remove, index);
                          }}
                        />
                      )} */}
                  </>
                );
              })}
            </>
          )}
        />
      </div>
    </div>
  );
};
