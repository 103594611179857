import { create } from "zustand";
import {
  getCashFlow,
  getExpenses,
  getExpensesChart,
  getIncomeExpensesChart,
  getInventoryList,
  getPayement,
  getSalesChart,
  getStudentwiseReceivable,
  getSummaryData,
  getTotalReceivable,
} from "../../src/components/Api/GetApi";
import { toast } from "react-toastify";
import { devtools } from "zustand/middleware";

export const useTotalReceivable = create((set) => ({
  receivable: " ",
  loading: false,

  fetchTotalReceivable: async () => {
    set({ loading: true });
    try {
      const res = await getTotalReceivable();
      const apiRes = res.data;

      if (apiRes?.status === 1) {
        set({ receivable: apiRes?.data });
      } else {
        set({ receivable: [] });
      }
    } catch {
      toast("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useGradewiseReceivable = create((set) => ({
  gradewiseReceivable: [],
  loading: false,

  fetchGradeReceiable: async (id) => {
    set({ loading: true });
    try {
      const res = await getStudentwiseReceivable(id);

      const apiRes = res.data;

      if (apiRes?.status === 1) {
        set({ gradewiseReceivable: apiRes?.data });
      } else {
        set({ gradewiseReceivable: [] });
      }
    } catch {
      toast("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useSalesChart = create(
  devtools((set) => ({
    salesChart: [],

    loading: false,

    fetchSalesChart: async (timeline) => {
      set({ loading: true });
      try {
        const res = await getSalesChart(timeline);
        const apiRes = await res.data;

        if (apiRes?.status === 1) {
          set({ salesChart: apiRes?.data });
        } else {
          set({ salesChart: [] });
        }
      } catch {
        console.log("something went wrong");
      } finally {
        set({ loading: false });
      }
    },
  }))
);

export const useExpensesChart = create((set) => ({
  expensesChart: [],
  loading: false,
  fetchExpensesChart: async (timeline) => {
    set({ loading: true });

    try {
      const res = await getExpensesChart(timeline);
      const apiRes = res.data;
      if (apiRes?.status === 1) {
        set({ expensesChart: apiRes?.data });
      } else {
        set({ expensesChart: [] });
      }
    } catch {
      console.log("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useIncomeExpensesChart = create((set) => ({
  incomeExpensesChart: [],
  loading: false,
  fetchIncomeExpensesChart: async (timeline) => {

    set({ loading: true });

    try {
      const res = await getIncomeExpensesChart(timeline);

      const apiRes = res.data;
      if (apiRes?.status === 1) {
        set({ incomeExpensesChart: apiRes?.data });
      } else {
        set({ incomeExpensesChart: [] });
      }
    } catch {
      console.log("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useRecentPayment = create((set) => ({
  payment: [],
  loading: false,
  fetchPayment: async (from, to, classToken) => {
    set({ loading: true });

    try {
      const res = await getPayement(from, to, classToken);
      const apiRes = res?.data;

      if (apiRes.status === 1) {
        set({ payment: apiRes?.data });
      } else {
        set({ payment: [] });
      }
    } catch (err) {
      console.log("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useRecentExpenses = create((set) => ({
  expenses: [],
  loading: false,
  fetchExpenses: async (from, to, expensesToken) => {
    set({ loading: true });

    try {
      const res = await getExpenses(from, to, expensesToken);
      const apiRes = res.data;

      if (apiRes.status === 1) {
        set({ expenses: apiRes?.data });
      } else {
        set({ expenses: [] });
      }
    } catch {
      console.log("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useCashFlow = create((set) => ({
  cashFlow: [],
  loading: false,
  fetchCashFlow: async (year) => {
    set({ loading: true });
    try {
      const res = await getCashFlow(year);
      const apiRes = await res.data;

      if (apiRes.status === 1) {
        set({ cashFlow: apiRes?.data });
      } else {
        set({ cashFlow: [] });
      }
    } catch {
      console.log("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useInventoryList = create((set) => ({
  inventory: [],

  loading: false,
  fetchInventoryList: async () => {
    set({ loading: true });
    try {
      const res = await getInventoryList();
      const apiRes = await res.data;

      if (apiRes.status === 1) {
        set({ inventory: apiRes?.data });
      } else {
        set({ inventory: [] });
      }
    } catch {
      console.log("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useSummary = create(
  devtools((set) => ({
    summary: [],
    loading: false,

    fetchSummaryData: async () => {
      set({ loading: true });
      try {
        const res = await getSummaryData();
        const apiRes = await res.data;
        if (apiRes.status === 1) {
          set({ summary: apiRes?.data });
        } else {
          set({ summary: [] });
        }
      } catch {
        console.log("something went wrong");
      } finally {
        set({ loading: false });
      }
    },
  }))
);
