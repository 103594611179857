import React from "react";
import { TeacherAssignment } from "../TeacherAssignment/TeacherAssigment";
import { AssignmentDetailForm } from "../AssignmentDetailForm/AssignmentDetailForm";
import "./activity.scss";

import { toast } from "react-toastify";
import { postHodApproval } from "../Api/PostApi";
import { useHodMarksApproval } from "../../store/hodDashboardStore";

const Activity = ({ data, onClose }) => {
  const { fetchHodMarksApproval } = useHodMarksApproval();
  const approve = async (id) => {
    const values = {
      approval_id: id,
    };
    try {
      const response = await postHodApproval(values);

      if (response.data.status === 1) {
        fetchHodMarksApproval();
        toast("submitted successfully");
      } else {
        toast("something went wrong");
      }
    } catch {
      toast("something went wrong");
    }
  };

  return (
    <div className="activity_modal_container">
      <div className="activity_heading">
        <div className="activity_heading_top">
          <h3>EXAM NAME</h3>
          <p>{data?.examName}</p>
        </div>
        <div className="activity_heading_middle">
          <div>
            <h3>grade</h3>
            <p>{data?.class_name}</p>
          </div>
          <div>
            <h3>Subject</h3>
            <p>{data?.subjectName}</p>
          </div>
        </div>

        <div className="activity_heading_bottom">
          <h3>submitted by</h3>
          <div className="activity_heading_bottom_wrapper">
            <div className="activity_heading_bottom_img">
              {data?.submitted_by?.charAt(0).toUpperCase()}
            </div>

            <div className="activity_heading_bottom_content">
              {`${data?.submitted_by} • `}
              <span>Class Teacher of</span>
              {data?.class_name}
            </div>
          </div>
        </div>
      </div>
      <AssignmentDetailForm
        data={data}
        viewData={data?.student_data}
        viewonly={true}
      />
      {data?.student_data?.[0].grade_token !== null &&
      data?.student_data?.length > 0 ? (
        <div className="accept_decline_btn_container">
          <div onClick={onClose} className="decline_btn">
            Close
          </div>
          <div
            className="accept_btn"
            onClick={async () => {
              await approve(data?.id);
              onClose();
            }}
          >
            Approve
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Activity;
