import React from "react";
import "./AssessmentHeaderTitle.scss";

export const AssessmentHeaderTitle = ({ selectedLessonTab }) => {
  const NoAssignments =
    !selectedLessonTab?.classwork_token &&
    !selectedLessonTab?.homework_token & !selectedLessonTab?.activity;

  return (
    <div className="assessment-header-title">
      {NoAssignments ? (
        <div className="description_section">No assignments found</div>
      ) : (
        <div className="description_section ">
          <div>
            <div className="desc_title">Homework Description</div>
            <p className="desc_content">
              {selectedLessonTab?.homework_description || "-"}
            </p>
          </div>

          <div>
            <div className="desc_title">Classwork Description</div>
            <p className="desc_content">
              {selectedLessonTab?.classwork_description || "-"}
            </p>
          </div>

          <div>
            <div className="desc_title">Activity Date</div>
            <p className="desc_content">
              {selectedLessonTab?.activity_date || "-"}
            </p>
          </div>
          <div>
            <div className="desc_title">activities</div>
            <p className="desc_content">{selectedLessonTab?.activity || "-"}</p>
          </div>
          <div>
            <div className="desc_title">images</div>
            <div className="img_wrapper">
              {selectedLessonTab?.imageUrl?.length > 0 ? (
                <>
                  {selectedLessonTab?.imageUrl?.map((item, index) => (
                    <div className="img_box">
                      <img src={item} alt="img" />
                    </div>
                  ))}
                </>
              ) : (
                "No image found"
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
