import Modal from "react-awesome-modal";
import "./unitLessonEvaluation.scss";

export const ResetConfirmationModal = ({ visible, setVisible, onReset, onDiscard }) => {
  return (
    <div className="reset-confirmation-modal">
      <Modal
        visible={visible}
        width="350"
        height="177"
        effect="fadeInUp"
        onClickAway={() => {
          setVisible?.("");
        }}
      >
        <div className="reset-modal">
          <h4 className="title">Reset Weightage?</h4>
          <p className="content">
            Are you sure you want to reset weightage for this evaluation?
          </p>
          <div className="btn-block">
            <button
              className="secondary-btn"
              onClick={() => {
                setVisible?.("");
                onDiscard?.();
              }}
            >
              Discard
            </button>
            <button
              className="delete-btn"
              onClick={onReset}
            >
              Reset
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
