import React, { useEffect, useState } from "react";
import LiveVideoPlayer from "./Video";
import { getCCTV } from "../Api/GetApi";
import "./Player.scss";

export const Player = () => {
  const [videoUrl, setVideoUrl] = useState();
  const [grid, setGrid] = useState(8);
  const items = Array.from({ length: grid });
  const column = grid === "32" ? 8 : 4;
  // Calculate the number of rows needed based on the number of items and 4 columns
  const rows = Math.ceil(grid / column);

  // Calculate the height of each div based on 90vh divided by the number of rows
  const itemHeight = `${90 / rows}vh`;

  useEffect(() => {
    getCCTV().then((res) => {
      setVideoUrl(res.data?.data?.cctv_urls);
    });
  }, []);

  if (videoUrl) {
    return (
      <div className="video-player-section">
        <div
          className={`grid-view-container  ${
            grid === "32" ? "grid-high" : grid
          } `}
        >
          {items.map((_, index) => (
            <LiveVideoPlayer
              device={videoUrl?.[index]}
              height={itemHeight}
              grid={grid}
            />
          ))}
        </div>
        <div className="option-section">
          <select
            onChange={(e) => {
              setGrid(e.target.value);
            }}
          >
            <option value={8}>8</option>
            <option value={16}>16</option>
            <option value={32}>32</option>
          </select>
        </div>
      </div>
    );
  } else return null;
};
