import "./TotalCard.scss";
import person from "../../images/person.svg";
import staff from "../../images/staff.svg";
import parent from "../../images/parent.svg";
import bus from "../../images/bus.svg";
import { domain } from "../../url";
import { useDeviceData } from "../../store/hodDashboardStore";
import { useEffect } from "react";

const TotalCard = (props) => {
  const { setAttendanceData, attendanceData } = useDeviceData();
  useEffect(() => {
    const deviceData = props?.data?.device_check;
    setAttendanceData(deviceData);
  }, []);
  return (
    <div className="total-card ">
      {attendanceData && (
        <div class="border-left-card">
          <div className="title-block">
            <i class="bx bx-time-five"></i>
            <p>Last Attendance Device Sync Date:</p>
            {props?.data?.device_sync?.length > 0 ? (
              <p className="bold-content">
                {props?.data?.device_sync?.[0].miti +
                  "(" +
                  props?.data?.device_sync?.[0].englishDate +
                  ")"}
              </p>
            ) : (
              <p style={{ fontSize: "10px" }} className="bold-content ml-0">
                Please turn off/on your attendance device
              </p>
            )}
          </div>
        </div>
      )}
      <div className="custom-card">
        <div className="row">
          <div className="col-md-6 card-content">
            <div
              onClick={() => {
                window.open(`${domain}/student_tables`);
              }}
            >
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <img src={person} alt="person" />
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Students</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_student}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 card-content">
            <div
              onClick={() => {
                window.open(`${domain}/staff_tables`);
              }}
            >
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <img src={staff} alt="staff" />
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Staffs</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_staff}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 card-content">
            <div
              onClick={() => {
                window.open(`${domain}/tokenuser`);
              }}
            >
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <img src={parent} alt="bus" />
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Parents</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_parent}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 card-content">
            <div
              onClick={() => {
                window.open(`${domain}/bus_registers`);
              }}
            >
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <img src={bus} alt="bus" />
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Bus</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_bus}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 card-content">
            <div
              onClick={() => {
                window.open(`${domain}/student_tables`);
              }}
            >
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <i class="bx bx-male-sign"></i>
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Male</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_male}
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
          <div className="col-md-6 card-content">
            <div
              onClick={() => {
                window.open(`${domain}/student_tables`);
              }}
            >
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <i class="bx bx-female-sign"></i>
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Female</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_female}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 card-content">
            <div>
              <div className="d-flex mg-btm">
                <div className="icons">
                  <div className="changeable">
                    <i class="bx bx-no-entry"></i>
                  </div>
                </div>
                <div className="texts">
                  <p className="extra-small-content">Total Unspecified</p>
                  <p className="small-title-view">
                    {props?.data?.total_member_and_bus?.total_unspecified}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TotalCard;
