import { useEffect } from "react";
import Portfolio from "../Portfolio/Portfolio";
import { useStudentId, useStudentStore } from "../../store/store";
import Select from "react-select";
import NepaliDate from "nepali-date";
import "./PortfolioSinglePage.scss";

const PortfolioSinglePage = ({ childId }) => {
  const urlString = window.location.href;
  const url = new URL(urlString);
  const pathname = url.pathname; // This will give you "/profile/12"
  const parts = pathname.split("/"); // Split the pathname by "/"
  const id = childId ? childId : parts[parts.length - 1];
  const { fetchStudentRemark } = useStudentStore();
  const currentNepaliMonth = new NepaliDate().format("MMMM");
  const { setSelectedMonth, selectedMonth } = useStudentId();
  const months = [
    { monthtoken: "01", name: "Baisakh" },
    { monthtoken: "02", name: "Jestha" },
    { monthtoken: "03", name: "Asar" },
    { monthtoken: "04", name: "Shrawan" },
    { monthtoken: "05", name: "Bhadra" },
    { monthtoken: "06", name: "Aswin" },
    { monthtoken: "07", name: "Kartik" },
    { monthtoken: "08", name: "Mangsir" },
    { monthtoken: "09", name: "Poush" },
    { monthtoken: "10", name: "Magh" },
    { monthtoken: "11", name: "Falgun" },
    { monthtoken: "12", name: "Chaitra" },
  ];
  const getCurrentMonthToken = (monthName) => {
    const month = months.find((m) => m?.name === monthName);
    return month ? month.monthtoken : "";
  };

  useEffect(() => {
    const currentMonth = getCurrentMonthToken(currentNepaliMonth);
    if (id !== "") {
      setSelectedMonth(currentMonth);
      fetchStudentRemark(currentMonth, id);
    }
  }, []);

  const handleMonthChange = (selectedOption) => {
    const selectedMonthToken = selectedOption.value;
    setSelectedMonth(selectedMonthToken);
    fetchStudentRemark(selectedMonthToken, id);
  };
  return (
    <div className="portfolio-single-page">
      <h4>Portfolio</h4>

      <div className="monthSelection">
        <Select
          options={months.map((month) => ({
            value: month.monthtoken,
            label: month.name,
          }))}
          onChange={handleMonthChange}
          value={{
            value: selectedMonth,
            label: months.find((month) => month.monthtoken === selectedMonth)
              ?.name,
          }}
        />
      </div>
      <Portfolio />
    </div>
  );
};

export default PortfolioSinglePage;
