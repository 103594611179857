import { NepDatePicker } from "neps-date-picker";
import { useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import {
  TeachingPlanError,
  useTeachingPlanFormikWrapper,
} from "../../../pages/teachingPlan/formikWrapper/formikWrapper";
import { useTeachingPlanStore } from "../../../pages/teachingPlan/store/teachingPlanStore";
import { useTeachingPlanMainStore } from "../../../pages/teachingPlanMain/store/teachingPlanMainStore";
import { getClassAlpha, getGradelist, getTeacherSubjectApi } from "../../Api/GetApi";
import { InputField } from "../../ui/formikFields/inputField";
import { SelectField } from "../../ui/formikFields/selectField";
import { TextField } from "../../ui/formikFields/textField";
import "./newPlan.scss";

export const NewPlan = ({ showHeader }) => {
  const [grade, setGrade] = useState([]);
  const [clearStartDate, setClearStartDate] = useState(false);
  const [clearEndDate, setClearEndDate] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const { isValid, values, errors, setErrors, setFieldValue } =
    useTeachingPlanFormikWrapper();
  const { viewPage, setViewPage } = useTeachingPlanStore();
  const history = useHistory();
  const {
    selectedStartDayToken,
    selectedEndDayToken,
    setSelectedStartDayToken,
    setSelectedEndDayToken,
  } = useTeachingPlanMainStore();
  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");
  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  useEffect(() => {
    getClassAlpha().then((res) => {
      setGrade(res?.data?.data);
    });
    setErrors({});
  }, []);

  useEffect(() => {
    getTeacherSubjectApi(values?.gradeToken).then((res) => {
      setSubjectList(res?.data?.subject);
    });
  }, [values?.gradeToken]);

  const onContinue = () => {
    // if (Object.keys(touched).length === 0) {
    //   handleSubmit();
    // }
    if (isValid) {
      setViewPage?.("lessonTab");
    }
  };

  const handleBackButton = () => {
    if (
      values.gradeToken !== "" ||
      values.subjectToken !== "" ||
      values.unit_name !== "" ||
      values.total_teaching_hour !== "" ||
      values.unit_objective !== "" ||
      values.start_day_token > 0 ||
      values.end_day_token > 0
    ) {
      localStorage.setItem("unitPlanData", JSON.stringify(values));
    }
  };
  useEffect(() => {
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  useEffect(() => {
    setFieldValue("start_day_token", selectedStartDayToken);
    setFieldValue("end_day_token", selectedEndDayToken);
  }, [selectedStartDayToken, selectedEndDayToken]);

  return (
    <>
      <ToastContainer />
      <div className="new-plan">
        <div className="title-block header">
          {showHeader || viewPage === "lessonTab" ? (
            <>
              <i
                className="bx bxs-chevron-left cursor-pointer"
                onClick={() => {
                  handleBackButton();
                  history.push(`/teaching-plan2?token=${token}`);
                }}
              ></i>
            </>
          ) : (
            ""
          )}
          <h2 className="title">Create New Plan</h2>
        </div>

        <div className="section-gap">
          <div className="new-plan-section">
            <div className="input-field-block">
              <p className="content">
                Grade<span>*</span>
              </p>
              <SelectField
                name="gradeToken"
                onChange={(e) => {
                  setFieldValue("gradeToken", e.target.value);
                }}
              >
                <option value="">Select Grade</option>
                {grade?.map((item, index) => {
                  return (
                    <option value={item?.id} key={index}>
                      {item?.name}
                    </option>
                  );
                })}
              </SelectField>
              <TeachingPlanError name="gradeToken" />
            </div>
            <div className="input-field-block">
              <p className="content">
                Subject<span>*</span>
              </p>
              <SelectField
                name="subjectToken"
                disabled={values?.gradeToken === ""}
              >
                <option value="">Select Subject</option>
                {subjectList?.map((item, index) => {
                  return (
                    <option value={item?.id} key={index}>
                      {item?.subjectName}
                    </option>
                  );
                })}
              </SelectField>
              <TeachingPlanError name="subjectToken" />
            </div>

            <div className="input-field-block">
              <p className="content">
                Unit Name<span>*</span>
              </p>
              <InputField
                name="unit_name"
                className="input-field"
                placeHolder="Enter Topic/Subject area/Content"
              />
              <TeachingPlanError name="unit_name" />
            </div>
            <div className="input-field-block">
              <p className="content">
                Total Teaching Hours<span>*</span>
              </p>
              <InputField
                name="total_teaching_hour"
                type="number"
                className="input-field"
                placeHolder="Enter total teaching hours"
                min={0}
              />
              <TeachingPlanError name="total_teaching_hour" />
            </div>

            <div className="input-field-block">
              <p className="content">
                Start Date<span>*</span>
              </p>
              <div className="d-flex">
                <div
                  title="Clear Date"
                  onClick={() => setClearStartDate(!clearStartDate)}
                  className="cross_btn"
                >
                  <MdClear size={12} />
                </div>
                <NepDatePicker
                  reset={clearStartDate}
                  onDateSelect={(val) => {
                    setFieldValue("start_day_token", val);
                  }}
                  dateToken={values?.start_day_token}
                />
              </div>
              <TeachingPlanError name="start_day_token" />
            </div>
            <div className="input-field-block">
              <p className="content">
                End date<span>*</span>
              </p>
              <div className="d-flex">
                <div
                  title="Clear Date"
                  onClick={() => setClearEndDate(!clearEndDate)}
                  className="cross_btn"
                >
                  <MdClear size={12} />
                </div>
                <NepDatePicker
                  reset={clearEndDate}
                  dateToken={values?.end_day_token}
                  onDateSelect={(val) => {
                    setFieldValue("end_day_token", val);
                  }}
                />
              </div>
              <TeachingPlanError name="end_day_token" />
            </div>
            <div className="input-field-block">
              <p className="content">
                Unit Objective<span>*</span>
              </p>
              <TextField
                name={`unit_objective`}
                type="text-area"
                className="text-field"
                placeHolder="Enter unit objectives here"
              />
              <TeachingPlanError name={`unit_objective`} />
            </div>
            <div className="btn-block">
              <button
                className="main-btn"
                onClick={() => {
                  if (isValid) {
                    onContinue();
                  } else {
                    toast.error(
                      errors.gradeToken ||
                        errors.subjectToken ||
                        errors.unit_name ||
                        errors.total_teaching_hour ||
                        errors.start_day_token ||
                        errors.end_day_token ||
                        errors.unit_objective
                    );
                  }
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
