import React from "react";
import "./teacherProfile.scss";
import { StudentProfile } from "../../components/StudentProfile/studentInfo/StudentProfile";
import TeacherAssessment from "../../components/TeacherProfileMain/TeacherAssesment/TeacherAssessment";
import TeacherSchedule from "../../components/TeacherProfileMain/TeacherSchedule/TeacherSchedule";
import StudentScore from "../../components/TeacherProfileMain/StudentScore/StudentScore";
import ClassProgress from "../../components/TeacherProfileMain/ClassProgress/ClassProgress";
import Assesments from "../../components/TeacherProfileMain/Assesments/Assesments";

const TeacherProfiles = () => {
  return (
    <div className="section-gap">
      <div className="teacher-profile ">
        <div className="teacher-profile-section">
          <div className="teacher-profile-container">
            <StudentProfile isTeacher={true} />

            <TeacherAssessment />

            <TeacherSchedule />
            <StudentScore />

            <div className="row">
              <div className="col-md-6">
                <ClassProgress />
              </div>
              <div className="col-md-6">
                <Assesments />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeacherProfiles;
