import React from "react";
import { Link, Redirect } from "react-router-dom";
import "./timeline.scss";
import NepaliDate from "nepali-date";

import { domain } from "../../../../url";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PopUp from "../../../popUp/popUp";
import axiosInstance from "../../../AxiosInceptor/AxiosInstance";

class Timeline extends React.Component {
  state = {
    isToggleOn: false,
    dueDate: "",
    details: {},
    visible: false,
    checkVisible: false,
    checkId: "",
    checked: false,
    comment: "",
    modalWidth: "50%",
    snackMessge: "",
    openSnackBar: false,
    snackType: "",
    plans:
      this.props?.location?.state?.details &&
      JSON.parse(this.props?.location?.state?.details),
  };

  showAction = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  componentDidMount() {
    // this.getDueDate();
    this.getModalWidth();
  }

  getDueDate = (props) => {
    const dueDate = new NepaliDate(props);
    const nepaliDueDate = dueDate.format("mmmm d ");
    return nepaliDueDate;
  };

  getStartDate = (props) => {
    const startDate = new NepaliDate(props);
    const nepaliStartDate = startDate.format("mmmm d");
    return nepaliStartDate;
  };

  openModal = () => {
    this.setState({
      visible: true,
      checkVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
      checkVisible: false,
    });
  };

  getModalWidth = () => {
    var windowWidth = window.innerWidth;
    var modalWidth = "50%";
    if (windowWidth <= 320) {
      modalWidth = "90%";
    } else if (windowWidth <= 480) {
      modalWidth = "90%";
    }
    this.setState({
      modalWidth,
    });
  };

  deletePlan = async (id, index) => {
    var res = await axiosInstance.delete(
      `${domain}/teacher_api/plan_detail/${id}/delete`
    );

    if (res.data.status === 11) {
      var removePlan = [...this.state.plans];
      removePlan.splice(index, 1);
      this.setState({
        visible: false,
        openSnackBar: true,
        snackMessge: "Successfully deleted",
        snackType: "success",

        plans: removePlan,
      });
    } else {
      this.setState({
        visible: false,
        openSnackBar: true,
        snackMessge: "Fail",
        snackType: "error",
      });
    }
  };

  onCheck = (index, id) => (event) => {
    this.setState({
      checkVisible: event.target.checked,
      checkId: id,
    });
  };

  postComment = async () => {
    var postData = {
      id: this.state.checkId,
      comment: this.state.comment,
    };
    var { data } = await axiosInstance.post(
      `${domain}/teacher_api/complete_plan_detail`,
      postData
    );
    if (data.status === 11) {
      this.setState({
        // checkVisible: false,
        openSnackBar: true,
        snackType: "success",
        snackMessge: "Successfully submited",
      });
    } else {
      this.setState({
        checkVisible: false,
        openSnackBar: true,
        snackType: "error",

        snackMessge: "Fail to submit",
      });
    }
  };

  getComment = (comment) => {
    this.setState({
      comment,
    });
  };

  render() {
    if (!this.props.location.state) {
      return <Redirect to="/teaching_plan" />;
    }
    console.log(this.props.location.state);
    // const details = JSON.parse(this.props.location.state.details);
    return (
      <div className="timeline ">
        <div className="title-block header">
          <Link to="/teaching_plan">
            <i className="bx bxs-chevron-left"></i>
          </Link>
          <h1 className="title">{this.props.location?.state?.planName}</h1>
          {/* <div className="end-block plan-icon">
            <div className="edit-icon">
              <i className="bx bx-edit-alt"></i>
            </div>
            <div className="delete-icon">
              <i className="bx bx-trash"></i>
            </div>
          </div> */}
        </div>
        <div className="section-gap">
          <div className="container ">
            <div className="timeline-top-title-block">
              <div className="row">
                <div className="col-sm-3">
                  <p className="timeline-top-title">Due Date</p>
                </div>
                <div className="col-sm-7">
                  <p className="timeline-top-title">Plan</p>
                </div>
                <div className="col-sm-2">
                  <p className="timeline-top-title right">
                    <i className="bx bx-filter"></i>
                  </p>
                </div>
              </div>
            </div>

            {this.state?.plans?.map((item, index) => {
              return (
                <div className="timeline-section" key={index}>
                  <div className="row">
                    <div className="col-sm-3 pr-0">
                      <div className="timeline-progress">
                        <div className="timeline-date">
                          {/* <div className="front-content">{item.due_date}</div> */}
                          <div className="front-content">
                            {this.getDueDate(item.due_date)}
                          </div>

                          <div className="block-content">
                            {this.getStartDate(
                              this.props.location.state.startDate
                            )}
                          </div>
                        </div>
                        <div className="timeline-progress-bar">
                          <div className="checkbox">
                            <input
                              type="checkbox"
                              defaultChecked={item.is_completed}
                              id={index}
                              onChange={this.onCheck(
                                index,
                                item.plan_detail_id
                              )}
                            />
                            <label htmlFor={index}></label>
                          </div>
                        </div>

                        <div className="border-right"></div>
                      </div>
                      <PopUp
                        visible={this.state.checkVisible}
                        closeModal={() => this.closeModal()}
                        title="Add a comment"
                        id={this.state.checkId}
                        post={this.postComment}
                        getComment={this.getComment}
                        textBox="true"
                      />
                    </div>
                    <div className="col-sm-9">
                      <div className="timeline-card">
                        <div className="card-section">
                          <Link
                            to={{
                              pathname: "/single_plan_card",
                              description: `${item.description}`,
                              dueDate: `${item.due_date}`,
                              startDate: `${this.props.location.state.startDate}`,
                              planType: `${item.plan_type_name}`,
                              state: this.props.location.state,
                              planTypeID: `${item.plan_type_id}`,
                              overDue: `${item.over_due}`,
                              imageName: `${this.props.location.state.imageName}`,
                            }}
                          >
                            <div className="card-block">
                              <div className="timeline-top">
                                <div className="card-title-block">
                                  <p
                                    className={`card-title ${
                                      item.plan_type_id === "1"
                                        ? "blue"
                                        : item.plan_type_id === "2"
                                        ? "pink"
                                        : item.plan_type_id === "3"
                                        ? "dark-green"
                                        : "red"
                                    }`}
                                  >
                                    {item.plan_type_name}
                                  </p>
                                  {item.over_due ? (
                                    <p className="card-title red">Overdue</p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {/* <div
                                onClick={this.showAction}
                                className="action-top-icon"
                              >
                                <i class="bx bx-trash icon-bg"></i>
                              </div> */}
                                {/* {this.state.isToggleOn ? (
                              <div className="action-block">
                                <p className="action-content">Edit</p>
                                <p className="action-content">Delete</p>
                              </div>
                            ) : (
                              ""
                            )} */}
                              </div>

                              <p className="block-content">
                                {item.description}
                              </p>
                              {/* <div className="timeline-comment">
                                <i className="bx bx-comment"></i>
                                <p className="block-content">1 Comment</p>
                              </div> */}
                            </div>
                          </Link>
                          <div>
                            <div
                              className="card-delete"
                              onClick={() => {
                                this.setState({
                                  tobeDeletedIndex: index,
                                  tobeDeletedId: item.plan_detail_id,
                                });
                                this.openModal();
                              }}
                            >
                              <i class="bx bx-trash icon-bg"></i>
                            </div>

                            <PopUp
                              visible={this.state.visible}
                              closeModal={() => this.closeModal()}
                              title="Delete Plan"
                              content="A notice will be submited."
                              post={() =>
                                this.deletePlan(
                                  this.state.tobeDeletedId,
                                  this.state.tobeDeletedIndex
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <Snackbar
                      open={this.state.openSnackBar}
                      autoHideDuration={3000}
                      onClose={() => this.setState({ openSnackBar: false })}
                    >
                      {this.state.status ? (
                        <Alert severity="success">
                          {this.state.snackMessge}
                        </Alert>
                      ) : (
                        <Alert severity="error">{this.state.snackMessge}</Alert>
                      )}
                    </Snackbar> */}

                    <Snackbar
                      open={this.state.openSnackBar}
                      autoHideDuration={3000}
                      onClose={() => this.setState({ openSnackBar: false })}
                    >
                      <Alert severity={this.state.snackType}>
                        {this.state.snackMessge}
                      </Alert>
                    </Snackbar>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
