import React, { useEffect, useState } from "react";
import "./salesdoughnutchart.scss";
import Chart from "react-apexcharts";
import { useSalesChart } from "../../store/accountDashboard";

import { NoDataContent } from "../NoDataContent/NoDataContent";
import NividLoader from "../NividLoader/NividLoader";

const SalesDoughnutChart = () => {
  const [timeLine, setTimeLine] = useState("this year");

  const { salesChart, loading, fetchSalesChart } = useSalesChart();

  const salesName = Array.isArray(salesChart)
    ? salesChart?.map((a) => (a === null ? "null" : a.product_name))
    : [];

  const salesQuantity = Array.isArray(salesChart)
    ? salesChart?.map((a) => {
        const quantity = Number(a.sales_qty);
        return isNaN(quantity) ? 0 : quantity;
      })
    : [];

  useEffect(() => {
    fetchSalesChart(timeLine);
  }, [timeLine]);

  const series = salesQuantity || [];

  const options = {
    chart: {
      type: "donut",
    },
    width: 400,
    labels: salesName || [],

    dataLabels: {
      enabled: true,
    },
    legend: {
      position: "bottom",
    },

    //  title: {
    //      text: "Random",
    //     style: {
    //       fontSize: "16px",
    //       fontFamily: "Nunito Sans",
    //     },
    //   },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div className="doughnutChart1_container">
      <div className="heading">
        <div className="title_main">Total Sales</div>
        <div>
          <select
            className="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              setTimeLine(e.target.value);
            }}
          >
            <option value="this year" defaultValue>
              This Fiscal Year
            </option>
            <option value="this month">This Month</option>
            <option value="last month">Last Month </option>
            <option value="this week"> This week</option>
            <option value="last week">Last Week</option>
          </select>
        </div>
      </div>
      <div className="doughnut1_wrapper">
        {loading ? (
          <div
            style={{
              display: "flex",
              height: "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NividLoader height={"325px"} />
          </div>
        ) : salesChart?.length >= 1 ? (
          <Chart options={options} series={series} height={300} type="donut" />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <NoDataContent
              image={require("../../images/emptyfile.png").default}
              title="No Data"
              description="Couldn't find sales data at the moment"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesDoughnutChart;
