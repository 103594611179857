import React, { useEffect, useState, useRef } from "react";
import "./AttendanceSettingsTable.scss";
import { AttendanceVisualsRepresentation } from "../AttendaneVisualsRepresentation/AttendanceVisualsRepresentation";
import Modal from "react-modal";
import { EditTimeLog } from "../EditTimeLog/EditTimeLog";
import { useAttendanceData } from "../store/useStore";
import NepaliDate from "nepali-date";
import { isBrowser } from "react-device-detect";
import Moment from "react-moment";
import { useTeacherProfile } from "../../store/teacherProfileStore";
import { toast } from "react-toastify";
import { DeleteAttendance } from "../Api/DeleteApi";
import { useStudentId } from "../../store/store";

export const AttendanceSettingsTable = ({ singlePage, isStudent }) => {
  const [month, setMonth] = useState("");
  const [visible, setVisible] = useState(false);
  const [selectedLog, setSelectedLog] = useState();
  const { attendanceList, data, loading, late, StudentAttendanceList } =
    useAttendanceData();
  const [nepaliMonth, setNepaliMonth] = useState();
  const [filterMonth, setFilterMonth] = useState([]);
  const { regularizeRequest } = useTeacherProfile();
  const [modalVisible, setModalVisible] = useState();
  const [selectedId, setSelectedId] = useState();
  const student = useStudentId();
  const { isUserAdmin } = useTeacherProfile();
  useEffect(() => {
    const getCurrentNepaliMonth = () => {
      const todayInNepali = new NepaliDate(new Date());
      const nepaliMonthName = todayInNepali.format("MMMM");
      setNepaliMonth(nepaliMonthName);
    };

    getCurrentNepaliMonth();
  }, []);
  useEffect(() => {
    if (isStudent) {
      StudentAttendanceList(student?.studentId, month);
    } else {
      attendanceList(month);
    }
  }, [month]);

  const closeModal = () => {
    setVisible(false);
  };

  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: isBrowser ? "50%" : "90%",
    height: "fit-content",
  };

  const MonthValue = [
    { id: 1, value: "Baisakh" },
    { id: 2, value: "Jestha" },
    { id: 3, value: "Asar" },
    { id: 4, value: "Shrawan" },
    { id: 5, value: "Bhadra" },
    { id: 6, value: "Aswin" },
    { id: 7, value: "Kartik" },
    { id: 8, value: "Mangsir" },
    { id: 9, value: "Poush" },
    { id: 10, value: "Magh" },
    { id: 11, value: "Falgun" },
    { id: 12, value: "Chaitra" },
  ];

  useEffect(() => {
    if (nepaliMonth) {
      const curentMonth = MonthValue?.filter(
        (month) => month?.value === nepaliMonth
      );
      const monthIndex = curentMonth?.[0]?.id;
      // const lastThreeMonths = [];
      // for (let i = 0; i < 4; i++) {
      //   const index = (monthIndex - i - 1 + 12) % 12;
      //   lastThreeMonths.push(MonthValue[index]);
      // }
      setFilterMonth(monthIndex);
    }
  }, [nepaliMonth]);

  function formatTime(timeString) {
    const [hours, minutes] = timeString.split(":");
    return `${parseInt(hours, 10)}h ${parseInt(minutes, 10)}m`;
  }
  const closeModalVisible = () => {
    setModalVisible(false);
  };

  const handleDelete = async () => {
    try {
      const response = await DeleteAttendance(selectedId);
      if (response.data.status === 200) {
        toast(response.data.message);
        attendanceList(month);
        setModalVisible(false);
      }
    } catch (error) {
      toast("Something went wrong");

      console.log(error);
    } finally {
    }
  };

  const formatDuration = (timeString) => {
    const time = Moment(timeString, "HH:mm:ss"); // Specify the format
    // Format the time for 12-hour format with meridian indicator
    const formattedTime = time.format("h:mm A");
    return formattedTime;
  };

  const scrollContainerRef = useRef(null);

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 200, // Adjust this value as needed
        behavior: "smooth",
      });
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -200, // Adjust this value as needed
        behavior: "smooth",
      });
    }
  };
  return (
    <div className="attendance-settings-table">
      <Modal
        isOpen={modalVisible}
        // onAfterOpen={}
        onRequestClose={closeModalVisible}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Teacher Role </p>
            </div>

            <div className="close-icon" onClick={closeModalVisible}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <p className="text-center">
              Are you sure you want to delete this attendance ?
            </p>

            <div className="d-flex-center">
              <button
                className="btn btn-content custom-btn"
                onClick={() => {
                  handleDelete();
                }}
              >
                Yes
              </button>
              <button
                className=" btn btn-light mr-10"
                onClick={() => {
                  closeModalVisible();
                }}
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}
              <p className="modal-title">Edit Entry and Exit time </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <EditTimeLog
              closeModal={closeModal}
              data={selectedLog}
              singlePage={singlePage}
              month={month}
            />
          </div>
        </div>
      </Modal>

      <div className="header-section">
        <div className="header-month-block">
          <div className="month-indicator">
            {month === ""
              ? `Last 30 Days`
              : `${MonthValue[parseInt(month) - 1].value}`}
          </div>
          <div className="scrollable-month-list">
            <div
              onClick={() => {
                scrollLeft();
              }}
            >
              <i class="bx bx-chevron-left"></i>
            </div>

            <div className="filter-month" ref={scrollContainerRef}>
              <div
                className={`month ${month === "" && "active"}`}
                onClick={() => {
                  setMonth("");
                }}
              >
                30 Days
              </div>
              {MonthValue?.slice(0, filterMonth)
                .reverse()
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`month ${month === item.id ? "active" : ""}`}
                      onClick={() => {
                        setMonth(item.id);
                      }}
                    >
                      {item.value}
                    </div>
                  );
                })}
            </div>
            <div
              onClick={() => {
                scrollRight();
              }}
            >
              <i class="bx bx-chevron-right"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="penalty-section-wrap">
        {data?.length > 0 && month && !isStudent && (
          <div className="late-penalty-section">
            <p>
              Total Absent Days :<span>{late?.total_absent || 0}</span>
            </p>
            <p>
              Total Late Days :<span>{late?.total_late_days || 0}</span>
            </p>
            <p>
              Total Deduction Days :
              <span>{late?.total_late_deductions || 0}</span>
            </p>
            <p>
              Overtime :
              <span>
                {late?.total_overtime ? formatTime(late?.total_overtime) : 0}
              </span>
            </p>
            <p>
              Total Shift :
              <span>
                {late?.total_shift ? formatTime(late?.total_shift) : 0}
              </span>
            </p>
          </div>
        )}
      </div>

      <div className="attendance-table-block">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th width="10%">Date</th>
              <th width="10%">Duration</th>
              <th width="40%" style={{ minWidth: "350px" }}>
                Attendance visuals
              </th>
              {!isStudent && <th width="10%">Effective Hours</th>}
              {!isStudent && <th width="10%">Gross hours</th>}
              <th width="10%">Late By </th>

              <th width="5%">Arrival</th>
              {regularizeRequest && !isStudent && (
                <th className="text-center">Action</th>
              )}
            </tr>
          </thead>

          <tbody>
            {loading ? (
              <tr>
                <td colSpan={7}>
                  <p style={{ textAlign: "center" }}>Loading...</p>
                </td>
              </tr>
            ) : (
              <>
                {data?.length > 0 ? (
                  <>
                    {data?.map((item, index) => {
                      const dataVal = item?.attendance;
                      let showEdit = false;
                      const todayDate = new NepaliDate(new Date());
                      const targetDate = item?.attendance?.date;
                      const currentDate = todayDate.format("YYYY/MM/DD");
                      const currentHour = todayDate.getHours();
                      if (targetDate < currentDate) {
                        showEdit = true;
                      } else if (targetDate > currentDate) {
                        showEdit = false;
                      } else {
                        showEdit = true;

                        if (currentHour >= 18) {
                          showEdit = true;
                        } else {
                          showEdit = false;
                        }
                      }
                      const length =
                        dataVal?.attendance_details &&
                        dataVal?.attendance_details?.length;

                      let durationTime;
                      const exitTime =
                        dataVal?.attendance_details?.length > 1 &&
                        dataVal?.attendance_details?.[0] !==
                          dataVal?.attendance_details?.[length - 1]
                          ? dataVal?.attendance_details?.[length - 1]
                          : "N/A";
                      if (
                        dataVal?.attendance_details &&
                        dataVal?.attendance_details?.length > 0 &&
                        dataVal?.attendance_details?.[0] !== null
                      ) {
                        durationTime = `${dataVal?.attendance_details?.[0]} -${exitTime}`;
                      } else {
                        durationTime = "-";
                      }

                      return (
                        <tr key={index}>
                          <td>{dataVal?.date}</td>
                          <td className="text-center">{durationTime}</td>
                          <td style={{ padding: "9px 15px" }}>
                            <AttendanceVisualsRepresentation
                              dataVal={dataVal}
                            />
                          </td>

                          {!isStudent && (
                            <td>
                              <div className="wrap-block">
                                {/* <div className="indicator"></div> */}
                                {dataVal?.effective_time}
                              </div>
                            </td>
                          )}
                          {!isStudent && (
                            <td>
                              <div className="wrap-block">
                                <div style={{ color: "#233A32" }}>
                                  <i className="bx bx-check"></i>
                                </div>

                                {dataVal?.gross_time}
                              </div>
                            </td>
                          )}
                          <td> {dataVal?.late_by}</td>

                          <td>
                            {dataVal?.late !== null && (
                              <> {dataVal?.late ? "Late" : "On time"}</>
                            )}
                          </td>

                          {!isStudent && regularizeRequest && (
                            <td style={{ textAlign: "center" }}>
                              {showEdit ? (
                                <div>
                                  {dataVal?.regularize ? (
                                    <div
                                      className={`btn btn-clock ${
                                        dataVal?.reg_approve
                                          ? "approve-btn"
                                          : "reject-btn"
                                      }`}
                                      title="Regularize request Responded "
                                      onClick={() => {
                                        toast(
                                          `The regularization request has been ${
                                            dataVal?.reg_approve
                                              ? "approved."
                                              : "rejected."
                                          }`
                                        );
                                      }}
                                    >
                                      {dataVal?.reg_approve ? (
                                        <i class="bx bx-check-circle"></i>
                                      ) : (
                                        <i class="bx bx-x-circle"></i>
                                      )}
                                    </div>
                                  ) : (
                                    <div
                                      className="btn btn-edit "
                                      onClick={() => {
                                        setSelectedLog(dataVal);
                                        setVisible(true);
                                      }}
                                      title="Regularize"
                                    >
                                      <i className="bx bx-edit"></i>
                                    </div>
                                  )}

                                  {isUserAdmin && (
                                    <div
                                      className="btn btn-delete ml-10"
                                      title="Delete"
                                      onClick={() => {
                                        setModalVisible(true);
                                        setSelectedId(dataVal?.date);
                                      }}
                                    >
                                      <i class="bx bx-trash"></i>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </>
                ) : (
                  <tr>
                    <td colSpan={7} style={{ textAlign: "center" }}>
                      No data available at the moment
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
