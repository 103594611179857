import React, { useState } from "react";
import "./schoolRegistration.scss";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { BasicInfo } from "../../components/schoolRegistration/BasicInfo";
import { PersonalInfo } from "../../components/schoolRegistration/PersonalInfo";
import { Payment } from "../../components/schoolRegistration/Payment";
import { ToastContainer, toast } from "react-toastify";
import { useSchoolRegistrationStore } from "./store/schoolRegistrationStore";
import { postSchoolRegistration } from "../../components/Api/PostApi";
import Modal from "react-modal";
import { isBrowser } from "react-device-detect";

const steps = [
  "Select campaign settings",
  "Create an ad group",
  "Create an ad",
];

const stepperConfig = [
  {
    label: "Personal Info",
    component: <PersonalInfo />,
  },
  {
    label: "Basic Info",
    component: <BasicInfo />,
  },
  {
    label: "Payment",
    component: <Payment />,
  },
];

export const SchoolRegistration = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeComponent, SetActiveComponent] = useState(stepperConfig[0]);
  const [isLoading, setIsloading] = useState(false);
  const [visible, setVisible] = useState(true);
  const {
    schoolName,
    contact,
    email,
    state,
    district,
    municipality,
    wardNo,
    uploadReceipt,
    guardian_name,
    delegate_photo,
    resetForm,
  } = useSchoolRegistrationStore();

  const closeModal = () => {
    setVisible(false);
  };

  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: isBrowser ? "36%" : "95%",
    height: "fit-content",
    padding: 0,
  };
  const onSubmit = () => {
    setIsloading(true);
    const formData = new FormData();
    formData.append("student_name", schoolName);
    formData.append("phone", contact);
    formData.append("email", email);
    formData.append("country", "Nepal");
    formData.append("current_state", state);
    formData.append("current_municipality", municipality);
    formData.append("ward_no", wardNo);
    formData.append("images[student_signature]", uploadReceipt);
    formData.append("guardian_name", guardian_name);
    formData.append("images[student_image]", delegate_photo);

    postSchoolRegistration(formData)
      .then((res) => {
        toast.success(res.data.message);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setIsloading(false);
        resetForm();
        setActiveStep(0);
        SetActiveComponent(stepperConfig[0]);
      });
  };

  const onNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    SetActiveComponent(stepperConfig[activeStep + 1]);
    return;
  };

  const handleNext = () => {
    if (activeStep === 0) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!schoolName) {
        return toast.error("Please enter School Name.");
      }
      if (!contact) {
        return toast.error("Please enter Contact Number.");
      } else if (contact.length !== 10) {
        return toast.error("Please enter 9*********.");
      }
      if (!email) {
        return toast.error("Please enter Email Address.");
      }
      if (!email.match(emailRegex)) {
        return toast.error("Please enter a valid Email Address.");
      }
      if (!guardian_name) {
        return toast.error("Please enter Delegate Name.");
      }
      if (!delegate_photo?.name) {
        return toast.error("Please upload your Photo.");
      }
      onNext();
    }
    if (activeStep === 1) {
      if (!state) {
        return toast.error("Please select State.");
      }
      if (!district) {
        return toast.error("Please select District.");
      }
      if (!municipality) {
        return toast.error("Please select Municipality.");
      }
      if (!wardNo) {
        return toast.error("Please enter Ward Number.");
      }
      onNext();
    }
    if (activeStep === 2) {
      // if (!uploadReceipt.name) {
      //   return toast.error("Please upload receipt.");
      // }
      onSubmit();
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    SetActiveComponent(stepperConfig[activeStep - 1]);
  };

  return (
    <div className="school-registration">
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-body" style={{padding: "0px"}}>
            <div className="close-modal-icon" onClick={closeModal} >
              <i class="bx bx-x"></i>
            </div>
            <img src="https://i.imgur.com/n0rLQCw.jpg" className="modal-img" alt="imgg" />
          </div>
        </div>
      </Modal>

      <center>
        <img height={100} width={100} src="/images/pabson.jpg" alt="" />
        <h4>PABSON Bagmati AGM 2080</h4>
      </center>
      <ToastContainer />
      <div className="container">
        <div className="school-registration">
          <Stepper activeStep={activeStep} alternativeLabel>
            {stepperConfig.map((item, index) => {
              return (
                <Step key={item?.label + index}>
                  <StepLabel>{item?.label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <div className="stepper-body">{activeComponent?.component}</div>
          <div className="btn-block">
            <button
              className="secondary-btn"
              disabled={activeStep === 0}
              onClick={handleBack}
            >
              Back
            </button>

            <button
              className="main-btn"
              onClick={handleNext}
              disabled={isLoading}
            >
              {activeStep === steps.length - 1
                ? isLoading
                  ? "Loading..."
                  : "Submit"
                : "Next"}
            </button>
          </div>
        </div>

        <center style={{ marginTop: "10px" }}>
          <div style={{ marginTop: "50px" }}>
            <p style={{ fontStyle: "italic", fontSize: "10px" }}>Powered By</p>
            <img height={50} src="/images/nivid.png" alt="" />
          </div>
        </center>
      </div>
    </div>
  );
};
