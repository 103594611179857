import { Field, Form, Formik } from "formik";
import NepaliDate from "nepali-date";
import { NepDatePicker } from "neps-date-picker";
import React, { useContext, useEffect, useState } from "react";
import { MdClear } from "react-icons/md";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { LessonPlanList, LessonPlanStatus } from "../Api/GetApi";
import { teacherClassWorkHomeWork } from "../Api/PostApi";
import { FormikErrorMessage } from "../HorizontalStepper/FormikWrapper";
import { AssessmentContext } from "../TeacherAssessment/TeacherAssessment";
import { useAssignmentListStore } from "../store/useStore";
import "./TeacherActivityForm.scss";

const TeacherActivityForm = (props) => {
  const [dateToken, setDateToken] = useState(
    new NepaliDate().format("YYYY-MM-DD")
  );
  const [clearDate, setClearDate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [homeworkImage, setHomeworkImage] = useState();
  const [classworkImage, setClassworkImage] = useState();
  const {
    gradeToken,
    subjectToken,
    allGradeToken,
    setGradeToken,
    setSubjectToken,
    setAllGradeToken,
  } = useContext(AssessmentContext);
  const { assignmentList } = useAssignmentListStore();
  const [data, setData] = useState();
  const [selectedId, setSelectedId] = useState();
  const [lessionOption, setLessionOption] = useState();
  const [show, setShow] = useState();
  const [planId, setPlanId] = useState();
  const [homework, setHomework] = useState();
  const [classwork, setClasswork] = useState();
  const [validate, setValidate] = useState(false);
  const validationSchema = Yup.object().shape({
    // homeworkDescription: Yup.string().required("This field is required"),
    // classworkDescription: Yup.string().required("This field is required"),
    dateToken: Yup.string().required("This field is required"),
  });
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (validate && !planId) {
      toast.error("Please create Teaching plan and select Lesson");
      return;
    }
    if (!gradeToken) {
      toast.error("Please select grade");
      return;
    }
    if (!subjectToken) {
      toast.error("Please select subject");
      return;
    }

    if (homework === "") {
      toast.error("Please enter homework");
      return;
    }
    if (classwork === "") {
      toast.error("Please enter classwork");
      return;
    }

    if (gradeToken && subjectToken) {
      const valuesArray = allGradeToken
        ?.split(",")
        ?.map((item) => parseInt(item, 10));

      setLoading(true);
      const formData = new FormData();
      formData.append("gradeToken", valuesArray);
      formData.append("lesson_plan_id", planId);
      formData.append("subjectToken", subjectToken);
      formData.append("homework[description]", homework);
      formData.append("classwork[description]", classwork);
      formData.append("classwork[image]", values.classworkImage);
      formData.append("homework[image]", values.homeworkImage);
      formData.append("dateToken", values.dateToken);
      formData.append("activity", values.activities);
      try {
        // Call your async function here
        const res = await teacherClassWorkHomeWork(formData);

        if (res.data.status === "success") {
          toast.success(res.data.message);
          resetForm();
          setHomeworkImage("");
          setClassworkImage("");
          setDateToken(0);
          setSelectedId(0);
          setPlanId(0);
          setData([]);
          setLessionOption([]);
          setHomework("");
          setClasswork("");
          setClearDate(!clearDate);
          setShow(false);
          // setSubjectToken();
          // setGradeToken();
          // setAllGradeToken();
          assignmentList(gradeToken, subjectToken, values.dateToken);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.error("Resource not found:", error);
          toast.error("The requested resource was not found");
        } else {
          console.error("An error occurred:", error);
          toast.error("An error occurred while submitting the form");
        }
      } finally {
        setSubmitting(false);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (gradeToken && subjectToken && dateToken) {
      LessonPlanStatus(gradeToken, subjectToken, dateToken).then((res) => {
        if (res?.data?.status === "success") {
          setData(res?.data?.data);
          setShow(true);
          setSelectedId(res?.data?.data?.[0]?.id);

          if (res?.data?.data?.length > 0) {
            setValidate(true);
          }
        } else {
          setShow(false);
          setSelectedId("");
          setValidate(false);
        }
      });
    }
  }, [gradeToken, subjectToken, dateToken]);

  useEffect(() => {
    if (selectedId) {
      LessonPlanList(selectedId).then((res) => {
        setLessionOption(res?.data?.data);
        setPlanId(res?.data?.data?.[0]?.id);
        setHomework(res?.data?.data?.[0]?.planned_homework);
        setClasswork(res?.data?.data?.[0]?.planned_classwork);
      });
    } else {
      setLessionOption([]);
      setPlanId("");
    }
  }, [selectedId]);

  return (
    <div className="teacher-activity-form-section">
      <Formik
        initialValues={{
          gradeToken: allGradeToken,
          subjectToken: subjectToken,
          homeworkDescription: "",
          classworkDescription: "",
          classworkImage: "",
          homeworkImage: "",
          dateToken: new NepaliDate().format("YYYY-MM-DD"),
          activities: "",
          lesson_plan_id: planId,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="section-gap">
              <div className="row">
                <div className="col-md-8">
                  {/* <div className="input-content-block">
                    <p className="content">Date </p>

                    <NepaliDatePicker
                      inputClassName="input-field"
                      className=""
                      value={dateToken}
                      onChange={(value) => {
                        setDateToken(value);
                        setFieldValue("dateToken", value);
                      }}
                      options={{ calenderLocale: "ne", valueLocale: "en" }}
                    />
                    

                    <FormikErrorMessage name="dateToken" />
                  </div> */}
                  <div className="input-content-block">
                    <p className="content">Date</p>
                    <div className="d-flex">
                      <div
                        title="Clear Date"
                        onClick={() => setClearDate(!clearDate)}
                        className="cross_btn"
                      >
                        <MdClear size={12} />
                      </div>
                      <NepDatePicker
                        reset={clearDate}
                        dateToken={dateToken}
                        onDateSelect={(val) => {
                          setDateToken(val);
                          setFieldValue("dateToken", val);
                        }}
                      />
                    </div>
                    <FormikErrorMessage name="dateToken" />
                  </div>

                  {show && data.length > 0 && (
                    <>
                      <div className="input-content-block">
                        <p className="content">Unit Name </p>

                        <select
                          className="form-control"
                          onChange={(e) => {
                            setSelectedId(e.target.value);
                            setHomework("");
                            setClasswork("");
                          }}
                        >
                          {data?.map((item, index) => {
                            return (
                              <option value={item?.id} key={index}>
                                {item.value}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {lessionOption?.length > 0 && (
                        <div className="input-content-block">
                          <p className="content">Lesson Plan </p>

                          <div className="lession-radio-container">
                            {lessionOption?.map((item, index) => {
                              const isChecked = planId === item.id;
                              return (
                                <div
                                  key={index}
                                  className={`radio-card ${
                                    isChecked && "selected"
                                  } `}
                                  onClick={() => {
                                    setPlanId(item.id);
                                    setFieldValue("lesson_plan_id", item.id);
                                    if (item.planned_homework !== null) {
                                      // setFieldValue(
                                      //   "homeworkDescription",
                                      //   item.planned_homework
                                      // );
                                      setHomework(item.planned_homework);
                                    }
                                    if (item.planned_classwork !== null) {
                                      setClasswork(item.planned_classwork);
                                      // setFieldValue(
                                      //   "classworkDescription",
                                      //   item.planned_classwork
                                      // );
                                    }
                                  }}
                                >
                                  <input
                                    key={item.id} // Add a unique key for React to efficiently update the DOM
                                    id={item.id}
                                    type="radio"
                                    name="lession"
                                    value={item.id} // Assign a value for each radio button
                                    checked={isChecked} // Ensure only the selected option is checked
                                    onChange={() => setPlanId(item.id)} // Update the selectedOption state when the radio button is clicked
                                  />
                                  {item.value}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="input-content-block">
                    <p className="content"> Activities</p>
                    <Field
                      name="activities"
                      type="text"
                      className="form-control"
                    />
                  </div>

                  <div className="input-content-block">
                    <p className="content"> Home work Description </p>
                    <Field
                      as="textarea"
                      name="homeworkDescription"
                      type="text"
                      className="form-control"
                      value={homework}
                      onChange={(e) => {
                        setHomework(e.target.value);
                        setFieldValue("homeworkDescription", e.target.value);
                      }}
                    />

                    <FormikErrorMessage name="homeworkDescription" />
                  </div>

                  <div className="input-content-block">
                    <p className="content"> Class Work Description</p>
                    <Field
                      as="textarea"
                      name="classworkDescription"
                      type="text"
                      className="form-control"
                      value={classwork}
                      onChange={(e) => {
                        setClasswork(e.target.value);
                        setFieldValue("classworkDescription", e.target.value);
                      }}
                    />

                    <FormikErrorMessage name="classworkDescription" />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="image-upload-section">
                    <div className="mb-10">
                      <p className="label-content">Homework Image </p>
                      <label htmlFor="upload-button1">
                        <div className="details-wrap-content">
                          <input
                            type="file"
                            className="img-area"
                            id="upload-button1"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const imageUrl = URL.createObjectURL(file);
                                setHomeworkImage(imageUrl);
                                setFieldValue("homeworkImage", file);
                              }
                            }}
                          />
                          <div className="image-upload-block">
                            <div className="image-upload-index">
                              <img
                                src={
                                  require("../../images/file upload.png")
                                    .default
                                }
                                className="img-icon"
                                alt="img"
                              />
                            </div>
                            <p className="small-content label">
                              <span>Choose Files</span> from the device
                            </p>
                          </div>
                        </div>
                      </label>
                      {homeworkImage && (
                        <>
                          <p className="label-content">Uploaded Image:</p>

                          <div className="show-image-block">
                            <img src={homeworkImage} alt="img" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="image-upload-section">
                    <div>
                      <p className="label-content">Classwork Image</p>
                      <label htmlFor="upload-button2">
                        <div className="details-wrap-content">
                          <input
                            type="file"
                            className="img-area"
                            id="upload-button2"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file) {
                                const classImageUrl = URL.createObjectURL(file);

                                setClassworkImage(classImageUrl);
                                setFieldValue("classworkImage", file);
                              }
                            }}
                          />
                          <div className="image-upload-block">
                            <div className="image-upload-index">
                              <img
                                src={
                                  require("../../images/file upload.png")
                                    .default
                                }
                                className="img-icon"
                                alt="img"
                              />
                            </div>
                            <p className="small-content label">
                              <span>Choose Files</span> from the device
                            </p>
                          </div>
                        </div>
                      </label>

                      {classworkImage && (
                        <>
                          <p className="label-content">Uploaded Image:</p>

                          <div className="show-image-block">
                            <img src={classworkImage} alt="img" />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className={`btn btn-content custom-btn m-t-2 ${
                  loading && "disable-cursor"
                }`}
                disabled={loading}
              >
                {loading ? "Submitting" : "Submit"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TeacherActivityForm;
