
import { StudentProfile } from "../../components/StudentProfile/studentInfo/StudentProfile";
import { SubjectsList } from "../../components/StudentProfile/subjectsList/StudentProfile";
import { TaskCompletion } from "../../components/StudentProfile/taskCompletion/StudentProfile";
import "./studentProfile.scss";

export const studentProfile = () => {
  return (
    <div className="student-profile section-gap">
      <div className="container">
        <div className="student-profile-section">
          <div className="student-profile-container">
            <StudentProfile />
            <TaskCompletion />
            <SubjectsList />
          </div>
        </div>
      </div>
    </div>
  );
};
