import React, { useEffect, useState, useCallback } from "react";
import "./date.scss";
import { styled } from "@mui/material/styles";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { getCalendar, getTeacherOutcome } from "../Api/GetApi";
import { teacherActivity } from "../Api/PostApi";
// import NepaliDate from "nepali-date";

import SchedulingOutcome from "../SchedulinOutcome.jsx/OutcomeScheduling";

import NepaliDate from "nepali-date-converter";
import Accordion from "@mui/material/Accordion";

import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const MonthValue = [
  { id: "1", value: "Baisakh" },
  { id: "2", value: "Jestha" },
  { id: "3", value: "Asar" },
  { id: "4", value: "Shrawan" },
  { id: "5", value: "Bhadra" },
  { id: "6", value: "Ashoj" },
  { id: "7", value: "Kartik" },
  { id: "8", value: "Mangsir" },
  { id: "9", value: "Poush" },
  { id: "10", value: "Magh" },
  { id: "11", value: "Falgun" },
  { id: "12", value: "Chaitra" },
];
const nameStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    backgroundColor: "#f0ffff",
    width: "30%",
    height: "250px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

const customStyles = {
  content: {
    top: "60%",
    left: "50%",
    right: "auto",
    backgroundColor: "#f0ffff",
    width: "50%",
    height: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.65)",
  },
};

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

function GridDate() {
  const rows = 6;
  const cols = 7;
  const currentDate = new Date();
  const currentDateInNepali = new NepaliDate(currentDate);
  const currentYearInNepali = currentDateInNepali.getYear();
  const [month, setMonth] = useState("");
  const [calendarData, setCalendarData] = useState([]);
  const [nepaliMonth, setNepaliMonth] = useState();
  const [filterMonth, setFilterMonth] = useState([]);
  const [isOutcomeOpen, setIsOutcomeOpen] = useState(false);

  const [selectedYear, setSelectedYear] = useState(currentYearInNepali);
  const [isNameOpen, setIsNameOpen] = useState(false);

  const [name, setName] = useState();

  const [clickedDate, setClickedDate] = useState(null);
  const [visitor, setVisitor] = useState();
  const [expandedAccordion, setExpandedAccordion] = useState(null);
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };
  const value = name?.filter((item) => item.visitor_id === visitor);

  const clikedMonth = MonthValue[parseInt(month) - 1]?.value;
  const clikedYear = selectedYear;

  useEffect(() => {
    const currentDateInNepali = new NepaliDate(new Date());
    const currentMonthIndex = currentDateInNepali.getMonth() + 1;

    if (!month) {
      setMonth(currentMonthIndex.toString());
    }

    getCalendar(month, selectedYear)
      .then((res) => {
        setCalendarData(res.data?.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [month, selectedYear]);

  useEffect(() => {
    const getCurrentNepaliMonth = () => {
      const todayInNepali = new NepaliDate(new Date());
      const nepaliMonthName = todayInNepali.format("MMMM");
      setNepaliMonth(nepaliMonthName);
    };

    getCurrentNepaliMonth();
  }, []);

  useEffect(() => {
    const monthIndex = MonthValue.findIndex(
      (month) => month?.value === nepaliMonth
    );

    if (monthIndex === 0) {
      const lastYearMonth = [...MonthValue.slice(-3), MonthValue[0]];
      setFilterMonth(lastYearMonth);
    } else {
      const previousMonth = MonthValue.slice(
        Math.max(0, monthIndex - 3),
        monthIndex + 1
      );

      setFilterMonth(previousMonth);
    }
  }, [nepaliMonth]);
  const openName = (meeting_data, clickedDate) => {
    setIsNameOpen(true);
    setName(JSON.parse(meeting_data));
    setClickedDate(clickedDate);
  };

  const closeName = () => {
    setIsNameOpen(false);
  };

  const closeModal = () => {
    setIsOutcomeOpen(false);
  };

  const handleSwitchChange = (cellData) => {
    const updatedCalendarData = calendarData.map((item) =>
      item.data === cellData ? { ...item, available: !item.available } : item
    );

    const updatedCell = updatedCalendarData.find(
      (item) => item.data === cellData
    );
    if (!updatedCell) return;

    const data = {
      day_token: updatedCell.day_token,
      available: updatedCell.available ? 1 : 0,
    };

    teacherActivity(data).catch((error) => {
      console.error("Error:", error);
    });

    setCalendarData(updatedCalendarData);
  };

  const renderGrid = () => {
    let grid = [];

    for (let i = 0; i < rows; i++) {
      let row = [];
      for (let j = 0; j < cols; j++) {
        const cell = calendarData.find(
          (item) => item?.x === i && item?.y === j
        );
        const notification =
          cell && cell.completed_meeting ? (
            <div className="notification">{cell?.completed_meeting}</div>
          ) : null;

        const missedMetting =
          cell && cell.missed_metting ? (
            <div>{cell?.missed_metting}</div>
          ) : null;
        const showCheckbox = cell !== undefined;

        row.push(
          <div
            className={
              "cell" + (cell && cell.completed_meeting !== 0 ? " done" : "")
            }
            key={`${i}-${j}`}
          >
            {cell ? cell.data : ""}
            {showCheckbox && (
              <FormControlLabel
                control={
                  <AntSwitch
                    className="switch"
                    checked={cell.available}
                    onChange={() => {
                      handleSwitchChange(cell.data);
                    }}
                  />
                }
              />
            )}
            <div className="metting">
              <p
                className={
                  cell && cell.missed_metting !== 0 ? "missed_meeting" : ""
                }
              >
                {missedMetting}
              </p>
              <p
                className={
                  cell && cell.completed_meeting !== 0
                    ? "visit-notification"
                    : ""
                }
                onClick={() => openName(cell.completed_meetings, cell?.data)}
              >
                {notification}
              </p>

              <p
                className={
                  cell && cell.missed_metting !== 0 ? "missed_meeting" : ""
                }
              >
                {missedMetting}
              </p>
            </div>
          </div>
        );
      }
      grid.push(
        <div className="row" key={i}>
          {row}
        </div>
      );
    }

    return grid;
  };

  return (
    <div className="teacher-calendar">
      <div className="time-zone">
        <div className="header-section">
          <div className="header-month-block">
            <div className="month-indicator">
              {month === ""
                ? `Baisakh`
                : `${MonthValue[parseInt(month) - 1].value}`}
            </div>
            <div className="filter-month">
              {filterMonth
                ?.slice()
                ?.reverse()
                .map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`month ${month === item.id ? "active" : ""}`}
                      onClick={() => {
                        setMonth(item.id);
                      }}
                    >
                      {item.value}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="day">
        <p>Sun</p>
        <p>Mon</p>
        <p>Tue</p>
        <p>Wed</p>
        <p>Thu</p>
        <p>Fri</p>
        <p>Sat</p>
      </div>
      <div className="grid">{renderGrid()}</div>
      <Modal
        isOpen={isNameOpen}
        onRequestClose={closeName}
        style={nameStyles}
        contentLabel="Meeting Modal"
      >
        <IoMdClose className="close" onClick={closeName} />
        <div className="visitor-visited">
          <h5>
            {clickedDate} {clikedMonth}, {clikedYear}
          </h5>
          <IoMdClose className="close_action" onClick={closeName} />
        </div>
        <div className="visitor-card">
          {name?.map((item) => {
            return (
              <div>
                <Accordion
                  expanded={expandedAccordion === item.visitor_id}
                  onChange={handleAccordionChange(item.visitor_id)}
                >
                  <AccordionSummary
                    key={item.visitor_id}
                    onClick={() => {
                      setVisitor(item.visitor_id);
                    }}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id={item?.visitor_id}
                  >
                    <div className="visitor_reason">
                      <p className="visitor_name">
                        <span className="first-letter">
                          {item?.request_by.charAt(0)}
                        </span>
                        {item?.request_by}
                      </p>
                      <div>
                        <p className="visitor_topic">
                          Purpose: {item?.purpose}
                        </p>
                      </div>
                    </div>
                  </AccordionSummary>

                  <>
                    <hr className="hr_line" />
                    <SchedulingOutcome visitorId={value} close={closeName} />
                  </>
                </Accordion>
              </div>
            );
          })}
        </div>
      </Modal>
    </div>
  );
}

export default GridDate;
