import Modal from "react-awesome-modal";
import React, { useState, useEffect, useRef } from "react";
import "./visitorModal.scss";
import { GrClose } from "react-icons/gr";
import { NepDatePicker } from "neps-date-picker";
import { Formik, Field, Form } from "formik";
import Select from "react-select";
import {
  getAppointmentTeacherList,
  getParentAppointments,
  getTeacherFreeSchedule,
} from "../Api/GetApi";
import "bootstrap/dist/css/bootstrap.css";
import { postTeacherScheduleRequest } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useAppointmentStore } from "../../store/appointmentListStore";

const VisitorModal = ({ visible, setVisible }) => {
  const { setAppointmentList, appointmentList } = useAppointmentStore();
  const [selectedIndex, setSelectedIndex] = useState();
  const [requestTime, setRequestTime] = useState();
  const [date, setDate] = useState(null);
  const [teacherList, setTeacherList] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [teacherID, setTeacherID] = useState(null);
  const formRef = useRef(null);
  const closeModal = (event) => {
    event.stopPropagation();
    setVisible(false);
    setSelectedIndex("");
    setSelectedTime("");
    setTeacherID(null);
    setDate(null);
    formRef.current && formRef.current.setFieldValue("purpose", "");
  };
  const initialValues = {
    purpose: "",
    time: "",
  };

  useEffect(() => {
    const data = {
      teacher_id: teacherID,
      day_token: date,
    };
    if (data.day_token && data.teacher_id) {
      try {
        getTeacherFreeSchedule(data).then((res) => {
          setRequestTime(res?.data);
        });
      } catch (error) {
        toast.error("Something went wrong on fetching the available time ");
        console.log(error);
      }
    }
  }, [date, teacherID]);

  const onClickButton = (index) => {
    if (selectedIndex) {
      setSelectedIndex("");
    }
    setSelectedIndex(index);
    setSelectedTime(requestTime.data[index]);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const postData = {
      teacher_id: teacherID,
      day_token: date,
      purpose: values.purpose,
      time: selectedTime.time,
    };
    if (
      !postData.teacher_id ||
      !postData.day_token ||
      !postData.purpose ||
      !postData.time
    ) {
      toast.error("All fields are required");
      return;
    }
   
    try {
      await postTeacherScheduleRequest(postData).then((res) => {
        toast.success(res?.data?.message);
      });
      await getParentAppointments().then((res) => {
        setAppointmentList(res?.data?.data);
      });
      setVisible(false);
      setSelectedIndex("");
      setSelectedTime("");
      setDate(null);
      setTeacherID(null);
      resetForm({ values: initialValues });
    } catch (error) {
      toast.error("Something went wrong");
      setVisible(false);
      setSelectedIndex("");
      setSelectedTime("");
      setDate(null);
      setTeacherID(null);
      resetForm({ values: initialValues });
    }
  };

  useEffect(() => {
    getAppointmentTeacherList().then((res) => {
      const teacherList = res?.data?.data?.map((item, index) => ({
        value: item.id,
        label: item.name,
      }));
      setTeacherList(teacherList);
    });
  }, []);

  const handleChange = (selectedOption) => {
    setTeacherID(selectedOption.value);
  };

  return (
    <Modal visible={visible} onClickAway={closeModal}>
      <div className="visitor-modal-container">
        <div onClick={closeModal} className="close-btn">
          <GrClose size={15} />
        </div>
        <div className="title-block header">
          <h1 className="title">Take Appointment</h1>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          <Form>
            <div className="form-section">
              <div>
                <label
                  htmlFor="teacher"
                  className="block-title d-flex justify-content-between"
                >
                  Select Teacher:
                  {teacherID === null && (
                    <p style={{ color: "red" }}>*Required</p>
                  )}
                </label>
                <Select options={teacherList} onChange={handleChange} />
              </div>
              <div>
                <label htmlFor="reason" className="block-title">
                  Reason for visit
                </label>
                <br />
                <Field
                  as="textarea"
                  name="purpose"
                  className="form-control"
                  id="reason"
                  style={{ width: "100%", minHeigth: "30px" }}
                />
              </div>
              <div>
                <label
                  htmlFor="date"
                  className="block-title d-flex justify-content-between"
                >
                  Date:
                  {(date === null || date === "") && (
                    <p style={{ color: "red" }}>*Required</p>
                  )}
                </label>
                <NepDatePicker
                  name="date"
                  className="date-picker"
                  onDateSelect={(val) => {
                    setDate(val);
                    setSelectedIndex(null);
                  }}
                />
              </div>
              <div>
                {date !== "" && date !== null && teacherID !== null && (
                  <>
                    <label htmlFor="time" className="block-title">
                      Select Time:
                    </label>
                    <br />
                    {requestTime?.data === null && (
                      <p>{requestTime?.message}</p>
                    )}
                    {requestTime?.data?.length === 0 && (
                      <p>No Schedule Available this day</p>
                    )}
                    {requestTime?.data?.map((item, index) => (
                      <button
                        key={index}
                        type="button"
                        disabled={!item.available}
                        onClick={() => onClickButton(index)}
                        className={
                          item.available
                            ? selectedIndex === index
                              ? "selectBtn active"
                              : "selectBtn"
                            : "selectBtn disabledBtn"
                        }
                      >
                        {item.time}
                      </button>
                    ))}
                  </>
                )}
              </div>
            </div>
            <button type="submit" className="btn btn-content ml-4">
              Submit
            </button>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};

export default VisitorModal;
