import { useFormikContext } from "formik";
import React, { useEffect, useMemo } from "react";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { useExamMarksStore } from "../../store/examMarksStore";
import { produce } from "immer";

export const AddExamMarks = () => {
  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const examMarksState = useExamMarksStore((state) => state);

  const handleChange = (e, rowIndex, colIndex) => {
    e.preventDefault?.();
    const updatedItem = produce(
      examMarksState?.submitStudentData[colIndex]?.data[rowIndex],
      (draft) => {
        draft[e.target.name] = e.target.value;
      }
  );
    examMarksState?.setSubmitStudentData?.(
      produce(examMarksState?.submitStudentData, (draft) => {
        draft[colIndex].data[rowIndex] = updatedItem;
      })
    );
  };

  useEffect(() => {
    if (examMarksState?.subjectWeightage || examMarksState?.selectedOption) {
      const weightage_theory = values?.internal_eval
        ? examMarksState?.selectedOption?.map((item) => {
            return item?.weightage;
          })
        : [examMarksState?.subjectWeightage?.max_theory];
      const weightage_practical =
        examMarksState?.subjectWeightage?.max_practical || 0;
      examMarksState?.setWeightageTheory(weightage_theory);
      examMarksState?.setWeightagePractical(weightage_practical);
    }
  }, [
    values?.internal_eval,
    examMarksState?.selectedOption,
    examMarksState?.subjectWeightage,
  ]);

  const updateTheoryMarks = () => {
    const updatedState = produce(examMarksState?.submitStudentData, (draft) => {
      draft.forEach((submitStudent) => {
        const matchingStudent = examMarksState?.examStudentData?.find(
          (examStudent) =>
            examStudent.exam_subject_token === submitStudent.exam_subject_token
        );
        if (matchingStudent) {
          matchingStudent.data.forEach((matchingStudentData) => {
            const studentToUpdate = submitStudent.data.find(
              (student) =>
                parseInt(student.rollNo) ===
                parseInt(matchingStudentData.rollNo)
            );
            if (studentToUpdate) {
              studentToUpdate.theory_mark =
                matchingStudentData.theory_mark ?? 0;
              studentToUpdate.practical_mark =
                matchingStudentData.pratical_mark ?? 0;
            }
          });
        }
      });
    });
    examMarksState?.setSubmitStudentData(updatedState);
  };

  useEffect(() => {
    if (
      examMarksState?.submitStudentData?.length > 0 ||
      examMarksState?.examStudentData?.length > 0
    ) {
      updateTheoryMarks();
    }
  }, [examMarksState?.examStudentData?.[0]?.exam_subject_token]);

  const totalTheoryMark = useMemo(() => {
    return examMarksState?.submitStudentData?.[0]?.data?.reduce(
      (accumulator, currentValue) => accumulator + currentValue.theory_mark,
      0
    );
  }, [examMarksState?.submitStudentData]);

  return (
    <div className="table-section">
      {examMarksState?.submitStudentData &&
      examMarksState?.submitStudentData?.length > 0 ? (
        <table className="table mb-8">
          <thead className="thead-light">
            <tr>
              <th width="10%">Roll</th>
              <th width="50%">Student Name</th>
              {values.internal_eval ? (
                <>
                  {examMarksState?.selectedOption?.map((item, index) => {
                    return (
                      <th width="25%" key={index}>
                        <div className="full-mark-section flex-dir">
                          <p>{item?.label} </p>
                          <p>
                            weightage:<span>{item?.weightage}</span>
                          </p>
                        </div>
                      </th>
                    );
                  })}
                </>
              ) : (
                <>
                  <th width="25%">
                    <div class="full-mark-section">
                      <p>Theory</p>
                      <p>
                        (Fullmarks:{" "}
                        <span>
                          {examMarksState?.subjectWeightage?.max_theory}
                        </span>
                        )
                      </p>
                    </div>
                  </th>
                  {!values.cas_eval &&
                    examMarksState?.subjectWeightage?.max_practical > 0 && (
                      <th width="25%">
                        <div class="full-mark-section">
                          <p> Practical</p>
                          <p>
                            {" "}
                            (Fullmarks:
                            <span>
                              {examMarksState?.subjectWeightage?.max_practical}
                            </span>
                            )
                          </p>
                        </div>
                      </th>
                    )}
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {examMarksState?.submitStudentData?.[0]?.data.map(
              (item, rowIndex) => (
                <tr key={rowIndex}>
                  <td width="10%">{item?.rollNo}</td>
                  <td width="50%">{item?.studentName}</td>
                  {examMarksState?.submitStudentData?.map((itm, colIndex) => {
                    return (
                      <>
                        <td width="25%" key={colIndex}>
                          <div className="input-field-block">
                            <input
                              className="input-field"
                              type="number"
                              placeholder="0"
                              name={`theory_mark`}
                              value={itm?.data?.[rowIndex]?.theory_mark}
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) >
                                  examMarksState?.weightageTheory?.[colIndex]
                                ) {
                                  e.preventDefault();
                                  examMarksState?.setOpenSnackBar(true);
                                  examMarksState?.setSnackMessge(
                                    `You can't enter value greater than ${examMarksState?.weightageTheory?.[colIndex]}`
                                  );
                                  examMarksState?.setSnackStatus(false);
                                } else {
                                  handleChange(e, rowIndex, colIndex);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (!/^[0-9.]$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                // Clear the value when the input field is focused
                                if (e.target.value === "0") {
                                  e.target.value = "";
                                }
                              }}
                              onBlur={(e) => {
                                if (!e.target.value) {
                                  e.target.value = "0";
                                  handleChange(e, rowIndex, colIndex);
                                }
                              }}
                            />
                          </div>
                        </td>
                        {!values.internal_eval &&
                          !values.cas_eval &&
                          examMarksState?.subjectWeightage?.max_practical >
                            0 && (
                            <td width="25%">
                              <div className="input-field-block">
                                <input
                                  className="input-field"
                                  type="number"
                                  placeholder="0"
                                  name={`practical_mark`}
                                  value={itm?.data?.[rowIndex]?.practical_mark}
                                  onChange={(e) => {
                                    if (
                                      Number(e.target.value) >
                                      examMarksState?.weightagePractical
                                    ) {
                                      e.preventDefault();
                                      examMarksState?.setOpenSnackBar(true);
                                      examMarksState?.setSnackMessge(
                                        `You can't enter value greater than ${examMarksState?.weightagePractical}`
                                      );
                                      examMarksState?.setSnackStatus(false);
                                    } else {
                                      handleChange(e, rowIndex, colIndex);
                                    }
                                  }}
                                  onKeyPress={(event) => {
                                    if (!/^[0-9.]$/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                  onFocus={(e) => {
                                    // Clear the value when the input field is focused
                                    if (e.target.value === "0") {
                                      e.target.value = "";
                                    }
                                  }}
                                  onBlur={(e) => {
                                    // If the field is empty when it loses focus, reset it to "0"
                                    if (!e.target.value) {
                                      e.target.value = "0";
                                      handleChange(e, rowIndex, colIndex);
                                    }
                                  }}
                                />
                              </div>
                            </td>
                          )}
                      </>
                    );
                  })}
                </tr>
              )
            )}
          </tbody>
        </table>
      ) : (
        <NoDataContent
          title=""
          image={require("../../images/emptyfile.png").default}
          description={
            "Select exam, grade, subject and evaluation type for students list to appear here"
          }
        />
      )}
      {examMarksState?.submitStudentData?.[0]?.data?.length > 0 ? (
        <div className="btn-section btn-block">
          <button className="main-btn" type="submit" disabled={isSubmitting}>
            {totalTheoryMark > 0 ? (
              <>
                {isSubmitting ? (
                  <span>
                    Updating Marks...{" "}
                    <i className="bx bx-loader-circle bx-spin"></i>
                  </span>
                ) : (
                  "Update Marks"
                )}
              </>
            ) : (
              <>
                {isSubmitting ? (
                  <span>
                    Saving Marks...{" "}
                    <i className="bx bx-loader-circle bx-spin"></i>
                  </span>
                ) : (
                  <span>Save Marks</span>
                )}
              </>
            )}
          </button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
