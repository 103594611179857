import React from "react";
import { Link } from "react-router-dom";

class Home extends React.Component {
  render() {
    return (
      <>
        <h1>Nivid</h1>
        <Link to="/canteen">
          <button className="main-btn">Canteen</button>
        </Link>
        <Link to="/add_details">
          <button className="main-btn">Add Details</button>
        </Link>
        <Link to="/plan_schedule">
          <button className="main-btn">Plan Schedule</button>
        </Link>
        <Link to="/single_plan_card">
          <button className="main-btn">Single plan card</button>
        </Link>
        <Link to="/timeline">
          <button className="main-btn">Timeline</button>
        </Link>
        <Link to="/teaching_plan">
          <button className="main-btn">Teaching Plan</button>
        </Link>
        <Link to="/new_plan">
          <button className="main-btn">New Plan</button>
        </Link>
        <Link to="/result">
          <button className="main-btn">Result</button>
        </Link>
        <Link to="/admission-form">
          <button className="main-btn">Admission Form</button>
        </Link>
        <Link to="/exam_marks">
          <button className="main-btn">Exam Marks</button>
        </Link>
        <Link to="/elearning">
          <button className="main-btn">ELearning</button>
        </Link>
        <Link to="/eca-header">
          <button className="main-btn">ECA Header</button>
        </Link>
        <Link to="/attendance-visuals">
          <button className="main-btn">Attendance Log</button>
        </Link>
        <Link to="/student_profile">
          <button className="main-btn">Student Profile</button>
        </Link>
      </>
    );
  }
}

export default Home;
