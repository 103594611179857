import React from "react";
import "./ContactInfo.scss";

export const ContactInfo = () => {
  return (
    <div className="contact-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <div className="admission-header">
              <h4 className="main-title school-name">Kathmandu World School</h4>
              <div className="address-wrapper d-column">
                <div className="address-info">
                  <p className="main-content place-name">Surya Binayak - 7</p>
                  <span className="side-bar"></span>
                  <p className="main-content address">
                    Gundu, Bhaktapur, Nepal
                  </p>
                </div>
                <div className="address-info">
                  <p className="main-content phone-no">5090337</p>
                  <span className="side-bar"></span>
                  <p className="main-content email">info@kws.edu.np</p>
                </div>
              </div>
            </div>

            <div className="option-head">
              {/* <div className="input-field-block">
                    <p className="main-content uppercase">
                      Grade Applied For<span className="star">*</span>:
                    </p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Grade 1", "Grade 2", "Grade 3"]?.map((item) => {
                        return <option key={item}>{item}</option>;
                      })}
                    </select>
                  </div> */}
              <div className="uppercase small-title center">
                Contact Address
              </div>
            </div>
            <div className="address-detail">
              <div className="uppercase medium-content blue-text">
                Permanent Address
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Country:</p>
                    <input
                      className="input-field"
                      value="Nepal"
                      name="country"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">State:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {[
                        "Province No 1",
                        "Province No 2",
                        "Province No 3",
                        "Province No 4",
                        "Province No 5",
                        "Province No 6",
                        "Province No 7",
                      ]?.map((item) => {
                        return (
                          <option key={item} name="state">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">District:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {[
                        "Kavre",
                        "Bhaktapur",
                        "Lalitpur",
                        "Sindhupalchowk",
                      ]?.map((item) => {
                        return (
                          <option key={item} name="district">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Municipality:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Panauti", "Banepa", "Dhulikhel"]?.map((item) => {
                        return (
                          <option key={item} name="municipality">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Ward No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="ward"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Street:</p>
                    <input className="input-field" name="street" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">House No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="houseNo"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="address-detail">
              <div className="row">
                <div className="col-md-6">
                  <div className="uppercase medium-content blue-text">
                    Current Contact Address
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="checkbox-block">
                    <div className="radio-label d-flex">
                      <input type="checkbox" name="same" />
                      <label className="main-content">
                        Is same as Permanent Address
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Country:</p>
                    <input
                      className="input-field"
                      value="Nepal"
                      name="country"
                    />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">State:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {[
                        "Province No 1",
                        "Province No 2",
                        "Province No 3",
                        "Province No 4",
                        "Province No 5",
                        "Province No 6",
                        "Province No 7",
                      ]?.map((item) => {
                        return (
                          <option key={item} name="state">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">District:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {[
                        "Kavre",
                        "Bhaktapur",
                        "Lalitpur",
                        "Sindhupalchowk",
                      ]?.map((item) => {
                        return (
                          <option key={item} name="district">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Municipality:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Panauti", "Banepa", "Dhulikhel"]?.map((item) => {
                        return (
                          <option key={item} name="municipality">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Ward No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="ward"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Street:</p>
                    <input className="input-field" name="street" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">House No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="houseNo"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
