import React, { useEffect } from "react";
import { useState } from "react";
import "./Meeting.scss";
import "nepali-datepicker-reactjs/dist/index.css";
import { NepDatePicker } from "neps-date-picker";
import { FaAngleRight } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";

import { NoDataContent } from "../NoDataContent/NoDataContent";
import { getHodMeeting, getHodMeetingGroup } from "../Api/GetApi";
import Notice from "../notice/notice";
import Modal from "react-modal";
import { useHodMeeting } from "../../store/hodDashboardStore";
import { isBrowser } from "react-device-detect";
import { MdClear } from "react-icons/md";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import NividLoader from "../NividLoader/NividLoader";

function Marks() {
  const monthNames = [
    "Baisakh",
    "Jestha",
    "Asar",
    "Shrawan",
    "bhadra",
    "Asoj",
    "Kartik",
    "Mangsir",
    "Poush",
    "Magh",
    "Falgun",
    "Chaitra",
  ];
  const {popupType, setPopupType} = useTeacherDashboardStore();
  const [date, setDate] = useState("");
  const[clearDate,setClearDate] = useState(false)

  const [showAll, setShowAll] = useState(false);

  const { HodMeeting,loading, fetchHodMeeting } = useHodMeeting();

  const [data, setData] = useState([]);
  // console.log(data);

  useEffect(() => {
    setData(HodMeeting);
  }, [HodMeeting]);

  const [allGroup, setAllGroup] = useState([]);
  const [group, setGroup] = useState("");
  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: isBrowser ? "50%" : "95%",
    height: "fit-content",
  };

  useEffect(() => {
    fetchHodMeeting(group, date);
  }, [group, date]);

  useEffect(() => {
    getHodMeetingGroup().then((response) => {
      if (response?.data?.status === 1) {
        setAllGroup(response?.data?.data);
      } else {
        setAllGroup([]);
      }
    });
  }, []);

  const modifiedData = showAll ? data : data?.slice(0, 3);

  const closeModal = () => {
    setPopupType("");
  };
  return (
    <div className="meeting">
      <Modal
        isOpen={popupType === "meetingNotice"}
        // onAfterOpen={}
        onRequestClose={() => {
          setPopupType("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block"></div>
              <p className="modal-title">Add Notice </p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setPopupType("");
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <Notice
              date={date}
              header={false}
              isDashboard={true}
              closeModal={closeModal}
            />
          </div>
        </div>
      </Modal>
      <div className="frame">
        <div className="first-frame-start">
          <div className="top-frame d-flex">
            <div className="heading-frame">
              <p className="heading">Meeting Notice</p>
            </div>
            <button className="button-frame bg-white d-flex">
              <FaPlus />
              <div className="btn-frame d-flex">
                <p className="text" onClick={() => setPopupType("meetingNotice")}>
                  Add Meeting Notice
                </p>
              </div>
            </button>
          </div>
          <div style={{paddingBottom:"10px"}} className="low-frame d-flex mt-2">
            <select
            style={{width:"190px"}}
              className="input-field"
              onChange={(e) => setGroup(e.target.value)}
            >
              <option value="" className="text-frame">
                Select Meeting Group
              </option>
              {allGroup?.map((item, index) => {
                return <option key={index}>{item.group}</option>;
              })}
            </select>
            <div className="right-frame-of justify-content-end d-flex">
              <div className="middle-frame  d-flex">
                <div className="date-frame d-flex">
                  <div title="Clear Date"  onClick={()=>setClearDate(!clearDate)}  className="cross_btn"><MdClear size={12}/></div>
                  <NepDatePicker reset={clearDate} onDateSelect={(val) => setDate(val)} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="last-frame mt-2">
          {loading ? <div><NividLoader height={"400px"} /> </div> :
                 data?.length == 0  ? (
                  <NoDataContent
                    title="No Data Found"
                    description="Data will be appeared when updated"
                    image={require("../../images/emptyfile.png").default}
                  />
                ) : (
                  modifiedData?.map((s, index) => {
                    return (
                      <div key={index} className="layout mt-2">
                        <div className="first-layout d-flex">
                          <div
                            style={{
                              display: "flex",
                              gap: "15px",
                              alignItems: "center",
                            }}
                          >
                            <div className="date-layout ">
                              <div className="d-flex flex-column">
                                <div className="text-month month-layout">
                                  {monthNames[s.month - 1]}{" "}
                                </div>
                                <div className="date-numm">{s.day} </div>
                              </div>
                            </div>
                            <div className="second-layout">
                              <div className="meet-layout d-flex justify-content-between">
                                <p className="meet-text">{s.title}</p>
                              </div>
                              <div className="time-layout">
                                <p className="time-text">{s.time}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="line mt-2"></div>
                        <div className="last-layout-lay">
                          <p className="last-text">{s.description}</p>
                        </div>
                      </div>
                    );
                  })
                )}

          
     
        </div>
        <div>
     
            <p
              type="button"
              className={`tt mt-3 ${data?.length > 0 ? "visible" : "hidden"}`}
              onClick={() => {
                setShowAll(!showAll);
              }}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {!showAll ? "View All" : "View Less"}
              <FaAngleRight className="mt-1" />
            </p>
         
        </div>
      </div>
    </div>
  );
}
export default Marks;
