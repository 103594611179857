export const filterSubject = (props, index) => {
    let studentList = props?.markdata.filter((student) => student.sum > 0)
    var filterSubject = props?.titledata?.filter(subject => {
        let theoryMark = parseFloat(studentList[index]?.subjects[subject.subjectName]?.theorymark)
        if (subject?.optionSubject && theoryMark == 0) {
            return false;
        } else if (isNaN(theoryMark)) {
            return false;
        }
        return true;
    })
    return filterSubject
}