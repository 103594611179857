import React from "react";
import "./NoDataContent.scss";

export const NoDataContent = ({marginTop, title, description, image }) => {
  return (
    <div style={{marginTop:marginTop}} className="no-data-content">
      <div className="img-block">
        <img src={image} alt="img" />
      </div>
      <h4 className="head-title">{title}</h4>
      <p className="description">{description}</p>
    </div>
  );
};
