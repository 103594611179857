import axios from "axios";
import React, { useEffect, useState } from "react";
import { domain } from "../../url";
import "./Leavedata.scss";
import NividLoader from "../NividLoader/NividLoader";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { getNotInYet } from "../Api/GetApi";

export const LeaveData = ({ dashboard }) => {
  const [details, setDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingYet, setIsLoadingYet] = useState(false);
  const [defaultImage, setDefaultImage] = useState([]);
  const [yetToCome, setYetToCome] = useState([]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await axios.get(`${domain}/leave_requested`);
        if (res.status === 200) {
          setDetails(res.data);
        }
      } catch (e) {
        console.log(e, "something went wrong");
      } finally {
        setIsLoading(false);
      }
    })();
    (async () => {
      setIsLoadingYet(true);
      try {
        const res = await getNotInYet();

        if (res.status === 200) {
          setYetToCome(res.data);
        }
      } catch (e) {
        console.log(e, "something went wrong");
      } finally {
        setIsLoadingYet(false);
      }
    })();
  }, []);
  const handleImage = (index) => {
    const temp = [...defaultImage, index];
    setDefaultImage(temp);
  };
  return (
    <div className="today-leave-data">
      <div className="custom-card mg-20">
        {!dashboard && <p className="medium-title-header">Leave/Late</p>}
        <Tabs>
          <Tab title="On leave Today" label="On Leave Today">
            <div className="leave-note-header">
              {isLoading ? (
                <NividLoader height={"80px"} />
              ) : details?.length === 0 ? (
                <div className="no-data-section">
                  <img
                    src={require("../../images/nulltoken.png").default}
                    alt="no-data"
                  />
                  <div className="no-data">
                    {/* <i class="bx bx-info-circle"></i> */}
                    <p>No data available</p>
                  </div>
                </div>
              ) : (
                <>
                  {details?.map((item, index) => {
                    const isImageNull =
                      Array.isArray(defaultImage) &&
                      defaultImage?.includes(index);
                    return (
                      <div
                        style={{ marginRight: "10px", maxWidth: "95px" }}
                        key={index}
                        className={`${
                          item.status
                            ? "approved-status"
                            : "not-approved-status"
                        }`}
                      >
                        <div
                          className={`indicator ${
                            item?.leave_type === "First Half Leave"
                              ? "first-half-indicator"
                              : item?.leave_type === "Second Half Leave"
                              ? "second-half-indicator"
                              : "full-indicator"
                          }`}
                        >
                          {isImageNull || !item?.image_url ? (
                            <div className="indicator-wrap">
                              <p className="indicator-text">
                                {item?.name.charAt(0)}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={item?.image_url}
                              onError={(e) => {
                                handleImage(index);
                              }}
                              alt="std-img"
                            />
                          )}
                          {/* {item?.image_url ? (
                        <img
                          src={item?.image_url}
                          onError={handleImage}
                          alt="std-img"
                        />
                      ) : (
                        <div className="indicator-wrap">
                          <p className="indicator-text">
                            {item?.name.charAt(0)}
                          </p>
                        </div>
                      )} */}
                        </div>
                        <p>{item?.name}</p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </Tab>
          <Tab label="Yet To Come" title="Yet To Come">
            <div className="leave-note-header">
              {isLoadingYet ? (
                <NividLoader height={"80px"} />
              ) : yetToCome?.length === 0 ? (
                <div className="no-data-section">
                  <img
                    src={require("../../images/nulltoken.png").default}
                    alt="no-data"
                  />
                  <div className="no-data">
                    {/* <i class="bx bx-info-circle"></i> */}
                    <p>No data available</p>
                  </div>
                </div>
              ) : (
                <>
                  {yetToCome?.map((item, index) => {
                    // const isImageNull =
                    //   Array.isArray(defaultImage) && defaultImage?.includes(index);
                    return (
                      <div
                        style={{ marginRight: "10px", maxWidth: "95px" }}
                        key={index}
                      >
                        <div className={`indicator `}>
                          {item?.image_url === null && !item?.image_url ? (
                            <div className="indicator-wrap">
                              <p className="indicator-text">
                                {item?.teacherName?.charAt(0)}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={item?.image_url}
                              // onError={(e) => {
                              //   handleImage(index);
                              // }}
                              alt="std-img"
                            />
                          )}
                          {/* {item?.image_url ? (
                        <img
                          src={item?.image_url}
                          onError={handleImage}
                          alt="std-img"
                        />
                      ) : (
                        <div className="indicator-wrap">
                          <p className="indicator-text">
                            {item?.name.charAt(0)}
                          </p>
                        </div>
                      )} */}
                        </div>
                        <p>{item?.teacherName}</p>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
