import Axios from "axios";
import { useEffect, useState } from "react";
import "./EventBarPage.scss";
import { Tabs, Tab } from "react-bootstrap-tabs";

import { EventDetails } from "../EventDetails/EventDetails";
import { domain } from "../../url";
import NividLoader from "../NividLoader/NividLoader";
import { useDeviceData } from "../../store/hodDashboardStore";

export const EventBarPage = (props) => {
  const [event, setEvent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { attendanceData } = useDeviceData();

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await Axios.get(`${domain}/dashboard_api/v1/events`);
        if (res.status === 200) {
          setEvent(res.data);
        }
      } catch (e) {
        console.log(e, "something went wrong");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const teacherBirthdate = props?.props?.birthday?.teacher_birthday
    ? props?.props?.birthday?.teacher_birthday
    : [];
  const studentBirthdate = props?.props?.birthday?.student_birthday
    ? props?.props?.birthday?.student_birthday
    : [];

  const mergedBirthdays = [...teacherBirthdate, ...studentBirthdate];

  return (
    <div className="progress-bar-section">
      <div
        className={`custom-card ${
          attendanceData ? "device-card" : "no-device-card"
        }`}
      >
        <div>
          <p className="medium-title-header">Current Events</p>
        </div>

        {isLoading ? (
          <NividLoader height={"400px"} />
        ) : (
          <Tabs onSelect={(index, label) => console.log(label + " selected")}>
            <Tab label="Month Events">
              <div className="tab-section scroller">
                <EventDetails event={event?.this_months_events} />
              </div>
            </Tab>
            <Tab label="Birthday Events">
              <div className="tab-section scroller birthdate-content">
                <EventDetails event={mergedBirthdays} birthdate={true} />
              </div>
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};
