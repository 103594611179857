import { useEffect, useState } from "react";
import { getDistrict, getMunicipality, getState } from "../Api/GetApi";
import { useSchoolRegistrationStore } from "../../pages/schoolRegistration/store/schoolRegistrationStore";

export const useCountryHooks = () => {
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [municipalityList, setMunicipalityList] = useState([]);
  const schoolRegistrationState = useSchoolRegistrationStore();
  const { state, district } = schoolRegistrationState;

  useEffect(() => {
    getState().then((res) => {
      setStateList(res?.data);
    });
  }, []);

  useEffect(() => {
    if (state) {
      getDistrict(state).then((res) => {
        setDistrictList(res?.data);
      });
    }
  }, [state]);

  useEffect(() => {
    if (district) {
      getMunicipality(district).then((res) => {
        setMunicipalityList(res?.data);
      });
    }
  }, [district]);

  return {
    stateList,
    districtList,
    municipalityList,
  };
};
