import React, { useEffect, useState } from "react";
import "./FoodCard.scss";
import food from "../../images/food.png";
import { GoPlus } from "react-icons/go";
import { AiOutlineMinus } from "react-icons/ai";
import { IoCheckmarkSharp } from "react-icons/io5";
import { useCanteenOrder, useLogOut } from "../../store/Canteen";

const FoodCard = ({ foodData }) => {
  const [quantity, setQuantity] = useState(1);
  const [isSelected, setIsSelected] = useState(false);
  const { order, addOrder, removeOrder, modifyOrder } = useCanteenOrder();
  const { isLogOut } = useLogOut();

  function increment() {
    setQuantity((prev) => prev + 1);
  }
  function decrement() {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    }
  }
  function obj() {
    const selected = {
      menu_id: foodData?.menu_id,
      menu_name: foodData?.name,
      quantity: quantity,
      menu_credit: foodData?.price * quantity,
    };

    return selected;
  }
  function select() {
    setIsSelected(!isSelected);
  }
  useEffect(() => {
    debugger;
    if (isSelected) {
      const selectedObj = obj();

      const tempIndex = order?.findIndex(
        (item) => item?.menu_id === selectedObj?.menu_id
      );

      if (tempIndex === -1) {
        addOrder(selectedObj);
      } else {
        modifyOrder(selectedObj, tempIndex);
      }
    } else {
      const index = order?.findIndex(
        (item) => item.menu_id === foodData?.menu_id
      );
      if (index !== -1) {
        removeOrder(index);
      }
    }
    console.log("order", order);
  }, [quantity, isSelected]);

  useEffect(() => {
    const selectedAlready = order?.findIndex(
      (item) => item.menu_id === foodData?.menu_id
    );

    if (selectedAlready === -1) {
      setIsSelected(false);
    } else {
      setIsSelected(true);
    }
  }, []);

  return (
    <div className="foodCard_container">
      <div className="img_wrapper">
        {foodData?.image === "https://storage.googleapis.com/nivid/" ? (
          <img src={food} alt="food" />
        ) : (
          <img src={foodData?.image || food} alt="food" />
        )}
        <div
          onClick={() => {
            select();
          }}
          className="select_box"
        >
          {isSelected ? (
            <div className="selected">
              <IoCheckmarkSharp size={16} color="#FFFFFF" />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="card_desc">
        <h2>{foodData?.name}</h2>
        <div className="price_quantity_wrapper">
          <p>Rs {foodData?.price}</p>
          <div className="quantity_section">
            <div onClick={() => decrement()} className="decrement_box">
              <AiOutlineMinus />
            </div>
            <div className="quantity_box">{quantity}</div>
            <div onClick={() => increment()} className="increment_box">
              <GoPlus />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FoodCard;
