import React, { useEffect, useState } from "react";

import "./TeacherAssessment.scss";
import Select from "react-select";
import { AiFillCheckCircle } from "react-icons/ai";
import { PiWarningCircleFill } from "react-icons/pi";
import { AiFillClockCircle } from "react-icons/ai";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useFetchData from "../../../utils/useFetchData";
import { getTeacherPlanProfileAssessmentStatus } from "../../Api/GetApi";
import { useTeacherProfile } from "../../../store/teacherProfileStore";
import { Preloader } from "react-preloader-icon";
import NividLoader from "../../NividLoader/NividLoader";
import { NoDataContent } from "../../NoDataContent/NoDataContent";
const TeacherAssessment = () => {
  const [selectedDateFilter, setSelectedDataFilter] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(0);

  const { selectedGrade, selectedSubject } = useTeacherProfile();

  const dateDropdown = [
    { label: "This Week", value: "this_week" },
    { label: "This Month", value: "this_month" },
    { label: "Last Month", value: "last_month" },
    { label: "This Year", value: "this_year" },
  ];
  const TeacherAssessment = () => {
    const [selectedDateFilter, setSelectedDataFilter] = useState(
      dateDropdown?.[0].value
    );
    const [slidesToShow, setSlidesToShow] = useState(0);

    const { selectedGrade, selectedSubject } = useTeacherProfile();

    // const assessmentCardData = [
    //   {
    //     name: "Assesment 1",
    //     status: "complete",
    //     remark: "Well done!",
    //     conclusion: "All students are scored!",
    //   },
    //   {
    //     name: "Assesment 2",
    //     status: "incomplete",
    //     remark: "Overdue scoring",
    //   },
    //   // {
    //   //   name: "Assesment 3",
    //   //   status: "complete",
    //   //   remark: "Well done!",
    //   //   conclusion: "All students are scored!",
    //   // },
    //   // {
    //   //   name: "Assesment 4",
    //   //   status: "pending",
    //   //   remark: "Pending Deadline",
    //   //   conclusion: "All students are scored!",
    //   // },
    // ];

    const icon = (val) => {
      switch (val) {
        case "complete":
          return (
            <AiFillCheckCircle
              style={{
                color: "#1AB394",
              }}
            />
          );
          break;
        case "overdue_scoring":
          return <PiWarningCircleFill style={{ color: "#EA5455" }} />;
          break;
        case "pending":
          return <AiFillClockCircle style={{ color: "#F79D3C" }} />;
          break;
        default:
          return (
            <AiFillCheckCircle
              style={{
                color: "#1AB394",
              }}
            />
          );
      }
    };

    const heading = (val) => {
      switch (val) {
        case "complete":
          return "Well done!";
          break;
        case "overdue_scoring":
          return "Overdue Scoring";
          break;
        case "pending":
          return "Pending Deadline";
          break;
        default:
          return "";
      }
    };
    const description = (val) => {
      switch (val) {
        case "complete":
          return "All students are scored!";
          break;
        case "overdue_scoring":
          return "Not Completed";
          break;
        case "pending":
          return "Due Date is near.";
          break;
        default:
          return "";
      }
    };

    const { data, loading, error } = useFetchData(
      getTeacherPlanProfileAssessmentStatus,
      selectedGrade,
      selectedSubject,
      selectedDateFilter
    );

    useEffect(() => {
      if (data?.assignment_status) {
        const slideNumber =
          data?.assignment_status?.length > 6
            ? 6
            : data?.assignment_status?.length - 1;

        setSlidesToShow(slideNumber);
      }
    }, [data]);

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      arrow: true,
    };

    return (
      <div className="teacher_assessment_section component_wrapper">
        <div className="top_section">
          <h2 className="heading">Assessment Status</h2>
          <div className="dropdown_wrapper">
            <Select
              placeholder="Select Date"
              className="react-select"
              options={dateDropdown}
              defaultValue={dateDropdown?.[0]}
              onChange={(selectedOption) =>
                setSelectedDataFilter(selectedOption.value)
              }
            />
          </div>
        </div>
        {loading ? (
          <NividLoader height="200px" />
        ) : (
          <div className="assessment_slider_wrapper">
            {data?.assignment_status?.length < 1 || data?.length === 0 ? (
              <NoDataContent
                image={require("../../../images/emptyfile.png").default}
                title="No Data Found"
                description="make sure that date filter is selected"
              />
            ) : (
              <Slider {...settings}>
                {data?.assignment_status?.map((item, index) => {
                  return (
                    <div key={index} className="assessment_card">
                      <div className={`heading_capsule  `}>
                        {item?.activity}
                      </div>
                      <div style={{ marginBlock: "12px" }}>
                        {icon(item?.case?.toLowerCase())}
                      </div>
                      <h3 className="title">
                        {heading(item?.case?.toLowerCase())}
                      </h3>
                      {item?.case === "incomplete" ? (
                        <div className="score_btn">score</div>
                      ) : (
                        <p className="details">
                          {description(item?.case?.toLowerCase())}
                        </p>
                      )}
                    </div>
                  );
                })}
              </Slider>
            )}
          </div>
        )}
      </div>
    );
  };
};

export default TeacherAssessment;
