import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { LibraryReviewPointPost } from "../Api/PostApi";
import { toast } from "react-toastify";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { useLibararyManagemnet } from "../store/useStore";

function Ratings({ bookId, datas, closePoints }) {
  const { fetchLibararyManagemnet } = useLibararyManagemnet();
  const rateData = datas.filter((data) => data.id === bookId);

  return (
    <Formik
      initialValues={{
        rating: 0,
        desc: "",
      }}
      validationSchema={Yup.object().shape({
        desc: Yup.string().required("Description is required"),
      })}
      onSubmit={(values, { resetForm }) => {
        const actualData = {
          review_id: rateData?.[0]?.id,
          student_id: rateData?.[0]?.studentId,
          remark_name: "book_review",
          points: values.rating,
          book_remark: values.desc,
        };

        LibraryReviewPointPost(actualData).then((res) => {
          toast.success("Rating and Description submitted successfully!");
        });
        closePoints();
        fetchLibararyManagemnet();
        resetForm();
      }}>
      {({ values, setFieldValue }) => (
        <Form>
          <div className="m-3">
            <Stack spacing={1}>
              <Rating
                name="rating"
                value={values.rating}
                onChange={(event, newValue) => {
                  setFieldValue("rating", newValue);
                }}
                defaultValue={0}
                precision={1}
              />
            </Stack>
            <Field
              name="desc"
              type="text"
              placeholder="Description"
              className="form-control p-2"
              style={{ width: "100%", fontSize: "10px" }}
            />
            <ErrorMessage
              className="text-danger error text-sm"
              name="desc"
              component="div"
            />
            <button type="submit" className="comment-btn mt-2">
              Submit
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default Ratings;
