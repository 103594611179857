import React, { useState } from "react";
import "./StudentScore.scss";
import Select from "react-select";
import StudentScoreChart from "./DoughnotChart/StudentScoreChart";
import PerformanceCard from "./PerformanceCard/PerformanceCard";
import { useTeacherProfile } from "../../../store/teacherProfileStore";
import useFetchData from "../../../utils/useFetchData";
import { getTeacherPlanProfileStudentScore } from "../../Api/GetApi";
import { dateMonthDropdown } from "../data";
import NividLoader from "../../NividLoader/NividLoader";
import { NoDataContent } from "../../NoDataContent/NoDataContent";

const StudentScore = () => {
  const [selectedMonth, setSelectedMonth] = useState(
    dateMonthDropdown?.[0].value
  );
  const { selectedGrade, selectedSubject } = useTeacherProfile();

  const { data, loading, error } = useFetchData(
    getTeacherPlanProfileStudentScore,
    selectedGrade,
    selectedSubject,
    selectedMonth
  );

  return (
    <div className="student_score_container component_wrapper">
      <div className="top_section">
        <h2 className="heading">Student Scores</h2>
        <div className="dropdown_wrapper">
          <Select
            placeholder="Select Date"
            className="react-select"
            options={dateMonthDropdown}
            defaultValue={dateMonthDropdown?.[0]}
            onChange={(selectData) => setSelectedMonth(selectData.value)}
          />
        </div>
      </div>
      {loading ? (
        <NividLoader height="200px" />
      ) : (
        <div>
          {data?.length === 0 ? (
            <NoDataContent
              image={require("../../../images/emptyfile.png").default}
              title="No Data Found"
              description="make sure that date filter is selected"
            />
          ) : (
            <div className="chart_table_body_wrapper">
              <StudentScoreChart data={data?.student_scores?.percentage} />
              <PerformanceCard
                data={data?.student_scores?.student_performances}
                isHighest={true}
              />
              <PerformanceCard
                data={data?.student_scores?.student_performances}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StudentScore;
