import React, { useEffect, useState } from "react";
import "./visitorSchedule.scss";
import "bootstrap/dist/css/bootstrap.css";
import { ScheduledCard } from "./VisitorCard";
import LineTab from "../TabList/LineTab/LineTab";
import { useAppointmentStore } from "../../store/appointmentListStore";
import { getAppointmentTeacherView } from "../Api/GetApi";

const VisitorScheduleTeacher = () => {
  const { qrData } = useAppointmentStore();
  

  const { appointmentListTeacher, setAppointmentListTeacher } =
    useAppointmentStore();
  useEffect(() => {
    getAppointmentTeacherView().then((res) => {
      setAppointmentListTeacher(res?.data?.data);
    });
  }, []);

  const tabData = [
    {
      title: "Scheduled",
      content: (
        <ScheduledCard
          cardList={appointmentListTeacher?.filter(
            (sch) => sch.is_visited === false
          )}
          isTeacher
        />
      ),
    },
    {
      title: "Completed",
      content: (
        <ScheduledCard
          cardList={appointmentListTeacher?.filter(
            (sch) => sch.is_visited === true
          )}
          isTeacher
        />
      ),
    },
  ];
  return (
    <div className="visitor-schedule-section">
      <div className="title-block header">
        <h1 className="title">Appointment</h1>
      </div>
      <div className="assessment-form-content mt-20">
        <LineTab tabData={tabData} defaultIndex={true} />
      </div>
    </div>
  );
};

export default VisitorScheduleTeacher;
