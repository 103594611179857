import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { AddProxySubjectPopup } from "./AddProxySubjectPopup";
import { useProxySubject } from "../store/useStore";
import { DeleteProxySubject } from "../Api/DeleteApi";
import { toast } from "react-toastify";

export const ProxyData = () => {
  const [visible, setVisible] = useState();
  const { fetchProxySubjectList, proxyClassList } = useProxySubject();
  const [modalvisible, setModalVisible] = useState();
  const [selectedId, setSelectedId] = useState();
  useEffect(() => {
    fetchProxySubjectList();
  }, []);

  const customStyles = {
    position: "fixed",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "50%",
    height: "fit-content",
  };

  const closeModal = () => {
    setVisible(!visible);
  };

  const handleDelete = async () => {
    if (selectedId) {
      const data = {
        proxy_id: selectedId,
      };

      try {
        const res = await DeleteProxySubject(data);
        if (res.data.status === 1) {
          toast(res.data.message);
          setModalVisible(false);
          fetchProxySubjectList();
        } else {
          toast(res.data.message);
        }
      } catch {
        toast("something went wrong");
      }
    }
  };
  return (
    <div className="teacher-role-section">
      <Modal
        isOpen={modalvisible}
        // onAfterOpen={}
        onRequestClose={() => {
          setModalVisible(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Teacher Role </p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setModalVisible(false);
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <p className="text-center">
              Are you sure you want to delete this proxy subject ?
            </p>

            <div className="d-flex-center">
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleDelete(selectedId);
                }}
              >
                Yes
              </button>
              <button
                className=" btn btn-light mr-10"
                onClick={() => {
                  setModalVisible(false);
                }}
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={visible}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Add Proxy </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <AddProxySubjectPopup closeModal={closeModal} />
          </div>
        </div>
      </Modal>

      <div className="header-flex-section" style={{ justifyContent: "end" }}>
        <button
          className="btn btn-content custom-btn"
          style={{ marginLeft: "auto" }}
          onClick={() => {
            setVisible(true);
          }}
        >
          <i className="bx bx-plus"></i> Add Proxy
        </button>
      </div>

      <div className="d-flex" style={{ gap: "30px", alignItems: "initial" }}>
        <div className="table-data-section" style={{ width: "50%" }}>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>S.N </th>
                <th>Date </th>
                <th>Grade </th>
                <th>Subject Name</th>
                <th>Action</th>
              </tr>
            </thead>

            {proxyClassList?.today_proxy?.length > 0 ? (
              <tbody>
                {proxyClassList?.today_proxy?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.date}</td>
                      <td>{item?.class_name}</td>
                      <td>{item?.subject_name}</td>
                      <td>
                        <button
                          className="action-btn action-delete-btn"
                          onClick={() => {
                            setModalVisible(true);
                            setSelectedId(item?.proxy_id);
                          }}
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <td
                  className="text-center"
                  colSpan={3}
                  style={{ padding: "15px" }}
                >
                  No data added yet
                </td>
              </tbody>
            )}
          </table>
        </div>

        <div className="table-data-section" style={{ width: "50%" }}>
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>S.N </th>
                <th>Date </th>
                <th>Grade </th>
                <th>Subject Name</th>
                <th>Action</th>
              </tr>
            </thead>

            {proxyClassList?.other_proxy?.length > 0 ? (
              <tbody>
                {proxyClassList?.other_proxy?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{item?.date}</td>
                      <td>{item?.class_name}</td>
                      <td>{item?.subject_name}</td>
                      <td>
                        <button
                          className="action-btn action-delete-btn"
                          onClick={() => {
                            setModalVisible(true);
                            setSelectedId(item?.proxy_id);
                          }}
                        >
                          <i class="bx bx-trash"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            ) : (
              <tbody>
                <td
                  className="text-center"
                  colSpan={3}
                  style={{ padding: "15px" }}
                >
                  No data added yet
                </td>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};
