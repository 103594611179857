import React from "react";
import view_syllabus from "../assets/view_syllabus.png";
import "./Subject.scss";

const SubjectCard = ({
  logo,
  title,
  chapter,
  onSubjectCardClick,
  onViewClick,
}) => {
  return (
    <div className="subject-card">
      <div
        className="subject-section"
        onClick={() => {
          onSubjectCardClick?.();
        }}
      >
        <div className="subject-logo">{logo}</div>
        <div className="subject-info">
          <div className="block-title">{title}</div>  
          <div className="content">{chapter} Chapters</div>
        </div>
      </div>

      <div
        className="view-syllabus"
        onClick={() => {
          onViewClick?.();
        }}
      >
        <img src={view_syllabus} alt="" />
        <p> View Syllabus</p>
      </div>
    </div>
  );
};

export default SubjectCard;
