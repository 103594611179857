import './feehistory.scss';

import FeeHistoryTop from "../FeeHistoryTop/FeeHistoryTop";
import FeeHistoryTab from "../FeeHistoryTab/FeeHistoryTab";

const FeeHistory = () => {
  return (
    <div className='feeHistory-container'>
      <FeeHistoryTop />
      <FeeHistoryTab />
    </div>
  );
};

export default FeeHistory;
