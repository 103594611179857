import React, { useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap-tabs";
import "./OrderUi.scss";
import FoodCard from "./FoodCard/FoodCard";
import { useCanteenParentOrder } from "../store/UseCanteenParentOrder";
import NividLoader from "../../NividLoader/NividLoader";
import { NoDataContent } from "../../NoDataContent/NoDataContent";

const OrderUi = () => {
  const {
    studentFoodList,
    activeStudentToken,
    setStudentFoodList,
    todayMenuList,
    loading,
    selectedFoodCategory,
    setSelectedFoodCategory,
  } = useCanteenParentOrder();

  useEffect(() => {
    setSelectedFoodCategory(todayMenuList?.category?.[0]?.category_id);
  }, [todayMenuList]);

  return (
    <div className="orderUi_container">
      <div className="card-tab-section-wrapper">
        {loading ? (
          <NividLoader />
        ) : todayMenuList?.category?.length > 0 ? (
          <div className="card_wrapper">
            <Tabs activeKey={selectedFoodCategory}>
              {todayMenuList?.category?.map((item, index) => {
                return (
                  <Tab
                    key={index}
                    title={item?.category}
                    label={item?.category}
                    eventKey={item.category_id}
                  >
                    {item?.menus?.map((food, index) => (
                      <FoodCard key={index} data={food} />
                    ))}
                  </Tab>
                );
              })}
            </Tabs>
          </div>
        ) : (
          <NoDataContent
            marginTop="100px"
            image={require("../../../images/emptyfile.png").default}
            title="Empty Menu"
            description={"No menu to be displayed"}
          />
        )}
      </div>
    </div>
  );
};

export default OrderUi;
