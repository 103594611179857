import Axios from "axios";
import { useEffect, useState } from "react";
import "./Message.scss";
import Moment from "react-moment";
import search from "../../images/seaarch.png";
import Modal from "react-awesome-modal";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import { toast } from "react-toastify";
import noData from "../../images/no-data.svg";
import { domain } from "../../url";
import { getDashboardMessage } from "../Api/GetApi";
import NepaliDate from "nepali-date";
import NividLoader from "../NividLoader/NividLoader";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

export const Message = () => {
  const [messageData, setMessageData] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(
    new NepaliDate().format("YYYY-MM-DD")
  );
  const [image, setImage] = useState("");
  const [selectMessage, setSelectMessage] = useState(null);
  const [selectedMessageData, setSelectedMessageData] = useState(null);
  const [btnChange, setBtnChange] = useState("false");
  const [message, setMessage] = useState("");
  const [newArray, setNewArray] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [imgfile, setImgFile] = useState(null);
  const [showFile, setShowFile] = useState(false);

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      backgroundColor: "red",
      transform: "translate(-50%, -50%)",
    },
  };
  const fetchSpecificUserMessage = () => {
    axiosInstance
      .get(
        `${domain}/dashboard_api/v1/messages/${selectMessage?.fromToken}/${selectMessage?.toToken}`
      )
      .then((res) => {
        setIsLoading(false);
        setSelectedMessageData(res.data);
      });
  };

  useEffect(() => {
    if (selectedDate !== undefined) {
      getDashboardMessage(selectedDate).then((res) => {
        setMessageData(res.data);
        setNewArray(res.data);
        setSelectMessage(res.data?.[0]);
      });
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectMessage?.fromToken) {
      setIsLoading(true);
      fetchSpecificUserMessage();
    }
  }, [selectMessage]);

  const postMessage = () => {
    setBtnChange("true");
    var formData = new FormData();
    formData.append("message", message);
    formData.append("image", image);
    axiosInstance
      .post(
        `${domain}/dashboard_api/v1/messages/${selectMessage?.fromToken}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setBtnChange("false");
        setShowFile(false);

        if (res.status == 201) {
          toast.success(res.data.message);
          var temp = [
            ...selectedMessageData,
            {
              detail: imgfile ?? message,
              status: "sent",
              image_url: null,
            },
          ];
          setSelectedMessageData(temp);
          setMessage("");
          setImage("");
          setImgFile(null);
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        setBtnChange("false");
        setShowFile(false);

        toast.error("Something went wrong", err.message);
      });
  };

  const showFullsize = (value) => {
    setVisible(true);
    setSelectedImg(value);
  };

  const selectFile = (event) => {
    setShowFile(true);
    setImage(event.target.files[0]);
    if (event.target.files.length !== 0) {
      setImgFile(URL.createObjectURL(event.target.files[0]));
    }
  };

  const handleChange = (e) => {
    const searchVar = e.target.value;
    const newArr = messageData?.filter((item) => {
      return item?.sender?.toLowerCase().includes(searchVar.toLowerCase());
    });
    setNewArray(newArr);
  };

  return (
    <div className="message-section section-gap">
      <div className="custom-card">
        <div className="message-top-section">
          <div>
            <p className="medium-title">Messages</p>
          </div>
          <div className="message-title-section">
            <div className="message-date-picker">
              <NepaliDatePicker
                defaultDate={true}
                inputClassName="form-control"
                className="mh-10 nepali-date-picker-min-width message-date"
                value={selectedDate}
                showResetDateButton
                onChange={(value) => {
                  setSelectedDate(value);
                }}
                resetButtonElement="×"
                options={{ calenderLocale: "en", valueLocale: "en" }}
              />
            </div>
            <div className="search-bar form-group">
              <div className="search-icon">
                <img src={search} alt="icons" />
              </div>
              <input
                type="search"
                name="search"
                className="input-field"
                placeholder="Search message"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
        </div>
        {newArray?.length === 0 || newArray === undefined ? (
          <div className="no-data">
            <div className="no-img">
              <img src={noData} alt="no-data" />
            </div>
            <div className="gesture">Oops!!</div>
            <div className="desc">No Message</div>
          </div>
        ) : (
          <div className="message-border">
            <div className="message-list width-50 scroller">
              {newArray?.map((item, index) => {
                return (
                  <div
                    className={
                      selectMessage === item ? "header active" : "header"
                    }
                    key={index}
                    onClick={() => {
                      setSelectMessage(item);
                    }}
                  >
                    <div className="width-100">
                      {item?.image_url !== null ? (
                        <div className="big-circle ">
                          <img
                            className="user-img"
                            src={item?.image_url}
                            alt="user-img"
                          />
                        </div>
                      ) : item?.sender ? (
                        <div className="big-circle ">
                          <p className="circle-font">
                            {item?.sender?.charAt(0)}
                          </p>
                        </div>
                      ) : (
                        <div className="big-circle ">
                          <p className="circle-font">U</p>
                        </div>
                      )}

                      <div className="user-detail">
                        <div className="d-flex align-items-center">
                          {item?.sender ? (
                            <p className="sub-content ">{item?.sender}</p>
                          ) : (
                            <p className="sub-content ">You</p>
                          )}

                          <p className="icon"></p>
                          {item?.receiver_name ? (
                            <p className="sub-content ">
                              {item?.receiver_name}
                            </p>
                          ) : (
                            <p className="sub-content ">You</p>
                          )}
                        </div>

                        <p className="description white-space">
                          {item?.detail?.toLowerCase().startsWith("http")
                            ? "sent a photo"
                            : item?.detail}
                        </p>
                      </div>
                    </div>
                    <div className="time">
                      <Moment fromNow>{item?.created_at}</Moment>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="right-body overflow-hidden ">
              <div className="top-header">
                <div className="width-100 ">
                  <div className="profile">
                    <div className="big-circle ">
                      {selectMessage?.image_url !== null ? (
                        <img
                          className="user-img"
                          src={selectMessage?.image_url}
                          alt="user-img"
                        />
                      ) : selectMessage?.sender !== "" ? (
                        <p className="circle-font">
                          {selectMessage?.sender?.charAt(0)}
                        </p>
                      ) : (
                        <p className="circle-font">U</p>
                      )}
                    </div>
                  </div>
                  <div className="user-detail">
                    <div className="d-flex">
                      {selectMessage?.sender ? (
                        <p className="small-content ">
                          {selectMessage?.sender}
                        </p>
                      ) : (
                        <p className="small-content ">You</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="time">
                  <Moment fromNow>{selectMessage?.created_at}</Moment>
                </div>
              </div>

              {isLoading ? (
                <div className="loader-text">
                  <NividLoader height={"100px"} />
                </div>
              ) : (
                <div className="scroller">
                  {selectedMessageData?.map((item, index) => {
                    return (
                      <>
                        <div className="" key={index}>
                          {item?.status === "sent" ? (
                            <div className="width-100 right-box">
                              <div className="blue-box">
                                <div className="user-detail">
                                  <div className="d-flex">
                                    <p className="small-content">
                                      {item?.sender_name ? (
                                        item?.sender_name
                                      ) : (
                                        <>You</>
                                      )}
                                    </p>
                                  </div>
                                </div>

                                {item?.detail.startsWith(
                                  "https://storage.googleapis"
                                ) || item?.detail.startsWith("blob:http") ? (
                                  <img
                                    src={item?.detail}
                                    className="img-msg"
                                    alt="img"
                                    onClick={() => showFullsize(item?.detail)}
                                  />
                                ) : (
                                  <div className="f-content">
                                    {item?.detail}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className="width-100 ">
                              <div className="profile">
                                <div className="big-circle ">
                                  {item?.image_url !== null ? (
                                    <img
                                      className="user-img"
                                      src={item?.image_url}
                                      alt="user-img"
                                    />
                                  ) : item?.sender_name ? (
                                    <p className="circle-font">
                                      {item?.sender_name?.charAt(0)}
                                    </p>
                                  ) : (
                                    <p className="circle-font">U</p>
                                  )}
                                </div>
                              </div>
                              <div className="white-box">
                                <div className="user-detail">
                                  {/* <div className="d-flex">
                                    {item?.receiver? (
                                      <p className="small-content">
                                        {item?.receiver}
                                      </p>
                                    ) : (
                                      <p className="small-content">
                                        Unknown User
                                      </p>
                                    )}
                                  </div> */}
                                  <div className="t-content d-flex">
                                    <div className="name">
                                      <p className="small-content">
                                        {item?.sender_name
                                          ? item?.sender_name
                                          : "You"}
                                      </p>
                                    </div>
                                  </div>
                                </div>

                                {item?.detail.startsWith(
                                  "https://storage.googleapis"
                                ) || item?.detail.startsWith("blob:http") ? (
                                  <img
                                    src={item?.detail}
                                    className="img-msg"
                                    alt="img"
                                    onClick={() => showFullsize(item?.detail)}
                                  />
                                ) : (
                                  <div className="f-content">
                                    {item?.detail}
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    );
                  })}
                  {/* {selectMessage?.toToken === 1 ? ( */}
                  <div className="reply-btn">
                    <div className="msg-box">
                      <div className="msg-input-area">
                        <input
                          type="text"
                          name="message"
                          className="input-field"
                          placeholder="Type your message here"
                          value={message}
                          onChange={(e) => {
                            setMessage(e.target.value);
                          }}
                        />

                        {showFile === false ? (
                          ""
                        ) : (
                          <p className="select-file-name">
                            Selected filename : <span> {image?.name}</span>
                          </p>
                        )}
                      </div>

                      <div class="image-upload">
                        <label for="file-input">
                          <i
                            className="file fa fa-paperclip icon_hover"
                            aria-hidden="true"
                          ></i>
                        </label>

                        <input
                          id="file-input"
                          accept="image/png, image/gif, image/jpeg"
                          type="file"
                          onChange={selectFile}
                        />
                      </div>
                    </div>

                    <button
                      className="primary-btn small-content submit-reply-btn"
                      name="reply"
                      type="button"
                      onClick={postMessage}
                    >
                      {btnChange === "true" ? (
                        <div className="d-flex">
                          Replying{" "}
                          <div>
                            <i class="fa fa-spinner"></i>
                          </div>{" "}
                        </div>
                      ) : (
                        "Reply"
                      )}
                    </button>
                  </div>
                  {/* // ) : ( // "" // )} */}
                </div>
              )}

              <Modal
                visible={visible}
                width="20%"
                // height="90%"
                // maxHeight="90%"
                effect="fadeInUp"
                style={customStyles}
                background="none"
                onClickAway={() => setVisible(false)}
              >
                <div className="pop-up">
                  <img className="popup-img" src={selectedImg} alt="img" />
                </div>
              </Modal>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
