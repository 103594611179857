import react from "react";
import { academicStore } from "../../store/teacherProfileStore";
import "./AcademicsTable.scss";
import { toast } from "react-toastify";

export const AcademicsTable = ({ data, keyName }) => {
  const { setModalVisible, setkeyValue, handleId, academicId, setAcademicId } =
    academicStore();
  const isExamSubjectstring = typeof data !== "string" && data?.length > 0;

  let dataArr = [];

  if (data) {
    dataArr = [...dataArr, ...data.map((item) => item.id)];
  }

  const checked = dataArr?.every((value) =>
    academicId[keyName]?.includes(value)
  );

  return (
    <div className="table-data-section academic-table" style={{ width: "50%" }}>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>S.N </th>
            <th>
              <div className="action-header">
                {data?.length > 0 && (
                  <input
                    type="checkbox"
                    name={`select${keyName}`}
                    checked={checked}
                    title="Select All"
                    onChange={(e) => {
                      if (e.target.checked) {
                        const tempData = academicId;
                        tempData[keyName] = dataArr;

                        setAcademicId(tempData);
                      } else {
                        const tempData = academicId;
                        tempData[keyName] = [];

                        setAcademicId(tempData);
                      }
                    }}
                  />
                )}

                {keyName === "examSubject" || keyName === "hodExamSubject"
                  ? "Exam Subject"
                  : "Subject"}
              </div>
            </th>
            <th className="action-header">
              Action
              <div
                className="action-btn action-delete-btn"
                title="Delete Selected "
                onClick={() => {
                  if (academicId[keyName]?.length > 0) {
                    setModalVisible(true);
                    setkeyValue(keyName);
                  } else {
                    toast("Please select subject");
                  }
                }}
              >
                <i class="bx bx-trash"></i>
              </div>
            </th>
          </tr>
        </thead>

        {isExamSubjectstring ? (
          <tbody>
            {data?.map((item, index) => {
              const checkedValue = academicId?.[keyName]?.includes(item.id);
              let name;
              if (keyName === "subject" || keyName === "examSubject") {
                name = `${item?.subjectName} - ${item?.class_name}`;
              } else {
                name = `${item?.name}`;
              }
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="action-header">
                      <input
                        type="checkbox"
                        checked={checkedValue}
                        onChange={() => {
                          handleId(item.id, keyName);
                        }}
                      />

                      {name}
                    </div>
                  </td>
                  <td>
                    <button
                      className="action-btn action-delete-btn"
                      onClick={() => {
                        setModalVisible(true);
                        setkeyValue(keyName);
                        const changedData = academicId;
                        changedData[keyName] = [item.id];
                        setAcademicId(changedData);
                      }}
                    >
                      <i class="bx bx-trash"></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <td className="text-center" colSpan={3} style={{ padding: "15px" }}>
              No exam subject added yet
            </td>
          </tbody>
        )}
      </table>
    </div>
  );
};
