import React from 'react'
import "./NividLoader.scss"
import { sOnly, baseOnly } from "./svgIcons";


const NividLoader = ({height}) => {
  return (
    <div style={{height:height}} className='Loader_wrapper'>
    <div className="loadingLogoLay">
    <div className="sOnlyCont">{sOnly}</div>
    <div className="baseOnlyCont">{baseOnly}</div>
  </div>
  </div>
  )
}

export default NividLoader
