import { IoIosArrowRoundBack, IoIosEye } from "react-icons/io";
import Button from "../Button/Button";
import { FaEdit } from "react-icons/fa";
import "./studentProfileTop.scss";

const StudentProfileTop = () => {
  return (
    <div className="student-top-container">
      {/* <div className="text">
        <p>Student’s Profile</p>
      </div> */}
      <div className="wrapper">
        {/* <Button text="Back" icon={IoIosArrowRoundBack} className="back-btn" /> */}
        <div className="rightButtons">
          {/* <Button text="View Report" icon={IoIosEye} className="view-btn" />{" "} */}
          {/* <Button text="Edit" icon={FaEdit} className="edit-btn" /> */}
        </div>
      </div>
    </div>
  );
};

export default StudentProfileTop;
