import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const useFetchData = (getVal,selectedGrade,selectedSubject,selectedDate) => {
    
    const [loading, setLoading] = useState();
    const [data,setData] = useState([]);
    const [error,setError] = useState();


      const fetchData = async()=>{
        setLoading(true);
        try {
          const res = await getVal(selectedGrade,selectedSubject,selectedDate);
          const apiRes = res.data;
    
          if (apiRes?.status === 1) {
            setData(apiRes?.data);
          } else {
            setData([]);
          }
        } catch (e) {
          // Handle error gracefully, for example with a toast
          toast("Something went wrong");
          setError(e)
        } finally {
          setLoading(false);
        }
      }

     useEffect(()=>{
        if(selectedGrade!==undefined &&selectedGrade!==null && selectedSubject!==undefined && selectedSubject!==null && selectedDate!==undefined && selectedDate!==null)
    fetchData();

     },[selectedGrade,selectedSubject,selectedDate])
     

      return{loading,error,data};






}

export default useFetchData;