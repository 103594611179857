import React, { useState } from 'react'
import Chart from "react-apexcharts";
import { useCashFlow } from '../../store/accountDashboard';

const CashLineChart = () => {

  const {cashFlow} = useCashFlow()
  const cashChartData = cashFlow.cash_inflow && cashFlow?.cash_inflow.map((data)=> data.cash_inflow)
 

    const options = {
        chart: {
          id: "spline-area-chart",
        },
        xaxis: {
          categories: [
            'Baisakh', 'Jestha', 'Asar', 'Shrawan', 'Bhadra', 'Asoj', 'Kartik', 'Mangsir', 'Poush', 'Magh', 'Falgun', 'Chaitra'
          ],
        },
        fill: {
          opacity: 0.5,
        },
        markers: {
          size: 5,
        },
        dataLabels: {
          enabled: false,
        },
          // legend: {
          //   show: true,
          //   position:"bottom",
          // },
          // title: {
          //   text: "Marksheet",
          //   style: {
          //     fontSize: "16px",
          //     fontFamily: "Nunito Sans",
          //   },
          // },
      };
    
      const series = [
        {
          name: "Cash",
          data: cashChartData,
          color: "#29ABE2",
        },
     
      ];
   
  return (
    <div>
       
        <Chart
            options={options}
            series={series}
            type="area"
            height="250px"
        />
    </div>
  )
}

export default CashLineChart;
