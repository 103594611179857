import React, { useState } from 'react'
import ViewAll from '../AccountDashboardViewBtn/ViewAll'


import { useRecentExpenses } from '../../store/accountDashboard'

import { NoDataContent } from '../NoDataContent/NoDataContent'
import { domain } from '../../url'
import {toast} from "react-toastify"
import NividLoader from '../NividLoader/NividLoader'

const ExpensesTable = ({from,to}) => {
  const [showAll,setShowAll] = useState(false)
    const header = ["Bill No.:","EXPENSES TYPE", "DATE", "AMOUNT PAID"]
    const{expenses,loading}= useRecentExpenses()
  

    const data = expenses?.recent_expenses ||[]
    const showData =  data && showAll ? data :data.slice(0,8)  

    if(loading){
        return <div ><NividLoader height={"380px"} /></div>
    }
  return (
    <div>
        <div style={{height:"350px",overflowY:"auto",border:"1px solid #EEEEEE" }}>
        <table >
            <thead>
                <tr>
                    {header.map((h)=>{
                        return(<th key={h}>{h}</th>)
                    })}
                </tr>
            </thead>
            {showData.length>=1 ? (<tbody>
                {
                    
                    showData.map((d,index)=>{
                        return(
                            <tr key={index}>
                                <td >{d?.bill_no} </td>
                                <td>{d?.ledgerName}</td>
                                <td>{d?.miti}</td>
                                <td>Rs. {Intl.NumberFormat('en-IN').format(d?.amount_paid)}</td>

                            </tr>
                        )

                    }) 
                }


               
            </tbody>) : (<tbody style={{width:"100%", height:"250px", justifyContent:"center", alignItems:"center"}}>
<tr>
    <td colSpan={header.length} style={{borderBottom:"none", textAlign: "center" }}><NoDataContent image={require("../../images/emptyfile.png").default} title="No Data" description="Couldn't find expenses data at the moment"   /></td>
  </tr>
</tbody>)  }
            
            
        </table>

        </div>
   

      { showData.length>=1  && <div onClick={()=>{
                       if(to!=="" && from!=="" ){
                        window.open(`${domain}/daily_transcation_detail_report?utf8=✓&fromDayToken=${from}&toDayToken=${to}&type=exp&commit=Show`)
        
                    }
                    else{
                        toast("Please select both date.")
                    }
        }} style={{marginTop:"8px"}}><ViewAll/></div>}
    </div>
  )
}

export default ExpensesTable