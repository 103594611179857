import { create } from "zustand";

import { getStudentData, getStudentRemark } from "../components/Api/GetApi";

const useStudentStore = create((set) => ({
  student: null,
  isLoading: false,
  isError: false,
  studentRemark: null,
  monthtoken: null,
  remarkLoading: false,
  fetchStudentData: async (id) => {
    set({ isLoading: true });
    try {
      const res = await getStudentData(id);
      set({ student: res.data[0], isLoading: false });
    } catch (error) {
      set({ isLoading: false });
      set({ isError: true });
    }
  },

  fetchStudentRemark: async (selectedMonth, id) => {
    try {
      set({ remarkLoading: true });
      const res = await getStudentRemark(id, selectedMonth);
      set({ studentRemark: res.data, remarkLoading: false });
    } catch (error) {
      set({ remarkLoading: false });
    }
  },
}));

export { useStudentStore };

export const useStudentId = create((set) => ({
  studentId: "",
  setStudentId: (val) => set({ studentId: val }),
  selectedMonth: "",
  setSelectedMonth: (val) => set({ selectedMonth: val }),
  manualAttendance: true,
  setManualAttendance: (val) => set({ manualAttendance: val }),
}));

const initialState = {
  shouldReload: true,
};

export const changeReload = create((set) => ({
  ...initialState,
  setReload: (val) => set({ shouldReload: val }),
}));
