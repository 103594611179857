import { create } from "zustand"
import { toast } from "react-toastify";
import { getCanteenMenu } from "../components/Api/GetApi";
import { devtools } from "zustand/middleware";
export const useCanteenMenu = create(devtools((set)=>({
foodMenu:[],
loading:false,
fetchFoodMenu:async()=>{
   
    set({loading:true})
    try{
    const res = await getCanteenMenu();
    const apiRes = await res.data;
    if(apiRes.status===1){
        set({foodMenu:apiRes?.data})

        
    }else{
        set({foodMenu:[]})
    }

    }catch{
        toast("Something went wrong")
    }finally{
        set({loading:false})
    }

}

})))


export const useCanteenOrder =create(devtools((set)=>({
    order:[],
    addOrder:(val)=>{
        set((prev)=>({order:[...prev.order,val]}))

    },
    modifyOrder:(newVal,index)=>{
        // set((prev) => ({ order: prev.order.filter((_, i) => i !== index) }));
        set((prev) => ({
            order: prev.order.map((item, i) => (i === index ? newVal : item))
          }));
    },
    removeOrder:(index)=>{
        set((prev) => ({ order: prev.order.filter((_, i) => i !== index) }));
    },

    clear:()=>{
        set({order:[]})

    }
 
})))

export const useStudentDetails = create(devtools((set)=>({
    studentDetails:[],

  addStudentData:(val)=>{
    set({studentDetails:val})
  },

  clearStudentData:()=>{
    set({studentDetails:[]})
  }
})))


export const useLogOut= create(devtools((set)=>({
    isLogOut: false,

    logOutDone:()=>{
        set((state)=>({isLogOut:!state.isLogOut}))
    }
})))

export const useBillData = create(devtools((set)=>({
    billData: {},
    isBillShowing:false,
    setIsBillShowing:()=>{
     set({isBillShowing:true})
    },
    
    clearIsBillShowing:()=>{
        set({isBillShowing:false})
       },
    setBillData:(data)=>{
     
        set({billData:data})
    },
    clearBillData:()=>{
      
        set({billData:{}})
    }
})))


