import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { useIframeTokenStore } from "../store/useStore";
import PreLoader from "../preLoader/preLoader";
import { useTeachingPlanStore } from "../../pages/teachingPlan/store/teachingPlanStore";
import { useTeacherProfile } from "../../store/teacherProfileStore";

const IframeContainer = ({ rest, component: Component }) => {
  const [showHeader, setShowHeader] = useState(true);
  let urlparam = new URLSearchParams(window.location.search);
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { setShowLessonHeader } = useTeachingPlanStore();

  const iframeToken = useIframeTokenStore((state) => state);
  const { setIsUserAdmin, setIsRegularizedRequest } = useTeacherProfile();
  useEffect(() => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      setData();
      setIsUserAdmin(false);
      setIsRegularizedRequest(true);
      setShowHeader(true);
      let userToken = urlparam.get("token");
      iframeToken?.setToken(userToken);
    } else {
      window.addEventListener("message", function (event) {
        const dashboardData =
          event?.data?.dashboardData && event?.data?.dashboardData;
        setData(dashboardData);
        const isadmin = event?.data?.current_user;
        console.log("isadmin ", isadmin);
        setIsUserAdmin(isadmin);
        if (isadmin === undefined || isadmin === null) {
          setIsRegularizedRequest(true);
        } else {
          setIsRegularizedRequest(isadmin);
        }
        let userToken = urlparam.get("token");
        const header = event.data.showHeader;
        if (header === undefined) {
          setShowLessonHeader(true);
          setShowHeader(true);
        } else {
          setShowLessonHeader(header);
          setShowHeader(header);
        }
        if (event.data.token) {
          userToken = event.data.token;
          userToken && iframeToken?.setToken(userToken);
        } else {
          localStorage.setItem("authToken", userToken);
          iframeToken?.setToken(userToken);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (iframeToken?.token === "") {
      setLoading(true);
      const timeoutId = setTimeout(() => {
        setLoading(false);
      }, 3000);

      return () => clearTimeout(timeoutId);
    } else {
      setLoading(false);
    }
  }, [iframeToken.token]);

  if (loading) {
    return <PreLoader />;
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {iframeToken?.token || urlparam.get("token") ? (
            <Component
              {...props}
              showHeader={showHeader}
              token={iframeToken?.token}
              dashboardData={data}
            />
          ) : (
            <NoDataContent
              image={require("../../images/emptyfile.png").default}
              title="No Token Found"
              description={"Looks like , token is missing"}
            />
          )}
        </>
      )}
    />
  );
};
export default IframeContainer;
