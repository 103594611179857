import { useEffect, useState } from "react";
import Header from "../../Header/Header";
import ECALayoutTbody from "../../LayoutTbody/ECALayoutTbody";
import LayoutTbodyBalmiki from "../../LayoutTbody/LayoutTbodyBalmiki";
import "./Layout6.scss";
import { filterSubject } from "../../LayoutTbody/FilterSubject";

export const Layout6 = (props) => {
  const [ledgerData, setLedgerData] = useState();
  const [titleData, setTitleData] = useState();
  const [gradeList, setGradeList] = useState();

  const bgImage = `url(${props.info?.schoolData?.logo})`;
  const backgroundTitle = `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30"%3E%3Ctext x="0" y="20" font-size="14" fill="%235494c9"%3E${props.info?.schoolData?.school_name}%3C/text%3E%3C/svg%3E')`;
  useEffect(() => {
    setLedgerData(props?.resultData[0]);
    setTitleData(props?.resultData[0].titledata);
    setGradeList(props?.resultData[0].markdata);
  }, [JSON.stringify(props?.resultData[0]), props]);
  return (
    <>
      <div className="layout6-section">
        {gradeList
          ?.filter((student) => student.sum > 0)
          .map((studentDetail, index) => {
            var filteredSubs = filterSubject(ledgerData, index);

            return (
              <div
                className="layout   layout-balmiki"
                style={{ paddingTop: `${props.info.header.margin}cm` }}
              >
                <div
                  className=" background-image-marksheet"
                  style={{
                    "--bg-image": bgImage,
                    "--bg-title": backgroundTitle,
                  }}
                >
                  <Header
                    schoolData={props.info?.schoolData}
                    header={props.info?.header}
                  />
                  <div className="layout-header">
                    <h4 className="layout-report-title font-blue">
                      Progress Report
                    </h4>
                  </div>
                  <div className="layout-info">
                    <div>
                      <div className="layout-info-block">
                        <p className="text-transform">
                          Name: {studentDetail.studentName}
                        </p>
                        <p className="text-transform">
                          Class: {studentDetail.classAlpha}
                        </p>
                        <p>
                          section:{" "}
                          {studentDetail.section == ""
                            ? "-"
                            : studentDetail.section}
                        </p>
                        <p>Roll No: {studentDetail.rollNo}</p>
                      </div>
                    </div>
                  </div>

                  <h4 className="layout-sub-title font-blue">{props.exam}</h4>

                  <div className="layout-table">
                    <div className="table-layout-one">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th rowSpan="2">S.N</th>
                            <th rowSpan="2" className="layout-subject">
                              Subjects
                            </th>
                            <th colSpan="2">Full Marks</th>
                            <th colSpan="3" className="layout-mark">
                              Obtained Mark
                            </th>
                            <th colSpan="1">Obtained Grade</th>
                            <th rowSpan="2">Grade Point</th>
                            <th rowSpan="2"> Percent %</th>
                          </tr>
                          <tr className="sub-head">
                            <th>Subjective</th>
                            <th>CAS</th>

                            <th>Subjective</th>
                            <th>CAS</th>
                            <th>Total</th>

                            <th>Final</th>
                          </tr>
                        </thead>
                        <tbody>
                          <LayoutTbodyBalmiki
                            filterSubject={filteredSubs}
                            studentDetail={studentDetail}
                            titleData={titleData}
                          />
                        </tbody>
                      </table>
                    </div>

                    <h4 className="layout-sub-title font-blue">
                      Grade point Average
                    </h4>

                    <div className="layout-table-two">
                      <table className="table table-bordered eca-remarks">
                        <thead>
                          <tr>
                            <th scope="col">GPA Obtained</th>
                            <th scope="col">Final Grade</th>
                            <th scope="col">Percentage</th>
                            <th scope="col">Attendance</th>
                            <th scope="col">Rank</th>

                            <th scope="col">Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{studentDetail.gradepoint}</td>
                            <td>{studentDetail.grade}</td>
                            <td>{studentDetail.percentage}</td>
                            <td>
                              {studentDetail.attendance}
                              {studentDetail.school_days
                                ? "/" + studentDetail.school_days
                                : ""}
                            </td>
                            <td>{studentDetail.classrank}</td>
                            {studentDetail.classrank === null ? (
                              <td></td>
                            ) : (
                              <td>
                                {props.extraData?.remark && (
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: studentDetail?.remark,
                                    }}
                                  ></div>
                                )}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="space-between">
                      <div
                        style={{
                          display: `${
                            window.location.host.includes("vidhyasagar")
                              ? "none"
                              : "block"
                          }`,
                          width: "50%",
                        }}
                        className="layout-table-three"
                      >
                        <p className="layout-sub-title font-blue">
                          Grading System
                        </p>
                        <div className="layout-text-area-block">
                          <table className="table table-bordered layout-one-two-table-three">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  width="28%"
                                  className="percentage-width"
                                >
                                  Equivalent Marks
                                </th>
                                <th scope="col" width="20%">
                                  Grade Point{" "}
                                </th>
                                <th scope="col" width="20%">
                                  Grade
                                </th>
                                <th
                                  scope="col"
                                  className="remark-width"
                                  width="32%"
                                >
                                  Remark
                                </th>
                              </tr>
                            </thead>
                            <tbody className="layout-three-tbody">
                              <tr>
                                <td>90 to 100</td>
                                <td>4.0</td>
                                <td>A+</td>
                                <td>Outstanding</td>
                              </tr>
                              <tr>
                                <td>80 to 90</td>
                                <td>3.6</td>
                                <td>A</td>
                                <td>Excellent</td>
                              </tr>
                              <tr>
                                <td>70 to 80</td>
                                <td>3.2</td>
                                <td>B+</td>
                                <td>Very good</td>
                              </tr>
                              <tr>
                                <td>60 to 70</td>
                                <td>2.8</td>
                                <td>B</td>
                                <td>Good</td>
                              </tr>
                              <tr>
                                <td>50 to 60</td>
                                <td>2.4</td>
                                <td>C+</td>
                                <td>Satisfactory</td>
                              </tr>
                              <tr>
                                <td>40 to 50</td>
                                <td>2.0</td>
                                <td>C</td>
                                <td>Acceptable</td>
                              </tr>

                              {props.info?.gradingSystem === "grading077" ? (
                                <>
                                  <tr>
                                    <td>30 to 40</td>
                                    <td>1.6</td>
                                    <td>D+</td>
                                    <td>Partically Acceptable</td>
                                  </tr>
                                  <tr>
                                    <td>20 to 30</td>
                                    <td>1.2</td>
                                    <td>D</td>
                                    <td>Insufficient</td>
                                  </tr>
                                  <tr>
                                    <td>0 to 20</td>
                                    <td>0.8</td>
                                    <td>E</td>
                                    <td>Very Insufficient</td>
                                  </tr>
                                </>
                              ) : (
                                <>
                                  <tr>
                                    <td>35 to 40</td>
                                    <td>1.6</td>
                                    <td>D</td>
                                    <td>Basic</td>
                                  </tr>
                                  <tr>
                                    <td>Below 35</td>
                                    <td>-</td>
                                    <td>NG</td>
                                    <td>Not Graded</td>
                                  </tr>
                                </>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="layout5-eca-width">
                        <p class="layout-sub-title font-blue">ECA</p>
                        <ECALayoutTbody
                          filterSubject={filteredSubs}
                          studentDetail={studentDetail}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="layout-bottom">
                    <div className="layout-bottom-block ">
                      <div>
                        <p className="issued-date">-</p>
                        <p className="layout-bottom-content"> Issued Date</p>
                      </div>
                      <p className="layout-bottom-content">Class Teacher</p>
                      <p className="layout-bottom-content">Principal</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
