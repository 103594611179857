import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap-tabs";
import { useCanteenMenu } from "../../store/Canteen";
import FoodCard from "../FoodCard/FoodCard";
import "./CanteenOrderMenu.scss"
import PreLoader from "../preLoader/preLoader"



const CanteenOrderMenu = () => {

  const { foodMenu, loading, fetchFoodMenu } = useCanteenMenu();
  

  const tabList =
    foodMenu?.length > 0 && foodMenu?.map((item, index) => item?.category);

  useEffect(() => {
    fetchFoodMenu();
  }, []);


  return (
    <div className="canteen_menu_container">
      {loading ? <div><PreLoader/></div> :
      foodMenu && foodMenu?.length > 0 && (
        <Tabs>
          {foodMenu?.map((item, index) => {
            return (
              <Tab key={item?.category_id} label={item?.category} title={item?.category}>
                <div className="food_menu_wrapper col">
                
                  {item?.menus?.map((menu, index) => (
                    <FoodCard  key={index} foodData={menu} />
                  ))}
                </div>
              </Tab>
              
            );
          })}
        </Tabs>
      )}
    </div>
  );
};

export default CanteenOrderMenu;
