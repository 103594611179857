import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Tab } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  getAvailableResources,
  getLessonPlanDetails,
  getTeacherData,
  getUnitApi,
} from "../../../components/Api/GetApi";
import {
  deleteTeachingPlan,
  postHodApproval,
  updateTeachingPlan,
} from "../../../components/Api/PostApi";
import PreLoader from "../../../components/preLoader/preLoader";
import { DeleteConfirmationModal } from "../../../components/teachingPlanComponents/lessonTab/deleteConfirmationModal";
import { EditModal } from "../../myTeachingPlan/editModal";
import { useTeachingPlanStore } from "../../teachingPlan/store/teachingPlanStore";
import "../index.scss";
import { useTeachingPlanMainStore } from "../store/teachingPlanMainStore";
import { FlowSheetDetails } from "./flowSheetDetails";
import { NoDataContent } from "../../../components/NoDataContent/NoDataContent";
import { Fab } from "@material-ui/core";
import HodCommentCard from "../../../components/HodCommentCard/HodCommentCard";
import { useUnitApi } from "../../../store/hodDashboardStore";
import { useTeacherDashboardStore } from "../../teacherdashboard/store/teacherDashboardStore";
import { DeleteLessonPlan } from "../../../components/Api/DeleteApi";
import NividLoader from "../../../components/NividLoader/NividLoader";

export const SinglePlanDetails = (props) => {
  const [visibleType, setVisibleType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  let gradeToken;
  let selectedUnit;
  let subjectToken;
  let month;
  let showHeader;
  let token;
  let isSection;
  const [unitList, setUnitList] = useState([]);
  const [viewAllComments, setViewAllComments] = useState(false);
  const history = useHistory();
  const { setViewPage, setEditMode, setIsAddLessonPlan, setIsUpdate } =
    useTeachingPlanStore();

  const { fetchHodUnitPlan } = useUnitApi();
  const { setIsHod, isHod } = useTeachingPlanStore();
  const [statusColor, setStatusColor] = useState();
  const [statusText, setStatusText] = useState();
  const [availableResources, setAvailableResources] = useState();


  useEffect(() => {
    getTeacherData().then((res) => {
      setIsHod(res.data?.is_hod);
    });
  }, []);

  useEffect(() => {
    getAvailableResources()
      .then((res) => {
        setAvailableResources(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [])

  if (props.popup === true) {
    gradeToken = props.selectedGrade !== undefined ? props.selectedGrade : null;
    selectedUnit = props.selectedId !== undefined ? props.selectedId : null;
    subjectToken =
      props.selectedSubject !== undefined ? props.selectedSubject : null;
    month = props.selectedMonth !== undefined ? props.selectedMonth : null;
    showHeader = props.showHeader;
  } else {
    let urlparam = new URLSearchParams(window.location.search);
    gradeToken = urlparam.get("gradeToken");
    selectedUnit = urlparam.get("selectedUnit");
    subjectToken = urlparam.get("subjectToken");
    month = urlparam.get("month");
    showHeader = urlparam.get("showHeader");
    token = urlparam.get("token");
    isSection = urlparam.get("isSection");
  }
  useEffect(() => {
    localStorage.setItem("authToken", token);
  }, [token]);

  const {
    lessonPlanDetails,
    setLessonPlanDetails,
    activeTab,
    setActiveTab,
    selectedLessonId,
    setSelectedLessonId,
    selectedStartDayToken,
    selectedEndDayToken,
    setSelectedUnitName,
    setSelectedUnit,
    unitDataFromLocal,
    activeTabName,
    setActiveTabName,
    selectedUnitData,
    setSelectedUnitData,
  } = useTeachingPlanMainStore();

  const { setSelectedLessonTab, selectedLessonTab, active } =
    useTeacherDashboardStore();

  const accordionConfig = [
    "response",
    "rules",
    "preview",
    "pedagogy",
    "skills",
    "homework",
    "classwork",
  ];
  const [expanded, setExpanded] = useState(accordionConfig?.map(() => true));

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[panelIndex] = isExpanded;
      return newExpanded;
    });
  };
  useEffect(() => {
    if (selectedLessonId !== undefined) {
      getLessonPlanDetails(selectedLessonId).then((res) => {
        setLessonPlanDetails(res?.data?.data);
      });
    }
  }, [selectedLessonId]);

  useEffect(() => {
    const fetchUnitApi = async () => {
      if (
        subjectToken &&
        gradeToken &&
        month &&
        subjectToken &&
        month &&
        subjectToken &&
        gradeToken &&
        month
      ) {
        setIsLoading(true);

        try {
          const res = await getUnitApi(
            gradeToken,
            subjectToken,
            month,
            isSection
          );
          setUnitList(res?.data?.data);
        } catch {
          setUnitList([]);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchUnitApi();
  }, [gradeToken, subjectToken, month, isSection]);

  useEffect(() => {
    if (unitList?.length > 0) {
      const temp = unitList?.find((item) => {
        return item?.id === Number(selectedUnit);
      });
      if (temp) {
        setSelectedUnitData(temp);
      }
    } else {
      setSelectedUnitData(null);
    }
  }, [unitList?.length]);

  useEffect(() => {
    setSelectedLessonId(selectedUnitData?.details?.[activeTab]?.lesson_plan_id);
    const selectedLesson = selectedUnitData?.details?.find((itm) => {
      return itm?.lesson_name === activeTabName;
    });
    setSelectedLessonTab(selectedLesson);
  }, [selectedUnitData, activeTab, activeTabName]);

  useEffect(() => {
    const selectedLessonStatus =
      props?.filterData?.[props?.arrayIndex]?.key[active].details?.[activeTab];

    let statusInd;
    let text;
    // switch (true) {
    //   case selectedLessonStatus?.has_assignment:
    //     statusInd = "green";
    //     text = "Assignment Added";
    //     break;

    //   case selectedLessonStatus?.approved_by &&
    //     selectedLessonStatus?.approved_by?.length > 0:
    //     statusInd = "yellow";
    //     text = "Lesson Approved";
    //     break;
    //   case selectedLessonStatus?.classwork_token:
    //     statusInd = "blue";
    //     text = "CW/HW Added";

    //     break;

    //   default:
    //     statusInd = "red";
    //     text = "Lesson Created";
    //     break;
    // }

    if (selectedLessonStatus?.has_assignment) {
      statusInd = "yellow";
      text = "Assignment Checked";
    } else if (
      selectedLessonStatus?.approved_by &&
      selectedLessonStatus?.approved_by?.length > 0
    ) {
      statusInd = "green";

      text = "Lesson Approved";
    } else if (selectedLessonStatus?.classwork_token) {
      statusInd = "blue";
      text = "CW/HW Added";
    } else {
      statusInd = "red";
      text = "Lesson Created";
    }
    setStatusColor(statusInd);
    setStatusText(text);
  }, [activeTab, props, active]);
  const handleDelete = (planId) => {
    const data = {
      teacher_plan_id: planId,
    };
    deleteTeachingPlan(data)
      .then((res) => {
        toast.success("Unit Plan deleted successfully !");
        setVisibleType("");
        history.push("/teaching-plan2");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDeletePlan = async () => {
    try {
      const res = await DeleteLessonPlan(selectedLessonId);
      if (res.data.status === "success") {
        toast.success("Lesson Plan deleted successfully !");
        setVisibleType("");
        props?.closeModal();
      } else {
        toast.success("something went wrong");
      }
    } catch {
      toast.success("Something went wrong");
    }
  };

  const handleEdit = (start_day_token, end_day_token) => {
    if (start_day_token === null || start_day_token === "") {
      toast.error("Please select start date");
      return;
    }
    if (end_day_token === null || end_day_token === "") {
      toast.error("Please select end date");
      return;
    }
    const data = {
      start_day_token: start_day_token,
      end_day_token: end_day_token,
      teacher_plan_id: selectedUnit,
    };
    updateTeachingPlan(data)
      .then((res) => {
        toast.success("Unit Plan updated successfully !");
        setVisibleType("");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const commentsData = useMemo(() => {
    if (viewAllComments) {
      return selectedUnitData?.comments;
    } else {
      return selectedUnitData?.comments?.slice(0, 1);
    }
  }, [selectedUnitData, viewAllComments]);

  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  const approve = async () => {
    if (selectedLessonTab?.approval_id) {
      const values = {
        approval_id: selectedLessonTab?.approval_id,
      };
      try {
        const response = await postHodApproval(values);
        toast(response.data.message);
        fetchHodUnitPlan(
          props?.selectedGrade,
          props?.selectedSubject,
          props?.selectedMonth
        );

        props?.closeModal();
      } catch {
        toast("something went wrong");
      }
    } else {
      toast("Approval failed");
    }
  };

  useEffect(() => {
    if (selectedUnitData) {
      setActiveTabName(selectedUnitData?.details?.[activeTab]?.lesson_name);
    }
  }, [selectedUnitData, activeTab]);

  if (isLoading) {
    return <NividLoader height="100%" />;
  }
  return (
    <div className="single-plan-details-page" style={{ height: "100%" }}>
      {showHeader ? (
        <div className="title-block header">
          <i
            className="bx bxs-chevron-left cursor-pointer"
            onClick={() => {
              history.push(`/teaching-plan2?token=${token}`);
            }}
          ></i>
          <h2 className="title">Unit Plan Details</h2>
          {!props.popup && (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="icon-section"
            >
              <div
                className="icon"
                onClick={() => {
                  // remove(index);
                  setSelectedUnit(selectedUnitData?.id);
                  setSelectedUnitName(selectedUnitData?.unit_name);
                  setEditMode(false);
                  setViewPage("lessonTab");
                  setIsAddLessonPlan(true);
                  setEditMode(false);
                  setIsUpdate(true);
                  if (unitDataFromLocal) {
                    setVisibleType("continue");
                  } else {
                    history.push(`/new_plan?token=${token}`);
                  }
                }}
                title="Add Lesson Plan"
              >
                <i class="bx bx-plus icon-bg"></i>
              </div>
            </div>
          )}
        </div>
      ) : null}

      {selectedUnitData ? (
        <>
          <div className="unit-plan-details">
            <div className="unit-plan-card">
              <div className="unit-plan-info">
                <div className="unit-title">
                  <p className="title">{selectedUnitData?.unit_name} </p>
                  <div className="grade-tag">
                    Class{" "}
                    {props?.showClassAlpha
                      ? selectedUnitData?.classAlpha
                      : selectedUnitData?.class_name}
                  </div>
                </div>

                <div className="extra-info-section">
                  <i className="bx bx-time icon-bg"></i>
                  <p>
                    Total Teaching Hours:
                    <span>{selectedUnitData?.total_teaching_hour}</span>
                  </p>
                </div>
                <div className="extra-info-section">
                  <i className="bx bx-calendar icon-bg"></i>
                  <p>
                    Date:
                    <span>
                      {selectedUnitData?.start_date} -
                      {selectedUnitData?.end_date}
                    </span>
                  </p>
                </div>
                {selectedUnitData?.unit_objective !== "" && (
                  <>
                    <hr />
                    <div className="single-info">
                      <p className="single-title">Unit Objective</p>
                      <p className="single-content">
                        {selectedUnitData?.unit_objective}
                      </p>
                    </div>
                  </>
                )}
                {!props.popup && (
                  <>
                    {selectedUnitData?.comments?.length > 0 && (
                      <>
                        <hr />
                        <div className="single-info">
                          <p className="single-content">Comments</p>
                        </div>
                        <div className="comment_body_wrapper">
                          {commentsData?.map((item, index) => (
                            <HodCommentCard key={index} data={item} />
                          ))}
                        </div>
                        {selectedUnitData?.comments?.length > 1 && (
                          <>
                            {viewAllComments ? (
                              <div
                                className="view-all-btn"
                                onClick={() => {
                                  setViewAllComments(false);
                                }}
                                title="View Less Comments"
                              >
                                <p>View Less </p>
                                <i class="bx bx-chevron-up icon-bg"></i>
                              </div>
                            ) : (
                              <div
                                className="view-all-btn"
                                onClick={() => {
                                  setViewAllComments(true);
                                }}
                                title="View All Comments"
                              >
                                <p>View All </p>{" "}
                                <i class="bx bx-chevron-down icon-bg"></i>
                              </div>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="lesson-section">
              <TabContext value={activeTab}>
                <TabList
                  aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  {selectedUnitData?.details?.map((item, index) => {
                    const selectedSectionLesson =
                      props?.filterData?.[props?.arrayIndex]?.key[active]
                        .details?.[index];
                    const status = selectedSectionLesson?.has_assignment
                      ? "yellow"
                      : selectedSectionLesson?.approved_by &&
                        selectedSectionLesson?.approved_by?.length > 0
                      ? "green"
                      : selectedSectionLesson?.classwork_token
                      ? "blue"
                      : "red";

                    return (
                      <Tab
                        onClick={() => {
                          setActiveTab(index);
                          setActiveTabName(item?.lesson_name);
                          setSelectedLessonId(item?.lesson_plan_id);
                        }}
                        className="tab-label-header"
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {props?.showClassAlpha && (
                              <>
                                {status && (
                                  <div
                                    className={`status-indicator ${status}`}
                                  ></div>
                                )}
                              </>
                            )}
                            <span>{item?.lesson_name}</span>
                          </div>
                        }
                        value={index}
                        key={index}
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </TabList>
                <TabPanel value={activeTab} key={activeTab}>
                  <div className="lesson-scroll-bar">
                    <div className="lesson-details">
                      <div className="row">
                        <div className="col-md-12 ">
                          <div className="header-border">
                            <div className="lesson-details-wrap">
                              <p> {selectedLessonTab?.lesson_name}</p>
                              {props?.showClassAlpha && (
                                <div
                                  className={`status-card-indicator ${statusColor}`}
                                >
                                  {statusText}
                                </div>
                              )}
                            </div>

                            {isHod &&
                              !selectedLessonTab?.approved_by?.length > 0 && (
                                <div
                                  className="delete-block"
                                  title="Delete Lesson"
                                  onClick={() => {
                                    setVisibleType("deletePlan");
                                  }}
                                >
                                  <i className="bx bx-trash"></i>
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="single-info">
                            <p className="single-title">Total Time</p>
                            <p className="single-content">
                              {lessonPlanDetails?.[0]?.total_time} minutes
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <div className="single-info">
                            <p className="single-title">Page No:</p>
                            <p className="single-content">
                              {lessonPlanDetails?.[0]?.from_page} to{" "}
                              {lessonPlanDetails?.[0]?.to_page}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-12 mt-3">
                          <div className="single-info">
                            <p className="single-title">Lesson Objectives</p>
                            <p className="single-content">
                              {lessonPlanDetails?.[0]?.objective
                                ? lessonPlanDetails?.[0]?.objective
                                : "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="accordion">
                      <h3 className="title">Lesson Flow Sheet </h3>
                      <div class="table-container">
                        <table className="table">
                          <thead className="thead-light">
                            <tr>
                              <th>Steps</th>
                              <th width="70%">
                                Teaching
                                methods/Activities/Experiments/Practical work
                              </th>
                              <th>Time</th>
                              <th>Resources</th>
                              <th>Key words</th>
                              <th>Homework</th>
                              <th>Classwork</th>
                            </tr>
                          </thead>

                          <tbody>
                            {accordionConfig?.map((accordion, accIndex) => {
                              let resourcesForPanel = [];
                              availableResources?.forEach((resourceGroup) => {
                                if (resourceGroup[accordion]) {
                                  resourcesForPanel = resourceGroup[accordion];
                                }
                              });
                              return (
                                <tr key={accIndex}>
                                  <td>
                                    {" "}
                                    Step {accIndex + 1} ({accordion})
                                  </td>
                                  <FlowSheetDetails
                                    keyName={accordion}
                                    selectedLessonData={lessonPlanDetails?.[0]}
                                    resourcesForPanel={resourcesForPanel}
                                  />
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {!props?.removeEdit && (
                      <div
                        className="floating-action-item"
                        onClick={() => {
                          setEditMode(true);
                          setSelectedUnit(selectedUnitData?.id);
                          setSelectedUnitName(selectedUnitData?.unit_name);
                          setViewPage("lessonTab");
                          setIsAddLessonPlan(true);
                          setIsUpdate(true);
                          history.push(`/new_plan?token=${token}`);
                        }}
                      >
                        <Fab aria-label="edit">
                          <i className="bx bx-edit"></i>
                        </Fab>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </TabContext>
            </div>
            <div className="fixed-footer-block">
              {selectedLessonTab?.approved_by &&
              selectedLessonTab?.approved_by?.length > 0 ? (
                <div className="approved-lesson-text alert alert-success">
                  <i class="bx bx-check-circle"></i>
                  <span>{selectedLessonTab?.lesson_name}</span>
                  Lesson has been approved
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                    marginBottom: "3px ",
                  }}
                >
                  {/* <div
                onClick={() => props?.closeModal()}
                style={{ paddingTop: "10px" }}
                className="decline_button"
              >
                Decline
              </div> */}

                  {isHod && (
                    <div
                      onClick={async () => {
                        await approve();
                      }}
                      style={{ paddingTop: "10px" }}
                      className="approve_button"
                    >
                      Approve
                    </div>
                  )}
                </div>
              )}
            </div>
            {visibleType === "delete" && (
              <DeleteConfirmationModal
                visible={visibleType === "delete"}
                setVisible={setVisibleType}
                onDelete={() => {
                  handleDelete(selectedUnit);
                }}
              />
            )}
            {visibleType === "edit" && (
              <EditModal
                visible={visibleType === "edit"}
                setVisible={setVisibleType}
                onEdit={handleEdit}
                startDateProps={selectedStartDayToken}
                endDateProps={selectedEndDayToken}
              />
            )}

            {visibleType === "deletePlan" && (
              <DeleteConfirmationModal
                visible={visibleType === "deletePlan"}
                setVisible={setVisibleType}
                onDelete={() => {
                  handleDeletePlan();
                }}
              />
            )}
          </div>
        </>
      ) : (
        <NoDataContent
          title="No  data found"
          image={require("../../../images/emptyfile.png").default}
        />
      )}
    </div>
  );
};
