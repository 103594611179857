import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./LineTab.scss";

export const LineTab = ({ tabData, defaultIndex }) => {
  return (
    <div className="line-tab-section">
      <div>
        <Tabs
          defaultActiveKey={
            defaultIndex ? tabData[1].title : tabData[0]?.title
          }>
          {tabData?.map((item, index) => {
            return (
              <Tab key={index} eventKey={item?.title} title={item?.title}>
                {item?.content}
              </Tab>
            );
          })}
        </Tabs>
      </div>
    </div>
  );
};

export default LineTab;
