import Axios from "axios";
import React, { useState } from "react";
import Select from "react-select";
import { useEffect } from "react";
import {
  getEditStFeedback,
  getFeedback,
  getGradeApi,
  getStudentInfo,
} from "../Api/GetApi";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import "./StudentFeedback.scss";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { postStudentfeedback } from "../Api/PostApi";
import { FeedbackTablebody } from "./FeedbackTablebody";

const StudentFeedback = ({ showHeader }) => {
  const [grade, setGrade] = useState();
  const [gradeValue, setGradeValue] = useState();
  const [student, setStudent] = useState();
  const [studentValue, setStudentValue] = useState();
  const [loader, setLoader] = useState();
  const [feedback, setFeedback] = useState();
  const [editValue, setEditValue] = useState();

  useEffect(() => {
    getGradeApi().then((res) => {
      console.log("grade", res.data);
      const data = res.data.grade.map((item) => {
        return {
          value: item.id,
          label: item.classAlpha,
        };
      });
      setGrade(data);
    });

    getFeedback().then((res) => {
      console.log(res);
      setFeedback(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (gradeValue) {
      getStudentInfo(gradeValue?.value, true).then((res) => {
        const data = res.data.map((item) => {
          return {
            value: item.id,
            label: item.studentName,
          };
        });

        setStudent(data);
        console.log("student", res.data);
      });
    }
  }, [gradeValue]);

  const RemarkTableSchema = Yup.object().shape({
    remarkList: Yup.array().of(
      Yup.object().shape({
        remark_type: Yup.string().required("This field is required"),
        remark: Yup.string().required("This field is required"),
      })
    ),
  });
  return (
    <div className="student-feedback-section">
      {/* {showHeader && (
        <div className="title-block header">
          <Link to="/">
            <i className="bx bxs-chevron-left"></i>
          </Link>

          <h2 className="title">Student Feedback </h2>
        </div>
      )} */}
      <div className="container">
        <div className="feedback-header row mt-20">
          <div className="col-md-4">
            <label>Select Grade</label>
            <Select
              options={grade}
              value={gradeValue}
              onChange={(option) => {
                setGradeValue(option);
              }}
            />
          </div>

          <div className="col-md-4">
            <label>Select Student</label>
            <Select
              options={student}
              value={studentValue}
              onChange={(option) => {
                setStudentValue(option);
              }}
            />
          </div>
        </div>
        {/* 
        <div className="add-btn-content">
          <button
            className="btn submit_btn"
            onClick={() => {
              const newArr = {
                remark_type: "",
                remark: "",
              };

              const tempdata = remarkList;
              setRemarkList([...tempdata, newArr]);
            }}
          >
            <i class="bx bx-plus"></i>
            Add new Remark
          </button>
        </div> */}
        <div style={{ marginTop: "20px" }}>
          <Formik
            initialValues={{
              remarkList: [{ remark_type: "", remark: "" }],
            }}
            validationSchema={RemarkTableSchema}
            onSubmit={async (values, { setFieldValue }) => {
              console.log("formikvaklues", values);
              if (!gradeValue?.value) {
                toast.error("Please select grade");
              } else if (!studentValue?.value) {
                toast.error("Please select student");
              } else {
                setLoader(true);
                const data = {
                  grade: gradeValue.value,
                  student_id: studentValue?.value,
                  data: values.remarkList,
                };

                try {
                  const response = await postStudentfeedback(data);
                  console.log(response);
                  if (response.status === 200) {
                    toast(response?.data.message);
                    setFieldValue("remarkList", [
                      { remark_type: "", remark: "" },
                    ]);
                    setGradeValue("");
                    setStudentValue("");
                  } else {
                    toast("something went wrong");
                  }
                } catch (err) {
                  toast("something went wrong");
                  console.error(err);
                } finally {
                  setLoader(false);
                }
              }
            }}
          >
            {({ values, setFieldValue }) => (
              <Form>
                <FeedbackTablebody
                  gradeValue={gradeValue}
                  studentValue={studentValue}
                  feedback={feedback}
                  loader={loader}
                  setFieldValue={setFieldValue}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default StudentFeedback;
