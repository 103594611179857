import React from "react";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
// import MultipleImageUploader from "../mulitpleImageUploader/multiPleImageUploader";
import Axios from "axios";
import { domain, mulitpartHeaders } from "../../url";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PopUp from "../popUp/popUp";
import "./schoolWall.scss";
import { NepDatePicker } from "neps-date-picker";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

class SchoolWall extends React.Component {
  state = {
    postedDate: "",
    description: "",
    images: [],
    openSnackBar: false,
    snackMessge: "",
    visible: false,
    modalWidth: "50%",
    snackStatus: true,
    loader: false,
  };

  openModal = () => {
    if (this.state.postedDate === "") {
      this.setState({
        loader: false,
        openSnackBar: true,
        snackMessge: "Please enter Posed date",
      });
    } else if (this.state.description === "") {
      this.setState({
        openSnackBar: true,
        snackMessge: "Please enter description",
        loader: false,
      });
    } else {
      this.setState({
        visible: true,
        loader: false,
      });
    }
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  addImages = (images) => {
    this.setState({
      images,
    });
  };

  inputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
  };

  uploadMultipleFiles = (e) => {
    this.setState({ images: e.target.files });
  };

  postSchoolWall = async () => {
    this.setState({
      loader: true,
      visible: false,
    });
    const formData = new FormData();
    formData.append("postedDate", this.state.postedDate);
    formData.append("description", this.state.description);
    [...this.state.images].forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });

    var { data } = await axiosInstance.post(
      `${domain}/teacher_api/schoolWall`,
      formData
    );
    if (data.status === 11) {
      this.setState({
        openSnackBar: true,
        snackMessge: "School Wall Submited Sucessfully !",
        visible: false,
        snackStatus: true,
        loader: false,
        description: "",
        images: "",
      });
    } else {
      this.setState({
        openSnackBar: true,
        snackMessge: "Fail !",
        visible: false,
        snackStatus: false,
        loader: false,
      });
    }
  };

  wall = () => {
    return (
      <div className="school-wall">
        <div className="title-block header">
          {/* <Link to="/">
            <i className="bx bxs-chevron-left"></i>
          </Link> */}
          <h2 className="title">School Wall</h2>
        </div>
        <div className="section-gap">
          <div className="container">
            <div className="input-content-block">
              <p className="content">Posted Date</p>

              <NepDatePicker
                onDateSelect={(val) => {
                  this.setState({ postedDate: val });
                }}
              />
            </div>
            <div className="input-content-block">
              <p className="content">Description</p>
              <textarea
                className="text-field"
                placeholder="Description"
                name="description"
                value={this.state.description}
                onChange={this.inputChange}
              />
            </div>
            <div className="input-content-block">
              <p className="content">Images</p>
              {/* <DropZone /> */}
              <input
                className="file-upload"
                type="file"
                accept="image/*"
                onChange={this.uploadMultipleFiles}
                multiple
              />
              {/* <MultipleImageUploader addImages={this.addImages} /> */}
            </div>
            <button
              className="main-btn"
              onClick={() => {
                this.openModal();
                // this.setState({
                //   loader: true,
                // });
              }}
              disabled={this.state.loader}
            >
              {this.state.loader ? "...loading" : "Submit"}
            </button>
            <PopUp
              visible={this.state.visible}
              closeModal={() => {
                this.closeModal();
                this.setState({
                  loader: false,
                });
              }}
              title="School Wall"
              post={this.postSchoolWall}
            />
            <Snackbar
              open={this.state.openSnackBar}
              autoHideDuration={3000}
              onClose={() => {
                this.setState({
                  openSnackBar: false,
                });
              }}
            >
              {this.state.snackStatus ? (
                <Alert severity="success">{this.state.snackMessge}</Alert>
              ) : (
                <Alert severity="error">{this.state.snackMessge}</Alert>
              )}
            </Snackbar>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return <>{this.wall()}</>;
  }
}

export default SchoolWall;
