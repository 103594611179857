import { get } from "jquery";
import { useStudentStore } from "../../store/store";
import ChartBar from "../ChartBar/ChartBar";
import "./studentInformation.scss";
import { useEffect, useState } from "react";
import { useStudentAttendance } from "../store/useStore";

const StudentInformation = () => {
  const { studentRemark } = useStudentStore();
  const { data } = useStudentAttendance();
  const studentRemarkData = studentRemark?.data;

  const [studentAttendence, setStudentAttendance] = useState(null);
  useEffect(() => {
    setStudentAttendance(data);
  }, [data]);

  const studentInfo = [
    {
      title: "Date of Birth",
      info: "dob_token",
    },
    {
      title: "Father's name",
      info: "fathername",
    },
    {
      title: "P. Address",
      info: "permanentAddress",
    },

    {
      title: "Blood Group",
      info: "bloodGroup",
    },
    {
      title: "Gender",
      info: "gender",
    },
    {
      title: "T. Address",
      info: "temporaryAddress",
    },
  ];

  const { student } = useStudentStore();

  const splitIntoColumns = (data, columns) => {
    const itemsPerColumn = Math.ceil(data.length / columns);
    const columnsData = [];

    for (let i = 0; i < columns; i++) {
      const startIndex = i * itemsPerColumn;
      const endIndex = startIndex + itemsPerColumn;
      const columnItems = data?.slice(startIndex, endIndex);
      columnsData.push(columnItems);
    }

    return columnsData;
  };

  const columnsData = splitIntoColumns(studentInfo, 2);

  return (
    <div className="student-info-container">
      <div className="row">
        <div className="col-md-6">
          <div className="data">
            {columnsData.map((column, columnIndex) => (
              <div key={columnIndex} className="column">
                {column.map((item, itemIndex) => (
                  <div key={itemIndex} className="text">
                    <p>{item.title}:</p>
                    {/* <span>{studentInfo && studentInfo[item.info]}</span> */}
                    <span
                      className={
                        item.title === "Contact Number" ? "contact" : null
                      }
                    >
                      {student && student.student_info?.[item.info]
                        ? student.student_info?.[item.info]
                        : "N/A"}
                    </span>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>

        <div className="col-md-6">
          <div className="chart-bar">
            <ChartBar attRemark={studentAttendence} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentInformation;
