import React from "react";

export default function LayoutTbodyBalmiki(props) {

  var newIndex = 0;
  const studentDetail = props.studentDetail;
  return (
    <>
      {props.filterSubject?.map((subject, index) => {
        {
          newIndex = +(index + 1);
        }
        const title_data = props.titleData.find(function (data) {
          return data.subjectName === subject.subjectName;
        })?.titledata ?? { tfm: "-", pfm: "-" };
        const totalSubjectMarks =
          title_data.pfm != "-"
            ? parseInt(title_data.tfm) + parseInt(title_data.pfm)
            : parseInt(title_data.tfm);
        const subjectObtainedPercentage =
          (studentDetail.subjects[subject.subjectName]?.totalobtained /
            totalSubjectMarks) *
          100;
        return (
          <tr key={index}>
            <td>{subject.subjectName ? index + 1 : ""}</td>
            <td className="layout-subject-body">{subject.subjectName}</td>
            <td>{title_data.tfm}</td>
            <td>{title_data.pfm}</td>
            <td >
              {studentDetail.subjects[subject.subjectName]?.theorymark}
            </td>
            <td >
              {studentDetail.subjects[subject.subjectName]?.praticalmark}
            </td>
            <td >
              {studentDetail.subjects[subject.subjectName]?.totalobtained}
            </td>
            <td >
              {" "}
              {studentDetail.subjects[subject.subjectName]?.totalgrade}
            </td>
            <td> {studentDetail.subjects[subject.subjectName]?.totalpoint}</td>
            <td>{subjectObtainedPercentage.toFixed(2)}</td>
          </tr>
        );
      })}
    </>
  );
}
