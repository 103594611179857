import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LessonTab } from "../../components/teachingPlanComponents/lessonTab";
import { NewPlan } from "../../components/teachingPlanComponents/newPlan";
import { FormikWrapper } from "./formikWrapper/formikWrapper";
import { useTeachingPlanStore } from "./store/teachingPlanStore";
import { useEffect, useState } from "react";

export const TeachingPlan = ({ showHeader }) => {
  const { viewPage, setViewPage, isAddLessonPlan } = useTeachingPlanStore();
  const history = useHistory();
  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");
  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const VIEW = {
    newPlan: <NewPlan showHeader={showHeader} />,
    lessonTab: <LessonTab />,
  };
  return (
    <div className="teaching-plan-page">
      <FormikWrapper>
        <>
          {/* <div className="title-block header">
            {showHeader || viewPage === "lessonTab" ? (
              <>
                  <i
                    className="bx bxs-chevron-left cursor-pointer"
                    onClick={() => {
                      if (viewPage === "lessonTab" && !isAddLessonPlan) {
                        return setViewPage("newPlan");
                      } else {
                        history.push(`/teaching-plan2?token=${token}`);
                      }
                    }}
                  ></i>
              </>
            ) : (
              ""
            )}
            <h2 className="title">
              {viewPage === "newPlan" ? "Create New Plan" : "Lesson Plan"}
            </h2>
          </div> */}
          {VIEW[viewPage]}
        </>
      </FormikWrapper>
    </div>
  );
};
