import "./feeHistoryTop.scss";
import { useStudentStore } from "../../store/store";
const FeeHistoryTop = () => {
  const { student } = useStudentStore();
  const dueBalance = student?.accontig_details?.[0]?.due_balance;
  const advanceBalance = student?.accontig_details?.[0]?.advance_balance;

  return (
    <div className="fee-history-top-container">
      <h4>Fee History</h4>
      <div className="card">
        <div className="dueAmount">
          <p>Due Amount:</p>
          <span>Rs. {dueBalance ? dueBalance : 0}</span>
        </div>
        <div className="horizontalDivider"></div>
        <div className="paidAmount">
          <p>Advanced:</p>
          <span>Rs. {advanceBalance ? advanceBalance : 0}</span>
        </div>
      </div>
    </div>
  );
};

export default FeeHistoryTop;
