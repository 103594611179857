import { useIframeTokenStore } from "./components/store/useStore";
export let portalDomain = "https://demo.elearning.app/";
export let domain = "https://demo79.uat.nivid.app";
export let socketDomain = "ws://demo79.uat.nivid.app";

export let schoolRegistrationDomain =
  "https://admin.kavrepabson.nivid.app/api/axios";
// export let domain = "https://demo79.uat.nivid.app";
// export let domain = "https://bimal.uat.nivid.app";
export const imgDomain = "https://storage.googleapis.com/nivid/";
const origin = window.location.origin;

const liveUrlPattern =
  /^(https:\/\/[a-zA-Z0-9.-]+\.react\.nivid\.app|https:\/\/[a-zA-Z0-9.-]+\.react\.dabalimart\.com\.np|https:\/\/[a-zA-Z0-9.-]+\.react\.beta\.nivid\.app)$/;
let urlparam = new URLSearchParams(window.location.search);
let token = "";

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  token = localStorage.getItem("token", null) || urlparam.get("token") || "";
} else {
  token = localStorage.getItem("token", null) || urlparam.get("token");

  if (liveUrlPattern.test(origin)) {
    domain = origin.replace("react.", "");
    portalDomain = origin.replace("react", "elearning");
  } else {
    domain = origin.replace("nividreact.axiossoftworks.com", "uat.nivid.app");
    portalDomain = origin.replace(
      "nividreact.axiossoftworks.com",
      "portal.nivid.app"
    );
  }
}

// Token from mobile web view
export const jwtToken = token;

export const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
};
// Headers for multipart
export const mulitpartHeaders = {
  "Content-Type": "multipart/form-data",
  Authorization: `Bearer ${token}`,
};
