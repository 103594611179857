import React from "react";
import { useSchoolRegistrationStore } from "../../pages/schoolRegistration/store/schoolRegistrationStore";
import { useCountryHooks } from "./useCountryHooks";
import { handleEnterKey } from "../../utils/service";

export const BasicInfo = () => {
  const schoolRegistrationState = useSchoolRegistrationStore((state) => state);
  const {
    setState,
    setDistrict,
    setMunicipality,
    setWardNo,
    state,
    district,
    municipality,
    wardNo,
  } = schoolRegistrationState;
  const { stateList, districtList, municipalityList } = useCountryHooks();

  return (
    <div className="basic-info section-gap">
      <div className="row">
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Country<i className="required-level">*</i></p>
            <input className="input-field" value="Nepal" disabled />
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">State<i className="required-level">*</i></p>
            <select
              className="input-field"
              value={state}
              onKeyDown={(e) => handleEnterKey(e, "district")}
              onChange={(e) => {
                setState(e.target.value);
              }}
            >
              <option>Select State</option>
              {stateList?.map((item, index) => {
                return (
                  <option value={item?.id} key={index}>
                    {item?.school_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">District<i className="required-level">*</i></p>
            <select
              className="input-field"
              id="district"
              value={district}
              onKeyDown={(e) => handleEnterKey(e, "municipality")}
              onChange={(e) => {
                setDistrict(e.target.value);
              }}
            >
              <option>Select District</option>
              {districtList?.map((item, index) => {
                return (
                  <option value={item?.id} key={index}>
                    {item?.school_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Municipality<i className="required-level">*</i></p>
            <select
              className="input-field"
              id="municipality"
              value={municipality}
              onKeyDown={(e) => handleEnterKey(e, "wardNo")}
              onChange={(e) => {
                setMunicipality(e.target.value);
              }}
            >
              <option>Select municipality</option>
              {municipalityList?.map((item, index) => {
                return (
                  <option value={item?.id} key={index}>
                    {item?.school_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Ward No.<i className="required-level">*</i></p>
            <input
              className="input-field"
              id="wardNo"
              value={wardNo}
              onChange={(e) => {
                setWardNo(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
