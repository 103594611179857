// import { useEffect, useState } from "react";
import "./loader.scss";

const Loader = () => {
  return (
    <div className="loader-container">
      <img src={require("../../images/logo.png").default} className="loader" />
      <span> Please wait....</span>
    </div>
  );
};

export default Loader;
