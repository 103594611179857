import React from "react";
import "./ParentsInfo.scss";

export const ParentsInfo = () => {
  return (
    <div className="parents-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <div className="admission-header">
              <h4 className="main-title school-name">Kathmandu World School</h4>
              <div className="address-wrapper d-column">
                <div className="address-info">
                  <p className="main-content place-name">Surya Binayak - 7</p>
                  <span className="side-bar"></span>
                  <p className="main-content address">
                    Gundu, Bhaktapur, Nepal
                  </p>
                </div>
                <div className="address-info">
                  <p className="main-content phone-no">5090337</p>
                  <span className="side-bar"></span>
                  <p className="main-content email">info@kws.edu.np</p>
                </div>
              </div>
            </div>

            <div className="option-head">
              {/* <div className="input-field-block">
                    <p className="main-content uppercase">
                      Grade Applied For<span className="star">*</span>:
                    </p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Grade 1", "Grade 2", "Grade 3"]?.map((item) => {
                        return <option key={item}>{item}</option>;
                      })}
                    </select>
                  </div> */}
              <div className="uppercase small-title center">
                Parent's Information
              </div>
            </div>
            <div className="fathers-detail">
              <div className="uppercase medium-content blue-text">
                Father's Information
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Father's Name:</p>
                    <input className="input-field" name="fatherName" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Email:</p>
                    <input className="input-field" name="femail" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Occupation:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Teacher", "Banker", "Farmer"]?.map((item) => {
                        return (
                          <option key={item} name="foccupation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Mobile No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="fmobile"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Post:</p>
                    <input className="input-field" name="fpost" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Office:</p>
                    <input className="input-field" name="foffice" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Telephone(Office):</p>
                    <input
                      className="input-field"
                      type="number"
                      name="ftel"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fathers-detail">
              <div className="uppercase medium-content blue-text">
                Mother's Information
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Mother's Name:</p>
                    <input className="input-field" name="motherName" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Email:</p>
                    <input className="input-field" name="memail" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Occupation:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Teacher", "Banker", "Farmer"]?.map((item) => {
                        return (
                          <option key={item} name="moccupation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Mobile No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="mmobile"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Post:</p>
                    <input className="input-field" name="mpost" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Office:</p>
                    <input className="input-field" name="moffice" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Telephone(Office):</p>
                    <input
                      className="input-field"
                      type="number"
                      name="mtel"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="fathers-detail">
              <div className="uppercase medium-content blue-text">
                Guardian's Information
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">
                      Local Guardian's Name:
                    </p>
                    <input className="input-field" name="guardianName" />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Address:</p>
                    <input className="input-field" name="gaddress" />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Relationship:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Brother", "Sister"]?.map((item) => {
                        return (
                          <option key={item} name="grelation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Occupation:</p>
                    <select className="input-field">
                      <option>--Select--</option>
                      {["Teacher", "Banker", "Farmer"]?.map((item) => {
                        return (
                          <option key={item} name="goccupation">
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Mobile No.:</p>
                    <input
                      className="input-field"
                      type="number"
                      name="gmobile"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="main-content name-tag">Telephone(Home):</p>
                    <input
                      className="input-field"
                      type="number"
                      name="gtel"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
