import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useExamMarksStore } from "../../store/examMarksStore";
import { ExamMarksPost } from "../Api/PostApi";
import "./examMarks.scss";
import { ExamMarksDetails } from "./examMarksDetails";

export const ExamMarks = () => {
  const examMarksState = useExamMarksStore((state) => state);

  useEffect(() => {
    if (isBrowser) {
      examMarksState?.setViewExamMarks(false);
    }
  }, [isBrowser]);

  return (
    <div className="exam-marks">
      <div className="title-block">
        {examMarksState?.viewExamMarks ? (
          ""
        ) : (
          <>
            {isMobile && (
              <Link
                onClick={() => {
                  examMarksState?.setViewExamMarks(true);
                }}
              >
                <i className="bx bx-left-arrow-alt"></i>
              </Link>
            )}
            <h2 className="title">Add Exam Marks</h2>
          </>
        )}
      </div>
      <div className="container">
        <Formik
          initialValues={{
            cas_eval: null,
            internal_eval: false,
            exam_subject_token: null,
            subject_token: null,
            eval_subject_token: null,
            data: [],
            examToken: null,
            gradeToken: null,
            strictFilter: null,
          }}
          validationSchema={Yup.object().shape({})}
          onSubmit={(values, { setFieldValue, setSubmitting }) => {
            setSubmitting(true);
            if (
              !Number(values?.subject_token) ||
              Number(values?.subject_token) === 0
            ) {
              examMarksState?.setSnackMessge("Please select subject");
              examMarksState?.setSnackStatus(false);
              examMarksState?.setOpenSnackBar(true);
              setSubmitting(false);
              return;
            }
            // if (
            //   !Number(values?.exam_subject_token) ||
            //   Number(values?.exam_subject_token) === 0
            // ) {
            //   examMarksState?.setSnackMessge("Please select evaluation type");
            //   examMarksState?.setSnackStatus(false);
            //   examMarksState?.setOpenSnackBar(true);
            //   setSubmitting(false);
            //   return;
            // }

            // const temp = {
            //   cas_eval: values.cas_eval,
            //   internal_eval: values.internal_eval,
            //   data: values.data,
            //   exam_subject_token: values?.exam_subject_token,
            //   grade_token: values?.gradeToken,
            //   exam_token: values?.examToken,
            // };

            examMarksState?.submitStudentData?.forEach((element) => {
              ExamMarksPost(element)
                .then((res) => {
                  if (res.status === 204 || res.status === 200) {
                    // examMarksState?.setSnackMessge(
                    //   "Exam Marks Submitted Successfully!"
                    // );
                    if (res.data.status === 200) {
                      examMarksState?.setSnackMessge(res?.data?.message);
                      examMarksState?.setSnackStatus(true);
                      examMarksState?.setOpenSnackBar(true);
                    } else {
                      examMarksState?.setSnackMessge(res?.data?.message);
                      examMarksState?.setSnackStatus(false);
                      examMarksState?.setOpenSnackBar(true);
                    }

                    examMarksState?.setSelectedOption();
                    setFieldValue("exam_subject_token", null);
                    setFieldValue("eval_subject_token", null);
                    setFieldValue("gradeToken", 0);
                    setFieldValue("subject_token", 0);
                    setFieldValue("examToken", 0);
                    setFieldValue("internal_eval", false);
                    examMarksState?.setSubmitStudentData([]);
                  }
                })
                .catch((err) => {
                  examMarksState?.setSnackMessge(
                    err.response.data.message || "Something went wrong"
                  );
                  examMarksState?.setSnackStatus(false);
                  examMarksState?.setOpenSnackBar(true);
                })
                .finally(() => {
                  setSubmitting(false);
                });
            });
          }}
        >
          {({ values }) => (
            <Form>
              <>
                <ExamMarksDetails />
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
