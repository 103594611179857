import React, { useEffect } from "react";
import BookReview from "../BookReview/BookReview";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./LibaryManagement.scss";
import { useLibararyManagemnet } from "../store/useStore";


function LibraryManagement() {
  const { data, fetchLibararyManagemnet } = useLibararyManagemnet();
useEffect(() => {
    fetchLibararyManagemnet();
  }, []);

  const pendingDatas = data?.Pending;
  const reviewedDatas = data?.Reviewed;

  const tabs = [
    {
      name: "Pending",
      component: (
        <BookReview pendingDatas={pendingDatas} reviewedDatas={reviewedDatas} />
      ),
    },
    {
      name: "Completed",
      component: <BookReview pendingDatas={reviewedDatas} />,
    },
  ];

  return (
    <>
      <div className="libary_management ">
        <div className="title-block header ">
          <h1 className="title">Library Management</h1>
        </div>
        <div className="section-gap libary_cart p-4">
          <Tabs defaultActiveKey="Pending">
            {tabs.map((tab) => (
              <Tab eventKey={tab.name} title={tab.name} key={tab.name}>
                {tab.component}
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </>
  );
}

export default LibraryManagement;
