import ReactHtmlParser from "react-html-parser";
import { NoDataContent } from "../../../components/NoDataContent/NoDataContent";

export const Reading = ({ data }) => {
  return (
    <div className="reading">
      {data?.description ? (
        ReactHtmlParser(data?.description)
      ) : (
        <NoDataContent
          title="No Data"
          image={require("../../../images/emptyfile.png").default}
          description={"There is no data at the moment"}
        />
      )}
    </div>
  );
};
