import Modal from "react-awesome-modal";
import "./lessonTab.scss";

export const DeleteConfirmationModal = ({ visible, setVisible, onDelete }) => {
  return (
    <div className="delete-confirmation-modal">
      <Modal
        visible={visible}
        width="350"
        height="177"
        effect="fadeInUp"
        onClickAway={() => {
          setVisible?.("");
        }}
      >
        <div className="delete-modal">
          <h4 className="title">Delete lesson</h4>
          <p className="content">
            Are you sure you want to delete this lesson?
          </p>
          <div className="btn-block">
            <button
              className="secondary-btn"
              onClick={() => {
                setVisible?.("");
              }}
            >
              Cancel
            </button>
            <button
              className="delete-btn"
              onClick={() => {
                onDelete?.();
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
