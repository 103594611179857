import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { domain, headers } from "../../url";
import {
  filterStudentByGrade,
  getAllSubjectForExam,
  getAllSubjects,
  getAssignmentDetails,
  getAssignmentList,
  getAttendanceReport,
  getChildData,
  getECAGradingList,
  getECAHeader,
  getExamDataList,
  getExamSubject,
  getExamlist,
  getGradeApi,
  getGradeApiForExam,
  getHodSubjectList,
  getLibraryManagement,
  getProxyClass,
  getRouteViewList,
  getSelectedExamSubjectList,
  getStudentAttendanceLog,
  getStudentListForRemark,
  getStudentAttendance,
  getStudentRecords,
  getStudentSpecificRecords,
  getTeacherDetails,
  getsubjectApi,
} from "../Api/GetApi";

export const usePreferenceStore = create((set) => ({
  data: [],
  loading: false,
  preferenceData: async () => {
    set({ loading: true });
    try {
      const response = await fetch(
        `${domain}/api/assignment_preferences`,
        headers
      );
      const data = await response.json();
      set({ data, loading: false });
    } catch (error) {
      console.error("Error fetching data:", error);
      set({ loading: false });
    }
  },
}));

export const useAssignmentListStore = create((set) => ({
  data: [],
  loading: false,
  error: "",
  assignmentList: async (gradeToken, subjectToken, dayToken) => {
    set({ loading: true });
    let isAllSame = false;
    if (typeof gradeToken === "string") {
      const details = gradeToken
        ?.split(",")
        .map((item) => parseInt(item.trim(), 10));
      isAllSame = details?.every((value, index, array) => value === array[0]);
    } else {
      const data = gradeToken?.map((item) => {
        if (typeof item === "string") {
          return parseInt(item.trim(), 10);
        } else {
          // Handle non-string values (e.g., null, undefined, non-string objects)
          return item; // or any other default value or handling you prefer
        }
      });
      isAllSame = data.every((value, index, array) => value === array[0]);
    }
    if (isAllSame && subjectToken && dayToken) {
      try {
        const response = await getAssignmentList({
          gradeToken,
          subjectToken,
          dayToken,
        });
        const data = response.data;

        set({ data: data, loading: false });
      } catch (error) {
        set({ loading: false, error: "Failed to load assignments", data: [] });
      }
    } else {
      set({ loading: false, error: "Failed to load assignments", data: [] });
    }
  },
}));
export const useTeacherDetailsStore = create((set) => ({
  data: [],
  loading: true,
  teacherDetails: async (token) => {
    set({ loading: true });
    try {
      const response = await getTeacherDetails(token);
      const data = response.data;
      set({ data: data?.profile, loading: false });
    } catch (error) {
      set({ loading: true });
      console.error("Error fetching teacher details:", error);
    } finally {
      set({ loading: false });
    }
  },
}));
export const useAttendanceData = create((set) => ({
  data: [],
  loading: false,
  error: "",
  late: {},

  attendanceList: async (month) => {
    set({ loading: true });
    try {
      const response = await getAttendanceReport(month);

      if (response.data.status === 200) {
        const data = response.data;
        set({
          data: data.message,
          loading: false,
          late: data,
        });
      } else {
        set({ data: [], loading: false });
      }
    } catch (error) {
      set({
        loading: false,
        error: "Failed to load Attendance list",
      });
    }
  },

  StudentAttendanceList: async (id, month) => {
    set({ loading: true });
    try {
      const response = await getStudentAttendanceLog(id, month);

      if (response.data.status === 200) {
        const data = response.data;
        set({
          data: data.message,
          loading: false,
          late: data,
        });
      } else {
        set({ data: [], loading: false });
      }
    } catch (error) {
      set({
        loading: false,
        error: "Failed to load Attendance list",
      });
    }
  },
}));

export const usegradeList = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchGradeList: async () => {
    set({ loading: true });

    try {
      const response = await getGradeApi();
      const data = response.data.grade;

      set({ data: data, loading: false });
    } catch (error) {
      set({ loading: false, error: "Failed to load grade list" });
    }
  },
}));

export const useGradeApiForExam = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchGradeApiForExam: async () => {
    set({ loading: true });

    try {
      const response = await getGradeApiForExam();
      const data = response.data.grade;
      set({ data: data, loading: false });
    } catch (error) {
      set({ loading: false, error: "Failed to load grade list" });
    }
  },
}));
export const useExamSubject = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchExamSubject: async (id) => {
    if (id) {
      set({ laoding: true });
      try {
        const response = await getExamSubject(id);
        const data = response.data;

        set({ data: data, loading: false });
      } catch (err) {
        set({ loading: false, error: "Failed to fetch" });
      }
    }
  },
}));

export const useAllSubjects = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchAllSubject: async (id) => {
    if (id) {
      set({ loading: true });

      try {
        const response = await getAllSubjects(id);
        const data = response.data;

        set({ data: data, loading: false });
      } catch (err) {
        set({ loading: false, error: "Failed to fetch " });
      }
    }
  },
}));

export const useExamSubjectList = create((set) => ({
  data: [],
  loading: false,
  error: "",
  fetchExamSubjectList: async (teacherId) => {
    set({ loading: true });
    try {
      const response = await getSelectedExamSubjectList(teacherId);
      const data = response.data;
      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: "failed to fetch " });
    }
  },
}));

export const useECAHeader = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchECAHeader: async (grade) => {
    set({ loading: true });

    try {
      const response = await getECAHeader(grade);
      const data = response.data;
      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: "failed to fetch" });
    }
  },
}));

export const useECAGradingList = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchECAGradingList: async () => {
    set({ loadig: true });

    try {
      const response = await getECAGradingList();
      const data = response.data;
      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: "failed to fecth" });
    }
  },
}));

export const useExamDataList = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchExamDataList: async () => {
    set({ loading: true });
    try {
      const response = await getExamDataList();
      const data = response.data;
      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: err.message });
    } finally {
      set({ loading: false });
    }
  },
}));

export const useExamlist = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchExamlist: async () => {
    set({ loading: true });
    try {
      const response = await getExamlist();
      const data = response.data;
      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: err.message });
    }
  },
}));

export const useTabList = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchTablist: async (studentId) => {
    set({ loading: true });
    try {
      const response = await getStudentSpecificRecords(studentId);
      const data = response.data;
      if (response.status === 200) {
        set({ data: data });
      } else {
        set({ data: [] });
      }
    } catch (err) {
      set({ error: err.message });
    } finally {
      set({ loading: false });
    }
  },
}));

export const useChildData = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchChildData: async () => {
    set({ loading: true });
    try {
      const response = await getChildData();
      const data = response.data;

      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: err.message });
    }
  },
}));

export const useStudentRecords = create((set) => ({
  data: [],
  loading: false,
  error: "",

  fetchStudentRecords: async (id) => {
    set({ loading: true });

    try {
      const response = await getStudentRecords(id);
      const data = response.data?.html;

      set({ data: data, loading: false });
    } catch (err) {
      set({ loading: false, error: err.message });
    }
  },
}));

export const useStudentAttendance = create((set) => ({
  data: [],
  loading: false,
  error: "",
  fetchStudentAttendance: async (month, id) => {
    set({ loading: true });
    try {
      const response = await getStudentAttendance(id, month);
      const data = response.data;
      set({ data: data });
    } catch (err) {
      set({ err: err.message });
    } finally {
      set({ loading: false });
    }
  },
}));
export const useStudentRemark = create((set) => ({
  data: [],
  loading: false,

  fetchStudentRemark: async () => {
    set({ loading: true });

    try {
      const response = await getStudentListForRemark();
      const data = response.data;
      set({ data: data });
    } catch (err) {
      console.log("error", err);
      set({ data: [] });
    } finally {
      set({ loading: false });
    }
  },
}));

export const useStudentbyGrade = create(
  devtools((set) => ({
    data: [],
    loading: false,

    fetchStudentList: async (gradeToken, dateToken, remarkToken) => {
      set({ loading: true });
      try {
        const response = await filterStudentByGrade(
          gradeToken,
          dateToken,
          remarkToken
        );
        const data = response.data?.data;
        set({ data: data });
      } catch (err) {
        console.log("error", err);
        set({ data: [] });
      } finally {
        set({ loading: false });
      }
    },
  }))
);

export const useIframeTokenStore = create((set) => ({
  token: "",
  setToken: (val) => set({ token: val }),
}));

export const useSubjectForExam = create((set) => ({
  data: [],
  loading: false,

  fetchSubjectForExam: async () => {
    set({ loading: true });

    try {
      const response = await getAllSubjectForExam();
      const data = response?.data?.subject;
      set({ data: data });
    } catch (err) {
      console.log("error", err);
      set({ data: [] });
    } finally {
      set({ loading: false });
    }
  },
}));
export const useLibararyManagemnet = create((set) => ({
  data: [],
  loading: false,

  fetchLibararyManagemnet: async () => {
    set({ loading: true });

    try {
      const response = await getLibraryManagement();
      const data = response?.data;
      set({ data: data });
    } catch (err) {
      console.log("error", err);
      set({ data: [] });
    } finally {
      set({ loading: false });
    }
  },
}));
export const useSubjectList = create((set) => ({
  data: [],
  loading: false,

  fetchSubjectList: async () => {
    set({ loading: true });

    try {
      const response = await getsubjectApi();
      const data = response?.data?.subject;
      set({ data: data });
    } catch (err) {
      console.log("error", err);
      set({ data: [] });
    } finally {
      set({ loading: false });
    }
  },
}));

export const useHodSubjectList = create((set) => ({
  hodData: [],
  loading: false,

  fetchHodSubjectList: async () => {
    try {
      const response = await getHodSubjectList();
      const data = response.data?.data;
      set({ hodData: data });
    } catch (err) {
      console.log("error", err);
      set({ data: [] });
    }
  },
}));

export const useAssignmentDetails = create(
  devtools((set) => ({
    checkedData: [],
    UnCheckedData: [],
    loader: false,
    CWcheckedData: [],
    CWUnCheckedData: [],

    fetchAssignmentDetails: async ({
      gradeToken,
      assignmentToken,
      ishomework,
    }) => {
      if (gradeToken && assignmentToken) {
        set({ loader: true });

        try {
          const response = await getAssignmentDetails({
            gradeToken,
            assignmentToken,
            ishomework,
          });
          const data = response.data;
          const checked = data?.filter((item) => item?.checked === true);
          const unChecked = data?.filter((item) => item?.checked !== true);
          if (ishomework) {
            set({ checkedData: checked, UnCheckedData: unChecked });
          } else {
            set({ CWcheckedData: checked, CWUnCheckedData: unChecked });
          }
        } catch (err) {
          console.log("error", err);
          set({ data: [] });
        } finally {
          set({ loader: false });
        }
      }
    },
  }))
);

export const useProxySubject = create((set) => ({
  proxyClassList: [],
  loading: false,
  fetchProxySubjectList: async () => {
    try {
      const response = await getProxyClass();
      const data = response.data?.data;
      set({ proxyClassList: data });
    } catch (err) {
      console.error("error", err);
      set({ proxyClassList: [] });
    }
  },
}));

export const useviewRoute = create((set) => ({
  viewData: [],
  loading: false,

  fetchViewsRoute: async () => {
    try {
      const response = await getRouteViewList();
      const data = response.data;
      set({ viewData: data });
    } catch (err) {
      console.error("error", err);
      set({ viewData: [] });
    }
  },
}));
