import React, { Component } from "react";
import "./Goal.scss";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
// import TextField from "@material-ui/core/TextField";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";

class Goal extends Component {
  state = {
    tabNumber: [0],
    activeTab: "0",
  };

  addGoal = () => {
    var addTabNumber = this.state.tabNumber;
    var indexToAdd = Math.max(...addTabNumber) + 1;
    addTabNumber.push(indexToAdd);
    this.props.addPlanTab(this.props.type, indexToAdd);
    this.setState({
      tabNumber: addTabNumber,
      activeTab: indexToAdd,
    });
  };

  deleteTab = () => {
    if (this.state.tabNumber.length > 1) {
      var deleteTabNumber = this.state.tabNumber;
      this.props.removePlanTab(this.props.type, parseInt(this.state.activeTab));

      const index = deleteTabNumber.indexOf(parseInt(this.state.activeTab));
      if (index > -1) {
        deleteTabNumber.splice(index, 1);
      }
      var minElIndex = deleteTabNumber.indexOf(Math.min(...deleteTabNumber));
      this.setState({
        tabNumber: deleteTabNumber,
        activeTab:
          deleteTabNumber[index === minElIndex ? minElIndex : index - 1],
      });
    }
  };

  // onDetails = (e) => {
  //   ;
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   this.setState({
  //     [name]: value,
  //   });
  // };
  render() {
    const { type, allplans } = this.props;
    // ;
    return (
      <div className="goal-area">
        <div className="goal-section">
          <div className="tab-section">
            <Tabs
              activeKey={this.state.activeTab}
              onSelect={(k) => this.setState({ activeTab: k })}
              id="uncontrolled-tab-example "
              className="block-title"
            >
              {this.state.tabNumber.map((tab, index) => {
                return (
                  <Tab eventKey={tab} title={type.name + `-${tab}`}>
                    <div
                      className="tab-pane fade show active"
                      id="nav-home"
                      role="tabpanel"
                      aria-labelledby="nav-home-tab"
                    >
                      <div className="input-content-block">
                        <p className="content">Description</p>
                        <textarea
                          className="input-field"
                          name="description"
                          value={allplans[type.name + tab]?.["description"]}
                          onChange={(e) =>
                            this.props.onDetailsChange(
                              e.target.value,
                              type.name + tab,
                              "description",
                              type?.value
                            )
                          }
                        ></textarea>
                      </div>

                      <div className="input-content-block">
                        <p className="content">Due Date</p>
                        <NepaliDatePicker
                          inputClassName="input-field"
                          className=""
                          name="dueDate"
                          value={allplans[type.name + index]?.["due_date"]}
                          onChange={(value) => {
                            this.props.onDetailsChange(
                              value,
                              type.name + index,
                              "due_date",
                              type.value
                            );
                          }}
                          options={{ calenderLocale: "ne", valueLocale: "en" }}
                        />
                      </div>
                    </div>
                  </Tab>
                );
              })}
            </Tabs>
          </div>
          <div onClick={this.addGoal}>
            <i class="bx bxs-plus-circle main-icon"></i>
          </div>
        </div>

        <div className="start-block">
          {/* <button className="main-btn">Save</button> */}

          <button className="secondary-btn" onClick={this.deleteTab}>
            Remove
          </button>
        </div>
      </div>
    );
  }
}

export default Goal;
