import { FaLocationDot } from "react-icons/fa6";
import "./studentImage.scss";
import { useStudentId, useStudentStore } from "../../store/store";
import { useEffect, useState } from "react";
import NepaliDate from "nepali-date";
import { FaEdit } from "react-icons/fa";
import Select from "react-select";
import { useStudentAttendance } from "../store/useStore";
import { domain } from "../../url";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Modal from "react-awesome-modal";
import { HistoryPopup } from "../StudentProfile/Historypopup/HistoryPopup";

const StudentImage = ({ childId }) => {
  const { student, fetchStudentRemark } = useStudentStore();
  const { fetchStudentAttendance } = useStudentAttendance();
  // const [selectedMonth, setSelectedMonth] = useState("");
  const currentNepaliMonth = new NepaliDate().format("MMMM");
  const [visible, setVisible] = useState();
  const { studentId, setSelectedMonth, selectedMonth } = useStudentId();
  const id = studentId;

  useEffect(() => {
    const currentMonth = getCurrentMonthToken(currentNepaliMonth);
    if (id !== "") {
      setSelectedMonth(currentMonth);
      fetchStudentRemark(currentMonth, id);
      fetchStudentAttendance(currentMonth, id);
    }
  }, []);

  const getCurrentMonthToken = (monthName) => {
    const month = months.find((m) => m?.name === monthName);
    return month ? month.monthtoken : "";
  };
  const handleMonthChange = (selectedOption) => {
    const selectedMonthToken = selectedOption.value;
    setSelectedMonth(selectedMonthToken);
    fetchStudentRemark(selectedMonthToken, id);
    fetchStudentAttendance(selectedMonthToken, id);
  };

  const noProfile =
    "https://uxwing.com/wp-content/themes/uxwing/download/peoples-avatars/no-profile-picture-icon.png";

  const months = [
    { monthtoken: "01", name: "Baisakh" },
    { monthtoken: "02", name: "Jestha" },
    { monthtoken: "03", name: "Asar" },
    { monthtoken: "04", name: "Shrawan" },
    { monthtoken: "05", name: "Bhadra" },
    { monthtoken: "06", name: "Aswin" },
    { monthtoken: "07", name: "Kartik" },
    { monthtoken: "08", name: "Mangsir" },
    { monthtoken: "09", name: "Poush" },
    { monthtoken: "10", name: "Magh" },
    { monthtoken: "11", name: "Falgun" },
    { monthtoken: "12", name: "Chaitra" },
  ];
  const closeModal = () => {
    setVisible(false);
  };
  const history = useHistory();
  return (
    <div className="student-image-container">
      <div className="student-image">
        <img src={student ? student.student_image : noProfile} alt="image" />

        <div className="info">
          <div className="text">
            <div style={{ display: "flex", gap: "10px" }}>
              <h2>{student && student?.student_info?.studentName}</h2>
              {!childId && (
                <div
                  onClick={() => {
                    window.open(
                      `${domain}/student_tables/${id}/edit`,
                      "_blank"
                    );
                  }}
                >
                  <FaEdit style={{ cursor: "pointer" }} />
                </div>
              )}
            </div>
            <div className="classRoll">
              {student?.grade && (
                <p>
                  <span>Class: </span>
                  {student && student?.grade}
                </p>
              )}
              <div
                style={{
                  height: "4px",
                  width: "4px",
                  borderRadius: "50%",
                  backgroundColor: "#666666",
                }}
              ></div>
              {student?.student_info?.rollNo && (
                <p>
                  <span>Roll No.: </span>
                  {student && student?.student_info?.rollNo}
                </p>
              )}
            </div>
          </div>
          {student?.student_info?.permanentAddress && (
            <div className="location">
              <FaLocationDot
                style={{
                  color: "#666666",
                  width: "12px",
                  height: "12px",
                  marginTop: "2px",
                }}
              />
              <p>{student && student?.student_info?.permanentAddress}</p>
            </div>
          )}
        </div>
      </div>
      <div className="monthSelection">
        <div
          className="history-block"
          title="history"
          onClick={() => {
            setVisible(true);
          }}
        >
          <i class="bx bx-history"></i>
        </div>
        <Select
          options={months.map((month) => ({
            value: month.monthtoken,
            label: month.name,
          }))}
          onChange={handleMonthChange}
          value={{
            value: selectedMonth,
            label: months.find((month) => month.monthtoken === selectedMonth)
              ?.name,
          }}
        />
      </div>

      <Modal
        visible={visible}
        // height="80%"
        width={"90%"}
        effect="fadeInUp"
        className="popuo-modal"
        onClickAway={() => closeModal()}
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                <i class="bx bx-history"></i>
              </div>
              <p className="modal-title">History</p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i className="bx bx-x"></i>
            </div>
          </div>
          <div className="modal-body">
            <HistoryPopup />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StudentImage;
