import Axios from "axios";
import React, { useEffect, useState } from "react";
import Header from "../../Header/Header";
import { LayoutTbody } from "../../LayoutTbody/LayoutTbody";
import "../../MarksheetLayout.scss";
import "./Layout2.scss";
import { filterSubject } from "../../LayoutTbody/FilterSubject";

export const Layout2 = (props) => {
  const [ledgerData, setLedgerData] = useState();
  const [titleData, setTitleData] = useState();
  const [gradeList, setGradeList] = useState();

  const bgImage = `url(${props.info?.schoolData?.logo})`;
  const backgroundTitle = `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30"%3E%3Ctext x="0" y="20" font-size="14" fill="%235494c9"%3E${props.info?.schoolData?.school_name}%3C/text%3E%3C/svg%3E')`;
  useEffect(() => {
    setLedgerData(props?.resultData[0]);

    setGradeList(props?.resultData[0].markdata);
  }, [props]);
  return (
    <>
      <div className="layout2-section">
        {gradeList
          ?.filter((student) => student.sum > 0)
          .map((studentDetail, index) => {
            var filteredSubs = filterSubject(ledgerData, index);

            return (
              <div
                className="layout layout-with-letterhead"
                style={{ paddingTop: `${props.info?.header?.margin}cm` }}
              >
                <div className="layout-wrapper">
                  <div
                    className="background-image-marksheet"
                    style={{
                      "--bg-image": bgImage,
                      "--bg-title": backgroundTitle,
                    }}
                  >
                    <div className="inner-border-marksheet">
                      <Header
                        schoolData={props.info?.schoolData}
                        header={props.info?.header}
                      />
                      <div className="layout-header">
                        <h4 className="layout-title"> Grade Sheet </h4>
                        <h4 className="layout-sub-title">{props?.exam}</h4>
                      </div>

                      <div className="layout-info ">
                        <div className="layout-info-block">
                          <p className="width-text">The Grade Secured By</p>
                          <p className="l-dot">{studentDetail?.studentName}</p>
                        </div>
                        <div className="layout-info-block">
                          <div className="layout-info-block-content">
                            <p>Grade </p>
                            <p className="layout-info-dot">
                              {studentDetail.classAlpha}
                            </p>
                          </div>
                          <div className="layout-info-block-content">
                            <p>Section </p>
                            <p className="layout-info-dot">
                              {studentDetail.section == ""
                                ? "-"
                                : studentDetail.section}
                            </p>
                          </div>{" "}
                          <div className="layout-info-block-content">
                            <p>Roll No : </p>
                            <p className="layout-info-dot">
                              {studentDetail.rollNo}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="layout-table">
                        <div className="table-layout-one">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th rowSpan="2">S.N</th>
                                <th rowSpan="2" className="layout-subject">
                                  Subject Name
                                </th>
                                <th rowSpan="2">Credit Hour</th>
                                <th colspan="2" className="layout-mark">
                                  Obtained Mark
                                </th>
                                <th rowSpan="2">Final Grade</th>
                                <th rowSpan="2">Grade Point</th>
                                <th rowSpan="2">Remark</th>
                              </tr>
                              <tr>
                                <th>Theory</th>
                                <th>Practical</th>
                              </tr>
                            </thead>
                            <tbody>
                              <LayoutTbody
                                filterSubject={filteredSubs}
                                studentDetail={studentDetail}
                                showAdditionalExamSubject={true}
                              />
                            </tbody>
                          </table>
                        </div>
                        <div className="layout-lable-two">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col">Date of Issue</th>
                                <th scope="col">Attendance</th>
                                <th scope="col">Grade Point Average(GPA)</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>-</td>
                                <td>
                                  {studentDetail.attendance}
                                  {studentDetail.school_days
                                    ? "/" + studentDetail.school_days
                                    : ""}
                                </td>
                                <td>{studentDetail.gradepoint}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div
                          style={{
                            display: `${
                              window.location.host.includes("vidhyasagar")
                                ? "none"
                                : "block"
                            }`,
                          }}
                          className="layout-table-three"
                        >
                          <div className="layout-text-area-block">
                            <div className="textarea-field layout-text-area layout-margin-right">
                              <p style={{ margin: 0 }}>Remarks :</p>
                              <div>
                                {props.info.extraData?.remark && (
                                  <h4
                                    className="textarea-text"
                                    style={{ margin: 0 }}
                                    dangerouslySetInnerHTML={{
                                      __html: studentDetail?.remark,
                                    }}
                                  >
                                    {/* {studentDetail?.remark} */}
                                  </h4>
                                )}
                              </div>
                            </div>

                            <table className="table table-bordered  layout-one-two-table-three">
                              <thead>
                                <tr>
                                  <th scope="col" className="percentage-width">
                                    Equivalent Marks
                                  </th>
                                  <th scope="col">Grade</th>
                                  <th scope="col">Grade Point </th>
                                  <th scope="col" className="remark-width">
                                    Remark{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>90 to 100</td>
                                  <td>A+</td>
                                  <td>4.0</td>
                                  <td>Outstanding</td>
                                </tr>
                                <tr>
                                  <td>80 to 90</td>
                                  <td>A</td>
                                  <td>3.6</td>
                                  <td>Excellent</td>
                                </tr>
                                <tr>
                                  <td>70 to 80</td>
                                  <td>B+</td>
                                  <td>3.2</td>
                                  <td>Very good</td>
                                </tr>
                                <tr>
                                  <td>60 to 70</td>
                                  <td>B</td>
                                  <td>2.8</td>
                                  <td>Good</td>
                                </tr>
                                <tr>
                                  <td>50 to 60</td>
                                  <td>C+</td>
                                  <td>2.4</td>
                                  <td>Satisfactory</td>
                                </tr>
                                <tr>
                                  <td>40 to 50</td>
                                  <td>C</td>
                                  <td>2.0</td>
                                  <td>Acceptable</td>
                                </tr>

                                {props.info?.gradingSystem === "grading077" ? (
                                  <>
                                    <tr>
                                      <td>30 to 40</td>
                                      <td>D+</td>
                                      <td>1.6</td>
                                      <td>Partically Acceptable</td>
                                    </tr>
                                    <tr>
                                      <td>20 to 30</td>
                                      <td>D</td>
                                      <td>1.2</td>
                                      <td>Insufficient</td>
                                    </tr>
                                    <tr>
                                      <td>0 to 20</td>
                                      <td>E</td>
                                      <td>0.8</td>
                                      <td>Very Insufficient</td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>35 to 40</td>
                                      <td>D</td>
                                      <td>1.6</td>
                                      <td>Basic</td>
                                    </tr>
                                    <tr>
                                      <td>Below 35</td>
                                      <td>NG</td>
                                      <td>-</td>
                                      <td>Not Graded</td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="layout-conclusion">
                          Calculation of overall grade point is not based on
                          marks percentage. # Calculation of GPA is based on sum
                          of multiplication Grade Point and Credit Hour/Total
                          Credit hour.
                        </div>
                      </div>

                      <div className="layout-bottom">
                        <div className="layout-bottom-block">
                          <p className="layout-bottom-content">Class Teacher</p>
                          {props.info?.school_seal ? (
                            <p className="layout-bottom-content">School Seal</p>
                          ) : (
                            ""
                          )}

                          {props.info.extraData?.showEContent ? (
                            <div className="layout-signature">
                              {props.info.extraData?.eSign ? (
                                <img
                                  src={props.info.extraData?.eSign}
                                  alt="sign"
                                />
                              ) : (
                                ""
                              )}
                              <p className="layout-bottom-content">
                                {props.info.extraData?.eName.split("#")[1] ??
                                  "Exam Co-ordinator"}

                                <span className="signature-name">
                                  {" "}
                                  {props.info.extraData?.eName.split("#")[0]}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {props.info.extraData?.showPContent ? (
                            <div className="layout-signature">
                              {props.info.extraData?.pSign ? (
                                <img
                                  src={props.info.extraData?.pSign}
                                  alt="sign"
                                />
                              ) : (
                                ""
                              )}
                              <p className="layout-bottom-content">
                                {props.info.extraData?.pName.split("#")[1] ??
                                  "Principal"}
                                <span className="signature-name">
                                  {" "}
                                  {props.info.extraData?.pName.split("#")[0]}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
