import React from "react";
import "./preLoader.scss";
import { Preloader, Oval } from "react-preloader-icon";
class PreLoader extends React.Component {
  render() {
    const { height } = this.props;
    return (
      <div style={{height:height}} className="preloader">
        <Preloader
          use={Oval}
          size={40}
          strokeWidth={6}
          strokeColor="#29ABE2"
          duration={2000}
        />
      </div>
    );
  }
}

export default PreLoader;
