import Axios from "axios";
import { useEffect, useState } from "react";
import "./EventDetails.scss";
import noData from "../../images/no-data.svg";

export const EventDetails = (props) => {
  return (
    <div className="event-details-section">
      {props?.event?.length === 0 || props?.event === undefined ? (
        <div className="no-data">
          <div className="no-img">
            <img src={noData} alt="no-data" />
          </div>
          {/* <div className="gesture">Oops!!</div>*/}
          <div className="no-data-content">
            No {props?.birthdate ? "Birthdays" : "Events"} available at the
            moment
          </div>
        </div>
      ) : (
        <div className="scroll-box">
          {props.event?.map?.((item, index) => {
            return (
              <>
                <div className="icon-box" key={index}>
                  <div className="timeline-icon-box">
                    <div
                      className="timeline-icon"
                      style={{ backgroundColor: item?.eventHex }}
                    >
                      {props?.birthdate ? (
                        <i class="bx bx-cake"></i>
                      ) : (
                        <i class="bx bx-calendar"></i>
                      )}
                    </div>
                  </div>
                  <div className="timeline-border"></div>
                  <div className="blue-box">
                    <div
                      className="d-space-between"
                      style={{ alignItems: "baseline" }}
                    >
                      <div>
                        <p className="small-content bold label-color">
                          {item?.eventName}
                        </p>
                        <p className="event-description">{item?.description}</p>
                      </div>

                      <p className="extra-small-content miti">{item?.miti}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};
