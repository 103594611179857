import React, { useEffect, useState } from "react";
import "./StudentTab.scss";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noData from "../../images/no-data.svg";
import moment from "moment";
import { domain } from "../../url";
import { postApproveLeave } from "../Api/PostApi";

export const StudentTab = ({ data, setNote }) => {
  const [approveLoader, setApproveLoader] = useState(false);
  const [rejectLoader, setRejectLoader] = useState(false);
  const [statusId, setStatusId] = useState(false);

  const approveLeave = async (id, value) => {
    const data = {
      approved: value,
    };

    setStatusId(id);
    try {
      const response = await postApproveLeave(id, data);

      toast.success(response?.data?.message, {
        className:
          "Toastify__toast Toastify__toast-theme--light Toastify__toast--success",
      });

      Axios.get(`${domain}/dashboard_api/v1/leave_notes`).then((res) => {
        setNote(res.data);
      });
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setApproveLoader(false);
      setRejectLoader(false);
    }
  };
  return (
    <div className="student-tab">
      {data?.length === 0 || data === undefined ? (
        <div className="no-data">
          <div className="no-img">
            <img src={noData} alt="no-data" />
          </div>
          {/* <div className="gesture">Oops!!</div>*/}
          <div className="no-data-content">
            No Event available at the moment
          </div>
        </div>
      ) : (
        <>
          {data?.map?.((item, index) => {
            const isdisabled =
              (approveLoader || rejectLoader) && statusId === item.id;
            return (
              <div className="tab-cover-wrap d-flex btn-hover" key={index}>
                {item?.url !== null || "" ? (
                  <div className="profile">
                    <div className="big-circle">
                      <img className="image" src={item?.url} alt="file" />
                    </div>
                  </div>
                ) : item?.name !== null || "" ? (
                  <div className="profile">
                    <div className="big-circle">
                      <p className="circle-font">{item?.name?.charAt(0)}</p>
                    </div>
                  </div>
                ) : (
                  <div className="profile">
                    <div className="big-circle">
                      <p className="circle-font">U</p>
                    </div>
                  </div>
                )}

                {/* <div className="card-icon">
              <div className="d-center img-block">
                <img className="image" src={item?.url} alt="file" />
              </div>
            </div> */}
                <div className=" full-contain">
                  <div
                    className={` ${
                      item?.is_approved === true ? "fullwidth" : " "
                    }`}
                  >
                    <div>
                      <div className="approve-content">
                        <p className="description white-space">
                          {item?.cause}{" "}
                        </p>
                      </div>
                      <div class="base-section">
                        <p className="extra-small-content time">
                          Posted By:{" "}
                          {item?.name === null || "" ? (
                            <span className="blue-text">Unknown User</span>
                          ) : (
                            <span className="blue-text">{item?.name}</span>
                          )}
                        </p>
                        <p className="extra-small-content  time time-text mgl-5">
                          •{item?.miti}
                        </p>

                        <div className="time  mgl-5">
                          <p className="time-dot"> •</p>
                          {moment(item?.created_at).fromNow()}
                        </div>
                      </div>
                      <p className="leave-type-content">
                        Leave Type:
                        <span>
                          {item.leave_type ? `${item.leave_type} Leave` : "-"}
                        </span>
                      </p>

                      {item.is_approved === true ? (
                        <p className="approve-text ">
                          <i class="bx bx-check"></i>
                          Approved
                        </p>
                      ) : item.is_approved === false ? (
                        <p className="reject-text ">
                          <i class="bx bx-x"></i>
                          Rejected
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div>
                    {item.is_approved === null ? (
                      <div className="d-flex">
                        <button
                          className={`btn-content btn-approve ${
                            isdisabled && "approve-disabled"
                          }`}
                          disabled={isdisabled}
                          onClick={() => {
                            setApproveLoader(true);
                            approveLeave(item.id, true);
                          }}
                        >
                          {/* <img class="" src={approve} alt="img" /> */}
                          <i class="bx bx-check-circle"></i>
                          Approve
                          {approveLoader && statusId === item.id && (
                            <img
                              className="loader-img"
                              src={
                                require("../../images/Loading_icon.gif").default
                              }
                              alt="loader"
                            />
                          )}
                        </button>

                        <button
                          className={`reject-btn ${
                            isdisabled && "reject-disabled"
                          }`}
                          disabled={isdisabled}
                          onClick={() => {
                            setRejectLoader(true);
                            approveLeave(item.id, false);
                          }}
                        >
                          <i class="bx bx-x-circle"></i>
                          Reject
                          {rejectLoader && statusId === item.id && (
                            <>
                              <img
                                className="loader-img"
                                src={
                                  require("../../images/Loading_icon.gif")
                                    .default
                                }
                                alt="loader"
                              />
                            </>
                          )}
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <ToastContainer />
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};
