import "./Portfolio.scss";
import ChartBar from "../../components/ChartBar/ChartBar";
import { useStudentStore } from "../../store/store";
import { NoDataContent } from "../NoDataContent/NoDataContent";

const Portfolio = () => {
  const { studentRemark, remarkLoading } = useStudentStore();

  const studentRemarksArray = studentRemark?.data;


  return (
    <div className="portfolio-container">
      {remarkLoading ? (
        <p style={{ margin: "auto" }}>loading...</p>
      ) : (
        <div className="chart-cards">
          {studentRemarksArray?.length > 0 ? (
            <div className="row">
              {studentRemarksArray?.map((student, index) => {
                return (
                  <div className="col-md-6">
                    <ChartBar key={index} remarks={student} />
                  </div>
                );
              })}
            </div>
          ) : (
            <NoDataContent
              title={`No Remarks yet`}
              description={
                "Remarks will appear here once teacher provides them"
              }
              image={require("../../images/noRemarks.png").default}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Portfolio;
