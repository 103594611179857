import React, { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import "./AttendanceSummary.scss";
import { getTeacherAttendancedata } from "../Api/GetApi";

export const AttendanceSummary = ({ date }) => {
  const [data, setData] = useState();
  useEffect(() => {
    getTeacherAttendancedata().then((res) => {
      setData(res.data);
    });
  }, []);

  const att = data?.teacher_attendance_data?.[date];
  console.log("att", att);
  return (
    <div className="attendance-summary-section">
      <div className="attendance-header">
        <div className="detail-flex">
          <h4 className="title-head-block">Total days</h4>
          <p className="mark-amount">{att?.totalworkingdays || "-"}</p>
        </div>
      </div>

      <div className="progress-bar-slider">
        <ProgressBar
          completed={att?.present_days}
          maxCompleted={att?.totalworkingdays}
          height="8px"
          className="percent"
          isLabelVisible={false}
          margin="10px 0"
          bgColor="#29ABE2"
        />
      </div>

      <div className="detail-flex">
        <div>
          <h4 className="title-head-block">Days Attended</h4>
          <p className="mark-amount">{att?.present_days}</p>
        </div>

        <div>
          <h4 className="title-head-block">Days Absent</h4>
          <p className="mark-amount" style={{ textAlign: "end" }}>
            {att?.absentday}
          </p>
        </div>
      </div>

      <div className="detail-flex">
        <div className="checkin-date">
          <h4 className="title-head-block">Yesterday</h4>
          <p>
            {data?.check_in_infos?.[0]?.entry_time &&
            !data?.check_in_infos?.[0]?.entry_time === "-" ? (
              <span>{data.check_in_infos?.[0]?.entry_time}</span>
            ) : (
              <span style={{ color: "#aa0a0a" }}> N/A </span>
            )}
            -
            {data?.check_in_infos?.[0]?.exit_time &&
            !data?.check_in_infos?.[0]?.exit_time === "-" ? (
              <span>{data.check_in_infos?.[0]?.exit_time}</span>
            ) : (
              <span style={{ color: "#aa0a0a" }}> N/A </span>
            )}
          </p>
        </div>

        <div className="checkin-date">
          <h4 className="title-head-block">Today</h4>
          <p>
            {data?.check_in_infos?.[0]?.entry_time &&
            !data?.check_in_infos?.[0]?.entry_time === "-" ? (
              <span>{data.check_in_infos?.[0]?.entry_time}</span>
            ) : (
              <span style={{ color: "#aa0a0a" }}> N/A</span>
            )}
            -
            {data?.check_in_infos?.[0]?.exit_time &&
            !data?.check_in_infos?.[0]?.exit_time === "-" ? (
              <span>{data.check_in_infos?.[1]?.exit_time}</span>
            ) : (
              <span style={{ color: "#aa0a0a" }}> N/A</span>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
