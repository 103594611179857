import React, { useState } from "react";
import "./HodTeachingPlanningPopupComment.scss";
import HodCommentCard from "../HodCommentCard/HodCommentCard";
import { postTeacherPlan } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useUnitApi } from "../../store/hodDashboardStore";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import { StudentRatingTable } from "../StudentRatingTable/StudentRatingTable";
import { StudentDetailsRatingPopup } from "../StudentDetailsRatingPopup/StudentDetailsRatingPopup";

const HodTeachingPlaningPopupComment = ({
  planId,
  selectedMonth,
  selectedSubject,
  selectedGrade,
  arrayIndex,
  filterData,
  active,
}) => {
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState("");

  const { UnitPlan, fetchHodUnitPlan } = useUnitApi();
  const { selectedLessonTab } = useTeacherDashboardStore();
  const postCmt = async () => {
    if (
      planId !== null &&
      planId !== undefined &&
      comment !== null &&
      comment !== ""
    ) {
      setLoader(true);

      const val = {
        teacher_plan_id: planId,
        comment: comment,
      };
      try {
        const postReq = await postTeacherPlan(val);
        if (postReq?.status === 200) {
          setComment("");
          if (selectedGrade !== "")
            fetchHodUnitPlan(selectedGrade, selectedSubject, selectedMonth);
          toast("Comment added successfully");
        } else {
          toast("Something Went Wrong");
          setComment("");
        }
      } catch (error) {
        toast("Something Went Wrong");
        console.log(error);
      } finally {
        setLoader(false);
      }
    } else {
      toast("Comment should not be null");
    }
  };
  if (
    selectedLessonTab?.approved_by &&
    selectedLessonTab?.approved_by?.length > 0
  ) {
    return <StudentDetailsRatingPopup />;
  } else
    return (
      <div className="hod_comment_container">
        <h3 className="title">Comments</h3>
        <p className="sub_title">
          Your comments and responses to clarification requests
        </p>
        <div className="comment_body_wrapper">
          {filterData?.[arrayIndex]?.key?.[active] &&
            arrayIndex !== null &&
            filterData?.[arrayIndex]?.key?.[active]?.comments?.map(
              (item, index) => <HodCommentCard key={index} data={item} />
            )}
        </div>
        <div className="comment_writing_section">
          <div className="text_area_wrapper">
            <textarea
              onChange={(e) => setComment(e.target.value)}
              value={comment}
              name=""
              id="text_area"
              rows={2}
              style={{ resize: "none" }}
              placeholder="Write your remarks here"
            ></textarea>
          </div>
          <div className="comment_btn_wrapper">
            <div
              onClick={() => postCmt()}
              className={`${loader ? "disabled-btn" : "comment-btn"}`}
            >
              {loader ? "Posting..." : "Post"}
            </div>
          </div>
        </div>
      </div>
    );
};

export default HodTeachingPlaningPopupComment;
