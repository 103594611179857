import { useState } from "react";
import { SubCard } from "./subCard";
import Collapse from "@mui/material/Collapse";

const teachingPlanList = [
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae deserunt numquam illum obcaecati natus Molestiae deserunt numquam illum obcaecati natus",
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae deserunt numquam illum obcaecati natus Molestiae deserunt numquam illum obcaecati natus",
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae deserunt numquam illum obcaecati natus Molestiae deserunt numquam illum obcaecati natus",
  "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Molestiae deserunt numquam illum obcaecati natus Molestiae deserunt numquam illum obcaecati natus",
];

export const CollapsibleCard = () => {
  const [show, setShow] = useState(false);
  const [showMoreText, setShowMoreText] = useState(false);
  const listLength = showMoreText ? teachingPlanList?.length : 2;
  return (
    <div className="collapsible-card">
      <div className="collapsible-card-header">
        <div className="card-title-block">
          <p className="content">Activity C: Create a Fall Leaf Journal</p>

          <div
            className={`icon ${show ? "rotate" : ""}`}
            onClick={() => setShow(!show)}
          >
            <i class="bx bx-chevron-down"></i>
          </div>
        </div>
        <div className="card-info">
          <p className="medium-content">Activity #</p>
          <p className="medium-content divider">
            <span> 1234</span>
          </p>
          <p className="medium-content">By</p>
          <div className="user-info">
            <img src={require("../../../../images/t1.jpg").default} alt="img" />
            <p className="medium-content">
              <span>Rumiko Shika</span>
            </p>
          </div>
          <p className="small-content">
            Class Teacher of <span> Five ‘A’</span>
          </p>
        </div>
      </div>

      <Collapse in={show}>
        <div className="collapsible-card-body">
          <div className="mid-section">
            <div>
              <p className="small-content">
                teaching plan debugging and testing
              </p>
              <ul>
                {teachingPlanList?.slice(0, listLength)?.map((item, index) => {
                  return (
                    <>
                      <li className="content" key={index}>
                        {item}
                      </li>
                    </>
                  );
                })}
              </ul>
              <p
                className="show-text"
                onClick={() => {
                  setShowMoreText(!showMoreText);
                }}
              >
                {!showMoreText ? "Show More" : "Show Less"}...
              </p>
            </div>
            <div className="image-list">
              <p className="small-content">Images</p>
              <div className="image-block">
                <img
                  src={require("../../../../images/doc.jpg").default}
                  alt="img"
                />
                <img
                  src={require("../../../../images/doc.jpg").default}
                  alt="img"
                />
              </div>
            </div>
          </div>
          <SubCard />
        </div>
      </Collapse>
    </div>
  );
};
