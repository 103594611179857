import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import "./subjectsList.scss";
import { SubjectDetails } from "./subjectDetails";

const accordionConfig = [
  "Biology",
  "Health Population and Education",
  "prBasic Mathematicseview",
  "Optional Mathematics",
  "Health ",
];

export const SubjectsList = () => {
  return (
    <div className="student-profile-subjects-list">
      <div className="accordion">
        {accordionConfig?.map((item, accIndex) => {
          return (
            <div className="accordion-list" key={accIndex}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<i class="bx bx-chevron-down"></i>}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h4 className="accordion-title">
                    Step {accIndex + 1} ({item})
                  </h4>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="accordion-body">
                    <SubjectDetails />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          );
        })}
      </div>
    </div>
  );
};
