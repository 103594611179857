import "./information.scss";
import { Information } from "./Information";
import {useStudentStore} from "../../store/store";

const InformationField = () => {
  const { student } = useStudentStore();
  const arr = Information;

  let studentInfo = null;
  if (student && student.student_info) {
    studentInfo = student.student_info;
  }

  return (
    <>
      <div className="information-container">
        <h4>Basic Information</h4>
        <div className="data">
          {arr.map((item, index) => (
            <div key={index} className="text">
              <p>{item.title}:</p>
              <span>{studentInfo && studentInfo[item.info]}</span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default InformationField;
