export const ActivityCard = ({ onViewDetails }) => {
  return (
    <div className="activity-card">
      <div className="activity-header">
        <p className="medium-content">
          <span>Activity A:</span> Dissect a Flower
        </p>
        <div className="right-block">
          <div className="rating">
            <i class="bx bxs-star"></i>
            <p className="medium-content">3.5</p>
          </div>
          <div className="more-icon">
            <i class="bx bx-dots-horizontal-rounded"></i>
          </div>
        </div>
      </div>
      <div className="activity-body">
        <div className="activity-date">
          <i class="bx bx-calendar-week"></i>
          <p className="small-content">2080-10-20</p>
        </div>
        <div
          className="view-details"
          onClick={() => {
            onViewDetails?.();
          }}
        >
          <p className="medium-content"> View Details</p>
          <i class="bx bx-chevron-right"></i>
        </div>
      </div>
    </div>
  );
};
