import React, { useState } from "react";
import "./Assesments.scss";
import Select from "react-select";
import { classDropdown, subjectDropdown } from "../../TeacherProfileMain/data";
import { NepDatePicker } from "neps-date-picker";
import { recentAssesment } from "../data";
import AssesmentCard from "./AssesmentCard/AssesmentCard";
import { MdKeyboardArrowRight } from "react-icons/md";
import AssesmentPopup from "./AssesmentPopup/AssesmentPopup";
import useFetchData from "../../../utils/useFetchData";
import { useTeacherProfile } from "../../../store/teacherProfileStore";
import { getTeacherPlanProfileRecentAssessment } from "../../Api/GetApi";
import { NoDataContent } from "../../NoDataContent/NoDataContent";

const Assesments = () => {
  const { selectedGrade, selectedSubject } = useTeacherProfile();

  const [selectedDateToken, setSelectedDateToken] = useState();
  const [isViewAll, setIsViewAll] = useState(false);

  const { data, loading, error } = useFetchData(
    getTeacherPlanProfileRecentAssessment,
    selectedGrade,
    selectedSubject,
    selectedDateToken
  );

  const modifiedRecentAssesement = isViewAll
    ? data?.recent_assessments
    : data?.recent_assessments?.slice(0, 4);
  return (
    <div className="assesments_container component_wrapper">
      <AssesmentPopup />
      <div className="top_section">
        <h2 className="heading">Recent Assessments</h2>
        <div className="date_section">
          <NepDatePicker onDateSelect={(val) => setSelectedDateToken(val)} />
        </div>
      </div>

      <div className="card_section">
        {data?.length === 0 || data?.recent_assessments?.length === 0 ? (
          <NoDataContent
            image={require("../../../images/emptyfile.png").default}
            title="No Data Found"
            description="make sure that date filter is selected"
          />
        ) : (
          modifiedRecentAssesement?.map((item, index) => {
            return <AssesmentCard cardData={item} key={index} />;
          })
        )}
      </div>

      <div onClick={() => setIsViewAll(!isViewAll)} className="view_section">
        <div
          className={`view_wrapper ${
            data?.length === 0 || data?.recent_assessments?.length === 0
              ? "hide"
              : ""
          }`}
        >
          <div className="view">{isViewAll ? "view less" : "view all"}</div>
          <MdKeyboardArrowRight color="#0A78B1" />
        </div>
      </div>
    </div>
  );
};

export default Assesments;
