import React from "react";
import "./chapterTab.scss";

export const ChapterTab = ({ label, onClick, isActive }) => {
  return (
    <button
      className={`chapter-button ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      {label}
    </button>
  );
};
