import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import CustomNodeElement from "../Custom Node/CustomNode";
import { getEmployeeData, getTeacherList } from "../Api/GetApi";
import "./EmployeeHierarchy.scss";
import Select from "react-select";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import PreLoader from "../preLoader/preLoader";

const EmployeeHierarchy = () => {
  const [data, setData] = useState("");
  const [teacherList, setTeacherList] = useState();
  const [selectedId, setSelectedId] = useState("");
  const [loader, setLoader] = useState(false);

  const [admin, setAdmin] = useState();
  useEffect(() => {
    const Apifetch = async () => {
      setLoader(true);
      const value = selectedId ? selectedId : "1";

      try {
        const res = await getEmployeeData(value);
        if (res?.data) {
          setData(res?.data);
        } else {
          setData([]);
        }
      } catch (err) {
        console.log("error", err);
        setData([]);
      } finally {
        setLoader(false);
      }
    };

    Apifetch();
  }, [selectedId]);

  useEffect(() => {
    getTeacherList().then((res) => {
      const resData = res?.data || [];
      const teacherData = resData?.map((item) => {
        return {
          label: item?.teacherName,
          value: item?.id,
        };
      });

      setTeacherList(teacherData);
    });
  }, []);

  const convertData = (data) => {
    const rootNode = {
      name: admin ? admin : "Administrator",
      children: data,
    };
    return rootNode;
  };

  return (
    <div className="hierarchy-tree-section">
      <div className="container">
        <div className="select-teacher-block">
          <label>Select Teacher</label>
          <Select
            options={teacherList}
            onChange={(e) => {
              setAdmin(e.label);
              setSelectedId(e?.value);
            }}
          />
        </div>
      </div>

      <div
        style={{
          width: "100%",
          height: "85vh",
          // background: " #f3f4f7",
          overflow: "none",
        }}
      >
        {loader ? (
          <PreLoader />
        ) : (
          <>
            {data?.length > 0 ? (
              <Tree
                data={convertData(data && data)}
                pathFunc="step"
                rootNodeClassName="root"
                branchNodeClassName="branch"
                leafNodeClassName="leaf"
                orientation="vertical"
                enableLegacyTransitions
                // translate={{ x: 700, y: 200 }}
                shouldCollapseNeighborNodes={true}
                // separation={{ siblings: 3 }}
                transitionDuration={800}
                dimensions={{ width: 1450, height: 500 }}
                nodeSize={{ x: 150, y: 100 }}
                renderCustomNodeElement={CustomNodeElement}
                pathClassFunc="path-class"
                scaleExtent={{ min: 0.6, max: 1.5 }}
                zoom={1.2}
                separation={{ siblings: 2, nonSiblings: 2 }} // Adjust separation values
                translate={{ x: 50, y: 50 }} // Adjust translation values
              />
            ) : (
              <NoDataContent
                title="No Data"
                image={require("../../images/emptyfile.png").default}
                description="Could find any data of selected Teacher"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default EmployeeHierarchy;
