import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { InputField } from "../../../components/ui/formikFields/inputField";
import { useTeachingPlanMainStore } from "../../teachingPlanMain/store/teachingPlanMainStore";
import { useTeachingPlanEvaluationFormikWrapper } from "../formikWrapper/formikWrapper";
import "./studentEvaluation.scss";
import { getStudentsFromGrade } from "../../../components/Api/GetApi";
import { NoDataContent } from "../../../components/NoDataContent/NoDataContent";
import { toast } from "react-toastify";

export const StudentEvaluation = () => {
  const {
    selectedEvaluationToken,
    gradeToken,
    subjectToken,
    setStudentsList,
    studentsList,
    isBulkUpdate,
    setSubmitStudentEvaluation,
    submitStudentEvaluation,
    studentEvaluationList,
  } = useTeachingPlanMainStore();
  const { isSubmitting, handleSubmit } =
    useTeachingPlanEvaluationFormikWrapper();

  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");

  useEffect(() => {
    const data = {
      gradeToken: gradeToken,
      subjectToken: subjectToken,
    };
    if (gradeToken && subjectToken) {
      getStudentsFromGrade(data).then((res) => {
        setStudentsList(res?.data);
      });
    }
  }, [gradeToken, subjectToken]);

  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const handleChange = (e, rowIndex, colIndex) => {
    e.preventDefault?.();
    const updatedItem = produce(
      submitStudentEvaluation[colIndex]?.data[rowIndex],
      (draft) => {
        draft[e.target.name] = Number(e.target.value);
      }
    );
    setSubmitStudentEvaluation?.(
      produce(submitStudentEvaluation, (draft) => {
        draft[colIndex].data[rowIndex] = updatedItem;
      })
    );
  };

  useEffect(() => {
    const sortedStudents = studentsList
      ?.sort((a, b) => a.rollNo - b.rollNo)
      .map((student) => ({
        student_token: student.id,
        mark: 0,
        studentName: student.studentName,
        rollNo: student.rollNo,
      }));

    if (selectedEvaluationToken?.length > 0) {
      const selectedEvaluationLength = selectedEvaluationToken?.length;
      const studentsDataArray =
        selectedEvaluationLength <= 1
          ? [
              {
                data: sortedStudents,
                evaluation_meta_token:
                  selectedEvaluationToken?.[0]?.evaluation_meta_token,
              },
            ]
          : Array(selectedEvaluationLength)
              .fill()
              .map((_, index) => ({
                data: sortedStudents,
                evaluation_meta_token:
                  selectedEvaluationToken[index]?.evaluation_meta_token,
              }));
      setSubmitStudentEvaluation(studentsDataArray);
    }
  }, [studentsList, selectedEvaluationToken, isBulkUpdate]);

  const updateMarks = () => {
    const updatedState = produce(submitStudentEvaluation, (draft) => {
      draft.forEach((submitStudent) => {
        const matchingStudent = studentEvaluationList?.find(
          (student) =>
            student.evaluation_meta_token ===
            submitStudent.evaluation_meta_token
        );
        if (matchingStudent) {
          matchingStudent.data.forEach((matchingStudentData) => {
            const studentToUpdate = submitStudent.data.find(
              (student) =>
                parseInt(student.student_token) ===
                parseInt(matchingStudentData.student_token)
            );
            if (studentToUpdate) {
              studentToUpdate.mark = matchingStudentData.value ?? 0;
            }
          });
        }
      });
    });
    setSubmitStudentEvaluation(updatedState);
  };

  useEffect(() => {
    if (
      submitStudentEvaluation?.length > 0 ||
      studentEvaluationList?.length > 0
    ) {
      updateMarks();
    }
  }, [studentEvaluationList?.[0]?.evaluation_meta_token]);

  return (
    <div className="student-evaluation">
      {submitStudentEvaluation?.[0]?.data.length > 0 ? (
        <div className="section-gap">
          <div className="student-title-section">
            <h4 className="title student-title">Student Evaluation</h4>
          </div>
          <div className="student-section">
            <table className="table mb-8">
              <thead className="thead-light">
                <tr>
                  <th width="10%">Roll</th>
                  <th width="50%">Student Name</th>
                  {selectedEvaluationToken?.map((item, index) => {
                    return (
                      <th width="25%" key={index}>
                        <div className="full-mark-section flex-dir">
                          <p>{item?.title} </p>
                          <p>
                            weightage:<span>{item?.weightage}</span>
                          </p>
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {submitStudentEvaluation?.[0]?.data.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td width="10%">{item?.rollNo}</td>
                    <td width="50%">{item?.studentName}</td>
                    {submitStudentEvaluation?.map((itm, colIndex) => {
                      return (
                        <td width="25%" key={colIndex}>
                          <div className="input-field-block">
                            <input
                              className="input-field"
                              type="number"
                              placeholder="0"
                              name={`mark`}
                              value={itm?.data?.[rowIndex]?.mark}
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) >
                                  selectedEvaluationToken?.[colIndex]?.weightage
                                ) {
                                  e.preventDefault();
                                  toast.error(`You can't enter value greater than ${selectedEvaluationToken?.[colIndex]?.weightage}`);
                                } else {
                                  handleChange(e, rowIndex, colIndex);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (!/^[0-9.]$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                // Clear the value when the input field is focused
                                if (e.target.value === "0") {
                                  e.target.value = "";
                                }
                              }}
                              onBlur={(e) => {
                                if (!e.target.value) {
                                  e.target.value = "0";
                                  handleChange(e, rowIndex, colIndex);
                                }
                              }}
                            />
                          </div>
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="btn-section btn-block">
              <button
                className="main-btn"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <>
                    {studentEvaluationList?.[0]?.evaluation_meta_token ? (
                      <>
                        <i class="bx bx-loader-circle bx-spin"></i> Updating ...
                      </>
                    ) : (
                      <>
                        <i class="bx bx-loader-circle bx-spin"></i> Submitting
                        ...
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {studentEvaluationList?.[0]?.evaluation_meta_token
                      ? "Update"
                      : "Submit"}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <NoDataContent
          image={require("../../../images/emptyfile.png").default}
          title="No Students Added"
          description={"No students have been added yet"}
        />
      )}
    </div>
  );
};
