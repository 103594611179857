import React from "react";
import "./RemarkPopup.scss";

import { RemarkPage } from "../RemarkPage/RemarkPage.jsx";

function Remark({ onClose,date }) {

  return (
    <div className="remark_Popup" >
    <RemarkPage date={date} isDashboard={true}/>
    </ div>
  );
}
export default Remark;
