import React from "react";
import { useState, useEffect } from "react";
import "./RemarksHod.scss";
import { NepDatePicker } from "neps-date-picker";

import { FaAngleRight } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import Remark from "../remarkPopup/RemarkPopup";

import { getGradeApi } from "../Api/GetApi";

import "nepali-datepicker-reactjs/dist/index.css";

import { NoDataContent } from "../NoDataContent/NoDataContent";
import Modal from "react-modal";
import NepaliDate from "nepali-date";
import { isBrowser } from "react-device-detect";
import { useHodRemarks } from "../../store/hodDashboardStore";
import PreLoader from "../preLoader/preLoader";
import { MdClear } from "react-icons/md";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import NividLoader from "../NividLoader/NividLoader";

function RemarksHod() {
  const { popupType, setPopupType } = useTeacherDashboardStore();
  const { HodRemarks, loading, fetchHodRemarks } = useHodRemarks();
  const [clearDate, setClearDate] = useState(false);
  const [date, setDate] = useState("");

  const [showAll, setShowAll] = useState(false);

  const [grade, setGrade] = useState([]);
  const [gradeToken, setGradeToken] = useState("");

  /* API */
  const [data, setData] = useState([]);
  const [isVisible, setIsVisible] = useState(false); //to hide the view all button when nodata is found

  const modifiedData = showAll ? data : data?.slice(0, 3);
  useEffect(() => {
    if (gradeToken !== undefined && date !== undefined) {
      (async () => {
        await fetchHodRemarks(gradeToken, date);
      })();
    }
  }, [gradeToken, date]);

  useEffect(() => {
    setData(HodRemarks);
  }, [HodRemarks]);

  useEffect(() => {
    getGradeApi().then((response) => setGrade(response?.data?.grade));
  }, []);

  const customStyles = {
    overFlow: "scroll",
    width: isBrowser ? "80%" : "85%",
    height: isBrowser ? "80%" : "90%",
    display: "grid",
    marginLeft: "auto",
    marginRight: "auto",
  };

  return (
    <div className="remarks_container">
      <Modal
        isOpen={popupType === "addRemarks"}
        // onAfterOpen={}
        onRequestClose={() => {
          setPopupType("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}
              <p className="modal-title">Remarks</p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setPopupType("");
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>
          <div>
            <Remark date={date} onClose={() => setPopupType("")} />
          </div>
        </div>
      </Modal>
      <div className="first-box ">
        <div className="top-layout">
          <div className="top-frame d-flex">
            <div className="heading-layout">
              <p className="heading">Recent Remarks</p>
            </div>
            <button className="button-frame bg-white d-flex">
              <FaPlus />
              <div className="button-layout">
                <p
                  className="button-text"
                  onClick={() => setPopupType("addRemarks")}
                >
                  Add Student Remark
                </p>
              </div>
            </button>
          </div>
          <div className="down-frame d-flex mt-2">
            <select
              style={{ width: "150px" }}
              className="input-field"
              onChange={(e) => {
                if (e.target.value === "") {
                  setGradeToken("");
                } else {
                  const findGrade = grade?.find(
                    (item) => item.classAlpha === e.target.value
                  );

                  setGradeToken(findGrade?.id);
                }
              }}
            >
              <option defaultValue="" className="select-f">
                Select Class
              </option>
              {grade?.map((item, index) => {
                return (
                  <option key={index} className="select-f">
                    {item.classAlpha}
                  </option>
                );
              })}
            </select>
            <div className="right-frame d-flex justify-content-end">
              <div className="date-frame-fr d-flex">
                {/* <NepaliDatePicker
                  value={date}
                  inputClassName="input-date"
                  // className="date-picker"
                  format="YYYY/MM/DD"
                  onChange={(value) => {
                    setDate(value);
                  }}
                  options={{ calenderLocale: "en", valueLocale: "en" }}
                />
                <img src={Calendar}></img> */}
                <div
                  title="Clear Date"
                  onClick={() => setClearDate(!clearDate)}
                  className="cross_btn"
                >
                  <MdClear size={12} />
                </div>
                <NepDatePicker
                  reset={clearDate}
                  onDateSelect={(val) => setDate(val)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="last-layout">
          {loading ? (
            <div>
              <NividLoader height={"400px"} />{" "}
            </div>
          ) : data?.length == 0 ? (
            <NoDataContent
              marginTop="100px"
              title="No Data Found"
              description="Data will be appeared when updated"
              image={require("../../images/emptyfile.png").default}
            />
          ) : (
            modifiedData?.map((s, index) => {
              return (
                <div key={index} className="first-frame mt-2">
                  <div className="second-fra">
                    <div className="third-frame">
                      <div className="first-frame-of-frame d-flex">
                        <div style={{ display: "flex", gap: "8px" }}>
                          <div className="group">
                            <div className="image-back">
                              <p className="r-text">
                                {s.data?.teacherName?.split("")[0] || "U"}
                              </p>
                            </div>
                          </div>
                          <div className="name-frame">
                            <div className="name-layout">
                              <p>{s.data.teacherName || "Teacher"}</p>
                            </div>
                            <div className="fourth-frame">
                              <div className="class-frame d-flex">
                                <span className="class-t">
                                  {`Class Teacher of `}
                                </span>
                                <span
                                  style={{ marginLeft: "2px" }}
                                  className="grade ms-1"
                                >
                                  {s?.data?.class_teacher_class}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="date-cal">
                          <div className="date-number">
                            {s.data.created_at.split("T")[0]}
                          </div>
                          {/* <div className='date-number'>{month}</div>
                                        <div className='date-number'>{day < 10 ? `0${day}` : day},</div>
                                        <div className='date-number'>{year}</div> */}
                          {/* <div className='faelli faellip mb-1'>
                                        <span><FaEllipsis/></span>
                                        </div> */}
                          {/* <img src={Dots}></img> */}
                          {/* <BsThreeDots /> */}
                        </div>
                      </div>
                      <div className="com-frame">
                        <span className="com-title">{s.data.remark_name}</span>
                        <p className="date-content">{s.data.remark}</p>
                      </div>
                    </div>
                    <div className="rectangle"></div>
                  </div>
                  <div className="last-frame-fr d-flex">
                    <div className="frame-first d-flex">
                      <div className="group-of">
                        <div className="back-gro">
                          <p className="g">
                            {s.data?.studentName?.split("")[0]}
                          </p>
                        </div>
                      </div>
                      <div className="mid-fra">
                        <span className="namm ms-1">
                          {s.data.studentName} •
                        </span>
                        <span key={index} className="namm ms-1">
                          {s.data.gradeName}
                        </span>
                        <span className="namm ms-1"> - Roll No.: </span>
                        <span className="namm ms-1">{s.data.rollNo}</span>
                      </div>
                    </div>
                    {s.data.value !== null && (
                      <div className="mon-frame m-2 d-flex">
                        {/* <p className="mon-text">"monthly "  </p> */}
                        <div className="chip">
                          <p className="text-neuro text-uppercase">
                            {s.data.value}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>

        <p
          type="button"
          className={`ttt mt-3 ${data?.length > 0 ? "visible" : "hidden"}`}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {!showAll ? "View All" : "View Less"}
          <FaAngleRight className="ms-1" />
        </p>
      </div>
    </div>
  );
}
export default RemarksHod;
