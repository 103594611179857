export const EmptySubjectList = () => {
  return (
    <div className="empty-subject-list">
      <img src={require("../assets/empty_data.png").default} alt="img" />
      <div className="empty-subject-list-block">
        <h3 className="title">No subject list at the moment</h3>
        <p className="content">Please select grade to view subject list</p>
      </div>
    </div>
  );
};
