import React, { useEffect, useState } from "react";
import "./SuggestionBox.scss";
import { ReactMic } from "react-mic";
import { Link } from "react-router-dom";
import { Field, Form, Formik } from "formik";
import { postSuggestion } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useIframeTokenStore } from "../store/useStore";
import { getStudentList } from "../Api/GetApi";
import Select from "react-select";

const SuggestionBox = () => {
  const [voice, setVoice] = useState(false);
  const [audioLink, setAudioLink] = useState(null);
  const location = useLocation();
  const iframeToken = useIframeTokenStore((state) => state);
  const [teacher, setTeacher] = useState("false");
  const [studentList, setStudentList] = useState();
  const [selectedStudent, setSelectedStudent] = useState();
  const [loader, setLoader] = useState(false);
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const teacher = queryParams.get("is_teacher");
    setTeacher(teacher);
    iframeToken?.setToken(token);
  }, []);

  const startHandle = () => {
    setDisable(true);
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
      })
      .then((stream) => {
        setVoice(stream);
      })
      .catch((err) => {
        alert("Permission denied");
      });
  };
  const stopHandle = () => {
    setDisable(false);
    setVoice(false);
  };

  useEffect(() => {
    getStudentList().then((res) => {
      const data = res.data;
      const studentdata = data?.map((item) => {
        return {
          label: `${item?.studentName} ${
            item?.gradeName && `- ${item?.gradeName}`
          }`,
          value: item?.id,
        };
      });

      setStudentList(studentdata);
      setSelectedStudent(studentdata?.[0]);
    });
  }, []);

  return (
    <div className="suggestion_container">
      <Formik
        initialValues={{
          suggestion: "",
          audio_link: "",
        }}
        onSubmit={async (values, { setFieldValue }) => {
          if (values.suggestion || values.audio_link) {
            const formData = new FormData();
            formData.append("suggestion", values.suggestion);
            formData.append("student_id", selectedStudent?.value);
            const audioBlob = await fetch(values.audio_link).then((res) =>
              res.blob()
            );
            formData.append("audio_file", audioBlob, "audio_file.mp3");
            setLoader(true);
            try {
              const res = await postSuggestion(formData);
              if (res.data.status === 1) {
                toast.success(res.data.message);
                setFieldValue("suggestion", "");
                setAudioLink("");
                setFieldValue("audio_link", "");
                setSelectedStudent(studentList?.[0]);
              } else {
                toast.error("Something went wrong");
              }
            } catch (error) {
              console.error("An error occurred:", error);
              toast.error("something went wrong");
            } finally {
              setLoader(false);
            }
          } else {
            toast("Please fill one of the field (audio/text) ");
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <div className="suggestion_content_wrapper">
              <div className="form-group">
                <h2 className="title-header">Suggestions</h2>
              </div>

              {teacher === "true" && (
                <div className="form-group">
                  <label> Select Student</label>
                  <Select
                    value={selectedStudent}
                    options={studentList}
                    onChange={(value) => {
                      setSelectedStudent(value);
                    }}
                  />
                </div>
              )}

              <div className="textarea_section form-group">
                <label>Description</label>
                <textarea
                  className="form-control"
                  value={values.suggestion}
                  onChange={(e) => {
                    setFieldValue("suggestion", e.target.value);
                  }}
                />
              </div>

              <div className="audio_section form-group">
                <label>Audio Suggestion</label>
                <ReactMic
                  className="react_mic"
                  record={voice}
                  onStop={(blob) => {
                    setAudioLink(blob.blobURL);
                    setFieldValue("audio_link", blob.blobURL);
                  }}
                />

                {audioLink ? (
                  <audio controls src={audioLink} className="audio_control" />
                ) : null}

                <div className="btn_wrapper">
                  {!voice ? (
                    <button
                      type="button"
                      onClick={startHandle}
                      className="record_btn"
                    >
                      Record
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={stopHandle}
                      className="stop_btn"
                    >
                      Stop
                    </button>
                  )}

                  {audioLink ? (
                    <button
                      type="button"
                      onClick={() => {
                        setVoice(false);
                        setAudioLink("");
                        setFieldValue("audio_link", "");
                      }}
                      className="clear_btn"
                    >
                      clear
                    </button>
                  ) : null}
                </div>

                <div className="suggestion-block-footer">
                  <button
                    className={`btn btn-info ml-auto ${
                      loader || disable ? "disable-cursor " : ""
                    }`}
                    disabled={loader || disable}
                  >
                    {loader ? "Submitting..." : "submit"}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SuggestionBox;
