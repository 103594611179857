import React, { useEffect, useState } from "react";
import "./notice.scss";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { domain, mulitpartHeaders } from "../../url";
import Axios from "axios";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PopUp from "../popUp/popUp";
import { getHodMeetingGroup } from "../Api/GetApi";
import { toast } from "react-toastify";
import { useHodMeeting } from "../../store/hodDashboardStore";
import { date } from "yup/lib/locale";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

const Notice = (props) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [noticeDate, setNoticeDate] = useState("");
  const [images, setImages] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [snackStatus, setSnackStatus] = useState(true);
  const [meetingGroup, setMeetingGroup] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  const { fetchHodMeeting } = useHodMeeting();
  const openModal = () => {
    if (title === "") {
      handleSnackBarOpen("Please enter title");
    } else if (description === "") {
      handleSnackBarOpen("Please enter description");
    } else if (noticeDate === "") {
      handleSnackBarOpen("Please enter notice date");
    } else {
      setVisible(true);
    }
  };

  useEffect(() => {
    const apiResponse = async () => {
      try {
        const res = await getHodMeetingGroup();

        if (res?.data?.status === 1) {
          setMeetingGroup(res?.data?.data);
          setSelectedGroup(res.data?.data[0]?.group);
        } else {
          setMeetingGroup([]);
        }
      } catch {
        setMeetingGroup([]);
      }
    };

    apiResponse();
  }, []);
  const closeModal = () => {
    setVisible(false);
  };

  const handleSnackBarOpen = (message) => {
    setOpenSnackBar(true);
    setSnackMessage(message);
  };

  const addImages = (images) => {
    setImages(images);
  };

  const inputChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "title":
        setTitle(value);
        break;
      case "description":
        setDescription(value);
        break;
      case "noticeDate":
        setNoticeDate(value);
        break;
      default:
        break;
    }
  };

  const uploadMultipleFiles = (e) => {
    setImages(e.target.files);
  };

  const postNotice = async () => {
    const formData = new FormData();
    [...images].forEach((image, index) => {
      formData.append(`images[${index}]`, image);
    });
    formData.append("title", title);
    formData.append("description", description);
    formData.append("noticeDate", noticeDate);
    formData.append("group_name", selectedGroup);

    try {
      const url = props.isDashboard
        ? `${domain}/teacherApi/groupNotification`
        : `${domain}/teacher_api/notice`;

      const { data } = await axiosInstance.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (data.status === 11) {
        handleSnackBarOpen("Notice Submitted Successfully !");
        setVisible(false);
        setSnackStatus(true);
        if (props?.isDashboard) {
          props.closeModal();
          toast("Notice Submitted Successfully !");
          fetchHodMeeting("", props?.date);
        }
      } else if (data.status === 22) {
        handleSnackBarOpen("You are not allowed to post notice !");
        setVisible(false);
        setSnackStatus(false);
      } else {
        handleSnackBarOpen("Failed !");
        setVisible(false);
        setSnackStatus(false);
      }
    } catch (error) {
      console.error("Error submitting notice:", error);
      handleSnackBarOpen("Failed to submit notice !");
      setVisible(false);
      setSnackStatus(false);
    }
  };

  return (
    <div className="notice">
      {props?.header && (
        <div className="title-block header">
          <h2 className="title">Notice</h2>
        </div>
      )}
      <div className="section-gap">
        <div className={`${props.header && "container"}`}>
          <div className="input-content-block">
            <p className="content">Title</p>
            <input
              type="text"
              className="input-field"
              placeholder="Title"
              name="title"
              value={title}
              onChange={inputChange}
            />
          </div>
          <div className="input-content-block">
            <p className="content">Description</p>
            <textarea
              className="text-field"
              placeholder="Description"
              name="description"
              value={description}
              onChange={inputChange}
            />
          </div>
          <div className="input-content-block">
            <p className="content">Notice Date</p>
            <NepaliDatePicker
              inputClassName="input-field"
              className=""
              value={noticeDate}
              onChange={(value) => {
                setNoticeDate(value);
              }}
              options={{ calenderLocale: "ne", valueLocale: "en" }}
            />
          </div>
          {props?.isDashboard ? (
            <div className="input-content-block">
              <label>Select Meeting Group</label>
              <select
                className="form-control"
                value={selectedGroup}
                onChange={(e) => {
                  setSelectedGroup(e.target.value);
                }}
              >
                {meetingGroup?.map((item, index) => {
                  return (
                    <option key={index} value={item.group}>
                      {item.group}
                    </option>
                  );
                })}
              </select>
            </div>
          ) : (
            <div className="input-content-block">
              <p className="content">Images</p>
              <input
                className="file-upload"
                type="file"
                accept="image/*"
                onChange={uploadMultipleFiles}
                multiple
              />
            </div>
          )}
          <button className="main-btn" onClick={() => openModal()}>
            Submit
          </button>
          <PopUp
            visible={visible}
            closeModal={() => closeModal()}
            title="Notice"
            content="A notice will be submitted."
            post={postNotice}
          />
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackBar(false)}
          >
            {snackStatus ? (
              <Alert severity="success">{snackMessage}</Alert>
            ) : (
              <Alert severity="error">{snackMessage}</Alert>
            )}
          </Snackbar>
        </div>
      </div>
    </div>
  );
};

export default Notice;
