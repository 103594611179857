import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { toast } from "react-toastify";
import "./visitorManagement.scss";
import * as Yup from "yup";
import { teacherSchedule } from "../Api/PostApi";
import { getTeacherSchedule } from "../Api/GetApi";
import { MdDeleteOutline } from "react-icons/md";
const validationSchema = Yup.object().shape({
  dayData: Yup.array().of(
    Yup.object().shape({
      from: Yup.string().required("Start time is required"),
      to: Yup.string()
        .required("End time is required")
        .test(
          "is-later",
          "End time must be later than start time",
          function (value) {
            const { from } = this.parent;
            if (!from || !value) return true;
            return from < value;
          }
        ),
      slot: Yup.string().required("Slot time is required"),
    })
  ),
});

function VisitorManagement() {
  const [initialValues, setInitialValues] = useState({
    dayData: [
      { day: "Sunday", from: "", to: "", slot: "" },
      { day: "Monday", from: "", to: "", slot: "" },
      { day: "Tuesday", from: "", to: "", slot: "" },
      { day: "Wednesday", from: "", to: "", slot: "" },
      { day: "Thursday", from: "", to: "", slot: "" },
      { day: "Friday", from: "", to: "", slot: "" },
      { day: "Saturday", from: "", to: "", slot: "" },
    ],
  });
  const [loading, setLoading] = useState(true);
  const [deleteDay, setDeleteDay] = useState([]);
  useEffect(() => {
    getTeacherSchedule().then((res) => {
      if (res && res.data && res.data.status === 1) {
        const { from, to, slot } = res.data.data;

        const updatedInitialValues = {
          dayData: initialValues?.dayData?.map((item, index) => ({
            ...item,
            from: from[index] || "",
            to: to[index] || "",
            slot: (slot && slot[index]) || "",
          })),
        };

        setInitialValues(updatedInitialValues);
        setLoading(false);
      }
    });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }
  const handleDelete = (day) => {
    setDeleteDay([...deleteDay, day]);
  };

  const onSubmit = (values, { setSubmitting }) => {
    const isEmpty = values.dayData.some((item) => !item.from || !item.to);

    if (isEmpty) {
      toast.error("Please fill all the fields");
      setSubmitting(false);
      return;
    }

    const from = [];
    const to = [];
    const slot = [];

    values.dayData.forEach((value) => {
      from.push(value.from);
      to.push(value.to);
      slot.push(value.slot);
    });

    const actualData = { from, to, slot };

    teacherSchedule(actualData).then((res) => {
      toast.success("Submitted successfully!");

      setSubmitting(false);
    });
  };

  return (
    <div className="scheduling">
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}>
        {({ values, handleChange, resetForm }) => (
          <Form>
            <div className="visitor-container">
              <h1 style={{ textAlign: "start" }} className="title-head">
                Schedule
              </h1>
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <div className="table-data-section">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="20%">Day</th>
                          <th width="20%">From</th>
                          <th width="20%">To</th>
                          <th width="20%">Slot</th>
                          <th width="10%">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values.dayData
                          .filter((item) => !deleteDay.includes(item.day))
                          .map((item, index) => (
                            <tr key={item.day}>
                              <td>{item.day}</td>
                              <td>
                                <Field
                                  style={{
                                    width: "100%",
                                    padding: "4px",
                                  }}
                                  placeholder="Enter start time"
                                  type="time"
                                  name={`dayData[${index}].from`}
                                  value={item.from}
                                  onChange={handleChange}
                                />
                              </td>
                              <td>
                                <Field
                                  style={{
                                    width: "100%",
                                    padding: "4px",
                                  }}
                                  placeholder="Enter end time"
                                  type="time"
                                  name={`dayData[${index}].to`}
                                  value={item.to}
                                  onChange={handleChange}
                                />
                                <ErrorMessage
                                  name={`dayData[${index}].to`}
                                  component="div"
                                  className="error-message"
                                />
                              </td>
                              <td>
                                <Field
                                  as="select"
                                  style={{
                                    width: "100%",
                                    padding: "4px",
                                  }}
                                  name={`dayData[${index}].slot`}
                                  value={item.slot}
                                  onChange={handleChange}>
                                  <option value="">Select Slot</option>
                                  <option value="15">15</option>
                                  <option value="30">30</option>
                                  <option value="45">45</option>
                                  <option value="60">60</option>
                                </Field>
                                <ErrorMessage
                                  name={`dayData[${index}].slot`}
                                  component="div"
                                  className="error-message"
                                />
                              </td>
                              <td>
                                <MdDeleteOutline
                                  onClick={() => handleDelete(item.day)}
                                  className="delete_day"
                                />
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <hr />
                  <button className="primary-btn" type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default VisitorManagement;
