import React from "react";
import "./ChapterCard.scss";

const ChapterCard = ({ logo, title, active }) => {
  return (
    <div className={`chapter-card ${active ? "active" : ""}`}>
      <div className="chapter-main">
        <div className="chapter-logo ">
          <p className=" logo">{logo}</p>
        </div>
        <div className="content">{title}</div>
      </div>
      <i class="bx bx-right-arrow-alt"></i>
    </div>
  );
};

export default ChapterCard;
