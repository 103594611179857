import { Tab } from "@mui/material";
import React, { useEffect, useState } from "react";

import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import { useTeachingPlanMainStore } from "../../pages/teachingPlanMain/store/teachingPlanMainStore";
import HodTeachingPlaningPopupComment from "../HodTeachingPlaningPopupComment/HodTeachingPlaningPopupComment";

export const HodAssessmentSideBar = ({
  filterData,
  arrayIndex,
  selectedGrade,
  selectedSubject,
  selectedMonth,
}) => {
  const [activeModalId, setActiveModalId] = useState();
  const { activeTab, activeTabName, setSelectedUnitData } =
    useTeachingPlanMainStore();
  const { setSelectedLessonTab, setViewedDetails, setActive, active } =
    useTeacherDashboardStore();
  function a11yProps(index) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    const firstTab = filterData?.[arrayIndex]?.key?.[active];
    setActiveModalId(firstTab?.id);

    const selectedLesson = firstTab?.details?.find((itm) => {
      return itm?.lesson_name === activeTabName;
    });
    setSelectedLessonTab(selectedLesson);
    setViewedDetails(firstTab);
  }, []);

  return (
    <div className="hod-assessment-sidebar-section " style={{ height: "100%" }}>
      <TabContext value={active}>
        <TabList
          aria-label="lab API tabs example"
          variant="scrollable"
          scrollButtons="on"
          // style={{ borderBottom: " 1px solid #eeeeee" }}
        >
          {filterData?.[arrayIndex]?.key?.map((item, index) => {
            return (
              <Tab
                className="tab-label-header"
                onClick={() => {
                  setActive(index);
                  setActiveModalId(item?.id);
                  setSelectedUnitData(item);
                  const selectedLesson = item?.details?.find((itm) => {
                    return itm?.lesson_name === activeTabName;
                  });
                  setSelectedLessonTab(selectedLesson);

                  setViewedDetails(item);
                }}
                label={<div>{item.class_name}</div>}
                value={index}
                key={index}
                {...a11yProps(index)}
              />
            );
          })}
        </TabList>
        <TabPanel
          value={active}
          key={active}
          height="100%"
          style={{ height: "100%" }}
        >
          <HodTeachingPlaningPopupComment
            selectedGrade={selectedGrade}
            selectedSubject={selectedSubject}
            selectedMonth={selectedMonth}
            planId={activeModalId}
            arrayIndex={arrayIndex}
            filterData={filterData}
            active={active}
          />
        </TabPanel>
      </TabContext>
    </div>
  );
};
