import React from "react";
import "./Parentfield.scss";
import { IoAdd } from "react-icons/io5";
import { MdPersonAddAlt1 } from "react-icons/md";
import Button from "../Button/Button";
import Table from "../Reusable Table/Table";
import { changeReload, useStudentStore } from "../../store/store";
import Modal from "react-awesome-modal";
import { useState } from "react";
import { toast } from "react-toastify";
import { AddParent } from "./AddParent";
import { RegisterParent } from "./RegisterParent";
import { Preloader } from "react-preloader-icon";
import PreLoader from "../preLoader/preLoader";
import NividLoader from "../NividLoader/NividLoader";

const ParentField = ({ childId }) => {
  const { student, isLoading, fetchStudentData } = useStudentStore();
  const [visible, setVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const { shouldReload } = changeReload();
  const closeModal = () => {
    setVisible(false);
  };

  const openModal = () => {
    setVisible(true);
  };

  const headers = ["Parent Name", "Parent Number", "Parent PIN"];
  const rows =
    student && student.parent_detail
      ? student.parent_detail.map((parent) => [
          parent.parentName ? parent.parentName : "-",
          parent.number ? parent.number : "-",
          parent.pin,
          parent.id,
        ])
      : [];

  const closeParentModal = () => {
    setModal(false);
  };
  if (!shouldReload && isLoading) {
    return <NividLoader height="200px" />;
  }
  return (
    <div className="parent-container">
      <Modal
        visible={visible}
        width="50%"
        effect="fadeInUp"
        onClickAway={() => closeModal()}
      >
        <RegisterParent closeModal={closeModal} />
      </Modal>

      <Modal
        visible={modal}
        effect="fadeInUp"
        className="popup-modal"
        onClickAway={() => closeParentModal()}
      >
        <AddParent closeParentModal={closeParentModal} />
      </Modal>
      <div className="upperFlex">
        <h4>Parents</h4>
        {!childId && (
          <div className="flexButton">
            <Button
              text="Add Parent"
              icon={IoAdd}
              className="outline"
              onClick={() => {
                setModal(true);
              }}
            />{" "}
            <Button
              text="Register Parent"
              icon={MdPersonAddAlt1}
              className="edit-btn"
              onClick={openModal}
            />
          </div>
        )}
      </div>
      <Table
        headers={headers}
        childId={childId}
        rows={rows}
        studentParent={student?.parent_detail}
        showDelete={true}
        makeGuardian={true}
        setModal={setModal}
      />

      {/* Modal */}
    </div>
  );
};

export default ParentField;
