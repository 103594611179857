import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getTeacherStudentAttendance } from "../Api/GetApi";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import "./index.scss";

export const TeacherStudentAttendance = () => {
  const [teacherStudentAttendance, setTeacherStudentAttendance] = useState();

  useEffect(() => {
    getTeacherStudentAttendance()
      .then((res) => {
        setTeacherStudentAttendance(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, []);

  return (
    <div className="student-attendance-block">
      <div className="container">
        {teacherStudentAttendance ? (
          <div className="table-section">
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th colSpan={2} className="text-center">Class: {teacherStudentAttendance?.grade}</th>
                </tr>
                <tr>
                  <th width="70%">Student Name</th>
                  <th width="30%">Status</th>
                </tr>
              </thead>
              <tbody>
                {teacherStudentAttendance?.details?.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td width="70%">{item?.student_name}</td>
                      <td width="30%">
                        {item?.present ? (
                          <div className="attendance-tag present">Present</div>
                        ) : (
                          <div className="attendance-tag absent">Absent</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : (
          <NoDataContent
            title=""
            image={require("../../images/emptyfile.png").default}
            description="No data found  at the moment"
          />
        )}
      </div>
    </div>
  );
};
