import React, { useEffect, useState } from "react";
import "../../MarksheetLayout.scss";
import { LayoutTbody } from "../../LayoutTbody/LayoutTbody";
import Header from "../../Header/Header";
import "./Layout3.scss";
import { filterSubject } from "../../LayoutTbody/FilterSubject";

export const Layout3 = (props) => {
  const [ledgerData, setLedgerData] = useState();
  const [titleData, setTitleData] = useState();
  const [gradeList, setGradeList] = useState();

  const bgImage = `url(${props.info?.schoolData?.logo})`;
  const backgroundTitle = `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30"%3E%3Ctext x="0" y="20" font-size="14" fill="%235494c9"%3E${props.info?.schoolData?.school_name}%3C/text%3E%3C/svg%3E')`;
  useEffect(() => {
    setLedgerData(props?.resultData[0]);

    setGradeList(props?.resultData[0].markdata);
  }, [props]);
  return (
    <>
      <div className="layout3-section">
        {gradeList
          ?.filter((student) => student.sum > 0)
          ?.map((studentDetails, index) => {
            var filteredSubs = filterSubject(ledgerData, index);
            return (
              <div
                className="layout layout-with-letterhead"
                style={{ paddingTop: `${props.info?.header.margin}cm` }}
              >
                <div className="layout-wrapper">
                  <div
                    className="background-image-marksheet"
                    style={{
                      "--bg-image": bgImage,
                      "--bg-title": backgroundTitle,
                    }}
                  >
                    <div
                      className="inner-border-marksheet"
                      style={{
                        borderColor: `${
                          window.location.host.includes("kavre")
                            ? "transparent"
                            : "-"
                        }`,
                      }}
                    >
                      <Header
                        schoolData={props.info?.schoolData}
                        header={props.info?.header}
                      />

                      <div className="layout-header">
                        <h4 className="layout-title"> Grade Sheet </h4>
                        <h4 className="layout-sub-title">
                          {props.exam.replace(/\(.*?\)/, "")}
                        </h4>
                      </div>
                      <div className="layout-info">
                        <p>Name of the student :{studentDetails.studentname}</p>
                        <div className="layout-info-block">
                          <p>
                            Grade : <span>{studentDetails.classAlpha}</span>
                          </p>
                          <p>
                            Section :{" "}
                            {studentDetails.section == ""
                              ? "-"
                              : studentDetails.section}
                          </p>
                          <p>Roll No : {studentDetails.rollNo}</p>
                        </div>
                      </div>
                      <div className="layout-table">
                        <div className="table-layout-one">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th rowSpan="2">S.N</th>
                                <th
                                  rowSpan="2"
                                  className="layout-subject"
                                  width="35%"
                                >
                                  Subject Name
                                </th>
                                <th rowSpan="2">Credit Hour</th>
                                <th colSpan="2" className="layout-mark">
                                  Obtained Mark
                                </th>
                                <th rowSpan="2">Final Grade</th>
                                <th rowSpan="2">Grade Point</th>
                                <th rowSpan="2">Remark</th>
                              </tr>
                              <tr>
                                <th>Theory</th>
                                <th>Practical</th>
                              </tr>
                            </thead>
                            <tbody>
                              <LayoutTbody
                                filterSubject={filteredSubs}
                                studentDetail={studentDetails}
                                showAdditionalExamSubject={false}
                              />
                              <tr>
                                <td colspan="6">Grade Point Average(GPA)</td>
                                <td colspan="2">{studentDetails.gradepoint}</td>
                              </tr>
                              <tr>
                                <td colspan="4">Remark</td>
                                <td
                                  colspan="4"
                                  dangerouslySetInnerHTML={{
                                    __html: studentDetails?.remark ?? "",
                                  }}
                                >
                                  {/* <b>{studentDetails?.remark}</b> */}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="layout-table-three">
                          <div className="extra-block">
                            <div className="extra-content">
                              <h4 className="table-content">
                                Extra Curricular Performance
                              </h4>
                            </div>
                            <div className="extra-content">
                              <h4 className="table-content">Attendance</h4>
                              <p className="attendance table-content">
                                {studentDetails.attendance}/
                                {studentDetails.school_days}
                              </p>
                            </div>
                          </div>
                          <div className="layout-text-area-block">
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th scope="col">S.N</th>
                                  <th scope="col" style={{ minWidth: "100px" }}>
                                    Topic
                                  </th>
                                  <th scope="col">Grade</th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentDetails.additional_subjects?.map(
                                  (additional_subject, add_sub_index) => {
                                    return (
                                      <tr key={add_sub_index}>
                                        <td>{add_sub_index + 1}</td>
                                        <td className="layout-subject-body">
                                          {additional_subject.subject}
                                        </td>

                                        <td>{additional_subject.grade}</td>
                                      </tr>
                                    );
                                  }
                                )}

                                {/* <tr>
                                <td>2</td>
                                <td className="layout-subject-body">
                                  Discipline
                                </td>
                                <td>
                                  {
                                    studentDetails.extra_curriculars?.[0]
                                      ?.discipline
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>3</td>
                                <td className="layout-subject-body">
                                  Participation in eca
                                </td>
                                <td>
                                  {
                                    studentDetails.extra_curriculars?.[0]
                                      ?.participation
                                  }
                                </td>
                              </tr>
                              <tr>
                                <td>4</td>
                                <td className="layout-subject-body">
                                  English Proficiency
                                </td>
                                <td>
                                  {
                                    studentDetails.extra_curriculars?.[0]
                                      ?.english_proficiency
                                  }
                                </td>
                              </tr> */}
                              </tbody>
                            </table>
                            <table className="table table-bordered  layout-margin-left layout-one-two-table-three">
                              <thead>
                                <tr>
                                  <th scope="col" className="percentage-width">
                                    Equivalent Marks
                                  </th>
                                  <th scope="col">Grade</th>
                                  <th scope="col">Grade Point </th>
                                  <th scope="col" className="remark-width">
                                    Remark{" "}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>90 to 100</td>
                                  <td>A+</td>
                                  <td>4.0</td>
                                  <td>Outstanding</td>
                                </tr>
                                <tr>
                                  <td>80 to 90</td>
                                  <td>A</td>
                                  <td>3.6</td>
                                  <td>Excellent</td>
                                </tr>
                                <tr>
                                  <td>70 to 80</td>
                                  <td>B+</td>
                                  <td>3.2</td>
                                  <td>Very good</td>
                                </tr>
                                <tr>
                                  <td>60 to 70</td>
                                  <td>B</td>
                                  <td>2.8</td>
                                  <td>Good</td>
                                </tr>
                                <tr>
                                  <td>50 to 60</td>
                                  <td>C+</td>
                                  <td>2.4</td>
                                  <td>Satisfactory</td>
                                </tr>
                                <tr>
                                  <td>40 to 50</td>
                                  <td>C</td>
                                  <td>2.0</td>
                                  <td>Acceptable</td>
                                </tr>

                                {props.info?.gradingSystem === "grading077" ? (
                                  <>
                                    <tr>
                                      <td>30 to 40</td>
                                      <td>D+</td>
                                      <td>1.6</td>
                                      <td>Partically Acceptable</td>
                                    </tr>
                                    <tr>
                                      <td>20 to 30</td>
                                      <td>D</td>
                                      <td>1.2</td>
                                      <td>Insufficient</td>
                                    </tr>
                                    <tr>
                                      <td>0 to 20</td>
                                      <td>E</td>
                                      <td>0.8</td>
                                      <td>Very Insufficient</td>
                                    </tr>
                                  </>
                                ) : (
                                  <>
                                    <tr>
                                      <td>35 to 40</td>
                                      <td>D</td>
                                      <td>1.6</td>
                                      <td>Basic</td>
                                    </tr>
                                    <tr>
                                      <td>Below 35</td>
                                      <td>NG</td>
                                      <td>-</td>
                                      <td>Not Graded</td>
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>

                      <div className="layout-bottom">
                        <div className="layout-bottom-block">
                          <p className="layout-bottom-content">Class Teacher</p>
                          {props.info.school_seal ? (
                            <p className="layout-bottom-content">School Seal</p>
                          ) : (
                            ""
                          )}

                          {props.info.extraData?.showEContent ? (
                            <div className="layout-signature">
                              {props.info.extraData?.eSign ? (
                                <img
                                  src={props.info?.extraData?.eSign}
                                  alt="sign"
                                />
                              ) : (
                                ""
                              )}
                              <p className="layout-bottom-content">
                                {props.info?.extraData?.eName.split("#")[1] ??
                                  "Exam Co-ordinator"}

                                <span className="signature-name">
                                  {" "}
                                  {props.info?.extraData?.eName.split("#")[0]}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          {props.info.extraData?.showPContent ? (
                            <div className="layout-signature">
                              {props.info.extraData?.pSign ? (
                                <img
                                  src={props.info.extraData?.pSign}
                                  alt="sign"
                                />
                              ) : (
                                ""
                              )}
                              <p className="layout-bottom-content">
                                {props.info.extraData?.pName.split("#")[1] ??
                                  "Principal"}
                                <span className="signature-name">
                                  {" "}
                                  {props.info.extraData?.pName.split("#")[0]}
                                </span>
                              </p>
                            </div>
                          ) : (
                            ""
                          )}

                          <p className="layout-date layout-bottom-content">
                            Date of issue : -
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};
