import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getStudentEvaluationData,
  getTeachingEvaluationList,
} from "../../components/Api/GetApi";
import { NoDataContent } from "../../components/NoDataContent/NoDataContent";
import PreLoader from "../../components/preLoader/preLoader";
import { useTeachingPlanMainStore } from "../teachingPlanMain/store/teachingPlanMainStore";
import { useTeachingPlanEvaluationFormikWrapper } from "./formikWrapper/formikWrapper";

export const PlanFilter = ({ isLoading }) => {
  let urlparam = new URLSearchParams(window.location.search);
  let viewPageFromUrl = urlparam.get("viewPage");

  const {
    grade,
    subjectList,
    unitList,
    selectedUnitData,
    selectedUnit,
    setSelectedUnit,
    gradeToken,
    setGradeToken,
    subjectToken,
    setSubjectToken,
    selectedLessonId,
    setSelectedLessonId,
    isLessonEvaluation,
    teachingEvaluationList,
    setTeachingEvaluationList,
    setSubmitEvaluationPlan,
    setSelectedUnitData,
    setUnitList,
    setViewPage,
    selectedEvaluationToken,
    setStudentEvaluationList,
    setSubmitStudentEvaluation,
    setStudentsList,
    setSelectedEvaluationToken,
  } = useTeachingPlanMainStore();
  const { setFieldValue, values } = useTeachingPlanEvaluationFormikWrapper();

  useEffect(() => {
    setViewPage(viewPageFromUrl);
  }, [viewPageFromUrl]);

 

  useEffect(() => {
    const fetchData = async () => {
      const studentsDataArray = [];
      for (const element of selectedEvaluationToken) {
        if (element?.weightage !== null) {
          try {
            const res = await getStudentEvaluationData(
              element?.evaluation_meta_token
            );
            const data = {
              data: res?.data.data,
              evaluation_meta_token: element?.evaluation_meta_token,
            };
            studentsDataArray.push(data);
          } catch (error) {
            console.error("Error fetching student evaluation data:", error);
          }
        }
      }

      if (selectedEvaluationToken?.length === studentsDataArray.length) {
        setStudentEvaluationList(studentsDataArray);
      }
    };

    fetchData();
  }, [selectedEvaluationToken]);

  useEffect(() => {
    const temp = teachingEvaluationList?.map((item) => ({
      unit_token: Number(values?.unit_token),
      grade_token: item?.grade_token,
      subject_token: item?.subject_token,
      lesson_token: values?.lesson_token,
      evaluation_token: item?.evaluation_token,
      weightage: Number(item?.weightage),
    }));

    setSubmitEvaluationPlan(temp);
  }, [teachingEvaluationList, values]);

  return (
    <div className="filter-section">
      <div className="grade-subject-section">
        <div className="input-field-block">
          <p className="content">Grade</p>
          <select
            name="gradeToken"
            onChange={(e) => {
              setGradeToken(e.target.value);
              setFieldValue("grade_token", e.target.value);
              setTeachingEvaluationList([]);
              setSelectedUnit();
              setSelectedUnitData([]);
              setUnitList([]);
              setSubmitStudentEvaluation([]);
              setStudentsList([]);
              setSelectedEvaluationToken([]);
              setViewPage("Evaluation List");
            }}
            value={gradeToken}
            className="input-field"
          >
            <option value="">Select Grade</option>
            {grade?.map((item, index) => {
              return (
                <option value={item?.id} key={index}>
                  {item?.class_name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="input-field-block">
          <p className="content">Subject</p>
          <select
            name="subjectToken"
            disabled={gradeToken === ""}
            className="input-field"
            onChange={(e) => {
              setSubjectToken(e.target.value);
              setFieldValue("subject_token", e.target.value);
              setTeachingEvaluationList([]);
              setSelectedUnit();
              setSelectedUnitData([]);
              setUnitList([]);
              setSubmitStudentEvaluation([]);
              setStudentsList([]);
              setSelectedEvaluationToken([]);
              setViewPage("Evaluation List");
            }}
            value={subjectToken}
          >
            <option value="">Select Subject</option>
            {subjectList?.map((item, index) => {
              return (
                <option value={item?.id} key={index}>
                  {item?.subjectName}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      {isLoading ? (
        <PreLoader />
      ) : (
        unitList?.length > 0 && (
          <>
            <div className="input-field-block">
              <p className="content">Unit</p>
              <select
                name="selectedUnit"
                disabled={gradeToken === "" || subjectToken === ""}
                className="input-field"
                onChange={(e) => {
                  setSelectedUnit(e.target.value);
                  setFieldValue("unit_token", e.target.value);
                  setSubmitStudentEvaluation([]);
                  setStudentsList([]);
                  setSelectedEvaluationToken([]);
                  setViewPage("Evaluation List");
                }}
                value={selectedUnit}
              >
                <option value="">Select Unit</option>
                {unitList?.map((item, index) => {
                  return (
                    <option value={item?.id} key={index}>
                      {item?.unit_name}
                    </option>
                  );
                })}
              </select>
            </div>
            {isLessonEvaluation && (
              <div className="input-field-block">
                <p className="content">Lesson Plan</p>
                <select
                  name="selectedLessonId"
                  disabled={selectedUnit === ""}
                  className="input-field"
                  onChange={(e) => {
                    setSelectedLessonId(e.target.value);
                    setFieldValue("lesson_token", e.target.value);
                  }}
                  value={selectedLessonId}
                >
                  <option value="">Select Lesson</option>
                  {selectedUnitData?.details?.map((item, index) => {
                    return (
                      <option value={item?.lesson_plan_id} key={index}>
                        {item?.lesson_name}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </>
        )
      )}
    </div>
  );
};
