import React, { useEffect, useState } from "react";
import { useStudentId } from "../../../store/store";
import { getHistory } from "../../Api/GetApi";

export const HistoryPopup = () => {
  const { studentId } = useStudentId();
  const [history, setHistory] = useState();
  useEffect(() => {
    if (studentId) {
      const data = {
        name: "student_tables",
        id: studentId,
      };
      getHistory(data).then((res) => {
        setHistory(res.data);
        console.log("res", res);
      });
    }
  }, []);
  return (
    <div
      className="history-popup"
      style={{ maxHeight: "80vh", overflowY: "auto" }}
    >
      <div className="history-table tableContainer">
        <table className="table  table-bordered ">
          <thead>
            <tr>
              <th>S.N</th>
              <th>User Name</th>
              <th>Action</th>
              <th>Heading</th>
              <th>Previous data</th>
              <th>Changed Data</th>
            </tr>
          </thead>
          {history?.length > 0 ? (
            <tbody>
              {history?.map((item, index) => {
                return item.audited_changes.map((itm, idx) => {
                  const title = itm.split(":");
                  const value = title[1]
                    .replace(/[\[\]]/g, "")
                    .split(",")
                    .map((name) => name.trim());

                  return (
                    <tr key={`${index}-${idx}`}>
                      {/* Only apply rowSpan to the first row of each item */}
                      {idx === 0 && (
                        <>
                          <td rowSpan={item.audited_changes.length}>
                            {index + 1}
                          </td>
                          <td
                            style={{ maxWidth: "15%" }}
                            rowSpan={item.audited_changes.length}
                          >
                            {item?.user_name}
                          </td>
                          <td
                            style={{ maxWidth: "15%" }}
                            rowSpan={item.audited_changes.length}
                          >
                            {item?.action}
                          </td>
                        </>
                      )}

                      <td
                        style={{
                          maxWidth: "300px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {title?.[0]}
                      </td>
                      <td
                        style={{
                          maxWidth: "300px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {value?.[0]}
                      </td>
                      <td
                        style={{
                          maxWidth: "300px",
                          wordWrap: "break-word",
                          whiteSpace: "normal",
                        }}
                      >
                        {value?.[1]}
                      </td>
                    </tr>
                  );
                });
              })}
            </tbody>
          ) : (
            <tbody>
              <td colSpan={6} style={{ textAlign: "center" }}>
                No data available
              </td>
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
