import Chart from "react-apexcharts";
import "./chart.scss";
import ChartCard from "../Chart Detail Card/ChartCard";
import { useState } from "react";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { useStudentAttendance } from "../store/useStore";

const ChartBar = ({ remarks, attRemark }) => {
  const { loading } = useStudentAttendance();
  // For Attendance
  const attendanceDetails = attRemark?.data;
  const attendanceChartData = attendanceDetails?.attendance_summary;
  const attendanceLabels = attendanceChartData
    ?.flatMap((item) => Object?.keys(item))
    .map((label) => label.replace(/total_/i, "").trim())
    .map((label) => {
      if (label === "early_exit") {
        return "Early Exit";
      } else {
        return label.charAt(0).toUpperCase() + label.slice(1);
      }
    });

  let attendanceValues = attendanceChartData?.flatMap((item) =>
    Object?.values(item)
  );

  const attendanceRemarks = attendanceDetails?.attendance_details;

  const isAttendanceEmpty = attendanceValues?.every(
    (value) => value === null || value === 0
  );

  if (isAttendanceEmpty) {
    attendanceValues = [];
  }

  const isPresent = attendanceDetails?.today_details?.[0]?.today_present;
  const todayAttendanceStatus = attendanceDetails?.today_details?.[0]?.status;
  const totalAttendance =
    attendanceChartData?.[0]?.total_absent +
    attendanceChartData?.[0]?.total_present;

  // For Other Remarks
  const remarksLabel = remarks?.remarks;
  const [selectedCard, setSelectedCard] = useState(null);
  //filter the key
  const labels = remarksLabel?.map((item) =>
    Object.keys(item).filter(
      (key) => key !== "color" && !key.endsWith("_count")
    )
  );

  const flattenedLabels = labels?.flat();

  const values = remarksLabel?.map((item) => Object?.values(item)?.[2]);
  let totalRemarkCount = 0;

  remarksLabel?.forEach((remark) => {
    Object?.keys(remark)?.forEach((key) => {
      if (key.endsWith("_count")) {
        totalRemarkCount += remark[key];
      }
    });
  });

  const remarkColor = (remark) => {
    switch (remark) {
      case "worst":
      case "absent":
        return "#ED5565";
      case "good":
        return "#C77EB7";
      case "outstanding":
      case "present":
      case "excellent":
        return "#3DD598";
      case "average":
      case "late":
        return "#f2c463";
      case "bad":
      case "leave":
        return "#5699D2";
      default:
        const randomColor = () => Math.floor(Math.random() * 256);
        const randomR = randomColor();
        const randomG = randomColor();
        const randomB = randomColor();
        return `rgb(${randomR}, ${randomG}, ${randomB})`;
    }
  };

  const colors = flattenedLabels?.map((label) =>
    remarkColor(label.toLowerCase())
  );
  const labelColorPairs = flattenedLabels?.map((label, index) => ({
    label: label,
    color: colors[index],
  }));
  const attendanceColor = attendanceLabels?.map((label) =>
    remarkColor(label.toLowerCase())
  );

  let chartLabel = "";
  if (remarks) {
    chartLabel = "Remarks";
  }

  if (attRemark) {
    chartLabel = "Attendance";
  }

  const options = {
    labels: flattenedLabels || attendanceLabels,
    colors: colors || attendanceColor,
    chart: {
      height: 100,
      width: 100,
    },

    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: "85%",
          labels: {
            show: true,
            total: {
              show: true,
              label: chartLabel,
              fontSize: "12px",
              color: "#333333",
              formatter: function (values) {
                if (chartLabel === "Attendance") {
                  return totalAttendance;
                }
                if (chartLabel === "Remarks") {
                  return totalRemarkCount;
                }
              },
            },
            value: {
              fontSize: "24px",
            },
            style: {
              fontFamily: "Noto Sans",
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 453,
        options: {
          chart: {
            height: 200,
            width: 200,
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
      style: {
        fontFamily: "Noto Sans",
      },
    },
    legend: {
      position: "bottom",
    },
  };

  const series = values || attendanceValues;

  return (
    <div className="chart-container">
      {remarks?.remarks?.length > 0 || attendanceValues?.length > 0 ? (
        <>
          <div className="chartbox">
            <div className="chartHead">
              <h3>{remarks?.remark_name || "Attendance"}</h3>
              {attRemark &&
                attendanceDetails?.today_details !== null &&
                (isPresent ? (
                  <span className={`${todayAttendanceStatus}`}>Already in</span>
                ) : (
                  <span>NOT in yet</span>
                ))}
            </div>
            <div className="bar">
              <Chart
                options={options}
                series={series}
                type="donut"
                className="chart"
                width="200px"
                height="200px"
              />
            </div>
          </div>
          <div className="chart-detail">
            {remarks?.remarks?.map((remark, index) => {
              return (
                <ChartCard
                  remark={remark}
                  remarkColor={remarkColor}
                  key={index}
                  labelColorPairs={labelColorPairs}
                  selectedCard={selectedCard}
                  setSelectedCard={setSelectedCard}
                />
              );
            })}
            {attRemark && (
              <ChartCard
                attRemark={attendanceRemarks}
                remarkColor={remarkColor}
              />
            )}
          </div>
        </>
      ) : attRemark && loading ? (
        <p style={{ margin: "auto" }}>Loading...</p>
      ) : (
        <div style={{ width: "100%" }}>
          <h3>{remarks?.remark_name}</h3>
          <NoDataContent
            title={`No ${remarks ? "Remarks" : "Attendance"} yet`}
            description={`${
              remarks ? "Remarks" : "Attendance"
            } will appear here once teacher provides them`}
            image={require("../../images/noRemarks.png").default}
          />
        </div>
      )}
    </div>
  );
};

export default ChartBar;
