import React, { useEffect, useState } from "react";
import "./LeaveNote.scss";
import Axios from "axios";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { StudentTab } from "../StudentTab/StudentTab";
import { domain } from "../../url";
import NividLoader from "../NividLoader/NividLoader";

export const LeaveNote = () => {
  const [note, setNote] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await Axios.get(`${domain}/dashboard_api/v1/leave_notes`);

        if (res.status === 200) {
          setNote(res.data);
        }
      } catch (e) {
        console.log(e, "something went wrong");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <div className="leave-note ">
      <div className="custom-card">
        <div className="top-section">
          <p className="medium-title">Leave Note</p>
          <p
            className=" view-items"
            onClick={() => {
              window.open(`${domain}/leave_note_report`);
            }}
          >
            View All
          </p>
        </div>

        <div>
          {isLoading ? (
            <NividLoader height={"350px"} />
          ) : (
            <Tabs onSelect={(index, label) => console.log(label + " selected")}>
              <Tab label="Student">
                <div className="tab-section scroller">
                  <StudentTab data={note?.student} setNote={setNote} />
                </div>
              </Tab>
              <Tab label="Teacher">
                <div className="tab-section scroller">
                  <StudentTab data={note?.teacher} setNote={setNote} />
                </div>
              </Tab>
            </Tabs>
          )}
        </div>
      </div>
    </div>
  );
};
