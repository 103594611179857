import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./tabComponent.scss";
import BasicInformationStudent from "../BasicInformationStudent/BasicInformationStudent";
import FeeHistory from "../FeeHistory/FeeHistory";
import Marks from "../Marks/Marks";
import Portfolio from "../Portfolio/Portfolio";
import Remarks from "../Remarks/Remarks";
import { StudentAttendance } from "../StudentAttendance/StudentAttendance";

function TabComponent({ childId }) {
  const showTab = true;
  const showAttendance = true;
  const tabs = [
    {
      name: "Parents Detail",
      component: <BasicInformationStudent childId={childId} />,
    },
    {
      name: "Marks",
      component: <Marks />,
    },
    {
      name: "Fee History",
      component: <FeeHistory />,
    },
    // {
    //   name: "Remarks",
    //   component: <Remarks />,
    // },
    showTab && { name: "Portfolio", component: <Portfolio /> },
    showAttendance && {
      name: "Attendance Log",
      component: <StudentAttendance />,
    },
  ];

  return (
    <div className="main-tab">
      <Tabs id="noanim-tab-example" className="tab-container">
        {tabs.map((tab, index) => (
          <Tab eventKey={index} key={index} title={tab.name}>
            {tab.component}
          </Tab>
        ))}
      </Tabs>
    </div>
  );
}

export default TabComponent;
