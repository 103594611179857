// CustomSelect.js

import React, { useEffect, useState } from "react";
import "./examMarks.scss"; // Import your CSS file for styling

const CustomSelect = ({ options, onChange, selected }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [activeSuboption, setActiveSuboption] = useState(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    setActiveSuboption(null);
  };

  const handleOptionClick = (option) => {
    if (option?.children) {
      setActiveSuboption(activeSuboption === option ? null : option);
    } else {
      setSelectedOption(option);
      onChange(option);
      setIsOpen(false);
    }
  };
  useEffect(() => {
    setSelectedOption(selected);
  }, [selected]);

  return (
    <div className="custom-select-section">
      <div
        className={`select-header ${isOpen ? "open" : ""}`}
        onClick={toggleAccordion}
      >
        {selectedOption ? selectedOption.label : "Select an option"}
        {/* <i className={`arrow-icon ${isOpen ? "open" : ""}`}></i> */}
        {isOpen ? (
          <i class="bx bx-chevron-up"></i>
        ) : (
          <i class="bx bx-chevron-down"></i>
        )}
      </div>
      <div className={`select-options ${isOpen ? "open" : ""}`}>
        {options?.map((option) => (
          <div
            key={option?.value}
            className={`option ${selectedOption === option ? "selected" : ""}`}
            onClick={() => handleOptionClick(option)}
          >
            {option?.label}
            {option?.children && (
              <>
                {activeSuboption === option ? (
                  <i class="bx bx-chevron-up"></i>
                ) : (
                  <i class="bx bx-chevron-down"></i>
                )}
              </>
            )}

            {option?.children && activeSuboption === option && (
              <div className="children">
                {option?.children.map((suboption) => (
                  <div
                    key={suboption?.value}
                    className={`suboption ${
                      selectedOption === suboption ? "selected" : ""
                    }`}
                    onClick={() => handleOptionClick(suboption)}
                  >
                    {suboption?.label}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomSelect;
