import React, { useEffect, useState } from "react";
import { QrReader } from "react-qr-reader";
import "./QRScan.scss";
import { useAppointmentStore } from "../../store/appointmentListStore";
import { postAppointmentComplete } from "../Api/PostApi";
import { toast } from "react-toastify";
import { Cameraswitch } from "@mui/icons-material";

export const QRScan = () => {
  const { qrData, setQrData } = useAppointmentStore();
  const [data, setData] = useState("No result !!");
  const [camera, setCamera] = useState("environment");

  useEffect(async () => {
    if (!qrData?.startsWith("http")) {
      const decodedbase64 = atob(qrData);
      const visitorId = decodedbase64.split(",")?.[0];

      if (!isNaN(visitorId)) {
        try {
          await postAppointmentComplete({ visitor_id: visitorId });
          toast.success("Appointment Completed!!!");
        } catch (error) {
          toast.error("Something went wrong");
        }
      }
    }
  }, [qrData]);
  const handleCameraChange = () => {
    if (camera === "environment") {
      setCamera("user");
    } else {
      setCamera("environment");
    }
  };
  return (
    <div className="qr-scan-container">
      <div className="container">
        <p>Scan Your QR Code</p>
        <div className="scanner-area">
          <QrReader
            onResult={(result, error) => {
              if (!!result) {
                setData(result?.text);
                setQrData(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: "100%", height: "300px" }}
            constraints={{ facingMode: camera }}
          />
        </div>

        <div className="arrow-icon">
          <i class="bx bx-chevrons-down"></i>

          {/* <i class="bx bxs-down-arrow"></i> */}
        </div>
        <div className="result-container">
          <div className="textarea-content">
            {data?.startsWith("http") ? (
              <div
                className="link-type"
                onClick={() => {
                  window.open(data);
                }}
              >
                {data}
              </div>
            ) : (
              data
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
