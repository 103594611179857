import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import "./chapterList.scss";
import { BreadCrumbs } from "../../../components/breadCrumbs/breadCrumbs";
import SearchComponent from "../../../components/eLearning/SearchComponent/SearchComponent";
import ChapterCard from "../../../components/eLearning/ChapterCard/ChapterCard";
import { getChapters } from "../../../components/Api/GetApi";
import { useEffect } from "react";

export const ChapterList = () => {
  const location = useLocation();
  const history = useHistory();
  const locationData = location?.state || Math;
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const filteredChapters = data?.filter((chapter) =>
    chapter?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );

  useEffect(() => {
    setIsloading(true);
    getChapters(locationData?.gradeToken, locationData.subjectToken).then(
      (res) => {
        setData(res?.data);
        setIsloading(false);
      }
    );
  }, [locationData?.gradeToken, locationData.subjectToken]);

  return (
    <div className="chapter-list-page">
      <BreadCrumbs child={["Home", locationData?.firstParams]} />
      <div className=" main-container">
        <div className="top-section">
          <div className="title">{locationData?.firstParams}</div>
          <div className=" search-component">
            <SearchComponent
              onSearch={setSearchQuery}
              placeholder="Search Chapter"
            />
          </div>
        </div>
        <div className="chapter-list">
          {isLoading ? (
            <>
              {Array.from({ length: 4 }, (_, index) => {
                return (
                  <div className="chapter-list-shimmer" key={index}>
                    <div className="shimmer-card" key={index}>
                      <div className="d-flex">
                        <div className="logo" />
                        <div className="list-title" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <>
              {filteredChapters?.map((chapter, index) => (
                <div
                  key={index}
                  onClick={() => {
                    history.push({
                      pathname: "/chapter",
                      state: {
                        firstParams: locationData?.firstParams,
                        secondParams: chapter.title,
                        syllabusNotesId: chapter?.id,
                        chapterList: data,
                      },
                    });
                  }}
                >
                  <ChapterCard logo={index + 1} title={chapter.title} />
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
