import React, { Component } from "react";
import { useLocation } from "react-router-dom";
import "./resultCard.scss";

export const ResultCard = () => {
  const location = useLocation();
  const pdfFile = location.state?.imgPdf;
  return (
    <div className="result-card-section">
      <img
        className="pdf-view"
        src={pdfFile}
        type="application/pdf"
        style={{ width: "100%" }}
      />
    </div>
  );
};
