import React, { useEffect, useState, useRef } from "react";
import "./CanteenOrderCart.scss";
import {
  useBillData,
  useCanteenOrder,
  useLogOut,
  useStudentDetails,
} from "../../store/Canteen";
import _ from "lodash";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";
import Modal from "react-modal";
import {
  postCanteenOrder,
  postCanteenStudentRfidDetails,
} from "../Api/PostApi";
import Numpad from "../Numbpad/Numpad";
import CanteenBillPopUp from "../CanteenBillPopup/CanteenBillPopUp";

const CanteenOrderCart = () => {
  const style = {
    fontWeight: "500",
    fontSize: "16px",
    color: "#333333",
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "20%",
    },
  };

  const { order } = useCanteenOrder();
  const [total, setTotal] = useState(0);
  const [isValidate, setIsValidate] = useState(false); //to change the template of card to order table
  // const [reader, setReader] = useState(null);
  // const [data, setData] = useState("");
  const [cardInputVal, setCardInputVal] = useState(null); //card value
  const [cardPin, setCardPin] = useState(null); //entered pin
  const inputRef = useRef(null);
  const cardPinRef = useRef(null);
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isOrdering, setIsOrdering] = useState(false);
  const { studentDetails, addStudentData, clearStudentData } =
    useStudentDetails();
  const { clear } = useCanteenOrder();
  const { isLogOut, logOutDone } = useLogOut();
  const { setBillData, setIsBillShowing, billData } = useBillData();
  const [shouldClear, setShouldClear] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#000000";
  }

  useEffect(() => {
    console.log("zustBill", billData);
  }, [billData]);
  function closeModal() {
    setIsOpen(false);
    setCardPin(null);
    setCardInputVal(null);
  }

  const debouncedHandleScan = _.debounce((e) => {
    setCardInputVal(e.target.value);
    setIsOpen(true);
  }, 500);

  async function orderNow() {
    const data = {
      token: studentDetails?.token,
      orders: order,
      total_credit: total,
    };

    if (total > studentDetails?.credit) {
      toast("Insufficient credit balance");
      setTotal(0);
      clear(); //clears the state of order list of zustand
      clearStudentData();
      setIsValidate(false);
      // setCardInputVal("")
      logOutDone();
    } else {
      setIsOrdering(true);
      try {
        const res = await postCanteenOrder(data);
        if (res?.data?.status === 1) {
          const billDataTemp = {
            ...data,
            QRCode: res?.data?.data,
            student_name: studentDetails?.name,
            class: studentDetails?.class_name,
          };

          toast("Successfully Ordered");
          setBillData(billDataTemp);

          setIsBillShowing();
          setTotal(0);
          clear();
          clearStudentData();
          setIsValidate(false);

          logOutDone();
        } else {
          toast(res?.data?.message);
          setTotal(0);
          clear();
          clearStudentData();
          setIsValidate(false);

          logOutDone();
        }
      } catch {
        toast("Something went wrong");
      } finally {
        setIsOrdering(false);
      }
    }
  }
  function cancelOrder() {
    setTotal(0);
    clear();
    clearStudentData();
    setIsValidate(false);

    logOutDone();
  }
  async function pinSubmit() {
    const values = {
      rfid: cardInputVal,
      pin: cardPin,
    };

    try {
      const res = await postCanteenStudentRfidDetails(values);
      if (res.data.status === 1) {
        addStudentData(res?.data?.data);
        setIsValidate(true);
        // setCardInputVal(null)
        setCardPin(null);
      } else {
        toast("Invalid pin or rfid");
        // setCardInputVal(null)
        setCardPin(null);
      }
    } catch {
      toast("something sent wrong");
      // setCardInputVal(null)
      setCardPin(null);
    } finally {
      setIsOpen(false);
    }
  }

  const handleOutsideClick = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      isValidate === false
    ) {
      inputRef.current.focus();
      document.removeEventListener("click", handleOutsideClick);
    }
  };

  isValidate === false &&
    document.addEventListener("click", handleOutsideClick);

  useEffect(() => {
    if (isValidate === false && modalIsOpen === false) {
      inputRef.current.focus();
    }
    if (!modalIsOpen && cardInputVal !== null) {
      setCardInputVal(null);
    }
  }, [isLogOut, modalIsOpen]);

  useEffect(() => {
    if (Array.isArray(order) && order?.length > 0) {
      const totalAmount = order?.map((item, index) => item?.menu_credit);
      const initialValue = 0;
      const netAmount = totalAmount?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        initialValue
      );
      setTotal(netAmount);
    }
  }, [order]);
  useEffect(() => {
    if (cardInputVal === null && isValidate === false) {
      inputRef.current.value = ""; // Clear the input field
    }
    if (cardPin === null && modalIsOpen && isValidate === false) {
      cardPinRef.current.value = "";
    }
  }, [cardInputVal, cardPin]);

  return (
    <div className="canteen_cart_container">
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h3>
          <div style={{ cursor: "pointer" }} onClick={closeModal}>
            <IoIosClose size={40} />
          </div>
        </div>

        <div style={{ fontSize: "12px" }}>Please Enter Your Card Pin.</div>

        <input
          type="password"
          style={{ marginTop: "5px", width: "100%", borderRadius: "5px" }}
          value={cardPin}
          ref={cardPinRef}
          disabled
        />
        <Numpad val={cardPin} shouldClear={shouldClear} setVal={setCardPin} />
        <div
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "#FF8080",
              padding: "5px 10px",
              textAlign: "center",
              color: "#FFFFFF",
              marginTop: "5px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
            onClick={() => setShouldClear(!shouldClear)}
          >
            Clear All
          </div>
          <div
            onClick={() => pinSubmit()}
            style={{
              backgroundColor: "#6257ED",
              padding: "5px 10px",
              textAlign: "center",
              color: "#FFFFFF",
              marginTop: "5px",
              cursor: "pointer",
              borderRadius: "5px",
            }}
          >
            Submit
          </div>
        </div>
      </Modal>
      <CanteenBillPopUp />

      {isValidate ? (
        <div className="canteen_cart_wrapper">
          <div className="canteen_cart_head">
            <div className="student_details_section">
              <div className="student_image">
                {studentDetails?.image !== null ? (
                  <img src={studentDetails?.image} alt="img" />
                ) : (
                  <div className="student_img_alt">
                    {studentDetails?.name?.charAt(0)}
                  </div>
                )}
              </div>
              <div className="student_desc">
                <h2>{studentDetails?.name}</h2>
                <div className="class_roll_wrapper">
                  <p>{studentDetails?.class_name}</p>
                  <div className="dot"></div>
                  <p>{studentDetails?.student_id}</p>
                </div>
              </div>
            </div>
            <div className="credit_details_section">
              <h3>AVAILABLE CREDITS</h3>
              <p>{studentDetails?.credit}</p>
            </div>
          </div>
          <div className="divider"></div>
          <div className="summary_section">
            <h2>Summary</h2>
          </div>
          <div className="table_section">
            {order.length > 0 ? (
              <table className="table">
                <tbody>
                  {order?.map((item, index) => (
                    <tr>
                      <td>{item?.menu_name}</td>
                      <td>X {item?.quantity}</td>
                      <td>{item?.menu_credit} Credits</td>
                    </tr>
                  ))}
                  {order.length > 0 && (
                    <tr>
                      <td style={style}>TOTAL</td>
                      <td></td>
                      <td style={style}>{total} Credits</td>
                    </tr>
                  )}
                </tbody>
              </table>
            ) : (
              <p>Please select the order.</p>
            )}
          </div>
          {order?.length > 0 && (
            <div
              disabled={isOrdering}
              onClick={() => orderNow()}
              className="order_btn"
            >
              {isOrdering ? "Ordering" : "Place Order"}
            </div>
          )}
          <div onClick={() => cancelOrder()} className="cancel_btn">
            Cancel
          </div>
        </div>
      ) : (
        <div className="scan_card_container">
          {/* <div className="img_container">
            <img src={imgCard} alt="card" />
            
          </div> */}
          <div className="reader">
            <div className="reader__bar"></div>
            <div className="reader__placeholder"></div>
            <div className="reader__cc-shadow"></div>
          </div>
          <div className="cc">
            <div className="cc__chip"></div>
          </div>
          <h2>Please Tap Your Card First.</h2>

          <input
            className="card_detail_box"
            value={cardInputVal}
            onChange={(e) => debouncedHandleScan(e)}
            ref={inputRef}
            type="text"
          />
        </div>
      )}
    </div>
  );
};

export default CanteenOrderCart;
