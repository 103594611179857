export const FlowSheetDetails = ({
  keyName,
  selectedLessonData,
  resourcesForPanel,
}) => {
  const responseDetails = (responseData) => {
    const extractedIds = responseData?.teaching_methods
      .split("#")
      .map((id) => id.replace(/^# /, "").trim())
      .filter((id) => id);

    const descriptionsString = extractedIds
      ?.map((id) => {
        const resource = resourcesForPanel.find(
          (resource) => resource.id === id
        );
        return resource ? resource.desc : "";
      })
      .join("<br>");
    return (
      <>
        {responseData?.teaching_methods &&
        responseData.teaching_methods.includes("#") ? (
          <>
            {descriptionsString?.trim() ? (
              <td dangerouslySetInnerHTML={{ __html: descriptionsString }}></td>
            ) : (
              <td>-</td>
            )}
          </>
        ) : (
          <td>
            {responseData?.teaching_methods?.length > 0
              ? responseData.teaching_methods
              : "-"}
          </td>
        )}

        <td>{responseData?.time > 0 ? responseData?.time + "minutes" : "-"}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </>
    );
  };

  const rulesDetails = (rulesData) => {
    const extractedIds = rulesData?.teaching_methods
      .split("#")
      .map((id) => id.replace(/^# /, "").trim())
      .filter((id) => id);

    const descriptionsString = extractedIds
      ?.map((id) => {
        const resource = resourcesForPanel.find(
          (resource) => resource.id === id
        );
        return resource ? resource.desc : "";
      })
      .join("<br>");
    return (
      <>
        {rulesData?.teaching_methods &&
        rulesData.teaching_methods.includes("#") ? (
          <>
            {descriptionsString?.trim() ? (
              <td dangerouslySetInnerHTML={{ __html: descriptionsString }}></td>
            ) : (
              <td>-</td>
            )}
          </>
        ) : (
          <td>
            {rulesData?.teaching_methods?.length > 0
              ? rulesData.teaching_methods
              : "-"}
          </td>
        )}
        <td>{rulesData?.time > 0 ? rulesData?.time + "minutes" : "-"}</td>

        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </>
    );
  };

  const skillsDetails = (skillsData) => {
    const extractedIds = skillsData?.teaching_methods
      .split("#")
      .map((id) => id.replace(/^# /, "").trim())
      .filter((id) => id);

    const descriptionsString = extractedIds
      ?.map((id) => {
        const resource = resourcesForPanel.find(
          (resource) => resource.id === id
        );
        return resource ? resource.desc : "";
      })
      .join("<br>");
    return (
      <>
        {skillsData?.teaching_methods &&
        skillsData.teaching_methods.includes("#") ? (
          <>
            {descriptionsString?.trim() ? (
              <td dangerouslySetInnerHTML={{ __html: descriptionsString }}></td>
            ) : (
              <td>-</td>
            )}
          </>
        ) : (
          <td>
            {skillsData?.teaching_methods?.length > 0
              ? skillsData.teaching_methods
              : "-"}
          </td>
        )}
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </>
    );
  };

  const homeworkDetails = (homeworkData) => {
    return (
      <>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>{homeworkData !== "" ? homeworkData : "-"}</td>
        <td>-</td>
      </>
    );
  };

  const classworkDetails = (classworkData) => {
    return (
      <>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>{classworkData !== "" ? classworkData : "-"}</td>
      </>
    );
  };

  const pedagogyDetails = (pedagogyData) => {
    const extractedIds = pedagogyData?.teaching_methods
      .split("#")
      .map((id) => id.replace(/^# /, "").trim())
      .filter((id) => id);

    const descriptionsString = extractedIds
      ?.map((id) => {
        const resources = resourcesForPanel?.find(
          (resource) => resource.id === id
        );
        return resources ? resources.desc : "";
      })
      .join("<br>");

    return (
      <>
        {pedagogyData?.teaching_methods &&
        pedagogyData.teaching_methods.includes("#") ? (
          <>
            {descriptionsString?.trim() ? (
              <td dangerouslySetInnerHTML={{ __html: descriptionsString }}></td>
            ) : (
              <td>-</td>
            )}
          </>
        ) : (
          <td>
            {pedagogyData?.teaching_methods?.length > 0
              ? pedagogyData.teaching_methods
              : "-"}
          </td>
        )}
        <td>
          {pedagogyData?.time > 0 ? pedagogyData?.time + "minutes" : "-"}{" "}
        </td>

        <td>
          {pedagogyData?.resources !== "" ? pedagogyData?.resources : "-"}
        </td>
        <td>
          {pedagogyData?.keywords?.length > 0 ? (
            <>
              {pedagogyData?.keywords.map((item) => {
                return <span className="tag">{item}</span>;
              })}
            </>
          ) : (
            "-"
          )}
        </td>
        <td>-</td>
        <td>-</td>
      </>
    );
  };

  const previewDetails = (previewData) => {
    const extractedIds = previewData?.teaching_methods
      .split("#")
      .map((id) => id.replace(/^# /, "").trim())
      .filter((id) => id);

    const descriptionsString = extractedIds
      ?.map((id) => {
        const resource = resourcesForPanel.find(
          (resource) => resource.id === id
        );
        return resource ? resource.desc : "";
      })
      .join("<br>");
    return (
      <>
        {previewData?.teaching_methods &&
        previewData.teaching_methods.includes("#") ? (
          <>
            {descriptionsString?.trim() ? (
              <td dangerouslySetInnerHTML={{ __html: descriptionsString }}></td>
            ) : (
              <td>-</td>
            )}
          </>
        ) : (
          <td>
            {previewData?.teaching_methods?.length > 0
              ? previewData.teaching_methods
              : "-"}
          </td>
        )}
        <td>{previewData?.time > 0 ? previewData?.time + "minutes" : "-"} </td>
        <td>{previewData?.resources !== "" ? previewData?.resources : "-"}</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
      </>
    );
  };

  const showKeyNameData = (keyName, selectedLessonData) => {
    switch (keyName) {
      case "response":
        return responseDetails(selectedLessonData?.response);
      case "rules":
        return rulesDetails(selectedLessonData?.rules);
      case "preview":
        return previewDetails(selectedLessonData?.preview);
      case "pedagogy":
        return pedagogyDetails(selectedLessonData?.pedagogy);
      case "skills":
        return skillsDetails(selectedLessonData?.skills);
      case "homework":
        return homeworkDetails(selectedLessonData?.planned_homework);
      case "classwork":
        return classworkDetails(selectedLessonData?.planned_classwork);
      default:
        return true;
    }
  };

  return <>{showKeyNameData(keyName, selectedLessonData)}</>;
};
