import React, { useEffect, useState } from "react";
import "./expensescard.scss";
import ViewAll from "../AccountDashboardViewBtn/ViewAll";

import Table2 from "../expensestable/ExpensesTable";
import RecordBtn from "../recordTransactionBtn/RecordBtn";
import { useRecentExpenses } from "../../store/accountDashboard";
import { domain } from "../../url";
import NepaliDate from "nepali-date";
import Select from "react-select";
import { LuSettings2 } from "react-icons/lu";
import AccountDateFilter from "../AccountDateFilter/AccountDateFilter";

const ExpensesCard = () => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [typeToken, setTypeToken] = useState({
    value: "",
    label: "Select Type",
  });
  const { loading, expenses, fetchExpenses } = useRecentExpenses();
  const [typeOptions, setTypeOptions] = useState([]);
  const expenseList = expenses?.expense_types;
  const [showFilter, setShowFilter] = useState(false);

  useEffect(() => {
    (async () => {
      const selectTypeList = await expenseList?.map((item, index) => {
        return { value: item.id, label: item.ledgerName };
      });
      await selectTypeList.unshift({
        value: "",
        label: "All Type",
      });

      setTypeOptions(selectTypeList);
    })();
  }, [expenseList]);

  const parseSelectOptions = (data) => {
    return data
      .split("<option")
      .filter((option) => option?.trim().startsWith("value="))
      .map((option) => {
        const valueMatch = option?.match(/value="([^"]+)"/);
        const labelMatch = option?.match(/>(.*?)<\/option>/);
        if (valueMatch && labelMatch) {
          const value = valueMatch[1];
          const label = labelMatch[1];
          return { value, label };
        } else {
          const value = "Select";
          const label = "";
          return { value, label };
        }
      });
  };
  const todayDate = new NepaliDate().format("YYYY/MM/DD");

  useEffect(() => {
    fetchExpenses(fromDate, toDate, typeToken?.value);
  }, [fromDate, toDate, typeToken?.value]);

  return (
    <div className="card3_container">
      <div className="heading">
        <div className="title_main">Recent Payments</div>
        <div
          onClick={() => {
            window.open(`${domain}/expense`);
          }}
        >
          <RecordBtn name="Record Transaction" />{" "}
        </div>
      </div>
      <div className="sub_heading">
        <div className="select_type">
          <Select
            value={typeToken}
            options={typeOptions || []}
            onChange={(e) => setTypeToken(e)}
          />
        </div>
        <div className="sub_heading_date">
          <div title="Date Filter" className="date_filter_wrapper">
            <LuSettings2
              size={24}
              color="#0E89CA"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            />

            <AccountDateFilter
              show={showFilter}
              setShow={setShowFilter}
              getFromDate={(value) => {
                setFromDate(value);
              }}
              getToDate={(value) => {
                setToDate(value);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        <Table2 from={fromDate} to={toDate} />
      </div>
    </div>
  );
};

export default ExpensesCard;
