import React from "react";
import "./customNode.css";
const CustomNodeElement = ({ nodeDatum, toggleNode }) => {
  return (
    <React.Fragment>
      <foreignObject
        style={{
          transform: "translate(-100px,-35px)",
          width: "200px",
          height: "80px",
        }}
      >
        <div className="card-container">
          <div>
            <img src={nodeDatum.img} className="emp-image" />
          </div>
          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <span className="emp-name">{nodeDatum.name}</span>
            <span className="emp-role">{nodeDatum.role}</span>
            <span className="emp-title">{nodeDatum.title}</span>
          </div>
        </div>
        {nodeDatum.children ? (
          <button onClick={toggleNode} className="collapseBtn">
            {nodeDatum.__rd3t.collapsed ? nodeDatum.children.length : "-"}
          </button>
        ) : null}
      </foreignObject>
    </React.Fragment>
  );
};
export default CustomNodeElement;
