import React from "react";
import StudentAssignmentCard from "../StudentAssignmentCard/StudentAssignmentCard";
import { NoDataContent } from "../NoDataContent/NoDataContent";

export const CheckedStatusActivity = ({
  handleDownload,
  studentList,
  ishomework,
  setBtnLoading,
  isBtnLoading,
  viewonly,
  checked,
  assignmentToken,
}) => {
  return (
    <div className="checked-status-activity">
      {studentList?.length > 0 ? (
        <StudentAssignmentCard
          isBtnLoading={isBtnLoading}
          handleDownload={handleDownload}
          setBtnLoading={setBtnLoading}
          studentList={studentList}
          ishomework={ishomework}
          viewonly={viewonly}
          checked={checked}
          assignmentToken={assignmentToken}
        />
      ) : (
        <NoDataContent
          title="No Data"
          image={require("../../images/emptyfile.png").default}
          description="No data found  at the moment"
        />
      )}
    </div>
  );
};
