import React, { useEffect, useState } from "react";
import "./PerformanceCard.scss";

const PerformanceCard = ({ isHighest, data }) => {
  const [filterData, setFilterData] = useState();

  const theadData = ["Rank", "NAME", "AVERAGE SCORE"];
  // const tbodyData = [
  //     {rank:1, name:"jhon doe", avg_score:5},
  //     {rank:1, name:"jhon doe", avg_score:5},
  //     {rank:1, name:"jhon doe", avg_score:5},
  //     {rank:1, name:"jhon doe", avg_score:5},
  //     {rank:1, name:"jhon doe", avg_score:5},
  //     {rank:1, name:"jhon doe", avg_score:5},
  //     {rank:1, name:"jhon doe", avg_score:5},

  // ]

  useEffect(() => {
    if (data) {
      const highPerformanceData =
        data && data?.find((item, _index) => item?.performance === "high");
      const lowPerformanceData =
        data && data?.find((item, _index) => item?.performance === "low");

      setFilterData(isHighest ? highPerformanceData : lowPerformanceData);
    }
  }, [data]);

  return (
    <div className="performance_card_container">
      {isHighest ? (
        <div className="title_section">
          <div className="icon_wrapper">
            <i class="bx bxs-star"></i>
          </div>
          <h2 className="heading">Highest Performance</h2>
        </div>
      ) : (
        <div className="title_section">
          <div className="icon_wrapper">
            <i class="bx bx-star"></i>
          </div>
          <h2 className="heading">Lowest Performance</h2>
        </div>
      )}
      <div className="table_wrapper">
        <table>
          <thead>
            {theadData?.map((item, index) => {
              return <th key={index}>{item}</th>;
            })}
          </thead>
          <tbody>
            {filterData !== undefined &&
              filterData?.students?.slice(0, 5).map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item?.student_name}</td>
                  <td>{item?.score}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PerformanceCard;
