import { React, useEffect, useState } from "react";
import Select from "react-select";
import "./Assignment.scss";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "nepali-datepicker-reactjs/dist/index.css";
import { StudentList } from "../StudentList/StudentList";
import axios from "axios";
import { domain, jwtToken } from "../../url";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

export const Assignment = () => {
  const [selectedValues, setSelectedValues] = useState();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessge, setSnackMessge] = useState("");
  const [snackStatus, setSnackStatus] = useState(true);

  const [getApiData, setGetApiData] = useState();
  //api of grade option
  const [gradeData, setGradeData] = useState([]);
  //set value of grade option
  const [gradeValue, setGradeValue] = useState();

  //api of assignment option
  const [assignmentData, setAssignmentData] = useState([]);
  //set value of assignment option
  const [assignmentValue, setAssignmentValue] = useState();

  //api of assignment option
  const [studentListData, setStudentListData] = useState([]);
  //set value of assignment option
  const [studentListValue, setStudentListValue] = useState();

  // nepali date picker
  const [date, setDate] = useState("");

  //selected option
  const [selectedValue, setSelectValue] = useState();
  const [selectAssignment, setSelectAssignment] = useState();
  const [selectStudentList, setSelectStudentList] = useState();
  const [submitData, setSubmitData] = useState();
  const [saveData, setSaveData] = useState();
  const [remarkTypeToken, setRemarkTypeToken] = useState();

  //select state api and value
  //select state api and value
  const [status, setStatus] = useState();

  // const subjectWiseList =()=>{

  // }

  const gradeSelect = (value) => {
    setSelectValue(value);
  };
  const assignmentSelect = (value) => {
    setSelectAssignment(value);
  };
  const studentListSelect = (value) => {
    setSelectStudentList(value);
  };
  useEffect(() => {
    axios
      .get(`${domain}/bulk_remark_api?token=${jwtToken}`)
      .then((res) => {
        setGradeData(res.data);
        setAssignmentData(res.data);
        setAssignmentValue(res.data);
      })
      .catch((e) => {
        alert("Errror");
      });
  }, []);
  useEffect(() => {
    if (selectedValue && remarkTypeToken && date) {
      axios
        .get(
          `${domain}/bulk_remark_student_list?gradeToken=${selectedValue}&remarkTypeToken=${remarkTypeToken}&date=${date}`
        )
        .then((res) => {
          setGetApiData(res.data);
        });
    }
  }, [studentListData]);

  useEffect(() => {
    if (gradeData) {
      const gradeOption = gradeData?.grade?.map((item) => {
        return {
          value: item?.id,
          label: item?.gradeName,
        };
      });
      setGradeValue(gradeOption);
    }
    if (assignmentData) {
      const assignmentOption = assignmentData?.remark?.map((item) => {
        return {
          value: item?.id,
          label: item?.remark_name,
        };
      });
      setAssignmentValue(assignmentOption);
    }
    if (studentListData) {
      const studentListOption = studentListData?.subject_list?.map((item) => {
        return {
          value: item?.id,
          label: item?.subjectName,
        };
      });
      setStudentListValue(studentListOption);
    }
  }, [gradeData, assignmentData, studentListData]);

  const handleSave = async (studentPerformance, studentDescription) => {
    const studentObject = {
      dayToken: date,
      remark_type_token: selectAssignment,
      subjectToken: selectStudentList,
      studentData: submitData?.student?.map((item) => {
        const selectedRemark = submitData?.remark?.find(
          (student) => student?.value === studentPerformance[item.id],
          (student) => student?.description === studentDescription[item.id]
        );
        return {
          studentToken: item.id,
          description: studentDescription[item.id],
          value: selectedRemark?.id,
        };
      }),
    };

    const response = await axios.post(
      `${domain}/bulk_remark_post_api`,
      studentObject
    );
    setSaveData(response.data);
    setOpenSnackBar(true);
    setSnackMessge("The status is updated!");
    setSnackStatus(true);
  };

  const handleChange = (value) => {
    setRemarkTypeToken(value);
    axios
      .get(
        `${domain}/get_bulk_remark?gradeToken=${selectedValue}&remark_type_token=${value}`
      )
      .then((res) => {
        setSubmitData(res.data);
        setStudentListData(res.data);
      });
  };
  return (
    <div className="assignment-page">
      <div className="select-page">
        <div className="form-group">
          <div className="row">
            <div className="col-md-4">
              <label className="d-block">Date</label>
              <NepaliDatePicker
                inputClassName="form-control"
                className=""
                value={date}
                format="YYYY/MM/DD"
                onChange={(value) => {
                  setDate(value);
                }}
                options={{ calenderLocale: "en", valueLocale: "en" }}
              />
            </div>
            <div className="col-md-4">
              <label className="d-block">Grade</label>

              <Select
                options={gradeValue}
                onChange={(e) => {
                  gradeSelect(e.value);
                }}
              />
            </div>

            <div className="col-md-4">
              <label className="d-block">Assignment</label>
              <Select
                options={assignmentValue}
                onChange={(e) => {
                  assignmentSelect(e.value);
                  handleChange(e.value);
                }}
              />
            </div>
            <div
              className={
                submitData?.subject_wise_list === true ? "col-md-4" : "d-none"
              }
            >
              <label className="d-block">Subject List</label>
              <Select
                options={studentListValue}
                onChange={(e) => {
                  studentListSelect(e.value);
                }}
              />
            </div>
            {/* {submitData.subject_wise_list === true ? (
              <div className="col-md-4">
                <label className="d-block">subject list</label>
                <Select
                  options={studentListValue}
                  onChange={(e) => {
                    studentListSelect(e.value)
                  }}
                />
              </div>
            ) : (
              <div></div>
            )} */}
          </div>
        </div>

        <SnackbarProvider maxSnack={3}>
          <StudentList
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={3000}
            handleChange={(e) => handleChange}
            submitData={submitData}
            status={status}
            selectedValue={selectedValue}
            selectAssignment={selectAssignment}
            date={date}
            handleSave={handleSave}
            getApiData={getApiData}
          />
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={() => setOpenSnackBar(false)}
          >
            {snackStatus ? (
              <Alert severity="success">{snackMessge}</Alert>
            ) : (
              <Alert severity="error">{snackMessge}</Alert>
            )}
          </Snackbar>
        </SnackbarProvider>
      </div>
    </div>
  );
};
