import axios from "axios";
import { domain, headers, schoolRegistrationDomain } from "../../url";
import axiosInstance from "../AxiosInceptor/AxiosInstance";
export const imgdomain = "https://storage.googleapis.com/axios-documents/";

export const getECAHeader = (grade) => {
  return axios.get(
    `${domain}/teacher_api/extra_curricular_headers?gradeToken=${grade}`,
    {
      headers,
    }
  );
};

export const getExamlist = () => {
  return axiosInstance.get(`${domain}/teacherApi/getExamAndGrade`);
};

export const getGradelist = () => {
  return axiosInstance.get(`${domain}/teacherApi/getAllClass`);
};

export const getStudentsFromGrade = (data) => {
  const { gradeToken, eca, headervalue, subjectToken, examToken } = data;
  let apiUrl = `${domain}/teacherApi/student_list_from_grade?grade_token=${gradeToken}&subject_token=${subjectToken}`;

  if (eca) {
    apiUrl += `&additional_exam_subject=${headervalue}`;
    apiUrl += `&exam_token=${examToken}`;
  }
  // if (strictFilter) {
  //   apiUrl += `&subject_token=${subjectToken}`;
  // }
  return axiosInstance.get(apiUrl);
};

export const getAttendanceList = (examToken) => {
  return axios.get(
    `${domain}/teacherApi/getAttendanceList?examToken=${examToken}`,
    { headers }
  );
};

export const getStudentSpecificRecords = (studentId) => {
  return axios.get(`${domain}/students/${studentId}/student_records`, {
    headers,
  });
};
export const getChildData = () => {
  return axiosInstance.get(`${domain}/getChildData`);
};
export const getStudentRecords = (id) => {
  return axios.get(`${domain}/student_records/${id}`, { headers });
};
export const getExamDataList = () => {
  return axiosInstance.get(`${domain}/exam_list`);
};
export const getGradeApi = () => {
  return axiosInstance.get(`${domain}/gradeApi`);
};

export const getGradeApiForExam = () => {
  return axiosInstance.get(`${domain}/gradeApiForExam`);
};
export const getExamSubjectList = (requestData) => {
  return axiosInstance.get(`${domain}/exam_subject_list`, {
    params: requestData,
  });
};

export const getSubjectApiForExam = (requestData) => {
  return axiosInstance.get(`${domain}/subjectApiForExam`, {
    params: requestData,
  });
};

export const getExamEvaluations = (requestData) => {
  return axiosInstance.get(
    `${domain}/exam_evaluations?grade_subject_token=${requestData}`
  );
};

export const getExamRecords = (requestData) => {
  return axiosInstance.get(`${domain}/exam_record`, {
    params: requestData,
  });
};

export const getSyllabus = (gradeToken) => {
  return axios.get(`${domain}/get_syllabus?gradeToken=${gradeToken}`, {
    headers,
  });
};
export const getChapters = (gradeToken, subjectToken) => {
  return axios.get(
    `${domain}/get_chapters/?subjectToken=${subjectToken}&gradeToken=${gradeToken}`,
    { headers }
  );
};
export const getCources = (syllabusNotesId) => {
  return axios.get(
    `${domain}/get_courses?syllabus_notes_id=${syllabusNotesId} `,
    { headers }
  );
};

export const getAllGrade = () => {
  return axios.get(`${domain}/grade_list`, { headers });
};

export const getAssignmentPreference = () => {
  return axios.get(`${domain}/api/assignment_preferences`, { headers });
};

export const getAssignmentDetails = ({
  gradeToken,
  assignmentToken,
  ishomework,
}) => {
  return axiosInstance.get(
    `${domain}/api/assignment_details?gradeToken=${gradeToken}&assignment_token=${assignmentToken}&is_homework=${ishomework}`
  );
};

export const getAssignmentList = ({ gradeToken, subjectToken, dayToken }) => {
  const commaIndex = gradeToken.indexOf(",");
  // If commaIndex is -1, it means there is no comma in the string
  // In that case, use the entire value as the first value
  const firstValue =
    commaIndex === -1 ? gradeToken : gradeToken.substring(0, commaIndex);

  return axiosInstance.get(
    `${domain}/api/unchecked_assignment?gradeToken=${firstValue}&subjectToken=${subjectToken}&dayToken=${dayToken}`
  );
};

export const getECAGradingList = () => {
  return axios.get(`${domain}/eca_grading_list`, { headers });
};

export const getAttendanceReport = (values) => {
  return axiosInstance.get(
    `${domain}/teacher_api/teacher_attendance_details?month=${values}`
  );
};

export const getTeacherDetails = (token) => {
  return axiosInstance.get(`${domain}/teacher_api/teacher_profile`, {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
};

export const getSubjects = (id) => {
  return axiosInstance.get(`${domain}/subjectApi?grade_token=${id}`);
};

export const getSubjectApiStrictFilter = (id) => {
  return axiosInstance.get(
    `${domain}/subjectApiStrictFilter?grade_token=${id}`
  );
};

export const getGrades = () => {
  return axiosInstance.get(`${domain}/gradeApi`);
};

export const getGradesfromAssignment = () => {
  return axiosInstance.get(`${domain}/gradeApiForAssignment`);
};

export const editDeviceAttendance = (value) => {
  return axiosInstance.get(
    `${domain}/editDeviceAttendance?id=${value?.id}&remark=${value?.remark}&entry_time=${value?.entry_time}&exit_time=${value?.exit_time}&date=${value?.date}`,
    { headers }
  );
};

export const getExamSubject = (id) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/get_all_exam_subjects_v2?teacher_token=${id}`
  );
};

export const getAllSubjects = (id) => {
  return axiosInstance.get(
    `${domain}/api/v2//teacher_api/get_all_subjects_v2?teacher_token=${id}`
  );
};
//Teacher_schedule
export const getTeacherSchedule = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/teacher_schedule`);
};
//Library Managemnet
export const getLibraryManagement = () => {
  return axiosInstance.get(`${domain}/bookreview`);
};
//calendar
export const getCalendar = (month, year) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/calender_data?month=${month}&year=${year}`
  );
};
//Teacher_Outcome
export const getTeacherOutcome = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/appointments`);
};
export const getSelectedExamSubjectList = (teacherId) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/get_selected_subjects_v2?teacher_token=${teacherId}`
  );
};
export const getState = () => {
  return axiosInstance.get(`${schoolRegistrationDomain}/province_list`);
};
export const getStudentData = (id) => {
  return axiosInstance.get(`${domain}/teacherApi/student_profile/${id}`);
};
export const getDistrict = (provinceId) => {
  return axiosInstance.get(
    `${schoolRegistrationDomain}/filter_province?province=${provinceId}`
  );
};
export const getMunicipality = (districtId) => {
  return axiosInstance.get(
    `${schoolRegistrationDomain}/filter_district?district=${districtId}`
  );
};
export const getStudentAttendance = (id, month) => {
  return axiosInstance.get(
    `${domain}/student_api/get_student_attendance_api?student_id=${id}&month=${month}`
  );
};
export const getDashboardMessage = (date) => {
  return axiosInstance.get(
    `${domain}/dashboard_api/v1/messages/senders?date=${date}`,
    {
      headers,
    }
  );
};
export const getTotalReceivable = () => {
  return axiosInstance.get(`${domain}/accounts/total_and_classwise_receivable`);
};

export const getStudentwiseReceivable = async (id) => {
  return axiosInstance.get(
    `${domain}/accounts/studentwise_receivable?grade_token=${id}`
  );
};

export const getSalesChart = async (timeline) => {
  return axiosInstance.get(
    `${domain}/accounts/sales_percentage?duration=${timeline}`
  );
};

export const getExpensesChart = async (timeline) => {
  return axiosInstance.get(
    `${domain}/accounts/expenses_percentage?duration=${timeline}`
  );
};

export const getIncomeExpensesChart = (timeline) => {
  return axiosInstance.get(
    `${domain}/accounts/income_expenses?year=${timeline}`
  );
};

export const getPayement = async (from, to, gradeToken) => {
  return axiosInstance.get(
    `${domain}/accounts/recent_fee_payment?from_date=${from}&to_date=${to}&grade_token=${gradeToken}`
  );
};

export const getExpenses = async (from, to, token) => {
  return axiosInstance.get(
    `${domain}/accounts/recent_expenses?from_date=${from}&to_date=${to}&expense_head=${token}`
  );
};

export const getCashFlow = async (year) => {
  return axiosInstance.get(`${domain}/accounts/cash_flow?year=${year}`);
};

export const getSummaryData = async () => {
  return axiosInstance.get(`${domain}/accounts/summary_data`);
};

export const getInventoryList = async () => {
  return axiosInstance.get(`${domain}/accounts/inventory_list`);
};

export const getClients = () => {
  return axios.get(`https://nividweb.axiossoftwork.com/api/ourclient`);
};

export const getDistrictList = (provinceid) => {
  return axios.get(
    `https://nividweb.axiossoftwork.com/api/axios/filter_province?province=${provinceid}`
  );
};

export const getSchoolList = () => {
  return axios.get(`https://nividweb.axiossoftwork.com/api/axios/school_types`);
};

export const postSearchlist = (data) => {
  return axios.post(
    `https://nividweb.axiossoftwork.com/api/axios/filter_school_data`,
    data
  );
};

export const getProvince = () => {
  return axios.get(
    `https://nividweb.axiossoftwork.com/api/axios/province_list`
  );
};
export const getMunicipalityList = (districid) => {
  return axios.get(
    `https://nividweb.axiossoftwork.com/api/axios/filter_district?district=${districid}`
  );
};
export const gradeApiForAssignment = () => {
  return axiosInstance.get(`${domain}/gradeApiForAssignment`, { headers });
};

export const getEmployeeData = (selectedId) => {
  // return axios.get(`${domain}/get_manager_childern_api`.values);
  return axios.get(`${domain}/get_manager_childern_api?user_id=${selectedId}`, {
    headers,
  });
};

export const getTeacherList = () => {
  return axios.get(`${domain}/teacher_listV2`, {
    headers,
  });
};

export const LessonPlanStatus = (gradeToken, subjectToken, dateToken) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/check_lesson_plan_flag_unit_api?grade_token=${gradeToken}&subject_token=${subjectToken}&day_token=${dateToken}`
  );
};
export const getHodMarkApproval = () => {
  return axiosInstance.get(`${domain}/exam_approval_api`, { headers });
};

export const getHodMeeting = (group, date) => {
  return axiosInstance.get(
    `${domain}/teacher_notice_api?group_name=${group}&date=${date}`,
    { headers }
  );
};

export const getHodRemarkData = (gradeToken, date) => {
  return axiosInstance.get(
    `${domain}/get_remarks_data?gradeToken=${gradeToken}&date=${date}`,
    { headers }
  );
};

export const getHodTeacherActivity = (token, date) => {
  return axiosInstance.get(
    `${domain}/teacher_activity_data?gradeToken=${token}&date=${date}`,
    { headers }
  );
};

export const getHodTeacherActivityData = () => {
  return axiosInstance.get(`${domain}/teacher_activity_data`, { headers });
};

export const getHodTeacherAttendence = () => {
  return axiosInstance.get(`${domain}/teacher_attendance_api`);
};

export const getHodMeetingGroup = () => {
  return axiosInstance.get(`${domain}/meeting_groups`, { headers });
};

export const getHodRemarksPopData = () => {
  return axiosInstance.get(`${domain}/get_remark_data`, { headers });
};

export const getHodRemarksPopValues = (id) => {
  return axiosInstance.get(
    `${domain}/remark_tables/subjectAndValueByRemarkType?remark_token=${id}`,
    { headers }
  );
};

export const getStudentListForRemark = () => {
  return axiosInstance.get(`${domain}/teacherApi/getStudentListForRemark`);
};

export const getSubjectValueFromRemark = (id) => {
  return axiosInstance.get(
    `${domain}/teacherApi/subjectAndValueByRemarkType/${id}`,
    {
      headers,
    }
  );
};

export const filterStudentByGrade = (gradeToken, dateToken, remarkToken) => {
  let api = `${domain}/api/v2/teacher_api/fetch_remarked_students?grade_token=${gradeToken}&day_token=${dateToken}&remark_type_token=${remarkToken}`;

  return axiosInstance.get(api);
};

export const getStudentRemark = (id, month) => {
  return axiosInstance.get(
    `${domain}/student_api/get_student_remarks?student_id=${id}&month=${month} `
  );
};

export const getTeacherRoutine = () => {
  return axiosInstance.get(`${domain}/teacher_api/teacher_period_routine`);
};
export const gradeApi = () => {
  return axiosInstance.get(`${domain}/gradeApiForAssignment`);
};
export const subjectApi = (gradeId) => {
  return axiosInstance.get(`${domain}/subjectApi?gradeToken=${gradeId}`);
};

export const LessonPlanList = (id) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_details?teacher_plan_id=${id}`
  );
};
export const getCommentActivity = (token) => {
  return axiosInstance.get(
    `${domain}/get_activity_comment?activity_id=${token}`
  );
};

export const getDashboardAssignments = () => {
  return axios.get(`${domain}/dashboard_api/v1/assignments`);
};

export const getParentList = () => {
  return axios.get(`${domain}/student_api/parent_list`);
};

export const getStudentList = () => {
  return axiosInstance.get(`${domain}/student_data/get_all_student_list`);
};
{
  /* Students feedback*/
}
export const getFeedback = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/feedback`);
};
export const getUnitApi = (gradeId, subjectId, month, isSection) => {
  let apiUrl = `${domain}/api/v2/teacher_api/get_subject_wise_plans?grade_token=${gradeId}&subject_token=${subjectId}&month=${month}`;

  if (isSection) {
    apiUrl += `&classAlpha=true`;
  }
  return axiosInstance.get(apiUrl);
};

export const getLessonPlanDetails = (lessonId) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/lesson_plan_details?lesson_plan_id=${lessonId}`
  );
};

export const getClassAlpha = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/class_alpha `);
};

export const getTeacherSubjectApi = (gradeId) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/subjectApi?gradeToken=${gradeId}`
  );
};

export const getClassAlphaHod = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/class_alpha?hod=true`);
};

export const getTeacherSubjectApiHod = (gradeId) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/subjectApi?hod=true&gradeToken=${gradeId}`
  );
};

export const getUnitApiHod = (gradeId, subjectId, month) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/get_subject_wise_plans?grade_token=${gradeId}&subject_token=${subjectId}&month=${month}`
  );
};

export const getsubjectApi = () => {
  return axiosInstance.get(`${domain}/subjectApi`);
};

export const getAllSubjectForExam = () => {
  return axiosInstance.get(`${domain}/examSubjectApi`);
};

export const getHodSubjectList = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/get_hod_subjects`);
};

export const getTeacherData = () => {
  return axiosInstance.get(`${domain}/teacherApi/getTeacherData/`);
};
export const getHodTeacherList = () => {
  return axiosInstance.get(`${domain}/hod_teacher_list?hod=true`);
};

export const getTeacherPlanProfileAttendance = (gradeToken, subjectToken) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_portfolio?gradeToken=${gradeToken}&subjectToken=${subjectToken}&route_filter=attendance_query`
  );
};

export const getTeacherPlanProfileStudentScore = (
  gradeToken,
  subjectToken,
  month
) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_portfolio?gradeToken=${gradeToken}&subjectToken=${subjectToken}&month=${month}&route_filter=student_scores`
  );
};

export const getTeacherPlanProfileAssessmentStatus = (
  gradeToken,
  subjectToken,
  filterType
) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_portfolio?gradeToken=${gradeToken}&subjectToken=${subjectToken}&filter_type=${filterType}&route_filter=assignment_status`
  );
};

export const getTeacherPlanProfileRecentAssessment = (
  gradeToken,
  subjectToken,
  dayToken
) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_portfolio?gradeToken=${gradeToken}&subjectToken=${subjectToken}&dayToken=${dayToken}&route_filter=recent_assessments`
  );
};

export const getTeacherPlanClassProgress = (gradeToken, subjectToken) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_portfolio?gradeToken=${gradeToken}&subjectToken=${subjectToken}&route_filter=class_progress`
  );
};

export const getTeacherPlanProfileSchedules = (
  gradeToken,
  subjectToken,
  filterType
) => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_plan_portfolio?gradeToken=${gradeToken}&subjectToken=${subjectToken}&filter_type=${filterType}&route_filter=schedules`
  );
};

export const getTeacherFreeSchedule = (data) => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/free_schedule`, {
    params: data,
  });
};

export const getParentAppointments = () => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/appointments?for=parent`
  );
};

export const getAppointmentTeacherList = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/teacher_list`);
};

export const getAppointmentTeacherView = () => {
  return axios.get(`${domain}/api/v2/teacher_api/appointments?for=teacher`, {
    headers,
  });
};

export const getNotInYet = () => {
  return axiosInstance.get(`${domain}/not_in_yet`);
};

export const getPrevClass = (year) => {
  return axiosInstance.get(
    `${domain}/class_transfer/previous_class?year=${year}`
  );
};
export const getFoodMenu = () => {
  return axiosInstance.get(`${domain}/canteen/menu_list`);
};

export const getStudentTable = (year, oldClass) => {
  return axiosInstance.get(
    `${domain}/class_transfer/classSection_transfer?year=${year}&oldClass=${oldClass}`
  );
};

export const getStudentInfo = (selectedGrade, admin) => {
  let apiUrl = `${domain}/teacherApi/student_list_for_class_teacher`;
  if (admin) {
    apiUrl += `?grade_token=${selectedGrade}`;
  }
  return axiosInstance.get(apiUrl);
};
export const getFoodMenuList = () => {
  return axiosInstance.get(`${domain}/food_schedule_api`);
};

export const getTeacherProfileUpdater = () => {
  return axiosInstance.get(`${domain}/teacherApi/teacher_profile_api`);
};

export const getSubjectData = () => {
  return axiosInstance.get(`${domain}/api/v2//teacher_api/get_subjects_data`);
};

export const getProxyClass = () => {
  return axiosInstance.get(`${domain}/get_proxy_class`);
};

export const getRouteList = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/routes_list`);
};

export const getRouteViewList = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/user_routes_list`);
};
export const getCanteenMenu = () => {
  return axios.get(`${domain}/canteen/menu_list`);
};

export const getEditStFeedback = (id) => {
  return axiosInstance.get(`${domain}/edit_student_feedback?fid=${id}`);
};

export const getEvaluationUnitApi = (gradeId, subjectId) => {
  let apiUrl = `${domain}/api/v2/teaching_plan_evaluation/lessonplan_subject_wise_plans?grade_token=${gradeId}&subject_token=${subjectId}`;
  return axiosInstance.get(apiUrl);
};

export const getTeachingEvaluationList = (
  gradeToken,
  subjectToken,
  selectedUnit
) => {
  return axiosInstance.get(
    `${domain}/api/v2/teaching_plan_evaluation/evaluation?grade_token=${gradeToken}&subject_token=${subjectToken}&unit_token=${selectedUnit}`
  );
};

export const getStudentEvaluationData = (evaluationToken) => {
  return axiosInstance.get(
    `${domain}/api/v2/teaching_plan_evaluation/evalution_data?evalutation_meta_token=${evaluationToken}`
  );
};

export const getChatList = () => {
  return axiosInstance.get(
    `${domain}/api/v2/message_api/list_chats?fromParentApp=false`
  );
};

export const getStudentAttendanceLog = (id, month) => {
  return axiosInstance.get(
    `${domain}/student_attendance_details?student_id=${id}&month=${month}`
  );
};
export const getAvailableResources = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/resources`);
};

export const getTeacherAttendancedata = () => {
  return axiosInstance.get(
    `${domain}/api/v2/teacher_api/teacher_attendance_data`
  );
};
export const getTeacherStudentAttendance = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/class_attendance`);
};

export const getCCTV = () => {
  return axiosInstance.get(`${domain}/api/v2/cctv/list`);
};

export const getHistory = (data) => {
  return axiosInstance.get(
    `${domain}/audit_log?table_name=${data.name}&id=${data.id}`
  );
};

export const getAttendanceRegularization = () => {
  return axiosInstance.get(`${domain}/api/v2/teacher_api/attendance_approval`);
};
