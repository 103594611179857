import React, { useEffect, useMemo, useState } from "react";
import "./TeacherProfileUpdater.scss";
import Select from "react-select";
import { getGradeApi, getTeacherProfileUpdater } from "../Api/GetApi";
import axios from "axios";
import { Formik, Form, FieldArray, Field } from "formik";
import * as Yup from "yup";
import CustomField from "./CustomFIeld";
import { postTeacherProfileUpdater } from "../Api/PostApi";
import { toast } from "react-toastify";
import { NoDataContent } from "../NoDataContent/NoDataContent";

const TeacherProfileUpdater = () => {
  // const { values, isValid, setSubmitting } = useFormikContext();
  // console.log("formikValues", values)
  const [refetch, setRefetch] = useState(false);
  // const [teacherData, setTeacherData] = useState([]);
  const [teacherData, setTeacherData] = useState({
    teacher_data: { data: [] },
    metadata: {},
  });
  const validate = Yup.object().shape({
    teachers: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email("Invalid email address")
          .notRequired()
          .nullable(),
        teacher_email: Yup.string()
          .email("Invalid teacher email address")
          .notRequired()
          .nullable(),
        contactNo: Yup.string()
          .matches(
            /^[0-9]{8,10}$/,
            "Contact number must be 8 to 10 digits long"
          )
          .required("Contact number is required"),
      })
    ),
  });

  // useEffect(() => {
  //   (async () => {
  //     const res = await getGradeApi();
  //     if (res.status === 200) {
  //       setGradeData(res?.data?.grade);
  //     } else {
  //       setGradeData([]);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      const res = await getTeacherProfileUpdater();
      if (res.status === 200) {
        setTeacherData(res?.data?.data);
      } else {
        setTeacherData({ teacher_data: { data: [] }, metadata: {} });
      }
    })();
  }, [refetch]);

  // const gradeSelectValue = useMemo(() => {
  //   if (gradeData?.length !== 0) {
  //     const gradeOption = gradeData?.map((item) => {
  //       return {
  //         value: item?.id,
  //         label: item?.classAlpha,
  //       };
  //     });
  //     setSelectedGrade(gradeOption?.[0]?.value);
  //     return gradeOption;
  //   }
  // }, [gradeData]);

  function compareTeachers(item1, item2) {
    const { updated_at: _, ...item1WithoutUpdatedAt } = item1;
    const { updated_at: __, ...item2WithoutUpdatedAt } = item2;
    return (
      JSON.stringify(item1WithoutUpdatedAt) ===
      JSON.stringify(item2WithoutUpdatedAt)
    );
  }

  const handleSubmit = (values) => {
    const initialTeacherData = teacherData?.teacher_data?.data;
    let indices = [];
    // values &&
    //   values?.teachers?.forEach((item, index) => {
    //     const prevItem = initialTeacherData[index];
    //     if (JSON.stringify(item) !== JSON.stringify(prevItem)) {
    //       indices.push(item);
    //     }
    //   });

    if (values) {
      values?.teachers?.reduce((acc, item, index) => {
        if (!compareTeachers(item, initialTeacherData[index])) {
          indices.push(item);
        }
      }, []);
    }

    // console.log("indices", indices);
    // Handle the changed values

    if (indices?.length === 0) {
      return;
    } else {
      const postData = {
        teacher_data: {
          data: indices,
        },
      };

      postTeacherProfileUpdater(postData).then((res) => {
        if (res.status === 200) {
          toast("Data Updated Successfully");
        } else {
          toast("Failed to update data");
        }
      });

      setRefetch((prev) => !prev);
    }

    // setSubmitting(false);
  };

  return (
    <div className="teacher_profile_updater_container">
      {/* <div className="title-block header">
        <h1 style={{color:"2c2c2c"}} className="title">Teacher Profile Updater</h1>
      </div> */}
      <div className="teacher_profile_wrapper">
        {/* <div className="grade_drowpdown_wrapper">
          <Select
            placeholder="Select Grade"
            className="react-select select"
            options={gradeSelectValue}
            onChange={(selected) => setSelectedGrade(selected?.value)}
            defaultValue={gradeSelectValue?.[0]}
          />
        </div> */}
        <div className="table_container">
          {teacherData?.teacher_data?.data?.length === 0 ? (
            <div>
             
              <NoDataContent
                marginTop="100px"
                title="No Data Found"
                description="Data will be appeared when updated or fetched properly"
                image={require("../../images/emptyfile.png").default}
              />
            </div>
          ) : (
            <Formik
              initialValues={{ teachers: teacherData?.teacher_data?.data }}
              onSubmit={handleSubmit}
              validationSchema={validate}
              validateOnMount
              validateOnChange
            >
              {({
                values,
                isValid,
                validateField,
                errors,
                setFieldTouched,
              }) => (
                <Form>
                  <div style={{ height: "95vh", overflow: "auto" }}>
                    <FieldArray name="teachers">
                      {({ remove, push }) => (
                        <table>
                          <thead>
                            <tr>
                              <th>S.No</th>
                              <th>Teacher Name</th>
                              <th>Gender</th>
                              <th>Caste</th>
                              <th>Nationality</th>
                              <th>Mother Tongue</th>
                              <th>citizenship Number</th>
                              <th>Address</th>
                              <th>Email</th>
                              <th>Teacher Email</th>
                              <th>Contact Number</th>
                              <th>Rank</th>
                              <th>Designation</th>
                              <th>Is Manager</th>
                              <th>Blood Group</th>
                              <th>Father Name</th>
                              <th>Mother Name</th>
                              <th>DOB</th>
                              <th>Base Salary</th>
                              <th>Manager Code</th>
                              <th>Teaching Level</th>
                              <th>Major Subject</th>
                              <th>Class Teacher</th>
                              {/* <th>fingerprint id</th>
                              <th>device id</th> */}
                              <th>shift</th>
                              <th>teaching language</th>
                              <th>disability</th>
                            </tr>
                          </thead>
                          <tbody>
                            {values?.teachers.map((teacher, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <CustomField
                                    name={`teachers[${index}].teacherName`}
                                    index={index}
                                    keyName="teacherName"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>
                                <td>
                                  <Field
                                    name={`teachers[${index}].gender`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">Select Gender</option>
                                    {Object.values(
                                      teacherData?.metadata?.gender || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].gender` === item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    name={`teachers[${index}].caste`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">Select Caste</option>
                                    {Object.values(
                                      teacherData?.metadata?.cast || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].caste` == item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].nationality`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">Select Nationality</option>
                                    {Object.values(
                                      teacherData?.metadata?.nationality || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].nationality` ==
                                            item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].mother_tounge`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">
                                      Select Mother Tounge
                                    </option>
                                    {Object.values(
                                      teacherData?.metadata?.mother_tongue || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].mother_tounge` ==
                                            item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>

                                <td>
                                  <CustomField
                                    name={`teachers[${index}].citizenship_no`}
                                    index={index}
                                    keyName="citizenship_no"
                                    onBlur={() => {
                                      handleSubmit(values);
                                    }}
                                  />
                                </td>

                                <td>
                                  <CustomField
                                    name={`teachers[${index}].address`}
                                    index={index}
                                    keyName="address"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>

                                <td>
                                  <CustomField
                                    name={`teachers[${index}].email`}
                                    index={index}
                                    keyName="email"
                                    onBlur={() => {
                                      setFieldTouched(
                                        `teachers[${index}].email`
                                      );
                                      validateField(`teachers[${index}].email`);
                                      handleSubmit(values);
                                    }}
                                  />
                                </td>

                                <td>
                                  <CustomField
                                    name={`teachers[${index}].teacher_email`}
                                    index={index}
                                    keyName="teacher_email"
                                    onBlur={() => {
                                      setFieldTouched(
                                        `teachers[${index}].teacher_email`
                                      );
                                      validateField(
                                        `teachers[${index}].teacher_email`
                                      );
                                      handleSubmit(values);
                                    }}
                                  />
                                </td>

                                <td>
                                  {/* <Field
                                    type="text"
                                    name={`teachers[${index}].contactNo`}
                                  /> */}
                                  <CustomField
                                    name={`teachers[${index}].contactNo`}
                                    index={index}
                                    keyName="contactNo"
                                    onBlur={() => {
                                      setFieldTouched(
                                        `teachers[${index}].contactNo`
                                      );
                                      validateField(
                                        `teachers[${index}].contactNo`
                                      );
                                      handleSubmit(values);
                                    }}
                                  />
                                </td>

                                <td>
                                  {/* <Field
                                    type="text"
                                    name={`teachers[${index}].rank`}
                                  /> */}
                                  <CustomField
                                    name={`teachers[${index}].rank`}
                                    index={index}
                                    keyName="rank"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>

                                <td>
                                  <CustomField
                                    name={`teachers[${index}].designation`}
                                    index={index}
                                    keyName="designation"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>

                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].is_manager`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option
                                      selected={
                                        `teachers[${index}].is_manager` == true
                                      }
                                      value="true"
                                    >
                                      true
                                    </option>
                                    <option
                                      selected={
                                        `teachers[${index}].is_manager` == false
                                      }
                                      value="false"
                                    >
                                      false
                                    </option>
                                  </Field>
                                </td>

                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].blood_group`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">Select Blood Group</option>
                                    {Object.values(
                                      teacherData?.metadata?.blood_group || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].blood_group` ==
                                            item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  {/* <Field
                                    type="text"
                                    name={`teachers[${index}].father_name`}
                                  /> */}
                                  <CustomField
                                    name={`teachers[${index}].father_name`}
                                    index={index}
                                    keyName="father_name"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>
                                <td>
                                  <CustomField
                                    name={`teachers[${index}].mother_name`}
                                    index={index}
                                    keyName="mother_name"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>
                                <td>
                                  <CustomField
                                    name={`teachers[${index}].dob`}
                                    index={index}
                                    keyName="dob"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>

                                <td>
                                  <CustomField
                                    name={`teachers[${index}].baseSalary`}
                                    index={index}
                                    keyName="baseSalary"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].manager_code`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">
                                      Select Manager Code
                                    </option>
                                    {Object.values(
                                      teacherData?.metadata?.manager || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].manager_code` ==
                                            item?.id
                                          }
                                          value={item?.id}
                                        >
                                          {item?.teacherName}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  <CustomField
                                    name={`teachers[${index}].teachingLevel`}
                                    index={index}
                                    keyName="teachingLevel"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>
                                <td>
                                  <CustomField
                                    name={`teachers[${index}].major_subject`}
                                    index={index}
                                    keyName="major_subject"
                                    onBlur={() => handleSubmit(values)}
                                  />
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].class_teacher`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">
                                      Select Class Teacher
                                    </option>
                                    {Object.values(
                                      teacherData?.metadata?.grades || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].class_teacher` ==
                                            item?.id
                                          }
                                          value={item?.id}
                                        >
                                          {item?.value}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                {/* <td>
                                  <CustomField
                                    name={`teachers[${index}].fingerprint_id`}
                                    index={index}
                                    keyName="fingerprint_id"
                                    onBlur={() =>  handleSubmit(values,initialValu}
                                  />
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].deviceId`}
                                    component="select"
                                    onBlur={() =>  handleSubmit(values,initialValu}
                                  >
                                    <option value="">Select deviceId</option>
                                    {Object.values(
                                      teacherData?.metadata?.device_id || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].deviceId` ==
                                            item?.id
                                          }
                                          value={item?.id}
                                        >
                                          {item?.value}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td> */}
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].shiftToken`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">Select Shift</option>
                                    {Object.values(
                                      teacherData?.metadata?.shift || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].shiftToken` ==
                                            item?.id
                                          }
                                          value={item?.id}
                                        >
                                          {item?.value}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].teaching_langugage`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">
                                      Select Teaching Language
                                    </option>
                                    {Object.values(
                                      teacherData?.metadata
                                        ?.teaching_language || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].teaching_langugage` ==
                                            item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                                <td>
                                  <Field
                                    type="text"
                                    name={`teachers[${index}].disability`}
                                    component="select"
                                    onBlur={() => handleSubmit(values)}
                                  >
                                    <option value="">Select disability</option>
                                    {Object.values(
                                      teacherData?.metadata?.disability || {}
                                    )?.map((item, key) => {
                                      return (
                                        <option
                                          key={key}
                                          selected={
                                            `teachers[${index}].disability` ==
                                            item
                                          }
                                          value={item}
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                  </Field>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </FieldArray>
                  </div>

                  {/* <button
                    style={{ marginTop: "10px" }}
                    className="submit_btn"
                    type="submit"
                    onClick={() => {
                      if (!isValid) {
                        toast.error(
                          "Validation Error. Please check the form validation."
                        );
                      }
                    }}
                  >
                    Submit
                  </button> */}
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default TeacherProfileUpdater;
