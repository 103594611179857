import { ErrorMessage, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { PostStudentEvaluation, PostTeachingPlanEvaluation } from "../../../components/Api/PostApi";
import { portalDomain } from "../../../url";
import { useTeachingPlanMainStore } from "../../teachingPlanMain/store/teachingPlanMainStore";
import { validationSchema } from "./validationSchema";

const initialValues = {
  unit_token: null,
  grade_token: "",
  subject_token: null,
  lesson_token: null,
  evaluation_token: null,
  weightage: null,
};

export const useTeachingPlanEvaluationFormikWrapper = () => {
  const formik = useFormikContext();
  if (!formik) {
    throw new Error("Formik context must be used within a Formik");
  }
  return formik;
};

export const FormikWrapper = ({ children }) => {
  const {
    viewPage,
    submitStudentEvaluation,
    submitEvaluationPlan,
    setSubmitEvaluationPlan,
    setTeachingEvaluationList, 
    setSubmitStudentEvaluation, 
    setViewPage, 
    setGradeToken, 
    setSubjectToken, 
    setSelectedUnit, 
    setSubjectList, 
    setUnitList
  } = useTeachingPlanMainStore();
  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");

  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const handleSuccess = (toastMessage, resetForm) => {
    toast.success(toastMessage);
    resetForm();
  };

  const handleError = (error) => {
    console.log(error);
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    if (viewPage === "Evaluation List") {
      submitEvaluationPlan?.forEach((element) => {
        PostTeachingPlanEvaluation(element)
          .then((res) => {
            if (res.status === 204 || res.status === 200) {
              setSubmitEvaluationPlan([]);
              setTeachingEvaluationList([]);
              setGradeToken(0);
              setSubjectToken(null);
              setSelectedUnit(null);
              setSubmitStudentEvaluation([]);
              setViewPage("Evaluation List");
              setSubjectList([]); 
              setUnitList([]);
              handleSuccess(res?.data?.message, resetForm);
            }
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      });
    } else {
      const tempData = submitStudentEvaluation?.map((item) => {
        return {
          data: item?.data?.map((itm) => {
            return {
              student_token: itm?.student_token,
              mark: itm?.mark,
            };
          }),
          evaluation_meta_token: item?.evaluation_meta_token,
        };
      });
      tempData?.forEach((element) => {
        PostStudentEvaluation(element)
          .then((res) => {
            if (res.status === 204 || res.status === 200) {
              setSubmitStudentEvaluation([]);
              setViewPage("Evaluation List");
              setTeachingEvaluationList([]);
              setGradeToken(0);
              setSubjectToken(null);
              setSelectedUnit(null);
              setSubjectList([]); 
              setUnitList([]);
              handleSuccess(res?.data?.message, resetForm);
            }
          })
          .catch((err) => {
            handleError(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {children}
      </Formik>
    </>
  );
};

export const TeachingPlanEvaluationError = ({ name }) => {
  return <ErrorMessage name={name} component="div" className="error" />;
};
