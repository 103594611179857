import { useFormikContext } from "formik";
import { useEffect } from "react";
import CustomSelect from "./customSelect";
import { useExamMarksStore } from "../../store/examMarksStore";
import { useExamDataList, useGradeApiForExam } from "../store/useStore";
import { Checkbox } from "@material-ui/core";

export const FilterSection = () => {
  const examMarksState = useExamMarksStore((state) => state);
  const { setFieldValue, values } = useFormikContext();
  const { data: gradeList, fetchGradeApiForExam} = useGradeApiForExam();
  const { fetchExamDataList, data: examList} = useExamDataList();

  useEffect(() => {
    fetchExamDataList();
  }, []);

  useEffect(() => {
    fetchGradeApiForExam();
  }, []);

  useEffect(() => {
    setFieldValue("cas_eval", examList?.cas_eval);
  }, [examList]);

  const handleSelectChange = (option) => {
    examMarksState?.setSelectedOption([option]);
    // examMarksState?.setExamStudentData();
    setFieldValue("eval_subject_token", option.value);
  };

  useEffect(() => {
    const examSubjectToken = values?.internal_eval
      ? Number(values?.eval_subject_token)
      : Number(values?.subject_token);
    setFieldValue("exam_subject_token", examSubjectToken);
  }, [
    values?.eval_subject_token,
    values?.internal_eval,
    values?.subject_token,
  ]);

  const handleCheck = (check) => {
    examMarksState?.setIsBulkUpdate(check);
    if (check) {
      examMarksState?.setSubmitStudentData([]);
      examMarksState?.setSelectedOption(examMarksState?.examEvaluations);
      if (examMarksState?.examEvaluations?.length === 1) {
        setFieldValue(
          "eval_subject_token",
          examMarksState?.examEvaluations?.[0]?.value
        );
      }
    } else {
      examMarksState?.setSelectedOption([]);
      examMarksState?.setSubmitStudentData([]);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="input-field-block">
          <p className="content first-content">Exam Name</p>

          <select
            className="custom-select"
            value={values?.examToken}
            onChange={(e) => {
              setFieldValue("examToken", e.target.value);
              setFieldValue("subject_token", 0);
              examMarksState?.setExamSubjectList();
              examMarksState?.setSelectedOption();
            }}
          >
            <option value="">Select Exam Name</option>
            {examList?.exam_data?.map((item, index) => {
              return (
                <>
                  <option key={index} value={item.id}>
                    {item.examName}
                  </option>
                </>
              );
            })}
          </select>
        </div>
      </div>
      <div className="col-md-6 col-sm-6 pr-0">
        <div className="input-field-block">
          <p className="content first-content">Grade</p>
          <select
            className="custom-select"
            value={values?.gradeToken}
            onChange={(e) => {
              setFieldValue("gradeToken", e.target.value);
              setFieldValue("subject_token", 0);
              examMarksState?.setExamSubjectList();
              examMarksState?.setSelectedOption();
              examMarksState?.setExamStudentData();
            }}
          >
            <option value="">Select Grade</option>
            {gradeList?.map((item, index) => {
              return (
                <option key={index} value={item.id}>
                  {item.classAlpha}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="col-md-6 col-sm-6">
        <div className="input-field-block">
          <p className="content first-content">Subject</p>
          <select
            className="custom-select"
            value={values?.subject_token}
            onChange={(e) => {
              setFieldValue("subject_token", e.target.value);
              setFieldValue("internal_eval", false);
              setFieldValue("exam_subject_token", e.target.value);
              examMarksState?.setSelectedSubjectObject(e.target.value);

              examMarksState?.setSelectedOption();
              examMarksState?.setExamStudentData();
            }}
          >
            <option value="">Select Subject</option>
            {examMarksState?.examSubjectList?.map((item, index) => {
              return (
                <>
                  <option value={item.id}>{item.subjectName}</option>
                </>
              );
            })}
          </select>
        </div>
      </div>
      {values.cas_eval && (
        <div className="col-md-12">
          <fieldset className="switch">
            <legend>Exam Type</legend>
            <div className="slide-container">
              <input
                type="radio"
                name="account"
                id="mark_entry"
                onChange={() => {
                  setFieldValue("internal_eval", false);
                  setFieldValue("exam_subject_token", null);
                  setFieldValue("eval_subject_token", null);
                  examMarksState?.setSelectedOption();
                  examMarksState?.setExamStudentData([]);
                  examMarksState?.setSubmitStudentData([]);
                  examMarksState?.setIsBulkUpdate(false);
                }}
                checked={!values.internal_eval}
              />
              <label
                htmlFor="mark_entry"
                className={!values.internal_eval ? "selected" : ""}
              >
                Mark Entry
              </label>
              <input
                type="radio"
                name="account"
                id="internal_evaluation"
                onChange={() => {
                  setFieldValue("internal_eval", true);
                  examMarksState?.setSelectedOption();
                  examMarksState?.setSubmitStudentData([]);
                  examMarksState?.setExamStudentData([]);
                }}
                checked={values.internal_eval}
              />
              <label
                htmlFor="internal_evaluation"
                className={values.internal_eval ? "selected" : ""}
              >
                Internal Evaluation
              </label>
            </div>
          </fieldset>
        </div>
      )}
      {values.internal_eval && (
        <div className="col-md-12">
          <div className="input-field-block">
            <p className="content first-content">Evaluation Type</p>
            <div className="checkbox-section">
              <Checkbox
                id="bulk_select"
                onChange={(e) => handleCheck(e.target.checked)}
              />
              <label htmlFor="bulk_select">Select All Evaluation Type</label>
            </div>
            {!examMarksState.isBulkUpdate && (
              <CustomSelect
                options={examMarksState?.examEvaluations}
                onChange={handleSelectChange}
                selected={examMarksState?.selectedOption?.[0]}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
