import Chart from "react-apexcharts";
import { NoDataContent } from "../NoDataContent/NoDataContent";
const AreaChart = ({ student }) => {
  const examData = student?.exam_detail[0];
  const examNames = examData && Object.keys(examData);

  let subjectList = [];
  const marks = examNames?.map((examName, index) => {
    const subjects = examData[examName];
    const examMarks = subjects?.map((subject) => {
      const subjectName = Object?.keys(subject)?.[0];
      const marks = Object?.values(subject)?.[0];
      subjectList.push(subjectName);
      return parseFloat(marks);
    });
    const color = index === 0 ? "#29ABE2" : "#7E8AC7";
    return {
      name: examName,
      data: examMarks,
      color: color,
    };
  });

  const options = {
    chart: {
      id: "spline-area-chart",
    },
    xaxis: {
      categories: subjectList,
    },
    fill: {
      opacity: 0.5,
    },
    markers: {
      size: 5,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      position: "top",
    },
    title: {
      text: "Marksheet",
      style: {
        fontSize: "16px",
        fontFamily: "Nunito Sans",
        color: "#000000a6",
      },
    },
  };

  const chartSeries =
    marks?.filter((mark) => {
      return mark.data.some((value) => !isNaN(value) && value !== null);
    }) || [];

  if (chartSeries.length === 0) {
    return (
      <NoDataContent
        title="No Chart data found"
        image={require("../../images/emptyfile.png").default}
      />
    );
  }
  return (
    <div style={{ padding: "0", margin: "0" }}>
      <Chart
        options={options}
        series={chartSeries}
        type="area"
        height="397px"
      />
    </div>
  );
};

export default AreaChart;
