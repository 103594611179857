import { create } from "zustand";
import { devtools } from "zustand/middleware";

export const useTeacherProfile = create(
  devtools((set) => ({
    modalOpen: false,
    isTeacherComp: false, //for weather to display teacher profile in hod dashboard or not in the route
    setIsTeacherComp: (val) => set({ isTeacherComp: val }),
    setModalOpen: (val) => set({ modalOpen: val }),
    selectedModalData: [],
    setSelectedModalData: (val) => set({ selectedModalData: val }),

    selectedGrade: null,
    setSelectedGrade: (val) => set({ selectedGrade: val }),

    selectedSubject: null,
    setSelectedSubject: (val) => set({ selectedSubject: val }),

    assessmentModalData: {}, //for the single card data
    setAssessmentModalData: (val) => set({ assessmentModalData: val }),

    isUserAdmin: false,
    setIsUserAdmin: (val) => set({ isUserAdmin: val }),

    regularizeRequest: true,
    setIsRegularizedRequest: (val) => set({ regularizeRequest: val }),
  }))
);

export const academicStore = create(
  devtools((set) => ({
    modalVisible: false,
    setModalVisible: (val) => set({ modalVisible: val }),
    keyValue: null,
    setkeyValue: (val) => set({ keyValue: val }),
    academicId: {
      examSubject: [],
      subject: [],
      hodSubject: [],
      hodExamSubject: [],
    },
    handleId: (value, name) => {
      set((state) => ({
        academicId: {
          ...state.academicId,
          [name]: state.academicId[name].includes(value)
            ? state.academicId[name].filter((item) => item !== value)
            : [...state.academicId[name], value],
        },
      }));
    },
    setAcademicId: (val) => {
      set({ academicId: val });
    },
  }))
);
