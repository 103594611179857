import { Fab, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useExamMarksStore } from "../../store/examMarksStore";
import {
  getExamEvaluations,
  getExamRecords,
  getStudentsFromGrade,
  getSubjectApiForExam,
} from "../Api/GetApi";
import { AddExamMarks } from "./addExamMarks";
import "./examMarks.scss";
import { FilterSection } from "./filterSection";
import { ViewExamMarks } from "./viewExamMarks";
import NividLoader from "../NividLoader/NividLoader";
import { useExamDataList, useGradeApiForExam } from "../store/useStore";

export const ExamMarksDetails = () => {
  const examMarksState = useExamMarksStore((state) => state);
  const { values } = useFormikContext();
  const { loading: loadingExamData } = useExamDataList();
  const { loading: loadingGrade } = useGradeApiForExam();
  const [loadingSubject, setLoadingSubject] = useState(false);
  const [loadingExamEvaluation, setLoadingExamEvaluation] = useState(false);

  useEffect(() => {
    if (values?.exam_subject_token || values?.subject_token) {
      const examSubjectToken = values?.internal_eval
        ? values?.exam_subject_token
        : values?.subject_token;
      const subject_weightage = examMarksState?.examSubjectList?.find(
        (item) => item.id === Number(examSubjectToken)
      );
      examMarksState?.setSubjectWeightage(subject_weightage);
    }
  }, [
    values?.subject_token,
    values?.internal_eval,
    values?.exam_subject_token,
  ]);

  useEffect(() => {
    if (values?.examToken && values?.gradeToken) {
      const data = {
        examToken: values?.examToken,
        gradeToken: values?.gradeToken,
      };
      setLoadingSubject(true);
      getSubjectApiForExam(data).then((res) => {
        setLoadingSubject(false);
        examMarksState?.setExamSubjectList(res?.data?.subject);
        examMarksState?.setStrictFilter(res?.data?.strict_filter);
      });
    }
  }, [values?.gradeToken, values?.examToken]);

  useEffect(() => {
    const data = {
      gradeToken: values?.gradeToken,
      subjectToken: values?.subject_token,
      strictFilter: values?.strictFilter,
    };
    if (values?.gradeToken && values?.subject_token) {
      getStudentsFromGrade(data).then((res) => {
        examMarksState?.setStudentsList(res?.data);
      });
    }
  }, [values?.gradeToken, values?.subject_token]);

  useEffect(() => {
    if (values?.subject_token) {
      setLoadingExamEvaluation(true);
      getExamEvaluations(values?.subject_token).then((res) => {
        setLoadingExamEvaluation(false);
        examMarksState?.setExamEvaluations(res?.data);
      });
    }
  }, [values?.subject_token]);

  useEffect(() => {
    const fetchData = async () => {
      const examStudentsDataArray = [];

      if (examMarksState?.submitStudentData?.[0]?.exam_subject_token !== 0) {
        for (const element of examMarksState?.submitStudentData) {
          if (element?.exam_subject_token !== 0) {
            const data = {
              cas_eval: values?.cas_eval,
              internal_eval: values?.internal_eval,
              exam_subject_token: element?.exam_subject_token,
            };
            examMarksState?.setIsBulkUpdateLoading(true);
            try {
              const res = await getExamRecords(data);
              examMarksState?.setIsBulkUpdateLoading(false);
              const subArray = {
                data: res?.data.data,
                exam_subject_token: element?.exam_subject_token,
              };
              examStudentsDataArray.push(subArray);
            } catch (error) {
              console.error("Error fetching exam records:", error);
            } finally {
              examMarksState?.setIsBulkUpdateLoading(false);
            }
          }
        }
        if (
          examMarksState?.submitStudentData?.length ===
          examStudentsDataArray.length
        ) {
          examMarksState?.setExamStudentData(examStudentsDataArray);
        }
      }
    };

    fetchData();
  }, [
    examMarksState?.submitStudentData?.length,
    examMarksState?.submitStudentData?.[0]?.exam_subject_token,
  ]);

  useEffect(() => {
    const sortedStudents = examMarksState?.studentsList
      ?.sort((a, b) => a.rollNo - b.rollNo)
      .map((student) => ({
        student_token: student.id,
        rollNo: student.rollNo,
        studentName: student.studentName,
        theory_mark: 0,
      practical_mark: 0,
      }));

    if (examMarksState?.selectedOption?.length > 0) {
      const selectedOptionLength = examMarksState?.selectedOption?.length;
      const studentsDataArray =
        selectedOptionLength <= 1
          ? [
              {
                data: sortedStudents,
                exam_subject_token: values?.internal_eval
                  ? Number(values?.eval_subject_token)
                  : Number(values?.subject_token),
                cas_eval: values?.cas_eval,
                internal_eval: values?.internal_eval,
                grade_token: values?.gradeToken,
                exam_token: values?.examToken,
              },
            ]
          : Array(selectedOptionLength)
              .fill()
              .map((_, index) => ({
                data: sortedStudents,
                cas_eval: values?.cas_eval,
                internal_eval: values?.internal_eval,
                grade_token: values?.gradeToken,
                exam_token: values?.examToken,
                exam_subject_token:
                  examMarksState?.selectedOption[index]?.value,
              }));
      examMarksState?.setSubmitStudentData(studentsDataArray);
    } else {
      const temp = values?.internal_eval
        ? []
        : values?.subject_token !== 0 || values?.subject_token !== ""
        ? [
            {
              data: sortedStudents,
              exam_subject_token: Number(values?.subject_token),
              cas_eval: values?.cas_eval,
              internal_eval: values?.internal_eval,
              grade_token: values?.gradeToken,
              exam_token: values?.examToken,
            },
          ]
        : [];

      examMarksState?.setSubmitStudentData(temp);
    }
  }, [
    values?.internal_eval,
    examMarksState?.studentsList,
    examMarksState?.selectedOption,
    examMarksState?.isBulkUpdate,
  ]);

  return (
    <div className="body-section section-gap">
      <FilterSection />
      {loadingGrade ||
      loadingExamData ||
      loadingSubject ||
      loadingExamEvaluation ||
      examMarksState?.isBulkUpdateLoading ? (
        <NividLoader height="300px" />
      ) : (
        <>
          {examMarksState?.viewExamMarks ? (
            <>
              <ViewExamMarks />
              <div
                className="floating-action-item"
                onClick={() => {
                  examMarksState?.setViewExamMarks(false);
                }}
              >
                <Fab aria-label="add">
                  <i className="bx bx-plus"></i>
                </Fab>
              </div>
            </>
          ) : (
            <AddExamMarks />
          )}
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={examMarksState?.openSnackBar}
        autoHideDuration={3000}
        onClose={() => examMarksState?.setOpenSnackBar(false)}
      >
        {examMarksState?.snackStatus ? (
          <Alert severity="success">{examMarksState?.snackMessge}</Alert>
        ) : (
          <Alert severity="error">{examMarksState?.snackMessge}</Alert>
        )}
      </Snackbar>
    </div>
  );
};
