import "./button.scss";
const Button = ({ text, icon, className, onClick }) => {
  const IconName = icon;

  return (
    <button className={className} onClick={onClick}>
      <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
        <IconName style={{ fontSize: "20px" }} />
        {text}
      </div>
    </button>
  );
};

export default Button;
