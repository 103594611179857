import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import Calendar1 from "../../images/calendar 1(1).svg";
import Calendar from "../../images/calendar 1.svg";
import "./Card.scss";

import Modal from 'react-modal';
import TablerCheck from "../../images/tabler_calendar-check.svg";
import Exclamation from "../../images/tabler_calendar-exclamation.svg";
import Tablerx from "../../images/tabler_calendar-x.svg";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import AccordionPopup from "../AccountDashboardPopup/AcordionPopup";
import { getHodTeacherAttendence, getHodTeacherList } from "../Api/GetApi";
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: "95%",
    height: "95%",
    overFlowY: "auto",
  
  },
};

function Card() {
  const { setPopupType, popupType } = useTeacherDashboardStore();
  const [data, setData] = useState([]);
  const [totalTeacherData,setTotalTeacherData] = useState([])

  useEffect(() => {
    getHodTeacherAttendence().then((res) => {
      if (res.data.status === 1) {
        setData(res?.data?.data);
      } else {
        setData([]);
      }
    });

    getHodTeacherList().then((res) => {
      if (res.data.status === 1) {
        setTotalTeacherData(res?.data?.data);
      } else {
        setTotalTeacherData([]);
      }
    });
  }, []);

  let subtitle;



  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setPopupType("");
  }


  return (
    <div className="card_container">
      <div className="card_wrapper ">
      <Modal
        isOpen={popupType === "teachers"}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom:"10px",
          }}


        >
          <div
            style={{ fontSize: "24px", fontWeight: "500", color: "#000000" }}
          >
           Total Teacher
          </div>
          <div style={{ cursor: "pointer" }} onClick={closeModal}>
            <MdCancel size={24} />
          </div>
        </div>

        <AccordionPopup isTeacher={true} teacherData={totalTeacherData?.teachers} />


      
       
      </Modal>
        <div className="teacher_subject_card d-flex">
          <div className="top_inner_Card d-flex" style={{cursor:"pointer"}} onClick={()=> setPopupType("teachers")}>
            <div className="card-inner-lay">
              <p className="card-num">{data?.total_teacher || 0}</p>
              <p className="card-text">Total Teachers</p>
            </div>
            <img src={Calendar} className="image-img"></img>
          </div>
          <div  className="top_inner_Card d-flex">
            <div className="card-inner-lay">
              <p className="card-num">{data?.total_subjects || 0}</p>
              <p className="card-text">Total Subjects</p>
            </div>
            <img src={Calendar1} className="image-img"></img>
          </div>
        </div>
        <div className="teacher_attendance_container mt-3">
          <div>
            <h3 className="heading-text">Today’s Teacher Attendance</h3>
          </div>
          <div className="teacher_container_card_wrapper d-flex mt-3">
            <div className="first-card">
              <div className="icon">
                <div className="icon_wrapper_first">
                  <img src={TablerCheck} className="m-2"></img>
                </div>
              </div>
              <div className="inner-layout">
                <div className="num-layout">
                  <p className="num mt-3">{data?.total_teacher_present || 0}</p>
                </div>
                <div className="text-lay">
                  <p className="text-name mt-1">Total Present</p>
                </div>
              </div>
            </div>

            <div className="second-card">
              <div className="icon">
                <div className="icon_wrapper_second">
                  <img src={Tablerx} className="m-2"></img>
                </div>
              </div>
              <div className="inner-layout">
                <div className="num-layout">
                  <p className="num mt-3">{data?.total_absent || 0}</p>
                </div>
                <div className="text-lay">
                  <p className="text-name mt-1">Total Absent</p>
                </div>
              </div>
            </div>
            <div className="third-card">
              <div className="icon">
                <div className="icon_wrapper_third">
                  <img src={Exclamation} className="m-2"></img>
                </div>
              </div>
              <div className="inner-layout">
                <div className="num-layout">
                  <p className="num mt-3">{data?.total_late || 0}</p>
                </div>
                <div className="text-lay">
                  <p className="text-name mt-1">Total Late</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Card;
