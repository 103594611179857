export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const handleEnterKey = (e, nextInputId) => {
  if (e.key === "Enter") {
    e.preventDefault(); // Prevent default form submission behavior
    const nextInput = document.getElementById(nextInputId);
    if (nextInput) {
      nextInput.focus(); // Move focus to the next input field
    }
  }
};
