import "./studentInfo.scss";
import Select from "react-select";
import React, { useEffect, useMemo, useState } from "react";
import { useTeacherProfile } from "../../../store/teacherProfileStore";
import useFetchData from "../../../utils/useFetchData";
import {
  getGradesfromAssignment,
  getSubjectApiStrictFilter,
  getTeacherData,
  getTeacherPlanClassProgress,
} from "../../Api/GetApi";

const studentData = {
  studentName: "Kriti Tamang",
  class: "Five",
  section: "A",
  rollNo: 3,
  location: "Banepa",
  district: "Kavrepalanchowk",
  attandanceList: [
    {
      label: "Total Present",
      value: 13,
      colorCode: "green",
    },
    {
      label: "Total Absent",
      value: 5,
      colorCode: "red",
    },
    {
      label: "Total Late",
      value: 8,
      colorCode: "orange",
    },
    {
      label: "Total Leave",
      value: 10,
      colorCode: "purple",
    },
  ],
};
const teacherData = {
  teacherName: "Nishan Sakha",
  class: "Five",
  section: "A",
  rollNo: 3,
  location: "Banepa",
  district: "Kavrepalanchowk",
  attandanceList: [
    {
      label: "Total Lesson Plan",
      value: 13,
      colorCode: "green",
    },
    {
      label: "Total Completed",
      value: 5,
      colorCode: "red",
    },
    {
      label: "Total Missed",
      value: 8,
      colorCode: "orange",
    },
    {
      label: "Total Pending",
      value: 10,
      colorCode: "purple",
    },
  ],
};

export const StudentProfile = ({ isTeacher }) => {
  const {
    selectedGrade,
    setSelectedGrade,
    setSelectedSubject,
    selectedSubject,
  } = useTeacherProfile();

  const [statusData, setStatusData] = useState();
  const [teacherInfo, setTeacherInfo] = useState({});

  const [gradeData, setGradeData] = useState([]);

  const [subjectData, setSubjectData] = useState([]);

  const colorSwitch = (val) => {
    switch (val) {
      case "completed":
        return "green";
        break;
      case "pending":
        return "orange";
        break;
      case "ongoing":
        return "purple";
        break;
      case "overdue":
        return "red";
        break;
      default:
        return "purple";
    }
  };

  useEffect(() => {
    (async () => {
      const res = await getTeacherData();
      if (res.status === 200) {
        setTeacherInfo(res?.data);
      } else {
        setTeacherInfo({});
      }
    })();
    (async () => {
      const res = await getGradesfromAssignment();
      if (res.status === 200) {
        setGradeData(res?.data?.grade);
      } else {
        setGradeData([]);
      }
    })();
  }, []);

  const gradeSelectValue = useMemo(() => {
    if (gradeData?.length !== 0) {
      const gradeOption = gradeData?.map((item) => {
        return {
          value: item?.id,
          label: item?.classAlpha,
        };
      });
      setSelectedGrade(gradeOption?.[0]?.value);
      return gradeOption;
    }
  }, [gradeData]);

  useEffect(() => {
    if (selectedGrade) {
      (async () => {
        const res = await getSubjectApiStrictFilter(selectedGrade);
        if (res.status === 200) {
          setSubjectData(res?.data?.subject);
        } else {
          setSubjectData([]);
        }
      })();
    }
  }, [selectedGrade]);

  const subjectSelectValue = useMemo(() => {
    if (subjectData?.length !== 0) {
      const subjectOption = subjectData?.map((item) => {
        return {
          value: item?.id,
          label: item?.subjectName,
        };
      });
      setSelectedSubject(subjectOption?.[0].value);
      return subjectOption;
    }
  }, [subjectData]);

  useEffect(() => {
    if (
      selectedGrade !== undefined &&
      selectedGrade !== null &&
      selectedSubject !== undefined &&
      selectedSubject !== null
    ) {
      const apiStatus = async () => {
        const res = await getTeacherPlanClassProgress(
          selectedGrade,
          selectedSubject
        );

        if (res.status === 200) {
          if (res.data.status === 1) {
            setStatusData(res?.data?.data?.unit_wise);
          } else {
            setStatusData([]);
          }
        } else {
          setStatusData([]);
        }
      };

      apiStatus();
    }
  }, [selectedGrade, selectedSubject]);

  return (
    <div className="student-info">
      <div className="student-info-top-section-wrapper">
        <div className="student-info-top-section">
          <img
            src={isTeacher ? teacherInfo?.imageUrl : " " || ""}
            alt="profile-img"
          />
          <div className="student-info-top-block">
            <h4 className="title">
              {isTeacher
                ? teacherInfo?.teacherName
                : studentData?.studentName || ""}
            </h4>
            {isTeacher ? (
              <div className="student-info-content-block">
                <p className="medium-content">
                  Class teacher of &nbsp;
                  <span>{teacherInfo?.class_teacher_class || ""}</span>
                </p>
              </div>
            ) : (
              <div className="student-info-content-block">
                <p className="medium-content">
                  Class:{" "}
                  <span>
                    {studentData?.class} {`'${studentData?.section}'`}
                  </span>
                </p>
                <div className="dot" />
                <p className="medium-content">
                  Roll No.: <span> {studentData?.rollNo}</span>
                </p>
              </div>
            )}
            <div className="student-info-content-block">
              <i class="bx bx-location-plus"></i>
              <p className="content">
                {isTeacher ? teacherInfo?.address : "" || ""}
              </p>
            </div>
          </div>
        </div>
        <div className="subject_grade_section">
          <Select
            placeholder="Select Grade"
            className="react-select select"
            options={gradeSelectValue}
            onChange={(selected) => setSelectedGrade(selected?.value)}
            defaultValue={gradeSelectValue?.[0]}
          />
          <Select
            placeholder="Select Subject"
            className="react-select select"
            options={subjectSelectValue}
            onChange={(selected) => setSelectedSubject(selected.value)}
            defaultValue={subjectSelectValue?.[0]}
            classNames="select"
          />
        </div>
      </div>

      <div className="student-info-number-section">
        {isTeacher
          ? statusData?.map((item, index) => {
              return (
                <div className="student-info-number-block" key={index}>
                  <p
                    className={`medium-content ${colorSwitch(
                      item?.status?.toLowerCase()
                    )} `}
                  >
                    {item?.status}:
                  </p>
                  <h4 className="number">{item?.total}</h4>
                </div>
              );
            })
          : studentData?.attandanceList?.map((item, index) => {
              return (
                <div className="student-info-number-block" key={index}>
                  <p className={`medium-content ${item?.colorCode} `}>
                    {item?.label}:
                  </p>
                  <h4 className="number">{item?.value}</h4>
                </div>
              );
            })}
      </div>
    </div>
  );
};
