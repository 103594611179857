import Axios from "axios";
import { toast } from "react-toastify";
import ProgressBar from "@ramonak/react-progress-bar";

import noData from "../../images/no-data.svg";
import "./AttendanceProgress.scss";
import { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import { domain } from "../../url";

export const AttendanceProgress = (props) => {
  return (
    <div className="progressbar-section">
      {props?.assignment?.length === 0 ? (
        <div className="no-data">
          <div className="no-img">
            <img src={noData} alt="no-data" />
          </div>
          <div className="no-data-content">
            No {props?.classwork ? "Classworks" : "Assignment"} available at the
            moment
          </div>
        </div>
      ) : (
        <div className="scroll-box">
          {props?.assignment?.map((item, index) => {
            return (
              <div className="blue-data-box">
                <div className="d-space-between" key={index}>
                  <p className="small-content title-color">
                    {item?.class_name}
                  </p>
                  <div
                    href={`/${
                      props?.classwork ? "classwork" : "assingnment_tables"
                    }?grade_name=${item?.class_name}`}
                    onClick={() => {
                      if (props?.classwork) {
                        window.open(
                          `${domain}/classwork?dayToken=${item?.dayToken}&gradeToken=${item?.gradeToken}`,
                          "_blank"
                        );
                      } else {
                        window.open(
                          `${domain}/assingnment_tables?dayToken=${item?.dayToken}&gradeToken=${item?.gradeToken}`,
                          "_blank"
                        );
                      }
                    }}
                  >
                    <RemoveRedEyeIcon />
                  </div>
                </div>
                <div>
                  <div className="progress-bar-slider">
                    <ProgressBar
                      completed={item?.provided?.split("/")[0]}
                      maxCompleted={item?.provided?.split("/")[1]}
                      height="8px"
                      className="percent"
                      isLabelVisible={false}
                      margin="10px 0"
                      bgColor="#29ABE2"
                    />
                    <p className="small-content label-color label-indicator">
                      {item?.provided}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
