import { create } from "zustand";

export const useExamMarksStore = create((set) => ({
  viewExamMarks: true,
  setViewExamMarks: (val) => set({ viewExamMarks: val }),
  strictFilter: false,
  setStrictFilter: (val) => set({ strictFilter: val }),
  // gradeToken: 0,
  // setGradeToken: (val) => set({ gradeToken: val }),
  // examToken: 0,
  // setExamToken: (val) => set({ examToken: val }),
  // subjectToken: 0,
  // setSubjectToken: (val) => set({ subjectToken: val }),
  // examSubjectToken: 0,
  // setExamSubjectToken: (val) => set({ examSubjectToken: val }),
  examSubjectList: [],
  setExamSubjectList: (val) => set({ examSubjectList: val }),
  examEvaluations: [],
  setExamEvaluations: (val) => set({ examEvaluations: val }),
  selectedOption: [],
  setSelectedOption: (val) => set({ selectedOption: val }),
  studentsList: [],
  setStudentsList: (val) => set({ studentsList: val }),
  openSnackBar: false,
  setOpenSnackBar: (val) => set({ openSnackBar: val }),
  snackMessage: "",
  setSnackMessge: (val) => set({ snackMessge: val }),
  snackStatus: true,
  setSnackStatus: (val) => set({ snackStatus: val }),
  subjectWeightage: 0,
  setSubjectWeightage: (val) => set({ subjectWeightage: val }),
  weightageTheory: null,
  setWeightageTheory: (val) => set({ weightageTheory: val }),
  weightagePractical: null,
  setWeightagePractical: (val) => set({ weightagePractical: val }),
  examStudentData: [],
  setExamStudentData: (val) => set({ examStudentData: val }),
  isBulkUpdate: false,
  setIsBulkUpdate: (val) => set({ isBulkUpdate: val }),
  isBulkUpdateLoading: false,
  setIsBulkUpdateLoading: (val) => set({ isBulkUpdateLoading: val }),
  submitStudentData: [],
  setSubmitStudentData: (val) => set({ submitStudentData: val }),
  selectedSubjectObject: null,
  setSelectedSubjectObject: (val) => set({ selectedSubjectObject: val }),
}));
