import React from "react";
import "./mealPlan.scss";
import Modal from "react-awesome-modal";
import { domain } from "../../../url";
import PreLoader from "../../preLoader/preLoader";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import MealPopUp from "../mealPopUp/mealPopUp";
import { NoDataContent } from "../../NoDataContent/NoDataContent";
import axiosInstance from "../../AxiosInceptor/AxiosInstance";

class MealPlan extends React.Component {
  state = {
    visible: false,
    modalWidth: "50%",
    food: [],
    checkedfoods: [],
    remarkValue: "",
    finalFoods: [],
    successsMessage: false,
    loading: false,
    student: [],
    studentValue: "",
    jwtToken: "",
    isLoading: false,
    snackMessge: "",
    openSnackBar: false,
  };

  componentDidMount() {
    this.getModalWidth();
    this.fetchApi();
  }

  getModalWidth = () => {
    var windowWidth = window.innerWidth;
    var modalWidth = "50%";
    if (windowWidth <= 320) {
      modalWidth = "90%";
    } else if (windowWidth <= 480) {
      modalWidth = "90%";
    }
    this.setState({
      modalWidth,
    });
  };

  openModal() {
    if (this.state.studentValue === "" && "select") {
      this.setState({
        openSnackBar: true,
        snackMessge: "Please select student",
      });
    } else {
      this.setState({
        visible: true,
      });
    }
  }

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  studentChange = (e) => {
    this.setState({
      studentValue: e.target.value,
    });
  };

  checkChange = (e, fooditem) => {
    var tempcheckedfoods = this.state.checkedfoods;
    if (e.target.checked) {
      tempcheckedfoods.push(fooditem);
      this.setState({
        checkedfoods: tempcheckedfoods,
      });
    } else {
      var filteredcheckedfoods = tempcheckedfoods.filter(function (
        value,
        index,
        arr
      ) {
        return value.id !== fooditem.id;
      });
      this.setState({
        checkedfoods: filteredcheckedfoods,
      });
    }

    this.setState({
      remarkValue: e.target.value,
    });
  };

  fetchApi = async () => {
    this.setState({
      isLoading: true,
    });
    var res = await axiosInstance.get(`${domain}/todayFoodList`);

    this.setState({
      food: res?.data.food,
      student: res?.data.student,
      jwtToken: res?.config.headers.Authorization,
    });
    this.setState({
      isLoading: false,
    });
  };

  postApi = async () => {
    this.state.checkedfoods.forEach((item, i) =>
      this.state.finalFoods.push(item.id)
    );

    var postdata = {
      studentToken: this.state.studentValue,
      foodMenuToken: this.state.finalFoods,
    };

    this.setState({
      loading: true,
    });
    var { data } = await axiosInstance.post(
      `${domain}/foodTransaction`,
      postdata
    );
    if (data.status === 11) {
      this.setState({
        successsMessage: true,
        loading: false,
      });
    } else {
      alert("fail");
    }
  };

  meal = () => {
    return (
      <>
        {this.state.isLoading ? (
          <PreLoader />
        ) : (
          <>
            {this.state.jwtToken === "null" || this.state.jwtToken === "" ? (
              <NoDataContent
                title="No data Found"
                description={"Looks like , data is missing"}
              />
            ) : (
              <div className="meal-plan">
                <div
                  className="title-block header center
                "
                >
                  {/* <i className="bx bxs-chevron-left"></i> */}
                  <h2 className="title">Choose Meal Plan</h2>
                </div>
                <div className="container">
                  <div className="meal-plan-area">
                    <select
                      className="input-field"
                      value={this.state.studentValue}
                      onChange={this.studentChange}
                    >
                      <option value="select">Select</option>
                      {this.state.student.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.studentName}
                          </option>
                        );
                      })}
                    </select>
                    {Object.keys(this.state.food).map((item, index) => {
                      return (
                        <div className="meal-plan-section" key={index}>
                          <h4 className="block-title">{item}</h4>
                          {this.state.food[item].map((fooditem, index) => {
                            return (
                              <div className="meal-plan-block" key={index}>
                                <div className="content-block">
                                  <div className="checkbox">
                                    <input
                                      type="checkbox"
                                      id={"checkbox" + fooditem.id}
                                      checked={this.state.value}
                                      onChange={(e) =>
                                        this.checkChange(e, fooditem)
                                      }
                                    />
                                    <label htmlFor={"checkbox" + fooditem.id}>
                                      <p className="content">{fooditem.name}</p>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </div>
                  {/* <div className="meal-plan-section">
            <h4 className="block-title">Remark</h4>
            <textarea
              className="text-field"
              placeholder="Add remark"
              value={this.state.remarkValue}
              onChange={this.checkChange}
            />
          </div> */}

                  <button className="main-btn" onClick={() => this.openModal()}>
                    Confirm
                  </button>
                  <Snackbar
                    open={this.state.openSnackBar}
                    autoHideDuration={1000}
                    onClose={() => this.setState({ openSnackBar: false })}
                  >
                    <Alert severity="success">{this.state.snackMessge}</Alert>
                  </Snackbar>
                </div>
                <Modal
                  visible={this.state.visible}
                  width={this.state.modalWidth}
                  //   height="330"
                  className="popup-container"
                  effect="fadeInUp"
                  onClickAway={() => this.closeModal()}
                >
                  <div>
                    <MealPopUp
                      checkedfoods={this.state.checkedfoods}
                      postApi={this.postApi}
                      closeModal={this.closeModal}
                      successsMessage={this.state.successsMessage}
                      loading={this.state.loading}
                    />
                    <span className="cpoint" onClick={() => this.closeModal()}>
                      <div className="close">
                        <i className="bx bx-x"></i>
                      </div>
                    </span>
                  </div>
                </Modal>
              </div>
            )}
          </>
        )}
      </>
    );
  };

  render() {
    return <>{this.meal()}</>;
  }
}

export default MealPlan;
