import React, { useEffect } from "react";
import Card from "../../components/topLeftCard/Card";
import Chart from "../../components/teacherAttandenceChart/Chart";
import MarksApproval from "../../components/marksApproval/MarksApproval";

import Teacher from "../../components/teachersActivity/Teacher";
import Meeting from "../../components/meeting/Meeting";
import "./teacherDashboard.scss";
import RemarksHod from "../../components/RemarkHod/RemarksHod";
import HodTeachingPlan from "../../components/HodTeacherActivities/HodTeachingPlan";
import { useTeacherDashboardStore } from "./store/teacherDashboardStore";
import { useTeacherProfile } from "../../store/teacherProfileStore";
import TeacherProfiles from "../teacherProfile";
import { useIframeTokenStore } from "../../components/store/useStore";

const TeacherDashboard = () => {
  const { setPopupType, popupType } = useTeacherDashboardStore();
  const { setToken } = useIframeTokenStore();

  let urlparam = new URLSearchParams(window.location.search);
  let userToken = urlparam.get("token");
  const [isTeacherComp, setIsTeacherComp] = useTeacherProfile((state) => [
    state.isTeacherComp,
    state.setIsTeacherComp,
  ]);
  // useEffect(() => {
  //   if (popupType !== "") {
  //     const disableBackButton = (e) => {
  //       e.preventDefault();
  //       setPopupType("");
  //       // window.history.forward();
  //     };
  //     window.history\.pushState(null, "", window.location.href);
  //     window.addEventListener("popstate", disableBackButton);

  //     return () => {
  //       window.removeEventListener("popstate", disableBackButton);
  //     };
  //   }
  // }, [popupType]);

  useEffect(() => {
    if (popupType !== "") {
      const handleBackButton = (e) => {
        e.preventDefault();
        setPopupType("");
      };

      window.history.pushState(null, "", window.location.href);

      window.onpopstate = handleBackButton;

      return () => {
        window.onpopstate = null;
      };
    }
  }, [popupType]);
  useEffect(() => {
    const handleBrowserBackNavigation = () => {
      // Update Zustand state when browser back arrow is clicked
      setToken(userToken);
      setIsTeacherComp(false);
    };

    window.addEventListener("popstate", handleBrowserBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBrowserBackNavigation);
    };
  }, [setIsTeacherComp]);

  return (
    <>
      {isTeacherComp === true ? (
        <TeacherProfiles />
      ) : (
        <div
          className="teacher_dashboard_container"
          style={{ background: "#f9f9ff" }}
        >
          <div className="row">
            <div className="col-md-6">
              <Card />
            </div>
            <div className="col-md-6">
              <Chart />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <MarksApproval />
            </div>
            <div className="col-md-6">
              <Meeting />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <RemarksHod />
            </div>
            <div className="col-md-6">
              <Teacher />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TeacherDashboard;
