import React, { useContext, useEffect, useState } from "react";
import { AssignmentDetailForm } from "../AssignmentDetailForm/AssignmentDetailForm";
import { AssessmentContext } from "../TeacherAssessment/TeacherAssessment";
import { useAssignmentListStore } from "../store/useStore";
import { Tab, Tabs } from "react-bootstrap";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import "./TeacherAssignment.scss";
import NepaliDate from "nepali-date";

export const TeacherAssignment = () => {
  const { gradeToken, subjectToken } = useContext(AssessmentContext);
  const { data, assignmentList } = useAssignmentListStore();
  const [dayToken, setDayToken] = useState(
    new NepaliDate().format("YYYY-MM-DD")
  );

  useEffect(() => {
    if (gradeToken && subjectToken && dayToken) {
      assignmentList(gradeToken, subjectToken, dayToken);
    }
  }, [gradeToken, subjectToken, dayToken]);

  return (
    <div>
      <div className="card-tab-section teacher-assignment-section">
        <div
          className="date-token"
          onChange={(e) => {
            setDayToken(e.target.value);
          }}
          style={{ float: "right", width: "200px" }}
        >
          <NepaliDatePicker
            inputClassName="input-field"
            className=""
            value={dayToken}
            onChange={(value) => {
              setDayToken(value);
            }}
            options={{ calenderLocale: "ne", valueLocale: "en" }}
          />
        </div>
        <div>
          <Tabs>
            <Tab eventKey="homework" title="Homework">
              <AssignmentDetailForm
                ishomework={true}
                dataList={data?.homeworks}
              />
            </Tab>

            <Tab eventKey="classwork" title="classwork">
              <AssignmentDetailForm
                ishomework={false}
                dataList={data?.classworks}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
