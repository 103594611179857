import { useEffect, useState } from "react";
import { NoDataContent } from "../../components/NoDataContent/NoDataContent";
import PreLoader from "../../components/preLoader/preLoader";
import { useTeachingPlanMainStore } from "../teachingPlanMain/store/teachingPlanMainStore";
import { PlanFilter } from "./planFilter";
import { StudentEvaluation } from "./studentEvaluation";
import { UnitLessonEvaluation } from "./unitLessonEvaluation";
import { getTeachingEvaluationList } from "../../components/Api/GetApi";
import { toast } from "react-toastify";

export const TeachingPlanFilter = ({ isLoading }) => {
  let urlparam = new URLSearchParams(window.location.search);
  let viewPageFromUrl = urlparam.get("viewPage");

  const [loading, setLoading] = useState(false);

  const {
    subjectToken,
    teachingEvaluationList,
    setTeachingEvaluationList,
    selectedUnitData,
    viewPage,
    setViewPage,
  } = useTeachingPlanMainStore();
  useEffect(() => {
    setViewPage(viewPageFromUrl);
  }, [viewPageFromUrl]);

  const fetchTeachingEvaluation = () => {
    getTeachingEvaluationList(
      selectedUnitData?.grade_token,
      subjectToken,
      selectedUnitData?.id
    )
      .then((res) => {
        setTeachingEvaluationList(res?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      subjectToken !== 0 &&
      subjectToken !== null &&
      selectedUnitData?.id &&
      selectedUnitData?.grade_token
    ) {
      setLoading(true);
      fetchTeachingEvaluation();
    }
  }, [subjectToken, selectedUnitData]);

  return (
    <div className="section-gap">
      <div className="container ">
        <PlanFilter />

        {isLoading ? (
          <PreLoader />
        ) : (
          <>
            <hr />
            {loading ? (
              <PreLoader />
            ) : viewPage === "Evaluation List" ? (
              <>
                {teachingEvaluationList?.length > 0 ? (
                  <UnitLessonEvaluation
                    fetchTeachingEvaluation={fetchTeachingEvaluation}
                  />
                ) : (
                  <NoDataContent
                    image={require("../../images/emptyfile.png").default}
                    title="No Teaching Plan Evaluation"
                    description={
                      "No Teaching Plan Evaluation has been created yet"
                    }
                  />
                )}
              </>
            ) : (
              <>
                <StudentEvaluation />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};
