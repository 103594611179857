import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  // unit_token: Yup.string().required("Please select unit"),
  // grade_token: Yup.string().required("Please Select grade"),
  // subject_token: Yup.string().required("Please select subjectToken"),
  // weightage: Yup.number().required("Please enter weightage"),
  // lesson_token: Yup.number().when("isLessonEvaluation", {
  //   is: (val) => {
  //     return val === true;
  //   },
  //   then: Yup.number().required("Required").min(1, "Required"),
  //   otherwise: Yup.number().notRequired(),
  // }),
});
