import React from "react";
import "./BasicInformation.scss";

export const BasicInformation = ({ data }) => {
  return (
    <div className="information-section">
      <div className="information-content">
        <p className="title-content tag">Full Name: </p>
        <p className="title-content ">
          {data?.teacher_name ? data?.teacher_name : "-"}{" "}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Designation: </p>
        <p className="title-content ">
          {data?.designation ? data?.designation : "-"}{" "}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Class Teacher of: </p>
        <p className="title-content ">
          {data?.class_teacher ? data?.class_teacher : "-"}{" "}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Teaching Level: </p>
        <p className="title-content ">
          {data?.teacher_level ? data?.teacher_level : "-"}{" "}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Gender: </p>
        <p className="title-content "> {data?.gender ? data.gender : "-"} </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Date of Birth: </p>
        <p className="title-content "> {data?.dob ? data?.dob : "-"} </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Joined Date: </p>
        <p className="title-content ">
          {data?.joined_at ? data?.joined_at : "-"}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Phone Number: </p>
        <p className="title-content ">
          {data?.phone_number ? data.phone_number : "-"}{" "}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Address: </p>
        <p className="title-content ">{data?.address ? data?.address : "-"}</p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> Blood Group: </p>
        <p className="title-content ">
          {data?.blood_group ? data.blood_group : "-"}{" "}
        </p>
      </div>

      <div className="information-content">
        <p className="title-content tag"> PAN Number: </p>
        <p className="title-content ">{data?.pan_no ? data.pan_no : "-"} </p>
      </div>
      {/* <div className="qualification-section">
        <h2 className="middle-content main-title">Qualification</h2>

        <div className="qualification-details">
          <div>
            <h4 className="medium-title title-description text-capitalize">
              {data?.designation}
            </h4>
            <h4 className="small-content text-capitalize">
              {data?.address ? data?.address : "-"}
            </h4>
          </div>

          <p className="small-content">{data?.joined_at}</p>
        </div>

        {/* <div className="qualification-details">
          <div>
            <h4 className="medium-title title-description">
              Secondary Level Teacher at ABC School of Banepa
            </h4>
            <h4 className="small-content">Banepa, Kavrepalanchowk</h4>
          </div>

          <p className="small-content">Magh 2076 - Present</p>
        </div> 
      </div> */}
    </div>
  );
};
