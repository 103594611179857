import { useState } from "react";

const btnConfig = [
  {
    label: "This Month",
    value: "current",
    img: require("../../../images/current-indicator.png").default,
  },
  {
    label: "Last Month",
    value: "later",
    img: require("../../../images/later-indicator.png").default,
  },
];

export const SwitchBtn = () => {
  const [activeMonth, setActiveMonth] = useState("current");
  return (
    <div className="switch-btn-section">
      <div className="switch-btn-block">
        {btnConfig?.map((item, index) => {
          return (
            <button
              key={index}
              className={`switch-btn ${
                item?.value === activeMonth ? "active" : ""
              }`}
              onClick={() => {
                setActiveMonth(item?.value);
              }}
            >
              <img src={item?.img} alt="icon" />
              {item?.label}
            </button>
          );
        })}
      </div>
    </div>
  );
};
