import "./customHeatMap.scss";
import { generateMapData } from "./service";

export const CustomHeatMap = ({ dataList }) => {
  const getColor = (status) => {
    switch (status) {
      case "deadline":
        return "blue";
      case "submitted":
        return "green";
      case "late":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "empty";
    }
  };
  const data = generateMapData(30);
  return (
    <div className="custom-heat-map">
      <div className="box-list">
        {/* {dataList?.map((item) => (
          <div className={`box ${getColor(item.status)}`} key={item.day} />
        ))} */}
        {list?.map((item, index) => (
          <div
            className={`box ${item.color} ${item?.x} ${item?.y}}`}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

export const CustomHeatMapTwo = () => {
  return (
    <div className="custom-heat-map">
      <div className="box-list">
        {list?.map((item, index) => (
          <div
            className={`box ${item.color} ${item?.x} ${item?.y}}`}
            key={index}
          />
        ))}
      </div>
    </div>
  );
};

const list = [
  { x: 0, y: 0, color: "" },
  { x: 0, y: 1, color: "" },
  { x: 0, y: 2, color: "green" },
  { x: 0, y: 3, color: "yellow" },
  { x: 0, y: 4, color: "red" },
  { x: 0, y: 5, color: "blue" },
  { x: 0, y: 6, color: "green" },
  { x: 1, y: 0, color: "yellow" },
  { x: 1, y: 1, color: "red" },
  { x: 1, y: 2, color: "blue" },
  { x: 1, y: 3, color: "green" },
  { x: 1, y: 4, color: "yellow" },
  { x: 1, y: 5, color: "red" },
  { x: 1, y: 6, color: "blue" },
  { x: 2, y: 0, color: "green" },
  { x: 2, y: 1, color: "yellow" },
  { x: 2, y: 2, color: "red" },
  { x: 2, y: 3, color: "blue" },
  { x: 2, y: 4, color: "green" },
  { x: 2, y: 5, color: "yellow" },
  { x: 2, y: 6, color: "red" },
  { x: 3, y: 0, color: "blue" },
  { x: 3, y: 1, color: "green" },
  { x: 3, y: 2, color: "yellow" },
  { x: 3, y: 3, color: "red" },
  { x: 3, y: 4, color: "blue" },
  { x: 3, y: 5, color: "green" },
  { x: 3, y: 6, color: "yellow" },
  { x: 4, y: 0, color: "red" },
  { x: 4, y: 1, color: "blue" },
  { x: 4, y: 2, color: "green" },
  { x: 4, y: 3, color: "yellow" },
  { x: 4, y: 4, color: "" },
  { x: 4, y: 5, color: "" },
  { x: 4, y: 6, color: "" },
];
