import React from "react";
import "./HodCommentCard.scss";
import Moment from "react-moment";

const HodCommentCard = ({ data }) => {
  return (
    <div className="hod_comment_card_container">
      <div className="img_wrapper">
        {data?.teacher_img && data?.teacher_img !== null ? (
          <img src={data?.teacher_img} alt="img" />
        ) : (
          <div className="img_alt">{data?.teacher_name.charAt(0)}</div>
        )}
      </div>
      <div className="desc_container">
        <h2 className="name_section">{data?.teacher_name}</h2>
        <h3 className="designation_section">
          Head of Department of <span>{data?.designation}</span>
        </h3>
        <p className="comment_description">{data?.comment}</p>
        <div className="time">
          {" "}
          <Moment fromNow>{data?.created_at}</Moment>
        </div>
      </div>
    </div>
  );
};

export default HodCommentCard;
