import React from "react";

export const LayoutTbody=(props)=> {
  var newIndex = 0;
  const studentDetail = props.studentDetail;
return(

<>
{props.filterSubject?.map((subject, index)=>{
     {
      newIndex = +(index + 1);
    }
  return(
    <tr key={index}>
    <td>{subject.subjectName ? index + 1 : ""}</td>
    <td className="layout-subject-body">{subject.subjectName}</td>
    <td > {studentDetail.subjects[subject.subjectName]?.credit} </td>

    <td>
    {studentDetail.subjects[subject.subjectName]?.theorygrade === null ? "-": studentDetail.subjects[subject.subjectName]?.theorygrade}

    </td>
    <td >                {studentDetail.subjects[subject.subjectName]?.praticalgrade}
 </td>
    <td > 
    {studentDetail.subjects[subject.subjectName]?.totalgrade}
       </td>
    <td >  {studentDetail.subjects[subject.subjectName]?.totalpoint}</td>
    <td ></td>
  </tr>

  )
})}


        {props?.showAdditionalExamSubject &&
        studentDetail.additional_subjects?.map(
          (additional_subject, add_sub_index) => {
            return (
              <tr key={add_sub_index}>
                <td>{newIndex + (add_sub_index + 1)}</td>
                <td className="layout-subject-body">
                  {additional_subject.subject}
                </td>
                <td>4.00</td>
                <td>{additional_subject.grade}</td>
                <td>-</td>
                <td>{additional_subject.grade}</td>
                <td>{additional_subject.point}</td>
                <td></td>
              </tr>
            );
          }
        )}  

           
           
     
    </>
)
}
