import React from "react";
import "./recordBtn.scss";
import plus from "../../images/plus.svg";

const RecordBtn = ({name}) => {
  return (
    <div className="record_btn">
      <img src={plus} alt="plus" />
      <div>{name}</div>
    </div>
  );
};

export default RecordBtn;
