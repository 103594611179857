import { domain } from "../url";

function RoutePage() {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let route = params.get("route");

  let url = domain + "/" + route
  return (window.location = url);
}
export default RoutePage;
