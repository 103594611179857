import React from "react";


export default function LayoutTbodyNew() {


  return (
    <>
    
          <tr >
            <td>1</td>
            <td className="layout-subject-body">1 </td>
            <td>Social</td>
            <td >
           12
            </td>
            <td >
          21
            </td>
            <td >
        33
            </td>
            <td >
             67
            </td>
            <td ></td>
          </tr>
 
    </>
  );
}
