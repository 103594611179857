import React, { useState, useEffect, useMemo } from "react";
import { getAttendanceList } from "../Api/GetApi";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import "./examAttendance.scss";
import PopUp from "../popUp/popUp";
import { AttendancePost } from "../Api/PostApi";
import { useExamlist } from "../store/useStore";
import { NoDataContent } from "../NoDataContent/NoDataContent";

const errorType = {
  exam: false,
  attendance: false,
};

const sudentKey = {
  studentId: "",
  attendance: "",
};
export const ExamAttendance = () => {
  const [examToken, setexamToken] = useState();

  const [data, setData] = useState([]);
  const [studentInfo, setStudentInfo] = useState([sudentKey]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessge, setSnackMessge] = useState("");
  const [snackStatus, setSnackStatus] = useState(true);
  const [showError, setShowError] = useState(errorType);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchExamlist, data: examlist } = useExamlist();

  const selectedExam = useMemo(() => {
    const temp = examlist?.exam?.find((item) => {
      return item?.id === Number(examToken);
    });
    return temp;
  }, [examToken]);

  useEffect(() => {
    fetchExamlist();
  }, []);

  const onAttendanceChange = (e, studentId) => {
    let newArr = [...studentInfo];
    const findStudentIndex = newArr.findIndex((f) => f.studentId === studentId);
    newArr[findStudentIndex] = {
      ...newArr[findStudentIndex],
      attendance: e.target.value,
    };

    setStudentInfo(newArr);
  };

  const closeModal = () => {
    setVisible(false);
    setIsLoading(false);
  };

  const onSubmit = () => {
    setIsSubmitted(true);
    const temp = studentInfo?.every(
      (student) =>
        student?.attendance?.toString()?.length > 0 &&
        student?.attendance?.toString()?.length < 11
    );
    if (examToken === undefined || examToken.length <= 0) {
      setShowError({ exam: true });
    } else if (!temp) {
      setOpenSnackBar(true);
      setSnackMessge("Please review the information");
      setSnackStatus(false);
    } else {
      setVisible(true);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const json = {
      exam_token: examToken,
      value: studentInfo,
    };

    AttendancePost(json)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setSnackMessge(res?.data?.message);
          setSnackStatus(true);
          setOpenSnackBar(true);
          setIsLoading(false);
          setVisible(false);

          setIsSubmitted(false);
        }
      })
      .catch((err) => {
        setSnackMessge(err.response.data.message);
        setSnackStatus(false);
        setOpenSnackBar(true);
        closeModal();
      });
  };

  const handleExamToken = (val) => {
    setexamToken(val);
    getAttendanceList(val).then((res) => {
      setData(res?.data);
      setStudentInfo(
        res.data?.map((item) => {
          return { studentId: item?.id, attendance: item?.attendance };
        })
      );
    });
  };

  return (
    <div className="exam-attendance section-gap">
      <div className="container">
        {typeof data === "string" ? (
          <p className="text-center">{data}</p>
        ) : (
          <div>
            <div className="form-group">
              <label>Exam Attendance</label>
              <select
                className="form-control"
                value={examToken}
                onChange={(e) => {
                  handleExamToken(e.target.value);

                  if (e.target.value.length > 0) {
                    setShowError({ exam: false });
                  }
                }}
              >
                <option value="" disabled selected={true}>
                  Select Exam
                </option>
                {examlist?.exam?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.examName}
                    </option>
                  );
                })}
              </select>
              {showError?.exam && <p className="error">Please Select Exam</p>}
            </div>
            {data?.length > 0 ? (
              <>
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th>Roll No.</th>
                      <th>Student Name</th>
                      <th>Attendance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.map((item, index) => {
                      const schoolDays = selectedExam?.school_days ?? 0;
                      return (
                        <tr>
                          <td>{item?.rollNo}</td>
                          <td>{item?.studentName}</td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              value={studentInfo?.[index]?.attendance}
                              onChange={(e) => {
                                if (
                                  Number(e.target.value) >
                                  schoolDays
                                ) {
                                  e.preventDefault();
                                  setOpenSnackBar(true);
                                  setSnackMessge(
                                    `The school days was only ${selectedExam?.school_days}. You can't enter value greater than that.`
                                  );
                                  setSnackStatus(false);
                                } else {
                                  onAttendanceChange(e, item?.id);
                                }
                              }}
                              onKeyPress={(event) => {
                                if (!/^[0-9.]$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              onFocus={(e) => {
                                if (e.target.value === "0") {
                                  e.target.value = "";
                                }
                              }}
                              onBlur={(e) => {
                                if (!e.target.value) {
                                  e.target.value = "0";
                                }
                              }}
                            />
                            {isSubmitted &&
                              studentInfo?.[index]?.attendance === "" && (
                                <p className="error" id={index}>
                                  Please enter Value
                                </p>
                              )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <button
                  className="main-btn"
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  submit
                </button>
                <PopUp
                  visible={visible}
                  closeModal={closeModal}
                  title="Are you sure want to submit ?"
                  content="Attendance will be submited."
                  post={handleSubmit}
                  isLoading={isLoading}
                />
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={openSnackBar}
                  autoHideDuration={3000}
                  onClose={() => setOpenSnackBar(false)}
                >
                  {snackStatus ? (
                    <Alert severity="success">{snackMessge}</Alert>
                  ) : (
                    <Alert severity="error">{snackMessge}</Alert>
                  )}
                </Snackbar>
              </>
            ) : (
              <NoDataContent
                title=""
                image={require("../../images/emptyfile.png").default}
                description={"Select exam for students list to appear here"}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
