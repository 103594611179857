import React from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import { useState } from "react";
import debounce from "lodash/debounce";
import get from "lodash/get";

const CustomField = ({ name, index, ...rest }) => {
  const { setFieldValue, values } = useFormikContext();
  const [value, setValue] = useState("");

  const handleChange = (e) => {
    const val = e.target.value;
    setValue(val);
    debouncedSetFieldValue(name, val);
  };

  const debouncedSetFieldValue = debounce((field, val) => {
    setFieldValue(field, val);
  }, 500);

  React.useEffect(() => {
    // Sync the initial value from Formik with the local state
    setValue(get(values, name, ""));
  }, [values?.teachers?.length]);

  return (
    <>
      <Field type="text" value={value} onChange={handleChange} {...rest} />
      <ErrorMessage
        style={{ color: "red", size: "8px" }}
        name={name}
        component="div"
      />
    </>
  );
};

export default CustomField;
