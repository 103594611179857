import { useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import "./previewTable.scss";
import { useStudentRecords } from "../store/useStore";

export const PreviewTable = ({ id, title, onBack }) => {
  const { fetchStudentRecords, data, loading: isLoading } = useStudentRecords();

  const outputComponentRef = useRef(null);
  useEffect(() => {
    fetchStudentRecords(id);
    generatePDF(data);
  }, [id]);

  const generatePDF = async (html) => {
    const options = {
      margin: 1,
      filename: `${title}.pdf`,
      image: { type: "png", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { format: "A4", orientation: "portrait" },
    };
    let output = await html2pdf().from(html).set(options).outputImg();
    outputComponentRef.current.append(output);
  };

  return (
    <div className="preview-table">
      <div className="container">
        <div className="title-block header">
          <span
            onClick={() => {
              onBack();
            }}
          >
            <i className="bx bxs-chevron-left"></i>
          </span>
          <p className="title">{title || "Result"}</p>
        </div>
        {isLoading ? (
          <div className="pre-loader">
            <h4 className="content center">Loading...</h4>
          </div>
        ) : (
          <div className="printable-content">
            <div className="block-gap">
              <div className="student-portfolio">
                <div className="output-img" ref={outputComponentRef}></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
