import { toast } from "react-toastify";
import { postAppointmentApprove, postAppointmentOutcome } from "../Api/PostApi";
import { getAppointmentTeacherView } from "../Api/GetApi";
import { useAppointmentStore } from "../../store/appointmentListStore";
import QrCodeIcon from "@mui/icons-material/QrCode";
import Modal from "react-modal";
import { useRef, useState } from "react";
import { GrClose } from "react-icons/gr";
import NepaliDate from "nepali-date-converter";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Field, Form, Formik } from "formik";
import { NoDataContent } from "../NoDataContent/NoDataContent";

export const ScheduledCard = ({ cardList, isTeacher }) => {
  const { appointmentListTeacher, setAppointmentListTeacher } =
    useAppointmentStore();
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const [outcome, setOutcome] = useState("");
  const [visitorId, setVisitorId] = useState("");
  const formRef = useRef(null);

  const [QRindex, setQRindex] = useState(null);
  const onApprove = async (id) => {
    const data = {
      visitor_id: id,
    };
    try {
      await postAppointmentApprove(data).then((res) => {
        toast.success(res?.data?.message);
      });
      await getAppointmentTeacherView().then((res) => {
        setAppointmentListTeacher(res?.data?.data);
      });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };
  const nepaliDate = new NepaliDate(new Date("2024-04-21")).toString();

  const initialValues = {
    outcome: "",
  };

  const handleOutcomeSubmit = (values, { resetForm }) => {
    const data = {
      visitor_id: visitorId,
      outcome: values.outcome,
    };
    if (!data.outcome) {
      toast.error("Outcome is required");
      return;
    }
    try {
      postAppointmentOutcome(data).then((res) => {
        toast.success(res?.data?.message);
      });
      setShowModal(false);
      resetForm({ values: initialValues });
    } catch {
      toast.error("Something went wrong");
      resetForm({ values: initialValues });
      setShowModal(false);
    }
  };

  return (
    <>
      {cardList.length > 0 ? (
        <div className="schedule-list row">
          {cardList.map((item, index) => {
            const nepDateMonth = new NepaliDate(new Date(item.date)).format(
              "MMMM"
            );
            const nepDateDay = new NepaliDate(new Date(item.date)).format("D");
            return (
              <div className="col-lg-12 col-sm-12" key={index}>
                <div className="schedule-item row">
                  <div
                    className="col-lg-2 col-sm-4"
                    style={{ height: "fit-content", margin: "auto 0" }}
                  >
                    <div
                      style={{
                        padding: "2px",
                        display: "flex",
                        justifyContent: "center",
                        background: "#CCE5FE",
                        flexDirection: "column",
                        alignItems: "center",
                        borderRadius: "8px",
                      }}
                    >
                      <h3>{nepDateDay}</h3>
                      <p style={{ fontSize: "12px" }}>{nepDateMonth}</p>
                    </div>
                  </div>
                  <div className="col-lg-7 col-sm-8">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <span
                        className={
                          item.is_approved === true
                            ? "success"
                            : item.is_visited
                            ? "success"
                            : "pending"
                        }
                      >
                        {item.is_visited === true
                          ? "Completed"
                          : item.is_approved === true
                          ? "Approved"
                          : "Pending"}
                      </span>
                      <Alert variant="primary">
                        {item.time.substring(0, 5)}
                      </Alert>
                    </div>
                    <h2 className="block-title mt-2"> {item.purpose}</h2>
                    {isTeacher && (
                      <p className="block-title">
                        Requested by: {item.request_by}
                      </p>
                    )}
                    {!isTeacher && (
                      <p className="block-title">
                        Requested to: {item.for_teacher}
                      </p>
                    )}
                  </div>
                  {isTeacher && !item.is_approved && (
                    <div
                      className="col-lg-3 col-sm-12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="acceptBtn"
                        variant="success"
                        onClick={() => onApprove(item.visitor_id)}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                  {isTeacher && item.is_visited && (
                    <div
                      className="col-lg-3 col-sm-12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <Button
                        className="acceptBtn"
                        variant="success"
                        onClick={() => {
                          setVisitorId(item.visitor_id);
                          setShowModal(true);
                          setModalType("outcome");
                        }}
                      >
                        Outcome
                      </Button>
                    </div>
                  )}
                  {!isTeacher && item.is_approved && !item.is_visited && (
                    <div
                      className="col-lg-3 col-sm-12"
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <button
                        className="main-btn qrBtn"
                        onClick={() => {
                          setShowModal(true);
                          setModalType("qr");
                          setQRindex(index);
                        }}
                      >
                        <QrCodeIcon />
                        Show QR
                      </button>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
          <Modal
            isOpen={showModal}
            onRequestClose={() => setShowModal(false)}
            style={{
              content: {
                width: "70%",
                height: "fit-content",
                margin: "auto",
                position: "absolute",
              },
              overlay: {
                background: "rgba(0, 0, 0, 0.7)",
                // background: "red",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
            }}
          >
            <GrClose
              size={25}
              onClick={() => {
                setShowModal(false);
                if (modalType === "outcome") {
                  formRef.current &&
                    formRef.current.setFieldValue("outcome", "");
                }
              }}
              style={{
                position: "absolute",
                top: "10",
                right: "10",
                color: "black",
                cursor: "pointer",
              }}
            />
            {modalType === "qr" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "bottom",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: "20px",
                  padding: "30px",
                }}
              >
                <h3>Please Scan This QR Code</h3>
                <img src={cardList?.[QRindex]?.qr} alt="Qr" />
              </div>
            )}
            {modalType === "outcome" && (
              <>
                <h5 className="modal-title">Outcome</h5>
                <Formik
                  initialValues={initialValues}
                  onSubmit={handleOutcomeSubmit}
                  innerRef={formRef}
                >
                  <Form>
                    <Field
                      as="textarea"
                      name="outcome"
                      className="form-control"
                      id="outcome"
                      style={{
                        width: "100%",
                        minHeigth: "50px",
                        margin: "20px 0px",
                      }}
                    />
                    <button type="submit" className="submit_btn">
                      Submit
                    </button>
                  </Form>
                </Formik>
              </>
            )}
          </Modal>
        </div>
      ) : (
        <NoDataContent
          title="No appointment"
          image={require("../../images/emptyfile.png").default}
          marginTop="20px"
        />
      )}
    </>
  );
};
