import React, { useEffect, useState } from "react";
import "./StudentsFeedback.scss";
import { ErrorMessage, Field, Formik, Form } from "formik";
import * as Yup from "yup";
import { getFeedback, getStudentList } from "../Api/GetApi";
import { postFeedback } from "../Api/PostApi";
import { toast } from "react-toastify";

function StudentsFeedback() {
  const [studentsList, setStudentsList] = useState([]);
  const [feedback, setFeedback] = useState([]);
  const validationSchema = Yup.object().shape({
    details: Yup.string().required("Details is required"),
    student_token: Yup.string().required("Must select student's name"),
    feedbackType: Yup.string().required("Feedback type must be selected"),
  });

  useEffect(() => {
    getStudentList().then((res) => {
      setStudentsList(res?.data || []);
    });
    getFeedback().then((res) => {
      setFeedback(res?.data?.data);
    });
  }, []);

  return (
    <div className="students_feedback">
      <h2 className="feedback_title">Students feedback</h2>
      <div className="container">
        <div className="section-gap">
          <Formik
            initialValues={{
              student_token: "",
              feedbackType: "",
              details: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              const submissionData = {
                student_token: values.student_token,
                student_feedbacks_id: values.feedbackType,
                details: values.details,
              };

              try {
                postFeedback(submissionData).then((res) => {
                  toast.success("Feedback submitted successfully!");
                });
              } catch (error) {
                console.error("Error submitting feedback:", error);
                toast.error("Failed to submit feedback. Please try again.");
              }

              resetForm();
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="students_feedback_section">
                  <div>
                    <label className="content first-content">
                      Students Name
                    </label>
                    <Field
                      as="select"
                      name="student_token"
                      className="students_name custom-select"
                      aria-label="Default select example"
                    >
                      <option value="">Select Student Name</option>
                      {studentsList.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.studentName}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="student_token"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <div>
                    <label className="content first-content">
                      Feedback Type
                    </label>
                    <Field
                      as="select"
                      name="feedbackType"
                      className="students_name custom-select"
                      aria-label="Default select example"
                    >
                      <option value="" disabled>
                        Select Feedback Type
                      </option>
                      {feedback.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.feedback_type}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="feedbackType"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <div>
                    <label className="content first-content">Details</label>
                    <Field
                      as="textarea"
                      name="details"
                      className="feedback form-control"
                      placeholder="Type details here"
                    />
                    <ErrorMessage
                      name="details"
                      component="div"
                      className="error text-danger"
                    />
                  </div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="main-btn"
                  >
                    Post
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default StudentsFeedback;
