import React, { useState } from "react";
import SearchComponent from "../SearchComponent/SearchComponent";
import SubjectCard from "./SubjectCard";
import { useEffect } from "react";
import { getAllGrade, getSyllabus } from "../../Api/GetApi";
import { useHistory } from "react-router-dom";
import { useElearningStore } from "../../../store/elearningStore";
import PopUp from "../PopUp/PopUp";
import ReactHtmlParser from "react-html-parser";
import { EmptySubjectList } from "./EmptySubjectList";
import Select from "react-select";
import { SubjectCardShimmer } from "./SubjectCardShimmer";

const customStyles = {
  option: (provided) => ({
    ...provided,
    color: "#333",
    backgroundColor: "#fff",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#29abe2",
      color: "#fff",
      cursor: "pointer",
    },
  }),
};
const SubjectList = () => {
  const [grade, setGrade] = useState([]);
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSubject, setSelectSubject] = useState();
  const [selecteGarde, setSelecteGarde] = useState(null);
  const [isLoading, setIsloading] = useState(false);
  const history = useHistory();
  const elearningState = useElearningStore((state) => state);

  const filteredSubjectsData = data?.filter((f) =>
    f?.subjectName?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const getData = (id) => {
    setIsloading(true);
    getSyllabus(id)
      .then((response) => {
        setData(response?.data);
        setIsloading(false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const handleGradeChange = (val) => {
    setIsloading(true);
    elearningState?.setTempGradeToken(Number(val?.id));
    setSelecteGarde(val);
    getSyllabus(Number(val?.id)).then((res) => {
      setData(res?.data);
      setIsloading(false);
    });
  };

  const onSubjectCardClick = (subject) => {
    history.push({
      pathname: "/chapter-list",
      state: {
        firstParams: subject?.subjectName,
        gradeToken: elearningState?.tempGradeToken ?? Number(selecteGarde?.id),
        subjectToken: subject?.subjecttoken,
      },
    });
  };

  const gradeList = grade?.map((item) => {
    return {
      label: item?.class_name,
      id: item?.id,
    };
  });

  useEffect(() => {
    getAllGrade().then((res) => {
      setGrade(res?.data);
      if (
        filteredSubjectsData?.length <= 0 &&
        !elearningState?.tempGradeToken
      ) {
        getData(res?.data?.[0]?.id);
      }
    });
  }, []);

  useEffect(() => {
    if (elearningState?.tempGradeToken) {
      const temp = gradeList?.find(
        (f) => f?.id === elearningState?.tempGradeToken
      );
      return setSelecteGarde(temp);
    } else {
      setSelecteGarde(gradeList[0]);
    }
  }, [grade]);

  useEffect(() => {
    if (
      elearningState?.tempGradeToken &&
      filteredSubjectsData?.length <= 0 &&
      selecteGarde?.id
    ) {
      getData(elearningState?.tempGradeToken);
    }
  }, [elearningState?.tempGradeToken, filteredSubjectsData, selecteGarde?.id]);

  const getComponent = () => {
    if (isLoading) {
      return (
        <div className="subject-card-list ">
          {Array.from({ length: 5 }, (_, index) => {
            return <SubjectCardShimmer key={index} />;
          })}
        </div>
      );
    }
    if (!filteredSubjectsData?.length) {
      return <EmptySubjectList />;
    } else
      return (
        <div className="subject-card-list ">
          {/* <SubjectCardShimmer /> */}
          {filteredSubjectsData?.map((subject, index) => (
            <SubjectCard
              key={index}
              logo={subject?.subjectName?.at(0)}
              title={subject?.subjectName}
              chapter={subject?.chapter_no}
              syllabus={subject?.syllabus}
              introduction={subject?.introduction}
              onSubjectCardClick={() => onSubjectCardClick(subject)}
              onViewClick={() => {
                setSelectSubject(subject);
                setIsPopupOpen(true);
              }}
            />
          ))}
        </div>
      );
  };

  return (
    <div className="subject-list">
      <div className="top-section">
        <div className="title">Subjects</div>
        <div className="top-rignt">
          <div className="input-field-block">
            <Select
              value={selecteGarde}
              options={gradeList}
              onChange={(val) => handleGradeChange(val)}
              placeholder="Select grade"
              className="grade-select"
              styles={customStyles}
            />
          </div>
          <div className="search-component">
            <SearchComponent
              onSearch={setSearchQuery}
              placeholder="Search Subject"
            />
          </div>
        </div>
      </div>

      {getComponent()}
      {isPopupOpen && (
        <PopUp
          isOpen={isPopupOpen}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          title={`Syllabus of ${selectedSubject?.subjectName} `}
          description={ReactHtmlParser(selectedSubject?.description)}
        />
      )}
    </div>
  );
};

export default SubjectList;
