import { Field } from "formik";

export const InputField = ({ name, touched, type, ...rest }) => {
  return (
    <Field name={name}>
      {({ field }) => (
        <input
          {...field}
          {...rest}
          type={type}
          className={`input-field`}
          onKeyPress={(event) => {
            if (type === "number")
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
          }}
        />
      )}
    </Field>
  );
};
