import React, { useEffect, useState } from "react";
import { MdCancel } from "react-icons/md";
import Modal from "react-modal";
import { useUnitApi } from "../../store/hodDashboardStore";
import { getClassAlphaHod, getTeacherSubjectApiHod } from "../Api/GetApi";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import "./HodTeachingPlan.scss";

import { IoIosArrowForward } from "react-icons/io";
import { toast } from "react-toastify";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import { SinglePlanDetails } from "../../pages/teachingPlanMain/singlePlanDetails";
import { useTeachingPlanMainStore } from "../../pages/teachingPlanMain/store/teachingPlanMainStore";
import { deleteTeachingPlan, updateTeachingPlan } from "../Api/PostApi";
import ApprovedByPopup from "../ApprovedByPopup/ApprovedByPopup";
import HodTeachingPlaningPopupComment from "../HodTeachingPlaningPopupComment/HodTeachingPlaningPopupComment";
import NividLoader from "../NividLoader/NividLoader";
import { DeleteConfirmationModal } from "../teachingPlanComponents/lessonTab/deleteConfirmationModal";
import { EditModal } from "../../pages/myTeachingPlan/editModal";
import { HodAssessmentSideBar } from "../HodAssessmentSidebar/HodAssessmentSidebar";

const monthList = [
  {
    id: 1,
    monthName: "Baishak",
  },
  {
    id: 2,
    monthName: "Jestha",
  },
  {
    id: 3,
    monthName: "Ashar",
  },
  {
    id: 4,
    monthName: "Shrawan",
  },
  {
    id: 5,
    monthName: "Bhadra",
  },
  {
    id: 6,
    monthName: "Ashoj",
  },
  {
    id: 7,
    monthName: "Kartik",
  },
  {
    id: 8,
    monthName: "Mangsir",
  },
  {
    id: 9,
    monthName: "Poush",
  },
  {
    id: 10,
    monthName: "Magh",
  },
  {
    id: 11,
    monthName: "Falgun",
  },
  {
    id: 12,
    monthName: "Chaitra",
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflowY: "unset !important",
    width: "95%",
    height: "95%",
  },
};
const customStyles2 = {
  position: "fixed",
  top: "50%", // Adjust this value to position the modal at the desired distance from the top
  left: "50%",
  transform: "translateX(-50%) translateY(-50%)",
  width: "50%",
  height: "50%",
  overFlow: "scroll",
  transition: "all 0.5s ease",
};

const HodTeachingPlan = () => {
  const [grade, setGrade] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState();
  const [subject, setSubject] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const { UnitPlan, loading, fetchHodUnitPlan } = useUnitApi();
  const [activeModalId, setActiveModalId] = useState(null);
  const [approvalId, setApprovalId] = useState(null);
  const [arrayIndex, setArrayIndex] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState();
  const [filterData, setFilterData] = useState();
  const { popupType, setPopupType, setViewedDetails } =
    useTeacherDashboardStore();
  const {
    selectedStartDayToken,
    selectedEndDayToken,
    setSelectedStartDayToken,
    setSelectedEndDayToken,
  } = useTeachingPlanMainStore();
  const { setSelectedUnit, selectedUnit } = useTeachingPlanMainStore();

  useEffect(() => {
    const gradeApiData = async () => {
      const temp = await getClassAlphaHod();
      setGrade(temp?.data?.data);
    };
    gradeApiData();
  }, []);
  useEffect(() => {
    if (selectedGrade !== undefined || selectedGrade >= 0) {
      const subApiData = async () => {
        const temp = await getTeacherSubjectApiHod(selectedGrade);
        setSubject(temp?.data?.subject);
      };
      subApiData();
    }
  }, [selectedGrade]);

  const handleEdit = (start_day_token, end_day_token) => {
    if (start_day_token === null || start_day_token === "") {
      toast.error("Please select start date");
      return;
    }
    if (end_day_token === null || end_day_token === "") {
      toast.error("Please select end date");
      return;
    }
    const data = {
      start_day_token: start_day_token,
      end_day_token: end_day_token,
      teacher_plan_id: selectedUnit,
    };
    updateTeachingPlan(data)
      .then((res) => {
        toast.success("Unit Plan updated successfully !");
        setPopupType("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (
      selectedGrade !== undefined &&
      selectedMonth !== undefined &&
      selectedSubject !== undefined
    ) {
      fetchHodUnitPlan(selectedGrade, selectedSubject, selectedMonth);
    }
  }, [selectedGrade, selectedSubject, selectedMonth]);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#000000';
  }

  function closeModal() {
    setPopupType("");
  }

  const handleDelete = (planId) => {
    const data = {
      teacher_plan_id: planId,
    };
    deleteTeachingPlan(data)
      .then((res) => {
        toast.success("Unit Plan deleted successfully !");
        setPopupType("");
        fetchHodUnitPlan(selectedGrade, selectedSubject, selectedMonth);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const mergedData = {};
    if (UnitPlan && UnitPlan.length > 0) {
      UnitPlan.forEach((item) => {
        const key = `${item.classAlpha}_${item.unit_name}`;
        if (mergedData[key]) {
          mergedData[key].push(item);
        } else {
          mergedData[key] = [item];
        }
      });

      const result = Object.values(mergedData).map((items) => ({ key: items }));

      setFilterData(result);
    } else {
      setFilterData([]);
    }
  }, [UnitPlan]);

  return (
    <div className="hod_teaching_plan_container">
      <Modal
        isOpen={popupType === "viewPlan"}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{ fontSize: "24px", fontWeight: "500", color: "#000000" }}
          >
            <div className="d-flex" style={{ gap: "6px" }}>
              Teaching Plan
            </div>
          </div>
          <div style={{ cursor: "pointer" }} onClick={closeModal}>
            <MdCancel size={24} />
          </div>
        </div>

        <div
          style={{
            height: "75vh",
          }}
          className="row"
        >
          <div style={{ height: "100%" }} className="col-md-7">
            <SinglePlanDetails
              popup={true}
              selectedGrade={selectedGrade}
              selectedSubject={selectedSubject}
              selectedMonth={selectedMonth}
              selectedId={activeModalId}
              showHeader={false}
              closeModal={closeModal}
              showClassAlpha={true}
              filterData={filterData}
              arrayIndex={arrayIndex}
              removeEdit={true}
            />
          </div>
          <div
            style={{ height: "100%", overflowY: "auto" }}
            className="col-md-5"
          >
            <HodAssessmentSideBar
              selectedGrade={selectedGrade}
              selectedSubject={selectedSubject}
              selectedMonth={selectedMonth}
              filterData={filterData}
              arrayIndex={arrayIndex}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={popupType === "approvedForm"}
        onRequestClose={() => {
          setPopupType("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: customStyles2,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}
              <p className="modal-title">Approved From</p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setPopupType("");
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>
          <ApprovedByPopup approvedData={selectedLesson?.details} />
        </div>
      </Modal>
      <div className="title_section">
        {/* <h2 className="heading">Teaching Plan</h2> */}
        <div className="dropdown_section">
          <div className="grade_month_wrapper">
            <select
              style={{ width: "135px" }}
              onChange={(e) => {
                setSelectedGrade(e.target.value);
              }}
              className="input-field"
            >
              <option value="">Select Grade</option>
              {grade.length > 0 &&
                grade?.map((item, index) => (
                  <option value={item?.id} key={index}>
                    {item?.name}
                  </option>
                ))}
            </select>
            {selectedGrade <= 0 || selectedGrade === undefined ? (
              ""
            ) : (
              <select
                style={{ width: "135px" }}
                value={selectedSubject}
                className="input-field"
                // disabled={selectedGrade <= 0 || selectedGrade === undefined}
                onChange={(e) => {
                  setSelectedSubject(e.target.value);
                }}
              >
                <option value="">Subject</option>
                {subject.length > 0 &&
                  subject?.map((item, index) => (
                    <option value={item?.id} key={index}>
                      {item?.subjectName}
                    </option>
                  ))}
              </select>
            )}
          </div>
          <select
            style={{ width: "135px" }}
            name="month"
            className="input-field"
            onChange={(e) => {
              setSelectedMonth(Number(e.target.value));
            }}
            value={selectedMonth}
          >
            <option value="">Select Month</option>
            {monthList?.map((item, index) => {
              return (
                <option value={item?.id} key={index}>
                  {item?.monthName}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="body_section">
        {loading ? (
          <NividLoader height="300px" />
        ) : filterData?.length === 0 ? (
          <NoDataContent
            title="No data available"
            description="Data will be appeared when updated"
            image={require("../../images/emptyfile.png").default}
          />
        ) : (
          filterData?.map((data, index) => {
            const item = data?.key?.[0];
            return (
              <div key={index} className="planing_content_wrapper">
                <div className="unit_section_wrapper">
                  <div className="unit_section">
                    <h3 className="unit_name_wrapper">{item?.unit_name}</h3>
                    <div className="extra-info-section">
                      <i class="bx bx-time icon-bg"></i>
                      <p>
                        Total Teaching Hours:{" "}
                        <span>{item?.total_teaching_hour}</span>
                      </p>
                    </div>
                    <div className="start_end_date_wrapper">
                      <i class="bx bxs-calendar icon-bg"></i>
                      <p>
                        Date:{" "}
                        <span>{`${item?.start_date} - ${item?.end_date}`}</span>
                      </p>
                    </div>
                  </div>
                  <div className="view_icon_wrapper">
                    <div className="d-flex">
                      <div
                        className="edit-icon"
                        onClick={() => {
                          setPopupType("edit");
                          setSelectedUnit(item?.id);
                          setSelectedStartDayToken(item?.start_day_token);
                          setSelectedEndDayToken(item?.end_day_token);
                        }}
                        title="Edit Unit Plan Date"
                      >
                        <i class="bx bx-calendar-edit"></i>
                      </div>

                      <div
                        className="delete-icon"
                        onClick={() => {
                          setPopupType("deletePlan");
                          setSelectedUnit(item?.id);
                        }}
                        title="Delete Unit Plan"
                      >
                        <i class="bx bx-trash icon-bg"></i>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setActiveModalId(item?.id);
                        setApprovalId(item?.approval_id);
                        setArrayIndex(index);
                        setPopupType("viewPlan");
                        setViewedDetails(item);
                      }}
                      className="view_all_wrapper"
                    >
                      View Details
                      <span>
                        <IoIosArrowForward />
                      </span>
                    </div>
                  </div>
                </div>

                <div className="teacher_section">
                  <div className="img_name_wrapper">
                    <div className="img_wrapper">
                      {item?.teacher_image &&
                      item?.teacher_image !== undefined &&
                      item?.teacher_image !== null ? (
                        <img src={item?.teacher_image} alt="img" />
                      ) : (
                        <div className="img_alt">
                          {item?.teacher_name?.charAt(0).toUpperCase() || "T"}
                        </div>
                      )}
                    </div>
                    <p className="teacher_name_wrapper">{item?.teacher_name}</p>
                  </div>
                  <div
                    onClick={() => {
                      setPopupType("approvedForm");
                      setSelectedLesson(item);
                    }}
                    className="approval_container"
                  >
                    <i class="bx bx-right-arrow-alt arrow_wrapper"></i>
                  </div>
                </div>
              </div>
            );
          })
        )}
        {popupType === "deletePlan" && (
          <DeleteConfirmationModal
            visible={popupType === "deletePlan"}
            setVisible={setPopupType}
            onDelete={() => {
              handleDelete(selectedUnit);
            }}
          />
        )}
        {popupType === "edit" && (
          <EditModal
            visible={popupType === "edit"}
            setVisible={setPopupType}
            onEdit={handleEdit}
            startDateProps={selectedStartDayToken}
            endDateProps={selectedEndDayToken}
          />
        )}
      </div>
    </div>
  );
};

export default HodTeachingPlan;
