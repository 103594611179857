import React from "react";
import "./AssesmentPopupChart.scss";
import ProgressBar from "@ramonak/react-progress-bar";
const AssesmentPopupChart = (props) => {
  const percentageConverter = (val) => {
    console.log(val);
    return val;
  };

  return (
    <div className="assesment_popup_chart_container">
      <div className="heading_wrapper ">
        <div className="row">
        <div className="checked_unchecked_section col-md-6">
          <h3 className="card_title">Total Checked:</h3>
          <p className="card_detail">{props?.totalChecked || 0}</p>
          </div>
      
        <div className="checked_unchecked_section col-md-6">
          <h3 className="card_title">Total Unchecked:</h3>
          <p className="card_detail">{props?.totalUnchecked || 0}</p>
        </div>
        </div>
      </div>
      <div className="progress_chart_wrapper">
        <div className="progress_bar_wrapper">
          <h4 className="progress_label">5-star:</h4>
          <ProgressBar
            completed={props?.star?.fiveStar}
            maxCompleted={props?.totalChecked}
            width="200px"
            height="12px"
            className="percent"
            isLabelVisible={false}
            margin="10px 0"
            bgColor="#29ABE2"
          />
        </div>
        <div className="progress_bar_wrapper">
          <h4 className="progress_label">4-star:</h4>
          <ProgressBar
            completed={props?.star?.fourStar}
            maxCompleted={props?.totalChecked}
            width="200px"
            height="12px"
            className="percent"
            isLabelVisible={false}
            margin="10px 0"
            bgColor="#29ABE2"
          />
        </div>
        <div className="progress_bar_wrapper">
          <h4 className="progress_label">3-star:</h4>
          <ProgressBar
            completed={props?.star?.threeStar}
            maxCompleted={props?.totalChecked}
            width="200px"
            height="12px"
            className="percent"
            isLabelVisible={false}
            margin="10px 0"
            bgColor="#29ABE2"
          />
        </div>
        <div className="progress_bar_wrapper">
          <h4 className="progress_label">2-star:</h4>
          <ProgressBar
            completed={props?.star?.twoStar}
            maxCompleted={props?.totalChecked}
            width="200px"
            height="12px"
            className="percent"
            isLabelVisible={false}
            margin="10px 0"
            bgColor="#29ABE2"
          />
        </div>
        <div className="progress_bar_wrapper">
          <h4 className="progress_label">1-star:</h4>
          <ProgressBar
            completed={props?.star?.oneStar}
            maxCompleted={props?.totalChecked}
            width="200px"
            height="12px"
            className=""
            isLabelVisible={false}
            margin="10px 0"
            bgColor="#29ABE2"
          />
        </div>
      </div>
    </div>
  );
};

export default AssesmentPopupChart;
