export const SubjectCardShimmer = () => {
  return (
    <div className="subject-card-shimmer ">
      <div className="shimmer-card">
        <div className="d-flex">
          <div className="logo" />
          <div className="list-block">
            <div className="list-title" />
            <div className="list-content" />
          </div>
        </div>
        <div className="divider" />
        <div className="shimmer-bottom">
          <div className="icon" />
          <div className="shimmer-content" />
        </div>
      </div>
    </div>
  );
};
