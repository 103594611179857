import React, { useState } from "react";
import "./inventorycard.scss";

import searchicon from "../../images/search.svg";
import Table3 from "../inventoryTable/InventoryTable";

import plus from "../../images/plus.svg";
import { domain } from "../../url";
import RecordBtn from "../recordTransactionBtn/RecordBtn";

const InventoryCard = () => {
  const [search, setSearch] = useState("");
  return (
    <div className="card4_container">
      <div className="heading">
        <div className="title_main">Inventory List</div>
        <div className="heading_right">
          <div className="searchbox">
            <input
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder="Select Product"
            />
            <img src={searchicon} alt="search" />{" "}
          </div>
          {/* <div className="dropdown">
            <button
              className="btn btn-sm dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <img src={plus} alt="plus" />
              Add Transaction
            </button>
            <ul className="dropdown-menu">
              <li className="dropdown-item">Purchase</li>
              <li className="dropdown-item">Sales</li>
            </ul>
          </div> */}
           <div onClick={()=>{
          window.open(`${domain}/product_purchase`)
        }}><RecordBtn name="Record Transaction"/> </div>  
        </div>
      </div>
      <div>
        <Table3 search={search} />
      </div>
    </div>
  );
};

export default InventoryCard;
