import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import "./dropZone.scss";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import CancelIcon from "@material-ui/icons/Cancel";

const DropZone = (props) => {
  const [file, setFile] = useState([]);

  useEffect(() => {
    if (props.file?.name) {
      setFile([props.file]);
    }
  }, [props.file?.name]);

  const handleDrop = (acceptedFiles) => {
    setFile(acceptedFiles);
    props.onDrop(acceptedFiles);
  };

  const onDelete = (index) => {
    var removeList = [...file];
    if (index !== -1) {
      removeList.splice(index, 1);
      setFile(removeList);
      props.onDrop(removeList);
    }
  };

  return (
    <div className="drop-zone">
      <Dropzone multiple={false} onDrop={handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="drop-zone-area">
              <CloudUploadIcon />
              <p className="content">Select mage</p>
            </div>
          </div>
        )}
      </Dropzone>
      <div>
        {file.length === 0 ? (
          // <li>Select Image</li>
          <></>
        ) : (
          <ul className="file-name-block">
            <>
              {file?.map((fileName, index) => {
                return (
                  <li key={index} className="file-name">
                    {fileName.name}
                    <button
                      className="close-btn"
                      onClick={() => onDelete(index)}
                    >
                      <CancelIcon />
                    </button>
                  </li>
                );
              })}
            </>
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropZone;
