import React from "react";

export const sOnly = (
  <svg
    width="27"
    height="28"
    viewBox="0 0 27 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_2715_1491)">
      <path
        d="M9.0842 19.8741H4.46094V0.38623L15.7783 9.11091H9.0842V19.8741Z"
        fill="white"
      />
      <path
        d="M17.392 0.369385H22.0152V19.8573L10.6979 11.1326H17.392V0.369385Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_2715_1491"
        x="0.460938"
        y="0.369385"
        width="25.5543"
        height="27.5046"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2715_1491"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_2715_1491"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const baseOnly = (
  <svg
    width="70"
    height="72"
    viewBox="0 0 70 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_ddd_2715_1489)">
      <path
        d="M46.9409 10.5181H23.5352C21.9194 10.5181 20.6095 11.828 20.6095 13.4438V38.8C20.6095 40.4158 21.9194 41.7257 23.5352 41.7257H46.9409C48.5568 41.7257 49.8666 40.4158 49.8666 38.8V13.4438C49.8666 11.828 48.5568 10.5181 46.9409 10.5181Z"
        fill="url(#paint0_radial_2715_1489)"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_2715_1489"
        x="0.609497"
        y="0.518066"
        width="69.2572"
        height="71.2075"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2715_1489"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.160784 0 0 0 0 0.670588 0 0 0 0 0.886275 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_2715_1489"
          result="effect2_dropShadow_2715_1489"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10" />
        <feGaussianBlur stdDeviation="10" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.160784 0 0 0 0 0.670588 0 0 0 0 0.886275 0 0 0 0.18 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_2715_1489"
          result="effect3_dropShadow_2715_1489"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect3_dropShadow_2715_1489"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_2715_1489"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(45.6 10.5181) rotate(90) scale(39.0095 36.5714)"
      >
        <stop stop-color="#9AE1FF" />
        <stop offset="1" stop-color="#29ABE2" />
      </radialGradient>
    </defs>
  </svg>
);
