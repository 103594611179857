import React, { useEffect, useRef, useState } from "react";
import "./AttendanceVisualsRepresentation.scss";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";

export const AttendanceVisualsRepresentation = ({ dataVal }) => {
  const [percentageArray, setPercentageArray] = useState([]);
  const [timeValue, setTimeValue] = useState([]);
  const [initialtime, setInitalTime] = useState();
  const [endTime, setEndTime] = useState();

  const attendanceDetails = dataVal?.attendance_details;
  const totalTime = {
    hours: 24,
    minutes: 0,
    seconds: 0,
  };

  const calculatePercentageArray = (time) => {
    if (!time || time.every((entry) => entry === null)) {
      console.warn("No valid times to calculate. Skipping further processing.");
      return [];
    } else {
      const totalSeconds =
        totalTime.hours * 3600 + totalTime.minutes * 60 + totalTime.seconds;

      const calculatedPercentages = time?.map((time, index) => {
        const [hours, minutes, seconds] = time?.split(":").map(Number);
        const timeInSeconds = hours * 3600 + minutes * 60 + seconds;

        const calculatedPercentage = (timeInSeconds / totalSeconds) * 100;

        return calculatedPercentage;
      });

      return calculatedPercentages;
    }
  };
  useEffect(() => {
    const percentageValue = calculatePercentageArray(attendanceDetails);
    setPercentageArray(percentageValue);
  }, []);
  const timeRange = [
    "0:00:00",
    "6:00:00",
    "9:00:00",
    "13:00:00",
    "18:00:00",
    "24:00:00",
  ];

  useEffect(() => {
    const timeValue = calculatePercentageArray(timeRange);
    setTimeValue(timeValue);
  }, []);

  const convertToPMTime = (time24) => {
    // Split the time string into hours, minutes, and seconds

    const [hours, minutes, seconds] = time24
      ? time24.split(":").map(Number)
      : [0, 0, 0];

    // Create a Date object with the given hours, minutes, and seconds
    const dateObj = new Date();
    dateObj.setHours(hours, minutes, seconds);

    // Get the formatted time in 12-hour format with AM/PM
    const time12 = dateObj.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return time12;
  };

  const handleTime = (index) => {
    const entry =
      attendanceDetails[index] && convertToPMTime(attendanceDetails?.[index]);
    setInitalTime(entry);

    const end =
      attendanceDetails[index + 1] &&
      convertToPMTime(attendanceDetails?.[index + 1]);

    setEndTime(end);
  };

  return (
    <div className="attendance-visuals-section">
      <div className="time-wrap">
        {timeValue?.map((item, index) => {
          const value = timeRange.map((item) => item.split(":")?.[0]);
          return (
            <div
              className="count"
              style={{
                left: `${item}%`,
              }}
            >
              {value[index]}
            </div>
          );
        })}
      </div>

      <div className="container-wrap">
        {percentageArray.length > 0 ? (
          <>
            <p
              className={`holiday-mark ${
                dataVal.is_approved && dataVal.leave_type === "First Half Leave"
                  ? "first-half-indicator"
                  : dataVal.leave_type === "Second Half Leave"
                  ? "second-half-indicator"
                  : ""
              }`}
              title={`${dataVal.is_approved ? dataVal?.reason : ""}`}
              onClick={() => {
                if (isMobile) {
                  toast(`${dataVal.is_approved ? dataVal?.reason : ""}`);
                }
              }}
            >
              {dataVal.is_approved ? dataVal?.reason : ""}
            </p>
            {percentageArray?.map((time, index) => {
              return (
                <>
                  <div
                    key={index}
                    className={`bar-completed ${
                      convertToPMTime(attendanceDetails?.[index]) ===
                        initialtime && "positive"
                    }`}
                    title={`${attendanceDetails[index]}  ${
                      attendanceDetails[index + 1]
                        ? `- ${attendanceDetails[index + 1]}`
                        : ""
                    }`}
                    style={{
                      width: `${
                        percentageArray[index + 1] -
                        percentageArray[index]?.toFixed(2)
                      }%`,
                      left: `${time}%`,
                    }}
                    onClick={() => {
                      if (isMobile) {
                        toast(
                          `${attendanceDetails[index]}  ${
                            attendanceDetails[index + 1]
                              ? `- ${attendanceDetails[index + 1]}`
                              : ""
                          }`
                        );
                      }
                    }}
                  ></div>
                </>
              );
            })}
          </>
        ) : (
          <p
            className={`holiday-mark ${
              dataVal.is_approved && dataVal.leave_type === "First Half Leave"
                ? "first-half-indicator"
                : dataVal.leave_type === "Second Half Leave"
                ? "second-half-indicator"
                : "full-leave"
            }`}
            title={`${
              dataVal.is_approved
                ? dataVal?.reason
                : dataVal.is_holiday
                ? "Holiday"
                : "Absent"
            }`}
            onClick={() => {
              if (isMobile) {
                toast(
                  `${
                    dataVal.is_approved
                      ? dataVal?.reason
                      : dataVal.is_holiday
                      ? "Holiday"
                      : "Absent"
                  }`
                );
              }
            }}
          >
            {dataVal.is_approved
              ? dataVal?.reason
              : dataVal.is_holiday
              ? "Holiday"
              : "Absent"}
          </p>
        )}
      </div>
    </div>
  );
};
