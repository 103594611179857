import axios from "axios";
import { domain, headers, schoolRegistrationDomain } from "../../url";
import axiosInstance from "../AxiosInceptor/AxiosInstance";

export const ECAPost = (data) => {
  return axios.post(`${domain}/teacher_api/eca_performance`, data, { headers });
};
//postFeedback
export const postFeedback = (data) => {
  return axios.post(`${domain}/api/v2/teacher_api/add_feedback`, data, {
    headers,
  });
};
export const AttendancePost = (data) => {
  return axios.post(`${domain}/teacher_api/exam_attendance`, data, { headers });
};
export const ExamMarksPost = (data) => {
  return axiosInstance.post(`${domain}/new_exam_table_record`, data);
};
//Libaray review point
export const LibraryReviewPointPost = (data) => {
  return axios.post(`${domain}/bookremarks`, data, { headers });
};
export const UpdateStudentProfilePost = (data) => {
  return axios.post(`${domain}/api/mobile/student_profile_updater`, data);
};

export const addCustomStatus = (data) => {
  return axios.post(`${domain}/api/status_api`, data, { headers });
};

export const togglePreference = (values) => {
  return axios.post(`${domain}/api/assignment_marking_toggler`, values, {
    headers,
  });
};

export const postTeacherActivityForm = (values) => {
  return axiosInstance.post(`${domain}/api/submit_assignment`, values);
};

export const postTeacherRole = (values) => {
  return axios.post(`${domain}/teacher_api/add_role`, values, { headers });
};

export const postExamSubject = (values) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/assign_subjects_to_teacher`,
    values
  );
};

export const postTeacherRoutine = (values) => {
  return axios.post(`${domain}/teacher_api/add_routine`, values, { headers });
};

export const teacherClassWorkHomeWork = (data) => {
  return axiosInstance.post(`${domain}/teacher_classwork_homework`, data);
};

export const postAdmin = (data) => {
  return axiosInstance.post(`${domain}/api/v2/teacher_api/enable_admin`, data);
};

export const postSchoolRegistration = (data) => {
  return axiosInstance.post(`${schoolRegistrationDomain}/admission_form`, data);
};

export const postHodApproval = (values) => {
  return axiosInstance.post(`${domain}/post_approval`, values);
};

export const postHodRemarkSubmit = (values) => {
  return axiosInstance.post(`${domain}/teacherApi/newRemarkSubmit`, values);
};
export const postRemark = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/new_remark_submit_v2`,
    data
  );
};
export const posteachingPlan = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/submit_teacher_plans`,
    data
  );
};
//teacherSchedule
export const teacherSchedule = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/teacher_schedule`,
    data
  );
};
//outcome
export const outcome = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/teacher_outcome`,
    data
  );
};
//teacherActivity
export const teacherActivity = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/teacher_available`,
    data
  );
};
export const postActivityRemarks = async (values) => {
  return axiosInstance.post(`${domain}/teacher_activity_remark_post`, values, {
    headers,
  });
};

export const PostAdmission = (data) => {
  return axios.post("axios/admission_form ", data, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });
};

export const postApproveLeave = async (id, data) => {
  return axiosInstance.post(
    `${domain}/dashboard_api/v1/approve_leave/${id}`,
    data
  );
};

export const postStudentParent = async (data) => {
  return axiosInstance.post(
    `${domain}/student_api/add_student_parent_settings`,
    data
  );
};

export const postRegisterParent = (data) => {
  return axiosInstance.post(
    `${domain}/student_api/register_parent_detail_api`,
    data
  );
};

export const postSuggestion = (data) => {
  return axiosInstance.post(`${domain}/post_suggestion`, data);
};

export const deleteTeachingPlan = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/delete_teacher_plan`,
    data
  );
};

export const updateTeachingPlan = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/teacher_plan_update`,
    data
  );
};

export const postHodSubject = (values) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/save_hod_subjects`,
    values
  );
};
export const postTeacherPlan = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/comment_teacher_plans`,
    data
  );
};
export const updateLessonPlan = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/update_teacher_lesson_plans`,
    data
  );
};

export const postTeacherScheduleRequest = (data) => {
  return axiosInstance.post(`${domain}/api/v2/teacher_api/request_visit`, data);
};

export const postAppointmentApprove = (data) => {
  return axiosInstance.post(`${domain}/api/v2/teacher_api/approve_visit`, data);
};

export const postAppointmentComplete = (data) => {
  return axiosInstance.post(`${domain}/api/v2/teacher_api/visited`, data);
};

export const postStudentClassTransfer = (data) => {
  return axiosInstance.post(
    `${domain}/class_transfer/class_section_transfer_post`,
    data
  );
};

export const postStudentImage = (data) => {
  return axiosInstance.post(`${domain}/uploadStudentImage`, data);
};
export const postAppointmentOutcome = (data) => {
  return axiosInstance.post(`${domain}/api/v2/teacher_api/outcome`, data);
};

export const postSingleStudentFoodRecord = (data) => {
  return axiosInstance.post(
    `${domain}/save_single_student_food_record_api`,
    data
  );
};

export const postTeacherProfileUpdater = (data) => {
  return axiosInstance.post(`${domain}/teacherApi/post_teacher_profile`, data);
};

export const postProxyClassRoutine = (data) => {
  return axiosInstance.post(`${domain}/post_proxy_class_routine`, data);
};

export const postRouteselect = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/add_user_to_user_group`,
    data
  );
};
export const postManualAttendance = (data) => {
  return axiosInstance.post(`${domain}/manual_attendance`, data);
};
export const postCanteenStudentRfidDetails = (data) => {
  return axios.post(`${domain}/auth_rfid`, data);
};
export const postCanteenOrder = (data) => {
  return axiosInstance.post(`${domain}/canteen/student_menu_order_post`, data);
};
export const postStudentfeedback = (data) => {
  return axiosInstance.post(`${domain}/student_feedback_post`, data);
};
export const PostTeachingPlanEvaluation = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teaching_plan_evaluation/evaluation`,
    data
  );
};

export const PostStudentEvaluation = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teaching_plan_evaluation/evalution_data`,
    data
  );
};

export const ResetTeachingPlanEvaluation = (data) => {
  return axiosInstance.post(
    `${domain}/api/v2/teaching_plan_evaluation/reset`,
    data
  );
};

export const PostAttendanceStatus = (values) => {
  return axiosInstance.post(
    `${domain}/api/v2/teacher_api/edit_attendance_post`,
    values
  );
};
