import React from "react";
import "./StudentScoreChart.scss";
import Chart from "react-apexcharts";

const StudentScoreChart = ({ data }) => {
  const series =
    (data && data?.map((item, _index) => Number(item.percentage))) || [];

  const legend = (data && data?.map((item, _index) => item.category)) || [];

  const options = {
    labels: legend,
    chart: {
      width: 380,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex] + "%";
      },
      position: "bottom",
    },
    // title: {
    //   text: 'Gradient Donut with custom Start-angle'
    // },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
  return (
    <div className="student_score_chart_container">
      <Chart options={options} series={series} type="donut" width={400} />
    </div>
  );
};

export default StudentScoreChart;
