import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./Chart.scss";
import { LeaveData } from "../LeaveData/LeaveData";
import { AttendanceSummary } from "../AttendanceSummary/AttendanceSummary";

function Chart() {
  const [date, setDate] = useState("yearly");
  return (
    <div>
      <div className="teacher_attandance_chart_container">
        <div className="chart_wrapper ">
          <div className="chart_heading_section">
            <div className="chart_heading">
              <p>Attendance summary </p>
            </div>
            <select
              style={{ width: "150px" }}
              className="input-field"
              onChange={(e) => {
                setDate(e.target.value);
              }}
            >
              <option value="yearly">The Year</option>
              <option value="monthly">The Month</option>
            </select>
          </div>
          <div className="chart_body_container">
            <AttendanceSummary date={date} />
          </div>
        </div>
      </div>
      <LeaveData dashboard={true} />
    </div>
  );
}
export default Chart;
