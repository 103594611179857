import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getSubjectValueFromRemark } from "../Api/GetApi";
import Select from "react-select";
import "./RemarkPage.scss";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { Field, Form, Formik } from "formik";
import { postRemark } from "../Api/PostApi";
import { NepaliDatePicker } from "nepali-datepicker-reactjs";
import NepaliDate from "nepali-date";
import { toast } from "react-toastify";
import PreLoader from "../preLoader/preLoader";
import { useStudentRemark, useStudentbyGrade } from "../store/useStore";
import { useHodRemarks } from "../../store/hodDashboardStore";

export const RemarkPage = ({ showHeader, isDashboard, date }) => {
  const [remark, setRemark] = useState();
  const { data, loading, fetchStudentRemark } = useStudentRemark();
  const [grade, setGrade] = useState();
  const [remarkValue, setRemarkValue] = useState();
  const [gradeValue, setGradeValue] = useState();
  const [subject, setSubject] = useState();
  const [subjectValue, setSubjectValue] = useState();
  const [evaluationValue, setEvaluationValue] = useState();
  const [studentRemark, setStudentRemark] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [studentDescription, setStudentDescription] = useState([]);
  const [btnLoad, setBtnLoad] = useState(false);
  const [loader, setLoader] = useState();
  const { data: studentDetails, fetchStudentList } = useStudentbyGrade();

  const [dateToken, setDateToken] = useState(
    new NepaliDate().format("YYYY-MM-DD")
  );
  console.log(dateToken);

  useEffect(() => {
    fetchStudentRemark();
  }, []);

  useEffect(() => {
    // Assuming your data variable holds the fetched data
    if (studentDetails?.length > 0) {
      const tempstudentList = [];
      const tempstudentRemark = [];
      const tempstudentDescription = [];
      studentDetails?.forEach((studentData) => {
        const { student_id, description, values } = studentData;
        const firstvalues = values?.length > 0 ? values[0]?.value : "";
        tempstudentList.push(student_id);
        tempstudentRemark.push(firstvalues);
        tempstudentDescription.push(description);
      });
      setStudentList(tempstudentList);
      setStudentRemark(tempstudentRemark);
      setStudentDescription(tempstudentDescription);
    }
  }, [studentDetails]);
  useEffect(() => {
    if (data) {
      const initialRemark = {
        value: data?.remarkType?.[0]?.id,
        label: data?.remarkType?.[0]?.remark_name,
      };
      const initialGrade = {
        value: data?.grades?.[0]?.id,
        label: `${data?.grades?.[0]?.classAlpha}  ${
          data?.grades?.[0]?.section ? data?.grades?.[0]?.section : ""
        }`,
      };
      setRemarkValue(initialRemark);
      setGradeValue(initialGrade);
    }
  }, [data]);

  useEffect(() => {
    const remarkOption = data?.remarkType?.map((item) => {
      return {
        value: item?.id,
        label: item?.remark_name,
      };
    });

    const gradeOption = data?.grades?.map((item) => {
      return {
        value: item?.id,
        label: `${item?.classAlpha} ${item?.section ? item?.section : ""}`,
      };
    });

    setRemark(remarkOption);
    setGrade(gradeOption);
  }, [data]);

  useEffect(() => {
    if (remarkValue?.value) {
      setLoader(true);

      getSubjectValueFromRemark(remarkValue.value).then((res) => {
        const subjectOption = res.data.subject?.map((item) => {
          return {
            value: item?.id,
            label: item?.subjectName,
          };
        });

        setSubject(subjectOption);
        setSubjectValue(subjectOption?.[0]);
        setLoader(false);
        setEvaluationValue(res?.data?.value);
      });
    }
  }, [remarkValue]);

  const handleOptionChange = (studentId, optionId, description) => {
    const findIndex = studentList?.findIndex((item) => item === studentId);

    if (studentList.length > 0 && findIndex !== -1) {
      // If studentId exist

      if (optionId) {
        const updatedRemarks = [...studentRemark];

        if (optionId === updatedRemarks[findIndex]) {
          updatedRemarks[findIndex] = null;
          setStudentRemark(updatedRemarks);
        } else {
          updatedRemarks[findIndex] = optionId;
          setStudentRemark(updatedRemarks);
        }
      }

      const updatedDesc = [...studentDescription];
      updatedDesc[findIndex] = description;
      setStudentDescription(updatedDesc);
    } else {
      // If studentId doesn't exist, append it to the list
      setStudentList([...studentList, studentId]);
      setStudentRemark([...studentRemark, optionId]);
      setStudentDescription([...studentDescription, description]);
    }
  };

  const { fetchHodRemarks } = useHodRemarks();

  const handleDescription = (studentId, findIndex, newValue) => {
    const updatedDescription = [...studentDescription];
    updatedDescription[findIndex] = newValue;
    setStudentDescription(updatedDescription);
  };
  const handleSubmit = async () => {
    const selectedId = [];
    const selectedRemark = [];
    const selectedDescription = [];

    studentList?.forEach((curr, index) => {
      const isRemarkValue =
        studentRemark[index] === "" || studentRemark[index] === null;
      const isDescriptionValue =
        studentDescription[index] === "" || studentDescription[index] === null;
      if (!isRemarkValue || !isDescriptionValue) {
        selectedId?.push(curr);
        selectedRemark?.push(studentRemark[index]);
        selectedDescription.push(studentDescription[index]);
      }
    });

    const values = {
      remarkDate: null,
      studentToken: selectedId,
      remarkEvaluation: "Daily",
      values: selectedRemark,
      subjectToken: subjectValue?.value,
      description: selectedDescription,
      remarkTypeToken: remarkValue?.value,
      dayToken: dateToken,
    };
    setBtnLoad(true);

    try {
      const res = await postRemark(values);

      if (res.data.status === 200) {
        toast("Data Saved Successfully");
        if (isDashboard) {
          fetchHodRemarks("", date);
        }
      } else {
        toast("something went wrong");
      }
    } catch (error) {
      console.log("error", error);
      toast("something went wrong");
    } finally {
      setBtnLoad(false);
    }
  };

  useEffect(() => {
    if (gradeValue?.value) {
      const gradeToken = gradeValue?.value;
      fetchStudentList(gradeToken, dateToken, remarkValue?.value);
    }
  }, [gradeValue, dateToken, remarkValue?.value]);
  return (
    <div className="remark-page-section">
      {loading ? (
        <PreLoader />
      ) : (
        <>
          {showHeader && (
            <div className="title-block header">
              <Link to="/">
                <i className="bx bxs-chevron-left"></i>
              </Link>

              <h2 className="title">Remarks </h2>
            </div>
          )}

          <div className="container">
            <div className="mt-20">
              <div className="row">
                <div className="col-md-3">
                  <label>Select Remark Type</label>
                  {remark?.length > 0 ? (
                    <Select
                      value={remarkValue}
                      options={remark}
                      onChange={(val) => {
                        setRemarkValue(val);
                      }}
                      className="select-option"
                      placeholder="Select remark type"
                    />
                  ) : (
                    <select className="form-control">
                      <option>Select</option>
                    </select>
                  )}
                </div>

                <div className="col-md-3">
                  <label>Select Grade</label>
                  {grade?.length > 0 ? (
                    <Select
                      value={gradeValue}
                      options={grade}
                      className="select-option"
                      onChange={(val) => {
                        setGradeValue(val);
                      }}
                      placeholder="Select Grade"
                    />
                  ) : (
                    <select className="form-control">
                      <option>select</option>
                    </select>
                  )}
                </div>

                {subject?.length > 0 && (
                  <div className="col-md-3">
                    <label>Select Subject</label>
                    <Select
                      value={subjectValue}
                      options={subject}
                      className="select-option"
                      onChange={(val) => setSubjectValue(val)}
                      placeholder="Select Subject"
                    />
                  </div>
                )}
              </div>
              <div className="student-table-block">
                <div className=" table-header-block">
                  <p className="remark-title">Student Remark Table</p>

                  <div className="date-picker">
                    <label>Select Date</label>
                    <NepaliDatePicker
                      inputClassName="form-control "
                      value={dateToken}
                      onChange={(value) => {
                        setDateToken(value);
                      }}
                      options={{ calenderLocale: "ne", valueLocale: "en" }}
                    />
                  </div>
                </div>

                {loader ? (
                  <PreLoader />
                ) : (
                  <div className="table-container">
                    {studentDetails?.length > 0 ? (
                      <Formik
                        initialValues={{ remarks: "" }}
                        onSubmit={handleSubmit}
                      >
                        {({ handleSubmit }) => (
                          <Form onSubmit={handleSubmit}>
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Roll No.</th>
                                  <th>Student Name</th>
                                  {evaluationValue?.map((item) => {
                                    return (
                                      <th style={{ textAlign: "center" }}>
                                        {item?.value}{" "}
                                        <input
                                          className="check-box"
                                          type="checkbox"
                                          name="evaluation_id"
                                          checked={
                                            studentDetails?.length ===
                                              studentRemark?.length &&
                                            studentRemark.every(
                                              (value) =>
                                                parseInt(value) === item.id
                                            )
                                          }
                                          onChange={(e) => {
                                            if (e.target.checked === false) {
                                              setStudentList([]);
                                              setStudentRemark([]);
                                              setStudentDescription([]);
                                            } else {
                                              const tempstudentList = [];
                                              const tempstudentRemark = [];
                                              const tempstudentDescription = [];
                                              studentDetails?.forEach(
                                                (studentData) => {
                                                  const {
                                                    student_id,
                                                    description,
                                                  } = studentData;
                                                  const firstvalues = item.id;

                                                  tempstudentList.push(
                                                    student_id
                                                  );
                                                  tempstudentRemark.push(
                                                    firstvalues
                                                  );
                                                  tempstudentDescription.push(
                                                    description
                                                  );
                                                }
                                              );
                                              setStudentList(tempstudentList);
                                              setStudentRemark(
                                                tempstudentRemark
                                              );
                                              setStudentDescription(
                                                tempstudentDescription
                                              );
                                            }
                                          }}
                                        />
                                      </th>
                                    );
                                  })}

                                  <th style={{ textAlign: "center" }}>
                                    Remarks
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {studentDetails?.map((student, index) => {
                                  const findIndex = studentList?.findIndex(
                                    (item) => item === student.student_id
                                  );

                                  return (
                                    <tr key={index}>
                                      <td className="text-center">
                                        {student?.roll_no
                                          ? student?.roll_no
                                          : "-"}
                                      </td>
                                      <td className="student-name">
                                        {student?.student_name}
                                      </td>
                                      {evaluationValue?.map(
                                        (evaluation, index) => (
                                          <td key={index}>
                                            <div className="checkbox-field">
                                              <div className="symbol-wrap ">
                                                <Field
                                                  type="checkbox"
                                                  name={`selectedOptions.${student.student_id}`}
                                                  value={evaluation.id}
                                                  className="check-box"
                                                  checked={
                                                    parseInt(
                                                      studentRemark[findIndex]
                                                    ) === evaluation?.id
                                                  }
                                                  onChange={() => {
                                                    handleOptionChange(
                                                      student?.student_id,
                                                      evaluation?.id,
                                                      ""
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </td>
                                        )
                                      )}
                                      <td>
                                        <Field
                                          type="text"
                                          name={`selectedOptions.${student.student_id}`}
                                          value={
                                            studentDescription[findIndex] || ""
                                          }
                                          onChange={(e) => {
                                            handleOptionChange(
                                              student.student_id,
                                              null,
                                              e.target.value
                                            );
                                            handleDescription(
                                              student.student_id,
                                              index,
                                              e.target.value
                                            );
                                          }}
                                          className="form-control remark-field"
                                        />
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                            <div className="btn-fixed">
                              <button
                                className="btn-content submit-btn"
                                type="submit"
                                disabled={btnLoad}
                              >
                                {btnLoad ? "Submitting..." : "Submit"}
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <NoDataContent
                        title=""
                        image={require("../../images/emptyfile.png").default}
                        description="No data found  at the moment"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
