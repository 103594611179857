import { useSchoolRegistrationStore } from "../../pages/schoolRegistration/store/schoolRegistrationStore";
import DropZone from "../dropZone/dropZone";

export const Payment = () => {
  const schoolRegistrationState = useSchoolRegistrationStore((state) => state);
  const { setUploadReceipt, uploadReceipt } = schoolRegistrationState;

  return (
    <div className="payment">
      <div className="row">
        <div className="col-md-6 col-sm-12 mb-5">
          <div variant="outlined" className="account-card">
            <h4 className="title-content">Payment Details</h4>
            <p className="content">
              <span>Account Number : </span>09001050021951
            </p>
            <p className="content">
              <span>Account Name :</span> PABSON PROVINCE 3
            </p>

            <p className="content">
              <span> Bank Name</span> : Nepal Investment Mega Bank Ltd.(NIMB)
            </p>
            <p className="content">
              <span>Branch</span> : Kamaladi, Kathmandu
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="input-field-block">
            <p className="content">Upload Receipt</p>
            <DropZone
              file={uploadReceipt}
              onDrop={(file) => {
                if (!file.length) {
                  return setUploadReceipt({});
                }
                setUploadReceipt(file[0]);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
