import React from "react";
import "./SmallPop.scss";
import { FaX } from "react-icons/fa6";
function SmallPopup({ onClose }) {
  return (
    <>
      <div className="modal-wrapper"></div>
      <div className="small_popup">
        <div className="lay m-3">
          <div className="lay2 d-flex">
            <div className="lay3">
              <h3 className="head">
                Decline Exam Marks for First Terminal Exam - 2080
              </h3>
            </div>
            <div className="cross">
              <p type="button" onClick={onClose}>
                <FaX />
              </p>
            </div>
          </div>
          <div className="lay4">
            <div className="lay5">
              <div className="title">
                <div className="lay6">
                  <p className="title1">Reason for declining exam marks</p>
                </div>
                <input
                  type="text"
                  className="lay7 title2 mt-1"
                  placeholder="You can write reason here"
                ></input>
              </div>
              <div className="lay9 d-flex justify-content-end mt-3">
                <button className="lay10 cancel" onClick={onClose}>
                  Cancel
                </button>
                <button className="lay12 decline">Decline</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SmallPopup;
