import { CustomHeatMap, CustomHeatMapTwo } from "../../charts/customHeatMap";
import { generateMapData } from "../../charts/customHeatMap/service";
import Select from "react-select";
import "./taskCompletion.scss";
import { useState } from "react";

const monthsConfig = [
  { label: "January", value: 1, list: generateMapData(30) },
  { label: "February", value: 2, list: generateMapData(27) },
  { label: "March", value: 3, list: generateMapData(30) },
  { label: "April", value: 4, list: generateMapData(28) },
  { label: "May", value: 5, list: generateMapData(27) },
  { label: "June", value: 6, list: generateMapData(29) },
  { label: "July", value: 7, list: generateMapData(29) },
  { label: "August", value: 8, list: generateMapData(27) },
  { label: "September", value: 9, list: generateMapData(27) },
  { label: "October", value: 10, list: generateMapData(30) },
  { label: "November", value: 11, list: generateMapData(28) },
  { label: "December", value: 12, list: generateMapData(29) },
];
const infoConfig = [
  {
    label: "Tasks & Deadlines - 8",
    color: "red",
  },
  {
    label: "Submitted - 20",
    color: "green",
  },
  {
    label: "Late - 10",
    color: "red",
  },
  {
    label: "Pending - 11",
    color: "yellow",
  },
];

const monthList = monthsConfig?.map((item) => {
  return {
    label: item?.value + " Month",
    value: item?.value,
  };
});

export const TaskCompletion = () => {
  const [selectedMonthList, setSelectedMonthList] = useState(monthList[6]);
  return (
    <div className="task-completion">
      <div className="card-white">
        <div className="task-comletion-top-section">
          <h4 className="block-title">Overall Task Completion</h4>
          <div className="right-block">
            <Select
              placeholder="Select Subject"
              className="react-select"
              options={[
                { label: "Nepali", value: "Nepali" },
                { label: "Math", value: "Math" },
                { label: "English", value: "English" },
              ]}
            />
            <Select
              placeholder="Select Month"
              className="react-select"
              options={monthList}
              value={selectedMonthList}
              onChange={(val) => {
                setSelectedMonthList(val);
              }}
            />
          </div>
        </div>
        <div className="heat-map-list">
          {/* <CustomHeatMapTwo /> */}
          {monthsConfig
            ?.slice(0, selectedMonthList?.value)
            .map((item, index) => {
              return (
                <div className="list-block" key={index}>
                  <CustomHeatMap dataList={item?.list} />
                  <div className="list-title">{item?.label}</div>
                </div>
              );
            })}
        </div>
        <div className="heat-map-info">
          {infoConfig?.map((item, index) => {
            return (
              <div className="info-bock" key={index}>
                <div className={`info-color ${item?.color}`} />
                <p className="medium-content">{item?.label}</p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
