import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useUnitApi } from "../../store/hodDashboardStore";
import { SinglePlanDetails } from "../../pages/teachingPlanMain/singlePlanDetails";
import { HodAssessmentSideBar } from "../HodAssessmentSidebar/HodAssessmentSidebar";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";

export const LessonPlanBlock = React.memo((props) => {
  console.log("passed_data", props);
  const data = props?.dashboardData;
  const [filterData, setFilterData] = useState([]);
  const { UnitPlan, fetchHodUnitPlan } = useUnitApi();
  const { setPopupType, setViewedDetails } = useTeacherDashboardStore();
  const [arrayIndex, setArrayIndex] = useState(1);

  const memoizedFetchHodUnitPlan = useCallback(() => {
    fetchHodUnitPlan(data?.class_alpha, data?.subject_id, data?.month);
  }, []);

  useEffect(() => {
    if (data?.grade_token) {
      setViewedDetails({ gradeToken: data?.grade_token });
    }
  }, []);

  useEffect(() => {
    memoizedFetchHodUnitPlan();
  }, [memoizedFetchHodUnitPlan]);

  const result = useMemo(() => {
    if (UnitPlan && UnitPlan.length > 0) {
      const mergedData = {};
      UnitPlan.forEach((item) => {
        const key = `${item.classAlpha}_${item.unit_name}`;
        if (mergedData[key]) {
          mergedData[key].push(item);
        } else {
          mergedData[key] = [item];
        }
      });
      return Object.values(mergedData).map((items) => ({ key: items }));
    }
    return [];
  }, [UnitPlan]);

  useEffect(() => {
    setFilterData(result);
  }, [result]);

  function closeModal() {
    setPopupType("");
  }

  useEffect(() => {
    const dataIndex = filterData?.findIndex((item) =>
      item.key.some((obj) => obj.id === data?.unit_token)
    );
    if (dataIndex !== -1) {
      setArrayIndex(dataIndex);
    }
  }, [filterData]);
  return (
    <div>
      <div
        style={{
          height: "75vh",
          width: "100%",
        }}
        className="row"
      >
        <div style={{ height: "100%" }} className="col-md-7">
          <SinglePlanDetails
            popup={true}
            selectedGrade={data?.class_alpha}
            selectedSubject={data?.subject_id}
            selectedMonth={data?.month}
            selectedId={data?.unit_token}
            showHeader={false}
            closeModal={closeModal}
            showClassAlpha={true}
            filterData={filterData}
            arrayIndex={arrayIndex}
            removeEdit={true}
          />
        </div>
        <div style={{ height: "100%", overflowY: "auto" }} className="col-md-5">
          <HodAssessmentSideBar
            selectedGrade={data?.class_alpha}
            selectedSubject={data?.subject_id}
            selectedMonth={data?.month}
            filterData={filterData}
            arrayIndex={arrayIndex}
          />
        </div>
      </div>
    </div>
  );
});
