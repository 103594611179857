import React, { useState } from "react";
import Chart from "react-apexcharts";

export const AreaChart = () => {
  const [chartState] = useState({
    series: [
      {
        name: "series1",
        data: [5, 10, 30, 10, 20, 10, 0],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "area",
        toolbar: {
          show: false,
        },
      },

      dataLabels: {
        enabled: true,
        style: {
          fontSize: "14px",
          colors: ["#1AB394"],
          borderRadius: "50%",
        },
        background: {
          enabled: true,
          // foreColor: "#1AB394",
          opacity: 1,
          borderWidth: 2,
          // borderRadius: "50%",
        },
      },

      stroke: {
        curve: "smooth",
        colors: ["#1AB394"],
      },

      xaxis: {
        type: "datetime",
        categories: [
          "01 Jan",
          "02 Jan",
          "03 Jan",
          "04 Jan",
          "05 Jan",
          "06 Jan",
          "07 Jan",
        ],
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        colors: ["#1AB394"],
        type: "gradient",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              height: 200,
            },
          },
        },
        {
          breakpoint: 375,
          options: {
            chart: {
              height: 180,
            },
          },
        },
        {
          breakpoint: 320,
          options: {
            chart: {
              height: 150,
            },
          },
        },
      ],
    },
  });

  return (
    <div className="area-chart">
      <div id="area-chart">
        <Chart
          options={chartState?.options}
          series={chartState?.series}
          type="area"
          height={250}
        />
      </div>
    </div>
  );
};
