import { Fab } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import {
  getClassAlpha,
  getTeacherSubjectApi,
  getUnitApi,
} from "../../components/Api/GetApi";
import {
  deleteTeachingPlan,
  updateTeachingPlan,
} from "../../components/Api/PostApi";
import { NoDataContent } from "../../components/NoDataContent/NoDataContent";
import PreLoader from "../../components/preLoader/preLoader";
import { DeleteConfirmationModal } from "../../components/teachingPlanComponents/lessonTab/deleteConfirmationModal";
import { useTeachingPlanStore } from "../teachingPlan/store/teachingPlanStore";
import { useTeachingPlanMainStore } from "../teachingPlanMain/store/teachingPlanMainStore";
import { ContinueConfirmationModal } from "./continueModal";
import { EditModal } from "./editModal";
import "./index.scss";

const monthList = [
  {
    id: 1,
    monthName: "Baishak",
  },
  {
    id: 2,
    monthName: "Jestha",
  },
  {
    id: 3,
    monthName: "Ashar",
  },
  {
    id: 4,
    monthName: "Shrawan",
  },
  {
    id: 5,
    monthName: "Bhadra",
  },
  {
    id: 6,
    monthName: "Ashoj",
  },
  {
    id: 7,
    monthName: "Kartik",
  },
  {
    id: 8,
    monthName: "Mangsir",
  },
  {
    id: 9,
    monthName: "Poush",
  },
  {
    id: 10,
    monthName: "Magh",
  },
  {
    id: 11,
    monthName: "Falgun",
  },
  {
    id: 12,
    monthName: "Chaitra",
  },
];

export const MyTeachingPlan = () => {
  const [visibleType, setVisibleType] = useState();
  const [token, setToken] = useState();
  const {
    setContinueData,
    setEditMode,
    editMode,
    setViewPage,
    isAddLessonPlan,
    setIsAddLessonPlan,
    setIsUpdate,
  } = useTeachingPlanStore();
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");
  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const {
    grade,
    setGrade,
    subjectList,
    setSubjectList,
    unitList,
    setUnitList,
    setSelectedUnit,
    month,
    setMonth,
    gradeToken,
    setGradeToken,
    subjectToken,
    setSubjectToken,
    selectedUnit,
    setActiveTab,
    selectedStartDayToken,
    selectedEndDayToken,
    setSelectedUnitName,
    setUnitDataFromLocal,
    unitDataFromLocal,
  } = useTeachingPlanMainStore();

  useEffect(() => {
    const temp = localStorage.getItem("unitPlanData");
    setUnitDataFromLocal(JSON.parse(temp));
  }, [localStorage.getItem("unitPlanData")]);

  useEffect(() => {
    getClassAlpha().then((res) => {
      setGrade(res?.data?.data);
    });
  }, []);

  useEffect(() => {
    if (gradeToken) {
      getTeacherSubjectApi(gradeToken).then((res) => {
        setSubjectList(res?.data?.subject);
      });
    }
  }, [gradeToken]);

  useEffect(() => {
    if (subjectToken && subjectToken && gradeToken && month) {
      setIsLoading(true);
      getUnitApi(gradeToken, subjectToken, month).then((res) => {
        setUnitList(res?.data?.data);
        setIsLoading(false);
      });
    }
  }, [gradeToken, subjectToken, month]);

  const handleDelete = (planId) => {
    const data = {
      teacher_plan_id: planId,
    };
    deleteTeachingPlan(data)
      .then((res) => {
        toast.success("Unit Plan deleted successfully !");
        setVisibleType("");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleContinue = () => {
    setContinueData(true);
    if (!isAddLessonPlan && !editMode) {
      setViewPage("newPlan");
      setIsAddLessonPlan(false);
      setIsUpdate(false);
      setEditMode(false);
    }

    history.push(`/new_plan?token=${token}`);
  };

  const handleDiscard = () => {
    setContinueData(false);
    setViewPage("newPlan");
    setIsAddLessonPlan(false);
    setIsUpdate(false);
    localStorage.removeItem("unitPlanData");
    setUnitDataFromLocal([]);
    history.push(`/new_plan?token=${token}`);
  };

  const handleEdit = (start_day_token, end_day_token) => {
    if (start_day_token === null || start_day_token === "") {
      toast.error("Please select start date");
      return;
    }
    if (end_day_token === null || end_day_token === "") {
      toast.error("Please select end date");
      return;
    }
    const data = {
      start_day_token: start_day_token,
      end_day_token: end_day_token,
      teacher_plan_id: selectedUnit,
    };
    updateTeachingPlan(data)
      .then((res) => {
        toast.success("Unit Plan updated successfully !");
        setVisibleType("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // if(isLoading){
  //   return <PreLoader />
  // }
  return (
    <div className="my-teaching-plan-page">
      <div className="timeline ">
        <div className="title-block header">
          <h1 className="title">Teaching Plan</h1>
        </div>
        <div className="section-gap">
          <div className="container ">
            <div className="filter-section">
              <div className="grade-subject-section">
                <div className="input-field-block">
                  <p className="content">Grade</p>
                  <select
                    name="gradeToken"
                    onChange={(e) => {
                      setGradeToken(e.target.value);
                    }}
                    value={gradeToken}
                    className="input-field"
                  >
                    <option value="">Select Grade</option>
                    {grade?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="input-field-block">
                  <p className="content">Subject</p>
                  <select
                    name="subjectToken"
                    disabled={gradeToken === ""}
                    className="input-field"
                    onChange={(e) => {
                      setSubjectToken(e.target.value);
                    }}
                    value={subjectToken}
                  >
                    <option value="">Select Subject</option>
                    {subjectList?.map((item, index) => {
                      return (
                        <option value={item?.id} key={index}>
                          {item?.subjectName}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>

              <div className="input-field-block">
                <p className="content">Month</p>
                <select
                  name="month"
                  className="input-field"
                  onChange={(e) => {
                    setMonth(e.target.value);
                  }}
                  value={month}
                >
                  <option value="">Select Month</option>
                  {monthList?.map((item, index) => {
                    return (
                      <option value={item?.id} key={index}>
                        {item?.monthName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            {isLoading ? (
              <PreLoader />
            ) : unitList?.length > 0 ? (
              <>
                <div className="timeline-top-title-block">
                  <div className="row">
                    <div className="col-sm-3">
                      <p className="timeline-top-title text-right">Due Date</p>
                    </div>
                    <div className="col-sm-7">
                      <p className="timeline-top-title">Plan</p>
                    </div>
                    {/* <div className="col-sm-2">
                      <p className="timeline-top-title right">
                        <i className="bx bx-filter"></i>
                      </p>
                    </div> */}
                  </div>
                </div>

                <div className="timeline-section">
                  {unitList?.map((item, index) => {
                    return (
                      <div className="row timeline-row" key={index}>
                        <div className="col-sm-3 pr-0">
                          <div className="timeline-progress">
                            <div className="timeline-date">
                              {/* <div className="front-content">{item.due_date}</div> */}
                              <div className="front-content">
                                {item.start_date}
                              </div>

                              <div className="block-content">
                                {item.end_date}
                              </div>
                            </div>
                            <div className="timeline-progress-bar">
                              {/* <div className="checkbox">
                                <input type="checkbox" id={3} />
                                <label htmlFor={3}></label>
                              </div> */}
                              {item.is_complete ? (
                                <div
                                  className="timeline-icon"
                                  title="Completed"
                                >
                                  <i className="bx bxs-check-circle"></i>
                                </div>
                              ) : (
                                <div
                                  className="timeline-icon"
                                  title="Not Completed"
                                >
                                  <i className="bx bx-circle"></i>
                                </div>
                              )}
                            </div>
                            {unitList?.at(-1).id !== item.id && (
                              <div className="border-right"></div>
                            )}
                          </div>
                        </div>
                        <div className="col-sm-9">
                          <div
                            className="timeline-card"
                            onClick={() => {
                              setSelectedUnit(item?.id);
                              setActiveTab(0);
                              history.push(
                                `/single_unit_plan?selectedUnit=${
                                  item?.id
                                }&gradeToken=${gradeToken}&subjectToken=${subjectToken}&month=${month}&showHeader=${true}&token=${token}`
                              );
                            }}
                          >
                            <div className="card-section">
                              <div className="card-block">
                                <div className="tags-section">
                                  <div className="grade-tag">
                                    Class {item?.class_name}
                                  </div>
                                  {/* <div className="subject-tag">
                                    {item.subject}
                                  </div> */}
                                </div>
                                <div className="card-title-block">
                                  <p className="card-title">
                                    {item?.unit_name}
                                  </p>
                                </div>
                                <div className="extra-info-section">
                                  <i class="bx bx-time icon-bg"></i>
                                  <p>
                                    Total Teaching Hours:{" "}
                                    <span>{item?.total_teaching_hour}</span>
                                  </p>
                                </div>

                                {item?.comments?.length > 0 && (
                                  <>
                                    <hr />
                                    <div className="extra-info-section">
                                      <i class="bx bx-comment icon-bg"></i>
                                      <p>
                                        <span>{item?.comments?.length} </span>
                                        comments
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                              <div
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}
                                className="icon-section"
                              >
                                {/* <div
                                  className="icon"
                                  onClick={() => {
                                    // remove(index);
                                    setVisibleType("delete");
                                    setSelectedUnit(item?.id);
                                  }}
                                  title="Delete Unit Plan"
                                >
                                  <i class="bx bx-trash icon-bg"></i>
                                </div> */}
                                <div
                                  className="icon"
                                  onClick={() => {
                                    // remove(index);
                                    setSelectedUnit(item?.id);
                                    setSelectedUnitName(item?.unit_name);

                                    setViewPage("lessonTab");
                                    setIsAddLessonPlan(true);
                                    setEditMode(false);
                                    setIsUpdate(true);
                                    if (unitDataFromLocal) {
                                      setVisibleType("continue");
                                    } else {
                                      history.push(`/new_plan?token=${token}`);
                                    }
                                  }}
                                  title="Add Lesson Plan"
                                >
                                  <i class="bx bx-plus icon-bg"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <NoDataContent
                image={require("../../images/emptyfile.png").default}
                title="No Unit Plan"
                description={"No unit plan has been created yet"}
              />
            )}

            {visibleType === "delete" && (
              <DeleteConfirmationModal
                visible={visibleType === "delete"}
                setVisible={setVisibleType}
                onDelete={() => {
                  handleDelete(selectedUnit);
                }}
              />
            )}
            {visibleType === "edit" && (
              <EditModal
                visible={visibleType === "edit"}
                setVisible={setVisibleType}
                onEdit={handleEdit}
                startDateProps={selectedStartDayToken}
                endDateProps={selectedEndDayToken}
              />
            )}
            {visibleType === "continue" && (
              <ContinueConfirmationModal
                visible={visibleType === "continue"}
                setVisible={setVisibleType}
                onContinue={() => {
                  handleContinue();
                }}
                onDiscard={() => {
                  handleDiscard();
                }}
              />
            )}
          </div>
          <div
            className="floating-action-item"
            onClick={() => {
              if (unitDataFromLocal) {
                setVisibleType("continue");
              } else {
                setViewPage("newPlan");
                setIsAddLessonPlan(false);
                setEditMode(false);
                setIsUpdate(false);
                history.push(`/new_plan?token=${token}`);
              }
            }}
          >
            <Fab aria-label="add">
              <i className="bx bx-plus"></i>
            </Fab>
          </div>
        </div>
      </div>
    </div>
  );
};
