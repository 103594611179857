import { Formik, Form, Field, ErrorMessage } from "formik";
import React from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { changeReload, useStudentId, useStudentStore } from "../../store/store";
import { postRegisterParent } from "../Api/PostApi";

export const RegisterParent = ({ closeModal }) => {
  const initialValues = {
    fatherName: "",
    fatherNumber: "",
    motherName: "",
    motherNumber: "",
    guardianName: "",
    guardianNumber: "",
  };
  const student = useStudentId();
  const { fetchStudentData } = useStudentStore();
  const { shouldReload, setReload } = changeReload();
  const validationSchema = Yup.object().shape({
    fatherName: Yup.string().test(
      "fatherName",
      "Father Name is required",
      function (value) {
        const { fatherNumber } = this.parent;
        return fatherNumber?.length > 0 ? !!value : true;
      }
    ),
    fatherNumber: Yup.string()
      .matches(/^(\+977|0)?[7-9][0-9]{9}$/, "Invalid phone number")
      .test("fatherNumber", "Father Number is required", function (value) {
        const { fatherName } = this.parent;
        return fatherName?.length > 0 ? !!value : true;
      }),
    motherName: Yup.string().test(
      "motherName",
      "Mother Name is required",
      function (value) {
        const { motherNumber } = this.parent;
        return motherNumber?.length > 0 ? !!value : true;
      }
    ),
    motherNumber: Yup.string()
      .matches(/^(\+977|0)?[7-9][0-9]{9}$/, "Invalid phone number")
      .test("motherNumber", "Mother Number is required", function (value) {
        const { motherName } = this.parent;
        return motherName?.length > 0 ? !!value : true;
      }),
    guardianName: Yup.string().test(
      "guardianName",
      "Guardian Name is required",
      function (value) {
        const { guardianNumber } = this.parent;
        return guardianNumber?.length > 0 ? !!value : true;
      }
    ),
    guardianNumber: Yup.string()
      .matches(/^(\+977|0)?[7-9][0-9]{9}$/, "Invalid phone number")
      .test("guardianNumber", "Guardian Number is required", function (value) {
        const { guardianName } = this.parent;
        return guardianName?.length > 0 ? !!value : true;
      }),
  });

  const handleSubmit = async (values, { resetForm }) => {
    // Check if any of the fields have a value
    const hasValue = Object.values(values).some((value) => value !== "");

    if (hasValue) {
      // Filter out the fields that are empty

      // const payLoad = {
      //   student_id: student.studentId,
      //   parent_detail: [
      //     {
      //       father_detail: {
      //         father_name: values?.fatherName,
      //         father_number: values?.fatherNumber,
      //       },
      //       mother_detail: {
      //         mother_name: values?.motherName,
      //         mother_number: values?.motherNumber,
      //       },
      //       guardian_detail: {
      //         guardian_name: values?.guardianName,
      //         guardian_number: values?.guardianNumber,
      //       },
      //     },
      //   ],
      //   check_boolean: "true",
      // };

      const payLoad = {
        student_id: student.studentId,
        parent_detail: {
          0: {
            father_detail: {
              father_name: values?.fatherName,
              father_number: values?.fatherNumber,
            },
            mother_detail: {
              mother_name: values?.motherName,
              mother_number: values?.motherNumber,
            },
            guardian_detail: {
              guardian_name: values?.guardianName,
              guardian_number: values?.guardianNumber,
            },
          },
        },
        check_boolean: "true",
      };
      try {
        const res = await postRegisterParent(payLoad);
        if (res.data.status === 1) {
          resetForm();
          toast.success(res.data.message);
          closeModal();
        } else {
          toast.success(res.data.message);
        }
        setReload(false);

        fetchStudentData(student.studentId);
      } catch {
        toast("some error occured");
      }
    } else {
      toast.warn("Please fill out at least one field.");
    }
  };
  return (
    <div className="register-parent">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        <Form className="form">
          <div
            style={{
              marginBottom: "15px",
              padding: "15px 0px",
              borderBottom: "1px solid #e9e9e9",
            }}
          >
            <h5>Parents Detail</h5>
          </div>

          <div className="row">
            {Object.keys(initialValues).map((key) => (
              <div className="col-md-6">
                <div className="input-field-block" key={key}>
                  <label htmlFor={key}>
                    {key
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, function (str) {
                        return str.toUpperCase();
                      })}
                  </label>
                  <Field
                    type="text"
                    id={key}
                    name={key}
                    className="input-field form-control"
                  />
                  <ErrorMessage name={key} className="error" component="div" />
                </div>
              </div>
            ))}
          </div>

          <div className="button-container">
            <button onClick={closeModal} className="btn default-btn">
              Cancel
            </button>
            <button type="submit" className="btn btn-content">
              Register
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
