import React, { useState } from "react";
import "./Popup.scss";
import axios from "axios";

import { RxCross2 } from "react-icons/rx";

import SmallPopUp from "../declinePopup/SmallPopup.jsx";
import { toast } from "react-toastify";
import { postHodApproval } from "../Api/PostApi.js";
import { useHodMarksApproval } from "../../store/hodDashboardStore.js";

function Popup({ onClose, classWiseData }) {
  const { fetchHodMarksApproval } = useHodMarksApproval();
  const [showModal, setShowModal] = useState(false);

  const approve = async (id) => {
    const values = {
      approval_id: id,
    };
    try {
      const response = await postHodApproval(values);
      if (response.data.status === 1) {
        fetchHodMarksApproval();
        toast("successfully Submitted");
      } else {
        toast("Something went wrong");
      }
    } catch {
      toast("something went wrong");
    }
  };

  return (
    <>
      <div className="exam_view">
        <div>
          <div className="box">
            <div className="secondbox">
              <div className="thirdbox">
                <div className="fourthbox">
                  <div className="level">
                    <div className="levelone">
                      <p className="titletwo">EXAM NAME</p>
                    </div>
                    <div className="layoutforfirst">
                      <p className="head-h">{classWiseData?.examName}</p>
                    </div>
                  </div>
                  <div className="sub_grade d-flex">
                    <div className="titletwo mt-2">
                      <div className="grade">
                        <p className="gradetitle text-uppercase">Grade</p>
                      </div>
                      <div className="gradelayout">
                        <p className="gradename">{classWiseData?.class_name}</p>
                      </div>
                    </div>
                    <div className="levelthree mt-2">
                      <div className="subject">
                        <p className="subjecttitle text-uppercase">Subject</p>
                      </div>
                      <div className="subjectlayout">
                        <p className="subjectname">
                          {classWiseData?.subjectName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="level">
                    <div className="submit mt-3">
                      <p className="submittitle text-uppercase">Submitted By</p>
                    </div>
                    <div className="frameof d-flex">
                      <div className="first-group mt-1">
                        <div className="imagee">
                          <p className="g-group m-1 mt-1">G</p>
                        </div>
                      </div>
                      <div className="frametwo">
                        <div className="framethree">
                          <span className="text-name-of">
                            {classWiseData?.submitted_by || "null"}
                          </span>
                          <span className="text-name-of"> • </span>
                          <span className="textone"> Class Teacher of: </span>

                          <span className="text-name-of ms-1">
                            {classWiseData?.class_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <table className="table mt-3">
                    <tr style={{ background: "#EEF3F4" }}>
                      <th className="text-uppercase" style={{ width: "10%" }}>
                        Roll
                      </th>
                      <th
                        style={{ width: "30%", textAlign: "initial" }}
                        className="text-uppercase"
                      >
                        Student Name
                      </th>
                      <th
                        style={{ width: "30%", textAlign: "center" }}
                        className="text-uppercase"
                      >
                        Theory
                      </th>
                      <th
                        style={{ width: "20%", textAlign: "center" }}
                        className="text-uppercase"
                      >
                        Practical
                      </th>
                    </tr>

                    {classWiseData?.student_data?.map((d, index) => (
                      <tr key={index}>
                        <td style={{ textAlign: "center" }}>{d.roll_no}</td>
                        <td className="nameframe nametitle">{d?.student_id}</td>
                        <td
                          style={{ textAlign: "center" }}
                          className="theory-practical-frame theory-practical-title"
                        >
                          {d?.theory_marks ? d?.theory_marks : "-"}
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                          className="theory-practical-frame theory-practical-title"
                        >
                          {d?.practical_marks ? d?.practical_marks : "-"}
                        </td>
                      </tr>
                    ))}
                  </table>
                  <div className="lastframe d-flex justify-content-between align-items-center">
                    <button
                      type="button"
                      className="button declinetext"
                      onClick={onClose}
                    >
                      Close
                    </button>
                    {showModal && (
                      <SmallPopUp onClose={() => setShowModal(false)} />
                    )}
                    <button
                      type="button"
                      className="approvebutton approvetext"
                      onClick={() => {
                        approve(classWiseData?.id);
                        onClose();
                      }}
                    >
                      Approve
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Popup;
