import { ErrorMessage, Formik, useFormikContext } from "formik";
import NepaliDate from "nepali-date-converter";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ToastContainer, toast } from "react-toastify";
import {
  posteachingPlan,
  updateLessonPlan,
} from "../../../components/Api/PostApi";
import { useTeachingPlanMainStore } from "../../teachingPlanMain/store/teachingPlanMainStore";
import { useTeachingPlanStore } from "../store/teachingPlanStore";
import { requiredValidation } from "./validationSchema";

import { getUnitApi } from "../../../components/Api/GetApi";
import { domain, portalDomain } from "../../../url";
import * as Sentry from "@sentry/browser";
import fetchToCurl from "fetch-to-curl";
import { useIframeTokenStore } from "../../../components/store/useStore";
export const lessonPlansObj = {
  teacher_plan_id: "",
  topic: "",
  total_time: "",
  from_page: "",
  to_page: "",
  objective: "",
  response: {
    teaching_methods: "",
    time: "",
  },
  rules: {
    teaching_methods: "",
    time: "",
  },
  preview: {
    teaching_methods: "",
    time: "",
    resources: "",
  },
  pedagogy: {
    teaching_methods: "",
    time: "",
    resources: "",
    keywords: [],
  },
  skills: {
    teaching_methods: "",
  },
  planned_homework: "",
  planned_classwork: "",
};

const initialValues = {
  unit_name: "",
  total_teaching_hour: "",
  start_day_token: null,
  end_day_token: null,
  gradeToken: "",
  subjectToken: "",
  unit_objective: "",
  lesson_plans: [lessonPlansObj],
};

export const useTeachingPlanFormikWrapper = () => {
  const formik = useFormikContext();
  if (!formik) {
    throw new Error("Formik context must be used within a Formik");
  }
  return formik;
};
export const FormikWrapper = ({ children }) => {
  const {
    viewPage,
    activeTab,
    setActiveTab,
    isAddLessonPlan,
    setIsAddLessonPlan,
    setIsUpdate,
    continueData,
    setContinueData,
    editMode,
  } = useTeachingPlanStore();

  const {
    selectedUnitName,
    gradeToken,
    subjectToken,
    unitDataFromLocal,
    setUnitDataFromLocal,
    lessonPlanDetails,
    selectedLessonId,
    redirect,
  } = useTeachingPlanMainStore();
  const history = useHistory();
  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");
  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const handleSuccess = (toastMessage, resetForm, history, token) => {
    toast.success(toastMessage);
    resetForm();
    if (redirect) {
      window.parent.postMessage("closeModal", `${portalDomain}`);
      // window.parent.postMessage("closeModal", `http://demo.lvh.me:3000`);
    } else {
      history.push(`/teaching-plan2?token=${token}`);
    }
  };

  const handleError = (error) => {
    console.log(error);
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    const data = !isAddLessonPlan
      ? {
          unit_name: values.unit_name,
          total_teaching_hour: values.total_teaching_hour,
          start_day_token: values.start_day_token,
          end_day_token: values.end_day_token,
          gradeToken: values.gradeToken,
          subjectToken: values.subjectToken,
          unit_objective: values.unit_objective,
          lesson_plans: values.lesson_plans,
        }
      : editMode
      ? {
          unitName: selectedUnitName,
          gradeToken: gradeToken,
          subjectToken: subjectToken,
          lesson_plan_id: selectedLessonId,
          lesson_plans: values.lesson_plans,
        }
      : {
          unitName: selectedUnitName,
          gradeToken: gradeToken,
          subjectToken: subjectToken,
          lesson_plans: values.lesson_plans,
        };
    const handleFinalSteps = () => {
      setActiveTab(0);
      setIsAddLessonPlan(false);
      setIsUpdate(false);
      setContinueData(false);
      localStorage.removeItem("unitPlanData");
      setUnitDataFromLocal([]);
    };

    const postOrUpdatePlan =
      isAddLessonPlan || editMode ? updateLessonPlan : posteachingPlan;

    postOrUpdatePlan(data)
      .then((res) => {
        handleSuccess(res?.data?.message, resetForm, history, token);
        setActiveTab(activeTab + 1);
        setSubmitting(false);
        if (values.lesson_plans.length === activeTab + 1) {
          handleFinalSteps();
        }

        const url = `${domain}/api/v2/teacher_api/submit_teacher_plans`; // The same URL used in Axios
        const options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, // Assuming you use a token in headers
          },
          body: JSON.stringify(data),
        };
        const curlCommand = fetchToCurl(url, options);
        console.log("Generated cURL command:");
        console.log(curlCommand);

        Sentry.captureMessage(curlCommand);
      })
      .catch(handleError);
  };

  const getContinueDataInitialValue = (continueData) => {
    switch (continueData) {
      case true:
        return unitDataFromLocal;
      case false:
        return initialValues;
      default:
        return initialValues;
    }
  };

  const getInitialValues = (continueData, editMode) => {
    switch (editMode) {
      case true:
        const tempInitialValue = {
          unit_name: "",
          total_teaching_hour: "",
          start_day_token: null,
          end_day_token: null,
          gradeToken: "",
          subjectToken: "",
          unit_objective: "",
          lesson_plans: [
            {
              teacher_plan_id: lessonPlanDetails?.[0]?.teacher_plan_id,
              topic: lessonPlanDetails?.[0]?.topic,
              total_time: lessonPlanDetails?.[0]?.total_time,
              from_page: lessonPlanDetails?.[0]?.from_page,
              to_page: lessonPlanDetails?.[0]?.to_page,
              objective: lessonPlanDetails?.[0]?.objective,
              response: lessonPlanDetails?.[0]?.response,
              rules: lessonPlanDetails?.[0]?.rules,
              preview: lessonPlanDetails?.[0]?.preview,
              pedagogy: lessonPlanDetails?.[0]?.pedagogy,
              skills: lessonPlanDetails?.[0]?.skills,
              planned_homework: lessonPlanDetails?.[0]?.planned_homework,
              planned_classwork: lessonPlanDetails?.[0]?.planned_classwork,
            },
          ],
        };
        return tempInitialValue;
      case false:
        return getContinueDataInitialValue(continueData);
      default:
        return initialValues;
    }
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={getInitialValues(continueData, editMode)}
        validationSchema={requiredValidation(viewPage, isAddLessonPlan)}
        onSubmit={handleSubmit}
      >
        {children}
      </Formik>
    </>
  );
};
export const TeachingPlanError = ({ name }) => {
  return <ErrorMessage name={name} component="div" className="error" />;
};
