import React, { useEffect, useState } from "react";
import "./preview.scss";
import html2pdf from "html2pdf.js";

import { capitalize } from "@material-ui/core";
import { PreviewTable } from "./previewTable";
import { useChildData, useStudentRecords, useTabList } from "../store/useStore";

export const Preview = () => {
  const [activeIndex, setactive] = React.useState(0);
  const [item, setItem] = React.useState([]);

  const [studentId, setStudentId] = React.useState();
  const [preview, setPreview] = useState(null);
  const { fetchTablist, data } = useTabList();
  const {
    fetchChildData,
    data: studentList,
    loading: isLoading,
  } = useChildData();
  const { fetchStudentRecords, data: htmldetails } = useStudentRecords();

  React.useEffect(() => {
    fetchChildData();
  }, []);

  React.useEffect(() => {
    setStudentId(studentList[0]?.studentToken);
  }, [studentList]);

  React.useEffect(() => {
    if (studentId) {
      fetchTablist(studentId);
    }
  }, [studentId]);

  useEffect(() => {
    setItem(data?.[0]?.data);
  }, [data]);
  const onSelectChange = (event) => {
    const selected = event.target.selectedOptions[0].value;
    setStudentId(selected);
  };
  const generatePDF = async (title) => {
    const options = {
      margin: 1,
      filename: `${title}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    //await html2pdf().from(html).set(options).save();
    await html2pdf()
      .from(htmldetails)
      .set(options)
      .outputPdf("datauri")
      .then((value) => {
        if (window) {
          window.downloadChannel.postMessage(value);
        }
      });
  };

  const handleOnClick = (data) => {
    setPreview(data);
  };

  return (
    <>
      {preview === null ? (
        <div className="preview-page">
          <div className="title-block header center">
            <h2 className="title">Document</h2>
          </div>
          {isLoading ? (
            <div className="pre-loader">
              <h4 className="content center">Loading...</h4>
            </div>
          ) : (
            <div className="container">
              <div className="block-gap">
                {studentList.length > 1 && (
                  <select
                    className="input-field"
                    onChange={onSelectChange}
                    value={studentId}
                  >
                    <option value="" disabled>
                      Select Student
                    </option>
                    {studentList.map((student) => (
                      <option value={student.studentToken}>
                        {student.studentName}
                      </option>
                    ))}
                  </select>
                )}
              </div>

              <div>
                <p className="content">Select the Preview Type</p>
                <div className="result-section">
                  {data?.map((item, index) => {
                    return (
                      <div
                        onClick={() => {
                          setItem(item?.data);
                          setactive(index);
                        }}
                        className={`nav-bar-item ${
                          activeIndex === index ? "active" : ""
                        }`}
                        key={index}
                      >
                        <p>{capitalize(item.type)}</p>
                      </div>
                    );
                  })}
                </div>
                <div className="nav-bar-content">
                  {item ? (
                    item.map((data, index) => {
                      return (
                        <div className="nav-bar-content-item" key={index}>
                          <p className="content">{capitalize(data.title)}</p>
                          {/*<p>{data.id}</p>*/}
                          <div className="content-actions">
                            <span
                              onClick={() => {
                                handleOnClick(data);
                              }}
                            >
                              <span className="icon-btn">
                                <i className="bx bx-show-alt"></i>
                              </span>
                            </span>
                            <span
                              className="icon-btn"
                              onClick={async () => {
                                fetchStudentRecords(data?.id);
                                generatePDF(data.title);
                              }}
                            >
                              <i className="bx bx-download"></i>
                            </span>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <p className="content center"> No data found</p>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <PreviewTable
          id={preview.id}
          title={preview.title}
          onBack={() => setPreview(null)}
        />
      )}
    </>
  );
};
