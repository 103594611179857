import React from "react";
import "./Banner.scss";
import navbarImage from "../assets/navbar-image.png";

export const Banner = () => {
  return (
    <div className="banner">
      <div className="row">
        <div className="col-md-8 ">
          <div className="banner-block">
            <h4 className="title">What do you want to learn today ?</h4>
            <p className="content ">
              Welcome back! let's start learning to expand your knowledge and
              skills.
            </p>
          </div>
        </div>

        <div className="col-md-4">
          <div className="banner-image">
            <img src={navbarImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
