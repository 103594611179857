import React, { useEffect } from "react";
import { FieldArray, Field, ErrorMessage, useFormikContext } from "formik";

export const FeedbackTablebody = ({
  gradeValue,
  studentValue,
  feedback,
  loader,
  setFieldValue,
}) => {
  const { values } = useFormikContext();

  return (
    <div className="feedback-table-body">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Remark Type</th>
            <th>Remark</th>
            <th>Action</th>
          </tr>
        </thead>

        {gradeValue && studentValue ? (
          <FieldArray name="remarkList">
            {({ remove, push }) => (
              <tbody>
                {values.remarkList.map((_, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Field
                          as="select"
                          name={`remarkList[${index}].remark_type`}
                          className="students_name custom-select mb-5"
                          aria-label="Default select example"
                        >
                          <option value="">Select Feedback Type</option>
                          {feedback?.map((item) => {
                            const selectedOption = values.remarkList?.filter(
                              (val) =>
                                parseInt(val.remark_type) === parseInt(item.id)
                            );

                            const alreadySelected =
                              selectedOption && selectedOption.length > 0;

                            return (
                              <option
                                className={`${
                                  alreadySelected && "display-none"
                                }`}
                                key={item.id}
                                value={item.id}
                              >
                                {item.feedback_type}
                              </option>
                            );
                          })}
                        </Field>

                        <ErrorMessage
                          name={`remarkList[${index}].remark_type`}
                          component="div"
                          className="text-danger"
                        />
                      </td>
                      <td>
                        <Field
                          type="text"
                          name={`remarkList[${index}].remark`}
                          className="form-control mb-5"
                        />
                        <ErrorMessage
                          name={`remarkList[${index}].remark`}
                          component="div"
                          className="text-danger"
                        />
                      </td>
                      <td>
                        <div className="action-btn-block">
                          <button
                            className={`btn trash-btn ${
                              values.remarkList.length === 1 &&
                              index === 0 &&
                              "disable-cursor"
                            }`}
                            type="button"
                            onClick={() => remove(index)}
                            disabled={
                              values.remarkList.length === 1 && index === 0
                            }
                            title={
                              values.remarkList.length === 1 && index === 0
                                ? "disabled"
                                : "remove row"
                            }
                          >
                            <i className="bx bx-x"></i>
                          </button>
                          <button
                            type="button"
                            className="btn add-btn"
                            onClick={() =>
                              push({ remark_type: "", remark: "" })
                            }
                            title="add new row"
                          >
                            <i className="bx bx-plus"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            )}
          </FieldArray>
        ) : (
          <tbody>
            <tr>
              <td colSpan={3} className="text-center">
                Please select grade and student
              </td>
            </tr>
          </tbody>
        )}
      </table>

      <button
        type="submit"
        className={`btn submit_btn ${loader && "disable-cursor"}`}
        disabled={loader || !gradeValue || !studentValue}
      >
        {loader ? "submitting.." : "Submit"}
      </button>
    </div>
  );
};
