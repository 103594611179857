import React from "react";
import "./examMarks.scss";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { useExamMarksStore } from "../../store/examMarksStore";
import { useFormikContext } from "formik";

export const ViewExamMarks = () => {
  const examMarksState = useExamMarksStore((state) => state);
  const { values } = useFormikContext();
  return (
    <div className="table-section">
      {examMarksState?.examStudentData &&
      examMarksState?.examStudentData?.[0]?.data?.length > 0 ? (
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th width="10%">Roll</th>
              <th width="50%">Student Name</th>
              {values.internal_eval ? (
                <>
                  {examMarksState?.selectedOption?.map((item, index) => {
                    return (
                      <th width="25%" key={index}>
                        {item?.label}
                      </th>
                    );
                  })}
                </>
              ) : (
                <>
                  <th width="25%">
                    <div class="full-mark-section">
                      <p>Theory</p>
                      <p>
                        (Fullmarks:{" "}
                        <span>
                          {examMarksState?.subjectWeightage?.max_theory}
                        </span>
                        )
                      </p>
                    </div>
                  </th>
                  {!values.cas_eval &&
                    examMarksState?.subjectWeightage?.max_practical > 0 && (
                      <th width="25%">
                        <div class="full-mark-section">
                          <p> Practical</p>
                          <p>
                            {" "}
                            (Fullmarks:
                            <span>
                              {examMarksState?.subjectWeightage?.max_practical}
                            </span>
                            )
                          </p>
                        </div>
                      </th>
                    )}
                </>
              )}
            </tr>
          </thead>

          <tbody>
            {examMarksState?.examStudentData?.[0]?.data
              ?.sort((a, b) => a.rollNo - b.rollNo)
              ?.map((item, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    <td width="10%">{item.rollNo}</td>
                    <td width="50%">{item.studentName}</td>
                    {examMarksState?.examStudentData?.map((itm, colIndex) => {
                      return (
                        <>
                          <td width="25%" key={colIndex}>
                            {itm?.data?.[rowIndex]?.theory_mark}
                          </td>
                          {!values.internal_eval &&
                            !values.cas_eval &&
                            examMarksState?.subjectWeightage?.max_practical >
                              0 && (
                              <td width="25%">
                                {itm?.data?.[rowIndex]?.pratical_mark}
                              </td>
                            )}
                        </>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      ) : (
        <NoDataContent
          title=""
          image={require("../../images/emptyfile.png").default}
          description={
            examMarksState?.examStudentData?.length === 0
              ? "Select exam, grade, subject and evaluation type for students list to appear here"
              : "Please add exam marks for the selected class and subject to appear here"
          }
        />
      )}
    </div>
  );
};
