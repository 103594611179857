import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { TeachingPlanError } from "../../../pages/teachingPlan/formikWrapper/formikWrapper";
import { InputField } from "../../ui/formikFields/inputField";
import { TextField } from "../../ui/formikFields/textField";
import { FlowSheet } from "./flowSheet";
import "./lessonForm.scss";
import { getAvailableResources } from "../../Api/GetApi";
import { toast } from "react-toastify";

const accordionConfig = [
  "response",
  "rules",
  "preview",
  "pedagogy",
  "skills",
  "homework",
  "classwork",
];

export const LessonForm = ({ fieldArrayIndex }) => {
  const [expanded, setExpanded] = useState(accordionConfig?.map(() => true));
  const [availableResources, setAvailableResources] = useState();

  const handleChange = (panelIndex) => (event, isExpanded) => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[panelIndex] = isExpanded;
      return newExpanded;
    });
  };

  useEffect(() => {
    getAvailableResources()
      .then((res) => {
        setAvailableResources(res?.data?.data);
      })
      .catch((err) => {
        toast.error(err);
      });
  }, [])

  return (
    <div className="lesson-form">
      <div className="section-gap">
      <h4 className="title lesson-title">Lesson Details</h4>
        <div className="lesson-section">
          
          <div className="input-field-block">
            <p className="content">Topic/Subject area/Content <span>*</span></p>
            <InputField
              name={`lesson_plans.${fieldArrayIndex}.topic`}
              className="input-field"
              placeHolder="Enter Topic/Subject area/Content"
            />
            <TeachingPlanError name={`lesson_plans.${fieldArrayIndex}.topic`} />
          </div>
          <div className="input-field-block">
            <p className="content">Total time</p>
            <div className="time-block">
              <InputField
                name={`lesson_plans.${fieldArrayIndex}.total_time`}
                className="input-field"
                type="number"
                placeHolder="Enter total time "
              />
              <p className="content">minutes</p>
              <TeachingPlanError
                name={`lesson_plans.${fieldArrayIndex}.total_time`}
              />
            </div>
          </div>
          <div className="page-no">
            <div className="input-field-block">
              <p className="content">From Page No.</p>
              <InputField
                name={`lesson_plans.${fieldArrayIndex}.from_page`}
                className="input-field"
                type="number"
                placeHolder="0"
              />
              <TeachingPlanError
                name={`lesson_plans.${fieldArrayIndex}.from_page`}
              />
            </div>
            <div className="input-field-block">
              <p className="content">To Page No</p>
              <InputField
                name={`lesson_plans.${fieldArrayIndex}.to_page`}
                className="input-field"
                type="number"
                placeHolder="0"
              />
              <TeachingPlanError
                name={`lesson_plans.${fieldArrayIndex}.to_page`}
              />
            </div>
          </div>
          <div className="input-field-block">
            <p className="content">Lesson Objectives</p>
            <TextField
              name={`lesson_plans.${fieldArrayIndex}.objective`}
              type="text-area"
              className="text-field"
              placeHolder="Enter lesson objectives here"
            />
            <TeachingPlanError
              name={`lesson_plans.${fieldArrayIndex}.objective`}
            />
          </div>
          <hr /> 
          <div className="accordion">
            <h4 className="title">Lesson Flow Sheet</h4>
            {accordionConfig?.map((item, accIndex) => {
              const panelIndex = accIndex;
              let resourcesForPanel = [];
              availableResources?.forEach((resourceGroup) => {
                if (resourceGroup[item]) {
                  resourcesForPanel = resourceGroup[item];
                }
              });
              return (
                <div className="accordion-list" key={accIndex}>
                  <Accordion
                    expanded={expanded[panelIndex]}
                    onChange={handleChange(panelIndex)}
                  >
                    <AccordionSummary
                      expandIcon={<i class="bx bx-chevron-down"></i>}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <h4 className="accordion-title">
                        Step {accIndex + 1} ({item})
                      </h4>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="accordion-body">
                        <FlowSheet
                          keyName={item}
                          fieldArrayIndex={fieldArrayIndex}
                          resourcesForPanel={resourcesForPanel}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
