import React, { useEffect, useMemo, useState } from "react";
import "./ClassProgress.scss";

import Chart from "react-apexcharts";
import { useTeacherProfile } from "../../../store/teacherProfileStore";
import { getTeacherPlanClassProgress } from "../../Api/GetApi";
import { NoDataContent } from "../../NoDataContent/NoDataContent";

const ClassProgress = () => {
  const [isUnitWise, setIsUnitWise] = useState(false);
  const [data, setData] = useState([]);

  const colorSwitch = (val) => {
    switch (val) {
      case "completed":
        return "#3DD598";
        break;
      case "pending":
        return "#FFCD5A";
        break;
      case "ongoing":
        return "#3DD598";
        break;
      case "overdue":
        return "#EA5455";
        break;
      default:
        return "#C77EB7CC";
    }
  };

  // const modifiedData = Object.keys(data).length > 0 && isUnitWise ? data?.unit_wise :data?.lesson_wise || []

  const modifiedData = useMemo(() => {
    
    return  isUnitWise
      ? data?.unit_wise
      : data?.lesson_wise ;
  }, [data, isUnitWise]);

  const getSeries = useMemo(() => {
    if (modifiedData?.length > 0) {
      const tempSeries = modifiedData?.map((item) => {
        return item?.percentage !== undefined &&
          item?.percentage !== null &&
          item?.percentage !== ""
          ? Number(item?.percentage)
          : 0;
      });
      return tempSeries;
    } else {
      return [];
    }
  }, [modifiedData]);

  const getLabel = useMemo(() => {
    if (modifiedData?.length > 0) {
      const tempSeries = modifiedData?.map((item) => {
        return item?.status;
      });
      return tempSeries;
    } else {
      return [];
    }
  }, [modifiedData]);

  const getColor = useMemo(() => {
    if (getLabel?.length > 0) {
      const tempColor = getLabel?.map((item) =>
        colorSwitch(item?.toLowerCase())
      );
      return tempColor;
    } else {
      return [getLabel];
    }
  }, []);
  const { selectedGrade, selectedSubject } = useTeacherProfile();

  useEffect(() => {
    if (
      selectedGrade !== undefined &&
      selectedGrade !== null &&
      selectedSubject !== undefined &&
      selectedSubject !== null
    ) {
      const api = async () => {
        const res = await getTeacherPlanClassProgress(
          selectedGrade,
          selectedSubject
        );

        if (res.status === 200) {
          if (res.data.status === 1) {
            setData(res?.data?.data);
          } else {
            setData([]);
          }
        } else {
          setData([]);
        }
      };

      api();
    }
  }, [selectedGrade, selectedSubject]);

  const series = getSeries;
  const options = {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: getLabel,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="classProgress_wrapper component_wrapper">
      <div className="top_section">
        <div className="heading_section">
          <h2 className="heading">Class Progress</h2>
          <div className="switch">
            <div className="slide-container">
              <input
                type="radio"
                name="unitWise"
                id="unit_wise"
                onChange={() => {
                  setIsUnitWise(true);
                }}
                checked={isUnitWise}
              />
              <label
                htmlFor="unit_wise"
                className={isUnitWise ? "selected" : ""}
              >
                Unit-wise
              </label>
              <input
                type="radio"
                name="lessonWise"
                id="lesson_wise"
                onChange={() => {
                  setIsUnitWise(false);
                }}
                checked={!isUnitWise}
              />
              <label
                htmlFor="lesson_wise"
                className={!isUnitWise ? "selected" : ""}
              >
                Lesson-wise
              </label>
            </div>
          </div>
        </div>
        {/* <div className="dropdown_wrapper">
        <Select
            placeholder="Select Grade"
            className="react-select"
            options={classDropdown}
          />
          <Select
            placeholder="Select Subject"
            className="react-select"
            options={subjectDropdown}
          />
        </div> */}
      </div>
      <div style={{ height: "330px" }} className="chart_section">
        {data?.length === 0 ? (
          <div style={{paddingTop: "40px"}}>
          <NoDataContent
            image={require("../../../images/emptyfile.png").default}
            title="No Data Found"
            description="make sure that grade and date filter is selected"
          />
          </div>
        ) : (
          <Chart options={options} series={series} type="pie" width={400} />
        )}
      </div>
    </div>
  );
};

export default ClassProgress;
