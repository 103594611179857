import React, { useEffect, useState } from "react";
import {
  useAllSubjects,
  useExamSubject,
  useExamSubjectList,
  useHodSubjectList,
  useSubjectForExam,
  useSubjectList,
  useTeacherDetailsStore,
} from "../store/useStore";
import { postExamSubject, postHodSubject } from "../Api/PostApi";
import { toast } from "react-toastify";
import Select from "react-select";
import CheckboxSelect from "../../utils/services/CheckboxSelect/CheckboxSelect";

export const HodSubjectPopup = ({ closeModal }) => {
  const [subjectValue, setSubjectValue] = useState([]);
  const [examSubjectValue, setExamSubjectValue] = useState();
  const { data: details } = useTeacherDetailsStore();
  const [subjectData, setSubjectData] = useState();
  const [ExamSubjectData, setExamSubjectData] = useState();
  const { fetchSubjectForExam, data: value } = useSubjectForExam();
  const { fetchSubjectList, data: item } = useSubjectList();
  const { fetchHodSubjectList } = useHodSubjectList();

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchSubjectForExam(details.teacher_id);
    fetchSubjectList();
  }, [details]);
  useEffect(() => {
    const gradeOption = item?.map((itm) => {
      return {
        value: itm?.id,
        label: `${itm?.subjectName} `,
      };
    });
    setSubjectData(gradeOption);
  }, [item]);

  useEffect(() => {
    const examOption = value?.map((itm) => {
      return {
        value: itm?.id,
        label: `${itm?.subjectName}`,
      };
    });
    setExamSubjectData(examOption);
  }, [value]);
  const AssignSubject = async () => {
    const subjectList = subjectValue?.map((item) => {
      return item.value;
    });

    const ExamSubjectList = examSubjectValue?.map((item) => {
      return item.value;
    });

    const values = {
      //   teacher_token: details?.teacher_id,
      subject_list: subjectList || [],
      exam_subject_list: ExamSubjectList || [],
    };

    if (values.subject_list.length <= 0 && values.exam_subject_list <= 0) {
      toast("please select a subject");
    } else {
      setLoader(true);

      try {
        const res = await postHodSubject(values);
        if (res.status === 200) {
          toast(res?.message);
          closeModal();
          fetchHodSubjectList();
        } else {
          toast("something went wrong");
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <div className="exam-subject">
      <div className="form-group">
        <label>Select Exam subject</label>

        <CheckboxSelect
          optionvalue={ExamSubjectData}
          setState={setExamSubjectValue}
          state={examSubjectValue}
        />
      </div>

      <div className="form-group">
        <label>Select subject</label>

        <CheckboxSelect
          optionvalue={subjectData}
          setState={setSubjectValue}
          state={subjectValue}
        />
      </div>

      <div className="footer-btn-section end-block mt-20">
        <button className=" btn cancel-btn mr-10" onClick={closeModal}>
          Cancel
        </button>
        <button
          className={`btn btn-content custom-btn ${loader && "disable-cursor"}`}
          disabled={loader}
          onClick={() => {
            AssignSubject();
          }}
        >
          {loader ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};
