import React, { useContext, useEffect, useState } from "react";
import "./AddPreferenceModal.scss";
import Modal from "react-awesome-modal";
import { CustomStatusModal } from "../CustomStatusModal/CustomStatusModal";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { domain } from "../../url";
import { toast } from "react-toastify";
import { togglePreference } from "../Api/PostApi";
import { useAssignmentDetails, usePreferenceStore } from "../store/useStore";
import { AssessmentContext } from "../TeacherAssessment/TeacherAssessment";

export const AddPreferenceModal = ({
  closeModal,
  assignmentToken,
  gradeId,
  dashboard,
  showHeader,
}) => {
  const { preferenceData, loading, data } = usePreferenceStore();
  const [modalVisible, setModalVisible] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const id = useContext(AssessmentContext);
  const { fetchAssignmentDetails } = useAssignmentDetails();
  const closeStatusModal = () => {
    setModalVisible(false);
  };

  toast.configure({
    zIndex: 999999,
  });

  const selectedGrade = dashboard ? gradeId : id.gradeToken;
  useEffect(() => {
    preferenceData();
  }, []);

  const handleRemove = (id) => {
    axios
      .delete(`${domain}/api/delete_status?id=${id}`)
      .then((res) => {
        preferenceData();
        toast(res.data);
        if (!dashboard) {
          closeModal();
        }
        if (selectedGrade && assignmentToken) {
          fetchAssignmentDetails({ selectedGrade, assignmentToken });
        }
      })
      .catch((err) => {
        console.log(err);
        toast("something went wrong");
      });
  };

  const initialValues = {
    assignment_remarks: data?.school_settings?.[0]?.assignment_remarks,
    assignment_markings: data?.school_settings?.[0]?.assignment_marking,
  };

  return (
    <div className="add-preference-modal">
      <Modal
        visible={modalVisible}
        // height="80%"
        width={dashboard ? "50%" : "100%"}
        effect="fadeInUp"
        className="popuo-modal"
        onClickAway={() => closeStatusModal()}
      >
        <div className="modal-details-section">
          {showHeader && (
            <div className="modal-header">
              <div className="title-content">
                <div className="icon-block">
                  <i className="bx bxs-cog"></i>
                </div>
                <p className="modal-title">Preferences</p>
              </div>

              <div className="close-icon" onClick={closeStatusModal}>
                <i className="bx bx-x"></i>
              </div>
            </div>
          )}
          <div className="modal-body">
            <CustomStatusModal
              closeStatusModal={closeStatusModal}
              closeModal={closeModal}
            />
          </div>
        </div>
      </Modal>

      {loading ? (
        <p style={{ textAlign: "center", marginTop: "10px" }}>loading...</p>
      ) : (
        <>
          {data?.school_settings?.length > 0 && (
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                setIsBtnLoading(true);

                try {
                  togglePreference(values).then((res) => {
                    if (res.status === 200) {
                      closeModal();
                      toast("Preference Changes Updated");
                      preferenceData();
                    } else {
                      closeModal();
                      toast("something went wrong");
                    }
                  });
                } catch (err) {
                  console.log(err);
                } finally {
                  setIsBtnLoading(false);
                }
              }}
            >
              {({ setFieldValue }) => (
                <Form>
                  {/* <div className="form-group">
                    <p className="title field-title">Show Field</p>
                    <div className="checkbox-list">
                      <div className="remark-field">
                        <div className="symbol-wrap ">
                          <Field
                            type="checkbox"
                            name="assignment_remarks"
                            className="check-box"
                            onChange={(e) => {
                              setFieldValue(
                                "assignment_remarks",
                                e.target.checked
                              );
                            }}
                          />

                          <p className="field-label">Remark</p>
                        </div>
                      </div>

                      <div className="remark-field">
                        <div className="symbol-wrap ">
                          <Field
                            type="checkbox"
                            name="assignment_markings"
                            className="check-box"
                            onChange={(e) =>
                              setFieldValue(
                                "assignment_markings",
                                e.target.checked
                              )
                            }
                          />
                          <p className="field-label">Marks</p>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="form-group">
                    <p className="title field-title">Status</p>
                    {data !== "undefined" && (
                      <div className="checkbox-list">
                        {data?.assignment_statuses?.map((item, index) => {
                          const showIcon =
                            item?.status_type === "Submitted" ||
                            item?.status_type === "not submitted";
                          return (
                            <>
                              <div className="remark-field" index={index}>
                                <div className="symbol-wrap">
                                  <div
                                    className="color-indicator"
                                    style={{ backgroundColor: item?.color }}
                                  ></div>
                                  <p className="field-label">
                                    {item?.status_type}
                                  </p>
                                </div>

                                {!showIcon && (
                                  <div
                                    className="delete-icon"
                                    onClick={() => {
                                      handleRemove(item.id);
                                    }}
                                  >
                                    <i className="bx bx-trash"></i>
                                  </div>
                                )}
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                  </div>

                  <button
                    className="dotted-btn"
                    type="button"
                    onClick={() => {
                      setModalVisible(true);
                    }}
                  >
                    <i className="bx bx-plus"></i>
                    Add Custom Status
                  </button>

                  <div className="modal-footer-btn">
                    {!dashboard && (
                      <button
                        className="outline-btn"
                        type="button"
                        onClick={closeModal}
                      >
                        Discard
                      </button>
                    )}

                    <button
                      className={`btn btn-content custom-btn  ${
                        isBtnLoading && "disable-cursor"
                      }`}
                      type="submit"
                    >
                      {isBtnLoading ? "Saving" : "Save Changes"}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </div>
  );
};
