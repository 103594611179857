import React, { useState } from "react";
import { ChapterTab } from "../../../components/eLearning/chapterTab/chapterTab";
import "./ChapterPage.scss";
import { useLocation } from "react-router-dom";
import ChapterCard from "../../../components/eLearning/ChapterCard/ChapterCard";
import { BreadCrumbs } from "../../../components/breadCrumbs/breadCrumbs";
import SearchComponent from "../../../components/eLearning/SearchComponent/SearchComponent";
import { useEffect } from "react";
import { getCources } from "../../../components/Api/GetApi";
import { NoDataContent } from "../../../components/NoDataContent/NoDataContent";
import { VedioList } from "./VedioList";
import { PdfToImageViewer } from "../../../components/pdfToImageViewer/pdfToImageViewer";
import { Reading } from "./Reading";

const ChapterPage = () => {
  const location = useLocation();
  const { firstParams, syllabusNotesId, chapterList } = location?.state || Math;
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [searchQuery, setSearchQuery] = useState("");
  const findActiveCardIndex = chapterList?.findIndex(
    (item) => item?.id === syllabusNotesId
  );
  const [activeCardIndex, setActiveCardIndex] = useState(findActiveCardIndex);
  const [activeChapter, setActiveChapter] = useState(
    chapterList?.[findActiveCardIndex]
  );

  const filteredChapters = chapterList?.filter((chapter) =>
    chapter?.title?.toLowerCase()?.includes(searchQuery?.toLowerCase())
  );
  useEffect(() => {
    setIsLoading(true);
    getCources(activeChapter?.id).then((res) => {
      setData(res?.data?.data);
      setFiles(res?.data?.pdf);
      setIsLoading(false);
    });
  }, [activeChapter?.id]);

  const getReadingComponent = () => {
    if (isLoading) {
      return (
        <div className="reading-shimmer">
          <div className="shimmer-card">
            <div className="list-title" />
            {Array.from({ length: 15 }, (_, index) => {
              return <div className="list-content" key={index}></div>;
            })}
          </div>
        </div>
      );
    } else return <Reading data={data} />;
  };

  useEffect(() => {
    setActiveTab({
      label: "Reading",
      component: getReadingComponent(),
    });
  }, [activeCardIndex, data?.description, isLoading]);

  const tabConfig = [
    {
      label: "Reading",
      component: getReadingComponent(),
    },
    {
      label: "PDFs",
      component: (
        <>
          {files.length > 0 ? (
            // <PdfViewer files={files} />
            <PdfToImageViewer files={files} />
          ) : (
            <NoDataContent
              title="No Files"
              image={require("../../../images/emptyfile.png").default}
              description={"There is no files at the moment"}
            />
          )}
        </>
      ),
    },
    {
      label: "Videos",
      component: <VedioList videos={data?.videos} />,
    },
  ];

  return (
    <div className="chapter-page">
      <BreadCrumbs child={["Home", firstParams, activeChapter?.title]} />

      <div className="chapter-page-section">
        <div className="tab-section">
          <div className="top-section">
            <div className="title">{activeChapter?.title}</div>
            <div className="top-btn-block">
              <button
                className="top-btn "
                disabled={activeCardIndex === 0 || filteredChapters.length <= 1}
                onClick={() => {
                  setActiveCardIndex(activeCardIndex - 1);
                  setActiveChapter(filteredChapters[activeCardIndex - 1]);
                }}
              >
                <i class="bx bx-chevron-left"></i>
                <p className="content">Previous</p>
              </button>
              <div className="btn-divider"></div>
              <button
                className="top-btn"
                disabled={
                  filteredChapters?.length - 1 === activeCardIndex ||
                  filteredChapters.length <= 1
                }
                onClick={() => {
                  setActiveCardIndex(activeCardIndex + 1);
                  setActiveChapter(filteredChapters[activeCardIndex + 1]);
                }}
              >
                <p className="content">Next</p>
                <i class="bx bx-chevron-right"></i>
              </button>
            </div>
          </div>
          <hr />
          <div>
            <div className="tab">
              {tabConfig?.map((item, index) => {
                return (
                  <ChapterTab
                    key={index}
                    label={item?.label}
                    onClick={() => setActiveTab(item)}
                    isActive={activeTab?.label === item?.label}
                  />
                );
              })}
            </div>
            <div className="tab-body">{activeTab?.component}</div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="card-section">
          <div className="card-section-top">
            <h2 className="title">Chapters</h2>
            <div className="chapter-search">
              <SearchComponent
                onSearch={setSearchQuery}
                placeholder="Search Chapter"
              />
            </div>
          </div>
          <div className="card-list">
            {filteredChapters?.map((chapter, index) => (
              <div
                key={index}
                onClick={() => {
                  setActiveCardIndex(index);
                  setActiveChapter(chapter);
                }}
              >
                <ChapterCard
                  key={index}
                  logo={index + 1}
                  title={chapter.title}
                  active={activeChapter?.id === chapter?.id}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChapterPage;
