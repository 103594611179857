import { create } from "zustand";

const initialState = {
  activeTab: 0,
  activeTabName: "",
  grade: [],
  subjectList: [],
  unitList: [],
  selectedUnit: 0,
  lessonPlanDetails: [],
  selectedLessonId: 0,
  month: 0,
  gradeToken: null,
  subjectToken: null,
  selectedStartDayToken: 0,
  selectedEndDayToken: 0,
  selectedUnitName: "",
  unitDataFromLocal: null,
  selectedUnitData: null,
  isLessonEvaluation: null,
  submitEvaluationPlan: [],
  teachingEvaluationList: [],
  viewPage: "Evaluation List",
  selectedEvaluationToken: [],
  submitStudentEvaluation: [],
  studentEvaluationList: [],
  isBulkUpdate: false, 
  studentsList:[], 
  subjectwiseUnitList: [], 
  selectedSubjectUnit: [], 
  unitEvaluationList: [],
  studentEvaluationData: [], 
  unitwiseEvaluationData: [], 
};

export const useTeachingPlanMainStore = create((set) => ({
  ...initialState,
  setActiveTab: (val) => set(() => ({ activeTab: val })),
  setActiveTabName: (val) => set(() => ({ activeTabName: val })),
  setGrade: (val) => set(() => ({ grade: val })),
  setSubjectList: (val) => set(() => ({ subjectList: val })),
  setSubjectwiseUnitList: (val) => set(() => ({ subjectWiseUnitList: val })),
  setUnitList: (val) => set(() => ({ unitList: val })),
  setSelectedUnit: (val) => set(() => ({ selectedUnit: val })),
  setLessonPlanDetails: (val) => set(() => ({ lessonPlanDetails: val })),
  setSelectedLessonId: (val) => set(() => ({ selectedLessonId: val })),
  setMonth: (val) => set(() => ({ month: val })),
  setGradeToken: (val) => set(() => ({ gradeToken: val })),
  setSubjectToken: (val) => set(() => ({ subjectToken: val })),
  setSelectedStartDayToken: (val) =>
    set(() => ({ selectedStartDayToken: val })),
  setSelectedEndDayToken: (val) => set(() => ({ selectedEndDayToken: val })),
  setSelectedUnitName: (val) => set(() => ({ selectedUnitName: val })),
  setUnitDataFromLocal: (val) => set(() => ({ unitDataFromLocal: val })),
  setRedirect: (val) => set(() => ({ redirect: val })),
  setSelectedUnitData: (val) => set(() => ({ selectedUnitData: val })),
  setIsLessonEvaluation: (val) => set(() => ({ isLessonEvaluation: val })),
  setSubmitEvaluationPlan: (val) => set({ submitEvaluationPlan: val }),
  setTeachingEvaluationList: (val) => set(() => ({ teachingEvaluationList: val })),
  setViewPage: (val) => set(() => ({ viewPage: val })),
  setSelectedEvaluationToken: (val) => set(() => ({ selectedEvaluationToken: val })),
  setIsBulkUpdate: (val) => set(() => ({ isBulkUpdate: val })),
  setSubmitStudentEvaluation: (val) => set({ submitStudentEvaluation: val }),
  setStudentEvaluationList: (val) => set({ studentEvaluationList: val }),
  setStudentsList:(val) => set({ studentsList: val }),
  setSelectedSubjectUnit:(val) => set({ selectedSubjectUnit: val }),
  setUnitEvaluationList:(val) => set({ unitEvaluationList: val }),
  setStudentEvaluationData:(val) => set({ studentEvaluationData: val }),
  setUnitwiseEvaluationData:(val) => set({ unitwiseEvaluationData: val }),
}));
