import { create } from "zustand";

const initialState = {
  activeTab: 0,
  viewPage: "newPlan",
  isUpdate: false,
  editMode: false,
  isAddLessonPlan: false,
  continueData: false,
  hasSubmitted: false,
  isHod: true,
  showLessonHeader: true,
};

export const useTeachingPlanStore = create((set) => ({
  ...initialState,
  setActiveTab: (val) => set(() => ({ activeTab: val })),
  setViewPage: (val) => set(() => ({ viewPage: val })),
  setIsUpdate: (val) => set(() => ({ isUpdate: val })),
  setEditMode: (val) => set(() => ({ editMode: val })),
  setIsAddLessonPlan: (val) => set(() => ({ isAddLessonPlan: val })),
  setContinueData: (val) => set(() => ({ continueData: val })),
  setHasSubmitted: (val) => set(() => ({ hasSubmitted: val })),
  setIsHod: (val) => set(() => ({ isHod: val })),
  setShowLessonHeader: (val) => set(() => ({ showLessonHeader: val })),
}));
