import React, { useEffect } from "react";
import "./headerCardRight.scss";
import { useSummary } from "../../store/accountDashboard";

const HeaderCardRight = () => {
  const { summary, fetchSummaryData } = useSummary();
  console.log("summary", summary);
  useEffect(() => {
    fetchSummaryData();
  }, []);

  const calculateAmount = (amount) => {
    if (amount) {
      console.log("amount", amount);
      let sub;
      if (amount?.startsWith("(") && amount?.endsWith(")")) {
        let amt;
        amt = ` ${Intl.NumberFormat("en-IN").format(
          parseFloat(amount.replace(/[()]/g, ""))
        )}`;
        sub = `Rs.(${amt})`;
        return sub;
      } else {
        sub = `Rs. ${Intl.NumberFormat("en-IN").format(parseFloat(amount))}`;
        return sub;
      }
    } else {
      return 0;
    }
  };
  return (
    <div className="card_right_container">
      <div className="card_header">
        <h2>Today Summary</h2>
      </div>

      <div className="card_body">
        <div className="card_body_left">
          <p>Collection</p>
          <h3>{calculateAmount(summary?.[0]?.collection || 0)}</h3>
        </div>
        <div className="pipe_ver"></div>
        <div className="card_body_mid">
          <p>Discount</p>
          <h3>{calculateAmount(summary?.[0]?.discount || 0)}</h3>
        </div>
        <div className="pipe_ver"></div>
        <div className="card_body_right">
          <p>Expenses</p>
          <h3>{calculateAmount(summary?.[0]?.expenses || 0)}</h3>
        </div>
      </div>
    </div>
  );
};

export default HeaderCardRight;
