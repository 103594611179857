import React, { useEffect, useState } from "react";
import { PDFtoIMG } from "react-pdf-to-image";
import "./pdfToImageViewer.scss";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import PreLoader from "../preLoader/preLoader";

export const PdfToImageViewer = ({ files }) => {
  const [selectedFile, setSelectedFile] = useState(files?.[0]);
  const [fileList, setFileList] = useState();
  const [pages, setPages] = useState();
  const [selectedPage, setSelectedPage] = useState();

  useEffect(() => {
    setFileList(
      pages?.map((item, index) => {
        return {
          id: index + 1,
          link: item,
        };
      })
    );
  }, [pages, selectedPage]);

  useEffect(() => {
    if (!selectedPage && pages?.length > 0) {
      setSelectedPage({
        id: 1,
        link: pages?.[0],
      });
    }
  }, [pages?.length, selectedPage]);

  return (
    <div className="pdf-to-image">
      <PDFtoIMG file={selectedFile?.link}>
        {({ pages }) => {
          setPages(pages);
          if (!pages.length) return <PreLoader />;
          return (
            <>
              <TransformWrapper
                initialScale={1}
                wheel={{
                  disabled: true,
                }}
              >
                {({ zoomIn, zoomOut, resetTransform }) => (
                  <React.Fragment>
                    <div className="top-section">
                      <select
                        className="input-field"
                        onChange={(e) => {
                          const temp = files?.find(
                            (f) => f.name === e.target?.value
                          );
                          setSelectedFile(temp);
                        }}
                      >
                        {files?.map((item, index) => {
                          return (
                            <option key={item + index} value={item?.name}>
                              {item?.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="tools  ">
                        <button className="icon-btn" onClick={() => zoomIn()}>
                          <i class="bx bx-zoom-in"></i>
                        </button>
                        <button className="icon-btn" onClick={() => zoomOut()}>
                          <i class="bx bx-zoom-out"></i>
                        </button>
                        <button
                          className="icon-btn"
                          onClick={() => resetTransform()}
                        >
                          <i class="bx bx-exit-fullscreen"></i>
                        </button>
                      </div>
                    </div>

                    <div className="pdf-conatiner">
                      <TransformComponent>
                        <img alt={"img"} src={selectedPage?.link} />
                      </TransformComponent>
                    </div>
                  </React.Fragment>
                )}
              </TransformWrapper>
              <div className="bottom-btn-block">
                <button
                  className="icon-btn"
                  disabled={selectedPage?.id <= 1}
                  onClick={() => {
                    const temp = fileList?.find(
                      (f) => f.id === selectedPage?.id - 1
                    );
                    setSelectedPage(temp);
                  }}
                >
                  <i class="bx bx-chevron-left"></i>
                </button>
                <input
                  className="input-field"
                  value={selectedPage?.id}
                  max={fileList?.length}
                  onChange={(e) => {
                    if (Number(e.target.value <= fileList?.length)) {
                      const temp = fileList?.find(
                        (f) => f.id === Number(e.target.value)
                      );
                      setSelectedPage(temp);
                    }
                  }}
                />
                <p className="content">of &nbsp; {fileList?.length}</p>

                <button
                  className="icon-btn"
                  disabled={fileList?.length === selectedPage?.id}
                  onClick={() => {
                    const temp = fileList?.find(
                      (f) => f.id === selectedPage?.id + 1
                    );
                    setSelectedPage(temp);
                  }}
                >
                  <i class="bx bx-chevron-right"></i>
                </button>
              </div>
            </>
          );
        }}
      </PDFtoIMG>
    </div>
  );
};
