import html2pdf from "html2pdf.js";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { useTeachingPlanMainStore } from "../../pages/teachingPlanMain/store/teachingPlanMainStore";

export const handlePdfDownload = ({ printRef }) => {
  const element = printRef.current;
  const options = {
    filename: "document.pdf",
    image: { type: "jpeg", quality: 0.98 },
    margin: [0.6, 0, 0.6, 0],
    html2canvas: {
      scale: 2,
      dpi: 192,
      logging: true,
      letterRendering: true,
      useCORS: true,
    },
    jsPDF: {
      unit: "in",
      format: "a4",
      orientation: "portrait",
      borderColor: "#219ebc", // border color
      borderWidth: 1,
    },
    pagebreak: { mode: "avoid-all", before: ".page-break" },
  };
  html2pdf().from(element).set(options).save();
};

export const downloadExcel = (
  gradeToken,
  gradeList,
  subjectList,
  subjectwiseUnitList,
  studentsList,
  selectedSubjectUnit,
  unitEvaluationList,
  unitwiseEvaluationData
) => {
  const worksheetData = [];
  const mergeRanges = [];

  // Grade row
  let gradeHeaders = "";
  const selectedGrade = gradeList?.find(
    (item) => item.id === Number(gradeToken)
  )?.class_name;

  gradeHeaders = [selectedGrade];

  mergeRanges.push({
    s: { r: 0, c: 0 },
    e: { r: 0, c: subjectList.length * 3 },
  });

  worksheetData.push(gradeHeaders);

  // Subject row
  const subjectHeaders = ["Student Name"];

  let colIndex = 1;
  subjectList.forEach((item) => {
    const selectedUnit = selectedSubjectUnit.find(
      (itm) => itm.subjectId === item?.id
    )?.unitId;
    const evaluation = unitEvaluationList.find(
      (itm) => itm.id === selectedUnit
    );

    const colSpan = evaluation?.data?.length || 1;
    mergeRanges.push({
      s: { r: 1, c: colIndex }, 
      e: { r: 1, c: colIndex + colSpan - 1 }, 
    });

    subjectHeaders.push(item?.subjectName);
    for (let i = 1; i < colSpan; i++) {
      subjectHeaders.push("");
    }

    colIndex += colSpan;
  });
  worksheetData.push(subjectHeaders);

  // Unit row
  const unitHeaders = [""]; 
  colIndex = 1;
  subjectList.forEach((item) => {
    const unit_list = selectedSubjectUnit.find(
      (itm) => itm.subjectId === item?.id
    )?.unitId;
    const selectedUnit = subjectwiseUnitList.find(
      (itm) => itm.id === item?.id
    )?.planData;

    const selectedUnitName = selectedUnit.find(
      (unit) => unit.id === Number(unit_list)
    )?.unit_name;

    const evaluation = unitEvaluationList.find((itm) => itm.id === unit_list);

    const colSpan = evaluation?.data?.length || 1;
    mergeRanges.push({
      s: { r: 2, c: colIndex }, // Adjusted to start from row 2
      e: { r: 2, c: colIndex + colSpan - 1 }, // End at the last column
    });

    unitHeaders.push(selectedUnitName || "-");
    for (let i = 1; i < colSpan; i++) {
      unitHeaders.push("");
    }

    colIndex += colSpan;
  });
  worksheetData.push(unitHeaders);

  // Evaluation headers row
  const evalHeaders = [""];
  subjectList.forEach((item) => {
    const selectedUnit = selectedSubjectUnit.find(
      (itm) => itm.subjectId === item?.id
    )?.unitId;
    const evaluation = unitEvaluationList.find(
      (itm) => itm.id === selectedUnit
    );

    if (evaluation?.data?.length > 0) {
      evalHeaders.push(...evaluation.data.map((evalItem) => evalItem?.title));
    } else {
      evalHeaders.push("-");
    }
  });
  worksheetData.push(evalHeaders);

  // Data rows with student evaluations
  studentsList.forEach((student) => {
    const row = [student?.studentName];

    subjectList.forEach((item) => {
      const selectedUnit = selectedSubjectUnit.find(
        (itm) => itm.subjectId === item?.id
      )?.unitId;
      const formattedUnit = unitwiseEvaluationData.find(
        (unit) => unit.unit_id === selectedUnit
      );

      if (formattedUnit?.data?.length > 0) {
        formattedUnit.data.forEach((evaluation) => {
          const studentEvaluation = evaluation.data.find(
            (evalStudent) => evalStudent.student_token === student.id
          );
          row.push(studentEvaluation ? studentEvaluation.value : "-");
        });
      } else {
        row.push("-");
      }
    });

    worksheetData.push(row);
  });

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  if (mergeRanges.length > 0) {
    worksheet["!merges"] = mergeRanges;
  }

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(data, "table_data.xlsx");
};
