import StudentImage from "../StudentImage/StudentImage";
import StudentProfileTop from "./StudentProfileTop";
import TabComponent from "../Tabs/TabComponent";
import "./studentProfile.scss";
import StudentInformation from "../StudentInformation/StudentInformation";

const StudentProfile = ({ childId }) => {
  return (
    <>
      <div className="student-profile-container">
        <div className="upperDiv">
          <StudentProfileTop />
          <div className="middleDiv">
            <StudentImage childId={childId} />
            <StudentInformation />
          </div>
        </div>
        <TabComponent childId={childId} />
        {/* <MyTabs /> */}
      </div>
    </>
  );
};

export default StudentProfile;
