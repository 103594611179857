import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../components/Loader/Loader";
import StudentProfile from "../../components/StudentProfile/StudentProfile";
import { changeReload, useStudentId, useStudentStore } from "../../store/store";
import "./studentProfilePage.scss";
import { useEffect } from "react";
import NividLoader from "../../components/NividLoader/NividLoader";

const StudentProfilePage = ({ childId }) => {
  const student = useStudentId((state) => state);

  const { isLoading, fetchStudentData } = useStudentStore();
  const { shouldReload } = changeReload();
  const urlString = window.location.href;
  const url = new URL(urlString);
  const pathname = url.pathname; // This will give you "/profile/12"
  const parts = pathname.split("/"); // Split the pathname by "/"
  const id = childId ? childId : parts[parts.length - 1];

  useEffect(() => {
    if (id) {
      fetchStudentData(id);
      student.setStudentId(id);
    }
  }, [id]);

  if (shouldReload && isLoading) {
    return <NividLoader height="100vh" />;
  }

  return (
    <div className="studentProfile-card">
      <StudentProfile childId={childId} />
    </div>
  );
};

export default StudentProfilePage;
