import React, { useEffect, useMemo, useState } from "react";
import "./CanteenParentOrderSelection.scss";
import { Tabs, Tab } from "react-bootstrap-tabs";
import OrderUi from "./OrderUi/OrderUi";
import { useCanteenParentOrder } from "./store/UseCanteenParentOrder";
import { getChildData } from "../Api/GetApi";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { toast } from "react-toastify";
import { postSingleStudentFoodRecord } from "../Api/PostApi";
import PreLoader from "../preLoader/preLoader";
import NividLoader from "../NividLoader/NividLoader";

const CanteenParentOrderSelection = () => {
  const [childData, setChildData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    foodMenu,
    studentFoodList,
    setStudentFoodList,
    todayMenuList,
    setTodayMenuList,
    activeStudentToken,
    setActiveStudentToken,
    fetchFoodMenu,
    selectedFoodCategory,
  } = useCanteenParentOrder();

  const [loader, setLoader] = useState(true);
  useEffect(() => {
    fetchFoodMenu();
    const fetchChildData = async () => {
      setLoader(true);
      try {
        const res = await getChildData();
        if (res.status === 200) {
          const resData = res?.data;
          setChildData(resData);
        }
      } catch (error) {
        console.log("error fetching", error);
      } finally {
        setLoader(false);
      }
    };

    fetchChildData();
  }, []);

  useEffect(() => {
    if (childData.length > 0) {
      setActiveStudentToken(childData[0].studentToken);
    }
  }, [childData]);

  const handleTabChange = (index) => {
    setActiveStudentToken(childData[index].studentToken);
  };

  const handleSubmit = async () => {
    const hasEmptyFoodMenu = studentFoodList?.some(
      (student) => student.food_menu.length > 0
    );

    if (hasEmptyFoodMenu) {
      const data = {
        students: studentFoodList,
      };
      setIsSubmitting(true);
      try {
        const res = await postSingleStudentFoodRecord(data);
        if (res.status === 200) {
          toast(res?.data?.data);
        } else {
          toast("something went wrong");
        }
      } catch {
        toast("something went wrong");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      toast("Please select  food items for atleast one student");
    }
  };
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const currentDate = new Date();
    const currentDay = days[currentDate.getDay()];

    const todayFoodMenu = foodMenu?.filter((item) => item?.day === currentDay);
    setTodayMenuList(todayFoodMenu?.[0]);
    const values = childData?.map((item) => {
      return {
        student_id: item?.studentToken,
        food_menu: [],
      };
    });

    setStudentFoodList(values);
  }, [childData]);

  if (loader) {
    return <NividLoader />;
  } else
    return (
      <div className="canteen_parent_container">
        <div className="title-block header">
          <h1 className="title">STUDENT CANTEEN MENU SELECTION</h1>
        </div>
        <div className="content_wrapper ">
          {childData?.length > 0 ? (
            <Tabs
              activeKey={activeStudentToken}
              onSelect={(index) => handleTabChange(index)}
            >
              {childData?.map((item, index) => (
                <Tab
                  key={index}
                  title={item?.studentName}
                  label={item?.studentName}
                  eventKey={item.studentToken}
                >
                  <OrderUi />
                </Tab>
              ))}
            </Tabs>
          ) : (
            <NoDataContent
              marginTop="100px"
              image={require("../../images/emptyfile.png").default}
              title="No children Found"
              description={"Confirm that you have atleast one child"}
            />
          )}
        </div>

        {childData?.length > 0 && (
          <div
            style={{
              position: "fixed",
              bottom: "20px",
              left: 0,
              right: 0,
              marginInline: "auto",
              cursor: "pointer",
              textAlign: "center",

              marginLeft: "10px",
              marginRight: "10px",
            }}
            className="submit_btn"
            onClick={() => {
              if (!isSubmitting) {
                handleSubmit();
              }
            }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </div>
        )}
      </div>
    );
};

export default CanteenParentOrderSelection;
