import React, { useEffect, useState } from "react";
import MarkApprovalInner from "./MarkApprovalInner";
import { useExamDataList } from "../store/useStore";
import Select from "react-select";
import {
  getAllSubjectForExam,
  getClassAlphaHod,
  getExamSubjectList,
} from "../Api/GetApi";

export const ExamMarksApproval = ({
  setApproved,
  setSelected,
  setPopupType,
  data,
  setShowAll,
  showAll,
  setPopUpMarks,
}) => {
  const { fetchExamDataList, data: details } = useExamDataList();
  const [selectedExam, setSelectedExam] = useState();
  const [subject, setSubject] = useState();
  const [selectedSubject, setSelectedSubject] = useState();
  const [filteredData, setFilteredData] = useState();
  useEffect(() => {
    fetchExamDataList();
    getAllSubjectForExam().then((res) => {
      setSubject(res.data.subject);
    });
  }, []);

  useEffect(() => {
    if (selectedExam && selectedSubject) {
      const filterExamSubject = data?.filter(
        (item, index) =>
          item?.examName === selectedExam.label &&
          item?.subjectName === selectedSubject.label
      );

      setFilteredData(filterExamSubject);
    }
  }, [selectedSubject, selectedExam]);

  return (
    <div className="exam-marks-approval-section">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "30px",
          marginTop: "10px",
        }}
      >
        <div style={{ minWidth: "30%" }}>
          <label>Select Exam</label>
          <Select
            options={details?.exam_data?.map((item) => ({
              value: item.id,
              label: item.examName,
            }))}
            value={selectedExam}
            onChange={(option) => {
              setSelectedExam(option);
            }}
          />
        </div>

        <div style={{ minWidth: "30%" }}>
          <label>Select Subject</label>
          <Select
            options={subject?.map((item) => ({
              value: item.id,
              label: item.subjectName,
            }))}
            value={selectedSubject}
            onChange={(option) => {
              setSelectedSubject(option);
            }}
          />
        </div>
      </div>
      <MarkApprovalInner
        setApproved={setApproved}
        setSelected={setSelected}
        setPopupType={setPopupType}
        data={filteredData}
        setShowAll={setShowAll}
        showAll={showAll}
        setPopUpMarks={setPopUpMarks}
      />
    </div>
  );
};
