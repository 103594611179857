import { ErrorMessage, Field } from "formik";
import { useState } from "react";
import {
  FormikErrorMessage,
  useAdmissionFormFormikContext,
} from "../HorizontalStepper/FormikWrapper";
import "./PersonalInfo.scss";

const religion = ["Hindu", "Christain", "Muslim", "Others"];

export const PersonalInfo = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { getFieldProps, setFieldValue } = useAdmissionFormFormikContext();
  const [gradeOption, setGradeOption] = useState("");
  const [nationalityOption, setNationalityOption] = useState("");

  // const [image, setImage] = useState(null || "");
  // const handleUpload = (e) => {
  //   if (e.target.files) {
  //     const imageURL = URL.createObjectURL(e.target.files[0]);
  //     setImage(imageURL);
  //   } else {
  //     console.log("upload a file");
  //   }
  // };

  return (
    <div className="personal-info">
      <div className="section-gap">
        <div className="container">
          <div className="admission-form">
            <div className="admission-header">
              <h4 className="main-title school-name">Kathmandu World School</h4>
              <div className="address-wrapper d-column">
                <div className="address-info">
                  <p className="main-content place-name">Surya Binayak - 7</p>
                  <span className="side-bar"></span>
                  <p className="main-content address">
                    Gundu, Bhaktapur, Nepal
                  </p>
                </div>
                <div className="address-info">
                  <p className="main-content phone-no">5090337</p>
                  <span className="side-bar"></span>
                  <p className="main-content email">info@kws.edu.np</p>
                </div>
              </div>
            </div>
            <p className="tag main-content">Fields with (*) are compulsory.</p>

            <div className="option-head">
              <div className="input-field-block">
                <p className="main-content uppercase">
                  Grade Applied For<span className="star">*</span>:
                </p>
                <Field
                  as="select"
                  className="input-field"
                  name="gradeFor"
                  onChange={(e) => {
                    setFieldValue("gradeFor", e.target.value);
                    setGradeOption(e.target.value);
                  }}
                  value={gradeOption}
                >
                  <option value="">--Select--</option>
                  {["Grade 1", "Grade 2", "Grade 3"]?.map((item) => {
                    return (
                      <>
                        <option key={item} value={item}>
                          {item}
                        </option>
                      </>
                    );
                  })}
                </Field>
                <FormikErrorMessage name="gradeFor" />
              </div>
              <div className="uppercase small-title center">
                Personal Information
              </div>
            </div>
            <p className="main-content name-tag">
              Name of Applicant (Full Name)
              <span className="star">*</span>:
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="input-field-block">
                  <input
                    className="input-field"
                    placeholder="First Name"
                    name="firstName"
                    {...getFieldProps("firstName")}
                  />
                  <FormikErrorMessage name="firstName" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <input
                    className="input-field"
                    placeholder="Middle Name"
                    name="middleName"
                    {...getFieldProps("middleName")}
                  />
                  <FormikErrorMessage name="middleName" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <input
                    className="input-field"
                    placeholder="Last Name"
                    name="lastName"
                    {...getFieldProps("lastName")}
                  />
                  <FormikErrorMessage name="lastName" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-field-block start-field">
                  <p className="main-content">
                    Date of Birth
                    <span className="star">*</span>:
                  </p>
                  <div className="d-column date-section">
                    <div className="d-flex date">
                      <span className="main-content">BS</span>
                      <input
                        className="input-field"
                        type="date"
                        name="BSdob"
                        {...getFieldProps("BSdob")}
                      />
                    </div>
                    <FormikErrorMessage name="BSdob" />
                    <div className="d-flex">
                      <span className="main-content">AD</span>
                      <input
                        className="input-field"
                        type="date"
                        name="ADdob"
                        {...getFieldProps("ADdob")}
                      />
                    </div>
                    <FormikErrorMessage name="ADdob" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-column gender-row">
                <div className="radio-tab d-flex">
                  <p className="main-content name-tag">
                    Gender
                    <span className="star">*</span>:
                  </p>
                  <div className="d-flex radio-block">
                    {["Male", "Female", " Others"]?.map((item, index) => {
                      return (
                        <div className="radio-label d-flex">
                          <input
                            type="radio"
                            value={item}
                            name="gender"
                            onChange={(e) => {
                              setFieldValue("gender", e.target.value);
                              setActiveIndex(index);
                            }}
                            checked={activeIndex === index}
                          />
                          <label className="main-content">{item}</label>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="input-field-block">
                  <div className="d-flex">
                    <p className="main-content name-tag">Age:</p>
                    <div className="age-block">
                      <input
                        className="input-field"
                        name="age"
                        {...getFieldProps("age")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-field-block">
                  <p className="main-content name-tag">
                    Nationality
                    <span className="star">*</span>:
                  </p>
                  <Field
                    as="select"
                    className="input-field"
                    name="nationality"
                    onChange={(e) => {
                      setFieldValue("nationality", e.target.value);
                      setNationalityOption(e.target.value);
                    }}
                    value={nationalityOption}
                  >
                    <option value="">--Select--</option>
                    {["Nepali", "Indian", " Japanese", "Chinese"]?.map(
                      (item) => {
                        return (
                          <>
                            <option key={item} value={item}>
                              {item}
                            </option>
                          </>
                        );
                      }
                    )}
                  </Field>
                  <FormikErrorMessage name="nationality" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-field-block">
                  <p className="main-content name-tag">Email:</p>
                  <input
                    className="input-field"
                    name="email"
                    {...getFieldProps("email")}
                  />
                  <FormikErrorMessage name="email" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-field-block">
                  <p className="main-content name-tag">Phone (Res.):</p>
                  <input
                    className="input-field"
                    name="phone"
                    type="number"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...getFieldProps("phone")}
                  />
                  <FormikErrorMessage name="phone" />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-field-block">
                  <p className="main-content name-tag">
                    Mobile No.
                    <span className="star">*</span>:
                  </p>
                  <input
                    className="input-field"
                    type="number"
                    name="mobileNo"
                    onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    {...getFieldProps("mobileNo")}
                  />
                  <FormikErrorMessage name="mobileNo" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <p className="main-content name-tag">Religion:</p>
                  <Field
                    as="select"
                    className="input-field"
                    name="religion"
                    onChange={(e) => {
                      setFieldValue("religion", e.target.value);
                    }}
                  >
                    <option value="">--Select--</option>
                    {religion?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                    <FormikErrorMessage name="religion" />
                  </Field>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <p className="main-content name-tag">Caste / Ethnicity:</p>
                  <Field
                    as="select"
                    className="input-field"
                    name="caste"
                    onChange={(e) => {
                      setFieldValue("caste", e.target.value);
                    }}
                  >
                    <option value="">--Select--</option>
                    {["Chettri", "Bahun", "Newar"]?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                    <FormikErrorMessage name="caste" />
                  </Field>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <p className="main-content name-tag">Blood Group:</p>
                  <Field
                    as="select"
                    className="input-field"
                    name="bloodGroup"
                    onChange={(e) => {
                      setFieldValue("bloodGroup", e.target.value);
                    }}
                  >
                    <option value="">--Select--</option>
                    {["A", "B", "O", "AB"]?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                    <FormikErrorMessage name="bloodGroup" />
                  </Field>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <p className="main-content name-tag">Province:</p>
                  <Field
                    as="select"
                    className="input-field"
                    name="province"
                    onChange={(e) => {
                      setFieldValue("province", e.target.value);
                    }}
                  >
                    <option value="">--Select--</option>
                    {[
                      "Province No 1",
                      "Province No 2",
                      "Province No 3",
                      "Province No 4",
                      "Province No 5",
                      "Province No 6",
                      "Province No 7",
                    ]?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      );
                    })}
                    <FormikErrorMessage name="province" />
                  </Field>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <p className="main-content name-tag">Municipality:</p>
                  <input
                    className="input-field"
                    name="municipality"
                    {...getFieldProps("municipality")}
                  />
                  <FormikErrorMessage name="municipality" />
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-field-block">
                  <p className="main-content name-tag">Street:</p>
                  <input
                    className="input-field"
                    name="street"
                    {...getFieldProps("street")}
                  />
                  <FormikErrorMessage name="street" />
                </div>
              </div>
            </div>

            {/* <div className="col-md-2">
                    <div className="image-block ">
                      <input
                        type="file"
                        className="no-input"
                        id="uploadImage"
                        name="image"
                        accept="image/*"
                        onChange={handleUpload}
                      />
                      {image === null || image === "" || image === undefined ? (
                        <>
                          <label htmlFor="uploadImage">
                            <span className="block-content">Attach a Photo</span>
                          </label>
                        </>
                      ) : (
                        <>
                          <label htmlFor="uploadImage" className="image-icon">
                            <img
                              src={require("../../images/edit.png").default}
                              alt="edit-icon"
                            />
                          </label>
                          <div className="student-image">
                            <img src={image} alt="user-img" />
                          </div>
                        </>
                      )}
                    </div>
                  </div> */}

            {/* <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="content ">Sex</p>
                    <select className="input-field">
                      <option>Select Sex</option>
                      {sex?.map((item) => {
                        return <option key={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-field-block">
                    <p className="content ">Group</p>
                    <select className="input-field">
                      <option>Select Group</option>
                      {group?.map((item) => {
                        return <option key={item}>{item}</option>;
                      })}
                    </select>
                  </div>
                </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
