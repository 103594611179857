import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
// import "./LeaveCard.scss";
import { Piechart } from "../Piechart/Piechart";

const GenderChart = (props) => {
  return (
    <div className="gender-card section-gap">
      <div className="custom-card">
        <div className="top-section">
          <p className="medium-title-header">Total Girls and Boys Ratio</p>
        </div>

        <div className="row">
          <Piechart totalnum={props?.data} />
        </div>
      </div>
    </div>
  );
};

export default GenderChart;
