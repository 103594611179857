const status = {
  deadline: "deadline",
  submitted: "submitted",
  late: "late",
  pending: "pending",
  empty: "empty",
};

function getStatusForDay(day) {
  if (day % 3 === 0) return status.submitted;
  if (day % 5 === 0) return status.late;
  if (day % 7 === 0) return status.pending;
  if (day % 2 === 0) return status.empty;
  return status.deadline;
}
export const generateMapData = (dataLength) => {
  const mapConfig = [];
  for (let i = 1; i <= dataLength; i++) {
    mapConfig.push({
      day: i,
      status: getStatusForDay(i),
    });
  }
  return mapConfig;
};
