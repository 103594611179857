import React, { useState } from "react";
import "./MarksApproval.scss";
import { NoDataContent } from "../NoDataContent/NoDataContent.js";
import { FaAngleRight } from "react-icons/fa6";

const MarkApprovalInner = ({
  data,
  setPopupType,
  setSelected,
  setApproved,
  showAll,
  setShowAll,
  setPopUpMarks,
}) => {
  const modifiedData = showAll ? data : data?.slice(0, 3);
  const [errorImg, setErrorImg] = useState([]);
  return (
    <>
      <div className="mark_approval_body_wrapper">
        {!data || data?.length === 0 ? (
          <NoDataContent
            title="No Data Found"
            description="Data will be appeared when updated"
            image={require("../../images/emptyfile.png").default}
          />
        ) : (
          modifiedData?.map((item, index) => {
            return (
              <div key={index} className="mark_approval_content_wrapper">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <div className="mark_approval_content_top_left">
                      <p className=" fontsize">{item.examName}</p>
                    </div>
                    <div className="mark_approval_content_top_middle">
                      <p className="main12">
                        <span className="mainn">Class: </span>
                        <span>{item.class_name}</span>
                      </p>
                      {/* <p className="main12-a">
                          <span className="mainn">Type: </span>
                          <span>{item.data_type}</span>
                        </p> */}
                    </div>
                  </div>

                  <div className="mark_approval_content_top_right">
                    <div className="main24 d-flex">
                      <a
                        type="button"
                        onClick={() => {
                          setPopupType("markDetails");
                          setSelected(item);
                        }}
                      >
                        View Details
                      </a>
                    </div>
                    <div className="icon-n  main24">
                      <span className="">
                        <FaAngleRight />
                      </span>
                    </div>
                  </div>
                </div>

                <div className=" mark_approval_content_divider" />
                <div className="mark_approval_content_bottom">
                  <div className="mark_approval_content_bottom_left ">
                    <div className="submitted_img_wrapper ">
                      <p>
                        {item?.submitted_by?.charAt(0).toUpperCase() || "S"}
                      </p>
                    </div>
                    <div className="main19">
                      <div className="main20">
                        <span className="main21"> </span>
                        <span className="main22">Submitted by: </span>
                        <span className="main21 ms-1">{item.submitted_by}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    onClick={() => {
                      setApproved(item?.approved_by);
                      setPopupType("approveMarks");
                      setPopUpMarks("exam");
                    }}
                    className="approve_section"
                  >
                    {item?.approved_by.length > 0 &&
                      item?.approved_by?.[0] !== null &&
                      item?.approved_by?.map((a, index) => {
                        return (
                          <div key={index} className="approve_img">
                            {a?.teacher_image ===
                              "https://storage.googleapis.com/nivid/" ||
                            errorImg?.includes(index) ? (
                              <div
                                style={{ color: "#1589CA" }}
                                className="no_image_filler_small"
                              >
                                {a?.teacherName?.charAt(0)}
                              </div>
                            ) : (
                              <img
                                src={a?.teacher_image}
                                alt="img"
                                onError={() => {
                                  setErrorImg([...errorImg, index]);
                                }}
                              />
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      {data?.length > 3 && !showAll && (
        <p
          type="button"
          className="tt mt-3"
          onClick={() => {
            setShowAll(true);
          }}
        >
          View All
          <FaAngleRight className="ms-1" />
        </p>
      )}
    </>
  );
};

export default MarkApprovalInner;
