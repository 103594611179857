import React, { useState, useEffect } from "react";
import "./Homework.scss";
import { RxCross2 } from "react-icons/rx";

import { getCommentActivity } from "../Api/GetApi.js";

import { postActivityRemarks } from "../Api/PostApi.js";

import { toast } from "react-toastify";
import Moment from "react-moment";

function Homework({ onClose, activityData }) {
  const [commentData, setCommentData] = useState([]);
  const [newComment, setNewComment] = useState("");

  useEffect(() => {
    getComment();
  }, [activityData.activity_id]);

  const getComment = async () => {
    try {
      const response = await getCommentActivity(activityData.activity_id);
      setCommentData(response?.data?.data?.data);
    } catch (error) {
      setCommentData([]);
    }
  };

  const addNewComment = async () => {
    const values = {
      comment: newComment,
      activity_id: activityData?.activity_id,
    };
    if (newComment === "") {
      toast("Comment cannot be empty!");
      return;
    }
    try {
      const response = await postActivityRemarks(values);

      if (response.status === 200) {
        toast("Comment Added Successfully");
        setNewComment("");
        getComment();
      } else {
        toast("Something went wrong");
        setNewComment("");
      }
    } catch (error) {
      toast("Failed to add comment");
      setNewComment("");
    }
  };

  return (
    <>
      <div className="homework">
        <div className="homework-frame m-3">
          <div className="second-lower-frame">
            <div className="second-insider-frame">
              <div className="third-top-insider">
                <div className="deep-insider-i">
                  <div className="home-des-frame">
                    <div className="home-des-frame-2">
                      <div className="home-des-title">
                        {activityData?.home_work === undefined ? (
                          <p className="textHomework text-uppercase">
                            CLASSWORK DESCRIPTION
                          </p>
                        ) : (
                          <p className="textHomework text-uppercase">
                            HOMEWORK DESCRIPTION
                          </p>
                        )}
                      </div>
                      <div className="home-comment">
                        <p>
                          {activityData?.home_work === undefined
                            ? activityData?.class_work
                            : activityData?.home_work}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="activities-outer-frame">
                    <div className="activities-inner-frame">
                      <div className="activities-title mt-2">
                        <p className="text-uppercase">Activities</p>
                      </div>
                      <div className="activities-comment">
                        <p>{activityData.activity}</p>
                      </div>
                    </div>
                  </div>
                  <div className="image-outer-frame">
                    <div className="image-title mt-2">
                      <p className="text-uppercase">Images</p>
                    </div>
                    {activityData.homework_image ||
                    activityData.classwork_image ? (
                      <div className="picture-outer-frame">
                        <div className="card-c">
                          <div className="card-i">
                            {activityData?.class_work === undefined ? (
                              <img
                                src={activityData?.homework_image}
                                className="image-i"
                              ></img>
                            ) : (
                              <img
                                src={activityData.classwork_image}
                                className="image-i"
                              ></img>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>No Image</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="last-outer-frame mt-2">
                <div className="header-field">
                  <div className="section-title-field">
                    <div className="title-t-text">
                      <p className="comment-title">Comments</p>
                      <p className="comment-description">
                        Your comments and responses to clarification requests
                      </p>
                    </div>
                  </div>
                </div>
                <div className="stroke-st-line"></div>
                {commentData?.length > 0 ? (
                  commentData?.map((comment, index) => {
                    return (
                      <div className="profile-frame mt-3" key={index}>
                        <div className="dialog-set">
                          <div className="dialog-in-frame">
                            <div className="dialog-l d-flex">
                              <div className="hcp-dp">
                                <img
                                  className="dp-lay"
                                  src={
                                    require("../../images/imageeee.png").default
                                  }
                                />
                              </div>
                              <div className="bubble">
                                <div className="bubble-ody">
                                  <div className="bubble-head">
                                    <div className="title-row">
                                      <p className="title-bubble">
                                        {comment.username
                                          ? comment.username
                                          : "Unknown User"}
                                      </p>
                                    </div>
                                    <div className="dep ">
                                      <span className="head-of">
                                        Head of Department of
                                      </span>
                                      <span className="subject-of ms-1">
                                        {comment?.designation}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="parent-frame">
                                    <p className="parent-text mt-1">
                                      {comment?.comment}
                                    </p>
                                  </div>
                                  <p className="time-ago mt-1">
                                    <Moment fromNow>
                                      {comment?.created_at}
                                    </Moment>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p>No comment available!</p>
                )}
              </div>
              <div className="add-comment-full-frame d-flex mt-3">
                <div className="add-comment-frame">
                  <input
                    className="input-f input-f-text"
                    placeholder="Write your remarks here"
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                  ></input>
                </div>
                <button
                  className="button-post button-text-t"
                  onClick={addNewComment}
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Homework;
