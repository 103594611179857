import React from "react";
import "./FoodCard.scss";
import { useCanteenParentOrder } from "../../store/UseCanteenParentOrder";

const FoodCard = ({ data }) => {
  const { studentFoodList, activeStudentToken, setStudentFoodList } =
    useCanteenParentOrder();

  const findIndex = studentFoodList?.findIndex(
    (itm) => itm.student_id === activeStudentToken
  );
  const handleCheckboxChange = (event) => {
    const menuId = data?.menus?.menu_id;
    const tempData = [...studentFoodList];

    if (event.target.checked) {
      tempData[findIndex].food_menu.push(menuId);
      setStudentFoodList(tempData);
    } else {
      tempData[findIndex].food_menu = tempData[findIndex].food_menu.filter(
        (id) => id !== menuId
      );

      setStudentFoodList(tempData);
    }
  };

  const checked = studentFoodList?.[findIndex]?.food_menu?.includes(
    data?.menus?.menu_id
  );
  return (
    <div className="food_card_container shadow-sm">
      <div className="img_name_wrapper">
        <div className="img_container">
          {data?.image ? (
            <img src={data?.menus?.image} alt="food" />
          ) : (
            <div className="no_img">{data?.menus?.name?.charAt(0)}</div>
          )}
        </div>
        <h3 className="food_name">{data?.menus?.name}</h3>
      </div>
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <p className="price_wrapper">{`Rs. ${data?.menus?.price}`}</p>
        <div className="checkbox_wrapper">
          <input
            type="checkbox"
            id={`checkbox-${data?.menus?.menu_id}`}
            onClick={(e) => {
              handleCheckboxChange(e);
            }}
            checked={checked}
          />
          <label htmlFor={`checkbox-${data?.menus?.menu_id}`}></label>
        </div>
      </div>
    </div>
  );
};

export default FoodCard;
