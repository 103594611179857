import React, { useEffect, useState } from "react";
import "./studentTransfer.scss";
import Select from "react-select";
import { useSelectableTable } from "./useSelectableTable";
import StudentTransferTable from "./StudentTransferTable";
import { getGradeApi, getPrevClass, getStudentTable } from "../Api/GetApi";
import { postStudentClassTransfer } from "../Api/PostApi";
import { toast } from "react-toastify";
import NepaliDate from "nepali-date-converter";

const StudentTransfer = () => {
  const [year, setYear] = useState(null);
  const [grade, setGrade] = useState(null);
  const [currentGrade, setCurrentGrade] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [prevGradeOptions, setPrevGradeOptions] = useState([]);
  const [currentGradeOptions, setCurrentGradeOptions] = useState([]);

  //Current year to fetch the table of current class
  useEffect(() => {
    const currentNepaliYear = new NepaliDate().format("YYYY");
    setCurrentYear(currentNepaliYear);
  }, [currentYear]);

  //Returned values from the useSelectableTable hook
  const {
    isItemSelected: isItemSelectedPrev,
    selectedItemList: selectedItemListPrev,
    handleSelectItem: handleSelectItemPrev,
    searchedInput: searchedInputPrev,
    handleSearch: handleSearchPrev,
    setTableData: setTableDataPrev,
    filteredData: filterDataPrev,
    areAllSelected: areAllSelectedPrev,
    handleSelectAll: handleSelectAllPrev,
    setIsItemSelected: setIsItemSelectedPrev,
    setSelectedItemList: setSelectedItemListPrev,
  } = useSelectableTable();

  const {
    isItemSelected: isItemSelectedCurr,
    selectedItemList: selectedItemListCurr,
    handleSelectItem: handleSelectItemCurr,
    searchedInput: searchedInputCurr,
    handleSearch: handleSearchCurr,
    setTableData: setTableDataCurr,
    filteredData: filteredDataCurr,
    areAllSelected: setAreAllSelectedCurr,
    handleSelectAll: handleSelectAllCurr,
    setIsItemSelected: setIsItemSelectedCurr,
    setSelectedItemList: setSelectedItemListCurr,
  } = useSelectableTable();

  const yearOptions = [
    { value: 2080, label: "2080" },
    { value: 2079, label: "2079" },
    { value: 2078, label: "2078" },
    { value: 2077, label: "2077" },
    { value: 2076, label: "2076" },
    { value: 2075, label: "2075" },
  ];

  //Previous class fetch
  useEffect(async () => {
    if (year) {
      const res = await getPrevClass(year);
      const prevClass = res.data.prev_class;
      setPrevGradeOptions(
        prevClass.map((grade) => ({
          value: grade.id,
          label: grade.name,
        }))
      );
    }
  }, [year]);

  //Previous class student table fetch
  useEffect(async () => {
    if (year && grade) {
      getStudentTable(year, grade).then((res) =>
        setTableDataPrev(res.data.tabledata)
      );
    }
  }, [year, grade]);

  //Current grade fetch
  useEffect(() => {
    getGradeApi().then((res) => {
      const gradeOptions = res.data;

      setCurrentGradeOptions(
        gradeOptions?.grade?.map((grade) => ({
          value: grade.id,
          label: grade.classAlpha,
        }))
      );
    });
  }, []);

  //Current class student table fetch
  useEffect(() => {
    if (currentGrade) {
      getStudentTable(currentYear, currentGrade).then((res) => {
        setTableDataCurr(res.data.tabledata);
      });
    }
  }, [currentGrade, currentYear]);

  const handleSubmit = async () => {
    if (year && grade && currentGrade && selectedItemListPrev.length > 0) {
      const data = {
        year: year,
        studentToken: selectedItemListPrev,
        newGradeToken: currentGrade,
      };
      try {
        await postStudentClassTransfer(data).then((res) => {
          toast.success(res.data.message);
        });
        await getStudentTable(year, grade).then((res) =>
          setTableDataPrev(res.data.tabledata)
        );
        await getStudentTable(currentYear, currentGrade).then((res) =>
          setTableDataCurr(res.data.tabledata)
        );
        setSelectedItemListPrev([]);
        setIsItemSelectedPrev("");
      } catch (error) {
        toast.error("Something went wrong!!");
      }
    }
    if (!currentGrade) {
      toast.error("Please select current class");
    }
    if (selectedItemListPrev.length === 0) {
      toast.error("Please select student");
    }
  };

  return (
    <div className="student-transfer-section">
      <div className="title-block header">
        <h1 className="title">Student Transfer</h1>
      </div>
      <div className="row student-transfer-main">
        <div className="col-lg-6 col-sm-12 mb-4">
          <div style={{ display: "flex", gap: "14px", marginBottom: "20px" }}>
            <div style={{ width: "30%" }}>
              <label>Previous Year:</label>
              <Select
                options={yearOptions}
                onChange={(option) => {
                  setYear(option.value);
                }}
              />
            </div>
            {year && (
              <div style={{ width: "30%" }}>
                <label>Previous Class:</label>
                <Select
                  options={prevGradeOptions}
                  onChange={(option) => {
                    setGrade(option.value);
                  }}
                />
              </div>
            )}
          </div>
          {year && grade && (
            <StudentTransferTable
              isItemSelected={isItemSelectedPrev}
              searchInput={searchedInputPrev}
              handleSearch={handleSearchPrev}
              handleSelectItem={handleSelectItemPrev}
              selectedItemList={selectedItemListPrev}
              tableData={filterDataPrev}
              areAllSelected={areAllSelectedPrev}
              handleSelectAll={handleSelectAllPrev}
              handleSubmit={handleSubmit}
              setTableData={setTableDataPrev}
              canTransfer
            />
          )}
        </div>
        <div className="col-lg-6 col-sm-12">
          <div style={{ width: "30%", marginBottom: "20px" }}>
            <label>Current Class:</label>
            <Select
              options={currentGradeOptions}
              // styles={customStyles}
              onChange={(option) => {
                setCurrentGrade(option.value);
              }}
            />
          </div>
          {currentGrade && (
            <StudentTransferTable
              isItemSelected={isItemSelectedCurr}
              searchInput={searchedInputCurr}
              handleSearch={handleSearchCurr}
              handleSelectItem={handleSelectItemCurr}
              // selectedItemList={selectedItemListCurr}
              tableData={filteredDataCurr}
              areAllSelected={setAreAllSelectedCurr}
              // handleSelectAll={handleSelectAllCurr}
              setTableData={setTableDataCurr}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StudentTransfer;
