import React from 'react';
export default function Header(props){
    return(
        <>
        {props.header?.showHead ? 
            <div className="layout-top-header">
            {props.header.headerImage === "" ? 
     
                 <>
                    <div className="layout-logo">
                        <img src={props.schoolData?.logo} alt="img" /> 
                    </div>
                    <div className="top-header-block">
                    <h2 className="top-header-title">{props.schoolData?.school_name}</h2>
                        <p className="top-header-content">{props.schoolData?.address}</p>
                        <p className="top-header-content">ESTD : {props.schoolData?.estd}</p>
                    </div>
                </> 
                :
                <div className='header-img-block'>
                    <img className="report-img" src={props.header.headerImage} alt="img" style={{width: "100%"}}/>
                </div>}
                  
            </div> 
            :""}
    </>
    );
}