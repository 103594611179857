import React, { useState } from "react";
import { addCustomStatus } from "../Api/PostApi";
import { toast } from "react-toastify";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { FormikErrorMessage } from "../HorizontalStepper/FormikWrapper";
import { usePreferenceStore } from "../store/useStore";

const validationSchema = Yup.object().shape({
  status_type: Yup.string().required("This field is required"),
  color: Yup.string().required("This field is required"),
});

export const CustomStatusModal = ({ closeStatusModal, closeModal }) => {
  const { preferenceData } = usePreferenceStore();
  const [isBtnLoading, setIsBtnLoading] = useState();
  return (
    <div className="custom-status-modal">
      <Formik
        initialValues={{
          status_type: "",
          color: "",
          default_status: false,
        }}
        onSubmit={async (values, { resetForm }) => {
          setIsBtnLoading(true);
          try {
            addCustomStatus(values).then((res) => {
              if (res.status === 201) {
                toast("Custom status added successfully");
                closeStatusModal();
                resetForm();
                preferenceData();
              } else {
                toast("something went wrong");
              }
            });
          } catch (error) {
            console.error("error", error);
          } finally {
            setIsBtnLoading(false);
          }
        }}
        validationSchema={validationSchema}
      >
        {({ setFieldValue }) => (
          <Form>
            <div className="sub-modal" onClick={closeStatusModal}>
              <i class="bx bx-left-arrow-alt"></i>
              <p>Add Custom Status</p>
            </div>

            <div className="custom-status-content form-group">
              <label>Custom Status</label>
              <Field name="status_type" type="text" className="form-control" />

              <FormikErrorMessage name="status_type" />
            </div>

            <div className="custom-status-content form-group">
              <label>Color </label>
              <Field name="color" type="color" className="form-control" />

              <FormikErrorMessage name="color" />
            </div>

            {/* <div className="custom-status-content form-group">
              <div className="remark-field">
                <div className="symbol-wrap ">
                  <Field
                    type="checkbox"
                    name="default_status"
                    className="check-box"
                    onChange={(e) => {
                      setFieldValue("default_status", e.target.checked);
                    }}
                  />

                  <p className="field-label">Default</p>
                </div>
              </div>
            </div> */}

            <div className="modal-footer-btn">
              <button className="primary-btn" type="submit">
                {isBtnLoading ? "Saving" : "Save status"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
