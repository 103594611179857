import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { InputField } from "../../../components/ui/formikFields/inputField";
import { useTeachingPlanMainStore } from "../../teachingPlanMain/store/teachingPlanMainStore";
import { useTeachingPlanEvaluationFormikWrapper } from "../formikWrapper/formikWrapper";
import "./unitLessonEvaluation.scss";
import { ResetConfirmationModal } from "./resetConfirmationPopup";
import { toast } from "react-toastify";
import { ResetTeachingPlanEvaluation } from "../../../components/Api/PostApi";

export const UnitLessonEvaluation = ({ fetchTeachingEvaluation }) => {
  const [visibleType, setVisibleType] = useState();
  const {
    teachingEvaluationList,
    submitEvaluationPlan,
    setSubmitEvaluationPlan,
    setViewPage,
    setSelectedEvaluationToken,
    setIsBulkUpdate,
    setSubmitStudentEvaluation,
    selectedEvaluationToken,
  } = useTeachingPlanMainStore();
  const { isSubmitting, handleSubmit } =
    useTeachingPlanEvaluationFormikWrapper();

  const [token, setToken] = useState();
  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");

  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const handleChange = (e, index) => {
    e.preventDefault?.();
    const updatedItem = produce(submitEvaluationPlan[index], (draft) => {
      draft[e.target.name] = Number(e.target.value);
    });
    setSubmitEvaluationPlan?.(
      produce(submitEvaluationPlan, (draft) => {
        draft[index] = updatedItem;
      })
    );
  };

  const handleBulkUpdate = () => {
    setIsBulkUpdate(true);
    setViewPage("Student Evaluation");
    setSubmitStudentEvaluation([]);
    const temp = teachingEvaluationList?.filter((item) => item.weightage > 0);
    setSelectedEvaluationToken(temp);
  };
  const handleReset = (evaluation_meta_token) => {
    const data = {
      evaluation_meta_token: evaluation_meta_token,
    };
    ResetTeachingPlanEvaluation(data)
      .then((res) => {
        if (res.status === 204 || res.status === 200) {
          setVisibleType("");
          toast.success(res?.data?.message);
          fetchTeachingEvaluation?.();
        }
      })
      .catch((err) => {
        toast.error(err);
        setVisibleType("");
      })
      .finally(() => {
        setVisibleType("");
      });
  };

  const handleDiscard = () => {
    setVisibleType("");
    setSelectedEvaluationToken([]);
  };

  return (
    <div className="unit-lesson-evaluation">
      <div className="section-gap">
        <div className="unit-lesson-title-section">
          <h4 className="title unit-lesson-title">Evaluation Criteria</h4>
          {teachingEvaluationList?.some(
            (item) => item.weightage !== null && Number(item.weightage) > 0
          ) && (
            <div
              className="icon-section"
              onClick={() => {
                handleBulkUpdate();
              }}
            >
              <i className="bx bx-right-arrow-alt"></i>
            </div>
          )}
        </div>
        <div className="unit-lesson-section">
          <table className="table mb-8">
            <thead className="thead-light">
              <tr>
                <th width="50%">Evaluation Type</th>
                <th width="50%">Mark</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {teachingEvaluationList?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td width="50%">{item?.title}</td>
                    {item?.weightage !== null && Number(item?.weightage) > 0 ? (
                      <>
                        <td width="50%">{item?.weightage}</td>
                        <td width="50%">
                          <div className="d-flex">
                            <div
                              className="icon-section mr-3"
                              title="Reset"
                              onClick={() => {
                                setVisibleType("reset");

                                setSelectedEvaluationToken([item]);
                              }}
                            >
                              <i className="bx bx-reset"></i>
                            </div>
                            <div
                              className="icon-section"
                              title="Student Evaluation"
                              onClick={() => {
                                setViewPage("Student Evaluation");
                                setSelectedEvaluationToken([item]);
                              }}
                            >
                              <i className="bx bx-right-arrow-alt"></i>
                            </div>
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td width="50%">
                          <InputField
                            name={`weightage`}
                            className="input-field"
                            type="number"
                            placeHolder="0"
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                            onKeyPress={(event) => {
                              if (!/^[0-9.]$/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            onFocus={(e) => {
                              if (e.target.value === "0") {
                                e.target.value = "";
                              }
                            }}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                e.target.value = "0";
                                handleChange(e, index);
                              }
                            }}
                          />
                        </td>
                        <td width="50%"></td>
                      </>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          {teachingEvaluationList?.some((item) => {
            return item.weightage === null || Number(item.weightage) === 0;
          }) && (
            <div className="btn-section btn-block">
              <button
                className="main-btn"
                type="submit"
                disabled={isSubmitting}
                onClick={handleSubmit}
              >
                {isSubmitting ? (
                  <>
                    <i class="bx bx-loader-circle bx-spin"></i> Submitting ...
                  </>
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          )}
        </div>
        {visibleType?.toLowerCase() === "reset" && (
          <ResetConfirmationModal
            visible={visibleType === "reset"}
            setVisible={setVisibleType}
            onReset={() => {
              handleReset(selectedEvaluationToken?.[0]?.evaluation_meta_token);
            }}
            onDiscard={() => {
              handleDiscard();
            }}
          />
        )}
      </div>
    </div>
  );
};
