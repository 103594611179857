import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
// import { SchoolwiseAdmission } from "./components/AdmissionForm/SchoolwiseAdmission";
import { Assignment } from "./components/Assignment/Assignment";
import { ContactInfo } from "./components/ContactInfo/ContactInfo";
import { ECAHeader } from "./components/ECAHeader/ECAHeader";
import { ParentsInfo } from "./components/ParentsInfo/ParentsInfo";
import { PersonalInfo } from "./components/PersonalInfo/PersonalInfo";
import AddDetailsPage from "./components/addDetailsPage/addDetailsPage";
import { ExamAttendance } from "./components/examAttendance/examAttendance";
import { ExamMarks } from "./components/examMarks/examMarks";
import Home from "./components/home";
import MealPlan from "./components/mealPlan/mealList/mealPlan";
import Notice from "./components/notice/notice";
import SinglePlanCard from "./components/planSchedule/planScheduleComponents/Timeline/singlePlanCard/singlePlanCard";
import Timeline from "./components/planSchedule/planScheduleComponents/Timeline/timeline";
import PopUp from "./components/popUp/popUp";
import { Preview } from "./components/preview/preview";
import { PreviewTable } from "./components/preview/previewTable";
import { PreviewContainer } from "./components/result/PreviewContainer/PreviewContainer";
import Result from "./components/result/result";
import { ResultCard } from "./components/result/resultCard/resultCard";
import RoutePage from "./components/routePage";
import SchoolWall from "./components/schoolWall/schoolWall";

import { StudentProfileUpdater } from "./components/StudentProfileUpdater/StudentProfileUpdater";

import IframeContainer from "./components/IframeContainer";
import TeacherAssessment from "./components/TeacherAssessment/TeacherAssessment";

import { AttendanceSinglePage } from "./components/AttendanceSinglePage/AttendanceSinglePage";
import EmployeeHierarchy from "./components/Employee Hierarchy/EmployeeHierarchy";
import { RemarkPage } from "./components/RemarkPage/RemarkPage";
import Schoollist from "./components/SchoolListM/Schoollist";
import "./index.css";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import AccountDashboard from "./pages/accountDashboard/AccountDasboard";
import { Elearning } from "./pages/elearning";
import ChapterPage from "./pages/elearning/ChapterPage/ChapterPage";

import { ChapterList } from "./pages/elearning/chapterList/chapterList";
import { SchoolRegistration } from "./pages/schoolRegistration";
import StudentProfilePage from "./pages/StudentProfilePage/StudentProfilePage";
import TeacherDashboard from "./pages/teacherdashboard/TeacherDashboard";
import { TeachingPlan as TeachingPlan2 } from "./pages/teachingPlan";
import Activity from "./components/activity/Activity";
import TeacherProfile from "./components/TeacherProfile/TeacherProfile";

import CanteenParentOrderSelection from "./components/CanteenParentOrderSelection/CanteenParentOrderSelection";
import ChildProfile from "./components/ChildProfile/ChildProfile";
import Date from "./components/Date/Date";
import { LessonTab } from "./components/teachingPlanComponents/lessonTab";

import PortfolioSinglePage from "./components/PortfolioSinglePage/PortfolioSinglePage";
import { QRScan } from "./components/QRScan/QRScan";
import { SingleLessonPlan } from "./components/SingleLessonTab/SingleLessonTab";
import StudentImageAdd from "./components/StudentImageAdd/StudentImageAdd";
import StudentTransfer from "./components/StudentTransfer/StudentTransfer";
import StudentsFeedback from "./components/StudentsFeedback/StudentsFeedback";
import SuggestionBox from "./components/SuggestionBox/SuggestionBox";
import TeacherProfileUpdater from "./components/TeacherProfileUpdater/TeacherProfileUpdater";
import VisitorSchedule from "./components/VisitorSchedule/VisitorSchedule";
import VisitorScheduleTeacher from "./components/VisitorSchedule/VisitorScheduleTeacher";

import LibaryMangement from "./components/LibaryManagement/LibaryMangement";
import CanteenOrderMain from "./components/CanteenOrder/CanteenOrderMain";
import CanteenBill from "./components/CanteenBill/CanteenBill";
import BookReview from "./components/BookReview/BookReview";
import StudentFeedback from "./components/StudentFeedback/StudentFeedback";

import { MyTeachingPlan } from "./pages/myTeachingPlan";
import { studentProfile } from "./pages/studentProfile";
import TeacherProfiles from "./pages/teacherProfile";
import { TeachingPlanEvaluation } from "./pages/teachingPlanEvaluation";
import { SinglePlanDetails } from "./pages/teachingPlanMain/singlePlanDetails";
import { LessonPlanBlock } from "./components/LessonPlanBlock/LessonPlanBlock";
import { TeachingPlanEvaluationGraph } from "./pages/teachingPlanEvaluationGraph";
import TeacherGroupChat from "./pages/TeacherGroupChat/TeacherGroupChat";
import LibraryManagement from "./components/LibaryManagement/LibaryMangement";
import { PreferenceBlock } from "./components/PreferenceBlock/PreferenceBlock";
import { TeacherStudentAttendance } from "./components/TeacherStudentAttendance";
import { Player } from "./components/Ezuikit/Player";
import { AttendanceApproval } from "./components/AttendanceApproval/AttendanceApproval";
function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path={`/`} component={SchoolRegistration} />
          <Route exact path={`/canteen`} component={MealPlan} />
          {/* <Route exact path={`/new_plan`} component={NewPlan} /> */}
          <Route exact path={`/add_details`} component={AddDetailsPage} />
          <Route exact path={`/single_plan_card`} component={SinglePlanCard} />
          <Route exact path={`/timeline`} component={Timeline} />
          <Route exact path={`/school_wall`} component={SchoolWall} />
          <Route exact path={`/notice`} component={Notice} />
          <Route exact path={`/popup`} component={PopUp} />
          <Route exact path={`/result`} component={Result} />
          <Route exact path={`/assignment`} component={Assignment} />
          <Route exact path={`/exam-layout`} component={ResultCard} />
          <Route exact path={`/eca-header`} component={ECAHeader} />
          <Route exact path={`/exam-attendance`} component={ExamAttendance} />
          <Route exact path={`/`} component={Home} />
          {/* <Route exact path={`/speech-dectector`} component={Speech} /> */}
          {/* Date */}
          <Route exact path={`/date`} componet={Date} />
          <Route exact path={`/personal-info`} component={PersonalInfo} />
          {/* Students feedback */}
          <Route
            exact
            path={`/students-feedback`}
            component={StudentsFeedback}
          />
          <Route exact path={`/video`} component={Player} />
          <Route exact path={`/parents-info`} component={ParentsInfo} />
          <Route exact path={`/contact-info`} component={ContactInfo} />
          <Route
            exact
            path={`/attendance-visuals`}
            component={AttendanceSinglePage}
          />
          <Route exact path={`/canteen_order`} component={CanteenOrderMain} />
          <Route
            exact
            path={`/previewContainer`}
            component={PreviewContainer}
          />
          {/* Libary Management*/}
          <Route
            exact
            path={`/library_management`}
            component={LibraryManagement}
          />

          <Route exact path={`/bill`} component={CanteenBill} />
          <Route
            exact
            path={`/student-profile-updater`}
            component={StudentProfileUpdater}
          />
          <Route exact path={`/preview`} component={Preview} />
          <Route exact path={`/preview/:id`} component={PreviewTable} />
          <Route exact path={`/redirect`} component={RoutePage} />
          <Route exact path={`/exam_marks`} component={ExamMarks} />
          <Route exact path={`/exam_marks/new`} component={ExamMarks} />
          <Route exact path={`/chapter-list`} component={ChapterList} />
          <Route exact path={`/chapter`} component={ChapterPage} />
          <Route exact path={`/schoollist`} component={Schoollist} />
          <Route exact path={`/visitor-schedule`} component={VisitorSchedule} />
          <Route exact path="/admissionForm">
            {/* <SchoolwiseAdmission /> */}
          </Route>
          <Route exact path={`/student_transfer`} component={StudentTransfer} />
          <Route exact path={`/suggestion`} component={SuggestionBox} />
          <Route
            exact
            path={`/teacher-profile-updater`}
            component={TeacherProfileUpdater}
          />
          <Route exact path={`/qr_scan`} component={QRScan} />
          <IframeContainer
            exact
            path={`/account_dashboard`}
            component={AccountDashboard}
          />
          <Route exact path={`/hierarchy`} component={EmployeeHierarchy} />
          <IframeContainer
            exact
            path={`/hod_dashboard`}
            component={TeacherDashboard}
          />
          <IframeContainer
            exact
            path={`/teaching-plan2`}
            component={MyTeachingPlan}
          />
          <IframeContainer exact path={`/new_plan`} component={TeachingPlan2} />
          <IframeContainer
            exact
            path={`/single_unit_plan`}
            component={SinglePlanDetails}
          />
          <Route
            exact
            path={`/appointment-teacher-section`}
            component={VisitorScheduleTeacher}
          />
          <IframeContainer
            exact
            path={`/student-image-add`}
            component={StudentImageAdd}
          />
          <Route exact path={`/student-profile`} component={studentProfile} />
          <IframeContainer
            exact
            path={`/teacher-profile`}
            component={TeacherProfiles}
          />
          <IframeContainer
            exact
            path={`/teacher_activity`}
            component={TeacherAssessment}
          />
          <IframeContainer
            exact
            path={`/teacher_profile`}
            component={TeacherProfile}
          />
          <IframeContainer
            exact
            path={`/lesson_tab`}
            component={SingleLessonPlan}
          />
          <IframeContainer
            exact
            path={`/student_profile/:id`}
            component={StudentProfilePage}
          />
          <IframeContainer exact path={`/elearning`} component={Elearning} />
          <IframeContainer exact path={`/dashboard`} component={Dashboard} />
          <IframeContainer
            exact
            path={`/remarks_entry`}
            component={RemarkPage}
          />
          <IframeContainer
            exact
            path={"/canteen_menu_selection"}
            component={CanteenParentOrderSelection}
          />
          <IframeContainer
            exact
            path={`/child_profile`}
            component={ChildProfile}
          />
          <IframeContainer
            exact
            path={`/portfolio_page/:id`}
            component={PortfolioSinglePage}
          />

          <IframeContainer
            exact
            path={`/student_feedback`}
            component={StudentFeedback}
          />
          <IframeContainer
            exact
            path={`/teaching-plan-evaluation`}
            component={TeachingPlanEvaluation}
          />
          <IframeContainer
            exact
            path={`/lesson_plan_block`}
            component={LessonPlanBlock}
          />
          <IframeContainer
            path={`/teaching-plan-evaluation-graph`}
            component={TeachingPlanEvaluationGraph}
          />
          <IframeContainer
            exact
            path={"/teacher/group_chat"}
            component={TeacherGroupChat}
          />
          <IframeContainer
            exact
            path={`/add_preference`}
            component={PreferenceBlock}
          />
          <IframeContainer
            exact
            path={`/student_attendance`}
            component={TeacherStudentAttendance}
          />

          <IframeContainer
            exact
            path={`/attendance_approval`}
            component={AttendanceApproval}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
