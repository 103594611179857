import { ErrorMessage, Formik, useFormikContext } from "formik";
import * as React from "react";
import { useToast } from "react-toastify";
import * as Yup from "yup";
// import { useToast } from "../context/toast/toast";
import { toast, ToastContainer } from "react-toastify";

export const initialValues = {
  gradeFor: "",
  firstName: "",
  middleName: "",
  lastName: "",
  BSdob: "",
  ADdob: "",
  gender: "Male",
  age: "",
  nationality: "",
  email: "",
  phone: "",
  mobileNo: "",
  religion: "",
  caste: "",
  bloodGroup: "",
  province: "",
  municipality: "",
  street: "",
  country: "",
  state: "",
  district: "",
  ward: "",
  houseNo: "",
  fatherName: "",
  femail: "",
  foccupation: "",
  fmobile: "",
  fpost: "",
  foffice: "",
  ftel: "",
  motherName: "",
  memail: "",
  moccupation: "",
  mmobile: "",
  mpost: "",
  moffice: "",
  mtel: "",
  guardianName: "",
  gaddress: "",
  grelation: "",
  goccupation: "",
  gmobile: "",
  gtel: "",
};

const validationSchema = Yup.object().shape({
  gradeFor: Yup.string().required("Grade name is required"),
  firstName: Yup.string().required("First Name is required"),
  middleName: Yup.string().required("Middle  Name is required"),
  lastName: Yup.string().required("LastName is required"),
  BSdob: Yup.string().required("Date of birth in BS is required"),
  ADdob: Yup.string().required("Date of birth in AD is required"),
  gender: Yup.string().required("Gender is required"),
  nationality: Yup.string().required("Nationality is required"),
  phone: Yup.string().min(10, "Must be 10 characters or more"),
  mobileNo: Yup.string()
    .required("Plese enter your mobile number")
    .min(10, "Must be 10 characters or more"),
});
export const useAdmissionFormFormikContext = () => {
  const formik = useFormikContext();
  if (!formik) {
    throw new Error("Formik context must be used within a Formik");
  }
  return formik;
};
export const FormikWrapper = ({ children }) => {
  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    const data = {
      ...values,
    };
    data.catch((err) => {
      let errorMessage = "Failed to proceed";
      if (err.message) {
        errorMessage = err.message;
      }
      if (err.response.data.message) {
        errorMessage = err.response.data.message;
      }
      toast.error(errorMessage);
    });
  };

  return (
    <>
      <ToastContainer />
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {children}
      </Formik>
    </>
  );
};

export const FormikErrorMessage = ({ name }) => {
  return <ErrorMessage name={name} component="div" className="error" />;
};
