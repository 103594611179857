import React from "react";

export default function ECALayoutTbody(props) {
  var newIndex = 0;
  const studentDetail = props.studentDetail;

  return (
    <>
      <table className="table table-bordered layout-one-two-table-three fit-content">
        <thead className="eca">
          <tr>
            <th>ECA Title</th>
            <th>Grade</th>
          </tr>
        </thead>
        <tbody className="eca">
         
        {studentDetail.additional_subjects?.map(
            (additional_subject, add_sub_index) => {
              return (
                <tr key={add_sub_index}>
                  <td className="layout-subject-body">
                    {additional_subject.subject}
                  </td>

                  <td>{additional_subject.grade}</td>
                </tr>
              );
            }
          )}
         
        </tbody>
      </table>
    </>
  );
}
