import { FieldArray, Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import Rating from "react-rating";
import { toast } from "react-toastify";
import { postTeacherActivityForm } from "../Api/PostApi";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { useAssignmentDetails, usePreferenceStore } from "../store/useStore";
import { AssessmentContext } from "../TeacherAssessment/TeacherAssessment";
import "./StudentAssignmentCard.scss";

const StudentAssignmentCard = ({
  handleDownload,
  studentList,
  ishomework,
  setBtnLoading,
  isBtnLoading,
  viewonly,
  assignmentToken,
  checked,
}) => {
  const [assignmentDetails, setAssignmentDetails] = useState();
  const { data } = usePreferenceStore();
  const { gradeToken } = useContext(AssessmentContext);
  const { fetchAssignmentDetails } = useAssignmentDetails();
  const {
    gradeToken: contextGradeToken,
    subjectToken: contextSubjectToken,
    iframe,
  } = useContext(AssessmentContext) || {};
  useEffect(() => {
    const details = studentList?.map((item, index) => {
      // const defaultStatus = data?.assignment_statuses?.filter(
      //   (item) => item.default_status === true
      // );

      return {
        studentToken: item?.studenttoken,
        assignmentId: item?.assignmentid,
        imageToken: item?.imageToken,
        remark: item?.remark,
        checked: item?.checked,
        checkedImageToken: item?.checkedImageToken,
        is_homework: ishomework,
        status_token: item?.status_token,
        marks: item?.marks ? item?.marks : 5,
        rollNo: item?.rollNo,
        studentName: item?.studentName,
        gradeToken: viewonly ? "" : contextGradeToken,
        subjectToken: viewonly ? "" : contextSubjectToken,
      };
    });

    setAssignmentDetails(details);
  }, [studentList]);

  const initialValues = {
    assignment_data: assignmentDetails && assignmentDetails,
  };

  return (
    <div className="student-assignment-card">
      {assignmentDetails ? (
        <Formik
          key={assignmentDetails}
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            const updatedData = values.assignment_data.map((assignment) => {
              // If status_token is present, set checked to true, otherwise keep it as is
              const statusAvailable =
                assignment.status_token === null ||
                assignment.status_token === "";
              return {
                ...assignment,
                checked: !statusAvailable,
              };
            });

            const submittedOne = updatedData?.filter(
              (item) => item.checked === true
            );

            // Update state with the new assignment data
            const submitValus = {
              assignment_data: submittedOne,
            };
            setBtnLoading(true);
            postTeacherActivityForm(submitValus)
              .then((res) => {
                if (res.status === 200) {
                  toast("Assignment Updated Successfully");
                  resetForm();

                  fetchAssignmentDetails({
                    gradeToken,
                    assignmentToken,
                    ishomework,
                  });
                }
              })
              .catch((err) => {
                console.log("error", err);
                toast("something went wrong");
              })
              .finally(() => {
                setBtnLoading(false);
              });
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <div className="row" style={{ marginTop: "20px" }}>
                <FieldArray name="assignment_data">
                  {() => {
                    return (
                      <>
                        {values.assignment_data?.map((item, index) => {
                          const statusName = data?.assignment_statuses?.filter(
                            (item) =>
                              item.id ===
                              values.assignment_data?.[index].status_token
                          );
                          return (
                            <div className="col-md-6" key={index}>
                              <div className="student-details-card">
                                <div className="student-details-block">
                                  <div className="details-content">
                                    <div className="form-group block-content">
                                      <label>Roll No.</label>
                                      <p className="content">{item?.rollNo}</p>
                                    </div>

                                    <div className="form-group block-content">
                                      <label>student name</label>
                                      <p className="content text-capitalize">
                                        {item?.studentName}
                                      </p>
                                    </div>
                                  </div>

                                  <div className="assignment-image-section">
                                    <label>Images</label>

                                    <div className="assignment-image-list">
                                      {item?.imageToken ? (
                                        <div className="details-block">
                                          <p className="file-name">
                                            {item.imageToken}
                                          </p>
                                          <div
                                            onClick={() => {
                                              handleDownload(item?.imageToken);
                                            }}
                                          >
                                            <i class="bx bxs-download"></i>
                                          </div>
                                        </div>
                                      ) : (
                                        <p>No Image Uploaded</p>
                                      )}
                                    </div>
                                  </div>
                                </div>

                                <div className="status-section">
                                  {data && (
                                    <>
                                      {data?.school_settings?.[0]
                                        ?.assignment_marking ||
                                      data?.assignment_statuses?.length > 2 ? (
                                        <div className="row">
                                          <div className="col-md-12">
                                            <label>Status</label>
                                            {values.assignment_data?.[index]
                                              .checked ? (
                                              <p>
                                                {statusName?.[0]?.status_type}
                                              </p>
                                            ) : (
                                              <select
                                                className="form-control"
                                                value={
                                                  values.assignment_data?.[
                                                    index
                                                  ].status_token
                                                }
                                                onChange={(e) => {
                                                  setFieldValue(
                                                    `assignment_data.${index}.status_token`,
                                                    e.target.value
                                                  );
                                                }}
                                              >
                                                <option value="">Select</option>
                                                {data?.assignment_statuses?.map(
                                                  (status, statusIdx) => {
                                                    return (
                                                      <option
                                                        key={statusIdx}
                                                        value={status?.id}
                                                      >
                                                        {status?.status_type}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </select>
                                            )}
                                          </div>

                                          {/* {data?.school_settings?.[0]
                                            ?.assignment_marking && (
                                            <div className="col-md-4">
                                              <label>Marks</label>
                                              <Field
                                                name={`assignment_data.${index}.marks`}
                                                type="text"
                                                className="form-control"
                                                placeholder="0"
                                              />
                                            </div>
                                          )} */}
                                        </div>
                                      ) : (
                                        <div className="status-area-block">
                                          <label>Status</label>

                                          <div className="status-list-wrap">
                                            {data?.assignment_statuses?.map(
                                              (itm, idx) => {
                                                const val =
                                                  values.assignment_data?.[
                                                    index
                                                  ].status_token;
                                                return (
                                                  <div
                                                    className={`status-block ${
                                                      val === itm.id &&
                                                      "active-status"
                                                    }`}
                                                    key={idx}
                                                    onClick={() => {
                                                      !viewonly &&
                                                        setFieldValue(
                                                          `assignment_data.${index}.status_token`,
                                                          itm.id
                                                        );
                                                    }}
                                                    value={itm?.id}
                                                  >
                                                    <div
                                                      className="color-indicator"
                                                      style={{
                                                        backgroundColor:
                                                          itm.color,
                                                      }}
                                                    ></div>
                                                    <p className="status-name">
                                                      {itm?.status_type}
                                                    </p>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      )}

                                      {/* {data?.school_settings?.[0]
                                        ?.assignment_remarks && (
                                        <div className="remark-area-block">
                                          <label>Remarks</label>
                                          <div>
                                            <Field
                                              as="textarea"
                                              placeholder="Enter remark"
                                              name={`assignment_data.${index}.remark`}
                                            ></Field>
                                          </div>
                                        </div>
                                      )} */}

                                      <div className="form-group mt-20">
                                        <label>Ratiings</label>

                                        <div className="rating-section">
                                          <Rating
                                            readonly={checked !== "checked"}
                                            initialRating={
                                              values.assignment_data?.[index]
                                                .marks
                                            }
                                            emptySymbol={
                                              <div className="empty-block">
                                                <i class="bx bxs-star"></i>
                                              </div>
                                            }
                                            fullSymbol={
                                              <div className="full-block">
                                                <i class="bx bxs-star"></i>
                                              </div>
                                            }
                                            onChange={(value) => {
                                              setFieldValue(
                                                `assignment_data.${index}.marks`,
                                                value
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                    );
                  }}
                </FieldArray>
              </div>

              {viewonly || checked === "unchecked" ? (
                ""
              ) : (
                <button
                  type="submit"
                  class={`sticky-btn ${isBtnLoading && "disable-cursor"}`}
                  disabled={isBtnLoading}
                >
                  <i class="bx bx-check"></i>
                </button>
              )}
            </Form>
          )}
        </Formik>
      ) : (
        <NoDataContent
          title="No Data"
          image={require("../../images/emptyfile.png").default}
          description="No data found  at the moment"
        />
      )}
    </div>
  );
};

export default StudentAssignmentCard;
