export const subjectDropdown = [
  { label: "Science", value: "Science" },
  { label: "Math", value: "Math" },
  { label: "Nepali", value: "Nepali" },
  { label: "G.K", value: "G.K" },
];

export const dateDropdown = [
  { label: "This Month", value: "This Month" },
  { label: "Last Month", value: "Last Month" },
  { label: "This Year", value: "This Year" },
];

export const dateMonthDropdown = [
  { label: "3 Month", value: 3 },
  { label: "6 Month", value: 6 },
  { label: "9 Month", value: 9 },
  { label: "12 Month", value: 12 },

];



export const classDropdown = [
  { label: "One", value: "One" },
  { label: "Two", value: "Two" },
  { label: "Three", value: "Three" },
  { label: "Four", value: "Four" },
];

export  const recentAssesment = [
  {
    subject:"Science",
    class:"Class 6’A’",
    assesment_type:"homework",
    date:"Apr 5, 2021"

  },
  {
    subject:"Science",
    class:"Class 6’A’",
    assesment_type:"homework",
    date:"Apr 5, 2021"

  },
  {
    subject:"Science",
    class:"Class 6’A’",
    assesment_type:"homework",
    date:"Apr 5, 2021"

  },
  {
    subject:"Science",
    class:"Class 6’A’",
    assesment_type:"homework",
    date:"Apr 5, 2021"

  },
  {
    subject:"Science",
    class:"Class 6’A’",
    assesment_type:"homework",
    date:"Apr 5, 2021"

  },
]



export const assesmentSubmitted = [
  {name:"nishan",
Marks: 0,
Remarks:" "},
{name:"hari",
Marks: 0,
Remarks:" "},
{name:"ram",
Marks: 0,
Remarks:" "},
{name:"syam",
Marks: 0,
Remarks:" "},
] 
