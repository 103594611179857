import Axios from "axios";
import { useIframeTokenStore } from "../store/useStore";

const axiosInstance = Axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    let { token } = useIframeTokenStore.getState();
    if (!token) {
      let urlparam = new URLSearchParams(window.location.search);
      token = urlparam.get("token") || localStorage.getItem("authToken");
    }
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["ngrok-skip-browser-warning"] = "true";

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);



export default axiosInstance;


