import React, { useState } from "react";
import "./accordion.scss";
import arrowUp from "../../images/arrow-up.svg";
import arrowDown from "../../images/arrow-down.svg";

import PopupModal from "../popupmodal/PopupModal";

const Acordion = ({ title, showAction,tableHeader,tableData }) => {

  const [isOpen, setIsOpen] = useState(false);
  
 

  return (
    <div className="accordion_container" >
      <div className="accordion_title" onClick={() => setIsOpen(!isOpen)}>
        {title}
        <span>
          {isOpen ? (
            <img src={arrowUp} alt="arrowUp" />
          ) : (
            <img src={arrowDown} alt="arrowDown" />
          )}
        </span>
      </div>

      {isOpen && (
        <div className="tableContainer">
          <table>
            <thead>
              <tr >
                {tableHeader.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
                {showAction===true && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {tableData?.map((row, index) =>{
                return (
                <tr className={`${showAction} ? active : inactive`} key={index}>
                  <td>{index+1}</td>
                  <td>{row.gradeName || row.expenseType}</td>
                  <td >{row.overdue_count}</td>

                  <td className="red">Rs.{row.overdue}</td>
                  {showAction && <td ><PopupModal gradeName={row.gradeName} gradeToken={row.gradeToken}/>  </td>}
                  
                </tr>
              )}
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default Acordion;
