import React, { useEffect, useState } from "react";
import "./expensesdoughnutchart.scss";
import Chart from "react-apexcharts";
import { useExpensesChart } from "../../store/accountDashboard";

import { NoDataContent } from "../NoDataContent/NoDataContent";
import NividLoader from "../NividLoader/NividLoader";

const ExpensesDoughnutChart = () => {
  const [timeLine, setTimeLine] = useState("this year");

  const { loading, expensesChart, fetchExpensesChart } = useExpensesChart();

  const expensesName = Array.isArray(expensesChart)
    ? expensesChart?.map((a) => a.ledgerName)
    : [];

  const expensesPercentage = Array.isArray(expensesChart)
    ? expensesChart?.map((a) => {
        const quantity = Number(a.percentage_amount);
        return isNaN(quantity) ? 0 : quantity;
      })
    : [];

  useEffect(() => {
    fetchExpensesChart(timeLine);
  }, [timeLine]);

  const series = expensesPercentage || [];

  const options = {
    chart: {
      type: "donut",
    },
    // width:140,
    labels: expensesName || [],

    dataLabels: {
      enabled: true,
    },
    legend: {
      position: "right",
    },

    //  title: {
    //      text: "Random",
    //     style: {
    //       fontSize: "16px",
    //       fontFamily: "Nunito Sans",
    //     },
    //   },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            show: true,
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <div className="doughnutChart2_container">
      <div className="heading">
        <div className="title_main">Total Expenses</div>
        <div>
          <select
            className="form-control"
            aria-label="Default select example"
            onChange={(e) => {
              setTimeLine(e.target.value);
            }}
          >
            <option value="this year" selected>
              This Fiscal Year
            </option>
            <option value="this month">This Month</option>
            <option value="last month">Last Month </option>
            <option value="this week"> This week</option>
            <option value="last week">Last Week</option>
          </select>
        </div>
      </div>
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              height: "300px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <NividLoader height={"325px"} />
          </div>
        ) : expensesChart?.length >= 1 ? (
          <Chart options={options} series={series} height={300} type="donut" />
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "300px",
            }}
          >
            <NoDataContent
              image={require("../../images/emptyfile.png").default}
              title="No Data"
              description="Couldn't find expenses data at the moment"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ExpensesDoughnutChart;
