import { ArrowRightAltRounded } from "@material-ui/icons";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import { FaCaretLeft, FaCaretRight, FaCheckDouble } from "react-icons/fa6";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { FaSortNumericDown, FaSortNumericUp } from "react-icons/fa";

const StudentTransferTable = ({
  searchInput,
  handleSearch,
  handleSelectItem,
  selectedItemList,
  isItemSelected,
  tableData,
  areAllSelected,
  handleSelectAll,
  handleSubmit,
  canTransfer,
  setTableData,
}) => {
  const [isAlphaSorted, setIsAlphaSorted] = useState(false);
  const [isNumSorted, setIsNumSorted] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;

  const pageCount = Math.ceil(tableData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentPageData = tableData.slice(offset, offset + itemsPerPage);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  useEffect(() => {
    setCurrentPage(0);
  }, [tableData]);

  return (
    <div className="table-section">
      <input
        className="field-input"
        value={searchInput}
        placeholder="Search for student..."
        onChange={handleSearch}
      />
      {/* Sorting the table */}
      {isAlphaSorted ? (
        <FaSortAlphaDown
          style={{
            position: "absolute",
            right: "25px",
            marginTop: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsAlphaSorted(false);
            setTableData(
              tableData.sort((a, b) =>
                a.studentName.localeCompare(b.studentName)
              )
            );
          }}
          size="20px"
        />
      ) : (
        <FaSortAlphaUp
          style={{
            position: "absolute",
            right: "25px",
            marginTop: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsAlphaSorted(true);
            setTableData(
              tableData.sort((b, a) =>
                a.studentName.localeCompare(b.studentName)
              )
            );
          }}
          size="20px"
        />
      )}
      {isNumSorted ? (
        <FaSortNumericDown
          style={{
            position: "absolute",
            right: "55px",
            marginTop: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsNumSorted(false);
            setTableData(tableData.sort((a, b) => a.rollNo - b.rollNo));
          }}
          size="20px"
        />
      ) : (
        <FaSortNumericUp
          style={{
            position: "absolute",
            right: "55px",
            marginTop: "15px",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsNumSorted(true);
            setTableData(tableData.sort((b, a) => a.rollNo - b.rollNo));
          }}
          size="20px"
        />
      )}
      <table className="table">
        <thead className="thead-light">
          <tr>
            <th>Roll no</th>
            <th>Student Name</th>
            <th>Class</th>
            <th>Section</th>
            {handleSelectAll && (
              //For select all button
              <FaCheckDouble
                className="selectAll"
                title={areAllSelected ? "Deselect all" : "Select all"}
                style={areAllSelected ? { color: "blue" } : { color: "black" }}
                onClick={handleSelectAll}
              />
            )}
          </tr>
        </thead>
        <tbody>
          {currentPageData?.map((data, index) => {
            const studentInfo = data.studentToken;
            const startIndex = data.gradeName.indexOf("(") + 1;
            const endIndex = data.gradeName.indexOf(")");
            const section = data.gradeName.substring(startIndex, endIndex);
            return (
              <tr
                key={index}
                onClick={() => {
                  handleSelectItem && handleSelectItem(index, studentInfo);
                }}
                className={
                  selectedItemList?.includes(data.studentToken) &&
                  isItemSelected
                    ? isItemSelected
                    : "tableRow"
                }
              >
                <td>{data.rollNo}</td>
                <td>{data.studentName}</td>
                <td>{data.classAlpha}</td>
                <td>{section}</td>
                <ArrowRightAltRounded className="arrow" />
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Pagination  */}
      {tableData.length > pageCount && (
        <div className="pagination">
          <ReactPaginate
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={handlePageChange}
            containerClassName="paginationContainer"
            activeClassName="active"
            previousLabel={<FaCaretLeft />}
            nextLabel={<FaCaretRight />}
          />
        </div>
      )}
      {tableData.length === 0 && (
        <NoDataContent
          image={require("../../images/emptyfile.png").default}
          title="No Students available"
        />
      )}
      {canTransfer && (
        <button className="primary-btn" onClick={handleSubmit}>
          Transfer
        </button>
      )}
    </div>
  );
};

export default StudentTransferTable;
