import Table from "../Reusable Table/Table";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./feeHistoryTab.scss";
import { useStudentStore } from "../../store/store";
const FeeHistoryTab = () => {
  const { student } = useStudentStore();
  const headers = ["Date", "Detail", "Amount Due"];
  const paidHeaders = ["Date", "Detail", "Amount Paid"];

  const dueRows =
    student?.accontig_details?.[0]?.due_list?.map((due) => [
      due.miti,
      due.name,
      `Rs ${due.amount}/-`,
    ]) ?? [];
  const paidRows =
    student?.accontig_details?.[0]?.paid_list?.map((paid) => [
      paid.miti,
      paid.name,
      `Rs ${paid.amount}/-`,
    ]) ?? [];

  return (
    <div className="fee-tab-container">
      <Tabs>
        <Tab eventKey="due" title="Due">
          <Table headers={headers} rows={dueRows} showEntries title="Due" />
        </Tab>
        <Tab eventKey="paid" title="Paid">
          <Table
            headers={paidHeaders}
            rows={paidRows}
            showEntries
            title="Paid"
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default FeeHistoryTab;
