import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";

import "./NebLedger.scss";
import "../../MarksheetLayout.scss";

export const NEBGradeSheet = (props) => {
  const [gradeList, setGradeList] = useState();

  const bgImage = `url(${props.info?.schoolData?.logo})`;
  const backgroundTitle = `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30"%3E%3Ctext x="0" y="20" font-size="14" fill="%235494c9"%3E${props.info?.schoolData?.school_name}%3C/text%3E%3C/svg%3E')`;
  useEffect(() => {
    setGradeList(props?.resultData[0].markdata);
  }, [props]);

  var prevCode = 0;

  const filterSubjectToRender = (titleData, index) => {
    var filterSubjects = titleData?.filter((item) => {
      let theoryMark = parseFloat(
        gradeList[index]?.subjects[item.subjectName]?.theorymark
      );
      if (item?.optionSubject && theoryMark == 0) {
        return false;
      } else if (isNaN(theoryMark)) {
        return false;
      }
      return true;
    });
    return filterSubjects;
  };

  const getFinalGrade = (studentDetail, subject) => {
    var higherMark = studentDetail?.highermark?.find(
      (eachStudent) => eachStudent.group_code === subject.group_code
    );

    if (subject.group_code != null && prevCode == subject.group_code)
      return <td className="bb-hidden" rowSpan="2"></td>;
    prevCode = subject.group_code;
    return (
      <td className="bb-hidden" rowSpan="2">
        {subject.group_code == null
          ? studentDetail.subjects[subject.subjectName]?.totalgrade
          : higherMark?.grade}
      </td>
    );
  };

  return (
    <div className="grade-sheet">
      {gradeList?.map((studentDetail, index) => {
        var filterSubjects = filterSubjectToRender(
          props?.resultData[0].titledata,
          index
        );
        return (
          <div>
            <div className="layout neb-gradesheet ">
              <div className="layout-wrapper">
                <div className="border-layout">
                  <div
                    className="background-image-marksheet"
                    style={{
                      "--bg-image": bgImage,
                      "--bg-title": backgroundTitle,
                    }}
                  ></div>
                  <div className="pd-10">
                    {window.location.host.includes("urbana") ? (
                      <div className="row">
                        <div
                          className="col-md-3"
                          style={{ position: "relative" }}
                        >
                          <img
                            src="https://i.imgur.com/yP914fm.png"
                            alt="skl"
                            style={{
                              width: "250px",
                              position: "absolute",
                              left: "10px",
                              top: 0,
                            }}
                          />
                        </div>
                        <div className="col-md-7">
                          <h3
                            className="headcenter ft-24"
                            style={{ margin: 0 }}
                          >
                            <b> {props.info?.schoolData?.school_name}</b>
                          </h3>
                          <h4
                            className="headcenter"
                            style={{ margin: 0, fontSize: "18px" }}
                          >
                            +2 College an Undertaking of NAME
                          </h4>
                          <h4
                            className="headcenter"
                            style={{ margin: 0, padding: 0 }}
                          >
                            Putalisadak, Infront of Singhadurbar
                            {/* {props.schoolData?.address} */}
                          </h4>
                          <h4
                            className="headcenter"
                            style={{ margin: 0, padding: 0 }}
                          >
                            Tel: 015321239, 015321238
                          </h4>
                          <h4
                            className="headcenter"
                            style={{ margin: 0, padding: 0 }}
                          >
                            Web: www.urbana.edu.np
                          </h4>
                          <h3
                            className="headcenter ft-24"
                            style={{ marginTop: "15px" }}
                          >
                            {" "}
                            <b>GRADE-SHEET </b>
                          </h3>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-md-2">
                          <img
                            src={props?.info?.schoolData?.logo}
                            style={{ width: "100px" }}
                            alt="logo"
                          />
                        </div>
                        <div className="col-md-8">
                          <h3 className="headcenter ft-24">
                            {" "}
                            <b> {props?.info?.schoolData?.school_name}</b>
                          </h3>
                          <h4 className="headcenter">
                            {props.info?.schoolData?.address}
                          </h4>
                          <h4 className="headcenter">
                            {" "}
                            ESTD: {props.info?.schoolData?.estd}{" "}
                          </h4>
                          <h3 className="headcenter ft-24">
                            {" "}
                            <b>GRADE-SHEET </b>
                          </h3>
                        </div>
                        <div className="col-md-2"></div>
                      </div>
                    )}
                    <p className="ft-14 mb-3">
                      THE GRADE(S) SECURED BY:
                      <b> {studentDetail?.studentName}</b>
                    </p>
                    {window.location.host.includes("urbana") ? (
                      <p className="ft-14 mb-3">
                        {" "}
                        GRADE: <b>{studentDetail?.class_name ?? "-"} </b>{" "}
                      </p>
                    ) : (
                      <p className="ft-14 mb-3">
                        DATE OF BIRTH:
                        <b>{studentDetail?.dob ?? "-"} BS</b>{" "}
                        <b style={{ paddingLeft: "25px" }}>
                          ({studentDetail?.englishDate ?? "-"} A.D){" "}
                        </b>
                      </p>
                    )}
                    <div className="row ">
                      <div className="col-md-5">
                        <p className="ft-14 mb-3">
                          {window.location.host.includes("urbana") ? (
                            <>ID: </>
                          ) : (
                            <>REGISTRATION NO:</>
                          )}
                          <b>{studentDetail?.reg_no ?? "-"} </b>{" "}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p className="ft-14 mb-3">
                          SYMBOL NO:{" "}
                          <b>
                            {studentDetail?.symbol_number ??
                              studentDetail?.rollNo ??
                              "-"}
                          </b>
                        </p>
                      </div>
                      <div className="col-md-3">
                        {window.location.host.includes("urbana") ? (
                          ""
                        ) : (
                          <p className="ft-14 mb-3">
                            {" "}
                            GRADE: <b>
                              {studentDetail?.classAlpha ?? "-"}
                            </b>{" "}
                          </p>
                        )}
                      </div>
                    </div>
                    <p className="ft-14 mb-3">
                      IN THE{" "}
                      {window.location.host.includes("urbana") ? (
                        <>FIRST TERMINAL</>
                      ) : (
                        <>ANNUAL</>
                      )}
                      EXAMINATION CONDUCTED IN 1929 B.S ( 2010 A.D) ARE GIVEN
                      BELOW:
                    </p>
                    <div className="margin-b">
                      <table className="table table-bordered grade-sheet-table">
                        <thead>
                          <tr>
                            <th width="10%">SUBJECT CODE</th>
                            <th width="60%">SUBJECTS</th>
                            <th width="5%">CREDIT HOUR (CH)</th>
                            <th width="5%">GRADE POINT (GP)</th>
                            <th width="5%">GRADE</th>
                            <th width="5%">FINAL GRADE (FG)</th>
                            <th width="10%">REMARKS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterSubjects?.map((subject) => (
                            <>
                              <tr>
                                <td className="bb-hidden" rowSpan="2">
                                  {subject.subject_code}
                                </td>
                                <td
                                  className="bb-hidden text-left"
                                  rowSpan={
                                    studentDetail.subjects[subject.subjectName]
                                      ?.praticalgrade == "-"
                                      ? "2"
                                      : "1"
                                  }
                                >{`${subject.subjectName}${
                                  studentDetail.subjects[subject.subjectName]
                                    ?.praticalgrade == "-"
                                    ? ""
                                    : " (TH)"
                                }`}</td>
                                <td className="bb-hidden" rowSpan="2">
                                  {
                                    studentDetail.subjects[subject.subjectName]
                                      ?.credit
                                  }
                                </td>
                                <td className="bb-hidden" rowSpan="2">
                                  {
                                    studentDetail.subjects[subject.subjectName]
                                      ?.totalpoint
                                  }
                                </td>
                                <td
                                  className="bb-hidden"
                                  rowSpan={
                                    studentDetail.subjects[subject.subjectName]
                                      ?.praticalgrade == "-"
                                      ? "2"
                                      : "1"
                                  }
                                >
                                  {
                                    studentDetail.subjects[subject.subjectName]
                                      ?.theorygrade
                                  }
                                </td>

                                {getFinalGrade(studentDetail, subject)}

                                <td className="bb-hidden" rowSpan="2"></td>
                              </tr>
                              <tr>
                                {studentDetail.subjects[subject.subjectName]
                                  ?.praticalgrade == "-" ? (
                                  ""
                                ) : (
                                  <>
                                    <td className="bb-hidden text-left">
                                      {subject.subjectName + " (PR)"}
                                    </td>
                                    <td className="bb-hidden">
                                      {
                                        studentDetail.subjects[
                                          subject.subjectName
                                        ]?.praticalgrade
                                      }
                                    </td>
                                  </>
                                )}
                              </tr>
                            </>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td></td>
                            <td className="theadsize"></td>
                            {/* <td className="bl-hidden"></td>
                  <td className="bl-hidden"></td> */}
                            <td className="theadsize" colSpan="5">
                              GRADE POINT AVERAGE(GPA):{" "}
                              <b>{studentDetail?.gradepoint} </b>
                            </td>
                          </tr>
                        </tfoot>
                      </table>

                      {window.location.host.includes("urbana") ? (
                        ""
                      ) : (
                        <h4 className="ft-14">EXTRA CREDIT SUBJECT</h4>
                      )}
                      {window.location.host.includes("urbana") ? (
                        <table className="table ">
                          <thead>
                            <tr class="di-none">
                              <td width="100%"></td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr class="border-bottom">
                              <td
                                style={{
                                  textAlign: "left",
                                  verticalAlign: "top",
                                }}
                                width="62%"
                              >
                                REMARKS:
                              </td>
                              <td
                                style={{
                                  textAlign: "left",
                                  verticalAlign: "top",
                                }}
                              >
                                ATTENDANCE:
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "24px",
                                    margin: 0,
                                  }}
                                >
                                  <b>{studentDetail?.remark}</b>
                                </p>
                              </td>
                              <td>
                                <p
                                  style={{
                                    textAlign: "center",
                                    fontSize: "24px",
                                    margin: 0,
                                  }}
                                >
                                  <b>
                                    {studentDetail?.attendance} /{" "}
                                    {studentDetail.school_days}
                                  </b>
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <table className="table  grade-sheet-table">
                          <thead>
                            <tr class="di-none">
                              <td width="10%"></td>
                              <td width="60%"></td>
                              <td width="5%"> </td>
                              <td width="5%"> </td>
                              <td width="5%"></td>
                              <td width="5%"> </td>
                              <td width="10%"></td>
                            </tr>
                          </thead>

                          <tbody>
                            <tr height="40px">
                              <td width="10%"></td>
                              <td width="52%"></td>
                              <td width="7%"> </td>
                              <td width="7%"> </td>
                              <td width="7%"></td>
                              <td width="7%"> </td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div class="bottom-0">
                    <div class="pd-10">
                      <div className="row" style={{ alignItems: "end" }}>
                        {window.location.host.includes("urbana") ? (
                          <>
                            <div className="col-md-5">
                              <p className="mb-3">PREPARED BY:</p>
                              <p class="mb-3">DATE OF ISSUE: -</p>
                            </div>
                            <div
                              className="col-md-4"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src="https://i.imgur.com/wvleP12.png"
                                alt="vp"
                                style={{ width: "80px", marginBottom: "-30px" }}
                              />
                              <p style={{ textAlign: "center" }}>
                                .........................................
                              </p>
                              <p className="principal mb-4">
                                {" "}
                                EXAM COORDINATOR
                              </p>
                            </div>
                            <div
                              className="col-md-3"
                              style={{ textAlign: "center" }}
                            >
                              <img
                                src="https://i.imgur.com/BqKX6Pf.png"
                                alt="vp"
                                style={{ width: "80px", marginBottom: "-30px" }}
                              />
                              <p style={{ textAlign: "center" }}>
                                .................................
                              </p>
                              <p className="principal mb-3"> VICE PRINCIPAL</p>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-sm-7">
                              <p className="mb-3">
                                PREPARED BY: ...............................
                              </p>
                              <p class="mb-3">
                                CHECKED BY: ..................................
                              </p>

                              <p class="mb-3">DATE OF ISSUE: date</p>
                            </div>
                            <div className="col-sm-5">
                              <p style={{ textAlign: "center" }}>
                                ...................................................
                              </p>
                              <p className="principal mb-3">
                                {" "}
                                HEAD TEACHER/CAMPUS CHIEF
                              </p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                    <hr className="neb-hr" />
                    <div class="pd-10 footer-section ">
                      <div class="d-flex">
                        <p class="ft-10 mb-3"> NOTE: </p>&nbsp;
                        <p class="ft-10 mb-3">
                          ONE CREDIT HOUR EQUALS 32 CLOCK HOURS
                        </p>
                      </div>
                      <div class="d-flex">
                        <p class="init-width ft-10 mb-3">INTERNAL (IN):</p>
                        <p class="calc-width ft-10 mb-3">
                          THIS COVERS THE PARTICIPATION, PRACTICAL/PROJECT
                          WORKS, COMMUNITY WORKS,INTERNSHIP,PRESENTATIONS
                          TERMINAL EXAMINATIONS
                        </p>
                      </div>

                      <div class="d-flex ft-10">
                        <p class="init-width ft-10  mb-3">THEORY (TH) :</p>
                        <p class="calc-width ft-10  mb-3">
                          THIS COVERS WRITTEN EXTERNAL EXAMINATIONS
                        </p>
                      </div>

                      <div class="row">
                        <div class="col-md-8">
                          <p class="ft-10  mb-3"> ABS=ABSENT</p>
                        </div>
                        <div class="col-md-3">
                          <p class="ft-10  mb-3" style={{ textAlign: "right" }}>
                            {" "}
                            W=WITHHELD
                          </p>
                        </div>
                      </div>
                      <div className="clear-fix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
