import { useHistory } from "react-router-dom/cjs/react-router-dom";
import "./breadCrumbs.scss";

export const BreadCrumbs = ({ child }) => {
  const history = useHistory();

  return (
    <div className="breadcrumbs">
      <i
        class="bx bx-arrow-back"
        onClick={() => {
          history.goBack();
        }}
      ></i>
      {child?.map((item, index) => {
        return (
          <div
            className={`breadcrumbs-item ${
              child?.length - 1 !== index ? "active" : ""
            }`}
            key={index}
          >
            <p className="content">{item}</p>
            {child?.length - 1 !== index && <i class="bx bx-chevron-right"></i>}
          </div>
        );
      })}
    </div>
  );
};
