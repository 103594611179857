import Modal from "react-awesome-modal";
import "./index.scss";

export const ContinueConfirmationModal = ({ visible, setVisible, onContinue, onDiscard }) => {
  return (
    <div className="continue-confirmation-modal">
      <Modal
        visible={visible}
        width="350"
        height="177"
        effect="fadeInUp"
        onClickAway={() => {
          setVisible?.("");
        }}
      >
        <div className="continue-modal">
          <h4 className="title">Continue Editing?</h4>
          <p className="content">
          We've noticed an unfinished unit plan form. Would you like to continue editing it?
          </p>
          <div className="btn-block">
            <button
              className="secondary-btn"
              onClick={() => {
                setVisible?.("");
                onDiscard?.();
              }}
            >
              Discard
            </button>
            <button
              className="main-btn"
              onClick={() => {
                onContinue?.();
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
