import React, { useEffect, useState } from "react";
import "./AttendanceApproval.scss";
import { getAttendanceRegularization } from "../Api/GetApi";
import { PostAttendanceStatus } from "../Api/PostApi";
import { toast } from "react-toastify";

export const AttendanceApproval = ({ props }) => {
  const [list, setList] = useState([]);
  const [status, setStatus] = useState();
  const [description, SetDescription] = useState([]);

  useEffect(() => {
    getAttendanceRegularization().then((res) => {
      setList(res.data.data);
    });
  }, []);

  const SubmitStatus = async (item, index) => {
    const values = {
      device_attendance_id: item?.device_attendance_id,
      attendance_approval_id: item?.id,
      new_entry_time: item?.new_entry_time,
      old_date: item?.old_date,
      new_exit_time: item?.new_exit_time,
      description: description[index],
      is_approved: status,
      fingerprint_id: item?.fingerprint_id,
      new_date: item?.date,
    };
    try {
      PostAttendanceStatus(values).then((res) => {
        toast(res.data.message);
      });
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="attendance-approval-section ">
      {props?.header && (
        <div className="title-block header">
          <h2 className="title"> Attendance Approval</h2>
        </div>
      )}

      <table
        style={{ width: "100%", textAlign: "center", marginTop: "20px" }}
        class="table table-bordered table-hover "
      >
        <thead>
          <tr>
            <th rowspan="2">Name</th>
            <th rowspan="2">Fingerprint Id</th>
            <th rowspan="2">Description</th>
            <th rowspan="2">Date</th>
            <th colspan="2">Old</th>
            <th colspan="2">New</th>
            <th rowspan="2">Admin Description</th>
            <th rowspan="2">Approve</th>
            <th rowspan="2">Action</th>
          </tr>
          <tr>
            <th>Entry Time</th>
            <th>Exit Time</th>
            <th>Entry Time</th>
            <th>Exit Time</th>
          </tr>
        </thead>
        {list?.length > 0 ? (
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr key={index}>
                  <td> {item?.teacherName}</td>
                  <td>{item?.fingerprint_id} </td>
                  <td> {item?.remark}</td>
                  <td> {item?.date}</td>
                  <td> {item?.old_entry_time}</td>
                  <td>{item?.old_exit_time} </td>
                  <td> {item?.new_entry_time}</td>
                  <td>{item?.new_exit_time} </td>
                  <td>
                    <input
                      type="text"
                      name="description"
                      class="form-control"
                      value={description[index]}
                      onChange={(e) => {
                        const tempDesc = [...description];
                        tempDesc[index] = e.target.value;
                        SetDescription(tempDesc);
                      }}
                    />
                  </td>

                  <td>
                    <div className="status-section">
                      <div>
                        <input
                          type="radio"
                          name="attendance_status"
                          value={true}
                          id="is_approved"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                        <p>Approve</p>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="attendance_status"
                          value={false}
                          id="is_rejected"
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                        <p>Reject</p>
                      </div>
                    </div>
                  </td>
                  <td>
                    <button
                      class="btn btn-content btn-approve"
                      onClick={() => {
                        SubmitStatus(item, index);
                      }}
                    >
                      Submit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        ) : (
          <tbody>
            <tr>
              <td colspan="11" style={{ textAlign: "center" }}>
                No data Available
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};
