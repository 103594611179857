import { useState } from "react";
import { ActivityCard } from "./activityCard";
import { ActivityModal } from "./activityModal";
import { SwitchBtn } from "./switchBtn";
import Dialog from "@mui/material/Dialog";
import { DonutChart } from "../../charts/donutChart";
import { AreaChart } from "../../charts/areaChart";

export const SubjectDetails = () => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="subject-details">
        <DonutChart />
        <div className="v-divider" />
        <div className="area-chart-section">
          <SwitchBtn />
          <AreaChart />
        </div>
      </div>
      <div className="activity-card-group">
        {Array.from({ length: 6 }, (_, index) => {
          return (
            <ActivityCard
              key={index}
              onViewDetails={() => {
                setVisible(true);
              }}
            />
          );
        })}
      </div>

      <Dialog
        onClose={() => {
          setVisible(false);
        }}
        open={visible}
      >
        <ActivityModal onClose={() => setVisible(false)} />
      </Dialog>
    </>
  );
};
