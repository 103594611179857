import React, { useState, useEffect } from "react";
import "./ECAHeader.scss";
import { getStudentsFromGrade } from "../Api/GetApi";
import PopUp from "../popUp/popUp";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { ECAPost } from "../Api/PostApi";
import {
  useECAGradingList,
  useECAHeader,
  useExamDataList,
  useGradeApiForExam,
  usegradeList,
} from "../store/useStore";

const errorType = {
  exam: false,
  ecaHeader: false,
  grade: false,
};
const sudentKey = {
  studentId: "",
  marks: "",
};

export const ECAHeader = () => {
  const [gradeValue, setGradeValue] = useState();
  const [showData, setShowData] = useState(false);
  const [headervalue, setHeadervalue] = useState();
  const [examToken, setexamToken] = useState();
  const [gradeToken, setGradeToken] = useState([]);
  const [grade, setGrade] = useState("");
  const [showError, setShowError] = useState(errorType);
  const [validationArray, setValidationArray] = useState([]);
  const [studentInfo, setStudentInfo] = useState([sudentKey]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackMessge, setSnackMessge] = useState("");
  const [snackStatus, setSnackStatus] = useState(true);
  const { fetchGradeApiForExam, data: gradeList } = useGradeApiForExam();
  const { fetchECAHeader, data: header } = useECAHeader();
  const { fetchECAGradingList, data: ecaGradingList } = useECAGradingList();
  const { fetchExamDataList, data: examlist } = useExamDataList();

  useEffect(() => {
    fetchExamDataList();
    fetchGradeApiForExam();
    fetchECAGradingList();
  }, []);

  const closeModal = () => {
    setVisible(false);
    setIsLoading(false);
  };

  const onSubmit = () => {
    setIsSubmitted(true);
    const temp = studentInfo?.every((a) => a.marks?.length > 0);
    if (examToken === undefined || examToken.length <= 0) {
      setShowError({ exam: true });
    } else if (headervalue === undefined || headervalue.length <= 0) {
      setShowError({ ecaHeader: true });
    } else if (grade === undefined || grade.length <= 0) {
      setShowError({ grade: true });
    } else if (!temp) {
      setOpenSnackBar(true);
      setSnackMessge("Please select all the value");
      setSnackStatus(false);
    } else {
      setVisible(true);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const json = {
      header: headervalue,
      exam_token: examToken,
      value: studentInfo,
      grade: grade,
    };

    ECAPost(json)
      .then((res) => {
        if (res.status === 201 || res.status === 200) {
          setSnackMessge(res.data.message);
          setSnackStatus(true);
          setOpenSnackBar(true);
          setIsLoading(false);
          setVisible(false);
          setexamToken("");
          setHeadervalue("");
          setGrade("");
          setShowData(false);
          setIsSubmitted(false);
        }
      })
      .catch((err) => {
        setSnackMessge(err.response.data.message);
        setSnackStatus(false);
        setOpenSnackBar(true);
        closeModal();
      });
  };

  useEffect(() => {
    if (grade) {
      fetchECAHeader(grade);
    }
  }, [grade]);

  useEffect(() => {
    gradeValue?.forEach((item) => {
      setGradeToken([...gradeToken, item.gradeToken]);
    });
  }, [gradeValue]);
  const handleChange = (e, studentId, index) => {
    let newArr = [...studentInfo];
    const findStudentIndex = newArr.findIndex((f) => {
      return f.studentId === studentId;
    });
    if (findStudentIndex !== -1) {
      newArr[findStudentIndex] = {
        ...newArr[findStudentIndex],
        marks: e,
      };
      if (e !== undefined) {
        let temp = [...validationArray];
        temp.splice(index, 1);
        setValidationArray(temp);
      }
      setStudentInfo(newArr);
    }
  };

  useEffect(() => {
    if (grade && examToken && headervalue) {
      const data = {
        gradeToken: grade,
        eca: true,
        examToken: examToken,
        headervalue: headervalue,
      };
      getStudentsFromGrade(data).then((res) => {
        setGradeValue(res.data);
        setStudentInfo(
          res.data.map((item, index) => {
            return { studentId: item?.id, marks: item?.marks || "" };
          })
        );

        setShowData(true);
      });
    }
  }, [grade, examToken, headervalue]);
  return (
    <div className="eca-header section-gap">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="input-field-block">
              <p className="content first-content">Exam</p>
              <select
                className="custom-select"
                name="exam"
                value={examToken}
                onChange={(e) => {
                  setexamToken(e.target.value);
                  if (e.target.value.length > 0) {
                    setShowError({ exam: false });
                  }
                }}
              >
                <option value="">Select Exam</option>
                {examlist?.exam_data?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.examName}
                    </option>
                  );
                })}
              </select>
              {showError?.exam && <p className="error">Please Select Exam</p>}
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="input-field-block">
              <p className="content first-content">Grade</p>

              <select
                className="custom-select"
                name="grade"
                value={grade}
                onChange={(e) => {
                  setGrade(e.target.value);
                  if (e.target.value.length > 0) {
                    setShowError({ grade: false });
                  }
                }}
              >
                <option value="">Select Grade</option>

                {gradeList?.map((item, index) => {
                  return (
                    <option value={item.id} key={index}>
                      {item.classAlpha}
                    </option>
                  );
                })}
              </select>
              {showError?.grade && <p className="error">Please Select Grade</p>}
            </div>
          </div>
          <div className="col-md-6 col-sm-6 pr-0">
            <div className="input-field-block">
              <p className="content first-content">ECA Header</p>

              <select
                className="custom-select"
                name="grade"
                value={headervalue}
                onChange={(e) => {
                  setHeadervalue(e.target.value);
                  if (e.target.value.length > 0) {
                    setShowError({ ecaHeader: false });
                  }
                }}
              >
                <option value="">Select ECA Header</option>

                {header?.map((item, index) => {
                  return (
                    <option value={item?.id} key={index}>
                      {item?.subject_name}
                    </option>
                  );
                })}
              </select>
              {showError?.ecaHeader && (
                <p className="error">Please Select ECA Header</p>
              )}
            </div>
          </div>
        </div>
        {showData ? (
          <div className="table-section">
            <table className="table">
              <thead class="thead-light">
                <tr>
                  <th width="10%">Roll</th>
                  <th width="50%">Student Name</th>
                  <th width="40%">Values</th>
                </tr>
              </thead>

              <tbody>
                {gradeValue
                  ?.sort((a, b) => a.rollNo - b.rollNo)
                  .map((item, index) => {
                    return (
                      <tr key={index}>
                        <td width="10%">{item.rollNo}</td>
                        <td value={item.id}>{item.studentName}</td>
                        <td>
                          <div className="input-field-block ">
                            <select
                              className="custom-select"
                              value={studentInfo?.[index]?.marks}
                              onChange={(e) => {
                                handleChange(e.target.value, item?.id, index);
                              }}
                            >
                              <option value="">Select</option>
                              {ecaGradingList?.map((item, index) => {
                                return (
                                  <option value={item?.id} key={index}>
                                    {item?.value}
                                  </option>
                                );
                              })}
                            </select>
                            {isSubmitted &&
                            studentInfo?.[index]?.marks === "" ? (
                              <p className="error" id={index}>
                                Please Select Value
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="btn-section">
              <button className="main-btn" onClick={onSubmit}>
                submit
              </button>
            </div>
          </div>
        ) : (
          ""
        )}

        <PopUp
          visible={visible}
          closeModal={closeModal}
          title="Are you sure want to submit ?"
          content="By clicking on the Submit button, ECA will be submited."
          post={handleSubmit}
          isLoading={isLoading}
        />
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
      >
        {snackStatus ? (
          <Alert severity="success">{snackMessge}</Alert>
        ) : (
          <Alert severity="error">{snackMessge}</Alert>
        )}
      </Snackbar>
    </div>
  );
};
