import React, { useEffect, useMemo, useState } from "react";
import "./AssesmentPopup.scss";
import Modal from "react-modal";
import { useTeacherProfile } from "../../../../store/teacherProfileStore";
import { RxCross2 } from "react-icons/rx";

import { assesmentSubmitted } from "../../data";
import { Tabs, Tab } from "react-bootstrap-tabs";
import { getAssignmentDetails } from "../../../Api/GetApi";
import { useAssignmentDetails } from "../../../store/useStore";
import AssesmentPopupChart from "./AssesmentPopupChart/AssesmentPopupChart";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "90%",
  },
};

const AssesmentPopup = ({ modalIsOpen, setIsOpen }) => {
  const [submittedData, setSubmittedData] = useState([]);
  const [hwRatingNumber, setHwRatingNumber] = useState({
    fiveStar: 0,
    fourStar: 0,
    threeStar: 0,
    twoStar: 0,
    oneStar: 0,
  });
  const [cwRatingNumber, setCwRatingNumber] = useState({
    fiveStar: 0,
    fourStar: 0,
    threeStar: 0,
    twoStar: 0,
    oneStar: 0,
  });

  const { setModalOpen, modalOpen, assessmentModalData } = useTeacherProfile();
  const {
    checkedData,
    UnCheckedData,
    loader,
    CWcheckedData,
    CWUnCheckedData,
    fetchAssignmentDetails,
  } = useAssignmentDetails();
  let subtitle;
  function openModal() {
    setModalOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#000000";
  }

  function closeModal() {
    setModalOpen(false);
  }

  const initialValues = {
    studentDetails: assesmentSubmitted,
  };

  useEffect(() => {
    if (
      assessmentModalData?.id !== undefined &&
      assessmentModalData?.id !== null &&
      assessmentModalData?.gradeToken !== undefined &&
      assessmentModalData?.gradeToken !== null
    ) {
      const data1 = {
        gradeToken: assessmentModalData?.gradeToken,
        assignmentToken: assessmentModalData?.id,
        ishomework: false,
      };
      const data2 = {
        gradeToken: assessmentModalData?.gradeToken,
        assignmentToken: assessmentModalData?.id,
        ishomework: true,
      };
      fetchAssignmentDetails(data1);
      fetchAssignmentDetails(data2);
    }
  }, [assessmentModalData?.id, assessmentModalData?.gradeToken]);

  useEffect(() => {
    if (checkedData?.length > 0) {
      const five = checkedData?.filter((item) => item?.marks === "5.0");
      const four = checkedData?.filter((item) => item?.marks === "4.0");
      const three = checkedData?.filter((item) => item?.marks === "3.0");
      const two = checkedData?.filter((item) => item?.marks === "2.0");
      const one = checkedData?.filter((item) => item?.marks === "1.0");

      setHwRatingNumber((val) => ({
        ...val,
        fiveStar: five?.length,
        fourStar: four?.length,
        threeStar: three?.length,
        twoStar: two?.length,
        oneStar: one?.length,
      }));
    }

    if (CWcheckedData?.length > 0) {
      const five = CWcheckedData?.filter((item) => item?.marks === "5.0");
      const four = CWcheckedData?.filter((item) => item?.marks === "4.0");
      const three = CWcheckedData?.filter((item) => item?.marks === "3.0");
      const two = CWcheckedData?.filter((item) => item?.marks === "2.0");
      const one = CWcheckedData?.filter((item) => item?.marks === "1.0");

      setCwRatingNumber((val) => ({
        ...val,
        fiveStar: five?.length,
        fourStar: four?.length,
        threeStar: three?.length,
        twoStar: two?.length,
        oneStar: one?.length,
      }));
    }
  }, [checkedData, CWcheckedData]);

  const checkDataNumberClasswork = useMemo(() => {
    return CWcheckedData?.length;
  })[CWcheckedData];
  const unCheckDataNumberClasswork = useMemo(() => {
    return CWUnCheckedData?.length;
  })[CWUnCheckedData];
  const checkDataNumberHomework = useMemo(() => {
    return checkedData?.length;
  })[checkedData];
  const unCheckDataNumberHomework = useMemo(() => {
    return UnCheckedData?.length;
  })[UnCheckedData];

  return (
    <Modal
      isOpen={modalOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="teacherProfile_assesment_container">
        <div className="assessment_title_container">
          <h2 className="subject_class_section"></h2>
          <div className="close_btn" onClick={closeModal}>
            <RxCross2 size={16} />
          </div>
        </div>
        <div className="description_section">
          <div>
            <div className="desc_title">Homework Description</div>
            <p className="desc_content">{assessmentModalData?.description}</p>
          </div>
          <div>
            <div className="desc_title">activities</div>
            <p className="desc_content">{assessmentModalData?.activity}</p>
          </div>
          <div>
            <div className="desc_title">images</div>
            <div className="img_wrapper">
              {assessmentModalData?.imageUrl?.length > 0 &&
                assessmentModalData?.imageUrl?.map((item, index) => (
                  <div className="img_box">
                    <img src={item} alt="img" />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="rating_section_wrapper card-tab-section">
          <Tabs>  
            <Tab label="Homework" title="Homework">
              <AssesmentPopupChart
                totalChecked={checkedData?.length}
                totalUnchecked={UnCheckedData?.length}
                star={hwRatingNumber}
              />
            </Tab>
            <Tab label="Classwork" title="Classwork">
              <AssesmentPopupChart
                totalChecked={CWcheckedData?.length}
                totalUnchecked={CWUnCheckedData?.length}
                star={cwRatingNumber}
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    </Modal>
  );
};

export default AssesmentPopup;
