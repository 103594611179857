import React, { createContext, useEffect, useState } from "react";
import { Link } from "@material-ui/core";
import { LineTab } from "../TabList/LineTab/LineTab";
import TeacherActivityForm from "../TeacherActivityForm/TeacherActivityForm";
import { TeacherAssignment } from "../TeacherAssignment/TeacherAssigment";
import { ToastContainer } from "react-toastify";
import {
  getGradesfromAssignment,
  getSubjectApiStrictFilter,
  getTeacherData,
} from "../Api/GetApi";
import "./TeacherAssessment.scss";

export const AssessmentContext = createContext();

const TeacherAssessment = ({ showHeader, token, iframe }) => {
  const [subjectList, setSubjectList] = useState();
  const [gradeList, setGradeList] = useState();
  const [gradeToken, setGradeToken] = useState();
  const [subjectToken, setSubjectToken] = useState();
  const [allGradeToken, setAllGradeToken] = useState("");
  const [showAssignment, setShowAssignment] = useState(false);

  useEffect(() => {
    getTeacherData().then((res) => {
      setShowAssignment(res.data?.assignment_tracking);
    });
  }, []);
  const show = showAssignment;
  const tabData = [
    show && {
      title: "Assignment",
      content: <TeacherAssignment />,
    },

    {
      title: "Activity",
      content: <TeacherActivityForm />,
    },
  ];

  useEffect(() => {
    getGradesfromAssignment().then((res) => {
      setGradeList(res?.data?.grade);
      setGradeToken(res?.data?.grade[0]?.id);
      setAllGradeToken(res.data.grade[0]?.id[0]?.toString());
    });
  }, [token]);

  useEffect(() => {
    if (gradeToken) {
      const commaIndex = gradeToken.indexOf(",");

      // If commaIndex is -1, it means there is no comma in the string
      // In that case, use the entire value as the first value
      const firstValue =
        commaIndex === -1 ? gradeToken : gradeToken.substring(0, commaIndex);

      getSubjectApiStrictFilter(firstValue).then((res) => {
        setSubjectList(res.data.subject);
        setSubjectToken(res?.data?.subject[0]?.id);
      });
    }
  }, [gradeToken]);
  return (
    <div className="teacher-assessment-section">
      <ToastContainer />

      {/* {showHeader && (
        <div className="title-block header">
          <Link to="/teaching_plan">
            <i className="bx bxs-chevron-left"></i>
          </Link>
          <h2 className="title">Teacher Assessment </h2>
        </div>
      )} */}

      <div className="section-gap responsive-container">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <label className="label-content">Grade</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setGradeToken(e.target.value);
                  setAllGradeToken(e.target.value);
                }}
                value={gradeToken}
              >
                {gradeList?.map((item, index) => {
                  return (
                    <option key={index} value={item.id}>
                      {item?.classAlpha}
                    </option>
                  );
                })}
              </select>

              {gradeList?.length > 0 ? (
                ""
              ) : (
                <p style={{ fontSize: "13px", margin: "3px 0" }}>
                  No grade found
                </p>
              )}
            </div>

            <div className="col-md-3">
              <label className="label-content">Subject</label>
              {subjectList && subjectList.length > 0 ? (
                <select
                  className="form-control"
                  onChange={(e) => {
                    setSubjectToken(e.target.value);
                  }}
                  value={subjectToken}
                >
                  {subjectList?.map((item, index) => {
                    return (
                      <option key={index} value={item?.id}>
                        {item?.subjectName}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <select className="form-control">
                  <option>select</option>
                </select>
              )}
            </div>
          </div>

          <AssessmentContext.Provider
            value={{
              gradeToken,
              subjectToken,
              allGradeToken,
              iframe,
              setAllGradeToken,
              setSubjectToken,
              setGradeToken,
            }}
          >
            <div className="assessment-form-content mt-20">
              <LineTab tabData={tabData} defaultIndex={true} />
            </div>
          </AssessmentContext.Provider>
        </div>
      </div>
    </div>
  );
};

export default TeacherAssessment;
