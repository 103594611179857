import React, { useEffect, useState } from "react";
import { Fab } from "@material-ui/core";
import "./visitorSchedule.scss";
import VisitorModal from "./VisitorModal";
import "bootstrap/dist/css/bootstrap.css";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { ScheduledCard } from "./VisitorCard";
import LineTab from "../TabList/LineTab/LineTab";
import { useAppointmentStore } from "../../store/appointmentListStore";
import { getParentAppointments } from "../Api/GetApi";

const VisitorSchedule = () => {
  const { appointmentList, setAppointmentList } = useAppointmentStore();
  useEffect(() => {
    getParentAppointments().then((res) => {
      setAppointmentList(res?.data?.data);
    });
  }, []);

  const [visible, setVisible] = useState(false);

  const nestedTab = [
    {
      name: "All",
      component: (
        <ScheduledCard
          cardList={appointmentList?.filter((sch) => sch.is_visited === false)}
        />
      ),
    },
    {
      name: "Approved",
      component: (
        <ScheduledCard
          cardList={appointmentList?.filter(
            (sch) => sch.is_visited === false && sch.is_approved === true
          )}
        />
      ),
    },
    {
      name: "Pending",
      component: (
        <ScheduledCard
          cardList={appointmentList?.filter(
            (sch) => sch.is_visited === false && sch.is_approved === false
          )}
        />
      ),
    },
  ];

  const tabData = [
    {
      title: "Scheduled",
      content: (
        <Tabs className="tab-container">
          {nestedTab.map((tab, index) => (
            <Tab
              eventKey={index}
              key={index}
              title={tab.name}
              tabClassName="nestedTab"
            >
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      ),
    },
    {
      title: "Completed",
      content: (
        <ScheduledCard
          cardList={appointmentList?.filter((sch) => sch.is_visited === true)}
        />
      ),
    },
  ];
  return (
    <div className="visitor-schedule-section">
      <div className="title-block header">
        <h1 className="title">Teacher Appointment</h1>
      </div>
      <div className="assessment-form-content mt-20">
        <LineTab tabData={tabData} />
      </div>

      <div className="floating-item">
        <Fab aria-label="add" onClick={() => setVisible(true)}>
          <i
            className="bx bx-plus"
            style={{ height: "unset", width: "unset" }}
          ></i>
        </Fab>
      </div>
      <VisitorModal visible={visible} setVisible={setVisible} />
    </div>
  );
};

export default VisitorSchedule;
