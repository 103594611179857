import React, { useEffect, useId } from "react";
import { useState } from "react";
import "./MarksApproval.scss";
import Modal from "react-modal";
import { Tabs, Tab } from "react-bootstrap-tabs";
import Popup from "../popupHod/Popup.jsx";
import Activity from "../activity/Activity.jsx";
import ApprovedByPopup from "../ApprovedByPopup/ApprovedByPopup.jsx";
import { useHodMarksApproval } from "../../store/hodDashboardStore.js";
import MarkApprovalInner from "./MarkApprovalInner.jsx";

import { NoDataContent } from "../NoDataContent/NoDataContent.js";
import HodTeachingPlan from "../HodTeacherActivities/HodTeachingPlan.jsx";
import PreLoader from "../preLoader/preLoader.js";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore.jsx";
import { ExamMarksApproval } from "./ExamMarksApproval.jsx";

function MarksApproval() {
  const [showAll, setShowAll] = useState(false);
  const [selected, setSelected] = useState();
  const [approved, setApproved] = useState([]);
  const [dataType, setDataType] = useState([]);
  const { setPopupType, popupType, setPopUpMarks, popUpMarks } =
    useTeacherDashboardStore();

  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    height: "90%",
  };

  const customStyles2 = {
    position: "fixed",
    top: "50%", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%) translateY(-50%)",
    width: "50%",
    height: "50%",
    overFlow: "scroll",
    transition: "all 0.5s ease",
  };

  const { HodMarksApproval, loading, fetchHodMarksApproval } =
    useHodMarksApproval();

  const [data, setData] = useState([]);
  const modifiedData = showAll ? data : data?.slice(0, 3);
  useEffect(() => {
    fetchHodMarksApproval();
  }, []);

  useEffect(() => {
    setData(HodMarksApproval);
  }, [HodMarksApproval]);

  useEffect(() => {
    const dataVal = ["Teaching Plan"];
    if (data.length > 0) {
      data?.forEach((obj) => {
        if (!dataVal?.includes(obj.data_type)) {
          dataVal.push(obj.data_type);
        }
      });
    }
    setDataType(dataVal);
  }, [data]);

  return (
    <div className="mark_approval_container ">
      <Modal
        isOpen={popupType === "markDetails"}
        onRequestClose={() => {
          setPopupType("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}
              <p className="modal-title">
                Teacher{" "}
                {selected?.data_type == "exam"
                  ? "Exam Mark Approval"
                  : "Activity Approval"}{" "}
              </p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setPopupType("");
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>
          {selected?.data_type == "exam" ? (
            <Popup classWiseData={selected} onClose={() => setPopupType("")} />
          ) : (
            <Activity onClose={() => setPopupType("")} data={selected} />
          )}
        </div>
      </Modal>

      <Modal
        isOpen={popupType === "approveMarks"}
        onRequestClose={() => {
          setPopupType("");
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: customStyles2,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              {/* <div className="icon-block">
                <i class="bx bxs-cog"></i>
              </div> */}

              <p className="modal-title">Approved From</p>
            </div>

            <div
              className="close-icon"
              onClick={() => {
                setPopupType("");
              }}
            >
              <i class="bx bx-x"></i>
            </div>
          </div>
          <ApprovedByPopup approvedData={approved} />
        </div>
      </Modal>

      <div className="marks_approval_wrapper ">
        <p className="heading-h">Approval</p>

        {loading ? (
          <div
            style={{ width: "200px", height: "450px", marginInline: "auto" }}
          >
            <PreLoader height="400px" />{" "}
          </div>
        ) : dataType.length > 0 ? (
          <Tabs>
            {dataType?.map((type, index) =>
              type === "Teaching Plan" ? (
                <Tab key={index} label={type} title={type}>
                  <HodTeachingPlan />
                </Tab>
              ) : type === "exam" ? (
                <Tab key={index} label={type} title={type}>
                  <ExamMarksApproval
                    setApproved={setApproved}
                    setSelected={setSelected}
                    setPopupType={setPopupType}
                    data={data?.filter((item) => item?.data_type === type)}
                    setShowAll={setShowAll}
                    showAll={showAll}
                    type={type}
                    setPopUpMarks={setPopUpMarks}
                  />
                </Tab>
              ) : (
                <Tab key={index} label={type} title={type}>
                  <MarkApprovalInner
                    setApproved={setApproved}
                    setSelected={setSelected}
                    setPopupType={setPopupType}
                    data={data?.filter((item) => item?.data_type === type)}
                    setShowAll={setShowAll}
                    showAll={showAll}
                    setPopUpMarks={setPopUpMarks}
                  />
                </Tab>
              )
            )}
          </Tabs>
        ) : (
          <div style={{ height: "400px" }}>
            {" "}
            <NoDataContent
              title="No Data Found"
              description="Data will be appeared when updated"
              image={require("../../images/emptyfile.png").default}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default MarksApproval;
