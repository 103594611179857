import React, { useEffect, useState } from "react";
import "./Parentfield.scss";
import Select from "react-select";
import { getParentList } from "../Api/GetApi";
import { changeReload, useStudentId, useStudentStore } from "../../store/store";
import { toast } from "react-toastify";
import { postStudentParent } from "../Api/PostApi";

export const AddParent = ({ closeParentModal }) => {
  const parentTypeList = ["father", "mother", "guardian"];
  const [parent, setParent] = useState();
  const [parentList, setParentList] = useState();
  const [parentType, setParentType] = useState(parentTypeList[0]);
  const [loader, setLoader] = useState();
  const student = useStudentId();
  const { fetchStudentData } = useStudentStore();
  const { setReload, shouldReload } = changeReload();
  const [isGuardian, setIsGuardian] = useState(false);

  useEffect(() => {
    getParentList().then((res) => {
      const response = res.data;
      const selectData = response?.data?.map((item) => {
        return {
          value: item?.id,
          label: item?.name,
        };
      });

      setParentList(selectData);
    });
  }, []);

  const handleSubmit = async () => {
    if (!parent?.value) {
      toast("Please select parent");
    } else if (!parentType) {
      toast("Please select Parent Type");
    } else {
      const payloadValues = {
        parent_type: parentType,
        parent_id: parent.value,
        student_id: parseFloat(student.studentId),
        is_guardian: isGuardian,
      };

      setLoader(true);

      try {
        const res = await postStudentParent(payloadValues);
        toast(res.data.message);
        closeParentModal();
        getParentList();
        setReload(false);
        fetchStudentData(student.studentId);
      } catch {
        toast("Something went wrong");
      } finally {
        setLoader(false);
      }
    }
  };
  return (
    <div className="add-parent-section">
      <div className="parent-popup-title">
        <p>Add Parent Details</p>
        <div
          onClick={() => {
            closeParentModal();
          }}
        >
          <i className="bx bx-x"></i>
        </div>
      </div>
      <div className="form-group">
        <label>Full Name</label>

        <Select
          value={parent}
          options={parentList}
          onChange={(value) => {
            setParent(value);
          }}
          placeholder="Choose Parent"
        />
      </div>

      <div className="form-group ">
        <label>Parent Type</label>
        <select
          className="form-control text-capitalize"
          value={parentType}
          onChange={(e) => {
            setParentType(e.target.value);
          }}
        >
          {parentTypeList?.map((item, index) => {
            return (
              <option key={index} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>

      <div className="btn-footer-block">
        <button
          className="btn default-btn"
          onClick={() => {
            closeParentModal();
          }}
        >
          Close
        </button>
        <button
          className="btn btn-content"
          onClick={() => {
            handleSubmit();
          }}
        >
          {loader ? "Submitting..." : "Submit"}
        </button>
      </div>
    </div>
  );
};
