import React from "react";
import "./CanteenBill.scss";
import { useBillData } from "../../store/Canteen";
import moment from "moment";
import QRCode from "react-qr-code";

const CanteenBill = () => {
  const { billData } = useBillData();
  const date = moment().format("MMM Do YYYY");
  const time = moment().format("h:mm:ss a");


  console.log("date", date);
  console.log("time", time);
  console.log(billData);
  return (
    <div className="canteen_bill_wrapper">
      <div className="top_section">
        <div className="detail_section">
          <h2>Canteen Bill</h2>
          <p>Name: {billData?.student_name} </p>
          <p>Grade: {billData?.class} </p>
          <p>Date: {date}</p>
          <p>Time: {time}</p>
        </div>
        <div className="qr_section">
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={billData?.QRCode}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>

      <table>
        <thead>
          <th>Name</th>
          <th>Qty</th>
          <th>Amount</th>
        </thead>
        <tbody>
          {billData?.orders?.map((food, index) => (
            <tr key={index}>
              <td>{food?.menu_name}</td>
              <td>X{food?.quantity}</td>
              <td>Rs.{food?.menu_credit}</td>
            </tr>
          ))}
         
     
          <tr>
            <td>Grand Total</td>
            <td></td>
            <td>Rs.{billData?.total_credit}</td>
          </tr>
        </tbody>
      </table>

      <p></p>
    </div>
  );
};

export default CanteenBill;
