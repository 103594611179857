import { BarChartCard } from "../BarChartCard/BarChartCard";
import "./BarChart.scss";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { domain } from "../../url";
import noData from "../../images/no-data.svg";
import NividLoader from "../NividLoader/NividLoader";

const BarChart = (props) => {
  const [barData, setBarData] = useState();
  const [attendanceType, setAttendanceType] = useState("system");
  const [cardData, setCardData] = useState();
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const res = await Axios.get(
          `${domain}/dashboard_api/v1/device_attendance_api`
        );
        if (res.status === 200) {
          setData(res?.data);
          setBarData(res?.data?.system_data?.system_data);
          setCardData(res?.data?.system_data?.total_system_count);
        }
      } catch (e) {
        console.log(e, "something went wrong");
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const onBtnClick = (type) => {
    if (type === "system") {
      setBarData(data?.system_data?.system_data);
      setCardData(data?.system_data?.total_system_count);
    } else {
      setBarData(data?.device_data?.device_data);
      setCardData(data?.device_data?.total_device_count);
    }
  };

  const chartOptions = {
    series: [
      {
        name: "Total Present",
        data: barData?.present,
        color: "#00BF91",
      },

      {
        name: "Total Absent",
        data: barData?.absent,
        color: "#ec948d",
      },
      {
        name: "Total Late",
        data: barData?.late,

        color: "#f4c65a",
      },
      {
        name: "Total Unmarked",
        data: barData?.unmarked,

        color: "#9b9b9b",
      },
    ],
    options: {
      legend: {
        position: "top",
        offsetX: 250,
        offsetY: 0,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      fill: {
        opacity: 1,
      },
      grid: {
        row: {
          colors: ["#fff"],
        },
        column: {
          colors: ["#fff"],
        },
      },
      xaxis: {
        categories: barData?.grade,
      },
      chart: {
        id: "basic-bar",
        stacked: true,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          columnWidth: "80%",
          dataLabels: {
            position: "bottom",
          },
        },
      },
      axisBorder: {
        show: true,
      },
      goals: [
        {
          name: "Expected",
          value: "unset",
          strokeColor: "#775DD0",
        },
      ],
      responsive: [
        {
          breakpoint: 576,
          options: {
            legend: {
              offsetX: 0,
            },
          },
        },
      ],
    },
  };

  return (
    <div className="bar-chart section-gap">
      <div className="custom-card">
        <div className="top-section">
          <div className="toggle-btn-block">
            <button
              className={`toggle-btn ${
                attendanceType === "system" ? "active" : ""
              }`}
              onClick={() => {
                setAttendanceType("system");
                onBtnClick("system");
              }}
            >
              System Attendance
            </button>
            <button
              className={`toggle-btn ${
                attendanceType === "device" ? "active" : ""
              }`}
              onClick={() => {
                setAttendanceType("device");

                onBtnClick("device");
              }}
            >
              Device Attendance
            </button>
          </div>
          {attendanceType === "device" && (
            <div className="ip-block">
              {data?.device_data?.offline_devices?.map((item, index) => {
                return <p className="ip">{item?.ip} |</p>;
              })}
            </div>
          )}
        </div>
        <h4 className="medium-title-header">Attendance</h4>
        <div className="row">
          <div className="col-md-3">
            <BarChartCard attendanceData={cardData} />
          </div>
          <div className="col-md-9">
            {isLoading ? (
              <NividLoader height={"300px"} />
            ) : barData ? (
              <div className="mixed-chart">
                <ReactApexChart
                  options={chartOptions?.options}
                  series={chartOptions?.series}
                  type="bar"
                  height={440}
                  width="100%"
                />
              </div>
            ) : (
              <div className="no-data">
                <div className="no-img">
                  <img src={noData} alt="no-data" />
                </div>
                <div className="no-data-content">
                  No Data available at the moment
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BarChart;
