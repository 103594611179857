import Axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import "../../MarksheetLayout.scss";
import Header from "../../Header/Header";
import "./Layout2080.scss";

export default function Layout2080(props) {
  const [ledgerData, setLedgerData] = useState();
  const [gradeList, setGradeList] = useState();

  var prevCode = 0;

  const bgImage = `url(${props.info?.schoolData?.logo})`;

  const backgroundTitle = `url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="300" height="30" viewBox="0 0 300 30"%3E%3Ctext x="0" y="20" font-size="14" fill="%235494c9"%3E${props.info?.schoolData?.school_name}%3C/text%3E%3C/svg%3E')`;
  useEffect(() => {
    // const data= props.resultData;
    // setLedgerData(props?.resultData[0])
    // setTitleData(data?.data?.[0].titledata)
    setGradeList(props?.resultData[0].markdata);
  }, [props]);
  const getFinalGrade = (studentDetail, subject) => {
    var higherMark = studentDetail?.highermark?.find(
      (eachStudent) => eachStudent.group_code === subject.group_code
    );

    if (subject.group_code != null && prevCode == subject.group_code)
      return <td className="bb-hidden" rowSpan="2"></td>;
    prevCode = subject.group_code;
    return (
      <td className="bb-hidden" rowSpan="2">
        {subject.group_code == null
          ? studentDetail.subjects[subject.subjectName]?.totalgrade
          : higherMark?.grade}
      </td>
    );
  };
  const filterSubjectToRender = (titleData, index) => {
    var filterSubjects = titleData?.filter((item) => {
      let theoryMark = parseFloat(
        gradeList[index]?.subjects[item.subjectName]?.theorymark
      );
      if (item?.optionSubject && theoryMark == 0) {
        return false;
      } else if (isNaN(theoryMark)) {
        return false;
      }
      return true;
    });

    return filterSubjects;
  };

  //   if (loading) {
  //     return (
  //       <div>
  //         <center>Loading</center>
  //       </div>
  //     );
  //   } else {
  return (
    <div className="grade-sheet layout2080-section">
      <div>
        {gradeList?.map((studentDetail, index) => {
          var filterSubjects = filterSubjectToRender(
            props?.resultData[0].titledata,

            index
          );
          return (
            // var totalGpa = 0;
            // studentDetail?.highermark?.map((each) => {
            //   if (each.group_code == 0) {
            //     totalGpa = each.point;
            //   }
            // });
            <div className="layout  neb-gradesheet m-0">
              <div
                className="border-layout background-image-marksheet"
                style={{ "--bg-image": bgImage, "--bg-title": backgroundTitle }}
              >
                <div className="pd-10">
                  <div className="row">
                    <Header
                      schoolData={props?.info?.schoolData}
                      header={props?.info?.header}
                    />

                    <div className="col-sm-12">
                      <p className="layout2080-examination-level">
                        {props?.exam}
                        {/* SECONDARY LEVEL EXAMINATION */}
                      </p>
                      <h3 className="headcenter ft-24">
                        <b>GRADE-SHEET </b>
                      </h3>
                    </div>
                  </div>
                  <p className="ft-14 mb-3">
                    THE GRADE(S) SECURED BY:
                    <b className="layout2080-year-gap">
                      {" "}
                      {studentDetail?.studentName}
                    </b>
                  </p>

                  <div className="row ">
                    <div className="col-sm-5">
                      <p className="ft-14 mb-3">
                        DATE OF BIRTH:{" "}
                        <b className="layout2080-year-gap">
                          {studentDetail?.dob ?? "-"}{" "}
                        </b>
                      </p>
                    </div>
                    <div className="col-sm-3">
                      <p className="ft-14 mb-3">
                        ROLL NO.:
                        <b className="layout2080-year-gap">
                          {studentDetail?.symbol_number ??
                            studentDetail?.rollNo ??
                            "-"}
                        </b>
                      </p>
                    </div>
                    <div className="col-sm-4">
                      <p className="ft-14 mb-3">
                        GRADE:
                        <b className="layout2080-year-gap">
                          {studentDetail?.classAlpha ?? "-"}
                        </b>
                      </p>
                    </div>
                  </div>
                  <p className="ft-14 mb-3">
                    IN THE EXAMINATION CONDUCTED IN{" "}
                    <b className="layout2080-year-gap">
                      {props?.resultData?.[0]?.conducted_years?.nepali}
                    </b>{" "}
                    B.S. ({" "}
                    <b className="layout2080-year-gap">
                      {props?.resultData?.[0]?.conducted_years?.english}
                    </b>{" "}
                    A.D. ) ARE GIVEN BELOW.
                  </p>
                  <div className="margin-b">
                    <table className="table table-bordered grade-sheet-table">
                      <thead>
                        <tr>
                          <th width="10%">SUBJECT CODE</th>
                          <th width="60%">SUBJECTS</th>
                          <th width="5%">CREDIT HOUR</th>
                          <th width="5%">GRADE POINT</th>
                          <th width="5%">GRADE</th>
                          <th width="5%">FINAL GRADE</th>
                          <th width="10%">REMARKS</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filterSubjects?.map((subject) => (
                          <>
                            <tr>
                              <td className="bb-hidden" rowSpan="2">
                                {subject.subject_code}
                              </td>
                              <td
                                className="bb-hidden text-left"
                                rowSpan={
                                  studentDetail.subjects[subject.subjectName]
                                    ?.praticalgrade == "-"
                                    ? "2"
                                    : "1"
                                }
                              >{`${subject.subjectName}${
                                studentDetail.subjects[subject.subjectName]
                                  ?.praticalgrade == "-"
                                  ? ""
                                  : " (TH)"
                              }`}</td>
                              <td className="bb-hidden" rowSpan="2">
                                {
                                  studentDetail.subjects[subject.subjectName]
                                    ?.credit
                                }
                              </td>
                              <td className="bb-hidden" rowSpan="2">
                                {
                                  studentDetail.subjects[subject.subjectName]
                                    ?.totalpoint
                                }
                              </td>
                              <td
                                className="bb-hidden"
                                rowSpan={
                                  studentDetail.subjects[subject.subjectName]
                                    ?.praticalgrade == "-"
                                    ? "2"
                                    : "1"
                                }
                              >
                                {
                                  studentDetail.subjects[subject.subjectName]
                                    ?.theorygrade
                                }
                              </td>
                              {getFinalGrade(studentDetail, subject)}

                              <td className="bb-hidden" rowSpan="2"></td>
                            </tr>
                            <tr>
                              {studentDetail.subjects[subject.subjectName]
                                ?.praticalgrade == "-" ? (
                                ""
                              ) : (
                                <>
                                  <td className="bb-hidden text-left">
                                    {subject.subjectName + " (PR)"}
                                  </td>
                                  <td className="bb-hidden">
                                    {
                                      studentDetail.subjects[
                                        subject.subjectName
                                      ]?.praticalgrade
                                    }
                                  </td>
                                </>
                              )}
                            </tr>
                          </>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>{gradeList?.[0].total_credit_hour}</td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>

                        <tr>
                          {/* <td></td>
                            <td className="theadsize"></td> */}
                          {/* <td className="bl-hidden"></td>
                            <td className="bl-hidden"></td> */}
                          <td className="theadsize" colSpan="7">
                            GRADE POINT AVERAGE (GPA):
                            <b className="ph-20">
                              {studentDetail?.gradepoint}{" "}
                            </b>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
                <div class="bottom-0">
                  <div class="pd-10">
                    <div className="row">
                      <div className="col-sm-7">
                        <p className="mb-3">
                          PREPARED BY: ...............................
                        </p>
                        <p class="mb-3">
                          CLASS TEACHER: ..................................
                        </p>

                        <p class="mb-3">
                          DATE OF ISSUE: <b className="ph-20">-</b>
                        </p>
                      </div>
                      <div className="col-sm-5">
                        {props?.info?.extraData?.showPContent ? (
                          <div className="layout-signature">
                            {props?.info?.extraData?.pSign ? (
                              <img
                                src={props.info?.extraData?.pSign}
                                style={{ visibility: "hidden" }}
                                alt="sign"
                              />
                            ) : (
                              ""
                            )}
                            <p className="principal mb-3">
                              {props.info?.extraData?.pName.split("#")[0]}
                              <span className="layout2080-signature">
                                {props?.info?.extraData?.pName.split("#")[1] ??
                                  "Principal"}
                              </span>
                            </p>
                          </div>
                        ) : (
                          <>
                            <p className="layout2080-nosign">.</p>
                            <p className="principal mb-3">
                              <span className="layout2080-signature">
                                HEAD TEACHER
                              </span>
                            </p>
                          </>
                        )}

                        {/* <p>.</p>
                              <p className="principal mb-3">
                                <span className="layout2080-signature">HEAD TEACHER</span>
                              </p> */}
                      </div>
                    </div>
                  </div>
                  <hr className="neb-hr" />
                  <div class="pd-10">
                    <div class="d-flex">
                      <p class="ft-10 mb-3"> NOTE: </p>&nbsp;
                      <p class="ft-10 mb-3">
                        ONE CREDIT HOUR EQUALS 32 WORKING HOURS
                      </p>
                    </div>
                    <div class="d-flex">
                      <p class="init-width ft-10 mb-3">INTERNAL (IN):</p>
                      <p class="calc-width ft-10 mb-3">
                        THIS COVERS THE PARTICIPATION, PRACTICAL/PROJECT WORKS
                        AND TERMINAL
                      </p>
                    </div>

                    <div class="d-flex ft-10">
                      <p class="init-width ft-10  mb-3">EXTERNAL (TH) :</p>
                      <p class="calc-width ft-10  mb-3">
                        THIS COVERS FINAL WRITTEN EXAMINATION
                      </p>
                    </div>

                    <div class="row">
                      <div class="col-sm-6">
                        <p class="ft-10  mb-3 ml-50"> ABS=ABSENT</p>
                      </div>
                      <div class="col-sm-6">
                        <p class="ft-10  mb-3" style={{ textAlign: "right" }}>
                          NG=NOT GRADED
                        </p>
                      </div>
                    </div>
                    <div className="clear-fix"></div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        {/* <Layout1 /> */}
      </div>
    </div>
  );
  //   }
}
