import { Field } from "formik";

export const SelectField = ({ name, ...rest }) => {
  return (
    <Field name={name}>
      {({ field }) => {
        return <select {...field} {...rest} className="input-field" />;
      }}
    </Field>
  );
};
