import React, { useEffect, useState } from "react";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { DeleteTeacherRoutine } from "../Api/DeleteApi";
import Modal from "react-modal";
import { useTeacherDetailsStore } from "../store/useStore";
import { toast } from "react-toastify";
import { TeacherRoutineForm } from "./TeacherRoutineForm";
import { getTeacherRoutine } from "../Api/GetApi";

export const TeacherRoutine = () => {
  const [visible, setVisible] = useState();
  const { teacherDetails } = useTeacherDetailsStore();

  const [selectedId, setselectedId] = useState();
  const [modalvisible, setModalVisible] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    getTeacherRoutine().then((res) => {
      setData(res.data.data);
    });
  }, []);
  const handleDelete = (item) => {
    const values = {
      teacherId: item?.teacher_id.toString(),
      period: item.period.toString(),
      selectedSubjects: null,
      routine: item?.routine.toString(),
    };
    DeleteTeacherRoutine(values).then((res) => {
      if (res.data.status === 200) {
        toast("Teacher Routine deleted successfully");
        closeModalVisible();
        teacherDetails();
      } else {
        toast("Something went wrong");
      }
    });
  };

  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    height: "fit-content",
  };

  const closeModal = () => {
    setVisible(!visible);
  };

  const closeModalVisible = () => {
    setModalVisible(false);
  };

  return (
    <div className="teacher-routine-section">
      <Modal
        isOpen={visible}
        // onAfterOpen={}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block"></div>
              <p className="modal-title">Add Routine </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <TeacherRoutineForm closeModal={closeModal} />
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={modalvisible}
        // onAfterOpen={}
        onRequestClose={closeModalVisible}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block">
                {/* <i class="bx bxs-cog"></i> */}
              </div>
              <p className="modal-title">Teacher Routine </p>
            </div>

            <div className="close-icon" onClick={closeModalVisible}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body">
            <p className="text-center">
              Are you sure you want to delete this routine ?
            </p>

            <div className="d-flex-center">
              <button
                className="btn btn-content custom-btn"
                onClick={() => {
                  handleDelete(selectedId);
                }}
              >
                Yes
              </button>
              <button
                className=" btn btn-light mr-10"
                onClick={() => {
                  closeModalVisible();
                }}
              >
                NO
              </button>
            </div>
          </div>
        </div>
      </Modal>

      <div className="header-flex-section">
        {/* <button
          className="btn btn-content custom-btn"
          onClick={() => {
            setVisible(true);
          }}
        >
          <i className="bx bx-plus"></i> Add Routine
        </button> */}
      </div>

      {data?.length > 0 ? (
        <div className="table-data-section">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>Period</th>
                <th>Grade</th>
                <th>Subject</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>Period {item?.period}</td>
                    <td> {item?.grade}</td>

                    <td>
                      {item?.subject ? (
                        <div className="info-tag">{item?.subject}</div>
                      ) : (
                        "NO subject assigned"
                      )}
                    </td>
                    <td>
                      <button
                        className="action-btn action-delete-btn"
                        onClick={() => {
                          setselectedId(item);
                          setModalVisible(true);
                        }}
                      >
                        <i class="bx bx-trash"></i>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <NoDataContent
          title=""
          image={require("../../images/emptyfile.png").default}
          description="No data available  at the moment"
        />
      )}
    </div>
  );
};
