import React, { useEffect } from "react";
import "./accordionpopup.scss";
import { useHistory } from "react-router-dom";

import { useGradewiseReceivable } from "../../store/accountDashboard";
import { useIframeTokenStore } from "../store/useStore";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import { useTeacherProfile } from "../../store/teacherProfileStore";

const AccordionPopup = ({ isTeacher, teacherData = [] }) => {
  const { setToken } = useIframeTokenStore();
  const popHead = isTeacher
    ? ["TEACHER NAME", ""]
    : ["Roll No.", "STUDENT NAME", "OVERDUE AMOUNT"];
  const history = useHistory();
  const { gradewiseReceivable: dataObj } = useGradewiseReceivable();
  const data = isTeacher ? teacherData : Object?.values(dataObj);
  const setIsTeacherComp = useTeacherProfile((state) => state.setIsTeacherComp);

  const viewTeacherProfile = (token) => {
    setToken(token);
    setIsTeacherComp(true);

    // return history.push(`/teacher-profile`);
  };

  if (isTeacher && data?.length === 0) {
    <NoDataContent
      marginTop="100px"
      title="No data available"
      description="Data will be appeared when updated"
      iframeToken
      image={require("../../images/emptyfile.png").default}
    />;
  }


  return (
    <div className="popup_container">
      <table>
        <thead>
          <tr>
            {popHead.map((pop, index) => {
              return <th key={index}>{pop}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {data?.length === 0 ? (
            <NoDataContent
              marginTop="50px"
              title={isTeacher ? "No Teacher Found." : "No Data Found"}
              description="Data will be appeared when updated"
              image={require("../../images/emptyfile.png").default}
            />
          ) : (
            data?.map((item, index) => {
              return (
                <tr key={index}>
                  {!isTeacher && <td>{item.rollNo}</td>}
                  <td className="student_name_wrapper">
                    <div className="img_container">
                      {item.imageName === null || item?.image ? (
                        <img src={item.imageName} alt="image" />
                      ) : (
                        <div className="no_img_filler_small">
                          {item?.studentName.charAt(0) ||
                            item?.teacher_name.charAt(0)}
                        </div>
                      )}
                    </div>
                    <div>{item?.studentName || item?.teacher_name}</div>
                  </td>
                  {isTeacher ? (
                    <td
                      onClick={() => viewTeacherProfile(item?.token)}
                      style={{ color: "29abe2", cursor: "pointer" }}
                    >
                      View
                    </td>
                  ) : (
                    <td style={{ color: "#CC2C2C" }}>{item.overdue}</td>
                  )}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AccordionPopup;
