import React, { useEffect, useState } from "react";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";
import { AssessmentHeaderTitle } from "../AssessmentHeaderTitle/AssessmentHeaderTitle";
import NividLoader from "../NividLoader/NividLoader";
import { useAssignmentDetails } from "../store/useStore";
import { StudentRatingTable } from "../StudentRatingTable/StudentRatingTable";
import LineTab from "../TabList/LineTab/LineTab";

export const StudentDetailsRatingPopup = () => {
  const { selectedLessonTab, viewedDetails } = useTeacherDashboardStore();
  const {
    CWcheckedData,
    checkedData,
    UnCheckedData,
    CWUnCheckedData,
    fetchAssignmentDetails,
    loader,
  } = useAssignmentDetails();
  const [cwData, setCWData] = useState();
  const [hwData, setHWData] = useState();

  useEffect(() => {
    const gradeToken = viewedDetails?.grade_token;
    if ((gradeToken, selectedLessonTab?.classwork_token)) {
      const assignmentToken = selectedLessonTab?.classwork_token;
      const ishomework = false;

      fetchAssignmentDetails({
        gradeToken,
        assignmentToken,
        ishomework,
      });
    } else {
      setCWData(null);
    }

    if ((gradeToken, selectedLessonTab?.homework_token)) {
      const assignmentToken = selectedLessonTab?.homework_token;
      const ishomework = true;
      fetchAssignmentDetails({
        gradeToken,
        assignmentToken,
        ishomework,
      });
    } else {
      setHWData(null);
    }
  }, [viewedDetails, selectedLessonTab, viewedDetails]);

  useEffect(() => {
    if (viewedDetails?.grade_token && selectedLessonTab?.classwork_token) {
      const classworkDetails = {
        checked: CWcheckedData,
        UnCheckedData: CWUnCheckedData,
      };
      setCWData(classworkDetails);
    } else {
      setCWData(null);
    }

    if (viewedDetails?.grade_token && selectedLessonTab?.homework_token) {
      const homeworkDetails = {
        checked: checkedData,
        UnCheckedData: UnCheckedData,
      };
      setHWData(homeworkDetails);
    } else {
      setHWData(null);
    }
  }, [CWcheckedData, checkedData, UnCheckedData, CWUnCheckedData]);
  const tabData = [
    {
      title: "Classwork",
      content: <StudentRatingTable details={cwData} />,
    },

    {
      title: "Homework",
      content: <StudentRatingTable details={hwData} />,
    },
  ];
  if (loader) {
    return <NividLoader height="100%" />;
  }
  return (
    <div>
      <AssessmentHeaderTitle selectedLessonTab={selectedLessonTab} />
      <div>
        {cwData === null && hwData === null ? (
          ""
        ) : (
          <LineTab tabData={tabData} />
        )}
      </div>
    </div>
  );
};
