import React, { useState } from "react";
import "./ApprovedByPopUp.scss";
import { useTeacherDashboardStore } from "../../pages/teacherdashboard/store/teacherDashboardStore";

const ApprovedByPopup = ({ approvedData }) => {
  const [error, setError] = useState([]);
  const { popUpMarks } = useTeacherDashboardStore();
  const isExamTab = popUpMarks === "exam";
  return (
    <div className="approved_popup_container">
      {approvedData &&
        approvedData?.map((item, index) => {
          const mappedData = isExamTab ? approvedData : item?.approved_by;

          return (
            <div key={index} className="approved_item_wrapper custom-card">
              {!isExamTab && (
                <p className="lesson-name"> {item?.lesson_name}</p>
              )}

              {mappedData ? (
                <div className="approved-list">
                  {mappedData?.map((itm, idx) => {
                    return (
                      <div>
                        <div className="d-flex mb-10" key={idx}>
                          <div className="icon-block">
                            <i class="bx bxs-user-check"></i>
                          </div>
                          <p>Approved By:</p>
                          <div className="d-flex mgl-5">
                            <div className="img_wrapper">
                              {itm?.teacher_image ===
                                "https://storage.googleapis.com/nivid/" ||
                              error?.includes(idx) ? (
                                <div className="no_image_filler">
                                  {itm?.teacherName?.charAt(0)}
                                </div>
                              ) : (
                                <img
                                  src={itm?.teacher_image}
                                  alt=""
                                  onError={() => {
                                    setError([...error, idx]);
                                  }}
                                />
                              )}
                            </div>
                            <div className="content_wrapper">
                              <h3>{itm?.teacherName}</h3>
                            </div>
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="icon-block">
                            <i class="bx bx-calendar-check"></i>
                          </div>
                          <p>Approved Date:</p>
                          <p className="mgl-5">
                            {itm?.time?.split("T")[0].slice(1) || "Time"}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p style={{ marginTop: "10px" }}>
                  {isExamTab ? "Exam" : "Lesson Plan"} is not approved
                </p>
              )}
            </div>
          );
        })}

      {/* {selectedLesson?.details &&
              selectedLesson?.details?.length > 0 ? (
                <>
                  {selectedLesson?.details?.map((item, index) => {
                    return (
                      <div key={index}>
                        <p>{item?.lesson_name}</p>

                        <div className="list">
                          {item?.approved_by &&
                          item?.approved_by?.length > 0 ? (
                            <div>
                              {item?.approved_by?.map((itm, idx) => {
                                return (
                                  <div key={idx}>
                                    <img src={itm.teacher_image} alt="img" />
                                    <p>{itm?.teacherName}</p>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                "No Lesson Plan Approved"
              )} */}
    </div>
  );
};

export default ApprovedByPopup;
