//@ts-check
import { useEffect, useState } from "react";
import { useTeacherGroupChat } from "./useTeacherGroupChat";
import React from "react";
import "./teacherGroupchat.scss";
import { useGroupChat } from "./useGroupChat";
import { Avatar } from "@mui/material";
import Moment from "react-moment";
const TeacherGroupChat = () => {
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const { loading, groupChat } = useGroupChat();
  const selectedGroup = groupChat?.find((chat) => chat.id === selectedGroupId);
  const toToken = selectedGroup?.pair?.[1];
  const uuid = selectedGroup?.uuid;

  const fromToken = selectedGroup?.pair?.[0];
  const {
    newMessage,
    setNewMessage,
    sendMessage,
    messages,
    connectGroupChatWebSocket,
  } = useTeacherGroupChat(selectedGroupId, uuid);
  useEffect(() => {
    //if message length change scroll to the last item in message-list-section container

    const messageList = document.querySelector(".message-list-section");
    if (messageList) {
      //scroll behaviour smooth
      messageList.scrollTo({
        top: messageList.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages.length]);
  return (
    <div>
      <div className="chat-section">
        <div className="row">
          <div className="col-sm-3 group-list">
            <h2 className="font-bold heading">Messages</h2>
            <div className="chatgroup-list">
              {!loading &&
                groupChat?.map((message, index) => {
                  return (
                    <div style={{ padding: "0 20px" }}>
                      <div
                        className={` group-item ${
                          selectedGroupId === message.id ? "active" : ""
                        }`}
                        onClick={() => {
                          setSelectedGroupId(message.id);
                        }}
                        key={index}
                      >
                        <Avatar className="avatar">
                          {message.group_name.charAt(0)}
                        </Avatar>
                        <div>
                          <p className="avatar-text">{message.group_name}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <section className="message-section col-sm-9">
            {selectedGroupId && (
              <div className="top-heading-section">
                <div className="selected-heading">
                  <div>
                    <Avatar
                      sx={{
                        height: "25px",
                        width: "25px",
                      }}
                      className="avatar"
                    >
                      {selectedGroup?.group_name?.charAt(0)}
                    </Avatar>
                  </div>
                  <div>
                    <h3 className="chat-title">{selectedGroup?.group_name}</h3>
                    <p className="chat-members">
                      Ram Chandra Thakur , Aaditya,
                      <span>
                        <i className="bx bx-plus"></i>1 other
                      </span>
                    </p>
                  </div>
                </div>
                <div>
                  <i className="bx bx-dots-horizontal-rounded"></i>
                </div>
              </div>
            )}

            <div className=" message-container">
              {!selectedGroupId && (
                <div className="empty-section">
                  Select a group to view messages
                </div>
              )}
              {messages && selectedGroupId && (
                <>
                  <div className="message-list-section ">
                    {messages.length === 0 ? (
                      <div className="empty-section">
                        <p>
                          Message is empty. Be the first to send the message
                        </p>
                      </div>
                    ) : (
                      messages?.map((message, index) => {
                        let isSender =
                          message.fromToken === selectedGroup.pair[0];
                        return (
                          <div className="message-item">
                            <div className="name-section">
                              {!isSender && (
                                <Avatar
                                  sx={{
                                    height: "25px",
                                    width: "25px",
                                  }}
                                  className="avatar"
                                >
                                  {message.sender_name.charAt(0)}
                                </Avatar>
                              )}
                            </div>
                            <div
                              className={`chat-message ${
                                isSender ? "message" : "sender"
                              }`}
                              key={index}
                            >
                              <p
                                className={`message-user ${
                                  isSender && "message-name"
                                }`}
                              >
                                {message.sender_name}
                              </p>
                              <p className="description">
                                {
                                  // @ts-ignore
                                  message.detail || ""
                                }
                              </p>
                              <p className="time">
                                <Moment format="hh:mm A">
                                  {message.created_at}
                                </Moment>
                              </p>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div className="input-section">
                    <input
                      className="input-box"
                      value={newMessage}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          sendMessage(e, toToken, fromToken);
                        }
                      }}
                      placeholder="Write your message here"
                      onChange={(e) => setNewMessage(e.target.value)}
                    />
                    <button
                      className="send-button"
                      onClick={(e) => {
                        sendMessage(e, toToken, fromToken);
                      }}
                    >
                      <i className="bx bx-send"></i>
                      Send
                    </button>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default TeacherGroupChat;
