import React, { useState } from "react";
import "./BookReview.scss";
import { BiBookOpen } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import Ratings from "./Rating";
import Rating from "@mui/material/Rating";
import { IoIosStarOutline } from "react-icons/io";

const nameStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    backgroundColor: "#f0ffff",
    width: "50%",
    height: "154px",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.35)",
  },
};

function BookReview({ pendingDatas, onRatingSubmit }) {
  const [ispoint, setIsPoints] = useState(false);

  const [currentBookId, setCurrentBookId] = useState(null);

  const handleOpen = (id) => {
    setIsPoints(true);
    setCurrentBookId(id);
  };
  const name = pendingDatas?.find((item) => item.id === currentBookId);

  const closePoints = () => {
    setIsPoints(false);
    setCurrentBookId(null);
  };

  return (
    <>
      <div className="container section-gap">
        <div className="book_review">
          <div className="review_details">
            {pendingDatas?.map((item) => (
              <div key={item.id} className="book_review_cart">
                <div className="d-flex justify-content-between libaray_book_cart">
                  <p className="reviwer mb-2">
                    <span className="first-letter">
                      {item?.studentName?.charAt(0)}
                    </span>
                    {item.studentName}
                  </p>
                  <div className="reviewer">
                    {item.rating !== null ? (
                      <Rating name="read-only" value={item.rating} readOnly />
                    ) : (
                      <button
                        variant="contained"
                        color="primary"
                        className="rate_btn"
                        onClick={() => handleOpen(item.id)}>
                        <span className="rate_star">
                          <IoIosStarOutline />
                        </span>
                        Rate
                      </button>
                    )}
                  </div>
                </div>
                {item.bookName === null ? (
                  ""
                ) : (
                  <div className="book_reviewer">
                    <div className="book_name d-flex">
                      <p className="me-2">
                        <BiBookOpen color=" #29abe2" />
                      </p>

                      <p className="reviwers">
                        <span className="reviwe_book">Book Name: </span>
                        {item.bookName}
                      </p>
                    </div>
                    {item.remark === null ? (
                      ""
                    ) : (
                      <div className="mt-3">
                        <p className="book_remarks ">
                          <span className="reviwe_book_remarks">Review: </span>
                          {item.remark}
                        </p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <Modal
          isOpen={ispoint}
          onRequestClose={closePoints}
          style={nameStyles}
          contentLabel="Meeting Modal">
          <div className="rating">
            <h5>Rate {name?.studentName} </h5>
            <IoMdClose className="close" onClick={closePoints} />
          </div>
          <div className="mt-2">
            <Ratings
              datas={pendingDatas}
              bookId={currentBookId}
              closePoints={closePoints}
            />
          </div>
        </Modal>
      </div>
    </>
  );
}

export default BookReview;
