import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./TabPanelPage.scss";

export const TabPanelPage = ({ tabData }) => {
  return (
    <div className="tab-panel-section">
      {/* <Tabs>
      </Tabs> */}

      <Tabs>
        {tabData?.map((item, index) => {
          return (
            <Tab eventKey={item.label} title={item.label}>
              {item.content}
            </Tab>
          );
        })}
      </Tabs>
      <div className="line-tab-section">
        <div></div>
      </div>
    </div>
  );
};
