import React from "react";
import Modal from "react-awesome-modal";
import "./popUp.scss";

class PopUp extends React.Component {
  state = {
    modalWidth: "50%",
    visible: false,
    comment: "",
  };
  componentDidMount() {
    this.getModalWidth();
  }

  getModalWidth = () => {
    var windowWidth = window.innerWidth;
    var modalWidth = "90%";
    if (windowWidth <= 320) {
      modalWidth = "90%";
    } else if (windowWidth <= 480) {
      modalWidth = "90%";
    }
    this.setState({
      modalWidth,
    });
  };

  openModal = () => {
    this.setState({
      visible: true,
    });
  };

  closeModal = () => {
    this.setState({
      visible: false,
    });
  };

  onComment = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({
      [name]: value,
    });
    this.props.getComment(this.state.comment);
  };

  popup = () => {
    return (
      <div className="popup">
        <Modal
          visible={this.props.visible}
          width={this.state.modalWidth}
          effect="fadeInUp"
          className="popuo-modal"
          onClickAway={() => this.props.closeModal()}
        >
          <div>
            <div className="popup">
              <div className="container">
                <div className="popup-section">
                  {this.props.textBox ? (
                    <>
                      <h4 className="title">{this.props.title}</h4>

                      <div className="popup-text-area">
                        <textarea
                          className="input-field"
                          name="comment"
                          value={this.state.comment}
                          onChange={this.onComment}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <h4 className="title">{this.props.title}</h4>
                      {this.props.isLoading ? (
                        <div className="loader-block">
                          <p className="content">Submiting</p>
                          <img
                            src={
                              require("../../images/Loading_icon.gif").default
                            }
                            alt="loader"
                          />
                        </div>
                      ) : (
                        <p className="content">{this.props.content}</p>
                      )}
                    </>
                  )}
                  {!this.props.isLoading && (
                    <div className="btn-block">
                      <button
                        className="secondary-btn"
                        onClick={() => this.props.closeModal()}
                      >
                        Cancel
                      </button>
                      <button
                        className="main-btn"
                        onClick={() => this.props.post()}
                      >
                        OK
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <span
              className="cpoint"
              behaviour
              onClick={() => {
                this.props.closeModal();
              }}
            >
              <div className="close">
                <i className="bx bx-x"></i>
              </div>
            </span>
          </div>
        </Modal>
      </div>
    );
  };

  render() {
    return <>{this.popup()}</>;
  }
}

export default PopUp;
