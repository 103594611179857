import React from "react";
import Rating from "react-rating";
import "./StudentRatingTable.scss";
import { NoDataContent } from "../NoDataContent/NoDataContent";
import LineTab from "../TabList/LineTab/LineTab";
import { useAssignmentDetails } from "../store/useStore";

export const StudentRatingTable = ({ details }) => {
  const tabData = [
    {
      title: "Checked",
      content: <StudentTableList studentList={details?.checked} />,
    },

    {
      title: "UnChecked",
      content: <StudentTableList studentList={details?.UnCheckedData} />,
    },
  ];

  return (
    <div className="student-rating-table-section">
      {details ? (
        <div className="card-tab-section">
          <LineTab tabData={tabData} />
        </div>
      ) : (
        <NoDataContent
          title="No Data found "
          image={require("../../images/emptyfile.png").default}
        />
      )}
    </div>
  );
};

export const StudentTableList = ({ studentList }) => {
  return (
    <div className="student-list-section  mt-20">
      {studentList && studentList.length > 0 ? (
        <table className="table ">
          <thead>
            <tr>
              <th>Roll No.</th>
              <th>Student Name</th>
              <th>Ratings</th>
            </tr>
          </thead>

          <tbody>
            {studentList?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.rollNo}</td>
                  <td>{item?.studentName}</td>

                  <td>
                    <div className="rating-section">
                      <Rating
                        readonly
                        initialRating={item?.marks}
                        emptySymbol={
                          <div className="empty-block">
                            <i class="bx bxs-star"></i>
                          </div>
                        }
                        fullSymbol={
                          <div className="full-block">
                            <i class="bx bxs-star"></i>
                          </div>
                        }
                      />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <NoDataContent
          title="No Checked Assignments "
          image={require("../../images/emptyfile.png").default}
        />
      )}
    </div>
  );
};
