import { useEffect, useState } from "react";
import {
  getClassAlpha,
  getEvaluationUnitApi,
  getGradelist,
  getTeacherSubjectApi,
  getUnitApi,
} from "../../components/Api/GetApi";
import { useTeachingPlanMainStore } from "../teachingPlanMain/store/teachingPlanMainStore";
import { FormikWrapper } from "./formikWrapper/formikWrapper";
import { TeachingPlanFilter } from "./teachingPlanFilter";
import { toast } from "react-toastify";

export const TeachingPlanEvaluation = () => {
  const [token, setToken] = useState();

  const [isLoading, setIsLoading] = useState(false);

  let urlparam = new URLSearchParams(window.location.search);
  let authToken = urlparam.get("token");

  useEffect(() => {
    if (authToken !== "") {
      localStorage.setItem("authToken", authToken);
      setToken(authToken);
    } else {
      const temp = localStorage.getItem("authToken");
      setToken(temp);
    }
  }, [authToken]);

  const {
    setGrade,
    setSubjectList,
    unitList,
    setUnitList,
    setSelectedUnitData,
    selectedUnit,
    gradeToken,
    subjectToken,
    setIsLessonEvaluation,
    setViewPage,
    viewPage,
  } = useTeachingPlanMainStore();

  useEffect(() => {
    setViewPage("Evaluation List");
  }, []);

  useEffect(() => {
    getGradelist().then((res) => {
      setGrade(res?.data);
    });
  }, []);

  useEffect(() => {
    if (gradeToken) {
      getTeacherSubjectApi(gradeToken).then((res) => {
        setSubjectList(res?.data?.subject);
      });
    }
  }, [gradeToken]);

  useEffect(() => {
    if (
      subjectToken !== 0 &&
      subjectToken !== null &&
      gradeToken !== "" &&
      gradeToken !== null
    ) {
      setIsLoading(true);
      getEvaluationUnitApi(gradeToken, subjectToken)
        .then((res) => {
          setUnitList(res?.data?.data?.plan);
          setIsLessonEvaluation(res?.data?.data?.is_lesson_evaluation);
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err);
          setIsLoading(false);
        });
    }
  }, [gradeToken, subjectToken]);

  useEffect(() => {
    if (unitList?.length > 0) {
      const temp = unitList?.find((item) => {
        return item?.id === Number(selectedUnit);
      });
      if (temp) {
        setSelectedUnitData(temp);
      }
    } else {
      setSelectedUnitData(null);
    }
  }, [unitList?.length, selectedUnit]);

  return (
    <div className="my-teaching-plan-page">
      <div className="timeline ">
        <div className="title-block header">
          {viewPage === "Student Evaluation" && (
            <div
              className="icon-section"
              onClick={() => {
                setViewPage("Evaluation List");
              }}
            >
              <i className="bx bx-left-arrow-alt"></i>
            </div>
          )}
          <h1 className="title">Teaching Plan Evaluation</h1>
        </div>
        <FormikWrapper>
          <TeachingPlanFilter isLoading={isLoading} />
        </FormikWrapper>
      </div>
    </div>
  );
};
