import React from "react";
import "./SearchComponent.scss";

const SearchComponent = ({ onSearch, placeholder }) => {
  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };
  return (
    <div className="search-main">
      <div className="search-image">
        <i class="bx bx-search"></i>
      </div>
      <input
        className="input-field"
        placeholder={placeholder}
        type="search"
        aria-label="Search"
        onChange={handleSearchChange}
      />
    </div>
  );
};

export default SearchComponent;
