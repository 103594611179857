import React, { useState } from "react";
import "./chartCard.scss";
import NepaliDate from "nepali-date";
import Modal from "react-modal";
import { postManualAttendance } from "../Api/PostApi";
import { toast } from "react-toastify";
import { useStudentId } from "../../store/store";
import { useStudentAttendance } from "../store/useStore";
const ChartCard = ({
  remark,
  remarkColor,

  attRemark,
  labelColorPairs,
  remarkTagColor,
  isattRemark,
}) => {
  console.log("rij", remark);
  let remarks = [];
  let firstKey = "";
  let tagStyle = {};
  const [visible, setVisible] = useState(false);
  const [loader, setLoader] = useState(false);
  const [time, setTime] = useState(false);
  const { studentId, manualAttendance, selectedMonth } = useStudentId();

  const customStyles = {
    position: "fixed",
    top: "20px", // Adjust this value to position the modal at the desired distance from the top
    left: "50%",
    transform: "translateX(-50%)",
    width: "60%",
    height: "fit-content",
  };
  const closeModal = () => {
    setVisible(!visible);
  };

  const { fetchStudentAttendance } = useStudentAttendance();
  const [dayToken, setDayToken] = useState("");
  const handleSubmit = async () => {
    setLoader(true);
    const data = {
      student_token: studentId,
      day_token: dayToken,
      entry_time: time,
    };

    try {
      const res = await postManualAttendance(data);

      if (res.status === 200) {
        toast(res.data.message);

        fetchStudentAttendance(selectedMonth, studentId);
        closeModal();
      } else {
        toast("something went wrong");
      }
    } catch {
      toast("something went wrong");
    } finally {
      setLoader(false);
    }
  };
  if (remark) {
    // firstKey = Object?.keys(remark)?.[0];
    firstKey = Object?.keys(remark).filter((key) => Array.isArray(remark[key]));
    remarks = remark?.[firstKey];

    // const filter = labelColorPairs?.filter((item) => item?.label === firstKey);
    // const colorHex = filter?.[0]?.color;
    tagStyle = {
      color: remarkTagColor,
      border: `1px solid ${remarkTagColor}`,
    };
  }

  if (attRemark) {
    remarks = attRemark;
    const attColor = remarkColor(attRemark?.[0]?.status?.toLowerCase());
    const filter = labelColorPairs?.filter((item) => item?.label === attRemark);
    tagStyle = {
      color: attColor,
      border: `1px solid ${attColor}`,
    };
  }

  return (
    <div className="chart-card-section">
      <Modal
        isOpen={visible}
        // onAfterOpen={}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "99",
          },
          content: customStyles,
        }}
        contentLabel="Example Modal"
      >
        <div className="modal-details-section ">
          <div className="modal-header">
            <div className="title-content">
              <div className="icon-block"></div>
              <p className="modal-title"> Manual attendance </p>
            </div>

            <div className="close-icon" onClick={closeModal}>
              <i class="bx bx-x"></i>
            </div>
          </div>

          <div className="modal-body manual-attendance-popup">
            <div className="form-group">
              <lable>Entry Time</lable>

              <div className="time-entry">
                <input
                  type="time"
                  className="form-control"
                  onChange={(e) => {
                    setTime(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="footer-btn-section end-block mt-20">
              <button className=" btn cancel-btn mr-10" onClick={closeModal}>
                Cancel
              </button>
              <button
                className={`btn btn-content custom-btn ${
                  loader && "disable-cursor"
                }`}
                disabled={loader}
                onClick={() => {
                  handleSubmit();
                }}
              >
                {loader ? "Submitting..." : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      {remarks?.length > 0 ? (
        remarks?.map((remarkItem, index) => {
          return (
            <div className="chart-card-container" key={index}>
              <div className="chart-card-title">
                <span className={`tag`} style={tagStyle}>
                  {firstKey || remarkItem?.status}
                  {remarkItem?.subject_name
                    ? `(${remarkItem.subject_name})`
                    : ""}
                </span>
                <div
                  style={{ display: "flex", gap: "8px" }}
                  className="popUp-btn-container"
                >
                  <p className="date-content">
                    {(remarkItem?.updated_at &&
                      new NepaliDate(new Date(remarkItem?.updated_at)).format(
                        "yyyy-mm-dd"
                      )) ||
                      remarkItem?.nepali_date}
                  </p>
                  {/* <BsThreeDots
                style={{ cursor: "pointer" }}
                onClick={() => showPopUp(remarkItem.id)}
                className="three-dots"
              /> */}

                  {/* {selectedCard === remarkItem.id && (
                <div className="pop-up">
                  <button style={{ color: "rgb(75, 128, 212)" }}>
                    Edit <FaRegEdit />
                  </button>
                  <button style={{ color: "#ea5b5b" }}>
                    Delete <MdDelete />
                  </button>
                </div>
              )} */}
                </div>
              </div>
              <div className="chart-card-content">
                <p>{remarkItem?.description || remarkItem?.remark}</p>
                {manualAttendance && isattRemark && (
                  <div className="manual-attendance-block">
                    <div
                      className="manual-attendance"
                      onClick={() => {
                        setVisible(true);
                        setDayToken(remarkItem?.dayid);
                      }}
                      title="Edit Attendance"
                    >
                      {/* <i class="bx bxs-edit-alt"></i> */}
                      <i class="bx bx-calendar-edit"></i>
                    </div>
                  </div>
                )}
              </div>
              <div className="chart-card-footer">
                {/* <button className="">
              <TbMessage /> 6
            </button> */}
                {/* <button className="reply tag">
              <BiShare />
              Reply
            </button> */}
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-center">No Remarks</p>
      )}
    </div>
  );
};

export default ChartCard;
