import moment from "moment";
import React, { useState } from "react";
import "./Notices.scss";
import Calender from "../../images/calander.png";
import noData from "../../images/no-data.svg";
import { domain } from "../../url";

const Notices = (props) => {
  const [defaultImage, setDefaultImage] = useState([]);

  const handleImage = (index) => {
    const temp = [...defaultImage, index];
    setDefaultImage(temp);
  };
  return (
    <div className="notice-block">
      <div className="custom-card ">
        <div className="d-space-between notice-header">
          <p className="medium-title">Notices</p>
          <button className="info-btn small-content">
            <div
              className="add-link"
              onClick={() => {
                window.open(`${domain}/notice_tables/new`);
              }}
            >
              + Add Notices
            </div>
          </button>
        </div>
        <div className="scroll-box">
          {props?.props?.noticelist && props?.props?.noticelist?.length > 0 ? (
            <>
              {props?.props?.noticelist?.map((item, index) => {
                const isImageNull =
                  Array.isArray(defaultImage) && defaultImage?.includes(index);
                return (
                  <div className="wrapper" key={index}>
                    <div className="d-flex wrap-flex">
                      <div className="img-block">
                        {!isImageNull ||
                        item?.image_url ===
                          "https://storage.googleapis.com/nivid/" ? (
                          <div className="profile">
                            <div className="square-content">
                              <p className="square-font">
                                {item?.title?.charAt(0).toUpperCase()}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="profile">
                            <div className="square-content">
                              <img
                                className="square"
                                src={item.image_url}
                                alt="user"
                                onError={(e) => {
                                  handleImage(index);
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-body">
                        <div className="d-space-between text-header">
                          <p className="head-title">{item.title}</p>
                          <p className="date-content">
                            <img src={Calender} alt="icons" />
                            <span className="extra-small-content time mb-0">
                              {moment(item.miti).format("YYYY/MM/DD")}
                            </span>
                          </p>
                        </div>
                        <p className="extra-small-content description">
                          {item.description}
                        </p>
                        <p className="smallest-content notice-time">
                          <span>about </span>
                          {moment(item.created_at)
                            .local()
                            .startOf("seconds")
                            .fromNow()}
                        </p>
                      </div>
                    </div>
                    <div className="border-bottom notice-separate"></div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="no-data">
              <div className="no-img">
                <img src={noData} alt="no-data" />
              </div>
              {/* <div className="gesture">Oops!!</div>*/}
              <div className="no-data-content">
                No Event available at the moment
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notices;
