import { create } from "zustand";
import { toast } from "react-toastify";
import {
  getHodMarkApproval,
  getHodMeeting,
  getHodRemarkData,
  getHodTeacherActivity,
  getUnitApi,
  getUnitApiHod,
} from "../components/Api/GetApi";
import { devtools } from "zustand/middleware";

export const useHodMeeting = create(
  devtools((set) => ({
    HodMeeting: [],
    loading: false,

    fetchHodMeeting: async (group, date) => {
      set({ loading: true });
      try {
        const res = await getHodMeeting(group, date);
        const apiRes = await res?.data;
        if (apiRes?.status === 1) {
          set({ HodMeeting: apiRes?.data });
        } else {
          set({ HodMeeting: [] });
        }
      } catch {
        toast("something went wrong");
      } finally {
        set({ loading: false });
      }
    },
  }))
);

export const useHodRemarks = create(
  devtools((set) => ({
    HodRemarks: [],
    loading: false,
    fetchHodRemarks: async (gradeToken, date) => {
      set({ loading: true });
      try {
        const res = await getHodRemarkData(gradeToken, date);
        const apiRes = await res?.data;
        if (apiRes?.status === 1) {
          set({ HodRemarks: apiRes?.data });
        } else {
          set({ HodRemarks: [] });
        }
      } catch {
        toast("something went wrong");
      } finally {
        set({ loading: false });
      }
    },
  }))
);

export const useHodMarksApproval = create(
  devtools((set) => ({
    HodMarksApproval: [],
    loading: false,
    fetchHodMarksApproval: async () => {
      set({ loading: true });
      try {
        const res = await getHodMarkApproval();
        const apiRes = await res?.data;
        if (apiRes?.status === 1) {
          set({ HodMarksApproval: apiRes?.data });
        } else {
          set({ HodMarksApproval: [] });
        }
      } catch {
        toast("something went wrong");
      } finally {
        set({ loading: false });
      }
    },
  }))
);

export const useHodTeacherActivity = create((set) => ({
  HodTeacherActivity: [],
  loading: false,
  fetchHodTeacherActivity: async (select, date) => {
    set({ loading: true });
    try {
      const res = await getHodTeacherActivity(select, date);
      const apiRes = await res?.data;
      if (apiRes?.status === 1) {
        set({ HodTeacherActivity: apiRes?.data });
      } else {
        set({ HodTeacherActivity: [] });
      }
    } catch {
      toast("something went wrong");
    } finally {
      set({ loading: false });
    }
  },
}));

export const useUnitApi = create(
  devtools((set) => ({
    UnitPlan: [],
    loading: false,
    fetchHodUnitPlan: async (selectedGrade, selectedSubject, selectedMonth) => {
      if (!selectedGrade && !selectedSubject && !selectedMonth) {
        toast("grade or subject or month is not selected ");
      } else {
        set({ loading: true });

        try {
          const res = await getUnitApiHod(
            selectedGrade,
            selectedSubject,
            selectedMonth
          );
          const apiRes = await res?.data;
          if (apiRes?.status === "success") {
            set({ UnitPlan: apiRes?.data });
          } else {
            set({ UnitPlan: [] });
          }
        } catch {
          toast("something went wrong");
          set({ UnitPlan: [] });
        } finally {
          set({ loading: false });
        }
      }
    },
  }))
);

const initialState = {
  attendanceData: true,
};

export const useDeviceData = create((set) => ({
  ...initialState,
  setAttendanceData: (val) => set(() => ({ attendanceData: val })),
}));
